import React from 'react';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { getListAxios, getJSONRequestFromAPI } from '../utils/Common/API';
import { setTransactionRequest } from '../../../actions/workFlowEventAction';
import { connect } from 'react-redux';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {  triggerToast } from "../utils/Common/HelperUtils";


function Asynchronous(props) {

  const [valuee, setValue] = React.useState(props.componentvalue !== undefined ? props.componentvalue : '');
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [showlist, setShowlist] = React.useState(false);
  const loading = open && options.length === 0;
  const { component } = props;
  const itemtip = component.ItemTipParams;
  let showSearchIcon = (props.componentvalue === undefined || props.componentvalue === '') ? true : false;
  var DataList = [];
  let ResultSet = [];
  var DataSet = {};
  let itemparams = itemtip.split(",");
  const [selectedvalue, setselectedvalue] = React.useState({});

  function getdetails(changevalue) {
    setShowlist(changevalue);
  }

  function valuesList(component) {

    let itemparams = component.ItemTipParams.split(",");
    let pagelevelvalues = props.pagelevelValues;
    let value = {};
    itemparams.map((itemparam, index) => {
      if (index > 0) {
        let item = itemparam.split("~");
        value[item[1]] = pagelevelvalues[component.SubApplicationName][item[0]];
      }
    })
    setselectedvalue(value);
  }

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {

      let request = {};
      if (props.workflowReducer.transactionRequest.hasOwnProperty(component.AttributeParams)) {
        request = JSON.parse(JSON.stringify(props.workflowReducer.transactionRequest[component.AttributeParams]));
        // console.log("getting request from store",request);
      }
      else {
        // console.log("Fetching request From API");
        request = await getJSONRequestFromAPI(component.AttributeParams, props.UIFlowReducer.productDetails);
        props.setTransactionRequest(JSON.parse(JSON.stringify(request)));
      }

      // let request = await getJSONRequestFromAPI(component.AttributeParams);
      // request.ServiceRequestDetail = getServiceRequestDetail();
      request = Object.assign({}, request, props.values, props.pagelevelValues[component.SubApplicationName]);
      // console.log("request for autocomplete ->", JSON.stringify(request));
      let responses = {};
      const response = await getListAxios.post('core/invokeWorkflowEvent', request);
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
        console.log("Response Failed")
        triggerToast("Response Failed")
      }
      else{
      responses = JSON.parse(JSON.stringify(response.data));
      // console.log("resposnes ->", JSON.stringify(responses));
      ResultSet = await responses[component.PlaceHolderText];

      itemparams = itemtip.split(",");
      let Name = itemparams[0].split("~");
      ResultSet && ResultSet.map((result) => {
        if (result.hasOwnProperty(Name[0])) {
          DataSet[Name[0]] = result[Name[0]];
          DataSet[Name[1]] = result[Name[0]];
          Object.entries(result).map(([key, value]) => {
            if (key.includes(component.PlaceHolderText)) {
              if (Array.isArray(value)) {
                value.map((keyvalue) => {
                  Object.entries(keyvalue).map(([itemkey, itemvalue]) => {
                    DataSet[key + "_" + itemkey] = itemvalue;
                  })
                })
              }
              else {
                DataSet[key] = value;
              }
            }
            else {
              if (Array.isArray(value)) {
                value.map((keyvalue) => {
                  Object.entries(keyvalue).map(([itemkey, itemvalue]) => {
                    DataSet[component.PlaceHolderText + "_" + key + "_" + itemkey] = itemvalue;
                  })
                })
              }

              else {
                DataSet[component.PlaceHolderText + key] = value;
              }
            }
          })
          DataList.push(DataSet);
          DataSet = {};
        }
      })
      var Details = JSON.stringify(DataList);
      if (active) {
        setOptions(JSON.parse(Details));
      }
    }
    })();
    return () => {
      active = false;
    };
  }, [loading]);


  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  React.useEffect(() => {
    if (props.componentvalue !== undefined && props.componentvalue !== "") {
      setValue({ Name: props.componentvalue });
      setInputValue(props.componentvalue);
    }
    else {
      setValue({ Name: '' });
      setInputValue('');
    }
  }, [props.componentvalue])

  ValidatorForm.addValidationRule('IsEmpty', (value) => {
    // console.log('Validation Triggered',value)
    if (!value) {
      return false;
    }
    return true;
  })
  let autocomplete="off";
  let custom3=component.Custom3 && JSON.parse(component.Custom3);
  (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

  let isRequired = component.IsRequired === "Y" ? true : false;
  const validations = [];
  const errorMessages = [];
  let componentLabel = isRequired ? props.componentLabel + ' *' : props.componentLabel;
  if (isRequired) {
    validations.push('required');
    errorMessages.push(component.RequiredMessage);
    validations.push('IsEmpty');
    errorMessages.push(component.RequiredMessage);
  }

  return (

    <Grid container xs={12} sm={12} md={12} lg={12} key={component.AttributeName} className={component.AttributeStyleClass + (component.AdjacentLabel !== "Y" ? "labelrightalignment": "")} style={{ padding: '12px', paddingTop: '0px' }}>
      <Grid item xs={12} md={12} lg={7} sm={12} >
      {component.AdjacentLabel === "Y" && <Typography className="custom-label-down labelstyle">{componentLabel}</Typography>}
        <Autocomplete style={{ display: "inline-flex", width: "85%" }}
          className="custom-autocomplete"
          autoComplete={autocomplete}
          disabled={component.IsReadonly}
          id={component.AttributeName}
          value={valuee}
          freeSolo
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          inputValue={inputValue}
          onChange={(event, newValue) => { props.handleAutoCompletechange(newValue, itemtip, component); setValue(newValue); }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) => option.Name}
          options={options}
          loading={loading}
          renderInput={(params) =>
            <TextValidator {...params}
              placeholder={component.ComponentLabel}
              value={inputValue}
              className="custom-input"
              name={component.AttributeName}
              // label={<Typography className="custom-label-down">{component.ComponentLabel}</Typography>}
              label={component.AdjacentLabel !== "Y" && <Typography className="custom-label-down">{componentLabel}</Typography>}
              margin="normal"
              validators={validations}
              errorMessages={errorMessages}
              fullWidth
              autoComplete={autocomplete}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {/* params.InputProps.endAdornment */}
                    {showSearchIcon && <IconButton> <SearchIcon /> </IconButton>}
                  </React.Fragment>
                )
              }}
            />}
        />

        {valuee !== null && valuee.Name !== "" &&
          <IconButton className="custom-svg-icon InfoiconIEOverride" aria-label="add an alarm" style={{ transform: "rotate(180deg)", color: '#20a8d8', fontSize: '1rem !important' }} onBlur={() => { setShowlist(false) }} onClick={() => { getdetails(!showlist); valuesList(component); }} >
            <ErrorIcon />
          </IconButton>
        }

      </Grid>
      <Grid item xs={6} md={6} lg={5} sm={6} >

        {
          showlist ?
            <Card style={{ position: 'absolute', marginTop: '0', zIndex: '1', border: '0.1px solid gainsboro', boxShadow: '1px 1px gray', minWidth: '230px' }}>
              <CloseIcon style={{ float: 'right', paddingRight: '5px', paddingTop: '5px', fontSize: '20px' }} onClick={() => { setShowlist(false) }} />
              <CardContent>
                {
                  selectedvalue != null ?
                    Object.keys(selectedvalue).map(function (key) {
                      return (
                        <div style={{ marginBottom: '5px' }}><p style={{ fontWeight: 'bold', marginBottom: '0px' }}>{key}</p>
                          <p style={{ marginBottom: '0px' }}>{selectedvalue[key]}</p></div>)
                    })
                    : null
                }
              </CardContent>
            </Card>
            :
            null
        }
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => ({
  workflowReducer: state.workflowReducer,
  metadataReducer: state.metadataReducer
});

const mapDispatchToProps = dispatch => {
  return {
    setTransactionRequest: request => dispatch(setTransactionRequest(request))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Asynchronous);
