import React, { Component } from "react";
import { CircularProgress, Grid, InputLabel, Typography } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputAdornment from '@material-ui/core/InputAdornment';
import { renderIcon } from '../utils/LOB_Util/AU/VIN_Validator';
import { connect } from "react-redux";
import { LoaderComponent } from "../utils/Common/CommonUtils";
class Text extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" }
    this.labelRef = React.createRef();
  }

  componentDidMount = () => {
    if (false) {
      const { component } = this.props;
      let label = unescape(component.ComponentLabel);
      const node = this.labelRef.current;
      node.innerHTML = label;
    }
    if (this.props.component.DefaultValue !== "" && this.props.componentvalue === undefined) {
      this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component, this.props);
    }
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("MaxLength", (value,maxlength) => {
      if (value.length>maxlength) {
        return false;
      }
      return true;
    });

  };

  componentDidUpdate(prevProps) {
    //this.props.componentvalue === undefined Removed this condition as it got triggered in when we add text field to update component and it is setting default value from tpo which is from previous state value.
    if (this.props.component.DefaultValue !== "" && prevProps.component.DefaultValue !== this.props.component.DefaultValue ){
      this.props.updateValueWithSubplicationName && this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component, this.props, prevProps);
    }
  }
  onChange = (e) => {
    e.target.value = e.target.value && e.target.value.trim() === "" ? "" : e.target.value;
    if (this.props.customTable) {
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      // console.log("Calling handleRowLevelInputChange",e.target.name, value[e.target.name]);
      this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex, this.props);
    } else if(this.props.component.OnChangeAction!=='' && this.props[this.props.component.OnChangeAction]) {
        let value = Object.assign({}, { [e.target.name]: e.target.value });
        this.props[this.props.component.OnChangeAction](value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
      } else {
       let value = Object.assign({}, { [e.target.name]: e.target.value });
       this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
      }
    }
  onFocus = (e) => {
    this.setState({ value: e.target.value })
  }
  onBlur = (e) => {
    if (this.state.value !== e.target.value && (this.props.component.OnBlurAction === "callMetadata") && this.props.callback) {
      this.props.callback()
    }
    else if (this.props.component.OnBlurAction!=='' ){
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      this.props.component.OnBlurAction && this.props[this.props.component.OnBlurAction] &&  this.props[this.props.component.OnBlurAction](value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
    }
  }
  onKeyDown = (e) =>{
    if(this.props.component.OnKeyDownAction!=='' ){
      this.props[this.props.component.OnKeyDownAction] && this.props[this.props.component.OnKeyDownAction](e,this.props);
    }
    else {
      this.props.onKeyDown && this.props.onKeyDown(e,this.props);
     }
  }
  render() {
    const { component, isDynamicTable, datatableRowindex, pagelevelValues, variant } = this.props;
    let componentvalue = this.props.componentvalue;
    let {Action={}}=component
    let customProp;
    let isRequired = component.IsRequired === "Y" ? true : false;
    let isReadOnly = component.IsReadonly;
    let componentLabel = "" ;
    if(Object.keys(Action).length>0){
      customProp= Action.ComponentProperties ? Action.ComponentProperties : ''

     }
     let properties = {...customProp}
    if(component.ComponentLabel){
      componentLabel = isRequired ? component.ComponentLabel + " *" : component.ComponentLabel;
    }
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
    // using Translated Label
    /* componentLabel = (this.props.componentLabel !== "" && this.props.componentLabel !== undefined)
      ? (isRequired ? this.props.componentLabel + " *" : this.props.componentLabel)
      : (isRequired ? component.ComponentLabel + " *" : component.ComponentLabel); */

    const validations = [];
    const errorMessages = [];
    let ValidatorMessage = component.ValidatorMessage
    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
      validations.push("IsEmpty");
      errorMessages.push(component.RequiredMessage);
    }

    if(component.MaxLength!=0)
   {
      validations.push('MaxLength:' + component.MaxLength);
      errorMessages.push(ValidatorMessage);
   }
   if (component.ValidationRegex) {
    validations.push('matchRegexp:'+ component.ValidationRegex);
    errorMessages.push(ValidatorMessage);
   }
    // Validation for checking the minimum and maximum Limit of value
    if(component.MinMaxValidationMessage && (component.MaxValue > 0 || (component.MinValue!=="" && component.MinValue != 0)))
    {
      ValidatorMessage = component.MinMaxValidationMessage.replace("{MIN_VALUE}",component.MinValue).replace("{MAX_VALUE}",component.MaxValue)
    }
    if (component.MaxValue > 0) {
      validations.push('maxFloat:' + component.MaxValue);
      errorMessages.push(ValidatorMessage);
    }
    if(component.MinValue!=="" && component.MinValue != 0){
      validations.push('minFloat:' + component.MinValue);
      errorMessages.push(ValidatorMessage);
    }

    let value = "";
    if (component.DefaultValue === "" && componentvalue !== undefined) {
      value = componentvalue;
    }
    if (component.DefaultValue !== "" && componentvalue !== undefined) {
      value = componentvalue;
    }
    if (component.DefaultValue !== "" && componentvalue === undefined) {
      value = component.DefaultValue;
    }
    if (component.DefaultValue === "" && componentvalue === undefined) {
      value = "";
    }
    if (
      component.DefaultValue !== "" &&
      component.AttributeValue === "" &&
      componentvalue !== undefined
    ) {
      // console.log("came in 5");
      value = componentvalue;
    }

    //if (value !== "0")   // clear the leading zeros
    //value = value.replace(/^0+/, '');
    let endAdnt = renderIcon({ component, data : pagelevelValues ? pagelevelValues[component.SubApplicationName] : {} })
    if(component.OutputFormatPattern==="percentage"){
      endAdnt=<React.Fragment>
          <InputAdornment position="end">%</InputAdornment>
          {endAdnt}
      </React.Fragment>
    }
    let styleObject = {};
    if(component.AdjacentLabel === 'Y'){
      styleObject = {display:'flex'};
    }
    const [Loader, isLoading] = LoaderComponent(<CircularProgress className='field-loader'></CircularProgress>, this.props)

    if(endAdnt){
      endAdnt = (<>
    {endAdnt}
    {Loader}
    </>)
      }else{
        endAdnt=Loader
      }

      if(isLoading) isReadOnly = true;


    return (
      <>
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size}  className={`${component.AdjacentLabel !== 'Y' ? 'custom-text' : null} ${component.AttributeStyleClass}`} style={styleObject}>
            {
            component.AdjacentLabel === 'Y' ?
            <>
              <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              className={`${component.AttributeStyleClass}_Label`}
              >
                <InputLabel htmlFor={component.AttributeName} className="custom-label-down text-font adjacent_label" ref={this.labelRef}>
                  {componentLabel}
                </InputLabel>
              </Grid>
              <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
              >
                <TextValidator
                name={component.AttributeName}
                value={value}
                defaultValue={value}
                id={component.AttributeName}
                className={`custom-input ${component.StyleClass} lineHeight`}
                onFocus={(e) => this.onFocus(e)}
                onChange={(e) => this.onChange(e)}
                onBlur={(e) => this.onBlur(e)}
                onKeyDown={(e) => this.onKeyDown(e)}
                disabled={isReadOnly}
                validators={validations}
                errorMessages={errorMessages}
                variant={variant}
                fullWidth
                InputProps={{
                  endAdornment: endAdnt
                }}
                autoComplete={autocomplete}
                />
              </Grid>
            </>
            :
            <TextValidator
            name={component.AttributeName}
            placeholder={componentLabel.replace(/&nbsp;/g, '')}
            label={
              !this.props.isDynamicTable && (
                <Typography className="custom-label-down small_label text-font" style={styles.label} ref={this.labelRef}>
                  {componentLabel}
                </Typography>
              )
            }
            value={value}
            defaultValue={value}
            className={`custom-input ${component.StyleClass} lineHeight`}
            onFocus={(e) => this.onFocus(e)}
            onChange={(e) => this.onChange(e)}
            onBlur={(e) => this.onBlur(e)}
            onKeyDown={(e) => this.onKeyDown(e)}
            disabled={isReadOnly}
            validators={validations}
            errorMessages={errorMessages}
            variant={variant}
            fullWidth
            InputProps={{
              endAdornment: endAdnt
            }}
            autoComplete={autocomplete}
            />
            }
        </Grid>

      { this.props.children &&
       <Grid item {...properties}>
       {this.props.children}
       </Grid>}
       </>
        );
  }
}

const styles = {
  label: {
    width: '150%'
  }
};

const mapStateToProps = (state) => {
  return{
    UIFlowReducer: state.UIFlowReducer
  }
}

export default connect(mapStateToProps)(Text);
