import submitbutton from "./SubmitButton";
import actionbutton from './ActionButton';
import multicheckboxpopper from "./MultiCheckBoxPopper";
import floaterprevious from './FloaterPrevious'

const NavigationControls = {
    actionbutton,
    submitbutton,
    multicheckboxpopper,
    floaterprevious 
}

export default NavigationControls;