import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";

export default class Subheading extends Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
  }
  
  componentDidMount() {
    if (false) {
      const { component } = this.props;
      let label = unescape(component.ComponentLabel);
      const node = this.labelRef.current;
      node.innerHTML = label;
    }
    let { component } = this.props;
  component.OnKeyUpAction && this.props[component.OnKeyUpAction] && this.props[component.OnKeyUpAction](this.props,this.state);
  }

  render() {
    const { component } = this.props;
    //let className = "col-xs-11 col-sm-12 col-md-12 col-lg-12 " + component.StyleClass
    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          sm={12}
          md={component.Size > 0 ? component.Size : 12}
          lg={component.Size > 0 ? component.Size : 12}
          key={component.AttributeName}
          className={`${component.AttributeStyleClass} large_heading`}
        >
          {
            component.IsRequired === 'Y' ?
              <Typography
                ref={this.labelRef}
                variant="h6"
                className={`MuiTypography-h6 ${component.StyleClass}`}
                
              >
                <span dangerouslySetInnerHTML={{ __html: component.ComponentLabel }}></span> 
              </Typography> :
              <Typography
                ref={this.labelRef}
                variant="h6"
                className={`MuiTypography-h6 ${component.StyleClass}`}
                
              >
                <span dangerouslySetInnerHTML={{ __html: component.ComponentLabel }}></span>
              </Typography>
          }
          <Grid item
          xs={12}
          sm={12}
          md={component.Size > 0 ? component.Size : 12}
          lg={component.Size > 0 ? component.Size : 12}>
                        {this.props.children}
           </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
