import React, { Component } from "react";
import { CircularProgress, FormControl, Grid, InputLabel } from "@material-ui/core";
import SelectDropdownValidatorV2 from "./SelectValidatorV2";
import { attributeSplit } from '../utils/Common/MetaUtils';
import { ValidatorForm } from "react-material-ui-form-validator";
import { commonSelectMethod } from "./ButtonUtil";
import { LoaderComponent } from "../utils/Common/CommonUtils";
class SelectV2 extends Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
    this.state = {
      selectAttributeParams: {}
    }
  }

  async componentDidMount() {

    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
    const { component } = this.props;
    if (false) {

      let label = unescape(component.ComponentLabel);
      const node = this.labelRef.current;
      node.innerHTML = label;
    }


    const {dynSubApplicationAttributeValueList,...OnKeyUpActionResponse} =  component.OnKeyUpAction && this.props[component.OnKeyUpAction]
     && await this.props[component.OnKeyUpAction](this.props)
     this.setState({
      dynSubApplicationAttributeValueList
     })

    let options =(this.props.responseList)?this.props.responseList:this.props.component.SubApplicationAttributeValueList;
    // let options = this.props.component.SubApplicationAttributeValueList;
    let defaultOption = options.find((option) => {
      if ((option.Value && this.props.componentvalue) && (option.Value.trim()=== this.props.componentvalue.trim())) {
        return true;
      } else return false;
    });



    // if (  this.props.component.SubApplicationAttributeValueList !== [] &&
    //       this.props.component.SubApplicationAttributeValueList.length === 2 &&
    //       this.props.component.SubApplicationAttributeValueList[0].DisplayValue === "Select" )
    // {
    //     if (this.props.customTable)
    //     {
    //       let value = Object.assign({}, { [this.props.component.AttributeName]: this.props.component.SubApplicationAttributeValueList[1].Value });
    //       this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex);
    //     }
    //     else
    //     {
    //       let value = Object.assign({}, { [this.props.component.AttributeName]: this.props.component.SubApplicationAttributeValueList[1].Value });
    //       this.props.handleHiddenInputChange(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
    //     }
    // }

    if (!this.props.customTable) {
      if ((this.props.component.DefaultValue !== "" || (this.props.componentvalue && (this.props.componentvalue !==this.props.component.DefaultValue )))  && !defaultOption) {
        this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
      }
    }
    if(this.props.component.AttributeParams != "" && this.props.component.AttributeParams != null){
      let attrParams = attributeSplit(this.props.component.AttributeParams)
      this.setState({ selectAttributeParams : attrParams });
    }

  }

  componentDidUpdate(prevProps) {

    // let options = this.props.component.SubApplicationAttributeValueList;
    // let defaultOption = options.find((option) => {
    //   if ((option.Value && this.props.componentvalue) && (option.Value.trim() === this.props.componentvalue.trim())) {
    //     return true;
    //   } else return false;
    // });
    // if ( this.props.component.SubApplicationAttributeValueList.length === 2 &&
    //      this.props.component.SubApplicationAttributeValueList[0].DisplayValue === "Select" &&
    //      this.props.attributeValue !== this.props.component.SubApplicationAttributeValueList[1].Value )
    // {
    //     if (this.props.customTable)
    //     {
    //       let value = Object.assign({}, { [this.props.component.AttributeName]: this.props.component.SubApplicationAttributeValueList[1].Value });
    //       this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex);
    //     }
    //     else
    //     {

    //       //this.props.handleHiddenInputChange( this.props.component, this.props.isDynamicTable,this.props.datatableRowindex);
    //       let value = Object.assign({}, { [this.props.component.AttributeName]: this.props.component.SubApplicationAttributeValueList[1].Value });
    //       this.props.handleHiddenInputChange(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
    //     }
    // }

    // if (!this.props.customTable) {
    //   if (this.props.component.DefaultValue !== "" && this.props.componentvalue !== this.props.component.DefaultValue) {
    //     this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
    //   }
    // }
    if (!this.props.customTable) {

      if (
        this.props.componentvalue&&prevProps.componentvalue&&
        this.props.componentvalue !== prevProps.componentvalue)
      this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.componentvalue }, this.props.component);
      else if (this.props.component.DefaultValue!==prevProps.component.DefaultValue && this.props.componentvalue!==this.props.component.DefaultValue ) {
        this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
      }
    }


  }

  onChange = (e) => {
    if(this.props.component.OnChangeAction){
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      this.props[this.props.component.OnChangeAction] && this.props[this.props.component.OnChangeAction](value,this.props.component, this.props.isDynamicTable, this.props.datatableRowindex,this.props)
      if(this.props.pageInfo && this.props.pageInfo.selectUtil && this.props.pageInfo.selectUtil[this.props.component.OnChangeAction]) {
        this.props.pageInfo.selectUtil[this.props.component.OnChangeAction](this.props, value);
      }
      else {
        // this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex,this.props); // to be removed
      }
    } else if(this.props.component.Action && Object.keys(this.props.component.Action).length > 0){
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      commonSelectMethod(this.props.component, value, this.props)
    } else if (this.props.customTable && this.props.component.OnChangeAction === '') {
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex, this.props);
    }
    else {
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      this.props.handleInputChangeV2 && this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex,this.props);
    }
  }

  render() {
    let { component, isDynamicTable, datatableRowindex, componentvalue, variant } = this.props;
    let {Action={}}=component
    let customProp;
    if(Object.keys(Action).length>0){
     customProp= Action.ComponentProperties ? Action.ComponentProperties : ''

    }
    let properties = {...customProp}
    let { selectAttributeParams : { displayEmpty = false } } = this.state;
    displayEmpty = displayEmpty === "true" ? true : false;
    const options =this.state.dynSubApplicationAttributeValueList||component.SubApplicationAttributeValueList;
    if (component.SubApplicationAttributeValueList.length >= 2 && component.SubApplicationAttributeValueList[0].DisplayValue === 'Select') {
      options = component.SubApplicationAttributeValueList.slice(1);
    }
    let isRequired = component.IsRequired === "Y" ? true : false;
    const validations = [];
    const errorMessages = [];

    let labelClassName = component.IsRequired === "Y" ? "mandatory attributeLabel" : "attributeLabel";
    if (isDynamicTable) {
      componentvalue = this.props.componentvalue;
    }
    let labelId = component.ComponentId + "_label";
    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
      validations.push("IsEmpty");
      errorMessages.push(component.RequiredMessage);
    }

    let attributeValue = '';
    if (this.props.transactionValue !== undefined) {
      const result = options.find((option) => option['Value'] && option['Value'].trim() === this.props.transactionValue.trim());
      attributeValue = (result && result['Value']) ? result['Value'] : '';
    }

    if(!attributeValue && component.DefaultValue && !displayEmpty) {
      const result = options.find((option) => option['Value'] && option['Value'].trim() === component.DefaultValue.trim());
      attributeValue = (result && result['Value']) ? result['Value'] : '';
    }

    if (this.props.transactionValue === undefined && component.DefaultValue) {
      const result = options.find((option) => option['Value'] && option['Value'].trim() === component.DefaultValue.trim());
      attributeValue = (result && result['Value']) ? result['Value'] : '';
    }
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    const [Loader, isLoading] = LoaderComponent(<CircularProgress className='field-loader'></CircularProgress>, this.props)

    let isDisabled=component.IsReadonly;
    if(isLoading) isDisabled = true;


    return (
      <>
      {component.AdjacentLabel === 'Y' ?  
        <Grid       
        className={component.StyleClass+ 'lineHeight custom-selectContainer'}
        item
          xs={12}
          sm={12}
          md={isDynamicTable ? 12 : component.Size}
          lg={isDynamicTable ? 12 : component.Size}
          key={component.AttributeName}
        >
          <Grid
          item
          xs={4}
          sm={4}
          md={isDynamicTable ? 12 : 4}
          lg={isDynamicTable ? 12 : 4}
          >
            {!isDynamicTable && (
            <InputLabel
              ref={this.labelRef}
              id={labelId}
              className={`text-font adjacent_label ${labelClassName} `}
              shrink={displayEmpty || undefined}
              htmlFor={component.AttributeName}
            >
              {component.ComponentLabel}
            </InputLabel>
            )}
          </Grid> 
          <Grid
          item
          xs={8}
          sm={8}
          md={isDynamicTable ? 12 : 8}
          lg={isDynamicTable ? 12 : 8}
          className="adjacent_TextValidator_Grid"
          >
          <SelectDropdownValidatorV2
            labelId={labelId}
            ComponentId={component.ComponentId}
            name={component.AttributeName}
            displayEmpty={displayEmpty}
            value={attributeValue}
            options={options}
            variant={variant}
            component={component}
            onChange={this.onChange}
            errorMessages={errorMessages}
            validators={validations}
            autoComplete={autocomplete}
          />
          </Grid> 
        </Grid>
        :
        <Grid
          className={(component.StyleClass + 'lineHeight custom-selectContainer')}
          item
          xs={12}
          sm={12}
          md={isDynamicTable ? 12 : component.Size}
          lg={isDynamicTable ? 12 : component.Size}
          key={component.AttributeName}
      >
        <FormControl className={`custom-dropdown custom-dropdown-select custom-dropdown-selectv2 ${component.AttributeStyleClass} `}>
          {(!isDynamicTable && (
            <InputLabel
              ref={this.labelRef}
              id={labelId}
              className={`custom-dropdown-label text-font small_label  ${variant === "outlined" && 'selectOutlinedLabel'} ${labelClassName} `}
              shrink={displayEmpty || undefined}
            >
              {component.ComponentLabel}
            </InputLabel>
          ))}
          <SelectDropdownValidatorV2
            labelId={labelId}
            ComponentId={component.ComponentId}
            name={component.AttributeName}
            displayEmpty={displayEmpty}
            value={attributeValue}
            options={options}
            variant={variant}
            component={component}
            onChange={this.onChange}
            errorMessages={errorMessages}
            validators={validations}
            autoComplete={autocomplete}
            loaderNeeded={isLoading}
            LoaderComponent={Loader}
            isDisabled={isDisabled}
          />
        </FormControl>
        </Grid>}
      { this.props.children &&
      <Grid item {...properties}>
      {this.props.children}
      </Grid>}
      </>
    );
  }
}

const styles = {
  selectContainer: {
    display: "flex",
  },
  dropdown: {
    minWidth: "100%",
    alignSelf: "flex-end"
  },
  label: {
    width: "100%",
    position: "relative",
    marginBottom: "-20px"

  },
};

export default SelectV2;
