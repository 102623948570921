import { AxiosInstance,getServiceRequestDetail } from "../Common/API";
import {format} from 'date-fns';
import store from "../../../../store";

/*
    * Method which will construct the Map from applicationService & WorkFlowService Response's.
    * @param WorkFlowResponse, ApplicationServiceResponse, Other Props
    * @return pagelevelValues Map
*/

export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? `${prefix}.` : '';
      if ( obj[k].constructor === Object)
        Object.assign(acc, flattenObject(obj[k], pre + k));
      else
        acc[pre + k] = obj[k];
      return acc;
}, {});

export const EntitySubAppTranslation =  (response, props, pagelevelValues = {}, isRowdata, DetailName, metaDataResponse, additionalList = [], entityMapping) => {

    let subappList = [];
    let EntitysubAppmaping = entityMapping || {};
    let additionalSubAppMapping = {};
    let entityHierarchyName = "";
   // const metadataRes = metaDataResponse ? metaDataResponse : isRowdata === 'StateResponse' ? props.metadataReducer.stateMetadataresponse : JSON.parse(props.metadataReducer.metadataResponse)
    if (EntitysubAppmaping) {
      //  const subAppDetailList = metadataRes.ApplicationDetail.SubApplicationDetailList;

        /*
            * Block which will construct the Map against EntityName & SubApplication Name from applicationService.
            * @return Map
        */
        // if (subAppDetailList.length > 0 && Object.keys(EntitysubAppmaping).length == 0) {
        //     subAppDetailList.map((subApplication) => {
        //         entityHierarchyName = subApplication.EntityHierarchyName !== "" ? subApplication.EntityHierarchyName : subApplication.ApplicationName;
        //         console.log("EntitysubAppmaping===========================>",EntitysubAppmaping[entityHierarchyName]);
        //         EntitysubAppmaping[entityHierarchyName] = subApplication.ApplicationName;
        //         if (!subappList.includes(subApplication.ApplicationName)) {
        //             subappList.push(subApplication.ApplicationName);
        //         }
        //     })
        // }

        /*
            This block will return the additionalSubAppMapping.
            key - FieldName
            value - SubApplicationName
        */
        if(additionalList.length > 0){
            additionalList.forEach(obj => {
                additionalSubAppMapping[obj.SubApplication_FieldTableName] = obj.SubApplicationName
            })
        }

        /*
            * Block which will construct the PageLevelValues Map from the WorkFlowService Response.
            * @params  WorkFlowService Response.
            * @return  PageLevelValues Map.
        */
        Object.entries(response).map(([entity, objValue]) => {
            // if(EntitysubAppmaping[entity]){
            if (isRowdata === 'Yes') {
                if (!EntitysubAppmaping[entity] && typeof objValue === 'object' && objValue !== null && !(Array.isArray(objValue))) {
                    Object.entries(objValue).map(([dotKey, dotValue]) => {
                        if (dotValue && dotValue !== null && dotValue !== undefined) {
                            let spclKey = entity + '.' + dotKey;
                            if (!(pagelevelValues.hasOwnProperty(DetailName))) {
                                pagelevelValues[DetailName] = {}
                            }
                            pagelevelValues[DetailName][spclKey] = dotValue;
                        }

                    })

                }
            }
            if(objValue.hasOwnProperty('SolartisCoverageDisplayName')){
                let additionalSubAppName = additionalSubAppMapping[objValue.SubApplicationFieldTableName]
                pagelevelValues[additionalSubAppName] = objValue
            }
            if (EntitysubAppmaping[entity] && typeof objValue === 'object' && objValue !== null && !(Array.isArray(objValue))) {
                Object.entries(objValue).map(([key, value]) => {
                    if (objValue.constructor === Object && EntitysubAppmaping[entity]) {
                        if (!(pagelevelValues.hasOwnProperty(EntitysubAppmaping[entity]))) {
                            pagelevelValues[EntitysubAppmaping[entity]] = {}
                        }
                        if (value.constructor === Object && value !== null) {
                            Object.entries(value).map(([dotKey, dotValue]) => {
                                let spclKey = key + '.' + dotKey;
                                pagelevelValues[EntitysubAppmaping[entity]][spclKey] = dotValue;
                            })
                        } else {
                            if (pagelevelValues[EntitysubAppmaping[entity]]) {
                                pagelevelValues[EntitysubAppmaping[entity]][key] = value;
                            }
                        }
                    }
                })

            } else if (Array.isArray(objValue) && objValue.length > 0) {
                let objectval = {}
                let temp = false
                Object.entries(objValue[0]).map(([keys, values]) => {
                    if (!Array.isArray(values)) {
                        if (!(pagelevelValues.hasOwnProperty(EntitysubAppmaping[entity]))) {
                            pagelevelValues[EntitysubAppmaping[entity]] = {}
                        }
                        objectval[keys] = values
                    }
                    pagelevelValues[EntitysubAppmaping[keys]] = []
                    if (Array.isArray(values) && EntitysubAppmaping[keys]) {
                        temp = true
                        values.map(el => {
                            let obj = {};
                            Object.entries(el).map(([key, value]) => {
                                if (typeof value === 'object' && value !== null) {
                                    Object.entries(value).map(([dotKey, dotValue]) => {
                                        let spclKey = key + '.' + dotKey;
                                        obj[spclKey] = dotValue;
                                    })
                                } else {
                                    obj[key] = value;
                                }
                            })
                            pagelevelValues[EntitysubAppmaping[keys]].push(obj);
                        })
                    }
                })
                pagelevelValues[EntitysubAppmaping[entity]] = objectval
                if(!temp){
                    pagelevelValues[EntitysubAppmaping[entity]] = objValue
                }
            } else {
                pagelevelValues[EntitysubAppmaping[entity]] = objValue;
            }
            // }
        })
    }
    if (isRowdata === "Yes" && Object.keys(response).length > 5) {
        if(pagelevelValues[DetailName])
        {
            Object.keys(pagelevelValues[DetailName]).map((key) => {
                if (key.includes('.')) {
                    let splitVal = key.split('.')
                    if (response.hasOwnProperty(splitVal[0])) {
                        delete response[splitVal[0]]
                    }
                }
            })
        }
        pagelevelValues[DetailName] = { ...pagelevelValues[DetailName], ...response }
    }

    // console.log("[karthu] paagelevel val", pagelevelValues)
    return pagelevelValues
}

export const getEntitySubAppMapping = async (props) => {
    let getEntityV1Request = constructGetEntityV1Request(props);
    try{
        let {data} = await AxiosInstance.post("core/getEntity/V1", getEntityV1Request)
        if(data && data.EntitySubApplication){
            return data.EntitySubApplication;
        }
    }catch (err){
        console.error(err);
    }
    return null;
}

const constructGetEntityV1Request = (props) => {
    const reduxState = store.getState();
    let { OwnerId, objectId, objectVerId, subApplication = [], subApplicationGroupList = [], State = "CW" } = props;

    let getEntityMappingRequest = Object.assign(
        {},
        require("../../models/getEntityV1.json"),
        {
            ServiceRequestDetail: getServiceRequestDetail(),
            OwnerId: OwnerId,
            ObjectId: objectId,
            ObjectVerId: objectVerId,
            State,
            SubApplicationNameList: subApplication,
            SubApplicationGroupNameList: subApplicationGroupList,
            EffectiveDate: format(new Date(), 'yyyy-MM-dd')
        })

        //  need to uncomment
    if (objectId && objectVerId) {
        let { clientInfo } = reduxState.ownerResources
        if(objectId==='Multi_Api')
        {
            getEntityMappingRequest.ObjectId=clientInfo.multiLob_ObjectId;
            getEntityMappingRequest.ObjectVerId=clientInfo.multiLob_ObjectVerId;
        }
        else if(objectId==='API'){
            getEntityMappingRequest.ObjectId=reduxState.UIFlowReducer.productDetails.ProductID;
            getEntityMappingRequest.ObjectVerId=reduxState.UIFlowReducer.productDetails.ProductVerID;
        }
        else{
            getEntityMappingRequest.ObjectId = objectId;
            getEntityMappingRequest.ObjectVerId = objectVerId;
        }

    } else {
        getEntityMappingRequest.ObjectId = localStorage.getItem("ObjectId");
        getEntityMappingRequest.ObjectVerId = localStorage.getItem("ObjectVerId");
    }
    return getEntityMappingRequest;
}

const constructGetEntityRequest = (subAppInfo) => {
  // state and effective date needs to be handled for more scenarios
  let { OwnerId, objectId, objectVerId, subappName = [], subApplicationGroupList = [], state = "CW" } = subAppInfo;
  let reduxState = store.getState();
  let { clientInfo } = reduxState.ownerResources
  if(subAppInfo.state === 'primaryState') subAppInfo.state = reduxState.UIFlowReducer.primaryState

  let getEntityMappingRequest = Object.assign({},require("../../models/getEntityV1.json"), {
      ServiceRequestDetail: getServiceRequestDetail(),
      OwnerId: OwnerId,
      ObjectId: objectId,
      ObjectVerId: objectVerId,
      State: subAppInfo.state,
      SubApplicationNameList: subappName,
      SubApplicationGroupNameList: subApplicationGroupList,
      EffectiveDate: format(new Date(), 'yyyy-MM-dd')
  })
  if(objectId === 'API'){
    getEntityMappingRequest.ObjectId=reduxState.UIFlowReducer.productDetails.ProductID;
    getEntityMappingRequest.ObjectVerId=reduxState.UIFlowReducer.productDetails.ProductVerID;
  }
  if(typeof objectId === "string" && objectId.toLowerCase() === "multi_api"){
    getEntityMappingRequest.ObjectId = clientInfo.multiLob_ObjectId;
    getEntityMappingRequest.ObjectVerId = clientInfo.multiLob_ObjectVerId;
  }

  return getEntityMappingRequest;
}

export const getEntitySubAppMappingV2 = async (subAppInfo, version = null) => {
  let getEntityV1Request = constructGetEntityRequest(subAppInfo);
  let urlVersion = version || "V1"
  try{
      let {data} = await AxiosInstance.post(`core/getEntity/${urlVersion}`, getEntityV1Request)
      if(data && data.EntitySubApplication){
          return data.EntitySubApplication;
      }
  }catch (err){
      console.error(err);
  }
  return null;
}

export const EntitySubAppTranslationV2 = async (apiResponse, props) => {

    const { SubApplicationEntity = null } = props;

    if(!SubApplicationEntity) return;

    let translatedResponse = {};

    for(const [key, value] of Object.entries(SubApplicationEntity)) {
        let checkKey = value ? value : key;
        if(apiResponse[checkKey] && typeof apiResponse[checkKey] === 'object' && !Array.isArray(apiResponse[checkKey])) {
            translatedResponse[key] = flattenObject(apiResponse[checkKey])
        }
        else if(apiResponse[checkKey] && key.includes('Summary') && Array.isArray(apiResponse[checkKey])) {
            translatedResponse[key] = apiResponse[checkKey].map((element) => {
                let result = flattenObject(element);
                return result;
            });
            translatedResponse[`${key.toLowerCase()}_dataList`] = translatedResponse[key].slice();
        if(apiResponse.hasOwnProperty('Count')) translatedResponse[`${key.toLowerCase()}_dataListSize`] = apiResponse.Count;
        }
        else if(apiResponse[checkKey]) {
            translatedResponse[key] = flattenObject(apiResponse[checkKey]);
        }
    }

    // let entitySubAppMapping = await getEntitySubAppMapping(props);
    // let translatedResponse = {};
    // Object.keys(apiResponse).forEach((key) => {
    //     if(entitySubAppMapping && entitySubAppMapping[key] && typeof apiResponse[key] === 'object' && !Array.isArray(apiResponse[key])) {
    //         translatedResponse[entitySubAppMapping[key]] = flattenObject(apiResponse[key])
    //     }
    //     else if(Array.isArray(apiResponse[key])) {
    //         translatedResponse['autosleasedhiredrentedorborrowedwithdriversphysicaldamagecoveragesummary_dataList'] = apiResponse[key].map((element) => {
    //             let result = flattenObject(element);
    //             return result;
    //         });
    //         translatedResponse['AutosLeasedHiredRentedOrBorrowedWithDriversPhysicalDamageCoverageSummary'] = translatedResponse['autosleasedhiredrentedorborrowedwithdriversphysicaldamagecoveragesummary_dataList'].slice();
    //     }
    //     else {
    //         translatedResponse[key] = flattenObject(apiResponse[key])
    //     }
    // })

    return translatedResponse;
}

export const EntitySubAppTranslationV3 = async (apiResponse, props) => {

  const { SubApplicationEntity = null } = props;

  if(!SubApplicationEntity) return;

  let translatedResponse = {}, translatedList = {};

  for(const [key, value] of Object.entries(SubApplicationEntity)) {
      let checkKey = value ? value : key;
      if(apiResponse[checkKey] && typeof apiResponse[checkKey] === 'object' && !Array.isArray(apiResponse[checkKey])) {
          translatedResponse[key] = flattenObject(apiResponse[checkKey])
      }
      else if(apiResponse[checkKey] && Array.isArray(apiResponse[checkKey])) {
          translatedList[`${key.toLowerCase()}_dataList`] = apiResponse[checkKey].map((element) => {
              let result = element;
              return result;
          });
          if(apiResponse.hasOwnProperty('Count')) translatedResponse[`${key.toLowerCase()}_dataListSize`] = apiResponse.Count;
      }
      else if(apiResponse[checkKey]) {
          translatedResponse[key] = flattenObject(apiResponse[checkKey]);
      }
  }
    return { translatedResponse, translatedList };
}


