import React, {Fragment} from 'react';
import { Grid, IconButton, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";

function TableHeading(props) {
    let { tableUtils, TableOptions, subAppInfo } = props;

    let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
    let headerName = SubApplicationDetail.Description;
    let SubApplnName = SubApplicationDetail.ApplicationName.toLowerCase();

    return (
        <Fragment>
           <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: "inline-block" }} className='section_heading'>
                <Typography variant="h6" className="custom-subheading small_font heading-font">
                    {headerName}{TableOptions.AddButtonNeeded && (<IconButton  onClick={() => tableUtils.AddRow(SubApplnName+"_dataList",props)}> <AddCircle /></IconButton>)}
                </Typography>
            </Grid> 
        </Fragment>
    )
}

export default TableHeading
