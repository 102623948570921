import React, { cloneElement } from 'react';
import { connect } from 'react-redux';
import { dynamicConditionValidation  } from '../../../utils/Common/NoCodeUtils';

class ShowHideHOC extends React.Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }

    getComponent = (props) => {
        let show
        if(props.pageInfo.utilfunction && props.pageInfo.utilfunction.hasOwnProperty(props.pageInfo.method)){
           show =  props.pageInfo.utilfunction[props.pageInfo.method](props)
        }
        else if(props.pageInfo.conditions && props.pageInfo.conditions.length > 0){
            show = dynamicConditionValidation(props.UIFlowReducer, props.pageInfo.conditions);
        }
         else{
            if (props.rowData && props.rowData.ID) {
                let conditionKey = props.pageInfo.conditionKey + props.rowData.ID
                show = props.metadataReducer.showHideCondition[conditionKey]
            }
        }
        return show

    }

    render(){
        const showHideKey = this.getComponent(this.props)
        const childrenProps = {
            parentProps: this.props.parentProps
        }
        const childrenWithProps = React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
              return cloneElement(child, { ...childrenProps })
            }
            return child;
        });

        return (
            <React.Fragment>
                {
                    showHideKey && childrenWithProps
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => (
    {
        metadataReducer: state.metadataReducer,
        UIFlowReducer: state.UIFlowReducer
    }
)

export default connect(mapStateToProps)(ShowHideHOC);
