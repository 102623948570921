import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default class ValidationMessages extends Component {
  render() {
    const { dataList } = this.props;
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <List className='validationMessages'>
                {
                    dataList.map((data,index) =>{
                        return (
                            <ListItem key={index}>
                                <ListItemText primary={data.Message} disableTypography style={{color:"red"}} />
                            </ListItem>
                        )
                    })
                }
          </List>
        </Grid>


      </React.Fragment>
    )
  }
}
