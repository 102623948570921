import React, { Component } from 'react';
import { Input } from 'reactstrap';

class Hidden extends Component {

	 componentDidMount()
     {
        if(!this.props.updateValueWithSubplicationName) return;
        if(this.props.component.DefaultValue !== "" && this.props.componentvalue === undefined)
        this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
     }
     componentDidUpdate()
     {
         if(this.props.component.DefaultValue !== "" && this.props.componentvalue !== this.props.component.DefaultValue)
         {
            this.props.handleHiddenInputChange && this.props.handleHiddenInputChange(this.props.component);
         }
         else if(this.props.component.SubApplicationAttributeValueList.length === 2 
                 && this.props.component.SubApplicationAttributeValueList[0].DisplayValue === "Select"
                 && this.props.componentvalue !== this.props.component.SubApplicationAttributeValueList[1].Value)
                 {
                    this.props.handleHiddenInputChange && this.props.handleHiddenInputChange(this.props.component);
                 }
     }

    render() {
        const { component } = this.props;
        let attributeValue = this.props.componentvalue;
        return (
            <Input
                type="hidden"
                id={component.ComponentId}
                value={attributeValue}
            />
        );
    }
}

export default Hidden;
