import { AUTHENTICATE, GET_PRIVILEGES, LOGOUT, SET_CONFIGINFO, SET_FROM_SSO } from '../actions/types';

const initialState = {
    userName: '',
    token: '',
    authData: {},
    privileges: [],
    configInfo : {},
    fromSSO : false
}

export default function(state = initialState, action) {
    switch(action.type){
        case AUTHENTICATE:
            //console.log("iniside AUTHENTICATE reducer ", action.payload)
            return{
                ...state,
                authData: action.payload
            }
        
        case GET_PRIVILEGES:
            return{
                ...state,
                privileges: action.payload
            }
        
        case LOGOUT:
            return{
                ...state,
                authData: action.payload
            }
        case SET_FROM_SSO:
            return{
                ...state,
                fromSSO: action.payload
            }

        case SET_CONFIGINFO:
        {
            let configInfo = JSON.parse(JSON.stringify(state.configInfo));
            if(configInfo.hasOwnProperty('AMTDetails'))
            {
                let temp = Object.assign({}, configInfo['AMTDetails'], action.payload)
                configInfo['AMTDetails'] = temp;
            }
            else
            {
                configInfo['AMTDetails'] = action.payload;
            }
           
            return{
                ...state,
                configInfo
            }
        }

        default:
            return state;
    }
}