import React, { useState, useEffect } from "react";
import { FormControl, Grid, InputLabel } from "@material-ui/core";
import SelectFilterDropdownValidator from "./SelectFilterValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import { attributeSplit, attributeSplitV2 } from '../utils/Common/MetaUtils';
import { commonSelectMethod } from './ButtonUtil';

function DynamicSelectFilter(props) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        ValidatorForm.addValidationRule("IsEmpty", (value) => {
            if (!value) return false;
            return true;
        });

        const onLoad = async () => {
            if (!props.customTable) {
                if (props.component.DefaultValue)
                    props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.component.DefaultValue }, props.component);
            }

            if (props.component.AttributeParams != "" && props.component.AttributeParams != null) {
                let attrParams = attributeSplit(props.component.AttributeParams);
                if (attrParams['persistAttribute'] === "true" && props.hasOwnProperty("handlePersistValue")) {
                    props.handlePersistValue({ [props.component.AttributeName]: props.component.DefaultValue }, props.component);
                }
            }

            if (props.component.Action && Object.keys(props.component.Action).length > 0 && props.component.Action.onLoad) {
                await commonSelectMethod({ ...props.component, Action: props.component.Action.onLoad }, {}, props, false, stateFunctions);
            }
        };

        onLoad();
    }, []);

    useEffect(() => {
        if (!props.customTable)
            props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.componentvalue }, props.component);
    }, [props.componentvalue]);

    useEffect(() => {
        if (!props.customTable)
            props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.component.DefaultValue }, props.component);
    }, [props.component.DefaultValue]);

    useEffect(() => {
        const onLoadMethod = async () => {
            if (props.component.Action && Object.keys(props.component.Action).length > 0 && props.component.Action.onLoad) {
                await commonSelectMethod({ ...props.component, Action: props.component.Action.onLoad }, {}, props, false, stateFunctions);
            }
        };
        if (props.componentState[`${props.component.SubApplicationName}_${props.component.AttributeName}__Update`]) {
            onLoadMethod();
            props.functions.updateValues({
                "transactionPrimaryObject": { ...props.transactionPrimaryObject, ...props.transactionPrimaryObject[props.component.SubApplicationName][props.component.AttributeName] = '' },
                "pagelevelValues": { ...props.pagelevelValues, ...props.pagelevelValues[props.component.SubApplicationName][props.component.AttributeName] = '' },
                [`${props.component.SubApplicationName}_${props.component.AttributeName}__Update`]: false
            });
        }
    }, [props.componentState[`${props.component.SubApplicationName}_${props.component.AttributeName}__Update`]]);

    const onChange = (e, data) => {
        let { component } = props, newObj = {};
        if (data) {
            let dataValue = data.Value ? data.Value : ''
            newObj = { [component.AttributeName]: dataValue };
            if (component.Action && component.Action.AdditionalAttributes != "" && component.Action.AdditionalAttributes != null) {
                let attrParams = attributeSplitV2(component.Action.AdditionalAttributes);
                if (Object.keys(attrParams).length > 0) {
                    Object.keys(attrParams).forEach(key => {
                        newObj = data.hasOwnProperty(key) ? { ...newObj, [attrParams[key]]: data[key] } : newObj;
                    });
                }
            }
        } else {
            newObj = { [component.AttributeName]: "" };
            if (component.Action && component.Action.AdditionalAttributes != "" && component.Action.AdditionalAttributes != null) {
                let attrParams = attributeSplitV2(component.Action.AdditionalAttributes);
                if (Object.keys(attrParams).length > 0) {
                    Object.keys(attrParams).forEach(key => {
                        newObj = { ...newObj, [attrParams[key]]: "" };
                    });
                }
            }
        }

        if (props.component.Action && props.component.Action.onChange && Object.keys(props.component.Action.onChange).length > 0)
            commonSelectMethod({ ...props.component, Action: props.component.Action.onChange }, newObj, props);
        else if (props.customTable)
            props.handleRowLevelInputChange(newObj, props.component, props.customTableRowIndex);
        else {
            if (props.component.AttributeParams != "" && props.component.AttributeParams != null) {
                let attrParams = attributeSplit(props.component.AttributeParams);
                if (attrParams['persistAttribute'] === "true" && props.hasOwnProperty('handlePersistValue'))
                    props.handlePersistValue(newObj, props.component);
            }
            props.handleInputChangeV2(newObj, props.component, props.isDynamicTable, props.datatableRowindex);
        }
    };

    const stateFunctions = {
        setOptions
    };

    let { component, isDynamicTable, componentvalue, variant } = props;
    let isRequired = component.IsRequired === "Y" ? true : false;
    const validations = [], errorMessages = [];
    let attributeValue = "";

    if (isDynamicTable) componentvalue = props.componentvalue;

    if (isRequired) {
        validations.push("required");
        errorMessages.push(component.RequiredMessage);
        validations.push("IsEmpty");
        errorMessages.push(component.RequiredMessage);
    }

    if (props.transactionValue !== undefined) {
        const result = options.find((option) => option['Value'] !== undefined && option['Value'].trim() === props.transactionValue.trim());
        attributeValue = (result && result['Value'] !== undefined) ? result : '';
    }

    if (!attributeValue && component.DefaultValue !== undefined) {
        const result = options.find((option) => option['Value'] !== undefined && option['Value'].trim() === component.DefaultValue.trim());
        attributeValue = (result && result['Value'] !== undefined) ? result : '';
    }

    if (props.transactionValue === undefined && component.DefaultValue !== undefined) {
        const result = options.find((option) => option['Value'] !== undefined && option['Value'].trim() === component.DefaultValue.trim());
        attributeValue = (result && result['Value'] !== undefined) ? result : '';
    }

    let dataTableClass = '';
    if (isDynamicTable) {
        dataTableClass = 'styleaddition';
    }

    return (
        <Grid
            className={component.StyleClass + ' lineHeight custom-selectContainer ' + dataTableClass}
            style={component.AdjacentLabel === 'Y' ? { display: 'flex' } : styles.selectContainer}
            item
            xs={12}
            sm={12}
            md={isDynamicTable ? 12 : component.Size}
            lg={isDynamicTable ? 12 : component.Size}
            key={component.AttributeName}
        >
            {component.AdjacentLabel === 'Y' ? (
                <>
                    <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        className={`${component.AttributeStyleClass}_Label`}
                    >
                        <InputLabel className="custom-label-down text-font adjacent_label">
                            {isRequired ? `${component.ComponentLabel} *` : component.ComponentLabel}
                        </InputLabel>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
                    >
                        <FormControl
                            style={styles.dropdown}
                            className={`custom-dropdown custom-dropdown-select ${component.AttributeStyleClass}`}
                        >
                            <SelectFilterDropdownValidator
                                ComponentId={component.ComponentId}
                                name={component.AttributeName}
                                value={attributeValue}
                                options={options}
                                component={component}
                                onChange={onChange}
                                errorMessages={errorMessages}
                                validators={validations}
                                variant={variant}
                            />
                        </FormControl>
                    </Grid>
                </>
            ) : (
                <FormControl
                    style={styles.dropdown}
                    className={`custom-dropdown custom-dropdown-select ${component.AttributeStyleClass}`}
                >
                    <SelectFilterDropdownValidator
                        ComponentId={component.ComponentId}
                        name={component.AttributeName}
                        value={attributeValue}
                        options={options}
                        component={component}
                        onChange={onChange}
                        errorMessages={errorMessages}
                        validators={validations}
                        variant={variant}
                    />
                </FormControl>
            )}
        </Grid>
    );
}

const styles = {
    selectContainer: {
        display: "flex",
    },
    dropdown: {
        minWidth: "100%",
        alignSelf: "flex-end"
    }
};

export default DynamicSelectFilter;
