import { SET_SSOUSER_DATA, SET_USER_INFO } from './types';


export const setUserInfo = (data) => dispatch => {
    dispatch({
        type: SET_USER_INFO,
        payload: data
    })
}

export const setSsoData = data => dispatch =>{
    dispatch({
      type: SET_SSOUSER_DATA,
      payload: data
  })
  }
