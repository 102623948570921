import React,{cloneElement} from 'react'

class CloneWithPropsHOC extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    transform = () => {
        let parentProps = {}

        if(this.props.transform){
           let allProps = this.props.transform(this.props)
           parentProps = {...allProps}
        }
        return parentProps
    }



    render(){
        const childrenWithProps = React.Children.map(this.props.children, child => {
            if(React.isValidElement(child)){
                let parentProps = this.transform()
                // console.log("CloneWithHoc ", parentProps)
                return cloneElement(child, {parentProps: {...this.props.parentProps,...parentProps}})
            }
        })
        return(
            <React.Fragment>
            {
                childrenWithProps
            }
            </React.Fragment>
        )
    }

}

export default CloneWithPropsHOC
