import React, { useEffect } from 'react'
import { pageLoadActions } from '../utils/Common/NoCodeUtils';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_VALIDATIONS } from '../../../actions/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const ValidationRules = (props) => {

// const [validations, setValidations] = React.useState(["Location Details Must be saved", "UW details must be saved"])
const { UIFlowReducer } = useSelector((state) => state);
const dispatch = useDispatch()

const { validationData,updateValidation} = UIFlowReducer

// let validationRules = UIFlowReducer[`${props.pageInfo.name}`]


useEffect(() => {
  async function callAPI(){
    let { pageOnLoadActions = [] } = props.pageInfo;
    // Object.assign(state.transactionPrimaryObject, expandValues)
  await pageLoadActions(pageOnLoadActions, props, props.parentProps.parentState, undefined);
  // if(values && Object.keys(values).length > 0 ) updateValues(values);
  // if(values && Object.keys(values).length > 0 ) dispatch({type: UPDATE_VALIDATIONS, payload: values})
  dispatch({type: UPDATE_VALIDATIONS, payload: false})
  }

  if(updateValidation)
  callAPI()
},[updateValidation])


  return (
    <div className='ValidationRulesDefaultClass'>
    {
      Array.isArray(validationData) && validationData.length > 0 ?
      <TableContainer component={Paper}>
      <Table aria-label="custom pagination table">
        <TableBody>
          {
        validationData.map(msg=> {
       return(
          <TableRow>
              <TableCell scope="row">{msg}</TableCell></TableRow>
       )})}
              </TableBody></Table>
          </TableContainer> : (validationData || '')
    }
    </div>
      )
}

export default ValidationRules
