import React from 'react';
import { TableCell, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { commonRowMethod } from '../ButtonUtil';
import { displayAction } from '../../utils/Common/CommonUtils';
import { dynamicConditionValidationV2 } from '../../utils/Common/NoCodeUtils';


function DataTableActionV2(props) {

    let {  subAppInfo, rowData, isDisabled, actionListOptions = [], tableFunctions,  alignTableData } = props;
    // let Options = TableOptions['ActionListOptions'] ? TableOptions['ActionListOptions'] : [];
    // let Options = actionListOptions;
    let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
    let SubApplnName = SubApplicationDetail.ApplicationName;
    let dataListName = SubApplnName.toLowerCase() +"_dataList";
    // let align = tableActions.alignContent ? "center":"left"
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let filteredActions = [];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const actionOptions = (actionListOptions, rowData) => {
      actionListOptions.map(obj=> {
        if(obj.hasOwnProperty("conditions") && obj.conditions.length > 0){
            dynamicConditionValidationV2(rowData,obj.conditions) && filteredActions.push(obj)
          }
          else filteredActions.push(obj);


        //   if(obj.hasOwnProperty('DisplayKey') && obj.DisplayKey !== "")
        //   {
        //       if(obj['DisplayValue'] === rowData[obj['DisplayKey']]){
        //           filteredActions.push(obj)
        //       }

        //   }
            //   else filteredActions.push(obj)
      })

    }
    displayAction(actionListOptions, rowData, filteredActions);
    // To be used when both And and Or condition validation needed
    //actionOptions(actionListOptions, rowData);

    return (
        <TableCell key="action_cell" align={alignTableData}>
          {
            filteredActions.length > 0 &&
            <React.Fragment>
            <IconButton
                aria-label="actionList"
                aria-controls="actionItems"
                aria-haspopup="true"
                onClick={handleClick}
                disabled={isDisabled}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="Action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
            {
            filteredActions.map((option, optindex) => (
                    <MenuItem key={option.ActionName} onClick={(e) => {
                        option.hasOwnProperty("rowAction") && Object.keys(option.rowAction).length ? commonRowMethod(dataListName, rowData, SubApplnName, props,option) :
                        tableFunctions.hasOwnProperty(option.onOptionClick) && tableFunctions[option.onOptionClick](dataListName, rowData, SubApplnName, props,option);handleClose(e) }}>
                        {option.ActionDisplayName}
                    </MenuItem>
                ))
            }
            </Menu>
        </React.Fragment>
        }
        </TableCell>
    )
}

export default DataTableActionV2
