import { SET_IMAGE_BASE64_FORMAT,HOMEPAGE_LOGOS,SET_FAVICON,SET_MINI_LOGO_PATH,SET_SPINNER_LOGO_PATH, SET_CLIENTINFO_KEY, SET_USER_LANGUAGE, SET_LANGUAGE_CODE, THIRD_PARTY_LOGO, OWNER_RESOURCE, SET_COLOR_PALETTE} from '../actions/types';

const initialState = {
    imageFormat : '',
    clientInfo : {},
    userLanguage : 'en',
    languageCode : [],
    loginUser_logo_path : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case SET_IMAGE_BASE64_FORMAT : 
            return{
                ...state,
                imageFormat : action.imageFormat,
                loginUser_logo_path : action.loginUser_logo_path
            }
        case SET_FAVICON : 
            return{
                ...state,
                favicon : action.favicon
            }
        case SET_MINI_LOGO_PATH : 
            return{
                ...state,
                mini_logo_path : action.mini_logo_path
            }
        case SET_SPINNER_LOGO_PATH : 
            return{
                ...state,
                spinner_logo_path : action.spinner_logo_path
            }
        case SET_CLIENTINFO_KEY : 
            return{
                ...state,
                clientInfo : action.clientInfo
            }
        case SET_USER_LANGUAGE : 
            return{
                ...state,
                userLanguage : action.userLanguage
            }
        case SET_LANGUAGE_CODE : 
            return{
                ...state,
                languageCode : action.languageCode
            }
        case THIRD_PARTY_LOGO : 
            return{
                ...state,
                veriskLogo : action.veriskLogo,
                lexisLogo: action.lexisLogo,
                userLogo : action.userLogo
            }
            case HOMEPAGE_LOGOS : 
            return{
                ...state,
                homeowners_logo_path : action.homeowners_logo_path,
                search_logo_path:action.search_logo_path,
                privateflood_logo_path : action.privateflood_logo_path,
                automobile_logo_path : action.automobile_logo_path,
                umberlla_logo_path : action.umberlla_logo_path,
                cl_logo_path : action.cl_logo_path
            }
        case OWNER_RESOURCE :
            return{
                ...state,
                imagesource : action.imagesource
            }
        case SET_COLOR_PALETTE : 
            return{
                ...state,
                ColorPalette : action.ColorPalette
            }
        default : return state;
    }
}