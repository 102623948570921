import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';



const MessageDialog = (props) => {


    const handleClose = () => {
        props.closeDialog();
    }

    return (
        <div>
            <Dialog disableBackdropClickfullWidth={true}
                className="fullWidthDialogue"
                maxWidth = {'sm'}
                disableEscapeKeyDown
                open={props.open}
                aria-labelledby="form-dialog-title"
            >

                <div style={{display:'flex'}}>
                    <DialogTitle id="form-dialog-title" >  <span > {props.messageInfo} </span> </DialogTitle>
                    <span  style={{marginLeft:"auto",marginRight:'.5rem', alignSelf:'center'}}>
                        <IconButton>
                            <CloseIcon onClick={handleClose} />
                        </IconButton>
                    </span>
                </div>
                <div>
                    <DialogContent id="form-dialog-content" > <span > {props.contentInfo} </span></DialogContent>
                </div>
            </Dialog>
        </div>
    );
};

export default MessageDialog;
