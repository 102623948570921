import React, { useEffect, useState } from 'react';
import {Grid} from "@material-ui/core";
import PageLoader from "../runtime/controls/PageLoader";
import RenderTemplate from '../../template/RenderTemplate';
import { connect } from "react-redux";
function DashboardSSO(props){
  let [state,setState]=useState({ 
    loading: false,
    template: []
  })
  useEffect(()=>{ 
    let { utilfunctionNeeded, utilfunction} =props
    let utilKey = props.templateName
    if(utilfunctionNeeded && utilfunction && utilfunction.hasOwnProperty(utilKey) && utilfunction[utilKey].componentDidMount){
      utilfunction[utilKey].componentDidMount(props,state,updateState).then((values)=>{
        if(values) updateValues(values);
      }) 
    }
  },[])

  useEffect( ()=>{
    let { utilfunctionNeeded, utilfunction} =props
    let utilKey = props.templateName
    if(utilfunctionNeeded && utilfunction && utilfunction.hasOwnProperty(utilKey) && utilfunction[utilKey].componentDidMount){
       utilfunction[utilKey].componentDidUpdate(props,state,updateState).then((values)=>{
        if(values) updateValues(values);
      }) 
    }
  },[props.UIFlowReducer.pageName])
 
  const updateState = (statename, value, rerender) => {
    if (rerender) {
      setState({
        ...state, [statename]: value
      });
    } else {
      state[statename] = value;
    }
  }
  const updateValues = (obj) => {
    setState({
      ...state,
      ...obj
    })
  }

    return(
      <>
      { 
            state.template &&
            <Grid item xs={12} sm={12} md={12} lg={12} className="grid_box">
                <RenderTemplate
                  Template={state.template}
                  updateValues={updateValues}
                  UpdateState={updateState}
                  {...state}
                  propsValue={props}
                />
            </Grid>
      }
      {
        state.loading &&
        <Grid>
          <PageLoader />
        </Grid>
      }
    </>
    )
}


const mapStateToProps = (state) => ({
  UIFlowReducer: state.UIFlowReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // setPageName: (name) => dispatch(setPageName(name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSSO);