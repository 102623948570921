import React, { Fragment } from 'react';
import { getDateWithoutOffset } from '../../utils/Common/DateUtils'
import { format } from "date-fns";

function CellPlainText(props) {
    let { component, rowData } = props;
    function formatChange(datatype,formatVal, componentvalue) {
        if (datatype.toLowerCase() === "currency" && !isNaN(componentvalue)) {
            if(formatVal.includes('~')){
                var temp = formatVal.split('~')
            let options={
                style: "currency",
                currency:temp[1]
              }
            if(temp[2] && temp[2] !=="false")
               options.maximumFractionDigits=temp[2]
            if(temp[3] && temp[3] =="true")
                options.trailingZeroDisplay="stripIfInteger"
                const currencyFormatter = new Intl.NumberFormat(temp[0], options);
                return currencyFormatter.format(componentvalue);
            } else if (formatVal && formatVal.toLowerCase() == "decimal") {
                let parts=componentvalue.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return "$" + parts.join(".");
            } else {
                return componentvalue;
            }
        }
        if (formatVal === 'MM/dd/yyyy') {
            if (componentvalue !== '0000-00-00') {
                return format(getDateWithoutOffset(componentvalue), component.OutputFormatPattern);
            }
        }
        return componentvalue
    }
    let value = (component.OutputFormatPattern && rowData[component.AttributeName]) ? (formatChange(component.AttributeDataType,component.OutputFormatPattern, rowData[component.AttributeName])) : rowData[component.AttributeName];
    return (
        <Fragment>
            <span key={component.ComponentId} className={component.StyleClass} align="left" style={{ width: "auto" }}>
                {value}
            </span>
        </Fragment>
    )
}

export default CellPlainText;
