import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from './reducers';
import Axios from 'axios';
import { expressServerBaseURL } from './views/runtime/utils/Common/config';

const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["navigationReducer", "UIFlowReducer", "metadataReducer", "requestresponsereducer"],
  whitelist: ["authReducer", "workflowReducer", "ownerResources", "userInfoReducer"]
};

const createPersistedReducer = (config) => persistReducer(config, appReducer);
const composeEnhancers = process.env.NODE_ENV !== 'production' ?(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;

export let store = createStore(
  createPersistedReducer(persistConfig),
  composeEnhancers(
    applyMiddleware(...middleware)
  )
);

export let persistor = persistStore(store);

export default store;

async function GetConfig() {
  try {
    const response = await Axios.post(`${expressServerBaseURL}resource/resource?domainName=${window.location.hostname}`);
    const config = response.data && response.data["ClientInfo"] && response.data["ClientInfo"].MULTI_TAB_REDUX_STORE_CONFIG === 'Y'
      ? { blacklist: ["navigationReducer", "UIFlowReducer", "metadataReducer", "requestresponsereducer"], whitelist: ["authReducer", "workflowReducer", "ownerResources", "userInfoReducer"] }
      : { blacklist: ['navigationReducer'], whitelist: ["UIFlowReducer", "authReducer", "metadataReducer", "workflowReducer", "ownerResources", "userInfoReducer", "requestresponsereducer"] };

    const updatedPersistConfig = {
      ...persistConfig,
      ...config,
    };

    const updatedPersistedReducer = createPersistedReducer(updatedPersistConfig);
    store.replaceReducer(updatedPersistedReducer);
    persistor.persist();
    
  } catch (err) {
    console.log("Exception in Store Config Resource call", err);
  }
}

GetConfig();
