import React,{Component} from "react";
import {Button} from '@mui/material';

class ButtonPrimary extends Component {
    constructor(props) {
        super(props);
      }
    render(){
        const { component} = this.props;
    
    return(
        <Button  className={`button-style ${component.StyleClass}`}>
           {component.ComponentLabel}
        </Button> 
    )
    }
}
export default ButtonPrimary