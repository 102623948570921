export const ownerId = 1;
export const mode = "LIVE";
export const environment = "115";

export const idleTimeOut = 1000 * 60 * 30;

export const SSOEncrytionKey = "v9y$B&E)H@McQeTh";

//for local

//export const expressServerBaseURL = "http://localhost:5000/api/";

//for production deployment

export const expressServerBaseURL = "api/";

export const mimeType = {
    pdf:'application/pdf',
    txt:'text/plain',
    html:'text/html',
    zip:'application/zip',
    doc:'application/msword',
    xls:'application/vnd.ms-excel',
    xlsx:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt:'application/vnd.ms-powerpoint',
    gif:'image/gif',
    png:'image/png',
    jpeg:'image/jpg',
    jpg:'image/jpg',
    php:'text/plain',
    json:'application/json',
    xml:'application/xml'
};

export const extensionType = {
    'application/pdf':'pdf',
    'text/plain':'txt',
    'text/html':'html',
    'application/zip':'zip',
    'application/msword':'doc',
    'application/vnd.ms-excel':'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':'xlsx',
    'application/vnd.ms-powerpoint':'ppt',
    'image/gif':'gif',
    'image/*':'gif,png,jpeg,jpg',
    'image/png':'png',
    'image/jpeg':'jpeg',
    'image/jpg':'jpg',
    'text/plain':'php',
    'application/json':'json',
    'application/xml':'xml'
};

export const stateAbbreviations = {
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY",
    "District of Columbia": "DC"
  };
  