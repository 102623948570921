import React,{useState,useEffect, useRef} from 'react';
import {Grid} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { ValidatorForm,TextValidator } from "react-material-ui-form-validator";
import IconButton from '@material-ui/core/IconButton';
import { commonEmailUploader } from './ButtonUtil';
const MultipleUploader=(props)=>{
const [attachment,setAttachment]=useState([]);
const validationMessage=[];
const [Error, setError]= useState([]);
const [length,setLength]=useState(0);
const {component}=props;
var validations = [];
const inputElement=useRef();
useEffect(()=>{
  if(props.multipleUploads){
  props.multipleUploads && props.multipleUploads(props,setAttachment);
  }else{
  let { pagelevelValues, component } = props;
    if (
      pagelevelValues &&
      pagelevelValues[component.SubApplicationName] &&
      pagelevelValues[component.SubApplicationName][component.AttributeName]
    ) {
      let onLoadValues =
        pagelevelValues[component.SubApplicationName][component.AttributeName];
      setAttachment(onLoadValues);
    }
  }
  ValidatorForm.addValidationRule("throwErr", () => { return false;});
},[])
useEffect(()=>{
  let e = {
    target : {
        name : component.AttributeName,
    }
  }
  setLength(attachment.length);
  onChange(e);
},[attachment])


const onChange =async (e) => {
  if (props.customTable) {
      let value = Object.assign({}, { [e.target.name]: attachment });
      props.handleRowLevelInputChange(value, component, props.customTableRowIndex, props);
  }
  else if(component.OnChangeAction!=='' && props[component.OnChangeAction]) {
      let value = Object.assign({}, { [e.target.name]: attachment });
      await props[component.OnChangeAction](value, component, props.isDynamicTable, props.datatableRowindex);
  }
  else {
      let value = Object.assign({}, { [e.target.name]: attachment });
      await props.handleInputChangeV2(value, component, props.isDynamicTable, props.datatableRowindex);
  }
}
const onUpload=async(event)=>{
        let attachedFile=event.target.files;
        component.MaxValue="20971520"
        if(attachedFile.length<=0){
          return;
        }
        else {
          let doc = await commonEmailUploader(attachedFile,setAttachment,attachment,props,true,component,setError,Error)
         }
 }



const downloadCurrentFile=(file)=>{
  //props[component.OnClickAction]&& props[component.OnClickAction](file);
  commonEmailUploader(file)

}
const deleteAttachment=(index, validationMessage,attachment)=>{
  let tempAttachment=JSON.parse(JSON.stringify([...attachment]));
  tempAttachment.splice(index,1);
  setAttachment(tempAttachment);
  if(tempAttachment.length===0){
    setError([])
    validationMessage.push([])
  }  
} 


if (component.IsRequired === "Y") {
  validations.push("required");
  validationMessage.push(component.RequiredMessage);
  validations.push("IsEmpty");
  validationMessage.push(component.RequiredMessage);
}

let extension = undefined;
if(Error && Error.length>0){
  validations.push('throwErr')
}
else{
  validations.push()
  validationMessage.push([])
}
let autocomplete="off";
let custom3=component.Custom3 && JSON.parse(component.Custom3);
(custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";


let value = attachment.length > 0 || (component.componentvalue && component.componentvalue.length > 0) ? "true" : ""; 

return(
  <>
    <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} className='small_label'>
    <div>Attachment Found: {length} Attachment(s)</div>
        {attachment.length>0 ? (
          <>
          {attachment.map((file,index)=>{
            return(<>
            <a key={index} className={component.AttributeStyleClass} onClick={()=>downloadCurrentFile(attachment[index])}>{file[component.ResponseKey]}</a>
            {!component.IsReadonly && <IconButton component="span" onClick={()=>deleteAttachment(index, validationMessage,attachment)}><BackspaceIcon/></IconButton>}
            {file.size ? "File Size :" + (((file.size)/1024)/1024).toFixed(2) + ' MB' : ""}
            <br/>
            {Error && Error.length>0?(
            <>
           { Error.map((mes)=>{
            if(file.orgFileName===mes.AttachmentName){
              return(<><p style={redColor}>{mes["fileSizeError"]}</p><p style={redColor}>{mes["fileNameError"]}</p><p style={redColor}>{mes["fileExtensionError"]}</p>
              </>)
            }           
            })}
            </>
            ): null}
            <br />
            </>)
          })}
          </>
        ):null} 
              
    <div className="upload upload-inlineContainer theme_button" >
          <div  className={`upload-button theme_button theme_buttonColor ${component.IsReadonly? 'disabled': ''}`} >
            <span className={`theme_button ${component.StyleClass}`}> <PublishIcon  /><span style={{...Styles}} className='uploader_text'>{component.ComponentLabel}</span> </span>
            <input 
              id="contained-button-file"
              multiple
              type="file"
              disabled={ (component.IsReadonly) ? true: false}
              required={component.IsRequired === "Y"}
              accept={ extension === undefined ? "application/vnd.ms-excel , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/pdf" : null }
              onChange={onUpload}
              className= "fileInputOverflow upload-button-input"
            />
           
          </div>
        </div>
    <TextValidator
     className='texterrorthrowstyles component-container'
     inputRef={inputElement}
     validators={validations}
     errorMessages={validationMessage}
     autoComplete={autocomplete}
     value={value}
     /> 
    </Grid >
     </>
);
}
const Styles={padding:'2px'};
const redColor={color:"red"};
export default MultipleUploader;

