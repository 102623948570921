import React from 'react'
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {NavigateNext ,NavigateBefore } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

function NavigationButtons(props) {
    const { t } = useTranslation();
    const LOB = props.metadataReducer.lob;
    return (
        <React.Fragment>
            {
                props.pageNavigation[props.currentPageIndex - 1].IsPrevNeeded &&
                <Grid container spacing={3} className="dynamicComponentAlignment">
                    <Grid item xs={11} sm={11} md={props.grid_size_button} lg={props.grid_size_button} className="form-actions flex-start" style={{minWidth:'100%'}}>
                        {props.metadataReducer.lob === "CommercialAutomobile" && 
                        <Tooltip title="Previous">
                            <IconButton type="button"
                                color="primary"
                                className="nav-btn theme_color theme_border nav-prev-btn fixed-nav-btn fixed-nav-btn-prev"
                                style={styles.button}
                                onClick={props.navigateToPrevious}>
                                <NavigateBefore/> 
                            </IconButton>
                        </Tooltip>}
                        <Button
                            type="button"
                            color="primary"
                            className="nav-btn theme_buttonColor theme_border theme_button  nav-prev-btn"
                            style={styles.button}
                            onClick={props.navigateToPrevious}
                        >
                            {t('PreviousButton')}
                        </Button>
                    </Grid>
                </Grid>
            }

            {props.currentPageIndex === 8 &&
                <div className="form-actions marginleftauto" style={{ paddingLeft: '5px' }}>
                    <Button
                        type="button"
                        color="primary"
                        className="nav-btn theme_buttonColor nav-next-btn theme_button"
                        style={styles.button}
                        onClick={props.createQuote}
                    >
                        {t('NextButton')}
                    </Button>
                </div>
            }
            {props.pageNavigation[props.currentPageIndex - 1].isSave &&
                <div className="form-actions" style={{ paddingLeft: '5px', marginLeft: '50%' }}>
                    <Button
                        type="submit"
                        color="primary"
                        className="nav-btn theme_buttonColor nav-next-btn theme_button"
                        style={styles.button}
                    >
                        {t('SaveButton')}
                    </Button>
                </div>
            }
            {/* {
                //!temp
                props.pageNavigation[props.currentPageIndex - 1].renderSubmission &&
                <div className="form-actions marginleftauto" style={{ paddingLeft: '5px' }}>
                    <Button
                        type="submit"
                        color="primary"
                        className="nav-btn nav-next-btn"
                        style={styles.button}
                        onClick={() => props.getAccountData(props)}
                    >
                        {t('CreateSubmissionButton')}
                    </Button>
                </div>
            } */}
            {
                props.pageNavigation[props.currentPageIndex - 1].IsNextNeeded &&
                <Grid container spacing={3} className="dynamicComponentAlignment">
                    <Grid item xs={11} sm={11} className="form-actions  flex-end" md={props.grid_size_button} lg={props.grid_size_button} style={{minWidth:'100%'}}>
                        <Button
                            disabled={
                                (props.metadataReducer.lob === "GeneralLiability" || props.metadataReducer.lob === "CommercialProperty") && props.currentPageIndex === 3 &&
                                (!props.metadataReducer.additionalCoverageVisibility || props.metadataReducer.additionalCoverageVisibility[LOB] !== "true" || (props.metadataReducer.additionalCoverageVisibility[LOB] === undefined)) ? true : false}
                            type="submit"
                            color="primary"
                            className={
                                (props.metadataReducer.lob === "GeneralLiability" || props.metadataReducer.lob === "CommercialProperty") && props.currentPageIndex === 3 &&
                                (!props.metadataReducer.additionalCoverageVisibility || props.metadataReducer.additionalCoverageVisibility[LOB] !== "true" || (props.metadataReducer.additionalCoverageVisibility[LOB] === undefined)) ? "nav-btn-disabled theme_button" : "nav-btn theme_buttonColor nav-next-btn theme_button"}
                            style={styles.button}
                        >
                            {
                                // ( (props.currentPageIndex === 1 && props.parentTabPosition === 0) ? (props.submissionResponse.SubmissionID === null || props.submissionResponse.SubmissionID === undefined) ? t('CreateSubmissionButton') : t('SaveAndNextButton') : t('NextButton'))
                                ( (props.pageNavigation[props.currentPageIndex - 1] ? t('NextButton') : null))
                            }
                        </Button>
                        {props.metadataReducer.lob === "CommercialAutomobile" && 
                        <Tooltip title="Next">
                            <IconButton 
                                disabled={props.metadataReducer.lob === "CommercialAutomobile" && props.currentPageIndex === 2 &&
                                    (!props.metadataReducer.additionalCoverageVisibility || props.metadataReducer.additionalCoverageVisibility[LOB] !== "true" || (props.metadataReducer.additionalCoverageVisibility[LOB] === undefined)) ||
                                    (props.metadataReducer.lob === "GeneralLiability" || props.metadataReducer.lob === "CommercialProperty") && props.currentPageIndex === 3 &&
                                    (!props.metadataReducer.additionalCoverageVisibility || props.metadataReducer.additionalCoverageVisibility[LOB] !== "true" || (props.metadataReducer.additionalCoverageVisibility[LOB] === undefined)) ? true : false}
                                type="submit"
                                color="primary"
                                className={props.metadataReducer.lob === "CommercialAutomobile" && props.currentPageIndex === 2 &&
                                    (!props.metadataReducer.additionalCoverageVisibility || props.metadataReducer.additionalCoverageVisibility[LOB] !== "true" || (props.metadataReducer.additionalCoverageVisibility[LOB] === undefined)) ||
                                    (props.metadataReducer.lob === "GeneralLiability" || props.metadataReducer.lob === "CommercialProperty") && props.currentPageIndex === 3 &&
                                    (!props.metadataReducer.additionalCoverageVisibility || props.metadataReducer.additionalCoverageVisibility[LOB] !== "true" || (props.metadataReducer.additionalCoverageVisibility[LOB] === undefined)) ? "nav-btn-disabled fixed-nav-btn" : "nav-btn theme_color theme_border fixed-nav-btn"}
                                style={styles.button}>
                                <NavigateNext/> 
                            </IconButton>
                        </Tooltip>}
                    </Grid>
                </Grid>
            }
            {
               props.IsMultiLob === 'true' && props.pageNavigation[props.currentPageIndex - 1 ].button && props.pageNavigation[props.currentPageIndex - 1].button.includes("productselection") &&
                
               <div className="form-actions marginleftauto" style={{ paddingLeft: '5px' }}>
                    <Button
                        type="button"
                        color="primary"
                        onClick={() => props.handleParentTabChange(null,2,{key:"SubmissionProductBrowse"})}
                        className=  "nav-btn theme_buttonColor nav-next-btn theme_button"
                        style={styles.button}
                    >
                        {
                            t('Product Selection') 
                        }
                    </Button>
                </div>

            }
            {
                 props.IsMultiLob === 'true' &&  props.tabPosition < props.totalState-1 && props.pageNavigation[props.currentPageIndex - 1 ].button && props.pageNavigation[props.currentPageIndex - 1].button.includes("nextLob") &&

                 <div className="form-actions marginleftauto" style={{ paddingLeft: '5px' }}>
                    <Button
                        type="button"
                        color="primary"
                        onClick={() => props.handleTabChange(null, props.tabPosition + 1 ,{ key : "SubmissionLob", parentTabPosition : 1 })}
                        className=  "nav-btn theme_buttonColor nav-next-btn"
                        style={styles.button}
                    >
                        {
                            t('Next LOB') 
                        }
                    </Button>
                </div>
            }
            {
                 props.IsMultiLob === 'true' &&  props.pageNavigation[props.currentPageIndex - 1 ].button && props.pageNavigation[props.currentPageIndex - 1].button.includes("MultiLobPrevious") &&                
                 <div className="form-actions" style={{ paddingLeft: '5px' }}>
                    <Button
                        type="button"
                        color="primary"
                        className="nav-btn theme_color theme_border nav-prev-btn theme_button"
                        style={styles.button}
                        onClick={ () => props.navigateToPreviousLOB()}
                    >
                        {t('Previous LOB')}
                    </Button>
                </div>
            }
            {
                //!temp
                (props.pageNavigation[props.currentPageIndex - 1].hasOwnProperty("renderRateAndPreview") && props.pageNavigation[props.currentPageIndex - 1].renderRateAndPreview) &&
                <div className="form-actions pull-right" style={{ marginLeft: "auto" }}>
                    {props.UIFlowReducer.RateRender &&
                        <Button
                            type="button"
                            color="primary"
                            className="nav-btn nav-next-btn theme_button"
                            style={styles.button}
                            onClick={() => props.getRate(props)}
                        >
                            Rate
                        </Button>}
                    {props.premium > 0 && (
                        <React.Fragment>
                            <Button
                                type="button"
                                color="primary"
                                className="nav-btn theme_buttonColor nav-next-btn theme_button"
                                style={styles.button}
                                onClick={() => props.getPreviewQuoteProposalDetail(props)}
                            >
                                Preview Quote Proposal
                            </Button>
                            {props.getPreviewWorksheetDetail && 
                            <Button
                                type="button"
                                color="primary"
                                className="nav-btn theme_buttonColor nav-next-btn theme_button"
                                style={styles.button}
                                onClick={() => props.getPreviewWorksheetDetail(props)}
                            >
                                Preview Rating Worksheet
                            </Button>                            
                            }                            
                            <Button
                                type="button"
                                color="primary"
                                className="nav-btn theme_buttonColor nav-next-btn theme_button"
                                style={styles.button}
                                onClick={() => props.createQuote(props)}
                            >
                                Create Quote
                            </Button>
                        </React.Fragment>)}
                </div>
            }
            {
                /* (props.currentPageIndex !== props.pageNavigation.length && props.pageNavigation[props.currentPageIndex-1].isSave) ?
                <div className="form-actions marginleftauto" style={{paddingLeft:'5px'}}>
                   <Button 
                       type="submit" 
                       color="primary" 
                       className="nav-btn nav-next-btn"
                       style={styles.button} 
                       >
                       Save & Next
                   </Button>
               </div> : props.currentPageIndex !== props.pageNavigation.length &&
               <div className="form-actions marginleftauto" style={{paddingLeft:'5px'}}>
               <Button 
                   type="submit" 
                   color="primary" 
                   className="nav-btn nav-next-btn"
                   style={styles.button} 
                   >
                    Next
               </Button>
           </div> */
            }
        </React.Fragment>
    )
}

const styles = {
    button: {
    }
}

const mapStateToProps = (state) => ({
    metadataReducer: state.metadataReducer,
    premium: state.workflowReducer.premium,
    // productFlowReducer: state.productFlowReducer,
    totalState: state.navigationReducer.navigation.TabNavigation ? state.navigationReducer.navigation.TabNavigation[1].ChildrenTabNavigation.length : null ,
    UIFlowReducer: state.UIFlowReducer
});


export default connect(mapStateToProps)(withRouter(NavigationButtons));