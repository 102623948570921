import React from "react";
import "./Home.css";
// import homeOwners from "../../../assets/img/Homepage/Homeowners.png";
// import privateFlood from "../../../assets/img/Homepage/privateflood.png"
import { Grid } from "@material-ui/core";
import Button from './Button';
// import Automobile from "../../../assets/img/Homepage/Automobile.png";
// import Umbrella from "../../../assets/img/Homepage/Umbrella.png";
import MPL from "../../../assets/img/Homepage/MPL.png"
import store from "../../../store";
class Homescreen extends React.Component {
  render() {
    const { component,componentProps,componentState,state,parentProps,functions } = this.props;
    const buttonComponent={...component,ComponentLabel:component.ColumnHeading}
    let propsObj={component:buttonComponent,componentProps,componentState,state,parentProps,functions};
    let {templateName}=this.props.componentProps;
    // const labelHomeOwners="HOMEOWNERS"
    // const labelPrivateFlood="PRIVATE FLOOD";
    // const labelUmbrella="UMBRELLA";
    // const labelAutomobile="AUTOMOBILE";
    const labelMPL="MANAGEMENT PROFESSIONAL LIABILITY";
    let buttonHere;
    let path;
    let redux = store.getState()
    if(component.IsDisabled==false){
      let util=this.props.componentProps.utilfunction[component.DefaultValue][templateName]&&this.props.componentProps.utilfunction[component.DefaultValue][templateName][component.OnClickAction]?this.props.componentProps.utilfunction[component.DefaultValue][templateName][component.OnClickAction]:{};
      propsObj={...propsObj,[component.OnClickAction]:util};
    }
    // if(component.ComponentLabel=== labelHomeOwners){
    //     path=homeOwners;
    //   }
    // else if(component.ComponentLabel=== labelPrivateFlood){
    //   path=privateFlood;
    // }
    // else if(component.ComponentLabel=== labelAutomobile){

    //   path=Automobile;
    // }


    // else if(component.ComponentLabel=== labelUmbrella){

    //   path=Umbrella;
    // }
    if(component.ComponentLabel===labelMPL){
         path=MPL;
       }
    return (
      <React.Fragment>
     {/*   <div className="back">
       <div>

         <Grid
            container
            spacing={8}
            style={{rowGap:50,columnGap:160}}
            direction="row"
            alignItems="centre"
    > */}
            <Grid item xs={12} sm={12} md={5} lg={5} style={{ paddingTop:"2%",paddingLeft:"10%",paddingBottom:"3%"}} >
              <div className="divcontainer ">
                <div className="header">
                  <label>{component.ComponentLabel}</label>
                  <br></br>
                  <div>
                    <img className="imge" src={redux.ownerResources[component.ResponseKey]}></img>
                  </div>
                  <br></br>
                </div>
                <div>
                  <Button {...propsObj}/>
                </div>
              </div>
            </Grid>
           </React.Fragment>  );
        {/*  </Grid>
        </div>
      </div> */}

  }
}

export default Homescreen;
