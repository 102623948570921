import IconButton from '@material-ui/core/IconButton';
import React, { Component } from 'react';
import { Publish, GetApp } from '@material-ui/icons';

class ImportExportButton extends Component {

    render() {

        return (
            <span>
                <IconButton color="primary" className="custom-icon-button theme_nav" aria-label="upload picture" component="span" onClick={()=> {this.props.onExport(this.props.dynamicdatatablerows, this.props.component.AttributeParams)}} title="Export Location(s)" >
                    <GetApp />
                </IconButton>
                <input accept=".xlsx, .xls" id="icon-button-file" type="file" onChange={this.props.onImport} style={{ display: 'none' }} title="Import Locations"/>
                <label htmlFor="icon-button-file">
                    <IconButton color="primary" className="custom-icon-button theme_nav " aria-label="upload picture" component="span" title="Import Location(s)" >
                        <Publish />
                    </IconButton>
                </label>
            </span>
        );
    }
}
export default ImportExportButton;
