import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import CheckboxValidator from './CheckboxValidator';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { commonCheckBoxMethod } from './ButtonUtil';
import { updateValueToRedux } from '../utils/Common/CommonUtils';


class BooleanCheckbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCheckboxName: this.props.component.SubApplicationAttributeValueList.length === 0 ? this.props.attributeValue : this.props.attributeValue || this.props.attributeValue === '' ? this.props.attributeValue.split(',') : this.props.component.DefaultValue ? this.props.component.DefaultValue.split(',') : [],
        }
    }

    componentDidMount() {
        ValidatorForm.addValidationRule("required", (value) => {
            if (!value) {
                return false;
            }
            return true;
        });
        let {component}=this.props;
        if(component.Action.SaveDataInSession && component.Action.SaveDataInSession==='Y'){
            if(this.props.componentvalue !== undefined && this.props.componentvalue !=='')
            updateValueToRedux(this.props,this.props.componentvalue);
            else
            updateValueToRedux(this.props,component.DefaultValue)
        }
        if (this.props.component.DefaultValue !== "" && this.props.componentvalue === undefined ) {
            this.props.updateValueWithSubplicationName && this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
          }
    }
    componentDidUpdate(prevProps){
        let {component}=this.props;
        if((prevProps.componentvalue && this.props.componentvalue===undefined && prevProps.componentvalue !=='' && this.props.componentvalue !== prevProps.componentvalue))
        if(component.Action.SaveDataInSession && component.Action.SaveDataInSession==='Y'){
            updateValueToRedux(this.props,this.props.componentvalue)
        }
    }
    onChange = async(e) => {
        let checked = e.target.checked === true ? 'Yes' : 'No';
        let value = Object.assign({}, { [e.target.name]: checked });
        this.setState({ selectedCheckboxName: checked })
        let { util, utilfunction } = this.props.parentProps && this.props.parentProps.pageInfo || {}
        let {component} = this.props;
        //temporary
        if(checked === "No" && component.Action &&  Object.keys(component.Action).length>0 && component.Action.unCheckAction ){
            commonCheckBoxMethod({...component, Action: {...component.Action.unCheckAction}},value,this.props)
        }
        else if(Object.keys(component.Action).length>0)
        commonCheckBoxMethod(component,value,this.props)
        else if(this.props.customTable){
          if(this.props.component.OnChangeAction!=='' && this.props[this.props.component.OnChangeAction]){
            this.props[this.props.component.OnChangeAction](value,this.props.component, this.props.isDynamicTable, this.props.datatableRowindex,this.props,this.state)
          } else{
            this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex, this.props);
          }
        }
        else {
            if(component.OnClickAction)
            {
                util && utilfunction && await utilfunction[util][component.OnClickAction](this.props.component, this.props.componentProps, this.props.parentProps, this.props.functions.UpdateState)
                this.props[component.OnClickAction] && this.props[component.OnClickAction](this.props,value,this.props.componentProps)
                // this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
            }
            else if(this.props.component.OnChangeAction!=='' && this.props[this.props.component.OnChangeAction]){
                this.props[this.props.component.OnChangeAction](value,this.props.component, this.props.isDynamicTable, this.props.datatableRowindex,this.props)
             }
            else
                this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
        }
    }

    handleMultipleCheckBox = (attributeName, checkBoxValue) => {
        this.setState((prevState) => {
            let selectedCheckboxName = prevState.selectedCheckboxName;
            let index = selectedCheckboxName.findIndex(value => value === checkBoxValue);
            if (index > -1) {
                selectedCheckboxName.splice(index, 1);
            }
            else {
                selectedCheckboxName = [...selectedCheckboxName, checkBoxValue];
            }
            let value = Object.assign({}, { [attributeName]: selectedCheckboxName.toString() });

            // LOB : 356736,2192 => Storing inside values state

            this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
            return { selectedCheckboxName }
        })
    }

    render() {

        const { component } = this.props;
        const { SubApplicationAttributeValueList } = component;

        // component.DefaultValue !== "true" ? this.state.selectedCheckboxName = false : this.state.selectedCheckboxName = true
        this.props.componentvalue && this.props.componentvalue.constructor !== Object ? (this.props.componentvalue !== "Yes" ? this.state.selectedCheckboxName = false : this.state.selectedCheckboxName = true) : (component.DefaultValue !== "Yes" ? this.state.selectedCheckboxName = false : this.state.selectedCheckboxName = true)

        let isRequired = component.IsRequired === 'Y' ? true : false;
        const validations = [];
        const errorMessages = [];

        if ((isRequired && !this.state.selectedCheckboxName) || (isRequired && Array.isArray(this.state.selectedCheckboxName) && this.state.selectedCheckboxName.length === 0)) {
            validations.push('required');
            errorMessages.push(component.RequiredMessage);
        }

        return (
            <Grid item xs={12} sm={12} md={component.Size > 0 ? component.Size : 12} lg={component.Size > 0 ? component.Size : 12} className={`check_box ${component.AttributeStyleClass !== "" ? component.AttributeStyleClass : ""}`}>
                <FormControl className={component.StyleClass}>
                    <CheckboxValidator
                        componentLabel={component.ComponentLabel}
                        SubApplicationAttributeValueList={SubApplicationAttributeValueList}
                        component={component}
                        componentAttributeValue={this.state.selectedCheckboxName}
                        onChange={this.onChange}
                        handleMultipleCheckBox={this.handleMultipleCheckBox}
                        errorMessages={errorMessages}
                        validators={validations}
                    />
                </FormControl>
            </Grid>);
    }
}

export default BooleanCheckbox;
