import React ,{ Fragment } from 'react';
import {  TextField, TableRow, TableCell } from "@material-ui/core";

function CustomFilterRow(props) {

    let { columnList, TableOptions, columnOptions, toggleSelect, ChildSubApplicationList } =props;
    let actionRequired = TableOptions['ActionListOptions'].length > 0 ? true : false;
    let detailRequired = TableOptions.ChildrenComponent != null || ChildSubApplicationList && ChildSubApplicationList.length > 0;  
    let selectable = !!TableOptions.Selectable && TableOptions.Selectable;
    let rowHeadingNeeded = TableOptions.hasOwnProperty("PivotSummary") && TableOptions.PivotSummary.hasOwnProperty("render") ?TableOptions.PivotSummary.render:false;

    return (
        <Fragment>
            <TableRow style={{backgroundColor: '#e5e5e5'}}  className='theme_DataTable'>
                { (detailRequired || rowHeadingNeeded)  && <TableCell />}
                { selectable && <TableCell />}
                {
                    columnList.map((component) => {
                        if (component.RenderValue) 
                        {
                            let componentId = component.ComponentId;
                            return (
                                <TableCell 
                                    key={componentId+"_filterArea"} 
                                    align="left" 
                                    size="small"
                                    className="dt-filterCell"
                                >
                                    {   
                                        TableOptions.FilteringNeeded &&
                                            <TextField
                                                id={`${componentId}_filterText`}
                                                name={componentId}
                                                className="custom-input"
                                                value={columnOptions.filtercriteria[componentId] || ""}
                                                onChange={(e) => columnOptions.handleFilter(e)}
                                                /* InputProps={
                                                    TableOptions.FilterIconNeeded && {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                    <FilterListIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                } */
                                            />
                                    }   
                                </TableCell>
                            )
                        }
                    })
                }
                { actionRequired && <TableCell />}
            </TableRow>
        </Fragment>
    )
}

export default CustomFilterRow;
