import red from '@material-ui/core/colors/red';
import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import { ValidatorComponent } from "react-material-ui-form-validator";
import {getDateWithoutOffset} from "../utils/Common/DateUtils"
import { TextField } from '@mui/material';

const red300 = red["500"];

const style = {
  left: 0,
  fontSize: "12px",
  color: red300
};

class CalendarValidator extends ValidatorComponent {

    render() {
        const {
            value,
            open,
            name,
            id,
            onClose,
            onClick,
            format,
            label,
            component,
            placeholder,
            disabled,
            styleClass,
            maxDate,
            minDate,
            editable,
            autoComplete,
            variant
        } = this.props;
        const formattedValue = value ? getDateWithoutOffset(value): value;
        const isError = (variant === "outlined" && !this.state.isValid) || false;
        let TextFieldProps = {}
        if(variant === "outlined"){
            TextFieldProps.TextFieldComponent = ((props) =>  <TextField {...props} variant={variant} />)
        }
        return (
            <>
            <MuiPickersUtilsProvider className={`${component.AttributeStyleClass} MuiInputBase-fullWidth custom-input adjacent_TextValidator_Grid
                adjacent_TextValidator_Grid ${component.AdjacentLabel === 'Y' ? 'MuiInputBase-fullWidth' : null}`} utils={DateFnsUtils}>
            <KeyboardDatePicker
                fullWidth='100%'
                //variant="inline"
                //orientation="landscape"
                //InputProps={{ readOnly: true }}
                disabled={disabled}
                // open={open}
                // onClose={onClose}
                // onClick={onClick}
                format={format}
                maxDate={maxDate}
                minDate={minDate}
                maxDateMessage = {component.ValidatorMessage}
                minDateMessage = {component.ValidatorMessage}
                className={styleClass+ ' lineHeight'}
                name={name}
                id={id}
                label={label}
                error={isError}
                placeholder={placeholder}
                value={formattedValue}
                autoComplete={autoComplete}
                onChange={(event,value) =>{this.props.onChange(event,value)}}
                {...(editable===false?{
                    open:open,
                    onClose:onClose,
                    onClick:onClick,
                    InputProps:{readOnly: true}
                    }:{onBlur:(e)=> this.props.onBlur && this.props.onBlur(e,editable),
                        helperText:undefined
                    })}
                {...TextFieldProps}
            />
            </MuiPickersUtilsProvider>
                {this.errorText()}
            </>
        );
    }


    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div className="errorMessageStyle" >
            <div className='small_error' style={style}>
                {this.getErrorMessage()}
            </div>
            </div>
        );
    }
}

export default CalendarValidator;
