import React from 'react';
import LocationDetail from './DetailSummaryList';
import LocationSummary from './SummaryV2';
import DynamicComponentHOC from '../../../corecomponents/DynamicComponentHOC'
import TemplateRendering from './TemplateRendering'
import { connect } from "react-redux";

class LocationWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: {}
        };
    }

    render() {
        const { showDetail, pageInfo } = this.props;
        return (
            <React.Fragment>
                {
                    pageInfo.showDetail || showDetail ?
                        pageInfo.isHocNeeded ?
                            <DynamicComponentHOC
                                {...this.props}
                            />
                            :
                            pageInfo.subApplication["locationDetail"] !== null ?
                                <React.Fragment>
                                    <LocationDetail
                                        {...this.props}
                                    />
                                </React.Fragment>
                                : null
                        :
                        showDetail !== true ?
                            pageInfo.subApplication["locationSummary"] !== null ?
                                <LocationSummary
                                    {...this.props}
                                />
                                :
                                pageInfo.isRenderTemplate ?
                                    <TemplateRendering
                                        {...this.props}
                                        isExpand={true}
                                    />
                                    : null
                            : null
                }


            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => ({
    metadataReducer: state.metadataReducer,
    UIFlowReducer: state.UIFlowReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationWrapper);
