import { AxiosInstance } from "../../../views/runtime/utils/Common/API";
import { SET_USER_INFO } from "../../../actions/types"
import store from "../../../store";
import { dateTimeFormat } from "../../runtime/utils/Common/DateUtils";

export const logUserDetailRequest = async (params, action, loginType) => {
    let LogRequest, userInfo = {}
    let date = new Date();
    let format = 'YYYY-MM-DD HH:mm:ss';
    let time = dateTimeFormat(date)
    LogRequest = require("../../runtime/models/ClientStatus.json")
    if (action != 'Logout'){
        LogRequest['UserLogInfo']['StartTime'] = time;
        LogRequest['UserLogInfo'].LoginType = loginType;
        LogRequest['UserLogInfo'].BrowserType = navigator.userAgent
        LogRequest['UserLogInfo'].Action = action
        LogRequest['UserLogInfo'].RoleName = params.Role
        LogRequest['UserLogInfo'].UserInitial = params.LastName
        LogRequest['UserLogInfo'].UserName = params.UserName
        if(loginType == "pwdAuthentication"){
            store.dispatch({ type : SET_USER_INFO, payload : userInfo })
        }
    }
    if (action == 'Logout'){
        LogRequest['UserLogInfo']['EndTime'] = time;
        LogRequest['UserLogInfo']['LoggedOffBy'] = "User Logout"
        LogRequest['UserLogInfo'].Action = action
    }
    AxiosInstance.post("auth/logUserDetail", LogRequest)
    .then((result) => console.log('Successfully Completed'))
    .catch((err) => console.log(err));
}
