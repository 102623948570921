import React from 'react';
import { TableCell, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

function DataTableAction(props) {

    let { TableOptions, subAppInfo, rowData } = props;
    let Options = TableOptions['ActionListOptions'] ? TableOptions['ActionListOptions'] : [];
    let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
    let SubApplnName = SubApplicationDetail.ApplicationName;
    let dataListName = SubApplnName.toLowerCase() +"_dataList";

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <TableCell key="action_cell" align="left">
            <IconButton
                aria-label="actionList"
                aria-controls="actionItems"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="Action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
            {
                Options.map((option, optindex) => (
                    <MenuItem key={option.ActionName} onClick={(e) => {TableOptions['ActionListOptions'][optindex].OnOptionClick(dataListName, rowData, SubApplnName, props);handleClose(e)}}>
                        {option.ActionDisplayName}
                    </MenuItem>
                ))
            }
            </Menu>
        </TableCell>
    )
}

export default DataTableAction
