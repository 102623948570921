import React, { Component } from "react";
class ClearButton extends Component {
  render() {
    const { component, onClick_ClearButton } = this.props;

    return (
      <span className={`col-xs-12 col-sm-6 col-md-6 col-lg-6 ${component.AttributeStyleClass}`}>	
        <button
          id={component.ComponentId}
          name={component.ComponentId}
          className={`MuiButtonBase-root MuiButton-root theme_button MuiButton-text nav-btn theme_color theme_border nav-next-btn MuiButton-textPrimary ${component.StyleClass}`}
          type="Button"
          onClick={(e) => onClick_ClearButton(component,this.props)}
        >
          <span>{component.ComponentLabel}</span>
        </button>
      </span>
    );
  }
}
export default ClearButton;
