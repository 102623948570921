import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { getMimeType, getFileExtension } from '../utils/Common/API';

class Import extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: "",
            drag: false,
            showValidation:false
        }
        this.dropRef = React.createRef();
        this.dragCounter = 0;
    }


    componentDidMount = () => {
        this.getAddEventListener();
        ValidatorForm.addValidationRule("IsEmpty", (value) => {
            if (!value) {
              return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule("IsInvalidFile", (value) => {
            if (this.state.showValidation) {
              return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        this.getRemoveEventListener()
    }

    getAddEventListener = () => {
        try {
            ["dragenter", "dragover", "dragleave", "mouseout", "drop"].forEach(
                (eventName) => {
                    window.addEventListener(eventName, this.handleDragEvents);
                }
            );
            window.addEventListener("dragenter", this.handleDragIn);
            window.addEventListener("dragleave", this.handleDragOut);
            window.addEventListener("mouseout", this.handleMouseOut);
            window.addEventListener("drop", this.handleMouseOut);
            let div = this.dropRef.current;
            div.addEventListener("drop", this.handleDrop);
        } catch (err) {
            console.log("Error in getAddEventListener method", err.message);
        }
    }
    getRemoveEventListener = () => {
        try {

            ["dragenter", "dragover", "dragleave", "mouseout", "drop"].forEach(
                (eventName) => {
                    window.removeEventListener(eventName, this.handleDragEvents);
                }
            );
            window.removeEventListener("dragenter", this.handleDragIn);
            window.removeEventListener("dragleave", this.handleDragOut);
            window.removeEventListener("mouseout", this.handleMouseOut);
            window.removeEventListener("drop", this.handleMouseOut);
            let div = this.dropRef.current;
            div.removeEventListener("drop", this.handleDrop);
        } catch (err) {
            console.log("Error in getRemoveEventListener method", err.message);
        }
    }

    handleDragEvents = (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
        } catch (err) {
            console.log("Error in handleDragEvents method", err.message);
        }
    };

    handleDragIn = (e) => {
        try {
            this.dragCounter++;
            if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                this.setState({ drag: true });
            }
        } catch (err) {
            console.log("Error in handleDragIn method", err.message);
        }
    };

    handleDragOut = (e) => {
        try {
            this.dragCounter--;
            if (this.dragCounter === 0) {
                this.setState({ drag: false });
            }
        } catch (err) {
            console.log("Error in handleDragOut method", err.message);
        }
    };

    handleMouseOut = (e) => {
        try {
            this.dragCounter = 0;
            if (this.dragCounter === 0) {
                this.setState({ drag: false });
            }
        } catch (err) {
            console.log("Error in handleMouseOut method", err.message);
        }
    };

    handleDrop = (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.dragCounter = 0;
            if (this.dragCounter === 0) {
                this.setState({ drag: false });
            }
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                let data = { ...e.dataTransfer.files }
                let event = {
                    target: {
                        files: data,
                    },
                };
                this.handleAttachmentImport(event);
                e.dataTransfer.clearData();
            }
        } catch (err) {
            console.log("Error in handleDrop method", err.message);
        }
    };


    onChange = async (e,event) => {
        if (this.props.customTable) {
            let value = Object.assign({}, { [e.target.name]: e.target.value });
            this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex, this.props);
        }
        else if(this.props.component.OnChangeAction!=='' && this.props[this.props.component.OnChangeAction]) {
            let value = Object.assign({}, { [e.target.name]: e.target.value });
            this.props[this.props.component.OnChangeAction](value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
        }
        else {
            let value = Object.assign({}, { [e.target.name]: e.target.value });
            await this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
            this.props.handleInputChangeV2({'attachedFile': event.target.files[0]}, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
        }
    }
    //Method for uplpading file directly
    // fileUpload =async (event)=>{
    //     const formData = new FormData();
    //     formData.append("uploadFileData", event.target.files[0].attachedFile, event.target.files[0].attachedFile.name);
    //     let fileUploadResponse = await AxiosInstance.post("/core/fileUpload?pathVariable=ATTACHMENTS_POLICY", formData);
    //     return ({AttachmentUrl: fileUploadResponse.data.newpath, AttachmentFileName: fileUploadResponse.data.newFileName})
    // }

    handleAttachmentImport = async(event) => {
        const { component } = this.props
        if(event.target.files[0]){
        // let filename = event.target.files[0].name && event.target.files[0].name.substring(0,event.target.files[0].name.lastIndexOf('.'));
        let fileNameWithoutExtension = event.target.files[0] && event.target.files[0].name && event.target.files[0].name.substring(0,event.target.files[0].name.lastIndexOf('.'));
        let fileExtension = event.target.files[0] && event.target.files[0].name.split(".").pop().toLowerCase();
        const {ItemTipParams} = component;
        let fileTypeCheck = ItemTipParams.split(',').map(part => getFileExtension(part.trim())).join(',');
        let fileTypeVal = component.ItemTipParams==='' ? false : !(fileTypeCheck.split(',').map(
            item => item.trim()).includes(event.target.files[0] ? fileExtension: ""))
        let splcharRgxValidation = component.ValidationRegex !== "" ? new RegExp(component.ValidationRegex).test(fileNameWithoutExtension) : false
        let val=event.target.files[0] && event.target.files[0].size>component.MaxValue || fileTypeVal || splcharRgxValidation
        if(val) this.props.functions.UpdateState("proceedSaving",false,false)
        else this.props.functions.UpdateState("proceedSaving",true,false)
        let filename = (event.target.files && event.target.files[0]) ? event.target.files[0].name : "";
        let e = {
            target : {
                name : component.AttributeName,
                value : filename
            }
        }
        this.onChange(e,event);
        this.props.functions.UpdateState('attachedFile', event.target.files[0], true);
        this.setState({...this.state,file: event.target.files[0],showValidation:val?true:false})
    }
    }


    render() {
        const { component, classes } = this.props;
        let extension = this.props.values && this.props.values.FileType;
        console.log("extension::",extension, this.props);
        let mimetype= getMimeType(extension);
        let acceptFiles = component.ItemTipParams || 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,image/*,text/plain'
        let componentvalue = "";
        if(this.props.componentvalue === undefined)
            componentvalue = "";
        else
            componentvalue = this.props.componentvalue

        let showError = this.props.parentProps && this.props.parentProps.hasOwnProperty("showError") ? this.props.parentProps.showError : false
        let value = this.state.file === undefined ? "" : this.state.file.name
        let isRequired = component.IsRequired === "Y" ? true : false;
        let componentLabel = "" ;
        if(component.ComponentLabel){
            componentLabel = isRequired ? component.ComponentLabel + " *" : component.ComponentLabel;
        }

        const validations = [];
        const errorMessages = [];
        if (isRequired) {
            validations.push("required");
            errorMessages.push(component.RequiredMessage);
            validations.push("IsEmpty");
            errorMessages.push(component.RequiredMessage);
        }
        if(this.state.showValidation){
            validations.push("IsInvalidFile");
            errorMessages.push(component.ValidatorMessage);
        }
        let autocomplete="off";
        let custom3=component.Custom3 && JSON.parse(component.Custom3);
        (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";


        return (
            <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} className={`grid-drag__container ${component.StyleClass}`} ref={this.dropRef}>
                <Tooltip title={componentvalue} classes={{ tooltip: classes.customWidth }} arrow >
                <TextValidator
                    autoFocus
                    // multiline
                    className="custom-input"
                    margin="normal"
                    autoComplete={autocomplete}
                    label={
                        <Typography className="custom-label-down text-font" style={styles.label}>
                            {componentLabel}
                        </Typography>
                    }
                    name={component.AttributeName}
                    placeholder={componentLabel.replace(/&nbsp;/g, '')}
                    value={componentvalue}
                    onChange = {(e) => this.onChange(e)}
                    disabled={true}
                    validators={validations}
                    errorMessages={errorMessages}
                    fullWidth
                    InputProps={{
                        inputProps: {
                            style: {
                                textOverflow: 'ellipsis',
                                cursor: "pointer"
                            }
                        }
                    }}
                />
                </Tooltip>
                <br/>
                {this.state.drag && (
                  <div className="drag__content">
                    <div className="drag__text">
                      <div>
                        <Publish style={{ fontSize: "2rem" }} />
                        <span> Drag & Drop your file here to upload</span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="upload upload-container theme_button">
                    <div className={`upload-button theme_button theme_buttonColor${component.IsReadonly?" disableButtonColor":""}`}>
                        <span className="upload-button-text theme_button">
                            <Publish/><span className='small_label'>Choose File</span>
                        </span>
                        <input
                            id="contained-button-file"
                            multiple
                            type="file"
                            accept={acceptFiles}
                            onChange={this.handleAttachmentImport}
                            className="fileInputOverflow upload-button-input"
                            disabled={component.IsReadonly}
                            autoComplete={autocomplete}
                        />
                    </div>
                   <span className={`upload-filename ${component.AttributeStyleClass}`}>{value}</span>
                   <span className="Drag-message">Drag & Drop your file here to upload</span>
                </div>
                {/* {this.state.showValidation===true && <span className="ErrorFileImport">{component.ValidatorMessage}</span>} */}
                {/* {showError && <span className="ErrorFileImport" >File Import is Mandatory</span>} */}
            </Grid>
        )
    }
}

const styles = (theme) => ({
    customWidth: {
        maxWidth: 600,
        backgroundColor: "rgb(47,53,58)",
        fontSize: "13px"
    },
    label: {
        width: '150%'
    }
});

export default withStyles(styles)(Import);
