import React, {Fragment, useState, useEffect} from 'react';
import { TableRow, TableCell } from "@material-ui/core";
import DataTableActionV2 from './DataTableActionV2';
import DetailIcon from './DetailIcon';
import DataTableDetailV2 from './DataTableDetailV2';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DataTableCheckBox from './DatatableCheckBox';
import { dynamicConditionValidation, dynamicConditionValidationV2,dynamicConditionValidationV3 } from '../../utils/Common/NoCodeUtils';
import container from '../../utils/DIContainer';
import { IconButton } from '@mui/material';
import store from '../../../../store';

function DataTableRowV2(props) {
    let [ isExpanded, setisExpanded ] = useState(false);
    let [drag,setDrag]=useState(false);
    let {  columnList, rowData, toggleSelect,ChildSubApplicationList,functions, tableActions, tableFunctions, alignTableData, children, DataTableRowIndex } = props;
    let { selectable = {}, pivotSummary = {}, editRow = {}, allowRowEdit = false, readOnly={}, expandRow = {},dragRow={} } = tableActions;
    if(dragRow.isNeeded){
        if(props.dragElement && props.dragElement.index!==null && props.dragElement.index===props.DataTableRowIndex && props.dragElement.value===null && props.dragElement.dragEnd){
            let obj={...props.dragElement};
            obj.index=null;
            obj.dragEnd=false;
            delete obj.previousIndex;
            props.tableFunctions.updateDataTableState('dragElement',obj,false);
            if(drag)
            setDrag(false);
        }
    }
    let actionListOptions = [], editRowHandler = ""
    useEffect(() => {
        if(!rowData) return null;
        if (rowData && expandRow && (expandRow.defaultExpand === true || expandRow.defaultExpand === 'true') && DataTableRowIndex === expandRow.expandDataIndex) {
            rowData['isExpanded'] = expandRow.defaultExpand
        }
    }, [])

    if(!rowData) return null;

    if(tableActions.hasOwnProperty('ActionList') && !Array.isArray(tableActions.ActionList)) actionListOptions.push(tableActions.ActionList)
    else if(tableActions.hasOwnProperty('ActionList') && Array.isArray(tableActions.ActionList)) actionListOptions = tableActions.ActionList

    let components = rowData.hasOwnProperty('rowMetaData') ? rowData['rowMetaData'] : [];
    const Controls = container.get('componentControls')
    let nonEditableRow = true;
    // TableControls.default[component.ComponentType.toLowerCase()])
    let actionRequired = actionListOptions.length > 0 ? true : false;
    // let detailRequired = TableOptions.ChildrenComponent != null || ChildSubApplicationList && ChildSubApplicationList.length > 0;
    let detailRequired = children || ChildSubApplicationList && ChildSubApplicationList.length > 0;
    // let detailRequired = childComponent.isNeeded || false;
    // let selectable = !!TableOptions.Selectable && TableOptions.Selectable;
    let singleLineRequired = tableActions.singleLineRequired ? tableActions.singleLineRequired : false
    let isRowChecked = rowData.hasOwnProperty(selectable.attributeName) && rowData[selectable.attributeName] === "true" ;
    let DisableDragRow = tableActions.dragRow && tableActions.dragRow.DisableDragRow
    if (!isRowChecked && selectable.hasOwnProperty('attributeName')) rowData[selectable.attributeName] = "false" ;
    let rowHeadingNeeded = pivotSummary.hasOwnProperty("isNeeded") ? pivotSummary.isNeeded : false;
    let rowDisplayHeading = rowHeadingNeeded && pivotSummary.hasOwnProperty("rowHeading") ? rowData[pivotSummary["rowHeading"]] : "" ;

    let BorderClass = tableActions.borderNeeded ? "BorderClass":"";
    // let align=TableOptions.ALignCenter?"center":"left"
    // CSS

    let isDisabled = false;
    let isReadOnly = false;// Needs to be removed.
    let isRead = false;

    if(tableFunctions && tableFunctions[editRow.onExpand] !== '' && tableFunctions[editRow.onExpand] !== undefined && tableFunctions[editRow.onExpand] !== null ){
        isExpanded = rowData["isExpanded"] ? (rowData["isExpanded"] === 'true' || rowData["isExpanded"] === true) : false;
        let key = props.subAppInfo['SubApplicationDetail'].ApplicationName.toLowerCase()+"_dataList";
        setisExpanded = (isExpand) => {
            tableFunctions[editRow.onExpand](key,rowData,isExpand,props);
        }
    }
    if(tableFunctions && tableFunctions[editRow.disableRowData] !== '' && tableFunctions[editRow.disableRowData] !== undefined && tableFunctions[editRow.disableRowData] !== null ){
       isDisabled = tableFunctions[editRow.disableRowData](rowData)
    }
    if(tableFunctions && tableFunctions[editRow.editRowFunction] && tableFunctions[editRow.editRowFunction] !== undefined && tableFunctions[editRow.editRowFunction] !== null)
        editRowHandler = tableFunctions[editRow.editRowFunction]
    if(readOnly &&readOnly.isReadOnly &&readOnly.condition ){
        isRead = dynamicConditionValidationV3(rowData,readOnly.condition);
        if(!isRead && props.disableColumnComponent){
            tableFunctions["updateDataTableState"]('disableColumnComponent', false, true);
        }
    }
    else if(props.disableColumnComponent) tableFunctions["updateDataTableState"]('disableColumnComponent', false,true);

    return (
        <Fragment>
            <TableRow key={props.DataTableRowIndex}
            draggable={drag}
            onDragOver={(e)=>{
                            if(e.someValue==='preventDragEvent')
                            return;
                            e.preventDefault();
                            props.DragEnter()
                        }}
            onDragStart={(e)=>{
                            props.DragStart()
                        }}
            onDragEnd={(e)=>{
                            if(e.someValue==='preventDragEvent')
                            return;
                            e.preventDefault();
                            props.DragEnd();
                        }}
            className={ "FocusAnchor_"+props.subAppInfo['SubApplicationDetail'].ApplicationName.toLowerCase()+'_dataList' } id={`${props.subAppInfo['SubApplicationDetail'].ApplicationName.toLowerCase()}[${props.DataTableRowIndex}]`} >
            {rowHeadingNeeded  && <TableCell  align={alignTableData} className={BorderClass} style={{backgroundColor: '#e5e5e5'}}>{rowDisplayHeading}</TableCell>}
                {!allowRowEdit && detailRequired && <DetailIcon onMouseDown={e=>e.preventDefault()} setisExpanded={setisExpanded} isExpanded={isExpanded}/>}
                { dragRow.isNeeded &&
                <TableCell>
                  <div>
                    <IconButton disabled = {DisableDragRow}>
                    <DragIndicatorIcon size="small"
                        className='drag-icon'
                        onMouseUp={()=>{
                            setDrag(false)
                        }}
                        onMouseDown={()=>{
                            setDrag(true)
                        }}
                        autoScroll={1}
                    style={{cursor:'grab'}} />
                 </IconButton>
                  </div>
                </TableCell>
                }
                {   selectable.isNeeded &&
                    <DataTableCheckBox
                        {...props}
                        isReadOnly={isReadOnly}
                        disabled={ !!props.dataList.length }
                        name={selectable.hasOwnProperty("attributeName") ? selectable.attributeName : "" }
                        checked={ isRowChecked }
                        defaultChecked= { isRowChecked }
                        onChange={(e) => toggleSelect(e, rowData)}
                     />

                }
                {/* {
                    actionRequired && <DataTableAction {...props} rowData={rowData}/>
                } */}
                {/* {
                    components.map((component, index)=>{
                        if(component.RenderValue){
                            const UIComponent = Controls.default[component.ComponentType.toLowerCase()];
                            let attributeValue = rowData[component.AttributeName] ? rowData[component.AttributeName] : "" ;
                            return(
                                    <TableCell
                                        key={component.ComponentId}
                                        align={alignTableData}
                                        className={`custom-classificationTableCell ${component.RowHeadingStyle} ${BorderClass}`}>
                                            <UIComponent
                                                functions={functions}
                                                component={component}
                                                attributeValue={attributeValue}
                                                componentvalue={attributeValue}
                                                transactionValue={attributeValue}
                                                isDynamicTable={true}
                                                customTable={true}
                                                customTableRowIndex={rowData}
                                                handleRowLevelInputChange={editRowHandler}
                                            />
                                    </TableCell>
                            );
                        }
                    })
                } */}
                {
                    nonEditableRow &&
                    <Fragment>
                    {/* {
                        columnList.map((component) => {
                            if(component.RenderValue){
                                if(TableControls.default[component.ComponentType.toLowerCase()]){
                                const UIComponent = TableControls.default[component.ComponentType.toLowerCase()];
                                return(
                                    <UIComponent
                                        {...props}
                                        component = {component}
                                        isDisabled={isDisabled}
                                    />
                                )
                                }
                            }
                        })
                    } */}
                    {
                        columnList.map((component, index)=>{
                            if(component.RenderValue){
                                const UIComponent = Controls[component.ComponentType.toLowerCase()];
                                let attributeValue = rowData[component.AttributeName] ? rowData[component.AttributeName] : "" ;
                                let {UIFlowReducer,metadataReducer}=store.getState();
                                if(Object.keys(component.Action).length > 0 && component.Action.cellContentDisplayCondition && Array.isArray(component.Action.cellContentDisplayCondition)){
                                    let isInvisible=false;
                                    isInvisible=dynamicConditionValidation(rowData,component.Action.cellContentDisplayCondition);
                                    if(isInvisible)
                                    return <TableCell
                                    key={component.ComponentId}
                                    align={alignTableData}
                                    className={`${component.RowHeadingStyle} ${BorderClass}`}>
                                    </TableCell>
                                }
                                if(component.Action && component.Action.cellReadOnlyCondition){
                                    let isReadOnly=dynamicConditionValidationV3({...metadataReducer,...UIFlowReducer,...rowData},component.Action.cellReadOnlyCondition);
                                    if(isReadOnly)
                                        component={...component,IsReadonly:true}
                                }
                                if(props.validationNeeded){
                                    return <TableCell
                                    key={component.ComponentId}
                                    align={alignTableData}
                                    className={`${component.RowHeadingStyle} ${BorderClass}`}>
                                    {attributeValue && <label className="red-text">{attributeValue}</label>}
                                    </TableCell>
                                }
                                return(
                                     <TableCell
                                            key={component.ComponentId}
                                            align={alignTableData}
                                            // onMouseDown={(e)=>e.preventDefault()}
                                            className={`${component.RowHeadingStyle} ${BorderClass} ${component.StyleClass} ${singleLineRequired ? 'lineBreaker' : ''}`}>
                                                 <UIComponent
                                                    {...props}
                                                    children={[]} // To not render datatable Children
                                                    functions={functions}
                                                    component={component}
                                                    attributeValue={attributeValue}
                                                    componentvalue={attributeValue}
                                                    transactionValue={attributeValue}
                                                    isDynamicTable={true}
                                                    customTable={true}
                                                    customTableRowIndex={rowData}
                                                    handleRowLevelInputChange={editRowHandler}
                                                    customTableReadOnly={isRead}
                                                    classNameParent="Remove-padding"
                                                />
                                        </TableCell>
                                );
                            }
                        })
                    }
                    </Fragment>
                }
                {
                    actionRequired && <DataTableActionV2 {...props} actionListOptions={actionListOptions} alignTableData={alignTableData} rowData={rowData} isDisabled={isDisabled}/>
                }

            </TableRow>
                {
                    (!allowRowEdit && detailRequired && isExpanded) && <DataTableDetailV2 {...props} dragNeeded={dragRow.isNeeded} isExpanded={isExpanded} actionRequired = {actionRequired}/>
                }
        </Fragment>
    )
}

export default DataTableRowV2;
