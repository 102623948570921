import React, { Component } from 'react';
import { Grid, InputLabel, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import CalendarValidator from './CalendarValidator';
import { format, isValid } from "date-fns";
import {getDateWithoutOffset, changeSOLDateformat} from '../utils/Common/DateUtils'
import { LOBUtil } from './../utils/UI_utils/CalenderUtil';
import { ValidatorForm } from 'react-material-ui-form-validator';

class Calendar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      calenderOpenClose: false
    }
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule('mindate', (value,mindate) => {
      if (value&&isValid(new Date(value))&&mindate&&isValid(new Date(mindate)))
      {
        let date1=new Date(value).toLocaleDateString("en-ZA")
        let date2=new Date(mindate).toLocaleDateString("en-ZA")
        if(new Date(date1) < new Date(date2) ){
          return false
        }
      }
       return true;
    });
    ValidatorForm.addValidationRule('maxdate', (value,maxdate) => {
      if (value&&isValid(new Date(value))&&maxdate&&isValid(new Date(maxdate)))
      {
        let date1=new Date(value).toLocaleDateString("en-ZA")
        let date2=new Date(maxdate).toLocaleDateString("en-ZA")
        if (new Date(date1) > new Date(date2)){
          return false
        }
      }
       return true;
    });
    ValidatorForm.addValidationRule("IsValidDate", (value) => {
      if (value&&!isValid(new Date(value))) {
        return false;
      }
      return true;
    });

  }


  handleCalenderOpenClose = () => {
    this.props.component.IsReadonly !== true && this.setState({ calenderOpenClose: !this.state.calenderOpenClose })
  }

  onChange = (event, datevalue) => {
    if(datevalue !== null && datevalue !== undefined ){
      if(datevalue.includes('_')){
        let value = Object.assign({}, { [this.props.component.AttributeName]: datevalue });
        this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
      }
      else{
        let formattedDatevalue = isValid(new Date(datevalue)) ? format(getDateWithoutOffset(datevalue),this.props.component.OutputFormatPattern):datevalue;
        if (this.props.customTable)
        {
          let value = Object.assign({}, { [this.props.component.AttributeName]: formattedDatevalue });
          this.props.handleDateRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex);
        }
        else
        {
          let value = Object.assign({}, { [this.props.component.AttributeName]: formattedDatevalue });
          // this.props.handleDateChange(event, datevalue, this.props.component.AttributeName, this.props.component)
          let {component}=this.props
          if(component.OnChangeAction)
          this.props[component.OnChangeAction] && this.props[component.OnChangeAction](this.props,value)
          else
            this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
        }
      }
    }else if(datevalue === null){
      let value = Object.assign({}, { [this.props.component.AttributeName]: '' });
      this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
    }
  }

  isValidDate = (date) => {

    let dateformat = /^\d{4}-\d{2}-\d{2}$/;
    if(date.match(dateformat)){
      return true;
    }
    return false
  }

  render() {
    let { component, attributeValue, componentvalue, variant } = this.props;
    let dateRange = {};
    let isReadOnly = component.IsReadonly;
    let isRequired = component.IsRequired === 'Y' ? true : false;
    const validations = [];
    const errorMessages = [];
    let {MaxValue,MinValue} =component
    let minDate,maxDate;
    let labelId = component.ComponentId + '_label';
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
    if (isRequired && !componentvalue) {
      validations.push('required');
      errorMessages.push(component.RequiredMessage);
    }
    if(!componentvalue){
      componentvalue = component.DefaultValue ? component.DefaultValue : componentvalue;
    }
    let LOB = this.props.UIFlowReducer.productDetails["LOBName"]
    if(this.isValidDate(component.MinValue) && this.isValidDate(component.MaxValue)){
      dateRange.minDate = changeSOLDateformat(component.MinValue)
      dateRange.maxDate = changeSOLDateformat(component.MaxValue)
    }
    if (LOB && LOBUtil[LOB] && LOBUtil[LOB][component.OnKeyUpAction]) {
      dateRange = LOBUtil[LOB][component.OnKeyUpAction](component, componentvalue)
    }
    if(MinValue&&MinValue!=='0'){
      validations.push('mindate:'+component.MinValue);
      // errorMessages.push(component.ValidatorMessage);
      minDate=new Date(component.MinValue)
    }
    if(MaxValue&&MaxValue!=='0')
    {
      validations.push('maxdate:'+component.MaxValue);
      // errorMessages.push(component.ValidatorMessage);
      maxDate=new Date(component.MaxValue)
    }
    validations.push('IsValidDate');
    let styleObject = {};
    if(component.AdjacentLabel === 'Y')
      styleObject = {display:'flex'};

    return (
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} className={`${component.AdjacentLabel === 'Y' ? '' : 'calendar'} custom-text`} key={component.AttributeName} style={styleObject}>
          {
            component.AdjacentLabel === 'Y' ? 
              <>
                <Grid item xs={4} sm={4} md={4} lg={4}> 
                   <InputLabel id={labelId} htmlFor={component.ComponentId} className="custom-label-down text-font adjacent_label" style={styles.label}>{component.ComponentLabel} {component.IsRequired === 'Y' ? '*' : null}</InputLabel>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} className='adjacent_TextValidator_Grid' > 
                  <CalendarValidator
                  validators={validations}
                  errorMessages={errorMessages}
                  open={this.state.calenderOpenClose}
                  onClose={() => this.handleCalenderOpenClose()}
                  onClick={() => this.handleCalenderOpenClose()}
                  format={component.InputFormatPattern}
                  disabled={isReadOnly}
                  component={component}
                  styleClass={component.StyleClass}
                  name={component.AttributeName}
                  id={component.ComponentId}
                  dateRange={dateRange}
                  autoComplete={autocomplete}
                  minDate={minDate}
                  maxDate={maxDate}
                  variant={variant}
                  value={componentvalue ? componentvalue : null}
                  onChange={(event, value) => { this.onChange(event, value) }}
                  editable={false}
                  />
                </Grid>
              </>
              :
              <CalendarValidator
              validators={validations}
              errorMessages={errorMessages}
              open={this.state.calenderOpenClose}
              onClose={() => this.handleCalenderOpenClose()}
              onClick={() => this.handleCalenderOpenClose()}
              format={component.InputFormatPattern}
              disabled={isReadOnly}
              component={component}
              styleClass={component.StyleClass}
              name={component.AttributeName}
              id={component.ComponentId}
              dateRange={dateRange}
              autoComplete={autocomplete}
              minDate={minDate}
              maxDate={maxDate}
              variant={variant}
              label={component.IsRequired === 'Y' ? !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down small_label text-font" style={styles.label}>{component.ComponentLabel} *</Typography> :
              !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down small_label text-font" style={styles.label}>{component.ComponentLabel}</Typography>}
              placeholder={component.ComponentLabel.replace(/&nbsp;/g, '')}
              value={componentvalue ? componentvalue : null}
              onChange={(event, value) => { this.onChange(event, value) }}
              editable={false}
              />
          }
      </Grid>
    )
  }
}

const styles = {
  label: {
    width: '100%'
  }
};

const mapStateToProps = (state) => {
  return{
    metadataReducer : state.metadataReducer,
    UIFlowReducer: state.UIFlowReducer
  }
}

export default connect(mapStateToProps)(Calendar)
