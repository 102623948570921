import React, {Fragment, useState} from 'react';
import { TableRow, TableCell } from "@material-ui/core";
import * as TableControls from './index';
import DataTableAction from './DataTableAction';
import DetailIcon from './DetailIcon';
import DataTableDetail from './DataTableDetail';
import DataTableCheckBox from './DatatableCheckBox';
import container from '../../utils/DIContainer';

function DataTableRow(props) {

    let [ isExpanded, setisExpanded ] = useState(false);
    let { tableUtils, TableOptions, columnList, rowData, toggleSelect,ChildSubApplicationList,functions} = props;
    if(!rowData) return null;
    let components = rowData.hasOwnProperty('rowMetaData') ? rowData['rowMetaData'] : [];
    let nonEditableRow = components.length === 0 ? true : false;
    let actionRequired = TableOptions['ActionListOptions'].length > 0 ? true : false;
    let detailRequired = TableOptions.ChildrenComponent != null || ChildSubApplicationList && ChildSubApplicationList.length > 0;
    let selectable = !!TableOptions.Selectable && TableOptions.Selectable;
    let isRowChecked = rowData.hasOwnProperty(selectable.attributeName) && rowData[selectable.attributeName] === "true" ;
    if (!isRowChecked && selectable.hasOwnProperty("attributeName"))  rowData[selectable.attributeName] = "false" ;
    const Controls = container.get('componentControls')
    let rowHeadingNeeded = TableOptions.hasOwnProperty("PivotSummary") && TableOptions.PivotSummary.hasOwnProperty("render") ?TableOptions.PivotSummary.render:false;
    let rowDisplayHeading = rowHeadingNeeded && TableOptions.PivotSummary.hasOwnProperty("rowHeading") ? rowData[TableOptions.PivotSummary["rowHeading"]] : "" ;

    if(tableUtils && tableUtils.onExpand !== '' && tableUtils.onExpand !== undefined && tableUtils.onExpand !== null ){
        isExpanded = rowData["isExpanded"] ? (rowData["isExpanded"] === 'true' || rowData["isExpanded"] === true) : false;
        let key = props.subAppInfo['SubApplicationDetail'].ApplicationName.toLowerCase()+"_dataList";
        setisExpanded = (isExpand) => {
            tableUtils.onExpand(key,rowData,isExpand,props);
        }
    }

    return (
        <Fragment>
            <TableRow className={ "FocusAnchor_"+props.subAppInfo['SubApplicationDetail'].ApplicationName.toLowerCase()+'_dataList' } >
            {rowHeadingNeeded  && <TableCell style={{backgroundColor: '#e5e5e5'}}>{rowDisplayHeading}</TableCell>}
                {detailRequired && <DetailIcon setisExpanded={setisExpanded} isExpanded={isExpanded}/>}
                {   selectable &&
                    <DataTableCheckBox
                        {...props}
                        disabled={ !!props.dataList.length }
                        name={selectable.hasOwnProperty("attributeName") ? selectable.attributeName : "" }
                        checked={ isRowChecked }
                        defaultChecked= { isRowChecked }
                        onChange={(e) => toggleSelect(e, rowData)}
                     />

                }
                {
                    components.map((component, index)=>{
                        if(component.RenderValue){
                            const UIComponent = Controls[component.ComponentType.toLowerCase()];
                            let attributeValue = rowData[component.AttributeName] ? rowData[component.AttributeName] : "" ;
                            return(
                                    <TableCell
                                        key={component.ComponentId}
                                        align="left"
                                        className={`custom-classificationTableCell ${component.RowHeadingStyle}`}>
                                            <UIComponent
                                                {...props}
                                                functions={functions}
                                                component={component}
                                                attributeValue={attributeValue}
                                                componentvalue={attributeValue}
                                                transactionValue={attributeValue}
                                                isDynamicTable={true}
                                                customTable={true}
                                                customTableRowIndex={rowData}
                                                handleRowLevelInputChange={tableUtils.EditRow}
                                            />
                                    </TableCell>
                            );
                        }
                    })
                }
                {
                    nonEditableRow &&
                    <Fragment>
                    {
                        columnList.map((component) => {
                            if(component.RenderValue){
                                if(TableControls.default[component.ComponentType.toLowerCase()]){
                                const UIComponent = TableControls.default[component.ComponentType.toLowerCase()];
                                return(
                                    <TableCell>
                                    <UIComponent
                                        {...props}
                                        component = {component}
                                    />
                                    </TableCell>
                                )
                                }
                            }
                        })
                    }
                    </Fragment>
                }
                {
                    actionRequired && <DataTableAction {...props} rowData={rowData}/>
                }
            </TableRow>
                {
                    (detailRequired && isExpanded) && <DataTableDetail {...props} isExpanded={isExpanded}/>
                }
        </Fragment>
    )
}

export default DataTableRow;
