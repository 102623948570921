export const model_XMLRequest = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
'<DROOLS_REQUEST_DETAIL xmlns:ns2="http://www.solartis.net/schema/ServiceRequest">' +
    '<ServiceRequestDetail>' +
        '<ns2:ServiceRequestVersion>1.0</ns2:ServiceRequestVersion>' +
        '<ns2:ServiceResponseVersion></ns2:ServiceResponseVersion>' +
        '<ns2:OwnerId>{OWNER_ID}</ns2:OwnerId>' +
        '<ns2:Token>{TOKEN}</ns2:Token>' +
        '<ns2:UserName>{USER_NAME}</ns2:UserName>' +
        '<ns2:LanguageCode></ns2:LanguageCode>' +
        '<ns2:BrowserIp></ns2:BrowserIp>' +
    '</ServiceRequestDetail>' +
    '<ATTRIBUTE_DETAILS>' +
        '<ATTRIBUTE_DETAIL KEY="Object::GenerateRequest::ContentType">json</ATTRIBUTE_DETAIL>' +
        '<ATTRIBUTE_DETAIL KEY="Object::OwnerId">{OWNER_ID}</ATTRIBUTE_DETAIL>' +
        '<ATTRIBUTE_DETAIL KEY="Object::EventName">GenerateRequestJson_V3</ATTRIBUTE_DETAIL>' +
        '<ATTRIBUTE_DETAIL KEY="Active">Y</ATTRIBUTE_DETAIL>' +
        '<ATTRIBUTE_DETAIL KEY="Object::GenerateJSon::EventName">{EVENT_NAME}</ATTRIBUTE_DETAIL>' +
        '<ATTRIBUTE_DETAIL KEY="Object::GenerateJSon::EventVersion"></ATTRIBUTE_DETAIL>' +
        '<ATTRIBUTE_DETAIL KEY="Object::GenerateJSon::OwnerId">{OWNER_ID}</ATTRIBUTE_DETAIL>' +
        '<ATTRIBUTE_DETAIL KEY="Object::Customer::OwnerId">{OWNER_ID}</ATTRIBUTE_DETAIL>'+
        '<ATTRIBUTE_DETAIL KEY="Object::Product::ProductVerNumber">{PRODUCTVERNUMBER}</ATTRIBUTE_DETAIL>'+
        '<ATTRIBUTE_DETAIL KEY="Object::Product::ProductNumber">{PRODUCTNUMBER}</ATTRIBUTE_DETAIL>'+
        '<ATTRIBUTE_DETAIL KEY="Object::GenerateJSon::Type">REQUEST</ATTRIBUTE_DETAIL>' +
        '<ATTRIBUTE_DETAIL KEY="Object::GenerateJSon::LOB">{LOB}</ATTRIBUTE_DETAIL>'+
    '</ATTRIBUTE_DETAILS>'+
'</DROOLS_REQUEST_DETAIL>';
