import React,{ Suspense } from 'react';
import { AppAside } from '@coreui/react';
import {
    IconButton,
    List,
    ListItem
  } from "@material-ui/core";
  import { AppAsideToggler } from "@coreui/react";
  import { CodeRounded } from "@material-ui/icons";
const DefaultAside= React.lazy(()=>  import('../../../../../containers/DefaultLayout/DefaultAside'));
function RightSideBar(props) {
    return (
        <React.Fragment>
              <div className="loglist">
                <List component="nav">
                  <ListItem alignItems="flex-start" disableGutters="false">
                    <AppAsideToggler className="" style={{ padding: "0px" }}>
                      <IconButton
                        title="Show Request/Response"
                        component="span"
                      >
                        <CodeRounded />
                      </IconButton>
                    </AppAsideToggler>
                  </ListItem>
                </List>
              </div>
            <AppAside fixed className="aside-header">
                <Suspense>
                <DefaultAside />
                </Suspense>
            </AppAside>
        </React.Fragment>
    );
}

export default RightSideBar;
