import React ,{ Fragment } from 'react';
import {  TextField, TableRow, TableCell } from "@material-ui/core";

function CustomFilterRowV2(props) {

    let { columnList,  columnOptions,  ChildSubApplicationList, tableActions, children } =props;
    let { filtering, selectable = {}, pivotSummary  = {}, dragRow={} } = tableActions;
    let filterNeeded = filtering && (Array.isArray(filtering.columns) && filtering.columns.length > 0) ? filtering.columns : []
    let actionListOptions = []
    if(tableActions.hasOwnProperty('ActionList') && !Array.isArray(tableActions.ActionList)) actionListOptions.push(tableActions.ActionList)
    else if(tableActions.hasOwnProperty('ActionList') && Array.isArray(tableActions.ActionList)) actionListOptions = tableActions.ActionList
    let actionRequired = actionListOptions.length > 0 ? true : false;
    let detailRequired = children || ChildSubApplicationList && ChildSubApplicationList.length > 0;
    let isFilterKeyNeeded = tableActions.filterLazyLoading && tableActions.filterLazyLoading.isFilterKeyNeeded || false;
    // let selectable = Selectable.render || false;
    // let rowHeadingNeeded = TableOptions.hasOwnProperty("PivotSummary") && TableOptions.PivotSummary.hasOwnProperty("render") ?TableOptions.PivotSummary.render:false;

    return (
        <Fragment>
            <TableRow style={{backgroundColor: '#e5e5e5'}} className='theme_datatableFilter'>
            {/* { actionRequired && <TableCell />} */}
                { (detailRequired || pivotSummary.isNeeded)  && <TableCell className='small_cell' />}
                { dragRow.isNeeded && <TableCell className='small_cell' />}
                { selectable.isNeeded && <TableCell className='small_cell' />}
                {
                    columnList.map((component) => {
                        if (component.RenderValue)
                        {
                            let componentId = component.ComponentId;
                            return (
                                <TableCell
                                    key={componentId+"_filterArea"}
                                    align="left"
                                    size="small"
                                    className="dt-filterCell"
                                >
                                    {
                                        (filtering.isNeeded || filterNeeded.includes(component.AttributeName)) &&
                                            <TextField
                                                id={`${componentId}_filterText`}
                                                name={isFilterKeyNeeded ? `${componentId}_Filter`: componentId}
                                                className="custom-input small_filter_input"
                                                value={columnOptions.filtercriteria[isFilterKeyNeeded ? `${componentId}_Filter`: componentId] || ""}
                                                onChange={(e) => columnOptions.handleFilter(e)}
                                            />
                                    }
                                </TableCell>
                            )
                        }
                    })
                }
                 { actionRequired && <TableCell />}
            </TableRow>
        </Fragment>
    )
}

export default CustomFilterRowV2;
