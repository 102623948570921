import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { AppHeader } from '@coreui/react';
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};


class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      userName: ''
    }
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.setState({
        firstname: this.props.userInfoReducer.Firstname,
        lastname: this.props.userInfoReducer.LastName
      })
    }, 2000)
  }

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }

  render() {

    const { t } = this.props;
    const { userLanguage, languageCode,imageFormat,mini_logo_path } = this.props.ownerResources;

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    // const userName = localStorage.getItem('userName')
    // UserName changed to Solartis Underwriter for solunderwriter
    if (this.state.firstname !== undefined && this.state.firstname !== null && this.state.firstname !== '' &&
      this.state.lastname !== undefined && this.state.lastname !== null && this.state.lastname !== '') {
      this.state.userName = this.state.lastname + ',' + this.state.firstname;
    }
    else if (this.state.firstname !== undefined && this.state.firstname !== null && this.state.firstname !== '') {
      this.state.userName = this.state.firstname;
    }
    else {
      this.state.userName = localStorage.getItem('userName')
    }


    let avatarLetter = '';
    if (this.state.userName !== '' && this.state.userName !== undefined && this.state.userName !== null) {
      avatarLetter = this.state.userName.charAt(0);
    }

    return (

      <React.Fragment>
          <AppHeader className="theme_header" fixed>
            <Suspense>
            {this.props.children}
             {/* <Header_Logo></Header_Logo> */}
            </Suspense>
        </AppHeader>
      </React.Fragment>

    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  ownerResources: state.ownerResources,
  userInfoReducer: state.userInfoReducer
});

export default withTranslation()(connect(mapStateToProps)(DefaultHeader));
