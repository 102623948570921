import React from 'react';
import PropTypes from 'prop-types';
import { AppFooter } from '@coreui/react';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

function NonStickyFooter(){

  return (
    <React.Fragment>
      <AppFooter className="custom-footer footer-margin">
        <span><a href="https://solartis.com" target="_blank" rel="noopener noreferrer">Solartis</a> &copy; {new Date().getFullYear()} </span>
        <span className="ml-auto">Powered by <a href="https://solartis.com" target="_blank" rel="noopener noreferrer">Solartis</a></span>
      </AppFooter>
    </React.Fragment>
  );
}

NonStickyFooter.propTypes = propTypes;
NonStickyFooter.defaultProps = defaultProps;

export default NonStickyFooter;