import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Dialog, DialogTitle, DialogActions, Button, IconButton} from '@material-ui/core'
import { Close, SystemUpdateAlt } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { commonWorkflowAxios, getJSONRequestFromAPI } from '../../utils/Common/API';
import { downloadFile } from '../../../../CommonAxios/FileHandlerAxios';
import appActions from "../../../../actions/index";
import {  triggerToast } from "../../utils/Common/HelperUtils";

const { actionType } = appActions

const FileExport = (props) => {

    const UIFlowReducer = useSelector(state => state.UIFlowReducer);
    const productDetails = useSelector(state => state.UIFlowReducer.productDetails);
    const metaReducer = useSelector((state) => {
        return state.metadataReducer
    });
    let exportType = metaReducer.exportType;
    const dispatch = useDispatch();

    const [exportStatusFilePath, setexportStatusFilePath] = useState(null);
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState(false);
    let { ExportEventName } = props.importExportProperties;
    if(props.importExportProperties["ExportDetails"] && exportType){
        ExportEventName = props.importExportProperties["ExportDetails"][exportType]['ExportEventName'] ? props.importExportProperties["ExportDetails"][exportType]['ExportEventName'] : "";
    }

    useEffect(()=>{
        if(exportStatusFilePath){
            exportStatus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportStatusFilePath])

    const exportStatus = () => {
        if (exportStatusFilePath) {
            let FileName = exportStatusFilePath.substring(exportStatusFilePath.lastIndexOf('/') + 1);
            let path     = exportStatusFilePath.replace(FileName,'');
            // console.log("********StatusFilePath********", exportStatusFilePath, FileName, path);

            try {
                downloadFile(FileName,path);
                setError(false);
            }catch(err){
                console.log("Error in While Downloading the File",err.message);
                setError(true);
            }
        }
    }

    const onExport = async() => {
        try {
            setLoader(true);

            const exportRequest = await getJSONRequestFromAPI(ExportEventName, productDetails)
            exportRequest['SubmissionID'] = UIFlowReducer.submissionID;
            if(exportType === 'GenesysFormatByQuote'){
                exportRequest["ExportType"] = "Quote";
                exportRequest["QuoteID"] = UIFlowReducer.quoteID;
            }


            let exportResponse = await commonWorkflowAxios.post('/core/invokeWorkflowEvent/V2',exportRequest);
            if(exportResponse.data.hasOwnProperty('ResponseStatus') && exportResponse.data.ResponseStatus.toLowerCase() === "failed"){
                throw new Error("Response Failed");
              }
            else{
            exportResponse = await exportResponse.data;

            if(Object.keys(exportResponse).length > 0){
                if(exportResponse.ExportFilePath){
                    setError(false);
                    setexportStatusFilePath(exportResponse.ExportFilePath);
                }else{
                    setexportStatusFilePath(null);
                    setError(true);
                }
            }
        }
        }
        catch (error) {
            console.log("Error in While Exporting File", error.message);
            triggerToast(error.message)
            setError(true);
        } finally {
            setLoader(false);
        }
    }

    const handleClose = () => {
        dispatch({
            type: actionType.TRIGGER_EXPORT,
            payload: {
                triggerExport : false,
                exportType: ""
            }
        })
        props.closeDialog(false);
    }

    const handleError = () => {

    }

    return (
        <Fragment>
            <Dialog disableBackdropClickfullWidth={true}
                className="fullWidthDialogue"
                maxWidth = {'sm'}
                disableEscapeKeyDown
                open={props.open}
                aria-labelledby="form-dialog-title"
            >
                <ValidatorForm  onSubmit={onExport} onError={handleError}>
                    <div style={{display:'flex'}}>
                        <DialogTitle id="form-dialog-title" >
                            <span>Export File</span>
                        </DialogTitle>
                        <span style={{marginLeft:"auto",marginRight:'.5rem', alignSelf:'center'}}>
                            <IconButton><Close onClick={handleClose} /></IconButton>
                        </span>
                    </div>
                    <DialogActions className="mt-1-5" >
                        {
                            <Fragment>
                                <Button
                                    startIcon={!loader && <SystemUpdateAlt className="mr-1" />}
                                    className="ImportStatusButton primaryButton"
                                    onClick={onExport}
                                >
                                    {
                                        loader ? <CircularProgress className="buttonLoader" /> : 'Export'
                                    }
                                </Button>
                                {error && <span className="ErrorFileImport" >Problem in Export, Try again</span>}
                            </Fragment>
                        }
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </Fragment>
    );
};

export default FileExport;
