import React, {Fragment} from 'react';
import TablePagination from '@material-ui/core/TablePagination';

function DataTablePaginationV2(props) {

    let { dataList, dataListSize, paginationProperties, tableActions, pagination} = props;
    let { lazyLoading = {} } = tableActions;
    let tableSize = dataList.length;
    if(lazyLoading.isNeeded){
        tableSize = dataListSize;
    }

    return (
        <Fragment>
            <TablePagination
                rowsPerPageOptions={pagination.rowsPerPageOptions}
                component="div"
                className='data_table_footer'
                count={tableSize}
                rowsPerPage={paginationProperties.rowsPerPage}
                page={paginationProperties.page}
                onChangePage={paginationProperties.changePage}
                onChangeRowsPerPage={paginationProperties.changeRows} 
            />
        </Fragment>
    )
}

export default DataTablePaginationV2;
