import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};


class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      userName: ''
    }
  }

  componentDidMount() {
    this.myInterval = setTimeout(() => {
      this.setState({
        firstname: this.props.userInfoReducer.Firstname,
        lastname: this.props.userInfoReducer.LastName
      })
    }, 2000)
  }

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }

  render() {

    const { t } = this.props;
    const { userLanguage, languageCode,imageFormat,mini_logo_path } = this.props.ownerResources;
  //console.log('NANDHU',mini_logo_path,this.props.ownerResources)
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    // const userName = localStorage.getItem('userName')
    // UserName changed to Solartis Underwriter for solunderwriter
    if (this.state.firstname !== undefined && this.state.firstname !== null && this.state.firstname !== '' &&
      this.state.lastname !== undefined && this.state.lastname !== null && this.state.lastname !== '') {
      this.state.userName = this.state.lastname + ',' + this.state.firstname;
    }
    else if (this.state.firstname !== undefined && this.state.firstname !== null && this.state.firstname !== '') {
      this.state.userName = this.state.firstname;
    }
    else {
      this.state.userName = localStorage.getItem('userName')
    }


    let avatarLetter = '';
    if (this.state.userName !== '' && this.state.userName !== undefined && this.state.userName !== null) {
      avatarLetter = this.state.userName.charAt(0);
    }

    return (
      <React.Fragment>
        <div className="app-navbar-brand customLogoBorder">
       <table>
        <tr>
          <td>
          <AppNavbarBrand
            className="d-md-down-none" display="md"
            full={{ src: mini_logo_path, width: 100, height: 45, alt: 'CoreUI Logo' }}
            minimized={{ src: mini_logo_path, width: 30, height: 30, alt: 'CoreUI Logo' }}
          />
          </td>
          <td className='headersidebar'>
          <AppSidebarToggler className="d-md-none" style={{ height: '54px' }} display="md" mobile />
          <span onClick={this.props.onToggle}>
          <AppSidebarToggler className="d-sm-down-none" display="lg" />
          </span>
          </td>
          </tr>
          </table>
        </div>

      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  ownerResources: state.ownerResources,
  userInfoReducer: state.userInfoReducer
});

export default withTranslation()(connect(mapStateToProps)(DefaultHeader));
