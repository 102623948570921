import axios from "axios";
import { expressServerBaseURL, ownerId, mode, environment } from "./config";
import { model_XMLRequest } from "../../models/GenerateRequest";
import store from "../../../../store";
import { includeInterceptor, includeCommonPrdInterceptor } from "../LoggerUtil/Interceptors";
import { mimeType, extensionType } from "./config";
import { format } from "date-fns";

export const getServiceRequestDetail = () => {
  const serviceRequestDetail = JSON.parse(
    JSON.stringify(require("../../models/ServiceRequestDetail.json"))
  );
  serviceRequestDetail.UserName = localStorage.getItem("userName");
  serviceRequestDetail.OwnerId = sessionStorage.getItem("OwnerID") !== null ? sessionStorage.getItem("OwnerID") : ownerId.toString();
  return serviceRequestDetail;
};

export const GetCommonEventName = () => {
  const {ownerResources, metadataReducer,UIFlowReducer} = store.getState()
  let LOB = UIFlowReducer.productDetails.LineOfBusiness ? UIFlowReducer.productDetails.LineOfBusiness : UIFlowReducer.productDetails.LOBName
  let EventName = ownerResources.clientInfo[`CommonEventName_${LOB}`] ?ownerResources.clientInfo[`CommonEventName_${LOB}`] : ownerResources.clientInfo[`CommonEventName`] ;
  return EventName;
  // return LOB === 'PL' ? CommonEventName_PL : LOB === 'MPL' ? CommonEventName_MPL  :  LOB === 'CL' ? CommonEventName_CL : LOB === "SWP" ? CommonEventName_PL : ''
 }

export const getNavigationRequest = async (groupName, productVerNum, values) => {
  let reduxState = store.getState()
  let clientInfo = reduxState.ownerResources.clientInfo
  let request= {}
  let effectiveDate = values && values.EffectiveDate ? values.EffectiveDate : format(new Date(), 'MM/dd/yyyy')
  request.ServiceRequestDetail = await getServiceRequestDetail();
  request.EventName = "SolartisCommonMaster_WF"
  request.TargetEventName = clientInfo.NavigationEventName && clientInfo.NavigationEventName != '' ? clientInfo.NavigationEventName : "GetNavigationInfoV3";
  request.FlowName = clientInfo && clientInfo.RuntimeName ? clientInfo.RuntimeName : ''
  // request.TargetEventName = "GetNavigationInfo"
 //need to check request.OwnerID = (sessionStorage.getItem("OwnerID") === "") ? ownerId.toString() : sessionStorage.getItem("OwnerID")+""
  request.OwnerID =  ownerId.toString()
  request.GroupName = groupName
  request.ProductVerNum = (productVerNum && productVerNum.length > 0) ? productVerNum : []
  request.EffectiveDate = request.ApplicableDate = effectiveDate
  request.NavigationCriteria = {FLOW: reduxState.metadataReducer.Flow, ...reduxState.UIFlowReducer.navigationCriteria}
  return request
}


export const generateJSONRequestAxios = axios.create({
  withCredentials: true,
  baseURL: expressServerBaseURL,
  headers: {
    "Content-Type": "application/json",
    // 'Token': token,
    MODE: mode,
    Environment: environment,
    EventName: "GenerateRequestJson_V3",
    OwnerId: 1,
  },
  responseType: "xml",
});

includeInterceptor(generateJSONRequestAxios);

//----------XML Request Construction for Dynamic Request Retrieval-----------------
export const getXMLRequest = async (eventName, productDetails) => {

  let xmlrequest = model_XMLRequest;
  xmlrequest = xmlrequest
    .replace("{EVENT_NAME}", eventName)
    .replace("{USER_NAME}", localStorage.getItem("userName"))
    .replace("{LOB}", "ALL")
    .replace("{PRODUCTVERNUMBER}", "PL_4742_12")
    .replace("{PRODUCTNUMBER}", "PL_4742_12_V1");
  return xmlrequest;
};

export const getCommonAPIXMLRequest = async (eventName) => {

  const reduxState = store.getState();
  let clientInfo = reduxState.ownerResources.clientInfo
  let xmlrequest = model_XMLRequest;
  xmlrequest = xmlrequest
    .replace("{EVENT_NAME}", eventName)
    .replace("{USER_NAME}", localStorage.getItem("userName"))
    .replace("{LOB}", "ALL")
    .replace("{PRODUCTNUMBER}", "PL_4742_12")
    .replace("{PRODUCTVERNUMBER}", "PL_4742_12_V1");
  return xmlrequest;
};

//----------XML -> JSON Mapper for Dynamic Request Retrieval---------------------
export const getJSONRequestFromAPI = async (eventName, productDetails, version) => {
  let xmlrequest
  //console.log("PRODUCT DETAILS========>",productDetails)
  if(version === 'V3' || version === 'V4') {
    xmlrequest = await getCommonAPIXMLRequest(eventName);

  }
  else{
    xmlrequest = await getXMLRequest(eventName, productDetails);
  }
  let actualJSONRequest = {};
  let request = {};
  request.xmlrequest = xmlrequest;

  try {
    actualJSONRequest = await generateJSONRequestAxios.post("core/generateJSONRequest",request);
    actualJSONRequest = await actualJSONRequest.data;

  } catch (error) {
    console.log("Error in Request geneneration", error);
  }

  return actualJSONRequest;
};

export const authAxios = axios.create({
  withCredentials: true,
  baseURL: expressServerBaseURL,
  headers: {
    "Content-Type": "application/json",
    Mode: mode,
    Environment: environment,
  },
  responseType: "json",
});

includeInterceptor(authAxios,true);

export const AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: expressServerBaseURL,
  headers: {
    "Content-Type": "application/json",
    // 'Token': token,
    MODE: mode,
    Environment: environment,
    OwnerId: 1,
  },
  responseType: "json",
});

includeInterceptor(AxiosInstance);

export const commonProductAxiosInstance = axios.create({
  withCredentials: true,
  baseURL: expressServerBaseURL,
  headers: {
    "Content-Type": "application/json",
    // 'Token': token,
    MODE: mode,
    Environment: environment,
    OwnerId: 1,
  },
  responseType: "json",
});

includeCommonPrdInterceptor(commonProductAxiosInstance);

export const constructMetaDataRequest = (subAppList, transactionValues) => {
  let request = JSON.parse(
    JSON.stringify(require("../../models/metadataRequest.json"))
  );
  request.ServiceRequestDetail = getServiceRequestDetail();
  let subApplicationList = [];
  subAppList.map((subAppName) => {
    subApplicationList.push({
      SubApplicationName: subAppName,
    });
  });
  request.SubApplicationNameList = subApplicationList;
  let transactionKeyList = [];
  transactionKeyList.push({
    key: "Object::Quote::EffectiveDate",
    value: "2020-07-01",
  });

  Object.keys(transactionValues).forEach((key) => {
{
      if (
        typeof transactionValues[key] === "string" ||
        typeof transactionValues[key] === "boolean" ||
        typeof transactionValues[key] === "number"
      ) {
        transactionKeyList.push({
          key: key,
          value: transactionValues[key],
        });
      } else if (typeof transactionValues[key] === "object") {
      }
}
  });

  transactionKeyList.push({
    key: "ExperienceRatingApplies",
    value: "No",
  });
  transactionKeyList.push({
    key: "ScheduleRatingModificationApplies",
    value: "No",
  });

  if (transactionKeyList.length > 0) {
    request.TransactionPrimaryKey.application = transactionKeyList;
  }

  return request;
};

export const metadataAxios = AxiosInstance;

export const getAccountAxios = AxiosInstance;

export const getListAxios = AxiosInstance;

export const createQuoteAxios = AxiosInstance;

export const getSubmissionAxios = AxiosInstance;

export const SaveUnderwritingQuestionsAxios = AxiosInstance;

export const LocationandBuildingAxios = AxiosInstance;

export const commonWorkflowAxios = AxiosInstance;

export const retrieveProductDetails = () => {
  const request = constructMetaDataRequest(["GetProductDetails"], {});
  metadataAxios
    .post("core/getmetadata", request)
    .then((response) => {
      const productMetadata = response.data;
      if (productMetadata.ApplicationDetail) {
        const productAttributeList =
          productMetadata.ApplicationDetail.SubApplicationDetailList[0]
            .AttributeDetailList;
        const productInfo = {};
        productAttributeList.forEach((attribute) => {
          productInfo[attribute.AttributeName] = attribute["AttributeValue"];
        });
        store.dispatch({
          type: "SET_PRODUCT_INFO",
          payload: productInfo,
        });
      }
    })
    .catch((error) => {
      console.error("Error while retrieving Product Details!", error);
    });
};

export const getMimeType = extenstion =>{
	if(extenstion){
		return mimeType[extenstion.toLowerCase()]
	}else{
		return 'application/json'
	}
}
export const getFileExtension = extenstion => {
  if (extenstion) {
      return extensionType[extenstion.toLowerCase()] || '';
  } else {
      return '';
  }
};
