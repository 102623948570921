import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import PaletteIcon from '@mui/icons-material/Palette'
import { Grid, Typography } from "@material-ui/core";
import { triggerToast } from "../utils/Common/HelperUtils";

class ColorPalette extends Component {
  constructor(props) {
    super(props);
    this.colorPaletteRef = React.createRef();
    this.state = {
      isColorPickerVisible: false,
      selectedColor: '',
      colors: {
        bgcolor: '',
        fontcolor: ''
      },
      option: {
        selectedoption: ''
      }
    };
  }



  componentDidMount=()=>{
    let colors = this.props.pagelevelValues.ColorPalette
    let option = this.props.pagelevelValues.ColorPalette.SelectedOption = ''
    let {AttributeName} =this.props.component
    this.setState((prevState)=>({
      ...prevState,
      colors: {
        bgcolor: colors.Background[AttributeName],
        fontcolor: colors.Font[AttributeName]
      },
      option: {
        selectedoption: option.SelectedOption
      }
    }))
  }

  handleColorChange = (color) => {
    try {
      let component = this.props.component;
      let SelectedOption = this.props.pagelevelValues.ColorPalette.SelectedOption;
      if (SelectedOption === "Background") {
        this.setState((prevState) => ({
          ...prevState,
          colors: {
            ...prevState.colors,
            bgcolor: color.hex,
          },
        }));
      } else if (SelectedOption === "Font") {
        this.setState((prevState) => ({
          ...prevState,
          colors: {
            ...prevState.colors,
            fontcolor: color.hex,
          },
        }));
      }
      const colors = SelectedOption;
      let colorPalette = this.props.componentState && this.props.componentState.ColorPalette ? this.props.componentState.ColorPalette : {}
      this.props.UpdateState("ColorPalette", {
        ...colorPalette,
        [colors]: {
          ...colorPalette[colors],
          [component.AttributeName]: color.hex,
        }
      }, true)
    } catch (exception) {
      triggerToast("Select an Option");
      console.log(exception);
    }
  };

  handleToggleColorPalette = () => {
    let myProps = this.props.pagelevelValues && this.props.pagelevelValues.ColorPalette && this.props.pagelevelValues.ColorPalette.SelectedOption

   if(myProps && myProps !==''){
    ColorPalette.closeAllColorPalettes(this);
    this.setState((prevState) => ({
      isColorPickerVisible: !prevState.isColorPickerVisible,
    }), () => {
      if (this.state.isColorPickerVisible) {
        document.addEventListener('click', this.handleGlobalClick);
      } else {
        document.removeEventListener('click', this.handleGlobalClick);
      }
    });
  }else{
    triggerToast("Select an Option");
  }
  };


  static openColorPaletteInstances = [];

  static closeAllColorPalettes = (currentInstance) => {
    for (const instance of ColorPalette.openColorPaletteInstances) {
      if (instance !== currentInstance) {
        instance.setState({ isColorPickerVisible: false });
      }
    }
  };

  componentDidMount() {
    ColorPalette.openColorPaletteInstances.push(this);
    window.addEventListener('click', this.handleGlobalClick);
  }


  componentWillUnmount() {
    const index = ColorPalette.openColorPaletteInstances.indexOf(this);
    if (index !== -1) {
      ColorPalette.openColorPaletteInstances.splice(index, 1);
    }
    window.removeEventListener('click', this.handleGlobalClick);
  }


  handleGlobalClick = (event) => {
    if (this.state.isColorPickerVisible && this.colorPaletteRef.current) {
      if (!this.colorPaletteRef.current.contains(event.target)) {
        this.setState({ isColorPickerVisible: false });
      }
    }
  }

  render() {

    const isOpen = this.state.isColorPickerVisible;
    let myProps = this.props.pagelevelValues && this.props.pagelevelValues.ColorPalette && this.props.pagelevelValues.ColorPalette.SelectedOption
    let paletteColor = myProps === "Background" ? this.state.colors.bgcolor : (myProps === "Font" ? this.state.colors.fontcolor : "")

    return (
      <React.Fragment>
          <Grid item xs={4} style={{margin:"3x 0"}}>
            <Typography variant="body2" gutterBottom style={styles.compText}> {this.props.component.ComponentLabel} </Typography>
          </Grid>
          <Grid item xs={4} style={styles.alignCenter}>
            <div style={{ backgroundColor: paletteColor, ...styles.colorPreview }}></div>
          </Grid>
          <Grid item xs={4} style={styles.alignCenter} ref={this.colorPaletteRef}>
            <PaletteIcon onClick={this.handleToggleColorPalette} />
            {isOpen && (
              <div style={styles.position}>
                <div style={{ position: "absolute" }}>
                  <SketchPicker
                    color={paletteColor}
                    onChange={this.handleColorChange}
                    style={{ zIndex: 100 }}
                  />
                </div>
              </div>
            )}
          </Grid>
      </React.Fragment>
    );
  }
}

const styles = {
  colorPreview: {
    borderRadius: "4px",
    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px ",
    width: "35px",
    height: "35px",
    marginTop: "10px",
    marginBottom: "10px"
  },
  compText: {
    marginTop: "8px",
    fontSize: "18px",
    fontFamily: "Roboto",
    width: "200px",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  position: {
    position: "fixed",
    top: "200px",
    right: "450px",
    zIndex: 100,
  }

}

export default ColorPalette;







