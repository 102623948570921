import React, { Component, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppAsideToggler, AppNavbarBrand, AppSidebarToggler, AppAside } from '@coreui/react';
import { Avatar } from '@material-ui/core';
// import logo from '../../assets/img/logo/login-logo-new.png';
// import sygnet from '../../assets/img/logo/logo-mini-lg.png';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};
const DefaultAside = React.lazy(() => import('./DefaultAside'));

class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      userName: ''
    }
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.setState({
        firstname: this.props.userInfoReducer.Firstname,
        lastname: this.props.userInfoReducer.LastName
      })
    }, 2000)
  }

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }

  render() {

    const { t } = this.props;
    const { userLanguage, languageCode,imageFormat,mini_logo_path } = this.props.ownerResources;

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    // const userName = localStorage.getItem('userName')
    // UserName changed to Solartis Underwriter for solunderwriter
    if (this.state.firstname !== undefined && this.state.firstname !== null && this.state.firstname !== '' &&
      this.state.lastname !== undefined && this.state.lastname !== null && this.state.lastname !== '') {
      this.state.userName = this.state.lastname + ',' + this.state.firstname;
    }
    else if (this.state.firstname !== undefined && this.state.firstname !== null && this.state.firstname !== '') {
      this.state.userName = this.state.firstname;
    }
    else {
      this.state.userName = localStorage.getItem('userName')
    }


    let avatarLetter = '';
    if (this.state.userName !== '' && this.state.userName !== undefined && this.state.userName !== null) {
      avatarLetter = this.state.userName.charAt(0);
    }

    return (
      <React.Fragment>
        <div className="app-navbar-brand">
          <AppAside fixed>
            <Suspense>
              <DefaultAside />
            </Suspense>
          </AppAside>
          <AppSidebarToggler className="d-md-none" style={{ height: '54px' }} display="md" mobile />
          <AppNavbarBrand
            className="d-md-down-none" display="md"
            full={{ src: mini_logo_path, width: 89, height: 23, alt: 'CoreUI Logo' }}
            minimized={{ src: mini_logo_path, width: 30, height: 30, alt: 'CoreUI Logo' }}
          />

         <span onClick={this.props.onToggle}>
           <AppSidebarToggler className="d-sm-down-none" display="lg" />
        </span>
        </div>
        {/* <Nav className="d-md-down-none" navbar>
           <NavItem>
            <AppSidebarToggler className="d-lg-none" display="md" mobile />
            <AppSidebarToggler className="d-md-down-none" display="lg" />
          </NavItem>
          <NavItem className="px-3">
             <NavLink to="/dashboard" className="nav-link" >Dashboard</NavLink>
            <NavLink to="/dashboard" className="nav-link" >{t('Dashboard')}</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <Link to="#" className="nav-link">{t('Manage')}</Link>
          </NavItem>
          <NavItem className="px-3">
            <NavLink to="/find" className="nav-link">{t('Find')}</NavLink>
          </NavItem>
        </Nav> */}
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink to="/find" className="nav-link"><i className="icon-magnifier custom-icon"></i>{/* <Badge pill color="danger">1</Badge> */}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="#" className="nav-link"><i className="icon-bell custom-icon custom-icon-bell-size"></i>{/* <Badge pill color="danger">1</Badge> */}</NavLink>
          </NavItem>
          <NavItem className="d-md-down-none hide">
            <NavLink to="#" className="nav-link"><i className="icon-list"></i></NavLink>
          </NavItem>
          {/* <NavItem className="mr-4">
            <NavLink to="#" className="nav-link"><i className="flag-icon flag-icon-us h4 custom-flag" style={{borderRadius: "20px", fontSize: "19px"}}></i></NavLink>
          </NavItem> */}
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              {
                userLanguage === 'en' ?
                  <Avatar className="avatar custom-flag-avatar flag-icon flag-icon-us">{' '}</Avatar> :
                  <Avatar className="avatar custom-flag-avatar flag-icon flag-icon-fr">{' '}</Avatar>
              }
            </DropdownToggle>
            <DropdownMenu right>
              {
                languageCode.map((languageInfo) => (
                  <DropdownItem onClick={() => this.props.changeLanguageCode(languageInfo['LanguageCode'])} ><i className={`flag-icon ${languageInfo['LanguageIcon']}`}></i>{languageInfo['LanguageName']}</DropdownItem>
                ))
              }
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem className="d-md-down-none">
            <h6>Hi, {this.state.userName}</h6>
          </NavItem>
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              <Avatar className="avatar custom-avatar-size">{avatarLetter}</Avatar>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem key="DPItem_welcome" header tag="div" className="text-center"><strong>Welcome {this.state.userName}!</strong></DropdownItem>
              {/*<DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem>
              <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
              <DropdownItem key="DPItem_profie"><i className="fa fa-user"></i> Profile</DropdownItem>
              <DropdownItem key="DPItem_settings"><i className="fa fa-wrench"></i> Settings</DropdownItem>
              <DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>
              <DropdownItem divider />
              <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem>*/}
              <DropdownItem key="DPItem_signout"onClick={e => this.props.onLogout(e)}><i className="fa fa-lock"></i>Sign out</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <AppAsideToggler className="d-md-down-none hide" />
        <AppAsideToggler className="d-lg-none" mobile />
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  ownerResources: state.ownerResources,
  userInfoReducer: state.userInfoReducer
});

export default withTranslation()(connect(mapStateToProps)(DefaultHeader));
