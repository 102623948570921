import React from 'react';
import { FileHandlerAxios,FileHandlerAxiosV2 } from '../../../CommonAxios/FileHandlerAxios';
import Link from '@material-ui/core/Link'
import { connect } from 'react-redux'

class Document extends React.Component {

  getDocument = async (e) => {

    e.preventDefault();
    if(this.props.component.ItemTipParams && this.props.component.ItemTipParams!==""){
      let itemparams = this.props.component.ItemTipParams.split(",");
      let fullFilePath;
      for(let i=0;i<itemparams.length;i++){
          let itemparam= itemparams[i];
          let item = itemparam.split("~");
          if(item[0]==="link")
          {
            let documentURL = this.props.rowData[item[1]];
            window.open(documentURL, '_blank')
            break;
          }else if(item[0]==="fullFilePath"){
            fullFilePath=this.props.rowData[item[1]];
          }else if(item[0]==="relativePath"){
            fullFilePath=`${this.props.configurationRootPath}${this.props.rowData[item[1]]}`
          }
      }
     fullFilePath && FileHandlerAxiosV2(fullFilePath,this.props.rowData);
    }
    else if(this.props.getDocument)
    {
      this.props.getDocument(this.props);
    }
    else
    {
      this.setState({ loading: true });
      // let drive = 'E:' //for local
      let transactionRootPath = this.props.transactionRootPath;
      let relativePath = this.props.rowData.DocumentURL;
      // let fileName = this.props.rowData.SolartisDocumentName;
      //let filePath = drive.concat(transactionRootPath, relativePath); // for local
      let filePath = transactionRootPath.concat(relativePath);
      FileHandlerAxios( filePath);
    }

  }

  render() {
    let {component}=this.props
    let DocumentName = this.props.rowData.SolartisDocumentDisplayName ? this.props.rowData.SolartisDocumentDisplayName : this.props.rowData[component.AttributeName]
    return (
      <React.Fragment>
        <span>
          <Link className="nav-link alignLeft" color="primary" component="button" underline="always" onClick={this.getDocument}>
            {DocumentName}
          </Link>
        </span>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  transactionRootPath: state.ownerResources.clientInfo.UI_TRANSACTION_ROOT_PATH,
  configurationRootPath: state.ownerResources.clientInfo.UI_CONFIGURATION_ROOT_PATH
});

export default connect(mapStateToProps)(Document);
