import React from 'react';
import { Grid, InputLabel, Typography } from "@material-ui/core";
import { Link, withRouter } from 'react-router-dom';
import { commonLinkMethod } from './ButtonUtil';

function OutputLinkText(props) {
  let { component, isDynamicTable } = props;
  let labelId = component.ComponentId + "_label";
  let value = props.componentvalue ? props.componentvalue : component.DefaultValue;
  let labelRef = React.createRef();
  let pathname = component.Action && Object.keys(component.Action).length>0 && component.Action.RedirectTo ? `/${component.Action.RedirectTo}` : null;

 const onClick = (e) => {
  if(props[props.component.OnClickAction])
   props[props.component.OnClickAction](e,props);
  else if(Object.keys(component.Action).length > 0){
    commonLinkMethod(component, props.pagelevelValues[component.SubApplicationName], props);
  } 
 }
  
  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        sm={12}
        md={component.Size}
        lg={component.Size}
        key={component.AttributeName}
        className={`OutputLinkText_class ${props.gridStyle ? props.gridStyle : ''}`}
      >
        {!isDynamicTable && (
          <InputLabel
            ref={labelRef}
            id={labelId}
            className={`${component.StyleClass} small_label`}
          >
            {component.ComponentLabel}
          </InputLabel>
        )}
        <Typography variant="body2" gutterBottom className={`${component.AttributeStyleClass}`}>
          <Link className="nav-link nav-linkpad small_label" onClick={(e) => onClick(e)}>
            {value}
          </Link>
        </Typography>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(OutputLinkText);