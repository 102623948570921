export const vehicleStatus = {
    popupParentClassName : 'vehicle_status_popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_AU',
    disableBackdropClickfullWidth : true,
    disableBackdropClick : true,
    eventInfo : {
        masterEventName: "SolartisMasterWF",
        getVehicleStatus : "GetVehicleBulkUpdateStatus_WF",
    },
    popupHeader : {
        subApplicationList : null,
        headerName : 'Vehicle Status',
        headerParentClassName : 'vehicle_status_popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'vehicle_status_popup_header_close'
        }
    },
    popupContent : {
        subApplicationList : ['SolartisVehicleStatusSummary'],
        contentParentClassName : 'vehicle_status_popup_content',
        parentStateUpdateNeeded : false,
        isSpecifiComponentNeeded : false,
        sepcificComponentName : null,
        renderContent : 'dynamicRender',
        tableOptions : {
            "ChildrenSubApplicationName": null,
            "ChildrenComponent" : null,
            "AddButtonNeeded" : false,
            "SortingNeeded" : false,
            "FilteringNeeded" : true,
            "rowsPerPageOptions" : [5, 10, 25],
            "RowsPerPage" : 25,
            "lazyLoading" : false,
            "ActionName"  : "Action",
            "ActionListOptions"  : []
        }
    },
    popupFooter : null
}

export const vehicleBulkCopy = {
    popupParentClassName : 'vehicle_BulkCopy_popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_AU',
    disableBackdropClickfullWidth : true,
    disableBackdropClick : true,
    eventInfo : {
        masterEventName: "SolartisMasterWF",
        EventName : "VehicleBulkCopy_WF",
    },
    popupHeader : {
        subApplicationList : null,
        headerName : 'Copy Vehicle',
        headerParentClassName : 'vehicle_BulkCopy_popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'vehicle_BulkCopy_popup_header_close'
        }
    },
    popupContent : {
        subApplicationList : ['BulkCopyVehicle'],
        contentParentClassName : 'vehicle_BulkCopy_popup_content',
        parentStateUpdateNeeded : false,
        isSpecifiComponentNeeded : false,
        sepcificComponentName : null,
        renderContent : 'dynamicRender',
    },
    popupFooter : null
}

export const removeState =
{
    popupParentClassName : 'vehicle_BulkCopy_popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_AU',
    disableBackdropClickfullWidth : true,
    disableBackdropClick : true,    
    popupHeader : {
        subApplicationList : null,
        headerName : 'DELETE STATE',
        headerParentClassName : 'vehicle_BulkCopy_popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'vehicle_BulkCopy_popup_header_close'
        }
    },
    popupContent :{
        staticContentLabel: "On deleting the state all vehicles added in that state will also be deleted.",
        contentParentClassName : 'vehicle_BulkCopy_popup_content',
        isStaticContentNeeded: true,
    },
    popupFooter : {    
    buttonInfo: [
        {
            buttonLabel: "DELETE",
            EventName : "Solartis_AU_1_11_V1_AU_RemoveSubmissionObject_1.0.0.1",
            methodName: "removeState",
        },
        {
            buttonLabel: "CANCEL",
            EventName : "",
            methodName: "handleClosePopup",
        }
    ]
    }
}
export const removeVehicle=
{
    popupParentClassName : 'vehicle_BulkCopy_popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_AU',
    disableBackdropClickfullWidth : true,
    disableBackdropClick : true,    
    popupHeader : {
        subApplicationList : null,
        headerName : 'REMOVE VEHICLE',
        headerParentClassName : 'vehicle_BulkCopy_popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'vehicle_BulkCopy_popup_header_close'
        }
    },
    popupContent :{
        staticContentLabel: "Are you sure you want to delete the vehicle?",
        contentParentClassName : 'vehicle_BulkCopy_popup_content',
        isStaticContentNeeded: true,
    },
    popupFooter : {    
    buttonInfo: [
        {
            buttonLabel: "REMOVE",
            EventName : "Solartis_AU_1_11_V1_AU_RemoveSubmissionObject_1.0.0.1",
            methodName: "invokeSelectiveDeleteVehicle",
        },
        {
            buttonLabel: "CANCEL",
            EventName : "",
            methodName: "handleClosePopup",
        }
    ]
    }
}

export const removeAllVehicle=
{
    popupParentClassName : 'vehicle_BulkCopy_popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_AU',
    disableBackdropClickfullWidth : true,
    disableBackdropClick : true,    
    popupHeader : {
        subApplicationList : null,
        headerName : 'REMOVE ALL VEHICLE',
        headerParentClassName : 'vehicle_BulkCopy_popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'vehicle_BulkCopy_popup_header_close'
        }
    },
    popupContent :{
        staticContentLabel: "Are you sure you want to delete All the vehicle?",
        contentParentClassName : 'vehicle_BulkCopy_popup_content',
        isStaticContentNeeded: true,
    },
    popupFooter : {    
    buttonInfo: [
        {
            buttonLabel: "REMOVE",
            EventName : "Solartis_AU_1_11_V1_AU_RemoveSubmissionObject_1.0.0.1",
            methodName:"invokeDeleteAllVehicle",
        },
        {
            buttonLabel: "CANCEL",
            EventName : "",
            methodName: "handleClosePopup",
        }
    ]
    }
}

export const ScheduleOfForms_Orchid={
    popupParentClassName : 'orchid_Schedule_Of_Forms_Popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_Orchid',
    disableBackdropClick : true,
    popupHeader:{
        subApplicationList : null,
        headerName : null,
        headerParentClassName : 'orchid_Schedule_Of_Forms_Popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'orchid_Schedule_Of_Forms_Popup_header_close'
        }
    },
    popupContent : {
        subApplicationList : ["ScheduleOfForms"],
        contentParentClassName : 'vehicle_BulkCopy_popup_content',
        parentStateUpdateNeeded : false,
        isSpecifiComponentNeeded : false,
        isStaticContentNeeded :false,
        sepcificComponentName : null,
        renderContent : 'dynamicRender',
        tableOptions : {
            "ChildrenSubApplicationName": null,
            "ChildrenComponent" : null,
            "AddButtonNeeded" : false,
            "SortingNeeded" : false,
            "FilteringNeeded" : true,
            "rowsPerPageOptions" : [5, 10, 25],
            "RowsPerPage" : 5,
            "lazyLoading" : false,
            "ActionName"  : "Action",
            "ActionListOptions"  : []
        }
    },
    popupFooter : null
}

export const UnderWriterWorkSheet_Satori={
    popupParentClassName : 'underWriterWorkSheet_MPL_Popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_MPL',
    disableBackdropClick : true,
    popupHeader:{
        subApplicationList : null,
        headerName : 'Underwriter Worksheet',
        headerParentClassName : 'underWriterWorkSheet_MPL_Popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'underWriterWorkSheet_MPL_Popup_header_close'
        }
    },
    popupContent : {
        subApplicationList : ["UnderwriterWorksheet", "IAPLBasePremium", "UnderwriterWorksheetSave", "ModifiersHeading", "IFLSectionPremium", "GeneralModifiers", "IFLBasePremium", "IAPLSectionPremiumDetails", "IndividualRiskModifiers", "UnderwriterWorksheetCustomiterator", "UnderwriterWorksheetFinalPremium", "UnderwriterWorksheetIAPL", "UnderwriterWorksheetIFL", "IAMLBasePremium", "IAMLSectionPremiumDetails", "UnderwriterWorksheetIAML", "UnderwriterWorksheetEPL", "EPLBasePremium", "EPLSectionPremiumDetails", "UnderwriterWorksheetFL", "FLBasePremium", "FLSectionPremiumDetails", "UnderwriterAdjustments","UnderwriterworksheetDates"],
        contentParentClassName : 'underWriterWorksheet_popup_content',
        parentStateUpdateNeeded : false,
        isSpecifiComponentNeeded : false,
        isStaticContentNeeded :false,
        sepcificComponentName : null,
        renderContent : 'dynamicRender',
        tableOptions : {
            "ChildrenSubApplicationName": null,
            "ChildrenComponent" : null,
            "AddButtonNeeded" : false,
            "SortingNeeded" : false,
            "FilteringNeeded" : true,
            "rowsPerPageOptions" : [5, 10, 25],
            "RowsPerPage" : 5,
            "lazyLoading" : false,
            "ActionName"  : "Action",
            "ActionListOptions"  : []
        }
    },
    popupFooter : null
}

export const BusinessRules_Satori={
    popupParentClassName : 'BusinessRules_MPL_Popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_MPL',
    disableBackdropClick : true,
    popupHeader:{
        subApplicationList : null,
        headerName : 'Business Rule',
        headerParentClassName : 'BusinessRules_MPL_Popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'BusinessRules_MPL_Popup_header_close'
        }
    },
    popupContent : {
        subApplicationList : ["BusinessRules"],
        contentParentClassName : 'underWriterWorksheet_popup_content',
        parentStateUpdateNeeded : false,
        isSpecifiComponentNeeded : false,
        isStaticContentNeeded :false,
        sepcificComponentName : null,
        renderContent : 'dynamicRender',
        tableOptions : {
            "ChildrenSubApplicationName": null,
            "ChildrenComponent" : null,
            "AddButtonNeeded" : false,
            "SortingNeeded" : false,
            "FilteringNeeded" : true,
            "rowsPerPageOptions" : [5, 10, 25],
            "RowsPerPage" : 5,
            "lazyLoading" : false,
            "ActionName"  : "Action",
            "ActionListOptions"  : []
        }
    },
    popupFooter : null
}
export const LapseOrDecline_Orchid={
    popupParentClassName : 'orchid_Lapse_Delcine_Popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_LapseDeclineOrchid',
    disableBackdropClick : true,
    popupHeader: null,
    popupContent : {
        subApplicationList : ["DeclineQuote", "LapseQuote"],
        contentParentClassName : 'lape_decline_popup_content',
        parentStateUpdateNeeded : false,
        isSpecifiComponentNeeded : false,
        isStaticContentNeeded :false,
        sepcificComponentName : null,
        renderContent : 'dynamicRender',
        tableOptions : {
            "ChildrenSubApplicationName": null,
            "ChildrenComponent" : null,
            "AddButtonNeeded" : false,
            "SortingNeeded" : false,
            "FilteringNeeded" : true,
            "rowsPerPageOptions" : [],
            "RowsPerPage" : 5,
            "lazyLoading" : false,
            "ActionName"  : "Action",
            "ActionListOptions"  : []
        }
    },
    popupFooter : null
}

export const ViewBumpRules_Orchid={
    popupParentClassName : 'orchid_ViewBumpRules_Popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_view_bump_rules',
    disableBackdropClick : true,
    popupHeader: {
        subApplicationList : null,
        headerName : null,
        headerParentClassName : 'orchid_View_Bump_Rules_Popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'orchid_View_Bump_Rules_Popup_header_close'
        }
    },
    popupContent : {
        subApplicationList : ["BumpRuleList"],
        contentParentClassName : 'ViewBumpRules_popup_content',
        parentStateUpdateNeeded : false,
        isSpecifiComponentNeeded : false,
        isStaticContentNeeded :false,
        sepcificComponentName : null,
        renderContent : 'dynamicRender',
        tableOptions : {
            "ChildrenSubApplicationName": null,
            "ChildrenComponent" : null,
            "AddButtonNeeded" : false,
            "SortingNeeded" : false,
            "FilteringNeeded" : true,
            "rowsPerPageOptions" : [],
            "RowsPerPage" : 5,
            "lazyLoading" : false,
            "ActionName"  : "Action",
            "ActionListOptions"  : []
        }
    },
    popupFooter : null
}
export const UploadStampedDocuments_Orchid={
    popupParentClassName : 'orchid_UploadStampDocuments_Popup',
    fullWidth : true,
    maxWidth : 'lg',
    utilFilePath : './PopupUtils/utils_upload_stamped_documents',
    disableBackdropClick : true,
    popupHeader: {
        subApplicationList : null,
        headerName : "Upload Stamped Dec Page",
        headerParentClassName : 'orchid_Upload_Stamped_Documents_Popup_header',
        closeButtonDetails : {
            closeTextButton : false,
            closeTextButtonName : null,
            closeIconButton : true,
            closeIconName : 'close',
            closeToolTip : 'close popup',
            closeParentClassName : 'orchid_Upload_Stamped_Documents_Popup_header_close'
        }
    },
    popupContent : {
        subApplicationList : ["UploadStampedDocuments"],
        contentParentClassName : 'UploadStampedDocuments_popup_content',
        parentStateUpdateNeeded : false,
        isSpecifiComponentNeeded : false,
        isStaticContentNeeded :false,
        sepcificComponentName : null,
        renderContent : 'dynamicRender',
        tableOptions : {
            "ChildrenSubApplicationName": null,
            "ChildrenComponent" : null,
            "AddButtonNeeded" : false,
            "SortingNeeded" : false,
            "FilteringNeeded" : false,
            "rowsPerPageOptions" : [],
            "RowsPerPage" : 5,
            "lazyLoading" : false,
            "ActionName"  : "Action",
            "ActionListOptions"  : []
        }
    },
    popupFooter : null
}
