import React from 'react';
import { format } from "date-fns";
import store from "../../../../store";
import appActions from "../../../../actions/index";
import { AxiosInstance, getJSONRequestFromAPI, getSubmissionAxios,commonProductAxiosInstance, getServiceRequestDetail,GetCommonEventName} from "./API";
import { FileHandlerAxios } from "../../../../CommonAxios/FileHandlerAxios";
import { logUserDetailRequest } from "../../../Pages/Login/loginUtil";
import {getFindConsoleAPI_ReqUtil} from "../../pages/FindUtils";
import * as Action from "../../../../actions/types";
import { EntitySubAppTranslation, EntitySubAppTranslationV2,  flattenObject,  getEntitySubAppMapping } from "../requestConstruction/ResponseTranslation";
import { handleOnChangeN } from './SelectUtil';
import { handleInputChangeV2 as handleChange } from "./InputUtils";
import { setCurrentPageIndex} from '../../../../actions/UIFlowActions';
import { successToast, triggerToast,triggerInfoToast, Toast, scrollBehaviourDown } from './HelperUtils';
import { constructAndInvokeMetadataCall,deleteDependentAttribute } from './MetaUtils';

const { actionType } = appActions
const COMMON_MASTER_EVENT_NAME = "SolartisCommonMaster_WF";

  export const getInsured=async(state,props,func,ID,submissionDetails,GetEventName = "SolartisPLCommonMaster",panelAndStateUpdateNeeded=true)=>{
    try{
    if(!ID)
    return;
    if(panelAndStateUpdateNeeded)
      func('loading',true,true)
    const currentPageIndex  = state.currentPageIndex;
    let {navigationReducer}=store.getState();
    let subApplication=navigationReducer.pageNavigation[currentPageIndex].SubApplicationName;
    delete subApplication.InsuredSummary;
    let {ObjectId:objectId,ObjectVerId:objectVerId}=navigationReducer.pageNavigation[currentPageIndex];
    let {submissionID}=submissionDetails ? submissionDetails : "";
    await store.dispatch({type: Action.SET_SUBMISSIONID,payload: submissionID});
    let responseFromTranslation={};
    let response={};
    if(ID){
    let request=Object.create({});
    request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName=navigationReducer.pageNavigation[currentPageIndex].geteventName;
    request.ID=ID;
    request.EventName= GetEventName;
    let [response,Entities]=await Promise.all([commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request),constructAndInvokeMetadataCall({subappName:subApplication,objectId,objectVerId})])
    responseFromTranslation=await EntitySubAppTranslationV2({...response.data},{...Entities.ApplicationDetail});
    await func("pagelevelValues",{...state.pagelevelValues,...responseFromTranslation,...response.data,...props.metadataReducer,...props.UIFlowReducer},false);
    await func("transactionPrimaryObject",{...state.pagelevelValues,...responseFromTranslation,...response.data,...props.metadataReducer,...props.UIFlowReducer},false);

    store.dispatch({type: Action.SET_PAGEHEADER_UPDATE,payload: true});
    if(panelAndStateUpdateNeeded)
      store.dispatch({type: Action.SET_EXPANDPANEL_DATA,payload: true});


     }
    const mapEntity=await constructAndInvokeMetadataCall({subappName:subApplication,objectId,objectVerId},{transactionPrimaryObject:{...state.transactionPrimaryObject,...responseFromTranslation}})
    await func("pageMetadata",mapEntity,false);
    if(panelAndStateUpdateNeeded)
      func('loading',false,true)
    return {mapEntity,response, responseFromTranslation};
    }
    catch(error){
        if(panelAndStateUpdateNeeded)
          func('loading',false,true)
        console.log("Error in getInsured",error);
    }
}


export const setDataLists = (dataListName=[] ,source={}, UpdateState, parentUpdate)=>{
  dataListName.forEach((data)=> {
    if(typeof data === 'object'){
      let {sourceKey='', mappedKey=''} = data;
      if(sourceKey in source){
        UpdateState && UpdateState(`${mappedKey.toLowerCase()}_dataList`,source[sourceKey],false);
        parentUpdate && parentUpdate(`${mappedKey.toLowerCase()}_dataList`,source[sourceKey], false);
      }
    }
    else if(typeof data === 'string'){
      if(data in source){
        UpdateState && UpdateState(`${data.toLowerCase()}_dataList`,source[data],false);
        parentUpdate && parentUpdate(`${data.toLowerCase()}_dataList`,source[data], false);
      }
    }
  })
}

export const getRiskOutofSync = async (eventName) => {
  try {
      let reduxState = store.getState();
      let { submissionID } = reduxState.UIFlowReducer;
      let { lob } = reduxState.metadataReducer
      store.dispatch({ type: actionType.SHOW_LOADER });
      let request = {};
      let data;
      request.OwnerId = sessionStorage.getItem('OwnerID');
      request.ServiceRequestDetail = Object.assign({}, getServiceRequestDetail());
      request.EventName = eventName;
      request.ID = submissionID;
      request.LOB = lob;
      let response = await AxiosInstance.post("core/invokeWorkflowEvent/V3", request);

      if (response.data && response.data.RiskOutOfSync) {
          let riskOutOfSync = response.data.RiskOutOfSync
          data = riskOutOfSync.toLowerCase() === "yes" ? true : (riskOutOfSync.toLowerCase() === "no" ? false : "")
          if (data !== ""){
               store.dispatch({ type: actionType.SET_RISK_OUTOF_SYNC, payload: data })
          }
      }
      store.dispatch({ type: actionType.HIDE_LOADER });
      return data
  }
  catch (error) {
      triggerToast(error.message)
      console.log("error in getRiskOutofSync", error)
  }
}

export const renderChildrenWithProps = (props, childProps) => {
    return React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {...childProps})
        }
        return child;
    });
}

export const renderChildrenWithPropsV2 = (props, childProps) => {
    return React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {...childProps, ...child.props})
        }
        return child;
    });
}

export const splitData = (data) => {
  let temp = { pagelevelValues: {}, transactionPrimaryObject: {} };
  Object.entries(data).forEach(([key, value], index) => {
    if (Array.isArray(value)) {
      temp[`${key.toLowerCase()}_dataList`] = value;
    }
    else {
      temp["pagelevelValues"][key] = value;
      temp["transactionPrimaryObject"][key] = value;
    }
  });
  return temp;
}

export const rateDataUpdate = (props) => {
  let obj = {}
  let { productBrowseData, data } = props
  if(productBrowseData && Object.keys(productBrowseData).length > 0 ){
    productBrowseData.hasOwnProperty('policy_dataList') &&
    Array.isArray(productBrowseData['policy_dataList']) &&
    productBrowseData['policy_dataList'].map((items, i) => {
        if(items.State === data.State){
          obj = {...items}
        }
      })
    }
  return obj
}
export const existingInsured=async(eventName,state,props,comp,func,e,mainProps)=>{
try{
  let {functions}=mainProps;
  functions["UpdateState"]('loading',true,true)
  store.dispatch({type: actionType.FINDINSURED_CLICK, payload:true});
  let { currentPageIndex } = mainProps.props.UIFlowReducer;
  let effectiveDate = (state.values && state.values.EffectiveDate) ? state.values.EffectiveDate : (props.values && props.values.EffectiveDate) ? props.values.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  let {ObjectId: objectId, ObjectVerId: objectVerId} = mainProps.props.pageNavigation[currentPageIndex - 1]
  let subAppInfo= {subappName: mainProps.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId}
  let {pagelevelValues,transactionPrimaryObject}=state;
  let [metadataVal,summaryMetadata] = await Promise.all( [ constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: transactionPrimaryObject, EffectiveDate: effectiveDate }), constructAndInvokeMetadataCall({...subAppInfo,subappName:['InsuredSummary']}, { transactionPrimaryObject: transactionPrimaryObject, EffectiveDate: effectiveDate })])
  functions["UpdateState"]("pageMetadata",{"ApplicationDetail":metadataVal.ApplicationDetail},false);
  let offsetInfo={ StartLimit : "0", EndLimit : "500" };
  let {ExistingInsuredYN,InsuredName="",Type}=pagelevelValues.InsuredSearch;
  let response= await getFindConsoleAPI_ReqUtil("Insured",{ExistingInsuredYN,InsuredName,Type},offsetInfo,state,mainProps.props,true);
  response.request.AgencyNumber=props.values.AgencyNumber;
  let resp=await commonProductAxiosInstance.post(response.url,response.request)
  response=resp.data.Insured;

  resp = response.map((data)=>{
    return {...data, rowMetaData: summaryMetadata.ApplicationDetail.SubApplicationDetailList[0].AttributeDetailList}
  })
  functions["UpdateState"]('loading',false,false)
  functions["UpdateState"]("insuredsummary_dataList",resp,true);
}
catch(error){
console.log("Error in Existing Insured",error);
}
}

export const AgencyLookUpList_Orchid=async(props, data, attributeInfo)=>{
  try{
    let {functions: {UpdateState}, component, props: newProps} = props
    UpdateState("loading", true, true)
    if(component.SubApplicationName==='AgencyInformation' && props.pagelevelValues.hasOwnProperty('AgentInformation')){
      delete props.pagelevelValues['AgentInformation'];
    }
    let request={};
    request["ServiceRequestDetail"] = await Object.assign({}, getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.EventName = GetCommonEventName()
    request.TargetEventName = attributeInfo.GetEventName;
    request.ID = data.ID;
    if(data.ID){
      let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
      Object.assign(props.componentState.transactionPrimaryObject,response.data)
      Object.assign(props.componentState.pagelevelValues,response.data)
        switch (component.SubApplicationName){
            case 'AgencyInformation':
              newProps.setAgencyDetails(props.componentState.pagelevelValues[component.SubApplicationName]);
              break;
            case 'AgentInformation':
              newProps.setAgentDetails(props.componentState.pagelevelValues[component.SubApplicationName]);
              newProps.setAgentID(props.componentState.pagelevelValues[component.SubApplicationName]["ID"])
              break;
          }
        }
      else{
        deleteDependentAttribute(component,props.componentState.pagelevelValues,props.componentState.transactionPrimaryObject)
          }
      let { currentPageIndex } = props.props.UIFlowReducer;
      let subApplication =  props.props.pageNavigation[currentPageIndex - 1].SubApplicationName;
      const {   ObjectVerId :  objectVerId,  ObjectId:objectId} = props.componentProps.pageMetadata.ApplicationDetail
      const subAppInfo = {objectId, objectVerId, subappName: subApplication}
      let metaDataRes = await  constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject:props.componentState.transactionPrimaryObject})
      UpdateState("pageMetadata",{"ApplicationDetail":metaDataRes.ApplicationDetail},false);
      UpdateState("pagelevelValues",props.componentState.pagelevelValues, false)
      UpdateState("transactionPrimaryObject",props.componentState.transactionPrimaryObject, false)
      UpdateState("loading", false, true)
}
catch(err){
  console.log("Error in AgencyLookUpList_Orchid", err)
}
}

export const AgencyLookUpList_MPL=(props)=>{
  if(props.pagelevelValues.hasOwnProperty('AgentInformation')){
  delete props.pagelevelValues['AgentInformation'];
  }
 }



export const copySubmission = (e, params, component) => {
  let { currentPageIndex } = params.propsValue.UIFlowReducer;
  store.dispatch({ type: actionType.SHOW_LOADER })
  let data,SubmissionID = params.propsValue;
  let reduxState = store.getState();
  let { submissionID } = reduxState.UIFlowReducer;
  let { LineOfBusiness } = reduxState.UIFlowReducer.productDetails;
  let { EventName } = component;
  let copySubmissionRequest = {};
  let totalPremium = "";

  copySubmissionRequest.ServiceRequestDetail = Object.assign({}, getServiceRequestDetail());
  copySubmissionRequest.ServiceRequestDetail.Lob = LineOfBusiness;
  copySubmissionRequest.ServiceRequestDetail.BrowserIp = "1.0";
  copySubmissionRequest.EventName = COMMON_MASTER_EVENT_NAME;
  copySubmissionRequest.TargetEventName = EventName;
  copySubmissionRequest.OwnerId = sessionStorage.getItem('OwnerID');
  copySubmissionRequest.ID = submissionID;

  AxiosInstance
    .post("core/invokeWorkflowEvent/V4", copySubmissionRequest)
    .then(response => {
      if (response.data) {
       data = response.data;
       SubmissionID=data.SubmissionID
      }
    })
    .catch(err => {
      store.dispatch({ type: actionType.HIDE_LOADER })
    })
    .finally(() => {
      triggerInfoToast("Submission Copied Successfully", { position: Toast.POSITION.TOP_CENTER })
      store.dispatch({
        type: actionType.SET_CREATESUBMISSION_RESPONSE,
        payload: data
    })
     store.dispatch({
       type: actionType.SET_SUBMISSIONID,
       payload: SubmissionID
    })

       store.dispatch({
        type: actionType.SET_PRODUCT_BROWSE_STATE_UPDATE,
         payload: { IsRatingInvoked: false } })
         store.dispatch({ type: actionType.PREMIUM, payload: totalPremium });
    store.dispatch({ type: actionType.HIDE_LOADER });
    }
    )
  if (currentPageIndex!==2)
  {
    params.navigateToPrevious(e);
  }

}

export const handleDateChange = (event, value, AttributeName, component, state) => {
  let pagelevelValue = state.pagelevelValues;
  let transactionValue = state.transactionPrimaryObject;
  let attributeName = AttributeName;
  let formattedDatevalue = value;
  let subappName = component.SubApplicationName;
  let pagelevelValues = Object.assign({}, pagelevelValue);
  let transactionKeyList = Object.assign({}, transactionValue);
  let values = Object.assign({}, state.values);
  values[attributeName] = formattedDatevalue;
  if (!pagelevelValues[subappName]) {
      pagelevelValues[subappName] = {};
  }
  if (!transactionKeyList[subappName]) {
      transactionKeyList[subappName] = {};
  }
  pagelevelValues[subappName][attributeName] = formattedDatevalue;
  transactionKeyList[subappName][attributeName] = formattedDatevalue;

  return { values:values, pagelevelValues: pagelevelValues, transactionPrimaryObject: transactionKeyList };
};

export const handlePersistValue = (value, component, state, updateState) => {
  let persistValues = Object.assign({}, state.persistValues)
  if(!persistValues[component.SubApplicationName]) persistValues[component.SubApplicationName] = {}
  persistValues[component.SubApplicationName] = Object.assign({},persistValues[component.SubApplicationName],value);
  updateState("persistValues", persistValues, false);
}


export const updateValueWithSubplicationName = (updatedValue, component, state) => {
  let keys = Object.keys(updatedValue);
  let SubapplicationName = component.SubApplicationName;
  let attributeName = keys[0];
  let attributeValue = updatedValue[attributeName];
  // let detailValues = Object.assign({}, state.detailValues);
  let pagelevelValues = Object.assign({}, state.pagelevelValues);
  let transactionPrimaryObject = Object.assign({}, state.transactionPrimaryObject);
  // detailValues[attributeName] = attributeValue;

  if (!pagelevelValues[SubapplicationName]) {
      pagelevelValues[SubapplicationName] = {};
  }
  // pagelevelValues[SubapplicationName] = Object.assign({},state.pagelevelValues[SubapplicationName],updatedValue);
  pagelevelValues[SubapplicationName][attributeName] = attributeValue;

  if (!transactionPrimaryObject[SubapplicationName]) {
      transactionPrimaryObject[SubapplicationName] = {};
  }
  // transactionPrimaryObject[SubapplicationName] = Object.assign({},state.transactionPrimaryObject[SubapplicationName],updatedValue);
  transactionPrimaryObject[SubapplicationName][attributeName] = attributeValue;

  return {...pagelevelValues}
}
export const updateValueWithSubapplicationName = (updatedValue, component, state, props) => {

    let keys = Object.keys(updatedValue);
    let SubapplicationName = component.SubApplicationName;
    let attributeName = keys[0];
    let attributeValue = updatedValue[attributeName];
     let IteratorIndex =props && props.componentState && props.componentState.IteratorIndex
    let ResponseKey = props && props.componentState && props.componentState.ResponseKey
    let IteratordataList;
    if(IteratorIndex!== undefined && typeof IteratorIndex === 'number' && ResponseKey){
      IteratordataList = state[ResponseKey]
      if(!(SubapplicationName in IteratordataList[IteratorIndex])){
      IteratordataList[IteratorIndex][SubapplicationName] = {}
     }
     IteratordataList[IteratorIndex][SubapplicationName] ={...IteratordataList[IteratorIndex][SubapplicationName], ...updatedValue}
     return {[ResponseKey]: IteratordataList}
    }
  let pagelevelValues = Object.assign({}, state.pagelevelValues);
  let transactionPrimaryObject = Object.assign({}, state.transactionPrimaryObject);

  if (!pagelevelValues[SubapplicationName]) {
    pagelevelValues[SubapplicationName] = {};
  }
  pagelevelValues[SubapplicationName][attributeName] = attributeValue;

  if (!transactionPrimaryObject[SubapplicationName]) {
    transactionPrimaryObject[SubapplicationName] = {};
  }
  transactionPrimaryObject[SubapplicationName][attributeName] = attributeValue;
  if(keys.length>1){
    pagelevelValues[SubapplicationName]= {...state.pagelevelValues[SubapplicationName],...updatedValue}
    transactionPrimaryObject[SubapplicationName] = {...state.transactionPrimaryObject[SubapplicationName],...updatedValue}
  }

  return { pagelevelValues, transactionPrimaryObject }
}

export const isValidJson = str => {
  try{
    JSON.parse(str)
  }catch(e){
    return false
  }
  return true
}

export const handleHiddenInputChange = (component, state) => {
  let attributeName = component.AttributeName;
  let attributeValue = component.DefaultValue;
  if (component.SubApplicationAttributeValueList.length == 2) {
    attributeValue = component.SubApplicationAttributeValueList[1].Value;
  }
  let values = Object.assign({}, state.values);
  values[attributeName] = attributeValue;

  let { SubApplicationName: subappName } = component;
  let pagelevelValues = Object.assign({}, state.pagelevelValues);
  let transactionPrimaryObject = Object.assign({}, state.transactionPrimaryObject);
  if (typeof subappName !== "string") {
    return;
  }
  if (!pagelevelValues[subappName]) {
    pagelevelValues[subappName] = {};
  }
  pagelevelValues[subappName][attributeName] = attributeValue;

  if (!transactionPrimaryObject[subappName]) {
    transactionPrimaryObject[subappName] = {};
  }
  transactionPrimaryObject[subappName][attributeName] = attributeValue;

  return { values, pagelevelValues, transactionPrimaryObject }
};

export const bindQuote = async (EventName,props,state,component,functions) => {
  const reduxState = store.getState();
  let {clientInfo } = reduxState.ownerResources
   props.updateValues({loading: true})
   try {
       let bindQuoteRequest = Object.create({});
       bindQuoteRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
       bindQuoteRequest["OwnerId"] = sessionStorage.getItem("OwnerID");
       bindQuoteRequest["EventName"] = "SolartisCommonMaster_WF";
       bindQuoteRequest["ID"] = props.UIFlowReducer.quoteID
       bindQuoteRequest["TargetEventName"] = props.eventName;
       bindQuoteRequest["EventAction"] = props.eventAction;
       if(clientInfo.MultiLineLOB==='CP')
        {
          bindQuoteRequest.ProductVerNumber = clientInfo.MultiLineProductVerNumber;
          bindQuoteRequest.ProductNumber = clientInfo.MultiLineProductNumber;
        }
        else
        {
         bindQuoteRequest.ProductVerNumber = props.productDetails.ProductNumber;
         bindQuoteRequest.ProductNumber = props.productDetails.ProductVerNumber;
        }
       let temp= await AxiosInstance.post("core/invokeWorkflowEvent/V4", bindQuoteRequest);
       let bindQuoteResponse = await temp.data;
       if (Object.keys(bindQuoteResponse).length > 0) {
         if (bindQuoteResponse.hasOwnProperty('RuleInformationList')) {
           let errorMessage = bindQuoteResponse['RuleInformationList'][0];
           props.updateValues({
               messageDialog: true,
               infoDialog: "Alert!",
               contentDialog: bindQuoteResponse.contentDialog
             })
             props.updateValues({loading: false})
             return
         } else {
           // props.updateValues({
           //     loading: false,
           //     getResponse: bindQuoteResponse,
           //     transactionprimaryobj: bindQuoteResponse
           //   })
             props.setGetQuoteResponse(bindQuoteResponse)
             props.setPolicyID(bindQuoteResponse.ID)

         }
         props.updateValues({loading: false})
         props.history.push('/policy')
       }
     } catch (error) {
       console.log("Error in BindQuote in API", error.message);
     }
}

export const savebusinesscontactinformation = async (params, state,props) => {
  const reduxState = store.getState();
  let {clientInfo } = reduxState.ownerResources
  let { policyID } = params;
  try {
    props.updateValues({loading: true})
    let Request = Object.create({});
    Request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    Request["OwnerId"] = sessionStorage.getItem("OwnerID");
    Request["EventName"] = "SolartisCommonMaster_WF";
    //Request["TargetEventName"] = "Solartis_CF_1_25_V1_CF_UpdatePolicyV2_1.0.0.1";
    Request["EventAction"] = props.businessContactEventAction;
    Request["PARENTID"] = Request["ROOTID"] = Request["ID"] = policyID;
    Request["TargetEventName"] = props.saveBusinessEventName;
    Request = Object.assign(Request, state.pagelevelValues.IssuePolicy)
    if(clientInfo.MultiLineLOB==='CP')
    {
      Request.ProductVerNumber = clientInfo.MultiLineProductVerNumber;
      Request.ProductNumber = clientInfo.MultiLineProductNumber;
    }
    else
    {
      Request.ProductVerNumber = props.productDetails.ProductNumber;
      Request.ProductNumber = props.productDetails.ProductVerNumber;
    }
    let Response = await AxiosInstance.post("core/invokeWorkflowEvent/V4", Request);
    Response = await Response.data;
    props.updateValues({loading: false})
    return Response;
  } catch (error) {
    props.updateValues({loading: false})
    console.log("Error in savebusinesscontactinformation  API", error.message);
    return {
      "messageDialog": true,
      "infoDialog": "Alert!",
      "contentDialog": `${error.message}`
    }
  }
}

export const getDetailbyAccount = async (findview, params, offsetInfo) => {
  let request = {};
  let { StartLimit = "", EndLimit = "" } = offsetInfo;
  let dataListName = "";
  let rerender = false;
  let agencyAgentInfo = getAgencyAgentInfo() || {};
  if (findview == "Account") {
    request = Object.assign({}, JSON.parse(JSON.stringify(require("../../models/getAccount.json"))));
  }
  else if (findview == "Submission") {
    request = Object.assign({}, JSON.parse(JSON.stringify(require("../../models/FindSubmission.json"))), agencyAgentInfo);
  }
  else if (findview == "Quote") {
    request = Object.assign({}, JSON.parse(JSON.stringify(require("../../models/FindQuote.json"))), agencyAgentInfo);
  }
  else if (findview == "Policy") {
    request = Object.assign({}, JSON.parse(JSON.stringify(require("../../models/FindPolicy.json"))), agencyAgentInfo);
  }

  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  request["ServiceRequestDetail"].OwnerId = sessionStorage.getItem("OwnerID");
  request["OwnerId"] = sessionStorage.getItem("OwnerID");
  if (request['AccountID'] == "") {
    request['AccountID'] = params.UIFlowReducer['accountID']
    request.StartLimit = StartLimit;
    request.EndLimit = EndLimit;
    let tableSubAppName = "Find" + findview + "Table"
    dataListName = tableSubAppName.toLowerCase() + "_dataList";
    rerender = true;
  }
  else {
    request['ID'] = params.UIFlowReducer['accountID'];
    request.EventName = "GetAccount_WF";
  }
  let account_SummaryResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
  let data = account_SummaryResponse.data;

  return { dataListName, data, rerender }
}

export const getDefaultData = async (state,UpdateState) => {
  let { userInfoReducer,UIFlowReducer } = store.getState();
  
  try {
    let agencyID = UIFlowReducer.agencyID ? UIFlowReducer.agencyID : userInfoReducer.defaultAgencyID
    let agentId = UIFlowReducer.agentID ? UIFlowReducer.agentID : userInfoReducer.defaultAgentID
    let [AgencyInformation, AgentInformation] = await Promise.all([
      await getAgencybyID_V2(agencyID),
      await getAgentbyID_V2(agentId)
    ]);
    if (!AgencyInformation && !AgentInformation) return null;
    let values = { ...AgencyInformation, ...AgentInformation }
    let defaultData = { AgencyInformation: AgencyInformation, AgentInformation: AgentInformation }
    // let translatedResponse = dataConversionv2(defaultData);
    await store.dispatch({
      type: actionType.SET_AGENTID,
      payload: AgentInformation.ID
    });
    if(AgencyInformation && AgentInformation){
    await UpdateState("pagelevelValues",{...state.pagelevelValues,...defaultData},false)
    await store.dispatch({ type: actionType.SET_EXPANDPANEL_DATA, payload: true });
  }
    return { pagelevelValues: defaultData, transactionPrimaryObject: defaultData, values };
  } catch (err) {
    console.log(err);
  }
}

const getAgencybyID_V2 = async (ID = null) => {
  if (!ID) return null;
  let userName = localStorage.getItem("userName");
  let agencyLookupRequest = { ...require("../../models/getAgencyList_V1.json") };
  agencyLookupRequest.ServiceRequestDetail.UserName = userName;
  agencyLookupRequest.ID = ID;
  agencyLookupRequest.OwnerId = sessionStorage.getItem("OwnerID");
  agencyLookupRequest.EventName=  GetCommonEventName();
  let { data: { AgencyInformation } } = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", agencyLookupRequest);
  return (AgencyInformation && AgencyInformation) || {};
}

const getAgentbyID_V2 = async (ID = null) => {
  if (!ID) return null;
  let agentLookupRequest = { ...require("../../models/getAgent_V1.json") };
  agentLookupRequest.ServiceRequestDetail.UserName = localStorage.getItem("userName");
  agentLookupRequest.ID = ID;
  agentLookupRequest.OwnerId = sessionStorage.getItem("OwnerID");
  agentLookupRequest.EventName=  GetCommonEventName();
  let { data: { AgentInformation } } = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", agentLookupRequest);
  return (AgentInformation && AgentInformation) || {};
}

export const getAgencyAgentInfo = () => {
  let { userInfoReducer: { defaultAgencyID = "", defaultAgentID = "", Role = "" } } = store.getState();
  return { AgencyID: defaultAgencyID, AgentID: defaultAgentID, Role: Role }
}

export const getAccountProfile = async () => {
  let userHeaderRequset = require("../../models/getAccountProfile.json");
  userHeaderRequset.UserProfile.UserName = localStorage.getItem("userName");
  try {
    let { data } = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", userHeaderRequset);
    if (data && data.AccountProfile) {
      let userInfo = data.AccountProfile[0] || {};
      userInfo['userName'] = userHeaderRequset.UserProfile.UserName
      logUserDetailRequest(userInfo, 'Login', 'pwdAuthentication')
      store.dispatch({ type: actionType.SET_USER_INFO, payload: userInfo })
      store.dispatch({ type: actionType.SET_AGENTID, payload: userInfo.defaultAgentID })
      store.dispatch({ type: actionType.SET_AGENCYID, payload: userInfo.defaultAgencyID })
    }
  } catch (err) {
    console.log(err);
  }
}

export const setStateList = async (state, updateState) => {
  const redux = store.getState();
  let stateList = redux.metadataReducer.stateList
  let primaryState = redux.metadataReducer.primaryState
  let states=[]
  if (stateList.length > 0) {
      for (let i = 0; i < stateList.length; i++) {
          if (!states.includes(stateList[i].State)) {
            states.push(stateList[i].State)
          }
      }
  }
  let stateString = await getStateListString(states)
  store.dispatch({type:actionType.SET_STATE_STRING, payload: stateString})
  store.dispatch({type:actionType.SET_PRIMARY_STATE, payload: primaryState})
  if(updateState){
    let transactionPrimaryObject = Object.assign({},{...state.transactionPrimaryObject})
    let pagelevelValues = Object.assign({}, {...state.pagelevelValues});
    transactionPrimaryObject["StateInfo"]= {}
    pagelevelValues["StateInfo"]= {}
    transactionPrimaryObject["StateInfo"]["StateList"] = stateString
    transactionPrimaryObject["StateInfo"]["PrimaryState"] = primaryState
    pagelevelValues["StateInfo"]["StateList"] = stateString
    pagelevelValues["StateInfo"]["PrimaryState"] = primaryState
    updateState("transactionPrimaryObject", transactionPrimaryObject, false)
    updateState("pagelevelValues", pagelevelValues, false)
  }
  return stateString
}

const getStateListString = (states) => {
  let stateString = states.toString()
  let replacedString
  if (stateString.includes(",")) {
      replacedString = stateString.replaceAll(",", '","')
  }
  else {
      replacedString = stateString
  }
  return replacedString
}

export const invokeGetStateList = async (eventName) => {
  const redux = store.getState();
  let request = {};
  let response
  let responseArr
  request.EventName = eventName
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request["ServiceRequestDetail"] = Object.assign({},getServiceRequestDetail());
  request.ID = redux.UIFlowReducer.submissionID;
  response = await getSubmissionAxios.post("/core/invokeWorkflowEvent", request)
      .then((response) => {
          responseArr = response.data.StateList
          let primaryState = response.data.PrimaryState
          store.dispatch({type: actionType.SET_STATELIST, payload: responseArr})
          store.dispatch({type: actionType.SET_PRIMARY_STATE, payload: primaryState})

      })
      .catch((err) => {
          console.log(err);
      });
  return responseArr
};

export const onRedirectToSummary= async(EventName,props,state,component,functions)=>{

 let lob = props.metadataReducer.lob;
 //lob='CommercialProperty'
 let nav = Object.assign({}, props.navigation);
 let n=nav.pageNavigation.map(x=>x.view).indexOf(props.location.pathname)
 let quoteNav = nav.pageNavigation[n];
 let lobLandingIndex = quoteNav.LandingIndex[lob];
 props.setCurrentPageIndex(lobLandingIndex)
 let summayNavigation =props.location.pathname.slice(1) + 'SummaryNavigation'
 let quoteSummaryChildren = nav[summayNavigation][lob];
 quoteNav.children = quoteSummaryChildren;
 let Template = [];
 Template = quoteSummaryChildren[lobLandingIndex].children ? quoteSummaryChildren[lobLandingIndex].children[0].template : quoteSummaryChildren[lobLandingIndex].template;
 props.updateValues({
   template: Template,
   summayNavigation:nav[summayNavigation]
 })
 // props.onRedirectToSummary();
 // return
 quoteSummaryChildren[lobLandingIndex].children ? props[quoteSummaryChildren[lobLandingIndex].children[0].onLoadAction](Template) : props[quoteSummaryChildren[lobLandingIndex].onLoadAction](Template);
//  props[quoteSummaryChildren[lobLandingIndex].onLoadAction](Template);
props.setPageNavigation(nav);
}

  export const onPreviewPolicy = async (EventName,props,state,component,functions) => {
    try {
      store.dispatch({ type: actionType.SHOW_LOADER });
      props.updateValues({loading: true})
      let Request = Object.create({});
      Request=Object.assign({},Request,props.pagelevelValues.PreviewPolicy)
      const reduxState = store.getState();
      let path = reduxState.ownerResources.clientInfo.UI_MEMORY_PDF_ROOT_PATH
      Request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
      Request["OwnerId"] = sessionStorage.getItem("OwnerID");
      Request["EventName"] = props.navigation.policySummaryNavigation[props.metadataReducer.lob][props.UIFlowReducer.currentPageIndex].PreviewProposalEventName;
      Request["ID"] = props.UIFlowReducer.policyID;
      Request["IsPreview"]=props.navigation.policySummaryNavigation[props.metadataReducer.lob][props.UIFlowReducer.currentPageIndex].IsPreview;
      Request["EffectiveDate"]=props.pagelevelValues.PolicyInformation['EffectiveDate'];
      Request["ExpirationDate"]=props.pagelevelValues.PolicyInformation['ExpirationDate'];
      Request["ProductNumber"]=props.productDetails.ProductNumber
      Request["ProductVerNumber"]=props.productDetails.ProductVerNumber;
      let response = await AxiosInstance.post("core/invokeWorkflowEvent", Request);
      path = path + response.data.SolartisDocuments[0].SolartisRelativePath
      FileHandlerAxios(response.data.SolartisDocuments[0].SolartisDocumentName, path)
      props.updateValues({loading: false})
      store.dispatch({ type: actionType.HIDE_LOADER });

    }
    catch (error) {
      props.updateValues({loading: false})
      console.log("Error in IssuePolicy in API", error.message);
        }
    }

export  const saveMandatoryRules = async (EventName,props,state,component,functions) => {
  try {
    store.dispatch({ type: actionType.SHOW_LOADER });
    //props.updateValues({loading: true})
    const reduxState = store.getState();
    const productDetails = reduxState.UIFlowReducer.productDetails;
    const request = await getJSONRequestFromAPI("Solartis_CF_1_25_V1_CF_SaveBusinessRulePolicyChanges_1.0.0.1", productDetails)
    request.ROOTID =  props.UIFlowReducer.policyID;
    request.ID = request.PARENTID =  props.UIFlowReducer.policyID;
    request.RuleInformationList = state['mandatoryrules_dataList'];
    const response = await AxiosInstance.post("core/invokeWorkflowEvent", request)
    return response.data;
  }
  catch (error) {
    console.log('error occured', error.message);
  }
  finally {
    //props.updateValues({loading: false})
    store.dispatch({ type: actionType.HIDE_LOADER });
  }
}

export const submitHandler = async (params, state,props) => {
  let { policyID } = params;
  try {
    props.updateValues({loading: true})
    let Request = Object.create({});
    Request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    Request["OwnerId"] = sessionStorage.getItem("OwnerID");
    Request["EventName"] = "Solartis_CF_1_25_V1_CF_UpdatePolicyV2_1.0.0.1";
    Request["PARENTID"] = Request["ROOTID"] = Request["ID"] = policyID;
    Request = Object.assign(Request, state.pagelevelValues.IssuePolicy)
    let Response = await AxiosInstance.post("core/invokeWorkflowEvent", Request);
    Response = await Response.data;
    props.updateValues({loading: false})
    return Response;
  } catch (error) {
    props.updateValues({loading: false})
    console.log("Error in savebusinesscontactinformation  API", error.message);
    return {
      "messageDialog": true,
      "infoDialog": "Alert!",
      "contentDialog": `${error.message}`
      }
    }
  }

export const onRedirectToSummary_MultiLine= async(EventName,props,state,component,functions) => {
  let lob = "MultiLine";
  let nav = Object.assign({}, props.navigation);
  let n=nav.pageNavigation.map(x=>x.view).indexOf(props.location.pathname)
  let quoteNav = nav.pageNavigation[n];
  let lobLandingIndex = quoteNav.LandingIndex[lob];
  props.setCurrentPageIndex(lobLandingIndex)
  let summayNavigation =props.location.pathname.slice(1) + 'SummaryNavigation'
  let quoteSummaryChildren = nav[summayNavigation][lob];
  quoteNav.children = quoteSummaryChildren;
  let Template = [];
  Template = quoteSummaryChildren[lobLandingIndex].template;
  props.updateValues({
      template: Template,
      summayNavigation:nav[summayNavigation]
  })
  props[quoteSummaryChildren[lobLandingIndex].onLoadAction](Template);
  props.setPageNavigation(nav);
}

export const getParentState = (props, state) => {
  let parentState
  switch(store.getState().metadataReducer.lob){
      case "GeneralLiability":{
          parentState = props.parentProps.pagelevelValues && props.parentProps.pagelevelValues.LocationDetail && props.parentProps.pagelevelValues.LocationDetail.LocationState
          return parentState ? parentState :  props.metadataReducer.primaryState
      }
      case "CommercialProperty":{
        parentState = props.parentProps.pagelevelValues && props.parentProps.pagelevelValues.LocationDetail && props.parentProps.pagelevelValues.LocationDetail.LocationState
        return parentState ? parentState :  props.metadataReducer.primaryState
      }
      case "CommercialAutomobile" : {
        return props.policyState || 'CW'
      }
      default:
          return store.getState().metadataReducer.hasOwnProperty("primaryState") && store.getState().metadataReducer.hasOwnProperty("primaryState") !== "" ? store.getState().metadataReducer.primaryState : 'CW'
  }
}

export const selectExistingInsured_homeowners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  let {customTableRowIndex,state,UpdateState}=props;
  store.dispatch({type: actionType.FINDINSURED_CLICK, payload:false});
  let response = await getInsured(stateObj,props,props.functions.UpdateState,customTableRowIndex.ID);
 await UpdateState("pageMetadata",{"ApplicationDetail":response.mapEntity.ApplicationDetail},true);
 await store.dispatch({type: Action.SET_INSUREDID, payload: customTableRowIndex.ID});
}


const selectExistingInsured_CL=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  let reduxValues=store.getState();
  let {customTableRowIndex,state,UpdateState}=props;
  store.dispatch({type: actionType.FINDINSURED_CLICK, payload:false});
  let response = await getInsured(state,reduxValues.metadataReducer,props.functions.UpdateState,customTableRowIndex.ID);
 await UpdateState("pageMetadata",{"ApplicationDetail":response.mapEntity.ApplicationDetail},true);
 await store.dispatch({type: Action.SET_INSUREDID, payload: customTableRowIndex.ID});
}


const handleROwLevelInputChange_MPL = async (value,propsObj ,stateObj, component,functions,e, props) => {
  functions.UpdateState('loading',true,true);
  const reduxState = store.getState();
  let effectiveDate = (propsObj.values && propsObj.values.EffectiveDate) ? propsObj.values.EffectiveDate : (props.values && props.values.EffectiveDate) ? props.values.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  let subApplication =  reduxState.navigationReducer.pageNavigation[reduxState.UIFlowReducer.currentPageIndex-1].SubApplicationName;
  const {   ObjectVerId :  objectVerId,  ObjectId:objectId , OwnerId} = props.componentState.pageMetadata.ApplicationDetail
  const subAppInfo = {objectId, objectVerId, subappName: subApplication}
  store.dispatch({
    type: actionType.FINDINSURED_CLICK,
    payload: false
  });
  let metaDataRes = await  constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: {...propsObj.transactionPrimaryObject}, EffectiveDate: effectiveDate })

  store.dispatch({type: Action.SET_INSUREDID, payload: props.customTableRowIndex.ID});
if (propsObj.values.ExistingInsured==="Yes"){
  props.customTableRowIndex[component.AttributeName] = value[component.AttributeName]
let EventName=reduxState.ownerResources.clientInfo.CommonEventName_MPL;
  let {response}  = await getInsured(props.componentState,reduxState.metadataReducer,functions.UpdateState,  props.customTableRowIndex.ID,{},EventName);
const entitySubAppObject = await getEntitySubAppMapping( { objectId, objectVerId ,OwnerId, subApplication  })
 await EntitySubAppTranslation(response.data, reduxState.metadataReducer, props.componentState.pagelevelValues, {}, {}, props.componentState.pageMetadata, undefined, entitySubAppObject)

let {AdditionalInsuredDetail} = response.data

// hide table
// if(entityTranslatedResponse) {

  props.UpdateState("transactionPrimaryObject",{...propsObj.transactionPrimaryObject, InsuredSearchMPL: {}},false);
  props.UpdateState("pagelevelValues",{...propsObj.pagelevelValues, InsuredSearchMPL: {}},false);

   functions.UpdateState("SelectedAddnlInsured_dataList", AdditionalInsuredDetail, false);

// }
}

if (propsObj.values.ExistingInsured==="No"){

  let select_flag=true;
  if ((props.componentState.pagelevelValues.InsuredMPL && props.componentState.pagelevelValues.InsuredMPL.CRDNumber == props.customTableRowIndex.CRDNumber)
  ||
  (props.componentState["SelectedAddnlInsured_dataList"] && props.componentState["SelectedAddnlInsured_dataList"].length > 0 && props.componentState["SelectedAddnlInsured_dataList"][0].AdditionalInsuredMPL.AdditionalInsuredCRDNumber == props.customTableRowIndex.CRDNumber)
  ){
  triggerInfoToast("The selected Insured has already been added.", { position:Toast.POSITION.TOP_RIGHT })
  select_flag=false;
  }

  if(select_flag){
  if(!(props.componentState.pagelevelValues.InsuredMPL) || Object.keys(props.componentState.pagelevelValues.InsuredMPL).length===0){
   props.UpdateState("pagelevelValues",{...props.componentState.pagelevelValues,InsuredMPL:props.customTableRowIndex},false);
  }
  else if(!(props.componentState["SelectedAddnlInsured_dataList"]) || props.componentState["SelectedAddnlInsured_dataList"].length<2)
  {
    // Translation not allowed, Need to check with API team and revert
    let AdditionalInsuredDetail=props.componentState["SelectedAddnlInsured_dataList"]||[];
    let AdditionalInsuredObject =  {
              AdditionalInsuredMPL: {
                AdditionalInsuredName: props.customTableRowIndex.InsuredName,
                AdditionalInsuredCRDNumber: props.customTableRowIndex.CRDNumber,
                AdditionalInsuredAddress: props.customTableRowIndex.InsuredFullAddress
              },AdditionalInsuredCommunicationMPL:{}
    }
    AdditionalInsuredDetail.push(AdditionalInsuredObject);
    props.UpdateState("SelectedAddnlInsured_dataList", AdditionalInsuredDetail ,false);
  }
  else if(props.componentState["SelectedAddnlInsured_dataList"].length>=2){
    triggerInfoToast("A maximum of two additional insured can be added", { position:Toast.POSITION.TOP_RIGHT })
  }}
}
functions.UpdateState('loading',false,true);
props.UpdateState("pageMetadata",metaDataRes,true);
scrollBehaviourDown()
}
export const handleFormSubmit = async (e,props) =>{
  if (e.keyCode === 13) {
     e.preventDefault();
   await existingInsuredMPL('', '', props.state, props.component, props.functions, e, props, null)
 }
}
export const existingInsuredMPL = async (eventName, props, state, comp, func, e, mainProps, customTableRowIndex) => {
  func.UpdateState('loading',true,true)
  try {
      let bool = true;
      store.dispatch({
          type: actionType.FINDINSURED_CLICK,
          payload: bool
        });
        let {functions} = mainProps;
        let { currentPageIndex } = mainProps;

  let effectiveDate = (state.values && state.values.EffectiveDate) ? state.values.EffectiveDate : (props.values && props.values.EffectiveDate) ? props.values.EffectiveDate : format(new Date(), 'yyyy-MM-dd')

  let {ObjectId: objectId, ObjectVerId: objectVerId} = mainProps.props.pageNavigation[currentPageIndex - 1]

  let subAppInfo= {subappName: mainProps.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId}

  let {pagelevelValues,transactionPrimaryObject, values}=state;

  let [metadataVal,summaryMetadata] = await Promise.all( [ constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: transactionPrimaryObject, EffectiveDate: effectiveDate }),  constructAndInvokeMetadataCall({...subAppInfo,subappName:['InsuredSummaryMPL']}, { transactionPrimaryObject: transactionPrimaryObject, EffectiveDate: effectiveDate })])

  functions["UpdateState"]("pageMetadata",{"ApplicationDetail":metadataVal.ApplicationDetail},false);


  let offsetInfo={ StartLimit : "0", EndLimit : "500" };


  if (pagelevelValues.InsuredSearchMPL.InsuredName && pagelevelValues.InsuredSearchMPL.CRDNumber){
    triggerInfoToast("Please enter either Name or CRD Number", { position:Toast.POSITION.TOP_RIGHT })
    }

  if (pagelevelValues.InsuredSearchMPL.ExistingInsured==="Yes"){

    let response= await getFindConsoleAPI_ReqUtil("Insured",{...transactionPrimaryObject.InsuredSearchMPL},offsetInfo, state, props);
    response.request.AgencyNumber=props.values.AgencyNumber;
    let resp=await commonProductAxiosInstance.post(response.url,response.request)
    response=resp.data.Insured;

    let newResponse = response.map((data)=>{
    return {...data, rowMetaData: summaryMetadata.ApplicationDetail.SubApplicationDetailList[0].AttributeDetailList}
})

functions["UpdateState"]("insuredsummarympl_dataList",newResponse,true);
  }
 else if (pagelevelValues.InsuredSearchMPL.ExistingInsured==="No"){
  let response= await getESIAdvisorSearch(pagelevelValues);
  let RuleInformationList = response.data.RuleInformationList || [];
  response=response.data.Insured;
   if(!response){
    response=[];
  }
  let RuleInfoErrorCode ={}
  if(RuleInformationList.length > 0){
    RuleInformationList.forEach((err) =>{ RuleInfoErrorCode[err.RuleInfoErrorCode]= true })
  }
  let metadatainsured = await constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: {...transactionPrimaryObject,RuleInfoErrorCode}, EffectiveDate: effectiveDate })
  functions["UpdateState"]("pageMetadata",{"ApplicationDetail":metadatainsured.ApplicationDetail},false);
  let newResponse = response.map((data)=>{
  return {...data, rowMetaData: summaryMetadata.ApplicationDetail.SubApplicationDetailList[0].AttributeDetailList}
   })
  functions["UpdateState"]("insuredsummarympl_dataList",newResponse,true);
  }

} catch (error) {
  console.log("There is a error in displaying Existing", error);
}
finally{
  func.UpdateState('loading',false,true)
}
}


const clearValues=async(props,value)=>{
  try{
  let {pagelevelValues}=props;
  let keys_datalist=[];
  keys_datalist=Object.keys(props.componentProps).filter(value=>value.includes("_dataList"))
  //Set state value with datalist to empty array
  for(let i=0;i<keys_datalist.length;i++){
  props.functions["UpdateState"](keys_datalist[i],[],false);
  }
  store.dispatch({type: actionType.SET_INSUREDID, payload: ""});
  store.dispatch({type: actionType.FINDINSURED_CLICK, payload:false});
  //get agency agent screen specific subapplication names.
  let subAppName=store.getState().navigationReducer.pageNavigation[0].SubApplicationName;
  let newPageLevelValues={}
  subAppName.forEach(subapp=>{
    newPageLevelValues[subapp]={...pagelevelValues[subapp]}
  });
  props.functions["UpdateState"]("pagelevelValues",{...newPageLevelValues},false);
  props.functions["UpdateState"]("transactionPrimaryObject",{...newPageLevelValues},false);
  await props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
  props.reference.resetValidations();
  }catch(e){
    console.log('Error in clearValues',e);
  }
}

export const clearSubappData = async(clearData,pagelevelValues={},transactionPrimaryObject={},updateState,rerender=false) =>{
  clearData.forEach((subapp,i) =>{
    let updateFlag = rerender ? (clearData.length)-1===i : rerender
    if(pagelevelValues.hasOwnProperty(subapp)){
      Object.assign(pagelevelValues,{[subapp]:{}})
      updateState("pagelevelValues",pagelevelValues,updateFlag)
    }
    if(transactionPrimaryObject.hasOwnProperty(subapp)){
      Object.assign(transactionPrimaryObject,{[subapp]:{}})
      updateState("transactionPrimaryObject",transactionPrimaryObject,updateFlag)
    }
  })
}

export const getESIAdvisorSearch = async (params) => {
   let getESIAdvisorSearchRequest = {};
   const reduxState = store.getState();
   let EventName=reduxState.ownerResources.clientInfo.CommonEventName_MPL;
   getESIAdvisorSearchRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
   getESIAdvisorSearchRequest = Object.assign({}, getESIAdvisorSearchRequest, {
   OwnerId: sessionStorage.getItem("OwnerID"),
   EventName,
   TargetEventName:"ESIAdviserSearch",
   InsuredName:params.InsuredSearchMPL.InsuredName,
   CRDNumber:params.InsuredSearchMPL.CRDNumber
   });
   let response = await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2", getESIAdvisorSearchRequest);
   return response;
  };

  export const  deleteAdditionalInsured  = async (value, propsObj ,stateObj, component,functions,e, props) => {
    const tempList = [...propsObj.SelectedAddnlInsured_dataList]
    tempList.splice(props.componentState.IteratorIndex,1)
    let NewAddnlnsuredList = propsObj.SelectedAddnlInsured_dataList.filter((data, index)=>index !== props.componentState.IteratorIndex)
    functions.UpdateState("SelectedAddnlInsured_dataList", tempList, true);
     }
     export const  deleteInsured  = async (value,propsObj ,stateObj, component,functions,e, props) => {
      const reduxState = store.getState();
      let effectiveDate = (propsObj.values && propsObj.values.EffectiveDate) ? propsObj.values.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
      let subApplication =  reduxState.navigationReducer.pageNavigation[reduxState.UIFlowReducer.currentPageIndex-1].SubApplicationName;
      const {   ObjectVerId :  objectVerId,  ObjectId:objectId , OwnerId} = props.componentState.pageMetadata.ApplicationDetail
      const subAppInfo = {objectId, objectVerId, subappName: subApplication}
     store.dispatch({
       type: actionType.FINDINSURED_CLICK,
       payload: false
     });
     let metaDataRes = await  constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: {}, EffectiveDate: effectiveDate })
     functions.UpdateState("pageMetadata",{"ApplicationDetail":metaDataRes.ApplicationDetail},false);
     Object.keys( props.componentState).forEach(datalist => {
       if(datalist.includes("_dataList")){
         functions.UpdateState(datalist,[],false);
       }
    })
      functions.UpdateState("transactionPrimaryObject",{},false);
      functions.UpdateState("pagelevelValues",{},true);
      }

// -- Start LOBUtils
export const handleInputChangeV2_MPL = async (value, component, isDynamicTable, datatableRowindex, props, callback,needMetadataCall) => {
  try{
    const argObj = {dataListKey:"SelectedAddnlInsured_dataList"}
    await handleChange(value, component, props,props.componentState,props.functions)
    // await handleInputChangeV2(value,component, isDynamicTable, datatableRowindex,callback,props.state,props, argObj)
    props.functions["updateStateValues"]({ [argObj.dataListKey] :props.componentState[argObj.dataListKey]})
    }
     catch(exception) {
        triggerToast("Error in handleInputChangeV2_MPL");
        console.log('Error in handleInputChangeV2_MPL',exception);
      }
  }
  const resetInsuredPage=async(value,component, isDynamicTable, datatableRowindex,props)=>{
    try{
      await props.handleInputChangeV2(value,component, isDynamicTable, datatableRowindex,props);
      props.reference.resetValidations();
    }catch(e){
      console.log('Error in resetInsuredPage',e);
    }
  }

  export let changeDOB = async(props,value,key=false)=>{
    try{
    let {SubApplicationName,AttributeName} = props.component
    let newPageValues={...props.pagelevelValues}
    if (!newPageValues[SubApplicationName]) {
      newPageValues[SubApplicationName] = {};
    }
    newPageValues[SubApplicationName][`${AttributeName}Changed`]="true"
    let transactionPrimaryObject = {...newPageValues}
    let redux = store.getState()
    let objectVerId,objectId
    let { currentPageIndex } = redux.UIFlowReducer;
    if(key){
      objectVerId = redux.UIFlowReducer.currentQuoteConsoleInfo.template[0].objectVerId
      objectId = redux.UIFlowReducer.currentQuoteConsoleInfo.template[0].objectId
    }else{
      objectVerId = props.props.pageNavigation[currentPageIndex - 1].ObjectVerId
      objectId = props.props.pageNavigation[currentPageIndex - 1].ObjectId
    }
    let subAppInfo= {subappName: key ? props.componentState.subappNameList : props.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId};
    let metadataResponse = await constructAndInvokeMetadataCall(subAppInfo, {transactionPrimaryObject});
    props.functions.UpdateState("pageMetadata",metadataResponse,false)
    props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
    props.functions.UpdateState("transactionPrimaryObject",transactionPrimaryObject,true)
    }catch(e){
      console.log('Failed in changeDOB',e)
    }
  }
// -- END LOBUtils


    export const defaultTableControls = {
      "tableUtils": {
        "AddRow": "",
        "onExpand": "",
        "onChildSave": "",
        "EditRow": "",
        "OnDetailEdit": "",
        "redirectTo": ""
      },
      "TableOptions": {
        "ChildrenSubApplicationName": null,
        "ChildrenComponent": null,
        "AddButtonNeeded": true,
        "SortingNeeded": false,
        "FilteringNeeded": false,
        "FilterIconNeeded": false,
        "rowsPerPageOptions": [5, 10, 25],
        "RowsPerPage": 5,
        "ActionName": "Action",
        "ActionListOptions": []
      }
    }

export const LOBUtil = {
  PL:{
    clearValues,
    resetInsuredPage,
    AgencyLookUpList_Orchid,
    changeDOB,
    existingInsured,
    "Orchid_Insured": {
      "insuredsummary":{
        selectExistingInsured:selectExistingInsured_homeowners,
        "tableUtils": {
          "AddRow": null,
          "onExpand": null,
          "onChildSave": null,
          "EditRow": selectExistingInsured_homeowners,
          "OnDetailEdit": "",
          "redirectTo": ""
        },
        "TableOptions": {
          "ChildrenSubApplicationName": null,
          "ChildrenComponent": null,
          "AddButtonNeeded": false,
          "SortingNeeded": false,
          "FilteringNeeded": false,
          "FilterIconNeeded": false,
          "rowsPerPageOptions": [5, 10, 25],
          "RowsPerPage": 5,
          "ActionName": "Action",
          "ActionListOptions": []
        }
    }
  }
},
CL:{
  clearValues,
  resetInsuredPage,
  AgencyLookUpList_Orchid,
  changeDOB,
  existingInsured,
    "CL_Insured": {
      "insuredsummary":{
        selectExistingInsured:selectExistingInsured_CL,
        "tableUtils": {
          "AddRow": null,
          "onExpand": null,
          "onChildSave": null,
          "EditRow": selectExistingInsured_CL,
          "OnDetailEdit": "",
          "redirectTo": ""
        },
        "TableOptions": {
          "ChildrenSubApplicationName": null,
          "ChildrenComponent": null,
          "AddButtonNeeded": false,
          "SortingNeeded": false,
          "FilteringNeeded": false,
          "FilterIconNeeded": false,
          "rowsPerPageOptions": [5, 10, 25],
          "RowsPerPage": 5,
          "ActionName": "Action",
          "ActionListOptions": []
        }
    }
  }
},
MPL:{
  clearValuesMPL:clearValues,
  existingInsuredMPL,
  handleFormSubmit,
  AgencyLookUpList_Orchid,
   deleteInsured,
  "Insured_MPL": {
    "insuredsummarympl" : {
      handleROwLevelInputChange_MPL,
      handleInputChangeV2 :handleInputChangeV2_MPL,
      "tableUtils": {
        "AddRow": null,
        "onExpand": null,
        "onChildSave": null,
        "EditRow": handleROwLevelInputChange_MPL,
        "OnDetailEdit": "",
        "redirectTo": ""
      },
      "TableOptions": {
        "ChildrenSubApplicationName": null,
        "ChildrenComponent": null,
        "AddButtonNeeded": false,
        "SortingNeeded": false,
        "FilteringNeeded": false,
        "FilterIconNeeded": false,
        "rowsPerPageOptions": [5, 10, 25],
        "RowsPerPage": 5,
        "ActionName": "Action",
        "ActionListOptions": []
      }
    },
    "additionalinsuredmpl": {
      deleteAdditionalInsured,
      handleInputChangeV2 :handleInputChangeV2_MPL,
      scrollBehaviourDown
    },
    "additionalinsuredcommunicationmpl": {
      handleInputChangeV2 :handleInputChangeV2_MPL
    }
  }
},
InsuredUtils:{
  clearValues,
  resetInsuredPage,
  AgencyLookUpList_Orchid,
  changeDOB,
  existingInsured,
  selectExistingInsured:selectExistingInsured_homeowners
}
}

export const invokeGetProductDetailsUtil = async (component) => {
  let getProductDetailsRequest = {};
  let SelectedLineOfBusiness = [];
  let getProductDetailsResponse = {};
  let productDetails = {};
  //alert("Alert invokeGetProductDetailsUtil");
  getProductDetailsRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  getProductDetailsRequest = Object.assign({}, getProductDetailsRequest, {
    OwnerId: sessionStorage.getItem("OwnerID"),
    EventName: "GetProductDetailsV2"
  });

    SelectedLineOfBusiness.push({
      LOBName: component.DefaultValue,
      InsuranceType: component.AttributeName
    });

    getProductDetailsRequest["SelectedLineOfBusiness"] = SelectedLineOfBusiness;
    try {
      getProductDetailsResponse = await commonProductAxiosInstance.post(  // check import
        "/core/invokeWorkflowEvent/V4",
        getProductDetailsRequest
      );
      getProductDetailsResponse = getProductDetailsResponse.data;
    } catch (e) {
      console.error("Error in GetProductDetails API", e.message);
    }
    if (getProductDetailsResponse.hasOwnProperty("SelectedLineOfBusiness") && getProductDetailsResponse["SelectedLineOfBusiness"].length > 0) {
      productDetails = getProductDetailsResponse.SelectedLineOfBusiness[0];
    }
    //this.props.setLOB(productDetails.LOBName);
    //this.props.setProductDetails(productDetails);
    store.dispatch({
      type: "SET_LOB",
      payload: productDetails.LOBName,
    });
    store.dispatch({
      type: "SET_PRODUCT_DETAILS",
      payload: productDetails,
    });
  return productDetails
};


export const asyncForEach = async(array, callback)=> {
  for (let index = 0; index < array.length; index++) {
  await callback(array[index], index, array);
}
}

export const constructDefaultValues = (pageMetadata = {}) => {

  let defaults = {}
  if(pageMetadata.hasOwnProperty('ApplicationDetail') && pageMetadata.ApplicationDetail.hasOwnProperty('SubApplicationDetailList') && pageMetadata.ApplicationDetail.SubApplicationDetailList.length === 0)
  return defaults;

  else {
    let { SubApplicationDetailList } = pageMetadata.ApplicationDetail;
    let subApplicationLength = SubApplicationDetailList.length

    for(let i = 0; i < subApplicationLength; i++){
      let type = SubApplicationDetailList[i].Type.toLowerCase()
      if(type === "detail" || type === "form" || type === "dialog" || type === "accordion") {

        let { AttributeDetailList, ApplicationName } = SubApplicationDetailList[i];
        let attributeDetailLength = AttributeDetailList.length;
        for(let j = 0; j < attributeDetailLength; j++) {
          let { AttributeName, DefaultValue } = AttributeDetailList[j];
          // if(DefaultValue !== "" ){
            if(!defaults[ApplicationName]) defaults[ApplicationName] = {}
            defaults[ApplicationName][AttributeName] = DefaultValue;
            if (AttributeDetailList[j].ComponentType.toLowerCase() === 'selectcalender') {
              let dates = handleOnChangeN(AttributeDetailList[j], { [AttributeDetailList[j].AttributeName]: AttributeDetailList[j].DefaultValue })
              for (let k = 0; k < Object.keys(dates).length; k++) {
                defaults[ApplicationName] = { ...defaults[ApplicationName], ...dates[k] }
              }
            }
          // }
        }
      }
    }
  }

  return defaults;
}

//  --------------------------------------------CommonUtil


//  Start No Code----
export const displayAction = (actionListOptions, rowData, filteredActions) =>{
    actionListOptions.map((obj)=>{
        if(obj.hasOwnProperty("conditions") && obj.conditions.length > 0){
            let showAction = true;
            let {conditions} = obj;
            conditions.map((item)=>{
                let operator = item.operator.toLowerCase();
                if(operator === "equal"){
                    {(item.value === rowData[item.key] && showAction) ? showAction = true : showAction = false}
                }
                if(operator === "notequal" ){
                    {(item.value !== rowData[item.key] && showAction) ? showAction = true : showAction = false}
                }
                             if(operator === "greaterthan" || operator === "greaterthanequal" ||operator === "lessthan" || operator === "lessthanequal" ){
                    let value = null;
                    {typeof item.value === "string" ? value = parseInt(item.value) : value = item.value}

                    if(operator === "greaterthan" ){
                        {(rowData[item.key] > value && showAction) ? showAction = true : showAction = false}
                    }
                    if(operator === "greaterthanequal" ){
                        {(rowData[item.key] >= value && showAction) ? showAction = true : showAction = false}
                    }
                    if(operator === "lessthan" ){
                        {(rowData[item.key] < value && showAction) ? showAction = true : showAction = false}
                    }
                    if(operator === "lessthanequal" ){
                        {(rowData[item.key] <= value && showAction) ? showAction = true : showAction = false}
                    }
                }
            })
           if(showAction){
            filteredActions.push(obj);
           }
        }
        else filteredActions.push(obj);
    })
}


export const postAPI=async(propsObj,Action,request)=>{
  if(Action.URL) {
      let response={}
      let APIURL =  Action.URL && Action.URLVersion ? `${Action.URL}/${Action.URLVersion}` : Action.URL
      if(Action.URLVersion ==="V3" || Action.URLVersion ==="V4")
          response = await commonProductAxiosInstance.post(APIURL,request);
      else
          response = await AxiosInstance.post(APIURL,request);
      if(response) {
          response = response.data
          if (response.ResponseStatus && (response.ResponseStatus.toLowerCase() === 'failed' || response.ResponseStatus.toLowerCase() === 'error')) {
              triggerToast(Action.failedToastNotificationMessage || response.ResponseStatus)
              console.log('Error:: ', response.ResponseStatus)
                          if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
              {
                  let {UpdateState} = propsObj;
                  if(UpdateState){
                      UpdateState("loading",false,true)
                  }else{
                      UpdateState=propsObj.functions.UpdateState;
                      if(UpdateState){
                          UpdateState("loading",false,true)
                      }
                  }

              }

                      }
          else if(Action.successToastNotificationMessage && Action.successToastNotificationMessage !== "") {
              successToast(Action.successToastNotificationMessage);
          }
      }
      return response
  }
}

export const basePageActions =async (props,routeName)=>{
  let { pageInfo = {}, navigationReducer: { navigation }} = props;
  let pageName = (props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.pageName) ? props.UIFlowReducer.pageData.pageName : props.UIFlowReducer.pageName;
  if(!pageName && props.navigationReducer.pageName)
  pageName=props.navigationReducer.pageName;
  let { defaultPageName = "" } = pageInfo;
  let obj = {}
  try{
      let sidebar = navigation.pageNavigation.hasOwnProperty(routeName) ? navigation.pageNavigation[routeName].sidebar : []
      let sidebarChildIndex = sidebar.findIndex(i => i.url === `/${routeName}`);
      if(sidebarChildIndex === -1) throw new Error("Navigation not available");
      let nav = sidebar[sidebarChildIndex].children || [];
      let pageIndex =  nav.findIndex(i => i.pageName === pageName)!==-1 ?  nav.findIndex(i => i.pageName === pageName) :nav.findIndex(i => i.pageName === defaultPageName) !== -1 ? nav.findIndex(i => i.pageName === defaultPageName):0
      await store.dispatch(setCurrentPageIndex(pageIndex+1))
      obj['currentPageInfo'] = nav[pageIndex];
      return obj
}
catch(err){
console.log("Error in Base Page Mount :: ", err)
}
}



// END No Code---->

export const updateValueToRedux=(props,value)=>{
    let {component}=props;
    let {SubApplicationName,AttributeName}=component;
    store.dispatch({ type: actionType.SET_UI_VALUES, payload: {[`${SubApplicationName}__${AttributeName}`] :value} });
  }


export const EntitySubAppTranslationV3 = async (apiResponse, props) => {

  const { SubApplicationEntity = null } = props;

  if(!SubApplicationEntity) return;

  let translatedResponse = {}, translatedList = {};

  for(const [key, value] of Object.entries(SubApplicationEntity)) {
      let checkKey = value ? value : key;
      if(apiResponse[checkKey] && typeof apiResponse[checkKey] === 'object' && !Array.isArray(apiResponse[checkKey])) {
          translatedResponse[key] = flattenObject(apiResponse[checkKey])
      }
      else if(apiResponse[checkKey] && Array.isArray(apiResponse[checkKey])) {
          translatedList[`${key.toLowerCase()}_dataList`] = apiResponse[checkKey].map((element) => {
              let result = element;
              return result;
          });
          if(apiResponse.hasOwnProperty('Count')) translatedResponse[`${key.toLowerCase()}_dataListSize`] = apiResponse.Count;
      }
      else if(apiResponse[checkKey]) {
          translatedResponse[key] = flattenObject(apiResponse[checkKey]);
      }
  }
}

  export const formRequestConstruction = async(request,component, Action) => {
    try{
    let {Selectable ={}} = Action;
    let subappName = Selectable.SubapplicationName ? Selectable.SubapplicationName : component.subapplicationName;
    let key=[] ;
    if ( Object.keys(Selectable).length>0 && Selectable.AttributeName && Selectable.Value){
        request[subappName] && request[subappName].map((obj,ind)=>{
            if( obj[Selectable.AttributeName] && (obj[Selectable.AttributeName]===Selectable.Value)){
                return request[subappName]=  key =[...key,obj]
            }
          })
    }
    return {...request}
}   catch(e){
    console.log('Error in formRequestConstruction Method : :', e)
}
}

export const invokeThirdPartyApi = async(props,response) => {
      if(response.data && response.data.IntegrationLogID && response.data.IntegrationLogID!== null && response.data.IntegrationLogID!==''){
          let ratingRequest={};
          ratingRequest["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
          props.pageInfo.thirdPartyApiEventMapping && Object.entries(props.pageInfo.thirdPartyApiEventMapping).forEach(([key, value]) => {
          ratingRequest[key]=value
          });
          ratingRequest["ObjectID"] = response.data.IntegrationLogID;
          const ratingResponse = await commonProductAxiosInstance.post("/core/invokeWorkflowEvent/V4",ratingRequest)
          if(ratingResponse.data && ratingResponse.data.IntegrationSummary &&ratingResponse.data.IntegrationSummary[0] ){
          let request = ratingResponse.data.IntegrationSummary[0].Request;
          let response = ratingResponse.data.IntegrationSummary[0].Response;
          let message = ratingResponse.data.IntegrationSummary[0].Message;
          await store.dispatch({type: actionType.SET_RATING_REQUEST, payload: isValidJson(request) ? JSON.stringify(JSON.parse(request),null,2) : {}})
          ratingResponse.data.IntegrationSummary[0].Status && ratingResponse.data.IntegrationSummary[0].Status.toLowerCase()!=='failed'?  await store.dispatch({type: actionType.SET_RATING_RESPONSE, payload: isValidJson(response) ? JSON.stringify(JSON.parse(response),null,2):{}}) : store.dispatch({type: actionType.SET_RATING_RESPONSE, payload: message})
          }
      }
  }

  export const setDefaultValue = async(customOptions,response)=>{
    try{
    let setDefaultValueResponse = response[customOptions.ResponseKey];
    setDefaultValueResponse = response[customOptions.ResponseKey]
    let requiredOptions=  setDefaultValueResponse && Array.isArray(setDefaultValueResponse) && setDefaultValueResponse.map((data,ind)=>{
    let {[customOptions.DisplayValueKey]:DisplayValue,ID:Sequence,[customOptions.ValueKey]:Value}=data
        return{DisplayValue,Sequence,Value};
    })
    if(Array.isArray(requiredOptions) && requiredOptions.length>0) return requiredOptions
    else return []
} catch(e){
    console.log("Error in setDefaultValue Method::",e)
}
}

 
export const setPagemetaDataonStateObj = (subapp, state) =>{
  const pageMetadata = {}
  pageMetadata.ApplicationDetail = {}
  pageMetadata.ApplicationDetail.SubApplicationDetailList = subapp
  return {...state, pageMetadata};
}

export const getObjectfromString = (str) => {
let keyValuePairs = str.split(',');
let resultObject = {};
keyValuePairs.forEach(pair => {
  let [key, value] = pair.split('~');
  resultObject[key] = value;
});
return resultObject
}

export const setFlowAndNavigation = (component) => {
  if(!component.Custom1.includes('~')){
    store.dispatch({ type: actionType.SET_FLOW , payload: component.Custom1});
    return;
  }
  let {FLOW, ...navigationCriteriaKeys} = component.Custom1 && getObjectfromString(component.Custom1)
store.dispatch({ type: actionType.SET_FLOW , payload: FLOW});
store.dispatch({ type: actionType.SET_NAVIGATIONCRITERIA_INFO , payload: navigationCriteriaKeys});
}

export const debounceCall = function () {
  let timer;
  return function (callBack, timeDelay) {
      clearTimeout(timer);
      timer = setTimeout(callBack, timeDelay*1000);
  }
}()


export const removeDiacritics =(address, component={}, props={}) => {

  let doCheck = false;
console.log("ayy check removeDiacritics", address, component,props)
  const reduxState = store.getState();
  if(component.Action && ('normalizeString' in component.Action)){
    doCheck = component.Action.normalizeString === 'Y'
  }
  else if(props.pageInfo && ('normalizeSubapps' in props.pageInfo) && props.pageInfo.normalizeSubapps.length > 0){
    doCheck =  props.pageInfo.normalizeSubapps.includes(component.SubApplicationName)
  }
  else if(props.pageInfo && ('normalizeString' in props.pageInfo)){
    doCheck = props.pageInfo.normalizeString === 'Y'
  }
  else if('normalizeString'in reduxState.ownerResources.clientInfo){
    doCheck = reduxState.ownerResources.clientInfo['normalizeString']
  }
  if(!doCheck) return address;

  if(typeof address==='object'){
    let normalizedObj = {}
    for(const [key, value] of Object.entries(address)){
      if(typeof value === 'string'){
        normalizedObj[key] = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      }
      else{
        normalizedObj[key] = value
      }
    }
    return normalizedObj;
  }
  else if(typeof address==='string'){
    return address.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }
  else{
    return address;
  }
}


export const LoaderComponent = (comp, props) => {
  const state =  props.componentState;
  const component = props.component;
  let isLoading = false;
  isLoading = component.Custom5.split(',').some((key)=>{
    return key && state[`${key}_loading`] ? true: false;
  })
  const loader = isLoading ? comp : null
  return [loader, isLoading]
}