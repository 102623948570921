import React, { useEffect, forwardRef,useState } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import store from "../../../../../store";
import appActions from "../../../../../actions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import DynamicComponent from "../../../corecomponents/DynamicComponent";
import { pageLoadActions } from '../../../utils/Common/NoCodeUtils';
import {setexpandPanelData} from "../../../../../actions/applicationMetadataAction"
import { constructAndInvokeMetadataCall } from "../../../utils/Common/MetaUtils";


const {actionType} =appActions
//import { stubFalse } from "lodash";
const ExpandPanelV2 = ((props) => {
  const { isMultiLOB } = props;
  let ExpandValues = {};
  const [pageMetadata, setPageMetadata] = useState({});
  const [expanded, setExpanded] = useState(false);

  let expandPanelRender = true
  if(props.utilfunctionNeeded && props.utilfunction){
    expandPanelRender = props.utilfunction.ExpandPanelRender(props)
  }

useEffect  (() => {
  async function metadatacall(){
  const {objectId, objectVerId, subApplication} = props.pageInfo
  let response= await constructAndInvokeMetadataCall( { subappName: subApplication, objectId, objectVerId},{ transactionPrimaryObject:{...ExpandValues,isExpandPanel:'Yes'}})
  setPageMetadata(response)
  props.setexpandPanelData(false)
  let isExpandPanel =  response.ApplicationDetail&&response.ApplicationDetail.SubApplicationDetailList.length> 0 && response.ApplicationDetail.SubApplicationDetailList[0].IsExpandable==='Y';
  setExpanded(isExpandPanel);
  }
  if(props.metadataReducer.expandPanelData)
  metadatacall()
},[props.metadataReducer.expandPanelData])
useEffect  (() => {
  async function callMetadata(){
  let { pageActions = [] } = props.pageInfo;
  let values = await pageLoadActions(pageActions, props, {}, props.functions);
  props.functions && props.functions.UpdateState && props.functions.UpdateState("loading",false,false)
  store.dispatch({ type: actionType.SET_UI_VALUES, payload: {expandPanelData: false} })
  let isExpandPanel =  values.pageMetadata.ApplicationDetail && values.pageMetadata.ApplicationDetail.SubApplicationDetailList.length> 0 && values.pageMetadata.ApplicationDetail.SubApplicationDetailList[0].IsExpandable==='Y';
  setExpanded(isExpandPanel);
  setPageMetadata(values.pageMetadata)
  }
  if(props.UIFlowReducer.expandPanelData)
  callMetadata()
},[props.UIFlowReducer.expandPanelData])

    ExpandValues = props.pagelevelValues

    const ExpansionPanelSummary = withStyles({
      root: {
        marginBottom: -1,
        minHeight: 10,
        height: 30,
        marginLeft: "1px !important",
        "&$expanded": {
          minHeight: 10,
        },
      },
      content: {
        "&$expanded": {
          margin: "12px 0",
        },
      },
      expanded: { transform: 'rotate(360deg) !important' },
      expandIcon: {
        order: -1,
        transform: 'rotate(270deg)'
      },
    })(MuiExpansionPanelSummary);

    var currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    let formatCurrency = (str) => {
      let result = currencyFormatter.format(parseFloat(str));
      result = result.substr(0, result.lastIndexOf(".") > 0 ? result.lastIndexOf(".") : result.length);
      return result;
    }
    let SubApplicationDetailList = pageMetadata.ApplicationDetail  && pageMetadata.ApplicationDetail.SubApplicationDetailList
    return (
      <React.Fragment>
       {
      expandPanelRender && SubApplicationDetailList && SubApplicationDetailList.length > 0 ?
        <MuiExpansionPanel   expanded={expanded}   className={`custom-collapsibleExpandPanel theme_border  theme_collapsiblecolor custombreadcrumbdetails content Default-ExpandpanelStyles`}   ref={props.myForwardedRef} >
          <ExpansionPanelSummary onClick={()=>setExpanded(!expanded)} className="custom_accordion" expandIcon={<ExpandMoreIcon ></ExpandMoreIcon>}>
            <Grid item xs={6}>
              <p className="panelSummaryContent large_heading" style={styles.panelSummaryContent}>
                {SubApplicationDetailList[0].Description}
              </p>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails   style={{background:"white",borderRadius:"0px 0px 10px 10px", paddingTop: "1rem", transition: "all 2s"}} >
            <Grid container spacing={2}>
              {
               SubApplicationDetailList.map((subapplication, index) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <Grid container spacing={2} direction="row" >
                        <Grid item xs={12}>
                        <DynamicComponent
                            components={subapplication.AttributeDetailList}
                            pagelevelValues={ExpandValues}
                            transactionPrimaryObject={ExpandValues}
                            gridStyle={"three-pixel-padding"}
                            />
                            </Grid>
                            </Grid>
                        </Grid>
                    );
                })
              }
            </Grid>
          </ExpansionPanelDetails>
        </MuiExpansionPanel>: null
        }
      </React.Fragment>
    )
})


const mapStateToProps = (state) => ({
  dashboardReducer: state.dashboardReducer,
  metadataReducer: state.metadataReducer,
  premium: state.workflowReducer.premium,
  compositePremium: state.workflowReducer.compositePremium,
  UIFlowReducer: state.UIFlowReducer
});
const mapDispatchToProps = (dispatch) => {
  return {
    setexpandPanelData: (value) => dispatch(setexpandPanelData(value))
  };
};

const styles = {
  panelSummaryContent: {
    
  },
  panelDetailHeading: {
    fontSize: "13px",
    fontWeight: "bolder",
    marginBottom: "0px",
    textTransform: "uppercase",
    color: "#25468C"
  },
  panelDetailValues: {
    fontSize: "12px",
    color: "black"
  },
};

ExpandPanelV2.propTypes = {
  classes: PropTypes.object.isRequired,
};


const ConnectedComponent = connect(mapStateToProps,mapDispatchToProps)(ExpandPanelV2);

const ExpandComponentWFR = forwardRef((props, ref) => {
  return (
    <ConnectedComponent
    {...props}
    myForwardedRef={ref}
    />
    )
  })


export default ExpandComponentWFR;
