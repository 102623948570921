import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';

export default class Sectionheading extends Component {
  render() {
    const { component } = this.props;
    //let className = "col-xs-11 col-sm-12 col-md-12 col-lg-12 " + component.StyleClass
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={component.Size ? component.Size : 12} lg={component.Size ? component.Size : 12} key={component.AttributeName} className={`${component.AttributeStyleClass} section_heading`}>
          <Typography variant="h6" className={`custom-subheading heading-font ${component.StyleClass}`}>{component.ComponentLabel}</Typography>
        </Grid>
      </React.Fragment>
    )
  }
}
