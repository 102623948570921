import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
class Image extends Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
  }


  onClick= (e)=>{
    if(this.props[this.props.component.OnClickAction])
     this.props[this.props.component.OnClickAction](e,this.props);
 }

  render() {
    const { component, parentProps} = this.props;
    console.log(this.props,"props")
    return(
        <React.Fragment>
           <Grid
          item
          xs={component.Size}
          sm={component.Size}
          md={component.Size}
          lg={component.Size}
          key={component.AttributeName}
          className={`${component.StyleClass}`}
        >
        <img src = {`${this.props.OwnerResources[component.ComponentLabel]}`} className={`${component.AttributeStyleClass}`} onClick={(e) => this.onClick(e)} alt ={`${component.ComponentLabel}`}>
        </img>

    {this.props.children && <div>{this.props.children}</div>}
    </Grid>
    </React.Fragment>
    )
}
}

const mapStateToProps = (state) => ({
  OwnerResources : state.ownerResources.imagesource,
});



export default connect(mapStateToProps)(Image);
