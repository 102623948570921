import React from 'react';

class Main extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <div className='app-body'>
                {this.props.children}
            </div>
        )
    }
}
export default Main;