import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from "react-redux";




const LayoutLoader = (props) => {

   const {ownerResources} =  useSelector((state)=> state)

   let component = props.component

   let imgSrc = (component.Action && component.Action.LoaderSrc) ? ownerResources[component.Action.LoaderSrc] : ownerResources.spinner_logo_path


   let styles = component.Action.styles

   let ImgStyles = (styles && styles.ImgStyles) || {width: 'auto', height: '5vh'}

return(
    <>
       <Grid  item xs={12} sm={12} md={Number(component.Size) || "auto"} lg={Number(component.Size) || "auto"} key={component.AttributeName} className={`${component.AttributeStyleClass}`}>
    {
        imgSrc ?
        <img className="custom-spinner" src={imgSrc} alt={component.componentLabel} style={{...ImgStyles}}/>
        : (component.Action.LoaderMessage && 
        <Typography className="text-center">{this.props.dynamicLoadingMessage}</Typography>)

    }
    </Grid>
    </>
)
}

export default LayoutLoader
