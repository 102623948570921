import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PageLoader from '../../../controls/PageLoader';
import ExpandableComponent from '../ExpandableComponent/ExpandableComponent';
import RenderTemplate from '../../../../../template/RenderTemplate';
import dynamicRender from '../dynamicRender';
import {constructAndInvokeMetadataCall} from "../../../utils/Common/MetaUtils"
class IteratorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,
            SubApplicationDetailList: []
        }
    }
    async componentDidMount() {
        const key = this.props.templateName;
        if (this.props.utilfunction[key].hasOwnProperty('componentDidMount')) {
            let values = await this.props.utilfunction[key].componentDidMount(this.state, this.props);
            if (values && Object.keys(values).length > 0)
                this.updateValues(values);
        }
    }
    async componentDidUpdate(prevProps, prevState) {
        const key = this.props.templateName;
        if (this.props.utilfunction[key].hasOwnProperty('componentDidUpdate')) {
            let values = await this.props.utilfunction[key].componentDidUpdate(this.state, this.props, prevState, prevProps,this.functions);
            if (values && Object.keys(values).length > 0)
                this.updateValues(values);
        }
    }
    callback = async (state) => {
        const { transactionPrimaryObject, objectId, objectVerId } = this.props;
        let pagelevelValues = {};
        if (( this.state.subappNameList && this.state.subappNameList.length !== 0) || (this.state.subAppGroupName && this.state.subAppGroupName.length > 0) ) {
            pagelevelValues = transactionPrimaryObject;
            const primaryState = 'CW'
            let EffectiveDate = this.props.values.EffectiveDate
            let subAppInfo={
                subappName:this.state.subappNameList,
                objectId:objectId,
                objectVerId:objectVerId,
                state:primaryState,
                subApplicationGroupList:this.state.subAppGroupName
            }
            const  response  = await constructAndInvokeMetadataCall(subAppInfo, {transactionPrimaryObject:pagelevelValues,EffectiveDate});
            this.setState((prevState) => {
                let SubApplicationDetailList = []
                SubApplicationDetailList = response.ApplicationDetail.SubApplicationDetailList;
                return { SubApplicationDetailList };
            }, () => {
            })
        }
    }

    handleInputChangeV2 = (value, component, isDynamicTable, datatableRowindex,props) => {
        const key = this.props.templateName;
        if (this.props.utilfunction[key].handleInputChangeV2) {
                let values = this.props.utilfunction[key].handleInputChangeV2(this.state, value, component,props);
                this.updateValues(values);

        }
        this.callback();
    }
    updateValues = (obj) => {
        this.setState({
            ...this.state,
            ...obj
        })
    }
    cc = () => {

        if (this.state.SubApplicationDetailList.length > 0) {
            for (let index = 0; index < this.state.SubApplicationDetailList.length; index++) {
                let components = this.state.SubApplicationDetailList[index].AttributeDetailList;
                for (let j = 0; j < components.length; j++) {
                    if (components[j].ComponentType === "Expand") {
                        let list = [];
                        let component = []
                        list = this.props[components[j].ResponseKey]; //this.props.selectedLOB //rateresponse
                        for (let k = 0; k < list.length; k++) {
                            let key = this.props.templateName,
                            name="";
                            if (this.props.utilfunction[key].hasOwnProperty('handleCollapseName')) {
                                let dname =  this.props.utilfunction[key].handleCollapseName(list[k][components[j]["AttributeName"]]);
                                name=dname ?dname:list[k][components[j]["AttributeName"]]
                                ;
                            }
                            component.push(
                                <ExpandableComponent pageName={name} key={index} >
                                {components[j].ChildSubApplicationList ?
                                    dynamicRender(components[j].ChildSubApplicationList) :
                                    <RenderTemplate  {...this.props} Template={this.props.pageInfo.children} data={list[k]} component={components[j]} statevalues={this.state}></RenderTemplate>}
                                </ExpandableComponent>
                            );

                        }
                        return component;

                    }
                }
            }
        }
        else
            return null;
    }
    UpdateState = (statename, value, rerender) => {
        if (rerender) {
          this.setState({
            [statename]: value,
          });
        } else {
          this.state[statename] = value;
        }
      };

        handleStateCollapseName = (value, data) => {
            let derivedName = value
            if (this.props.utilfunction[this.props.templateName].handleStateCollapseName) {
                derivedName = this.props.utilfunction[this.props.templateName].handleStateCollapseName(value, data);
            }

            return derivedName
        }

functions={
    UpdateState:this.UpdateState,
    handleInputChangeV2:this.handleInputChangeV2,
    handleStateCollapseName:this.handleStateCollapseName
}
    render() {
        return (
            <Fragment>
            { this.state.showLoader && <PageLoader></PageLoader>}
            {dynamicRender(this.state.SubApplicationDetailList,this.props,this.state,this.functions)}

            </Fragment>
        )
    }
}
const mapStateToProps = state => (
    {
        selectedLOB: state.multiFlowReducer.selectedLOB,
        ratedLOB: state.multiFlowReducer.ratedLOB
    }
)
export default connect(mapStateToProps)(IteratorComponent);
