import React, { Component } from "react";
import { Grid } from "@material-ui/core";
class EmptySpace extends Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
  }

  render() {
    const { component, isDynamicTable, datatableRowindex } = this.props;

    return (
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size}>
      </Grid>
    );
  }
}

const styles = {
  label: {
    width: '150%'
  }
};

export default EmptySpace;
