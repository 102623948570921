import React , {Fragment} from 'react';
import {   TableRow, TableCell, TableSortLabel } from "@material-ui/core";
import DataTableCheckBox from './DatatableCheckBox';

function DataTableColumns(props) {

    let { columnList, TableOptions, columnOptions, toggleSelect,ChildSubApplicationList } =props;
    let actionRequired = TableOptions['ActionListOptions'].length > 0 ? true : false;
    let detailRequired = TableOptions.ChildrenComponent != null || ChildSubApplicationList && ChildSubApplicationList.length > 0;
    let selectable = !!TableOptions.Selectable && TableOptions.Selectable;
    let rowHeadingNeeded = TableOptions.hasOwnProperty("PivotSummary") && TableOptions.PivotSummary.hasOwnProperty("render") ?TableOptions.PivotSummary.render:false;

    const createSortHandler = (property) => (event) => {
        columnOptions.handleSort(event, property);
    };

    return (
        <Fragment>
        {
            <TableRow className='custom_insured_detail_table theme_DataTable'>
                { (detailRequired || rowHeadingNeeded)  && <TableCell />}
                {   selectable &&
                    <DataTableCheckBox
                        {...props}
                        disabled={ !!props.dataList.length }
                        name={selectable.hasOwnProperty("attributeName") ? selectable.attributeName : "" }
                        checked={ props.isColumnChecked }
                        defaultChecked= { props.defaultChecked }
                        onChange={ (e)=> toggleSelect(e) }
                     />
                }
                {
                    <Fragment>
                        {
                            columnList.map((component) => {
                                if (component.RenderValue)
                                {
                                    let componentId = component.ComponentId;
                                    let styleClass = component.StyleClass ? component.StyleClass : "" ;
                                    if(TableOptions.FilteringNeeded){
                                        styleClass = styleClass + "dt-headCell";
                                    }
                                    if(component.RowHeadingStyle){
                                        styleClass = `${styleClass} ${component.RowHeadingStyle}`
                                    }
                                    return (
                                        <TableCell
                                            className={`${component.ComponentType.toLowerCase() === 'link' ? '' : styleClass} custom_insured_detail_table_text Custom_Data_Table_Column_Cell`}
                                            key={componentId}
                                            align="left"
                                            sortDirection={columnOptions.sortBy === componentId ? columnOptions.sortOrder : false}
                                        >
                                        {   !TableOptions.SortingNeeded && <>{component.ComponentLabel}</>}
                                        {   TableOptions.SortingNeeded &&
                                            <TableSortLabel
                                                active={columnOptions.sortBy === componentId}
                                                direction={columnOptions.sortBy === componentId ? columnOptions.sortOrder : "asc"}
                                                onClick={createSortHandler(componentId)}
                                            >
                                                {component.ComponentLabel}
                                            </TableSortLabel>
                                        }
                                        {   /*(TableOptions.FilteringNeeded) &&
                                            <TextField
                                                id={`${componentId}_filterText`}
                                                name={componentId}
                                                value={columnOptions.filtercriteria[componentId] || ""}
                                                onChange={(e) => columnOptions.handleFilter(e)}
                                                /* InputProps={
                                                    TableOptions.FilterIconNeeded && {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                 <FilterListIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                } */
                                            // />*/
                                        }
                                        </TableCell>
                                    )
                                }
                            })
                        }
                    </Fragment>
                }
                {
                    actionRequired &&
                    <TableCell key="actionitem" align="left" className={`Custom_Data_Table_Column_Cell ${TableOptions.FilteringNeeded} && dt-headCell`}>
                        {TableOptions.ActionName}
                    </TableCell>
                }
            </TableRow>
        }
        </Fragment>
    )
}

export default DataTableColumns;
