import {getMetdataRequest} from "./utils_AU";
import { getServiceRequestDetail,AxiosInstance } from '../../../utils/Common/API';
import { handleInputChangeV2 } from "../../../utils/Common/InputUtils";
import store from "../../../../../store";
import { triggerToast } from "../../../utils/Common/HelperUtils";
import * as Action from '../../../../../actions/types';
import { constructAndInvokeMetadataCall } from "../../../utils/Common/MetaUtils";
import { EntitySubAppTranslation } from "../../../utils/requestConstruction/ResponseTranslation";

async function componentDidMount() {
    const { popupDetail = null } = this.props;
    if(!popupDetail) return;
    if (this.props.UnderwriterWorksheet__popupStatus)
    {
      componentDidMount_underwriter(this);
    }
    else{
      await getMetdataRequest(this);
    }
}

/**
 * classObject param is "this" object of parent class
 * @param {this} clssObject
 * @returns
 */

export const handleInputChangeV2_MPL = async(value, component,props,state, isDynamicTable, datatableRowindex, propsObj) => {
  try{
      state = propsObj.componentState
      let dataListKey = propsObj && propsObj.componentState && propsObj.componentState.ResponseKey
      const argObj = {dataListKey}
      if(propsObj && propsObj.componentState && typeof propsObj.componentState.IteratorIndex === 'number'){
          state[argObj.dataListKey].forEach((data, index)=>{
           if(index === propsObj.componentState.IteratorIndex){
             let subappObj = data[component.SubApplicationName] || {}
             let dataObj = {[component.SubApplicationName]: Object.assign(subappObj,value)}
             Object.assign(data,dataObj);
           }
         })
         const IteratorInputChange = state[argObj.dataListKey] ? {[argObj.dataListKey] :state[argObj.dataListKey]} : {}
         return {IteratorInputChange}
        }else{
      const obj =  await handleInputChangeV2(value, component,props,state,  functions, propsObj.componentState, propsObj.componentProps);
      return {...obj}
       }
}
catch(exception){
  console.log("Exception in handleInputChangev2", exception)
}
}

async function componentDidMount_underwriter(classObject) {
    classObject.props.parentState.UpdateState('loading',true,true);
    let {props}=classObject;
    const {  ProductID : objectId , ProductVerID : objectVerId } = classObject.props.productDetails;
    let subApplicationList = classObject.props.popupDetail.popupContent.subApplicationList
    let subappInfo =  { subappName: subApplicationList, objectId, objectVerId }
    const [getresponse, metadataResponse] = await Promise.all([getGetUnderWriterWorksheetMPL(props) ,constructAndInvokeMetadataCall(subappInfo)]);
    await underwritertranslateupdate(props,metadataResponse,getresponse)
    await store.dispatch({type: Action.SET_POLICY_DATE, payload: getresponse.data.Date});
    let metadataResponseUpdated = await constructAndInvokeMetadataCall(subappInfo, {transactionPrimaryObject: classObject.props.pagelevelValues})
    classObject.UpdateStateContent("pageMetadata", metadataResponseUpdated, false);
    classObject.props.parentState.UpdateState('loading',false,true);

}

export const underwritertranslateupdate=async(props,metaDataResponse,getresponse)=>
{
    try
    {
    let response = await getEntitySubAppTranslation(props,metaDataResponse,getresponse.data.UnderWriterDetail);
    let underwritterworksheet_datalist =  response ? [{...response}] : [{}];
    props.functions.UpdateState("underwritterworksheet_datalist", underwritterworksheet_datalist, false);
    props.functions.UpdateState("pagelevelValues", {...getresponse.data}, false);
    props.functions.UpdateState("transactionPrimaryObject", {...getresponse.data}, true);
    }catch(error)
    {
        console.log('error in underwritertranslateupdate',error);
    }
}

const getGetUnderWriterWorksheetMPL=async(props)=>{
    try{
    let request = {};
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    const reduxState = store.getState();
    request.EventName=reduxState.ownerResources.clientInfo.CommonEventName_MPL;
    // if get , save
    request.TargetEventName= props.pageInfo.getEventName;
    request.ID = props.propsValue.UIFlowReducer.insuredID;
    const response= await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      throw new Error("Response Failed");
    }else{
    return response;
    }
  }
  catch(error){
    console.log("getGetUnderWriterWorksheetMPL error",error);
    triggerToast(error.message)
  }
}

export const EntitySubAppTranslation_Metadata =(state, props, response, MetadataRes)=>{
  try{
    const reduxState = store.getState();
    let Values = {}
    let SubApplicationEntity = MetadataRes.ApplicationDetail.SubApplicationEntity
    Object.entries(SubApplicationEntity).forEach(async([key, value])=>{
      let newobj = {[value]: key}
      if(response.hasOwnProperty(value))
      {
        Object.assign(Values, await EntitySubAppTranslation({[value] : response[value]}, reduxState.metadataReducer, state.pagelevelValues, {}, {}, MetadataRes, undefined, newobj))
      }
    })
    return Values;
  }
  catch(exception){
    console.log("Error in EntitySubAppTranslation_Metadata", exception)
  }
}

export const getEntitySubAppTranslation = async(props,metaDataResponse,SourceDetail)=>{
  try{
  let result={};
  SourceDetail.forEach(async(detail)=>{
    let obj = {[detail.CoverageName]:detail}
    let Cov1 = await EntitySubAppTranslation_Metadata({}, props, obj, metaDataResponse)
    Object.assign(result,Cov1);
  })
  return result;
  }catch(error){
    console.log("getGetUnderWriterWorksheetMPL getEntityTranslation error",error);
  }
}

const functions = {
    componentDidMount : componentDidMount,
    getMetdataRequest : getMetdataRequest,
    handleInputChangeV2:handleInputChangeV2_MPL
}
export default functions;
