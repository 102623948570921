import React, { Component } from 'react'
import { Grid, Typography, InputLabel } from '@mui/material';
import InputMask from 'react-input-mask'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

class PhoneV3 extends Component {
    constructor(props) {
        super(props)

        ValidatorForm.addValidationRule('IsEmpty', (value) => {
            // console.log("From ManualValidation",value);
            if (!value) {
                return false;
            }
            return true;
        });

    }
    componentDidMount() {

        const patternObj = {
            "(999) 999-9999" : { "pattern" : /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/,
                                "emptyPattern": "(___) ___-____",
                                "sequencePattern":/\(([0-9]){3}\) [0-9]\1{2}-[0-9]\1{3}/},
            "9-999-999-9999": { "pattern" : /[0-9]{1}-[0-9]{3}-[0-9]{3}-[0-9]{4}/,
                                "emptyPattern":"_-___-___-____",
                                "sequencePattern":/([0-9]){1}-[0-9]\1{2}-[0-9]\1{2}-[0-9]\1{3}/}
            }

        console.log("Check1====>",this.props);
        console.log("Check2====>",this.props.component.InputFormatPattern);
        console.log("Check3====>",patternObj[this.props.component.InputFormatPattern]);

        ValidatorForm.addValidationRule('phoneformat', (value) => {
            if (value){
                if(value==patternObj[this.props.component.InputFormatPattern]["emptyPattern"])
                return true
            return patternObj[this.props.component.InputFormatPattern]["pattern"].test(value) ;
           }
           return true
        } );

        ValidatorForm.addValidationRule('sequencephoneformat', (value) => {
            if (value){
                if(value==patternObj[this.props.component.InputFormatPattern]["emptyPattern"])
                return true
            return !patternObj[this.props.component.InputFormatPattern]["sequencePattern"].test(value) ;
           }
           return true
        } );
    }
    onChange = (e) => {

                let value = Object.assign({}, { [e.target.name]: e.target.value });
        this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);

    }
    render() {
        const { component, attributeValue, variant } = this.props;
        let isRequired = component.IsRequired === 'Y' ? true : false;
        let componentLabel = isRequired ? this.props.componentLabel + ' *' : this.props.componentLabel;
        let isReadOnly = component.IsReadonly;
        const validations = [];
        const errorMessages = [];
        let componentvalue = this.props.componentvalue;
        if (isRequired) {
            validations.push('required');
            errorMessages.push(component.RequiredMessage);
            validations.push('IsEmpty');
            errorMessages.push(component.RequiredMessage);
        }
        if(!isReadOnly){
            validations.push('phoneformat')
            errorMessages.push(component.ValidatorMessage);
        }
		// validations.push('sequencephoneformat');
        // errorMessages.push('Phone number is invalid.');
        if (component.ValidationRegex) {
            validations.push('matchRegexp:'+ component.ValidationRegex);
            errorMessages.push(component.ValidatorMessage);
            }

        let value = '';
        if(component.IsReadonly===""){

        }
        if (component.DefaultValue === "" && component.AttributeValue === "" && componentvalue !== undefined) {
            // console.log("came in 1");
            value = componentvalue;
        }
        if (component.DefaultValue !== "" && component.AttributeValue !== "" && componentvalue !== undefined) {
            // console.log("came in 2");
            value = componentvalue;
        }
        if (component.DefaultValue !== "" && component.AttributeValue === "" && componentvalue === undefined) {
            // console.log("came in 3");
            value = component.DefaultValue;
        }
        if (component.DefaultValue !== "" && component.AttributeValue !== "" && componentvalue === undefined) {
            // console.log("came in 4");
            value = '';
        }
        if (component.DefaultValue !== "" && component.AttributeValue === "" && componentvalue !== undefined) {
            // console.log("came in 5");
            value = componentvalue;
        }
        let autocomplete="off";
        let custom3=component.Custom3 && JSON.parse(component.Custom3);
        (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
        const styleObject = component.AdjacentLabel === 'Y' ? {display: 'flex'} : {}
        return (
            <Grid item xs={12} sm={12} md={parseInt(component.Size)} lg={parseInt(component.Size)} style={styleObject}>
                { component.AdjacentLabel === 'Y' ? 
                <>
                <Grid 
                item 
                xs={4} 
                sm={4} 
                md={4} 
                lg={4}  
                className={`${component.AttributeStyleClass}_Label`}
                >
                    <InputLabel htmlFor={component.AttributeName} className="custom-label-down text-font adjacent_label" ref={this.labelRef}>
                        {componentLabel}
                    </InputLabel>
                </Grid>
                <Grid 
                item 
                xs={8} 
                sm={8} 
                md={8} 
                lg={8}  
                className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
                >
                <InputMask
                        mask={component.InputFormatPattern}
                        maskChar="_"
                        value={value}
                        onChange={(e) => this.onChange(e)}
                        disabled={isReadOnly}

                    >
                        {() =>
                            <TextValidator
                                disabled={isReadOnly}
                                style={{borderBottom:"1px dotted rgba(0, 0, 0, 0.42) !important"}}
                                name={component.AttributeName}
                                placeholder={component.ComponentLabel}
                                className={`custom-input ${component.StyleClass} lineHeight`}
                                fullWidth
                                validators={validations}
                                errorMessages={errorMessages}
                                variant={variant}
                                autoComplete={autocomplete}

                            />
                        }
                </InputMask>
                </Grid>
                </>:
                <InputMask
                        mask={component.InputFormatPattern}
                        maskChar="_"
                        value={value}
                        onChange={(e) => this.onChange(e)}
                        disabled={isReadOnly}

                    >
                        {() =>
                            <TextValidator
                                disabled={isReadOnly}
                                style={{borderBottom:"1px dotted rgba(0, 0, 0, 0.42) !important"}}
                                name={component.AttributeName}
                                placeholder={component.ComponentLabel}
                                label={<Typography className="custom-label-down text-font small_label">{componentLabel}</Typography>}
                                className={`custom-input ${component.StyleClass} lineHeight`}
                                fullWidth
                                validators={validations}
                                errorMessages={errorMessages}
                                variant={variant}
                                autoComplete={autocomplete}

                            />
                        }
                </InputMask>
                }
            </Grid>
        )
    }
}

export default PhoneV3;
