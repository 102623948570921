import React, { useState } from "react";
import { Button, Grid, Popper, Checkbox, FormGroup, FormControlLabel, FormControl, Paper } from "@material-ui/core";


const SingleCheckBox = (props) => {
    let [checked, setChecked] = useState(false);

    const handleCheck = (e) => {
        setChecked(e.target.checked);
        props.handleChange(e.target.name, e.target.checked);
    }

    return (
        <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            name={props.name}
            checked={checked}
            onChange={handleCheck}
            size="small"
        />
    )
}

export default function MultiCheckBoxPopper(props) {

    let [anchorEl, setAnchorEl] = useState(null);
    let [inputSet, setInputset] = useState(new Set());
    let [paramMap, setParamMap] = useState({});

    let { component } = props;
    let { ComponentLabel, AttributeStyleClass, IsReadonly, OnClickAction } = component;
    let onClick = props[OnClickAction] || props.lobUtil[OnClickAction];
    let itemTipParams = component.ItemTipParams && component.ItemTipParams.split(",");

    const handleChange = (name, isChecked) => {
        setInputset(prevSet => {
            isChecked && prevSet.add(name);
            (!isChecked && prevSet.has(name)) && prevSet.delete(name);
            return prevSet;
        })
        setParamMap(prevParamMap => ({ ...prevParamMap, [name]: isChecked ? "Yes": "No" }))
    }

    const togglePopper = ({currentTarget}) => {
        setInputset(new Set());
        setParamMap({});
        setAnchorEl(anchorEl ? null : currentTarget)
    }

    const handleClick = (e) => {
        onClick && onClick(e, props, component, paramMap);
        togglePopper(e);
    }

    return (
        <>
            <Button
                type="button"
                color="primary"
                disabled={IsReadonly}
                className={IsReadonly ? `${AttributeStyleClass} nav-btn-disabled` : AttributeStyleClass}
                onClick={togglePopper}>
                {ComponentLabel}
            </Button>
            <Popper
                placement="top"
                modifiers={{arrow:{enabled:true, element:anchorEl}}}
                open={!!anchorEl}
                anchorEl={anchorEl}
                transition>
                <Paper elevation={2} style={{padding: "10px"}}>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" column>
                            { itemTipParams && itemTipParams.map(item => {
                                let [label, keyName] = item.split("~");
                                return (
                                    <FormControlLabel
                                        key={keyName}
                                        control={<SingleCheckBox name={keyName} handleChange={handleChange} />}
                                        label={label} />
                                )
                            })}
                        </FormGroup>
                    </FormControl>
                    <Grid container direction="row"  alignItems="flex-start" style={{justifyContent:"space-between"}}>
                        <Button
                            type="button"
                            size="small"
                            className="nav-btn"
                            onClick={togglePopper}>
                            Close
                        </Button>
                        <Button
                            type="button"
                            size="small"
                            disabled={inputSet.size === 0}
                            className={inputSet.size === 0 ? "nav-btn disabled" : "nav-btn"}
                            onClick={handleClick}>
                            Done
                        </Button>
                    </Grid>
                </Paper>
            </Popper>
        </>
    );
}
