import React, { Fragment } from 'react';
import { Grid, IconButton, InputLabel, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import TableActionSelect from '../TableActionSelect';

function TableHeadingV2(props) {
    let { tableFunctions, subAppInfo, tableActions } = props;
    let { addRow = {}, actionSelect = {} } = tableActions;
    let { childComponent } = tableActions;
    let popupTemplateName = (childComponent && childComponent.popupTemplateName) ? childComponent.popupTemplateName : '';
    let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
    let headerName = SubApplicationDetail.Description;
    let SubApplnName = SubApplicationDetail.ApplicationName.toLowerCase();
    let grid_size = actionSelect.isNeeded ? 6 : 12

    return (
        <Fragment>
            <Grid container alignItems="center" justify="space-between" className='section_heading'>
                <Grid item container xs={grid_size} sm={grid_size} md={grid_size} lg={grid_size} alignItems="center" style={{ display: "inline-block" }}>
                    <Typography variant="h6" className="custom-subheading small_font heading-font">
                        {headerName}
                        {addRow.isNeeded && (
                            <IconButton className="addicon" onClick={() => tableFunctions[addRow.addRowFunction] && tableFunctions[addRow.addRowFunction](SubApplnName+"_dataList", props, popupTemplateName)}>
                                <AddCircle />
                            </IconButton>
                        )}
                    </Typography>
                </Grid>
                {actionSelect.isNeeded && (
                    <Grid item container xs={6} sm={6} md={6} lg={6} alignItems="center" justify="flex-end">
                        <InputLabel className="marginRight1em custom-row small_label">
                            {actionSelect.headerName}
                        </InputLabel>
                        <TableActionSelect {...props} />
                    </Grid> 
                )}
            </Grid>
        </Fragment>
    );
}

export default TableHeadingV2;
