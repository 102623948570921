
import * as navigationActions from './navigationAction';
import * as userInfoActions from "./userInfoActions";
import * as applicationMetadataAction from './applicationMetadataAction';
import * as UIFlowActions from "./UIFlowActions";
import * as type from './types';

const appActions = {
    navigationActions,
    userInfoActions,
    applicationMetadataAction,
    UIFlowActions,
    actionType:{...type}
 };

 export default appActions;