import React, { useState, useEffect, useRef } from "react";
import { Grid, InputLabel } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import MultiSelectValidator from './MultiSelectValidator';

export default function MultiSelectV2(props) {
    const [selectedValuesName, setSelectedValuesName] = useState([]);
    const prevProps = useRef(props);
    const labelRef = useRef();
    let {component,componentvalue,componentState} = props

    useEffect(() => {
        if(component.IsRequired === "Y"){
            ValidatorForm.addValidationRule("multiselectrequired", (value) => {
                if (Array.isArray(value) && value.length <= 0) {
                    return false;
                }            
                return true;
            });
        }
        
        if (component.DefaultValue.constructor === Array) {
            if(component.DefaultValue.length > 0 || componentvalue)
            props.updateValueWithSubplicationName({ [component.AttributeName]: component.DefaultValue }, component);
            setSelectedValuesName(component.DefaultValue);
        }
    }, []);

    useEffect(() => {
        if (!props.customTable) {
            if (JSON.stringify(componentvalue) !== JSON.stringify(prevProps.current.componentvalue)) {
                props.updateValueWithSubplicationName({ [component.AttributeName]: componentvalue }, component);
                setSelectedValuesName(componentvalue === "" ? [] : componentvalue);
            }
            else if (Array.isArray(component.DefaultValue) &&
                JSON.stringify(component.DefaultValue) !== JSON.stringify(prevProps.current.component.DefaultValue)) {
                props.updateValueWithSubplicationName({ [component.AttributeName]: component.DefaultValue }, component);
                setSelectedValuesName(component.DefaultValue);
            } 
            prevProps.current = props;
        }
    }, [component.DefaultValue, componentvalue]);

    const optionsList = componentState[`${component.SubApplicationName}_${component.AttributeName}__Options`] 
        ? componentState[`${component.SubApplicationName}_${component.AttributeName}__Options`]
        : component.SubApplicationAttributeValueList;

    const onChange = (event, newValue) => {
        if (newValue !== undefined) {
            const newValues = newValue.map(option => option.Value);
            setSelectedValuesName(newValues);
            let value = { [component.AttributeName]: newValues };
            if (component.OnChangeAction && component.OnChangeAction !== '') {
                props[component.OnChangeAction] && props[component.OnChangeAction](props, value, component, props.isDynamicTable, props.datatableRowindex, props);
            } else {
                props.handleInputChangeV2(value, component, props.isDynamicTable, props.datatableRowindex, props);
            }
        }
    };

    const validations = [];
    const errorMessages = [];
    let isRequired = component.IsRequired === "Y";

    let ComponentLabel = component.ComponentLabel ? (isRequired ? `${component.ComponentLabel} *` : component.ComponentLabel) : '';

    if (isRequired) {
        validations.push('multiselectrequired');
        errorMessages.push(component.RequiredMessage);
    }
    
    const styleObject = component.AdjacentLabel === 'Y' ? {display:'flex'} : {} 
    const { variant } = props

    return (
        <Grid item xs={12} sm={12} md={component.Size > 0 ? component.Size : 12} lg={component.Size > 0 ? component.Size : 12} className={`${component.AdjacentLabel !== 'Y' ? 'custom-text' : null} ${component.AttributeStyleClass}`} style={styleObject}>
        { component.AdjacentLabel === 'Y' ? 
        <>
            <Grid 
            item 
            xs={4} 
            sm={4} 
            md={4} 
            lg={4}  
            className={`${component.AttributeStyleClass}_Label`}
            >
                <InputLabel htmlFor={component.AttributeName} className="custom-label-down text-font adjacent_label" ref={labelRef}>
                    {ComponentLabel}
                </InputLabel>
            </Grid>
            <Grid 
            item 
            xs={8} 
            sm={8} 
            md={8} 
            lg={8}  
            className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
            >
            <MultiSelectValidator
                options={optionsList}
                selectedValuesName={selectedValuesName}
                onChange={onChange}
                validators={validations}
                errorMessages={errorMessages}   
                value={selectedValuesName}
                variant={variant}
                {...props}
            />
            </Grid>
        </>:
            <MultiSelectValidator
                options={optionsList}
                selectedValuesName={selectedValuesName}
                onChange={onChange}
                validators={validations}
                errorMessages={errorMessages}
                ComponentLabel={ComponentLabel}
                value={selectedValuesName}
                labelRef={labelRef}
                variant={variant}
                {...props}
            />}
        </Grid>
    );
}
