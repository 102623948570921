import React, { Component } from "react";
import { FormControl, Grid, InputLabel, Typography, Button } from "@material-ui/core";
import SelectDropdownValidator from "./SelectValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import { attributeSplit } from '../utils/Common/MetaUtils';
import CalendarValidator from './CalendarValidator';
import { format, isValid } from "date-fns";
import { commonSelectMethod } from './ButtonUtil';
import * as SelectUtil from '../utils/Common/SelectUtil'
import { AccordiononErrorContextConsumer } from "../sections/CustomComponents/ContextAPIUtils/AccordiononErrorContext";
import { getDateWithoutOffset } from "../utils/Common/DateUtils";

class SelectCalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
        enddateOpenClose: false,
        startdateOpenClose: false,
        dates: [],
        selectAttributeParams: {}
    }
    this.labelRef = React.createRef();
  }

  componentDidMount() {

    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });

    if (!this.props.customTable) {
      if (this.props.component.DefaultValue)
        this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
        let val= (this.props.component.OnChangeAction && SelectUtil[this.props.component.OnChangeAction] ) ? SelectUtil[this.props.component.OnChangeAction](this.props.component,{[this.props.component.AttributeName]: this.props.component.DefaultValue}) : ''
        if(Object.keys(val).length>0){
        for (let i = 0; i <  Object.keys(val).length; i++) {
          this.props.handleInputChangeV2(val[i], this.props.component);
        }}
    }
    if (this.props.component.AttributeParams != "" && this.props.component.AttributeParams != null) {
      let attrParams = attributeSplit(this.props.component.AttributeParams)
      if (Object.keys(attrParams).length > 1) {
        let keys = Object.keys(attrParams);
        let date1 = keys[0], date2 = keys[1]
        this.UpdateState('dateFieldName1', date1, false)
        this.UpdateState('dateFieldName2', date2, false)
      }

      if (attrParams['persistAttribute'] === "true" && this.props.hasOwnProperty("handlePersistValue")) {
        this.props.handlePersistValue({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
      }
      if(this.props.component.AttributeParams != "" && this.props.component.AttributeParams != null){
        let attrParams = attributeSplit(this.props.component.AttributeParams)
        this.setState({ selectAttributeParams : attrParams });
      }
    }

  }
  UpdateState = (statename, value, rerender) => {
    if (rerender) {
      this.setState({
        [statename]: value
      });
    } else {
      this.state[statename] = value;
    }
  }
  componentDidUpdate(prevProps) {
    if (!this.props.customTable) {

      if (
        this.props.componentvalue && prevProps.componentvalue &&
        this.props.componentvalue !== prevProps.componentvalue)
        this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.componentvalue }, this.props.component);
      else if (this.props.component.DefaultValue !== prevProps.component.DefaultValue && this.props.componentvalue !== this.props.component.DefaultValue) {
        this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
      }
    }
  }

  onChange = (e) => {
    let val = []
    if (this.props.component.OnChangeAction) {
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      this.props[this.props.component.OnChangeAction] && this.props[this.props.component.OnChangeAction](value,this.props.component, this.props.isDynamicTable, this.props.datatableRowindex,this.props)

      if (this.props.component.OnChangeAction != '' && SelectUtil[this.props.component.OnChangeAction]) {
        val = SelectUtil[this.props.component.OnChangeAction](this.props.component, value, this.props);
        this.requiredDates(value, val,this.props.component)
      }
      if (this.props.component.Action && Object.keys(this.props.component.Action).length > 0 && e.target.value != 'Custom') {
        let value = Object.assign({}, { [e.target.name]: e.target.value });
        commonSelectMethod(this.props.component, value, this.props);
      }
      this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
    }
    else {
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      if (this.props.component.AttributeParams != "" && this.props.component.AttributeParams != null) {
        let attrParams = attributeSplit(this.props.component.AttributeParams)
        if (attrParams['persistAttribute'] === "true" && this.props.hasOwnProperty("handlePersistValue")) {
          this.props.handlePersistValue(value, this.props.component);
        }
      }
      this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
    }
  }

  requiredDates = (value, val, component) => {
    if (value && value[component.AttributeName] === 'Custom') {
      this.UpdateState('CalenderNeeded', val, true)
    }
    else {
      this.UpdateState('CalenderNeeded', null, true);
      this.UpdateState('CreatedFromDate', null, true);
      this.UpdateState('CreatedToDate', null, true);
      this.UpdateState('dates', null, true)
    }
    let len = Object.keys(val).length
    for (let i = 0; i < len; i++) {
      this.props.handleInputChangeV2(val[i], this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
    }
  }

  checkButtonEnabler = () => {
    if (this.state.CreatedFromDate && this.state.CreatedToDate) {
      this.state.dates = [this.state.CreatedFromDate, this.state.CreatedToDate]
    }
    if (this.state.CreatedFromDate === null || this.state.CreatedToDate === null) {
      this.state.dates = []
    }
  }
  calenderOnChange = (event, datevalue, key) => {
    ValidatorForm.addValidationRule("IsValidDate", (datevalue) => {
      if (datevalue && !isValid(new Date(datevalue))) {
        return false;
      }
      return true;
    });
    if (datevalue !== null && datevalue !== undefined) {
      if (datevalue.includes('_')) {
      }
      else {

        let formattedDatevalue = isValid(new Date(datevalue)) ? format(getDateWithoutOffset(datevalue), this.props.component.OutputFormatPattern) : datevalue;
        let value = Object.assign({}, { [key]: formattedDatevalue });
        this.props.handleInputChangeV2(value, this.props.component);
        this.UpdateState(key, formattedDatevalue, true)
      }
    }
  }
  handleStartdateCalenderOpenClose = () => {
    (this.props.component.IsReadonly !== true && this.props.component.IsReadonly !== "true") && this.setState({ startdateOpenClose: !this.state.startdateOpenClose })
  }
  handleEnddateCalenderOpenClose = () => {
    (this.props.component.IsReadonly !== true && this.props.component.IsReadonly !== "true") && this.setState({ enddateOpenClose: !this.state.enddateOpenClose })
  }
  triggerApi = (e) => {
    if (this.props.parentProps && this.props.parentProps.subappNameList) {
      let subappList = this.props.parentProps.subappNameList
      subappList.map((app) => {
        if (app.endsWith('Summary')) {
          let SName = app.toLowerCase();
          this.props.functions.UpdateState(`${SName}_dataTableUpdate`, true, true)
        }
      })
    }

  }
  onBlur = (e, editable) => {
    let datevalue = e.target.value;
    if (datevalue && datevalue.includes('_') && editable) {
      let formattedDatevalue = isValid(new Date(datevalue)) ? format(getDateWithoutOffset(datevalue), this.props.component.OutputFormatPattern) : datevalue;
      let value = Object.assign({}, { [this.props.component.AttributeName]: formattedDatevalue });
      this.props.handleInputChangeV2(value, this.props.component);
    }
  }

  render() {
    let { component, isDynamicTable, datatableRowindex, componentvalue } = this.props;
    let { selectAttributeParams : { displayEmpty = false } } = this.state;
    displayEmpty = displayEmpty === "true" ? true : false;
    let options = component.SubApplicationAttributeValueList;
    if (component.SubApplicationAttributeValueList.length >= 2 && component.SubApplicationAttributeValueList[0].DisplayValue === 'Select') {
      options = component.SubApplicationAttributeValueList.slice(1);
    }
    this.checkButtonEnabler()
    let isRequired = component.IsRequired === "Y" ? true : false;
    const validations = [];
    const errorMessages = [];
    let labelClassName = component.IsRequired === "Y" ? "mandatory attributeLabel" : "attributeLabel";
    if (isDynamicTable) {
      componentvalue = this.props.componentvalue;
    }
    let labelId = component.ComponentId + "_label";
    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
    }
    if (this.state.CreatedFromDate && this.state.CreatedToDate) {
      let invalidErr = ' Please enter a valid date'
      let priorDateErr = 'End Date cannot be prior to Start date'

      let d1 = (new Date(this.state.CreatedFromDate)).toLocaleDateString("en-ZA"),
        d2 = (new Date(this.state.CreatedToDate)).toLocaleDateString("en-ZA");
      if (!isValid(new Date(d1)) || !isValid(new Date(d2))) {
        validations.push('IsValidDate')
        errorMessages.push(invalidErr);
      }
      if (new Date(d1) > new Date(d2)) {
        validations.push('Maxdate')
        errorMessages.push(priorDateErr)
        ValidatorForm.addValidationRule('Maxdate', () => {
          if (new Date(d1) > new Date(d2)) {
            return false
          }
          return true;
        });
      }
 }
    let attributeValue = '';
    if (!attributeValue && component.DefaultValue) {
      const result = options.find((option) => option['Value'] && option['Value'].trim() === component.DefaultValue.trim());
      attributeValue = (result && result['Value']) ? result['Value'] : '';
    }
    if (this.props.transactionValue !== undefined) {
      const result = options.find((option) => option['Value'] && option['Value'].trim() === this.props.transactionValue.trim());
      attributeValue = (result && result['Value']) ? result['Value'] : '';
    }
    if (this.props.transactionValue === undefined && component.DefaultValue) {
      const result = options.find((option) => option['Value'] && option['Value'].trim() === component.DefaultValue.trim());
      attributeValue = (result && result['Value']) ? result['Value'] : '';
    }
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";


    return (
      <>
        <Grid
          className={(component.AdjacentLabel === "Y" ? "labelrightalignment": "")+ (component.StyleClass + ' lineHeight custom-selectContainer')}
          // style={styles.selectContainer}
          item
          xs={12}
          sm={12}
          md={isDynamicTable ? 12 : component.Size}
          lg={isDynamicTable ? 12 : component.Size}
          key={component.AttributeName}
        >{component.AdjacentLabel === "Y"    && (!isDynamicTable && (
          <InputLabel
            ref={this.labelRef}
            id={labelId}
            className={`custom-dropdown-label labelstyle ${labelClassName} `}
            style={styles.label}
            shrink={displayEmpty || undefined}
          >
            {component.ComponentLabel}
          </InputLabel>
        ))}
          
          <FormControl
            className={`custom-dropdown custom-dropdown-select ${component.AttributeStyleClass} `}
          >
            {(component.AdjacentLabel !== "Y")  && (!isDynamicTable && (
              <InputLabel
                ref={this.labelRef}
                id={labelId}
                className={`custom-dropdown-label ${labelClassName} `}
                style={styles.label}
                shrink={displayEmpty || undefined}
              >
                {component.ComponentLabel}
              </InputLabel>
            ))}

            <AccordiononErrorContextConsumer>
              {(AccordiononError) =>
                <SelectDropdownValidator
                  labelId={labelId}
                  ComponentId={component.ComponentId}
                  name={component.AttributeName}
                  // value={componentvalue || ""}
                  displayEmpty={displayEmpty}
                  value={attributeValue}
                  options={options}
                  component={component}
                  onChange={this.onChange}
                  autoComplete={autocomplete}
                  {...AccordiononError}
                />
              }
            </AccordiononErrorContextConsumer>
          </FormControl>
        </Grid>
        {this.state.CalenderNeeded === true && (<>
          <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} key={component.AttributeName + this.state.dateFieldName1} >
            {component.AdjacentLabel === "Y"  &&(component.IsRequired === 'Y' ? !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down" style={styles.label}>{this.state.dateFieldName1} *</Typography> :
                    !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down" style={styles.label}>{'Start Date'}</Typography>)}
            <AccordiononErrorContextConsumer>
              {(AccordiononError) =>
                <CalendarValidator
                  validators={validations}
                  errorMessages={errorMessages}
                  open={this.state.startdateOpenClose}
                  onClose={() => this.handleStartdateCalenderOpenClose()}
                  onClick={() => this.handleStartdateCalenderOpenClose()}
                  format={component.InputFormatPattern}
                  component={component}
                  styleClass={component.StyleClass}
                  name={this.state.dateFieldName1}
                  id={this.state.dateFieldName1}
                  label={(component.AdjacentLabel !== "Y")&&(component.IsRequired === 'Y' ? !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down" style={styles.label}>{this.state.dateFieldName1} *</Typography> :
                    !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down" style={styles.label}>{'Start Date'}</Typography>)}
                  placeholder={component.ComponentLabel.replace(/&nbsp;/g, '')}
                  value={this.state.CreatedFromDate ? this.state.CreatedFromDate : null}
                  onChange={(event, value) => { this.calenderOnChange(event, value,this.state.dateFieldName1) }}
                  onBlur={this.onBlur}
                  editable={false}
                  autoComplete={autocomplete}
                  {...AccordiononError}
                />
              }
            </AccordiononErrorContextConsumer>
          </Grid>
          <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} key={component.AttributeName + this.state.dateFieldName2 } >
          {component.AdjacentLabel === "Y"  &&(component.IsRequired === 'Y' ? !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down" style={styles.label}>{this.state.dateFieldName2} *</Typography> :
                    !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down" style={styles.label}>{'End Date'}</Typography>)}
            <AccordiononErrorContextConsumer>
              {(AccordiononError) =>
                <CalendarValidator
                  validators={validations}
                  errorMessages={errorMessages}
                  open={this.state.enddateOpenClose}
                  onClose={() => this.handleEnddateCalenderOpenClose()}
                  onClick={() => this.handleEnddateCalenderOpenClose()}
                  format={component.InputFormatPattern}
                  component={component}
                  styleClass={component.StyleClass}
                  name={this.state.dateFieldName2}
                  id={this.state.dateFieldName2}
                  label={(component.AdjacentLabel !== "Y")&&(component.IsRequired === 'Y' ? !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down" style={styles.label}>{this.state.dateFieldName2} *</Typography> :
                    !this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down" style={styles.label}>{'End Date'}</Typography>)}
                  placeholder={component.ComponentLabel.replace(/&nbsp;/g, '')}
                  value={this.state.CreatedToDate ? this.state.CreatedToDate : null}
                  onChange={(event, value) => { this.calenderOnChange(event, value, this.state.dateFieldName2) }}
                 onBlur={this.onBlur}
                  editable={false}
                  autoComplete={autocomplete}
                  {...AccordiononError}
                />
              }
            </AccordiononErrorContextConsumer>
          </Grid>
          <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} className={`${component.AttributeStyleClass}`}>
            <Button name={'confirm'}
              color="primary"
              disabled={(this.state.dates && this.state.dates.length > 1 && validations.length === 0) ? false : true}
              className={(this.state.dates && this.state.dates.length > 1 && validations.length === 0) ? `nav-btn margin1 pull-down` : `nav-btn-disabled pull-down`}
              value={'confirm'}
              onClick={(e) => { this.triggerApi(e, component) }}
            >
              <span className="ui-button-text ui-c">{'confirm'}</span>
            </Button>
          </Grid>
        </>)}
      </>
    );
  }
}

const styles = {
  selectContainer: {
    display: "flex",
  },
  dropdown: {
    minWidth: "100%",
    alignSelf: "flex-end"
  },
  label: {
    width: "100%",
    position: "relative",
    marginBottom: "-20px"

  },
  marginTop:{
    marginTop:'5%'
  }
};

export default SelectCalender;
