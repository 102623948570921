import { constructAndInvokeMetadataCall } from "./MetaUtils";
import store from "../../../../store";
import appActions from "../../../../actions/index";
import { getNavigation } from "../../../../CommonAxios/ResourceAxios";
import { templateConstruct } from "./Navigation_Util";
import { Toast, successToast, triggerToast } from "./HelperUtils";



const { actionType } = appActions;

const PageHeaderDidMount = async(state,props,setPageMetadata)=>{
  try{
    let tobj = {}
    tobj = props.pagelevelValues
    const {objectId, objectVerId, subApplication} = props.pageInfo
     let response = await constructAndInvokeMetadataCall(
    { subappName: subApplication, objectId, objectVerId},
    { transactionPrimaryObject:tobj})

    setPageMetadata(response)
  }catch(e){
    console.log("Error in PageHeaderDidMount", e)
  }
}

const lapseSubmission_RateIndication=async(response,propsObj,stateObj,e)=>{
  try{
   if (response){
    propsObj.componentProps.functions.UpdateState("loading",true,true)
    await store.dispatch({type:actionType.SET_SUBMISSION_STATUS,payload:response.SubmissionStatus})
    propsObj.componentProps.functions.UpdateState("pagelevelValues",{...propsObj.pagelevelValues,...response},true);
    propsObj.componentProps.functions.UpdateState("transactionPrimaryObject" , {...response}, true)
    await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
    let navObj = await getNavigation(["ProductNavigation"],[propsObj.componentProps.productDetails.ProductVerNumber]);
      if (navObj.hasOwnProperty("productNavigation")) {
      let templatefromredux = propsObj.componentProps.templateInfo;
      let key = propsObj.componentProps.productDetails.LOBName ? `${propsObj.componentProps.productDetails.LOBName}_submission` : "template";
      key = navObj.productNavigation[key] ? key : "template"
      templateConstruct(navObj, ["template"], templatefromredux);
      console.log('()key 12', key)
      let prodnav = navObj.productNavigation[key];
      let pageNav = Object.assign({}, propsObj.componentProps.navigation);
      pageNav.pageNavigation.submission.sidebar[1].children = prodnav;
      store.dispatch({type: actionType.SET_NAVIGATION, payload: pageNav})
      store.dispatch({type: actionType.SET_PAGE_NAVIGATION, payload: prodnav})
      // propsObj.componentProps.setNavigation(pageNav);
      // propsObj.componentProps.setPageNavigation(prodnav);
    }
    store.dispatch({type: actionType.SET_PROCESS_STATUS, payload: response.ProcessStatus})
    store.dispatch({type: actionType.SET_CURRENT_PAGEINDEX, payload: 2})
    propsObj.componentProps.functions.UpdateState("loading",false,true)
    successToast("Submission Lapsed!!");
   }  else {
    triggerToast('Error in LapseSubmission',{POSITION:Toast.POSITION.TOP_CENTER})
   }
  }catch(e){
    console.log('Error in lapseSubmission_RateIndication',e);
  }
}

const DeclineSubmission_RateIndication=async(response,propsObj,stateObj,e)=>{
  try{
    if (response){
      await store.dispatch({type:actionType.SET_SUBMISSION_STATUS,payload:response.SubmissionStatus})
      propsObj.componentProps.functions.UpdateState("pagelevelValues",{...propsObj.pagelevelValues,...response},true);
      propsObj.componentProps.functions.UpdateState("transactionPrimaryObject" , {...response}, true)
      await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
      let navObj = await getNavigation(["ProductNavigation"],[propsObj.componentProps.productDetails.ProductVerNumber]);
      if (navObj.hasOwnProperty("productNavigation")) {
      let templatefromredux = propsObj.componentProps.templateInfo;
      let key = propsObj.componentProps.productDetails.LOBName ? `${propsObj.componentProps.productDetails.LOBName}_submission` : "template";
      key = navObj.productNavigation[key] ? key : "template"
      templateConstruct(navObj, ["template"], templatefromredux);
      console.log('()key 13', key)
      let prodnav = navObj.productNavigation[key];
      let pageNav = Object.assign({}, propsObj.componentProps.navigation);
      pageNav.pageNavigation.submission.sidebar[1].children = prodnav;
      store.dispatch({type: actionType.SET_NAVIGATION, payload: pageNav})
      store.dispatch({type: actionType.SET_PAGE_NAVIGATION, payload: prodnav})
      // propsObj.componentProps.setNavigation(pageNav);
      // propsObj.componentProps.setPageNavigation(prodnav);
    }
    store.dispatch({type: actionType.SET_PROCESS_STATUS, payload: response.ProcessStatus})
    store.dispatch({type: actionType.SET_CURRENT_PAGEINDEX, payload: propsObj.pageInfo.RedirectionIndex })

      successToast("Submission Declined!!");
      }  else {
        triggerToast('Error in DeclineSubmission',{POSITION:Toast.POSITION.TOP_CENTER})
      }

  }catch(e){
    console.log('Error in DeclineSubmission_RateIndication',e);
  }
}

const ExpandPanelRender = (props) => {
 const index = props.currentPageIndex !== undefined ? props.currentPageIndex : props.UIFlowReducer.currentPageIndex;
 return index > 1
}

 export const PageHeaderUtil = {
  ExpandPanelRender,
  PageHeader: {
    componentDidMount: PageHeaderDidMount,
    actionbuttons:{
      lapseSubmission : lapseSubmission_RateIndication,
      declineSubmission:DeclineSubmission_RateIndication
    }
  },
}
