import React from 'react';
import red from '@material-ui/core/colors/red';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import SelectRadio from '@material-ui/core/Radio';
import { commonSelectMethod } from './ButtonUtil';
import {  FormControlLabel, Grid, RadioGroup } from '@material-ui/core';

const red300 = red['500'];

const style = {
    left: 0,
    fontSize: '12px',
    color: red300,
    // position: 'absolute',
    marginTop: 0,
};

const styles = {
    label: {
        marginTop: '15px'
    },
    formControl: {
        minWidth: '100%'
    },
    radionButton: {
        color: '#0D1E37',
        fontSize: '12px'
    }
}


class SelectRadioValidator extends ValidatorComponent {

    onChange = (e) => {
        if(this.props.component.Action && Object.keys(this.props.component.Action).length > 0){
            let value = Object.assign({}, { [e.target.name]: e.target.value });
            commonSelectMethod(this.props.component, value, this.props)}
        else{
            let value = Object.assign({}, { [e.target.name]: e.target.value });
            this.props.component.OnChangeAction && this.props[this.props.component.OnChangeAction] ?this.props[this.props.component.OnChangeAction](this.props,value):this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
        }
    }

    render() {
        const { errorMessages, validators, requiredError, value, options, component, attributeValue, ...rest } = this.props;

        return (

            <div>
                <RadioGroup
                    dir="row"
                    className="custom-radio-group"
                    aria-label={component.ComponentLabel}
                    name={component.AttributeName}
                    value={value}

                    onChange={(e) => this.onChange(e)}
                >
                    <Grid container spacing={3}>
                        {
                            options.map((option, index) => {
                                return (
                                    <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
                                        <FormControlLabel className='small_label'  key={option.Sequence} value={option.Value} disabled={component.IsReadonly}
                                            control={<SelectRadio onClick={(e) => this.props.valueSelect(e)} style={styles.radionButton} />} label={option.DisplayValue} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </RadioGroup>

                {this.errorText()}
            </div>

        );

    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={style}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default SelectRadioValidator;
