import React, { Component, Fragment, cloneElement } from 'react';
import {TableRow, TableCell, Collapse, Box} from "@material-ui/core";
import { connect } from 'react-redux';
import RenderTemplate from '../../../../template/RenderTemplate';
import container from '../../utils/DIContainer';

class DataTableDetailV2 extends Component {

    render() {
        const dynamicRender = container.get('dynamicRender')
        let { isExpanded, ChildSubApplicationList,rowData,hierarchicalRendering,subAppInfo,parentProps, tableActions = {}, columnList, actionRequired, templateInfo} = this.props;
        let { childComponent = {} } = tableActions
        let { SubApplicationDetail } = subAppInfo,{ ApplicationName } = SubApplicationDetail;
        let colspanValue = 2, childAsTemplate = false, childTemplate = []

        colspanValue = actionRequired ? colspanValue : colspanValue -1;
        colspanValue = this.props.dragNeeded ? colspanValue + 1 : colspanValue;
        if(childComponent.hasOwnProperty('renderTemplate') && childComponent.hasOwnProperty('renderTemplateName') && templateInfo[childComponent.renderTemplateName]) {
            childAsTemplate = childComponent.renderTemplate;
            childTemplate = templateInfo[childComponent.renderTemplateName] || [];
        }
        const childrenList = React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
              return cloneElement(child, { ...this.props })
            }
            return child;
        });
        if(ChildSubApplicationList && ChildSubApplicationList.length > 0)
        {
            let temp = { pagelevelValues: {}, transactionPrimaryObject: {},[ApplicationName] :{} };
            Object.entries(rowData).forEach(([key, value], index) => {
                if(typeof value === 'object' && value !== null)
                {
                    temp["pagelevelValues"][key] = value;
                    temp["transactionPrimaryObject"][key] = value;
                }
                else
                {
                    temp[ApplicationName][key] = value;
                }


              });
            parentProps=Object.assign({},this.props.componentState,rowData,temp);
        }
        return (
            <Fragment>
                <TableRow>
                    <TableCell style={{ paddingBottom: '5px', paddingTop: '5px' }} colSpan={columnList.length+colspanValue}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                {
                                    childAsTemplate ? <RenderTemplate {...this.props} Template = {childTemplate}/>
                                    :
                                    (ChildSubApplicationList && ChildSubApplicationList.length > 0?
                                    dynamicRender(ChildSubApplicationList, this.props.componentProps, parentProps, this.props.functions,
                                    this.props.TableControls, this.props.summarySubApp, this.props.detailSummaryList,hierarchicalRendering) :
                                        childrenList)

                                }
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    templateInfo: state.navigationReducer.templateInfo,
});

export default connect(mapStateToProps) (DataTableDetailV2);
