import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { InputLabel } from "@mui/material";

class Email extends Component {
  componentDidMount = () => {
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
  };

  onChange = (e) => {

    let value = Object.assign({}, { [e.target.name]: e.target.value });
    this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);

  }
  render() {
    const { component, variant } = this.props;
    let attributeValue = this.props.attributeValue;
    let componentvalue = this.props.componentvalue;
    let isRequired = component.IsRequired === "Y" ? true : false;
    let isReadOnly = component.IsReadonly;
    let componentLabel = isRequired
      ? component.ComponentLabel + " *"
      : component.ComponentLabel;
    const validations = [];
    const errorMessages = [];
    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
      validations.push("IsEmpty");
      errorMessages.push(component.RequiredMessage);
    }
    validations.push("isEmail");
    errorMessages.push("Please enter a valid email address");
    if (component.ValidationRegex) {
      validations.push('matchRegexp:'+ component.ValidationRegex);
      errorMessages.push(component.ValidatorMessage);
      }

    let value = "";
    if (
      component.DefaultValue === "" &&
      component.AttributeValue === "" &&
      componentvalue !== undefined
    ) {
      // console.log("came in 1");
      value = componentvalue;
    }
    if (
      component.DefaultValue !== "" &&
      component.AttributeValue !== "" &&
      componentvalue !== undefined
    ) {
      // console.log("came in 2");
      value = componentvalue;
    }
    if (
      component.DefaultValue !== "" &&
      component.AttributeValue === "" &&
      componentvalue === undefined
    ) {
      // console.log("came in 3");
      value = component.DefaultValue;
    }
    if (
      component.DefaultValue !== "" &&
      component.AttributeValue !== "" &&
      componentvalue === undefined
    ) {
      // console.log("came in 4");
      value = "";
    }
    if (
      component.DefaultValue !== "" &&
      component.AttributeValue === "" &&
      componentvalue !== undefined
    ) {
      // console.log("came in 5");
      value = componentvalue;
    }
    
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
    const styleObject = component.AdjacentLabel === 'Y' ? {display : "flex"} :{}

    return (    
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} style={styleObject}>
        {component.AdjacentLabel === 'Y' ? 
            <>
              <Grid 
              item 
              xs={4} 
              sm={4} 
              md={4} 
              lg={4}  
              className={`${component.AttributeStyleClass}_Label`}
              >
                <InputLabel htmlFor={component.AttributeName} className="custom-label-down text-font adjacent_label">
                  {componentLabel}
                </InputLabel>
              </Grid>
              <Grid 
              item 
              xs={8} 
              sm={8} 
              md={8} 
              lg={8}  
              className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
              >
            <TextValidator
              name={component.AttributeName}
              id={component.AttributeName}
              placeholder={!this.props.isDynamicTable && component.ComponentLabel}
              value={value}
              defaultValue={value}
              className={`custom-input ${component.StyleClass} lineHeight`}
              onChange={(e) => this.onChange(e)}
              disabled={isReadOnly}
              autoComplete={autocomplete}
              validators={validations}
              errorMessages={errorMessages}
              variant={variant}
              fullWidth
            />
              </Grid>
            </>:
            <TextValidator
            name={component.AttributeName}
            placeholder={!this.props.isDynamicTable && component.ComponentLabel}
            label={
                !this.props.isDynamicTable && (
                  <Typography className="custom-label-down text-font small_label">
                    {componentLabel}
                  </Typography>
                )
            } 
            value={value}
            defaultValue={value}
            className={`custom-input ${component.StyleClass} lineHeight`}
            onChange={(e) => this.onChange(e)}
            disabled={isReadOnly}
            autoComplete={autocomplete}
            validators={validations}
            errorMessages={errorMessages}
            variant={variant}
            fullWidth
            />}
      </Grid>
    );
  }
}

export default Email;
