import dynamiccomponenthoc from '../views/runtime/corecomponents/DynamicComponentHOC';
import locationwrapper from '../views/runtime/sections/CustomComponents/Location/Wrapper';
import showhidehoc from '../views/runtime/sections/CustomComponents/Location/ShowHideHOC';
import fileimportexport from '../views/runtime/controls/FileImportExport';
import iteratorcomponent from '../views/runtime/sections/CustomComponents/IteratorComponent/IteratorComponent';
import collapsible from '../views/runtime/sections/CustomComponents/Collapsible/Collapsible';
import header from '../views/runtime/sections/CustomComponents/BaseTemplateComponents/Header';
import footer from '../views/runtime/sections/CustomComponents/BaseTemplateComponents/Footer';
import nonstickyfooter from '../containers/DefaultLayout/NonStickyFooter';
import sidebar from '../views/runtime/sections/CustomComponents/BaseTemplateComponents/Sidebar';
import main from '../views/runtime/sections/CustomComponents/BaseTemplateComponents/Main';
import content from '../views/runtime/sections/CustomComponents/BaseTemplateComponents/Content';
import logo from '../views/runtime/sections/CustomComponents/BaseTemplateComponents/Orchid_Header/Header_Logo';
import notificationpanel from '../views/runtime/sections/CustomComponents/BaseTemplateComponents/Orchid_Header/Header_Icon';
import mainheader from '../views/runtime/sections/CustomComponents/BaseTemplateComponents/Orchid_Header/MainHeader';
import submission from '../views/runtime/sections/CustomComponents/Submission/Submission';
import find from '../views/runtime/pages/Find';
import popup from '../views/runtime/controls/Popup/Popup';
import rightsidebar from '../views/runtime/sections/CustomComponents/BaseTemplateComponents/RightSideBar';
import childcomp from '../views/runtime/sections/CustomComponents/ChildComponent/childComponent';
import childcompv2 from '../views/runtime/sections/CustomComponents/ChildComponent/childCompV2';
import detailsummarylist from '../views/runtime/sections/CustomComponents/Location/DetailSummaryList';
import templaterendering from '../views/runtime/sections/CustomComponents/Location/TemplateRendering';
import dashboard from '../views/DashboardSSO/DashboardSSO';
import dynamictab from '../views/runtime/sections/CustomComponents/DynamicTab/DynamicTab';
import dynamicpageheader from '../views/runtime/controls/PageHeaderV2';
import expandpanel from '../views/runtime/sections/CustomComponents/ExpandPanel/ExpandPanelV4';
import expandpanelv2 from '../views/runtime/sections/CustomComponents/ExpandPanel/ExpandPanelV5';
import hoc from '../views/runtime/corecomponents/HOC';
import breadcrumb from '../views/runtime/sections/CustomComponents/Navigation/Navigation';
import expandablewrappercomponent from '../views/runtime/sections/CustomComponents/ExpandableWrapper/ExpandableWrapperComponent';
import popupwrapper from '../views/runtime/controls/popupwrapper';
import validationrules from '../views/runtime/controls/ValidationRules';
import container from '../views/runtime/utils/DIContainer';
const controls = {
    dynamiccomponenthoc,
    // additionalcoveragesummary,
    // locationschedule,
    locationwrapper,
    // compositetable,
    // cflocationmods,
    fileimportexport,
    showhidehoc,
    iteratorcomponent,
    collapsible,
    header,
    footer,
    nonstickyfooter,
    main,
    sidebar,
    content,
    logo,
    notificationpanel,
    mainheader,
    submission,
    find,
    // availablemarketwrapper,
    popup,
    rightsidebar,
    childcomp,
    childcompv2,
    detailsummarylist,
    templaterendering,
    dashboard,
    dynamictab,
    dynamicpageheader,
    hoc,
    expandpanel,
    breadcrumb,
    expandpanelv2,
    expandablewrappercomponent,
    popupwrapper,
    validationrules
}

export default controls;

container.register('templateControls',controls)
