import React from "react";
import { Autocomplete, MenuItem ,Typography } from "@mui/material";
import red from "@material-ui/core/colors/red";
import CheckIcon from "@mui/icons-material/Check";
import { TextValidator, ValidatorComponent } from 'react-material-ui-form-validator';

const red300 = red["500"];

const style = {
  left: 0,
  fontSize: "12px",
  color: red300
};

export default class MultiSelectValidator extends ValidatorComponent {
  render() {
    const { ComponentLabel, options, selectedValuesName, validators, errorMessages, value, component, onChange, variant, ...rest } = this.props;
    const IsReadonly = component.IsReadonly;
    const isError = (variant === "outlined" && !this.state.isValid) || false;
        return (
      <>
        <Autocomplete
          sx={{ m: 0, width: '100%', top: 'auto' }} 
          multiple
          id={component.AttributeName}
          className={`custom-autocomplete autoComplete_${component.StyleClass}`}
          options={options}
          value={options.filter(option => selectedValuesName && selectedValuesName.includes(option.Value))}
          onChange={onChange}
          getOptionLabel={(option) => option.DisplayValue}
          disableCloseOnSelect
          disabled={IsReadonly}
          renderInput={(params) => (
              <TextValidator
              {...params}
              variant={variant}
              className="custom-input"
              label={component.AdjacentLabel !=='Y' ? <Typography className="custom-label-down small_label text-font">{ComponentLabel}</Typography> :''}
              placeholder={selectedValuesName && selectedValuesName.length === 0 ? (ComponentLabel) : ''}
              error={isError}
              />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem
              {...props}
              value={option.Value}
              sx={{
                justifyContent: "space-between",
                backgroundColor: selected ? "#f0f0f0" : "transparent"
              }}
            >
              {option.DisplayValue}
              {selected ? <CheckIcon color="info" /> : null}
            </MenuItem>
          )}
        />
        {this.errorText()}
        </>
        );
    }
    errorText() {
        const { isValid } = this.state;
    
        if (isValid) {
          return null;
        }
        return <span className="small_error" style={style}>{this.getErrorMessage()}</span>;
      }
}