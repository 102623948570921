import React from 'react';
import Icons from '../../../../../IconLibrary';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize : 14,
        padding : '12px',
    },
}));

const BootstrapTooltip = (props) => {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}


const renderBtnIcon = (icon, color, toolTipText) => {
    const IconComponent = Icons[icon];
    return (
        <BootstrapTooltip title={toolTipText}>
            {IconComponent && <IconComponent className={`decode-btn-icon ${color}`} />}
        </BootstrapTooltip> 
    )
}

const getStatusType = (props) => {
    const { data, component } = props;
    switch (true) {
        case (data[`${component.AttributeName}IsValid`] === 'Yes' && data[`${component.AttributeName}IsOverride`] === 'No'):
            return 'Success';
        case (data[`${component.AttributeName}IsValid`] === 'Yes' && data[`${component.AttributeName}IsOverride`] === 'Yes'):
            return 'Override';
        case (data[`${component.AttributeName}IsOverride`] === 'Yes'):
            return 'Override';
        case (data[`${component.AttributeName}IsValid`] === 'No'):
            return 'Error';
        case (data[component.AttributeName] === 'Import') :
            return 'Flag';
        case (data[component.AttributeName] === 'UI' && component.SubApplicationName === 'SolartisVehicleStatusSummary') : 
            return 'SuccessVehicle';
        default: return null;
    }
}

/**
 * renderIcon function accepts two parameter and returns icon based on result
 * @param {props} props 
 * @param {callback function} callback 
 * @returns 
*/

export const renderIcon = (props, callback = false) => {
    const { data, component } = props;
    if(data) {
        const symbolType = getStatusType(props);
        const priorValue = symbolType ? data[`${component.AttributeName}_prior`] || null : null;
        switch (true) {
            case(symbolType === 'SuccessVehicle') :
                return renderBtnIcon('success', 'success', '');
            case (symbolType === 'Success'):
                return renderBtnIcon('success', 'success', 'Valid VIN');
            case (symbolType === 'Override'):
                return renderBtnIcon('change', 'warning', `${priorValue ? `Prior Value : ${priorValue}` : 'Valid VIN, User entered value(s) Overwritten'}`);
            case (symbolType === 'Error'):
                return renderBtnIcon('error', 'error', 'Invalid VIN');
            case (symbolType === 'Flag'):
                return renderBtnIcon('flag', 'error', 'Please review the details and click update');
            default: return null;
        }
    }
    else return null;
}
