import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { format } from "date-fns";
import { getDateWithoutOffset } from "../utils/Common/DateUtils";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
class StaticDate extends Component {
  componentDidMount = () => {
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
  };

  handleInternally = (event, value) => {
    // console.log('Handle internaly',event,value)
    let date1 = getDateWithoutOffset(value);
    let d1 = format(date1, 'MM/dd/yyyy');
    // console.log('d1--->',typeof d1,d1)
    //let d2 = format(new Date(date2), this.props.component.InputFormatPattern);
    // console.log("D1 Formatted",d1,date1);
    // console.log("D2 Formatted",d2,date2);
    this.props.handleDateChange(d1, 'DateofBirth');
    //console.log("From handleInternally",d1,d2);
  };

  render() {
    const { component} = this.props;
    /* let attributeValue =
      this.props.attributeValue === undefined ||
      this.props.attributeValue === ""
        ? component.DefaultValue
        : this.props.attributeValue; */
    let isRequired = component.IsRequired === "Y" ? true : false;
    const validations = [];
    const errorMessages = [];
    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
      validations.push("IsEmpty");
      errorMessages.push(component.RequiredMessage);
    }
    // console.log('From Static Text',componentvalue)
    let value = "";
    value = this.props.values[component.AttributeName];
    value = getDateWithoutOffset(value)
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";


    // console.log('Date Value-->',typeof value)
    return (
      <Grid item xs={6} sm={6} md={component.Size} lg={component.Size}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          variant="inline"
          orientation="landscape"
          margin="normal"
          id="date-picker-dialog"
          label="Date of Birth"
          name={component.AttributeName}
          format="MM/dd/yyyy"
          value={value}
          onChange={(event, value) => {
            this.handleInternally(event, value);
          }}
          autoComplete={autocomplete}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

      </Grid>
    </MuiPickersUtilsProvider>
      </Grid>
    );
  }
}
export default StaticDate;
