import React from 'react';
import { Grid  } from "@material-ui/core";


const Spacer=(props)=> {
        let { component, isDynamicTable } = props;
        return (
          <Grid
            className={component.StyleClass}
            item
            xs={12}
            sm={12}
            md={isDynamicTable ? 12 : component.Size}
            lg={isDynamicTable ? 12 : component.Size}
            key={component.AttributeName}
          >
              <div className={`${component.AttributeStyleClass} Spacer`} />
              </Grid>
        );
    }

export default Spacer