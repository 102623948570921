import React, { Component } from "react";
import { FormControl, Grid, InputLabel, MenuItem } from "@material-ui/core";
import SelectDropdown from "@material-ui/core/Select";

class StaticSelect extends Component {
  state = {
    value: "",
  };

  componentDidMount() {
    this.setState({
      value: this.props.component.AttributeValue,
    });
  }

  componentDidUpdate() {
    if (
      this.props.component.SubApplicationAttributeValueList.length === 2 &&
      this.props.component.SubApplicationAttributeValueList[0].DisplayValue ===
        "Select" &&
      this.props.attributeValue !==
        this.props.component.SubApplicationAttributeValueList[1].Value
    ) {
      // this.props.handleHiddenInputChange(
      //   this.props.component,
      //   this.props.isDynamicTable,
      //   this.props.datatableRowindex
      // );
    }
  }
  onChange = (e) => {
    // this.setState({
    //   value: e.target.value
    // })
    this.props.handleInputChangeV2(e,this.props.component);
  };

  render() {
    const { component, isDynamicTable, datatableRowindex } = this.props;
    const options = component.SubApplicationAttributeValueList;

    let value = "";

if (this.props.values[component.AttributeName]) {
      value = this.props.values[component.AttributeName];
    }
    else{
      value = component.AttributeValue;
    }

    let labelId = component.ComponentId + "_label";
    let labelClassName =
      component.IsRequired === "Y"
        ? "mandatory attributeLabel"
        : "attributeLabel";
        let autocomplete="off";
        let custom3=component.Custom3 && JSON.parse(component.Custom3);
        (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
         
    
    return (
      <Grid
        className={component.StyleClass}
        item
        xs={12}
        sm={12}
        md={isDynamicTable ? 12 : component.Size}
        lg={isDynamicTable ? 12 : component.Size}
        key={component.AttributeName}
      >
        <FormControl
          style={styles.dropdown}
          className={`custom-dropdown ${component.AttributeStyleClass}`}
        >
          {!isDynamicTable && (
            <InputLabel
              id={labelId}
              className={labelClassName}
              style={styles.label}
            >
              {component.ComponentLabel}
            </InputLabel>
          )}
          <SelectDropdown
            labelId={labelId}
            id={component.ComponentId}
            name={component.AttributeName}
            fullWidth
            value={value}
            onChange={(e) => this.onChange(e)}
            autoComplete={autocomplete}
          >
            {options.map((option) => {
              return (
                <MenuItem key={option.Sequence} value={option.Value}>
                  {option.DisplayValue}
                </MenuItem>
              );
            })}
          </SelectDropdown>
        </FormControl>
      </Grid>
    );
  }
}

const styles = {
  dropdown: {
    minWidth: "100%",
    marginTop: "16px",
  },
  label: {
    width: "150%",
  },
};

export default StaticSelect;
