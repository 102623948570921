import React from 'react';
import { Grid, Typography, TableRow, TableCell} from "@material-ui/core";

function NoRecords(props) {
    let {colSpanValue} = props;
    return (
        <TableRow>
        <TableCell colSpan={props.columnList.length + colSpanValue}>
        <Grid container spacing={3} justify="center" alignItems="center" className='norecord small_label'>
            <Typography variant="h6" style={{padding: '7px'}}>
                No Records To Display.
            </Typography>
        </Grid>
        </TableCell>
        </TableRow>
    )
}

export default NoRecords;
