import React, { useEffect, useState } from 'react';
import { MenuItem, FormControl, InputLabel } from "@material-ui/core";
import SelectDropdown from "@material-ui/core/Select";
import { commonActionSelectMethod } from './ButtonUtil';
import { dynamicConditionValidationV2 } from '../utils/Common/NoCodeUtils';

function TableActionSelect(props) {
    const [isReadOnly, setIsReadOnly] = useState(false);
    const { tableActions, functions, componentState } = props;
    const { actionSelect = {} } = tableActions;
    const { options, AttributeName, conditions = []} = actionSelect;
    const subapplicationName = props.subAppInfo && props.subAppInfo.subapplicationName;
    
    useEffect(() => {
        const dataList = componentState[`${subapplicationName.toLowerCase()}_dataList`] || [];
        if (dataList.length === 0) {
            setIsReadOnly(true);
        } else {
            const hasValidCondition = dataList.some((obj) => dynamicConditionValidationV2(obj, conditions));
            setIsReadOnly(!hasValidCondition);
        }
    }, [componentState[`${subapplicationName.toLowerCase()}_dataList`]]);

    const onChange = async (event) => {
        options.map(async (option) => {
            if (event.target.value === option.Value) {
            let booleanValue = option.action && Object.keys(option.action).length > 0
            functions.UpdateState(AttributeName, option.Value, booleanValue ? false : true);
            if (booleanValue) {
                commonActionSelectMethod(option.action, props, componentState, functions, AttributeName);
            }
        }
    });
    };

    return (
        <FormControl className='custom-action-select-form adjacent_label'>
            <InputLabel id="action-select-label">{actionSelect.label}</InputLabel>
            <SelectDropdown
                className='custom-action-select'
                labelId="label-action"
                id="action"
                value={''}
                onChange={onChange}
                label="action"
                disabled={isReadOnly}
            >
                {options && options.map((option) => (
                    <MenuItem key={option.Sequence} value={option.Value}>
                        {option.DisplayValue}
                    </MenuItem>
                ))}
            </SelectDropdown>
        </FormControl>
    );
}

export default TableActionSelect;
