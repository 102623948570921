import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Grid, Typography} from "@material-ui/core";
// import { getDateWithoutOffset ,changeSOLDateformat} from '../utils/Common/DateUtils'
import { isValid } from "date-fns";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

function MaskedText(props) {
    const labelRef = React.createRef();
    const [dateValue, setDateValue] = useState(null)
    const [showValue, setShowValue] = useState(false);
    const [error, setError] = useState(false)
    const [cursorPosition, setCursorPosition] = useState(0);

    useEffect(() => {

        setDateValue(outputDateFormat(componentvalue))
        ValidatorForm.addValidationRule("IsEmpty", (componentvalue) => {
            if (!componentvalue) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule("throwErr", () => { return false });
    }, [])

    const { component } = props;
    useEffect(() => {
        if(component.DefaultValue!==''){
            setDateValue(outputDateFormat(component.DefaultValue))
        }
       },[component])
    let componentvalue = props.componentvalue;

    let isRequired = component.IsRequired === "Y" ? true : false;
    let isReadOnly = component.IsReadonly;
    let labelId = component.ComponentId + '_label';
    let componentLabel = "";
    if (component.ComponentLabel) {
        componentLabel = isRequired ? component.ComponentLabel + " *" : component.ComponentLabel;
    }
    function removeCharFromString(inputString) {
        const regex = /[^a-zA-Z0-9\s]/g
        return inputString && inputString.replace(regex, '');
    }
    const validations = [];
    const errorMessages = [];
    if (isRequired) {
        validations.push("required");
        errorMessages.push(component.RequiredMessage);
    }
    let formattedDateValue = new Date(dateValue).toLocaleDateString("en-ZA");

    if ( dateValue && dateValue.length === 10 && isValid(new Date(component.MinValue)) && isValid(new Date(component.MaxValue))) {
        if(component.InputFormatPattern.toLowerCase()==='dd/mm/yyyy'){
            const [day, month, year] = dateValue.split('/')
            formattedDateValue  = `${month}/${day}/${year}`
        }
        if((new Date(component.MinValue) > new Date(formattedDateValue)) || (new Date(component.MaxValue) < new Date(formattedDateValue))){
            validations.push('throwErr')
            errorMessages.push(component.ValidatorMessage);
        }
        }
    if(dateValue && (dateValue.length!==10 ||  !isValid(new Date(formattedDateValue)))){
            validations.push('throwErr')
            errorMessages.push(component.Custom2);
        }
    if (component.ValidationRegex) {
        validations.push('matchRegexp:' + component.ValidationRegex);
        errorMessages.push(component.ValidatorMessage);
    }
    if(!componentvalue && componentvalue===undefined){
        componentvalue = component.DefaultValue ? component.DefaultValue : componentvalue;
    }

    const handleClickShowPassword = () => { showValue ? setShowValue(false) : setShowValue(true) };

    const handleMouseDownPassword = () => { setShowValue(true)
    console.log('handle Mouse down')
    };

    const handleMouseUpPassword = () => {  setShowValue(false)
    console.log('handle Mouse Up')
    };


    componentvalue = removeCharFromString(componentvalue)
    const outputDateFormat = (num) => {
        if(component.OutputFormatPattern.toLowerCase()==='yyyy-mm-dd' && component.InputFormatPattern.toLowerCase()==='mm/dd/yyyy'){
            num = removeCharFromString(num)
            let data = '', day = '', month = '', year = '';
            if (num) {
                year = num && num.slice(0, 4);
                month = num && num.slice(4, 6);
                day = num && num.slice(6,8);
                return data = `${month}/${day}/${year}`
            }
            return ''
        }
        else{
            return getDateFormat(num)
        }

    }
    const getDateFormat = (num) => {
        num = removeCharFromString(num)
        let data = '', day = '', month = '', year = '';
        if (num) {
            day = num && num.slice(0, 2);
            month = num && num.slice(2, 4);
            year = num && num.slice(4, num.length);
            if (day.length <= 2) {
                data = num
            }
            if (month.length > 0) {
                data = `${day}/${month}`
            }
            if (month.length > 2 || year.length>0) {
                data = `${day}/${month}/${year}`
            }
            return data
        }
        return ''
    }

    const onChange = (e) => {
        let validInput = removeCharFromString(e.target.value)
        if (validInput.length>8)  {
            return
        }
        let data = getDateFormat(validInput)
        data.length > dateValue.length ? setCursorPosition(e.target.selectionStart + 1) :setCursorPosition(e.target.selectionStart);
        if (data.length === 10) {
            let formattedDatevalue='',value=''
            if(component.OutputFormatPattern.toLowerCase()==='mm/dd/yyyy'&& component.InputFormatPattern.toLowerCase()==='dd/mm/yyyy' ) {
                const [day, month, year] = data.split('/')
                formattedDatevalue  = `${month}/${day}/${year}`
                 value = Object.assign({}, { [e.target.name]: formattedDatevalue })
            }
            if(component.OutputFormatPattern.toLowerCase()==='yyyy-mm-dd'&& component.InputFormatPattern.toLowerCase()==='mm/dd/yyyy' ) {
                let requiredFormat = data.replace(/\//g, '-')
                const [month, day, year] = requiredFormat.split('-')
                formattedDatevalue  = `${year}-${month}-${day}`
                 value = Object.assign({}, { [e.target.name]: formattedDatevalue })
            }
            else{
                 value = Object.assign({}, { [e.target.name]: data })
            }
            props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);

        }
        else {
            let value = Object.assign({}, { [e.target.name]: data })
            props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
        }
        setDateValue(data)
        isValidDate(data) ? setError(false) : setError(true)
    }
    const getValidDate = (date, day, month, year) => {
        return (
            date.getFullYear() === parseInt(year, 10) &&
            date.getMonth() + 1 === parseInt(month, 10) &&
            date.getDate() === parseInt(day, 10)
        );
    }
    const isValidDate = (dateString) => {
        if (component.InputFormatPattern.toLowerCase() === 'dd/mm/yyyy') {
            const [day, month, year] = dateString.split('/')
            return getValidDate(new Date(year, month - 1, day), day, month, year)

        }
        if (component.InputFormatPattern.toLowerCase() === 'mm/dd/yyyy') {
            const [month, day, year] = dateString.split('/')
            return getValidDate(new Date(year, month - 1, day), day, month, year)
        }
        else {
            return false;
        }

    };
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    if (error && componentvalue) {
        validations.push('throwErr')
        errorMessages.push(component.ValidatorMessage)
    }
    return (
        <>
            <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} className={component.AttributeStyleClass}>

                <TextValidator
                    name={component.AttributeName}
                    placeholder={component.InputFormatPattern.toUpperCase()}
                    label={
                        !props.isDynamicTable && (
                            <Typography className="custom-label-down text-font" style={styles.label} ref={labelRef}>
                                {componentLabel}
                            </Typography>
                        )
                    }
                    value={showValue ? dateValue : componentvalue}
                    className={`custom-input ${component.StyleClass} lineHeight ${showValue ? "" : "text-security-disc"} `}
                    onChange={component.IsReadonly ? null : (e) => onChange(e)}
                    disabled={isReadOnly}
                    validators={validations}
                    under
                    // inputRef={(input) => {
                    //     if (input && cursorPosition !== null) {
                    //       input.setSelectionRange(cursorPosition, cursorPosition); // Set cursor position
                    //     }
                    // }}
                    onFocus={handleMouseDownPassword}
                    onBlur={handleMouseUpPassword}
                    errorMessages={errorMessages}
                    autoComplete={autocomplete}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick= {handleClickShowPassword}
                                    // {component.IsReadonly ? null : handleClickShowPassword}
                                >
                                    {showValue ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
        </>
    )
};
const styles = { label: { width: '150%' } };

export default MaskedText;
