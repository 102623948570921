import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { CardContent, Grid } from "@material-ui/core";
import { ValidatorForm } from 'react-material-ui-form-validator';
import dynamicRenderV2 from '../sections/CustomComponents/dynamicRenderV2';
import { updateValueWithSubapplicationName, handleDateChange, handlePersistValue,basePageActions  } from '../utils/Common/CommonUtils';
import { handleInputChangeV2 as handleInputChangeV2_Util,handleHiddenInputChange, addRow,deleteRow, onExpand, onChildSave, showDialog,handleAutoCompletechange } from '../utils/Common/InputUtils.js';
import { handleRowLevelInputChange } from '../utils/Common/DynamicHOC_Utils.js';
import Button from '../controls/Button';
import { commonButtonMethod,onTableLazyLoadData,commonRowMethod } from '../controls/ButtonUtil';
import PageLoader from '../controls/PageLoader';
import RenderTemplate from "../../../template/RenderTemplate";
import { withRouter } from 'react-router-dom';
import { constructEncodedString, decodeString, pageLoadActions } from '../utils/Common/NoCodeUtils.js';
import store from "../../../store";
import appActions from "../../../actions";
import * as authaction from '../../../actions/authActions'
import ValidationMessages from '../controls/Validation/ValidationMessages.js';

const { actionType } = appActions;

class HOC extends Component {
    constructor(props){
        super(props);
        this.state = {
            pagelevelValues: {},
            transactionPrimaryObject: {},
            pageMetadata: {},
            pageSubAppInfo: {},
            currentPageInfo:{},
            loading: false
        }
        this.hocForm = React.createRef();
    }

    async componentDidMount() {
        let { pageInfo = {} } = this.props;
        let { pageOnLoadActions = [],basePage=false } = pageInfo;
        if(basePage && pageInfo.resetFlow===true)
        store.dispatch(authaction.resetsubmission());
        if(basePage){
            let val=window.location.hash.split('/'),pageData={};
            if(val.length > 1 && val[2]){
                let newVal=val[2];
                pageData = decodeString(newVal)
            }
            store.dispatch({ type: actionType.SET_UI_VALUES, payload: {pageData:{...pageData}}})
        }
        let routeName = this.props.location && this.props.location.pathname.slice(1).split('/')[0];
        let values = await pageLoadActions(pageOnLoadActions, this.props, this.state, this.functions);
        if(Object.keys(values).length > 0 ) this.updateValues(values);
        if(basePage){
            let basePagevalues =await basePageActions(this.props,routeName);
            if(Object.keys(basePagevalues).length > 0 ) this.updateValues(basePagevalues);
        }
    }

    async componentDidUpdate(prevProps, prevState){
        let { pageInfo = {}, UIFlowReducer: {  pageName } } = this.props;
        let { basePage=false,pageOnLoadActions=[] } = pageInfo;
        let routeName = this.props.location && this.props.location.pathname.slice(1).split('/')[0];
        if(basePage && this.props.pageInfo.pageName && this.props.UIFlowReducer[`${this.props.pageInfo.pageName}_updatePageName`]){    
            if(this.props.pageInfo.pageName && this.props.UIFlowReducer[`${this.props.pageInfo.pageName}_updatePageName`])
            await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {[`${this.props.pageInfo.pageName}_updatePageName`]: false} })              
            let columnList={}
            Object.keys(this.state).map(key=>{
                if(key.endsWith("_columnList")){
                    columnList[key]=[]
                }
            })
            this.setState({
                currentPageInfo:{},...columnList
            });
            await this.componentDidMount()
        }
        if(basePage && routeName && prevProps.location.pathname.slice(1).split('/')[0] !== routeName){
            await this.componentDidMount()
        }else
        {
        if((basePage && prevProps.UIFlowReducer.pageName !== pageName && pageName !== '') || (basePage && prevProps.UIFlowReducer.pageData && this.props.UIFlowReducer.pageData && prevProps.UIFlowReducer.pageData.pageName !== this.props.UIFlowReducer.pageData.pageName) ){
            let values=await basePageActions(this.props,routeName)
            if(Object.keys(values).length > 0 ) this.updateValues(values);
        }
        if(!basePage && this.props.pageInfo.pageName && this.props.UIFlowReducer[`${this.props.pageInfo.pageName}_updatePageName`]){
            // need to check where update scenario's without metadata calls
            let columnList={}
            Object.keys(this.state).map(key=>{
                if(key.endsWith("_columnList")){
                    columnList[key]=[]
                }
            })
            let values = await pageLoadActions(pageOnLoadActions, this.props, this.state, this.functions);
            if(Object.keys(values).length > 0 ) this.updateValues(values);
            if(Object.keys(columnList).length > 0 ) this.updateValues(columnList);
        }
    }
    }

    // state update method
    updateValues = (params) => {
        this.setState({ ...this.state, ...params });
    }

    updateState = (statename, value, rerender) => {
        if (rerender) this.setState({ [statename]: value });
        else this.state[statename] = value;
    };

    handleLoader = (params) => {
        this.setState({ loading: params });
    }

    //form submit method
    handleSubmit(e) {
        e.preventDefault();
        let dynamicButton = false;
        if(e.target.somevalue) dynamicButton = true;
        if(dynamicButton)
        {
            let { stateObj, component, functions, props } = e.target.somevalue
            commonButtonMethod(component, {...props}, stateObj, functions, e)
        }
    }

    //form error method
    executeScroll() {
        ReactDOM.findDOMNode(this.hocForm.current.errors[0]).scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
        if (false || !!document.documentMode) {
        window.scrollBy(0, -120);
        }
    }

    // input handlers
    handleInputChangeV2 = async (value, component, isDynamicTable, datatableRowindex, props) => {
        await handleInputChangeV2_Util(value, component, this.props, this.state, this.functions, this.state.pageSubAppInfo,{},true ,props );
    }

    updateValueWithSubapplicationName = (value, component) => {
        this.setState(prev=>{
            let values = updateValueWithSubapplicationName(value, component, prev);
            return {...prev,...values}
        })
    }

    handleDateChange = (event, value, attributeName, component) => {
        let values = handleDateChange(event, value, attributeName, component, this.state);
        this.updateValues(values)
    }

    handleHiddenInputChange = (component) =>{
        let values = handleHiddenInputChange(component, this.state);
        this.updateValues(values);
    }

    saveTableData = (
        tablename,
        attributeName,
        attributeValue,
        datatableRowindex
      ) => {
        this.setState((prevState) => {
          let tableData = prevState[tablename];

          tableData = tableData.map((el) => {
            if (el === datatableRowindex) {
              el[attributeName] = attributeValue;
            }
            return el;
          });
          return { [tablename]: tableData };
        });
      };

    handleRTEChange = (value, component) => {
        let attributeName = component.AttributeName
        let selectedValue = value.toString('html')
        let attributeValue = selectedValue
        this.setState(
            (prevState) => {
                let subappName = component.SubApplicationName;
                let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
                let transactionPrimaryObject = JSON.parse(
                    JSON.stringify(prevState.transactionPrimaryObject))
                if (!pagelevelValues[subappName]) {
                    pagelevelValues[subappName] = {};
                }
                pagelevelValues[subappName][attributeName] = attributeValue;

                if (!transactionPrimaryObject[subappName]) {
                    transactionPrimaryObject[subappName] = {};
                }
                transactionPrimaryObject[subappName][attributeName] = attributeValue;
                return {
                    pagelevelValues,
                    transactionPrimaryObject,
                };
            }
        )
    }

    handlePersistValue = (value, component) => {
        handlePersistValue(value, component, this.state, this.updateState);
    }

    handleAutoCompletechange = async (value,tip, component) => {
       await handleAutoCompletechange(value, tip, component,this.state,this.state.pageSubAppInfo,this.functions)

    }

    functions = {
        updateStateValues: this.updateValues,
        updateValues:this.updateValues,
        handleInputChangeV2: this.handleInputChangeV2,
        updateValueWithSubplicationName: this.updateValueWithSubapplicationName,
        handleDateChange: this.handleDateChange,
        handleHiddenInputChange: this.handleHiddenInputChange,
        handleRTEChange: this.handleRTEChange,
        handlePersistValue: this.handlePersistValue,
        UpdateState: this.updateState,
        handleLoader: this.handleLoader,
        handleAutoCompletechange:this.handleAutoCompletechange,
        saveTableData: this.saveTableData
    }

    dataTableFunctions = {
        "tableUtils": {
            "AddRow": addRow,
            "onExpand": onExpand,
            "onChildSave": onChildSave,
            "EditRow": "",
            "OnDetailEdit": "",
            "redirectTo": ""
        },
        "TableOptions": {
            "ChildrenSubApplicationName": null,
            "ChildrenComponent": null,
            "AddButtonNeeded": false,
            "SortingNeeded": false,
            "FilteringNeeded": false,
            "FilterIconNeeded": false,
            "rowsPerPageOptions": [5, 10, 25],
            "RowsPerPage": 5,
            "ActionName": "Action",
            "ActionListOptions": []
        },
        tableFunctions: {
            addRow: addRow,
            AddRow: addRow,
            deleteRow: deleteRow,
            onExpand: onExpand,
            onChildSave: onChildSave,
            onTableLazyLoadData: onTableLazyLoadData,
            showDialog:showDialog,
            EditRow:handleRowLevelInputChange,
            commonRowMethod
        }
    }

    render() {
        let subAppList = [];
        let { submitButton = {} } = this.props.pageInfo
        let { topMarginNeeded = true } = this.props.pageInfo;
        let isChildNeeded = this.props.pageInfo.hasOwnProperty("isChildNeeded")
        ? this.props.pageInfo.isChildNeeded
        : false;
         let hasChildren =
        this.props.pageInfo.hasOwnProperty("children") &&
        this.props.pageInfo.children != null &&
        this.props.pageInfo.children.length > 0
        ? true
        : false;
        if (this.state.pageMetadata && this.state.pageMetadata.ApplicationDetail) {
            subAppList = this.state.pageMetadata.ApplicationDetail && this.state.pageMetadata.ApplicationDetail.SubApplicationDetailList;
        }
        let buttonProps = {
            componentProps: this.props,
            componentState: this.state,
            refs: this.hocForm,
            component: { ...submitButton, ComponentType: "submit", AttributeName : "HOCSave",
                ComponentLabel: submitButton.buttonName, Action: submitButton.Action }
        }
        let { currentPageInfo = {} } = this.state;
        let { basePage = false } = this.props.pageInfo;
        return (
            <>{
                basePage && Object.keys(currentPageInfo).length > 0 ? (<RenderTemplate {...this.props} Template={currentPageInfo.template} key={ `${currentPageInfo.pageName} `} />):
                (
                <div className = {topMarginNeeded ? 'margin-top-5' : ''}>
                <ValidatorForm
                    id="child"
                    onSubmit={e => { this.handleSubmit(e) }}
                    onError={e => this.executeScroll()}
                    ref={this.hocForm}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CardContent className="custom-indentation">
                            <Grid container spacing={3}>
                                {
                                    dynamicRenderV2(subAppList, this.props, this.state, this.functions, this.dataTableFunctions, this.state.summarySubApp, this.state.detailSummaryList, this.state.hierarchicalRendering, this.hocForm, this.state.staticReadonlyRequired)
                                }
                            </Grid>
                        </CardContent>
                    </Grid>
                    {
                        submitButton.isButtonNeeded &&
                        <Grid container className = "mt-1-5" justify="flex-end">
                            <Button {...buttonProps} />
                        </Grid>
                    }
                    {isChildNeeded &&
                    hasChildren &&
                    this.props.children}
                </ValidatorForm>
        </div>)
            }
            {
                    this.state.loading && <PageLoader />
            }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    navigationReducer: state.navigationReducer,
    UIFlowReducer: state.UIFlowReducer
  });

export default withRouter(connect(mapStateToProps)(HOC));
