import React, { useState, useEffect, useRef } from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from 'axios';
import { commonAutoCompleteMethod } from './ButtonUtil';
import { dynamicObjectConstruction } from '../utils/Common/NoCodeUtils';

const AutoCompleteV4 = (props) => {

    // props
    const { component, componentvalue, pagelevelValues, lobUtil } = props;
    const { LookupInstanceName, OnClickAction = null } = component;
    const [value, setValue] = useState(componentvalue ? componentvalue : '');
    const [inputValue, setInputValue] = useState('');
    const [defaultValue, setDefaultValue] = useState({});
    const [attributeInfo, setAttributeInfo] = useState({});
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([] || {});
    const [showlist, setShowlist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedvalue, setselectedvalue] = useState(null);
    const showSearchIcon = !componentvalue ? true : false;
    const prevProps = useRef(props)

    // useEffect for setSearchQuery
    useEffect(() => {
        let timerID = null;
        let timer=800;
        if(component.Action && component.Action.timeDelay && component.Action.timeDelay!== '' && !isNaN(Number(component.Action.timeDelay)))
        timer=Number(component.Action.timeDelay) * 1000;
        if(open && inputValue.length === 0) {
            setSearchQuery(inputValue);
        }
        if(open && inputValue.length > 0) {
            timerID = setTimeout(() => {
                setSearchQuery(inputValue);
            }, timer)
        }

        return () => {
            if(timerID) clearTimeout(timerID);
        }

    }, [open, inputValue])


    // useEffect for fetch data
    useEffect(() => {
        let timerID = null;
        let axiosCancelToken = axios.CancelToken.source();
        let timer=800;
        if(component.Action && component.Action.timeDelay && component.Action.timeDelay!== '' && !isNaN(Number(component.Action.timeDelay)))
        timer=Number(component.Action.timeDelay) * 1000;
        const fetchData = async () => {
            if (!open) {
                setLoading(false);
                setOptions([]);
            }
            if(open) {
                timerID = setTimeout(() => {
                    invokeAutocomplete(axiosCancelToken)
                }, timer)
            }
        }
        fetchData();

        return () => {
            if (axiosCancelToken) axiosCancelToken.cancel();
            if(timerID) clearTimeout(timerID);
         }
    }, [searchQuery, open])


    useEffect(() => {
        if (props.componentvalue) {
            setValue({ [LookupInstanceName]: props.componentvalue });
        }
        else {
            setValue('');
        }
    }, [props.componentvalue])

    useEffect(() => {
        if(props.component.DefaultValue !== "" &&  props.componentvalue === undefined  &&  props.updateValueWithSubplicationName)
           props.updateValueWithSubplicationName({ [ props.component.AttributeName]:  props.component.DefaultValue },  props.component);
      }, [props.updateValueWithSubplicationName]);

      useEffect(() => {
        if(props.component.DefaultValue !== prevProps.current.component.DefaultValue && props.component.DefaultValue !== "" &&  props.componentvalue === undefined  &&  props.updateValueWithSubplicationName){
            props.updateValueWithSubplicationName({ [ props.component.AttributeName]:  props.component.DefaultValue },  props.component);
        }
        prevProps.current = props
      }, [props.component.DefaultValue]);

    const invokeAutocomplete = async (axiosCancelToken) => {
        try {
            setLoading(true);
            if(Object.keys(component.Action).length > 0){
                let {Options:response=[]} = await getList(props,searchQuery,axiosCancelToken);
                setLoading(false)
                if (Array.isArray(response) && response.length > 0) {
                    setOptions(response);
                }
                else if (typeof response === 'object' && Object.keys(response).length > 0) {
                    setOptions(response[component.ResponseKey]);
                }
                else {
                    setOptions(undefined);
                }
            }
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            if (err === 'Cancelled') {
                return;
            }
            console.log(err);
        }
    }

    // handleOptionSelect
    const handleOnChange = async (e, data) => {
       let newObj = {};
        if (data) {
            if(component.Action && component.Action.responseKeyMapping && component.Action.responseKeyMapping.length > 0){
                // to save only the required keys from the response.
                newObj = await dynamicObjectConstruction(component.Action.responseKeyMapping,data,{},'response')
            }
            else{
                let displayValue = data.LookupDisplayValue ? data.LookupDisplayValue : data[component.LookupInstanceName] ?  data[component.LookupInstanceName]: data.DisplayName
                newObj = {
                    ...data,
                    [component.AttributeName]: displayValue,
            }
        }
        }
        else
        {
            let object={}
        if(component.ItemTipParams){
            let items = component.ItemTipParams.split('~')
            items.map(item=>{
                object[item] = ''
            })
        }
        newObj = {
            [component.AttributeName]: '',
            ...object
        }}
        setValue(data);
        if(component.Action && (component.Action.onChange && Object.keys(component.Action.onChange).length > 0) && data){
            await commonAutoCompleteMethod({...props.component, Action: {...component.Action.onChange,handlerNeeded:true}},newObj, props);
        }
        else
       props.handleAutoCompletechange(newObj, component.ItemTipParams, component);
    }

    const getList = async(props,searchQuery,axiosCancelToken) => {
        let response = {};
        if(component.Action && (component.Action.onLoad && Object.keys(component.Action.onLoad).length > 0)){
            response = await commonAutoCompleteMethod({...component, Action: component.Action.onLoad},{}, {...props,axiosCancelToken},searchQuery);
        }
        return response ? response : {};
    }

    ValidatorForm.addValidationRule('IsEmpty', (value) => {
        if (!value) {
            return false;
        }
        return true;
    });
    let variant = props.variant
    let isRequired = component.IsRequired === "Y" ? true : false;
    const validations = [];
    const errorMessages = [];
    if (isRequired) {
        validations.push('required');
        errorMessages.push(component.RequiredMessage);
        validations.push('IsEmpty');
        errorMessages.push(component.RequiredMessage);
    }
    let styleObject = {};
    if(component.AdjacentLabel === "Y")
      styleObject = {display:'flex'};
    return (
            <Grid style={{...styles.mainGrid,...styleObject}} item xs={12} md={component.Size? component.Size :7} lg={component.Size? component.Size :7} sm={12} key={component.AttributeName} >
                {
                    component.AdjacentLabel === "Y" ?
                    <>
                        <Grid style={styles.mainGrid} item xs={4} md={component.Size? 4 :7} lg={component.Size? 4 :7} sm={4} key={component.AttributeName}>
                            <InputLabel htmlFor={component.AttributeName} className="custom-label-down adjacent_label">{component.IsRequired === 'Y' ? `${component.ComponentLabel} *` : component.ComponentLabel}</InputLabel>
                        </Grid>
                        <Grid style={styles.mainGrid} item xs={8} md={component.Size? 8 :7} lg={component.Size? 8 :7} sm={8} key={component.AttributeName} className='adjacent_TextValidator_Grid' >
                            <Autocomplete
                            style={{ display: "inline-flex", width: "100%" }}
                            freeSolo
                            className={`custom-autocomplete  ${component.StyleClass} lineHeight`}
                            disabled={component.IsReadonly}
                            id={component.AttributeName}
                            value={value}
                            options={options ? options : []}
                            onOpen={() => setOpen(true)}
                            onFocus={()=> setOpen(true)}
                            onClose={() => setOpen(false)}
                            inputValue={inputValue}
                            onChange={(e, newValue) => handleOnChange(e, newValue)}
                            onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
                            openOnFocus={true}
                            getOptionLabel={(option) => {
                                if(!option[LookupInstanceName]) return '';
                                return option[LookupInstanceName]
                            }}
                            loading={loading}
                            clearOnBlur={false}
                            renderInput={(params) =>
                                <TextValidator {...params}
                                    placeholder={component.ComponentLabel}
                                    value={inputValue}
                                    className="custom-input"
                                    name={component.AttributeName}
                                    margin="normal"
                                    validators={validations}
                                    errorMessages={errorMessages}
                                    variant={variant}
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {showSearchIcon && <IconButton> <SearchIcon /> </IconButton>}
                                            </React.Fragment>
                                        )
                                    }}
                                />}
                            />
                        </Grid>
                    </> 
                    : 
                    <Autocomplete
                        style={{ display: "inline-flex", width: "100%" }}
                        freeSolo
                        className={`custom-autocomplete small_options ${component.StyleClass} lineHeight`}
                        disabled={component.IsReadonly}
                        id={component.AttributeName}
                        value={value}
                        options={options ? options : []}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        inputValue={inputValue}
                        onChange={(e, newValue) => handleOnChange(e, newValue)}
                        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
                        getOptionLabel={(option) => {
                            if(!option[LookupInstanceName]) return '';
                            return option[LookupInstanceName]
                        }}
                        loading={loading}
                        clearOnBlur={true}
                        renderInput={(params) =>
                            <TextValidator {...params}
                                placeholder={component.ComponentLabel}
                                value={inputValue}
                                className="custom-input"
                                name={component.AttributeName}
                                label={<Typography className="custom-label-down small_label text-font">{component.IsRequired === 'Y' ? `${component.ComponentLabel} *` : component.ComponentLabel}</Typography>}
                                margin="normal"
                                validators={validations}
                                variant={variant}
                                errorMessages={errorMessages}
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {showSearchIcon && <IconButton> <SearchIcon /> </IconButton>}
                                        </React.Fragment>
                                    )
                                }}
                            />}
                    />
                }
                {/* {
                    value && value.DisplayValue !== "" && component.ItemTipParams &&
                    <IconButton className="custom-svg-icon InfoiconIEOverride" aria-label="add an alarm" style={{ transform: "rotate(180deg)", color: '#20a8d8', fontSize: '1rem !important' }} onBlur={() => { setShowlist(false) }} onClick={() => { setShowlist(!showlist); InfoList(); }} >
                        <ErrorIcon />
                    </IconButton>
                } */}
                {
                    showlist ?
                        <Card className="autocomplete_info_box">
                            <CloseIcon style={{ float: 'right', paddingRight: '5px', paddingTop: '5px', fontSize: '20px' }} onClick={() => { setShowlist(false) }} />
                            <CardContent style={{padding:'16px'}}>
                                {
                                    selectedvalue ?
                                        Object.keys(selectedvalue).map((key, index) => {
                                            return (
                                                <div style={{ marginBottom: '5px' }} key={index}>
                                                    <p style={{ fontWeight: 'bold', marginBottom: '0px' }}>{key}</p>
                                                    <p style={{ marginBottom: '0px' }}>{selectedvalue[key]}</p>
                                                </div>
                                            )
                                        })
                                        : null
                                }
                            </CardContent>
                        </Card>
                        :
                        null
                }
        </Grid>
    )

}

const styles = {
    mainGrid : {
        position : 'relative'
    }
}

// const mapStateToProps = state => ({
//     UIFlowReducer: state.UIFlowReducer,  // Since component is moved to Action no reducer Dependency needed...
// });

// const mapDispatchToProps = dispatch => {
//     return {
//         setTransactionRequest: request => dispatch(setTransactionRequest(request))
//     }
// }

export default AutoCompleteV4;
