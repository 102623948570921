import React, { Component, Fragment, lazy, Suspense } from 'react';
import { connect } from "react-redux";
import { Grid,Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {invokeCopyVehicle}from '../../../runtime/sections/CustomComponents/VehicleSchedule/VehicleUtility.js';
import { ValidatorForm } from "react-material-ui-form-validator";
import PageLoader from "../../../runtime/controls/PageLoader";
const PopupHeader = lazy(() => import('./PopupHeader'));
const PopupContent = lazy(() => import('./PopupContent'));
const PopupStaticContent = lazy(() => import('./PopupStaticContent'));
const PopupFooter = lazy(() => import('./PopupFooter'));



class Popup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pagelevelValues: {},
            transactionPrimaryObject: {},
            values: this.props.values,
            utilFunctions: null,
            showLoader: false,
            loading: false
        }
        this.BulkCopyForm = React.createRef();
    }

    resetState = async()=>{  
        this.setState({
            pagelevelValues: {},
            transactionPrimaryObject: {}
        })                
    }

    async componentDidMount() {
        await this.invokeUtilFile();
        if(this.props.bulkCopy!==true)
        {this.updatePopupContent();}
    }

    invokeUtilFile = async () => {
        const { popupDetail } = this.props.pageInfo;
        const { utilFilePath } = popupDetail[this.props.popupName];
        try {
            const functions = await import(`${utilFilePath}`);
            this.setState((prevState) => {
                let subappNameList = this.props.subApplication
                let utilFunctions = JSON.parse(JSON.stringify(prevState.utilFunctions));
                utilFunctions = functions.default;
                return { utilFunctions,subappNameList };
            });
        }
        catch (err) {
            console.log("Error invoking file");
        }
    }

    updatePopupContent = () => {
        const { popupDetail } = this.props.pageInfo;
        const { popupContent } = popupDetail[this.props.popupName];
        if(popupContent && popupContent.tableOptions) {
            const { tableOptions } = popupContent;
            if(tableOptions.ActionListOptions.length > 0) {
                for(let i = 0; i < tableOptions.ActionListOptions.length; i++) {
                    const { OnOptionClick } = tableOptions.ActionListOptions[i];
                    tableOptions.ActionListOptions[i].OnOptionClick = this[OnOptionClick] || null;
                }
            }
        }
    }
    handleClosePopup=()=>{
        let {UpdateState}=this.props.parentState
        UpdateState(`${this.props.name}__popupStatus`,false,true)
        this.resetState();
    }
    handleInputChangeV2 = async(value, component, isDynamicTable, datatableRowindex,propsObj) => {
        const { utilFunctions } = this.state;
        if(utilFunctions) {
            let statevalues = await utilFunctions.handleInputChangeV2(value,component,this.props,this.state, isDynamicTable, datatableRowindex,propsObj);
            this.updateValues(statevalues);
        }
    }

    updateValueWithSubplicationName = (updatedValue, component, state) => {
        let { SubApplicationName } = component;
        let pagelevelValues = Object.assign({}, this.state.pagelevelValues);
        if (!pagelevelValues[SubApplicationName]) pagelevelValues[SubApplicationName] = {};
        pagelevelValues[SubApplicationName] = Object.assign({}, this.state.pagelevelValues[SubApplicationName], updatedValue);
        return pagelevelValues;
    }

    updateValues = (obj) =>{
        this.setState({
            ...this.state,
            ...obj
        })
    }

    onCopyVehicle = async() => {
        this.handleLoader();
        let vehicleList = await invokeCopyVehicle(this.props, this.props.currentRowInfo,this.state);
        this.props.updateDataTable( vehicleList, false );
        this.handleLoader();
    }


    handleLoader = (params) => {
        this.setState({showLoader: params});
    }

    UpdateState = (statename, value, rerender) => {
        if (rerender) {
            this.setState({
                [statename]: value
            });
        } else {
            this.state[statename] = value;
        }
    }


    render() {

        const LOB = this.props.productDetails["LineOfBusiness"] || null;
        const { popupStatus, popupName } = this.props;
        const { popupDetail } = this.props.pageInfo;
        const popupInfo = popupDetail[popupName];
        const { popupHeader, popupContent, popupFooter } = popupInfo;

        const functions = {
            handleInputChangeV2 : this.handleInputChangeV2,
            UpdateState : this.UpdateState,
            handleClosePopup: this.handleClosePopup,
            onDeleteVehicle : this.onDeleteVehicle,
            onCopyVehicle : this.onCopyVehicle,
            updateValueWithSubplicationName : this.updateValueWithSubplicationName,            
            handleLoader: this.handleLoader,    
            updateValues:   this.UpdateState                                             
        }

        return (
            <Fragment>
                 {this.props[`${this.props.name}__popupStatus`] === true ? (    
                 <Grid container spacing={2}>
                    <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Dialog
                            className={popupInfo.popupParentClassName}
                            open={this.props[`${this.props.name}__popupStatus`]}
                            fullWidth={popupInfo.fullWidth}
                            maxWidth={popupInfo.maxWidth}
                            disableBackdropClickfullWidth={popupInfo.disableBackdropClickfullWidth}
                            disableBackdropClick={popupInfo.disableBackdropClick}
                            onEscapeKeyDown={this.handleClosePopup}
                        >
                            {
                                this.state.utilFunctions &&
                                <Fragment>
                                    {
                                        popupHeader &&
                                        <MuiDialogTitle disableTypography className={popupHeader.headerParentClassName ? popupHeader.headerParentClassName : ''}>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <PopupHeader
                                                    {...this.props}
                                                    {...functions}
                                                    {...this.state}
                                                    lob={LOB}
                                                    functions={functions}
                                                    popupDetail={popupDetail[this.props.popupName] || {}}
                                                />
                                            </Suspense>
                                        </MuiDialogTitle>
                                    }
                                    <MuiDialogContent className={popupContent && popupContent.contentParentClassName}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                        {
                                           popupContent.isStaticContentNeeded ?
                                            <PopupStaticContent
                                                {...this.props}
                                                {...functions}
                                                {...this.state}
                                                lob={LOB}
                                                functions={functions}
                                                popupDetail={popupDetail[this.props.popupName] || {}}
                                            /> :
                                            <PopupContent
                                                {...this.props}
                                                {...functions}
                                                {...this.state}
                                                popupState={this.state}
                                                lob={LOB}
                                                functions={functions}
                                                popupDetail={popupDetail[this.props.popupName] || {}}
                                            />
                                        }
                                        </Suspense>
                                        {this.props.bulkCopy &&
                                        <ValidatorForm
                                            id="child"
                                            onSubmit={e => { this.onCopyVehicle(e) }}
                                            onError={e => console.log(e)}
                                            ref={this.BulkCopyForm}
                                        >
                                            <Grid>
                                            {
                                                <Button
                                                    type="button"
                                                    id="childform"
                                                    onClick={() => this.BulkCopyForm.current.submit()}
                                                    color="primary"
                                                    className="nav-btn custom-CopyButton-margin-left"
                                                >
                                                    Copy
                                                </Button>
                                            }
                                            </Grid>
                                     
                                        </ValidatorForm>
                                        }
                                    </MuiDialogContent>
                                    {
                                        popupFooter &&
                                        <MuiDialogActions disableTypography className={popupFooter.footerParentClassName ? popupFooter.footerParentClassName : ''}>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <PopupFooter
                                                    {...this.props}
                                                    {...functions}
                                                    {...this.state}
                                                    lob={LOB}
                                                    functions={functions}
                                                    popupDetail={popupDetail[this.props.popupName] || {}}
                                                />
                                            </Suspense>
                                        </MuiDialogActions>
                                    }
                                </Fragment>
                            }
                        </Dialog>
                    </Grid>
                </Grid>):null
                 }
            {
                (this.state.showLoader || this.state.loading) && <PageLoader />
            } 
            </Fragment>
        )
    }

}

const mapStateToProps = (state) => ({
    productDetails: state.UIFlowReducer.productDetails
});

export default connect(mapStateToProps)(Popup);
