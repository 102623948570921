export const GetAdditionalCoverageName_GL = (props) => {
    let hierarchy = props.pageInfo.Hierarchy;
    let LOB = props.metadataReducer.lob;
    let obj = {
        Hierarchy: hierarchy,
        EffectiveDate: props.values.EffectiveDate
    }
    switch (hierarchy) {
        case "Policy Detail":
            return {
                ...obj,
                ROOTID: props.UIFlowReducer.submissionID,
                ID: props.metadataReducer.policycoverageID[LOB],
            }
        case "Location Detail":
            return {
                ...obj,
                ROOTID: props.UIFlowReducer.submissionID,
                ID: props.parentProps.parentRowData.ID
            }
        case "Classification Detail":
            return {
                ...obj,
                ROOTID: props.UIFlowReducer.submissionID,
                ID: props.parentProps.parentRowData.ID,
            }
        default:
            return {}


    }


}
export const GetAdditionalCoverageName_CF = (props) => {
    let hierarchy = props.pageInfo.Hierarchy
    let LOB = props.metadataReducer.lob;
    let obj = {
        Hierarchy: hierarchy,
        EffectiveDate: props.values.EffectiveDate
    }
    if (hierarchy) {
        if (hierarchy === 'Policy Detail') {
            return {
                ...obj,
                // State: props.values.State ? props.values.State : props.metadataReducer.primaryState,
                ROOTID: props.UIFlowReducer.submissionID,
                ID: props.metadataReducer.policycoverageID[LOB],
                ProductVerNumber: props.UIFlowReducer.productDetails.ProductVerNumber,
                ProductNumber: props.UIFlowReducer.productDetails.ProductNumber
            }
        }
        else {
            return {
                ...obj,
                // State: props.parentProps.pagelevelValues["PolicyDetail"].State,
                ROOTID: props.UIFlowReducer.submissionID,
                ID: props.parentProps.parentRowData.ID,
                ProductVerNumber: props.UIFlowReducer.productDetails.ProductVerNumber,
                ProductNumber: props.UIFlowReducer.productDetails.ProductNumber
            }
        }
    }
    else {
        return {}
    }
}

export const Utils = {
    GL: {
        additionalCoverageName: GetAdditionalCoverageName_GL
    },
    CF: {
        additionalCoverageName: GetAdditionalCoverageName_CF
    }
}