import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import dynamicRender from "../sections/CustomComponents/dynamicRender";
import store from "../../../store";
import appActions from "../../../actions";
const { actionType } = appActions;

export const DynamicPageHeader = (props) => {
  const [pageMetadata, setPageMetadata] = useState({});

  const { UIFlowReducer, metadataReducer } = useSelector((state) => state);
  const PageHeaderUpdate = UIFlowReducer.PageHeaderUpdate;

  let state = { pageMetadata, UIFlowReducer, metadataReducer };

  useEffect(() => {
    const callUtilMethod = async () => {
      try {
        const LOB =
          UIFlowReducer.productDetails.hasOwnProperty("LineOfBusiness") &&
          UIFlowReducer.productDetails["LineOfBusiness"];
        const key = props.templateName ? props.templateName : LOB;
        if ( props.utilfunction[key] && props.utilfunction[key].hasOwnProperty("componentDidMount")) {
          await props.utilfunction[key].componentDidMount(
            state,
            props,
            setPageMetadata
          );
        }
        store.dispatch({
          type: actionType.SET_PAGEHEADER_UPDATE,
          payload: false,
        });
      } catch (e) {
        console.log("Error in PageHeader", e);
      }
    };
    if (PageHeaderUpdate) callUtilMethod();
  }, [PageHeaderUpdate]);


  let SubApplicationDetailList =
    pageMetadata.ApplicationDetail &&
    pageMetadata.ApplicationDetail.SubApplicationDetailList;

  return (
    <>
      {SubApplicationDetailList && SubApplicationDetailList.length > 0 ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="pageHeaderDefaultClass"
        >
          <Grid container spacing={3}>
            {dynamicRender(
              SubApplicationDetailList,
              props,
              state,
              {},
              {},
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default DynamicPageHeader;
