import React, { useState, useEffect, useRef } from 'react';
import { Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker } from "@material-ui/pickers";
import { getDateWithoutOffset } from "../utils/Common/DateUtils";

export default function EffectiveDate(props){

    // useState
    const [ openEffectiveDate, setOpenEffectiveDate ] = useState(false);

    // props
    const { component, values } = props;

    // refs
    const componentRef = useRef(component);
    const valueRef = useRef(values);
    const handleInputChangeV2Ref = useRef(props.handleInputChangeV2);

    const getEffectiveDate = (effectiveDate, defaultValue) => {
        let effectiveDateValue = effectiveDate;
        if(defaultValue){
          switch(defaultValue){
            case "Now" :
              return effectiveDate;
            case "NextMonth" :
              {
                effectiveDateValue.setMonth(effectiveDate.getMonth() + 1);
                effectiveDateValue.setDate(1);
                return effectiveDateValue;
              }
            default :
              return effectiveDate;
          }
        }
    }

    useEffect(() => {
        let effectiveDate = getEffectiveDate(new Date(), componentRef.current.DefaultValue);
        let formatedDate = format(getDateWithoutOffset(effectiveDate), componentRef.current.OutputFormatPattern);
        if ( valueRef.current[effectiveDate] === undefined ) {
          handleInputChangeV2Ref.current( { [componentRef.current.AttributeName] : formatedDate }, componentRef.current );
        }
    }, [])

    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";


    const handleInternally = (event, value) => {
        let effectiveDate = getDateWithoutOffset(value);
        let formatedDate = format(effectiveDate, componentRef.current.OutputFormatPattern);
        setOpenEffectiveDate(!openEffectiveDate);
        handleInputChangeV2Ref.current({ [componentRef.current.AttributeName] : formatedDate }, componentRef.current);
    };

    const displayValue = values[componentRef.current.AttributeName] ?  getDateWithoutOffset(values[componentRef.current.AttributeName]) : values[componentRef.current.AttributeName]

    return(
        <React.Fragment>
            <Grid item xs={12} sm={12} md={6} lg={6} key={componentRef.current.AttributeName}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    //variant="inline"
                    //orientation="landscape"
                    open={openEffectiveDate}
                    onClose={() => setOpenEffectiveDate(!openEffectiveDate)}
                    onClick={() => setOpenEffectiveDate(!openEffectiveDate)}
                    format="MM/dd/yyyy"
                    margin="normal"
                    name={componentRef.current.AttributeName}
                    id={componentRef.current.AttributeName}
                    label={component.ComponentLabel}
                    placeholder="mm/dd/yyyy"
                    value={displayValue}
                    autoComplete={autocomplete}
                    minDate={new Date()}
                    onChange={(event, value) => { handleInternally(event, value) }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </React.Fragment>
    )

}
