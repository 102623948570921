import React, { Suspense } from 'react'
import { AppFooter } from '@coreui/react';
import DefaultFooter from '../../../../../containers/DefaultLayout/DefaultFooter';

const Footer = (props) => {

    return (
        <AppFooter className="custom-footer">
            <Suspense>
              <DefaultFooter />
            </Suspense>
        </AppFooter>
    )
}

export default Footer;