import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

export default function Message (props) {
    const { component } = props;
    const { Action } = component;
    const redux = useSelector((state) => state);
    const localStorageData = Object.fromEntries(Object.entries(localStorage).map(([key, value]) => [key,value]));
    const data = {...localStorageData,...redux.UIFlowReducer}

    const replacePlaceholders = (text) => {
        return text.replace(/\$\{([^}]+)\}/g, (item, key) => {
            return data[key] || key;
        });
    };

    const headerMessage = replacePlaceholders(Action.header);
    const message = replacePlaceholders(Action.message);

    return (
        <Grid item xs={12} sm={12} md={parseInt(component.Size) > 0 ? parseInt(component.Size) : 12} lg={parseInt(component.Size) > 0 ? parseInt(component.Size) : 12}>
            <Box padding={2}>
                <Typography variant="h4" component="h1" className={component.AttributeStyleClass}>
                    {headerMessage}
                </Typography>

                <Typography variant="body1" style={{ marginTop: '10px' }} className={component.AttributeStyleClass}>
                    {message}
                </Typography>
            </Box>
        </Grid>
    );
}
