import { SET_PAGENAME } from "../../../../actions/types";
import store from "../../../../store"

const componentMount = async (props, state,updateState) => {
  let template=[]
  let routeName = props.location.pathname.slice(1).toLowerCase();
  let {sidebar} = Object.assign({}, props.navigation.pageNavigation[routeName]);
  let page = sidebar.findIndex(i => i.name.toLowerCase() === routeName)
  if(page>=0){
  let {children} = sidebar[page]
  let pageName=props.UIFlowReducer.pageName ? props.UIFlowReducer.pageName : props.pageInfo.pageName
  let pageIndex = children.findIndex(i => i.pageName === pageName)
  let templateName = children[pageIndex]
  template=templateName.template
  store.dispatch({ type: SET_PAGENAME, payload: templateName.pageName })
  }
  return { template:template  }
}

const componentUpdate = async (props, state) => {
  if (props.UIFlowReducer.pageName) {
    let template=[]
    let routeName = props.location.pathname.slice(1).toLowerCase();
    let {sidebar} = Object.assign({}, props.navigation.pageNavigation[routeName]);
    let page = sidebar.findIndex(i => i.name.toLowerCase() === routeName)
    if(page>=0){
    let {children} = sidebar[page]
    let pageIndex = children.findIndex(i => i.pageName === props.UIFlowReducer.pageName)
    let templateName = children[pageIndex]
      template=templateName.template
    }
    return {  template:template  }
  }
}
export let Dashboard = {
  'componentDidMount': componentMount,
  'componentDidUpdate': componentUpdate,
}