import React, { Fragment } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Grid } from '@material-ui/core';

const EditButton = (props) => {

    // props
    const { component, enableEditOption } = props;

    return(
        <Fragment>
            <Grid className="edit_grid theme_nav" item xs={1} sm={1} md={props.component.Size} lg={props.component.Size}>
                <EditIcon onClick={() => enableEditOption(component)} />
            </Grid>
        </Fragment>
    )

}

export default EditButton;
