import React from 'react';
import red from '@material-ui/core/colors/red';
import Checkbox from '@material-ui/core/Checkbox';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { FormControlLabel } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';

const red300 = red['500'];

const style = {
    left: 0,
    fontSize: '12px',
    color: red300,
    position: 'absolute',
    marginTop: 0,
};


class CheckboxValidator extends ValidatorComponent {

    render() {

        const { componentLabel, component, componentAttributeValue, SubApplicationAttributeValueList,isReadOnly=component.IsReadonly } = this.props;

        return (
            <div>
                <FormGroup row>
                    {
                        SubApplicationAttributeValueList.length === 0 ? 
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={isReadOnly}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    name={component.AttributeName}
                                    checked={componentAttributeValue}
                                    defaultChecked={component.DefaultValue === 'true' ? true : false}
                                    onChange={(event) => this.props.onChange(event)}
                                    size="small"
                                />
                            }
                            label={componentLabel}
                        /> : 
                        SubApplicationAttributeValueList.map(( componentInfo ) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={component.IsReadonly}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        name={componentInfo.DisplayValue}
                                        checked={componentAttributeValue ? componentAttributeValue.includes(componentInfo.Value) ? true : false : false}
                                        onChange={() => this.props.handleMultipleCheckBox(component.AttributeName, componentInfo.Value)}
                                        size="small"
                                    />
                                }
                                label={componentInfo.DisplayValue}
                            />
                        ))
                    }
                </FormGroup>
                {this.errorText()}
            </div>

        );

    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div className='small_error' style={style}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default CheckboxValidator;
