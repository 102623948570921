import Axios from "axios";
import store from "../../../../store";
const  {DateTime} = require('luxon');
//const moment = require('moment');
let format = 'YYYY-MM-DD HH:mm:ss';

const getTimeZone = async () => {
    var d = new Date();
    var usertime = d.toLocaleString();

    // Some browsers / OSs provide the timezone name in their local string:
    var tzsregex = /\b(ACDT|ACST|ACT|ADT|AEDT|AEST|AFT|AKDT|AKST|AMST|AMT|ART|AST|AWDT|AWST|AZOST|AZT|BDT|BIOT|BIT|BOT|BRT|BST|BTT|CAT|CCT|CDT|CEDT|CEST|CET|CHADT|CHAST|CIST|CKT|CLST|CLT|COST|COT|CST|CT|CVT|CXT|CHST|DFT|EAST|EAT|ECT|EDT|EEDT|EEST|EET|EST|FJT|FKST|FKT|GALT|GET|GFT|GILT|GIT|GMT|GST|GYT|HADT|HAEC|HAST|HKT|HMT|HST|ICT|IDT|IRKT|IRST|IST|JST|KRAT|KST|LHST|LINT|MART|MAGT|MDT|MET|MEST|MIT|MSD|MSK|MST|MUT|MYT|NDT|NFT|NPT|NST|NT|NZDT|NZST|OMST|PDT|PETT|PHOT|PKT|PST|RET|SAMT|SAST|SBT|SCT|SGT|SLT|SST|TAHT|THA|UYST|UYT|VET|VLAT|WAT|WEDT|WEST|WET|WST|YAKT|YEKT)\b/gi;

    // In other browsers the timezone needs to be estimated based on the offset:
    var timezonenames = {"UTC+0":"GMT","UTC+1":"CET","UTC+2":"EET","UTC+3":"EEDT","UTC+3.5":"IRST","UTC+4":"MSD","UTC+4.5":"AFT","UTC+5":"PKT","UTC+5.5":"IST","UTC+6":"BST","UTC+6.5":"MST","UTC+7":"THA","UTC+8":"AWST","UTC+9":"AWDT","UTC+9.5":"ACST","UTC+10":"AEST","UTC+10.5":"ACDT","UTC+11":"AEDT","UTC+11.5":"NFT","UTC+12":"NZST","UTC-1":"AZOST","UTC-2":"GST","UTC-3":"BRT","UTC-3.5":"NST","UTC-4":"CLT","UTC-4.5":"VET","UTC-5":"EST","UTC-6":"CST","UTC-7":"MST","UTC-8":"PST","UTC-9":"AKST","UTC-9.5":"MIT","UTC-10":"HST","UTC-11":"SST","UTC-12":"BIT"};

    var timezone = usertime.match(tzsregex);
    if (timezone) {
        timezone = timezone[timezone.length-1];
    } else {
        var offset = -1*d.getTimezoneOffset()/60;
        offset = "UTC" + (offset >= 0 ? "+" + offset : offset);
        timezone = timezonenames[offset];
    }

    /* console.log(usertime );
    console.log(offset );
    console.log(timezone); */

return timezone;
}

export const AMT_Logger = async (logInfo) => {

    let AMT_loggerRequest = JSON.parse(JSON.stringify(require("../../models/AMT_SendData.json")));

    const ReduxState = store.getState();
    // const configInfo = ReduxState.authReducer.configInfo;
    const clientInfo = ReduxState.ownerResources.clientInfo;

    const productDetails = ReduxState.UIFlowReducer.productDetails;
    let productDetailsKeyMapper = Object.create({});
    if(Object.entries(productDetails).length > 0){
        Object.entries(productDetails).forEach(([key, value]) => {
            if(key == 'ProductID'){
                productDetailsKeyMapper['ObjectId'] = value;
            }
            else if(key == 'ProductVerID'){
                productDetailsKeyMapper['ObjectVerId'] = value;
            }
            else{
                productDetailsKeyMapper[key] = value;
            }
        })
    }

    // let logInfo = configInfo.hasOwnProperty('AMTDetails') ?  configInfo['AMTDetails'] : {};
    logInfo = {...logInfo, ...productDetailsKeyMapper};

    //let BrowserDate = new Date();
    //logInfo['BrowserTime'] =moment(BrowserDate, format).format(format) +"."+ BrowserDate.getMilliseconds();
    logInfo['BrowserTime'] = logInfo['RequestedTime'];
    logInfo['BrowserTimeZone'] = await getTimeZone();
    logInfo['ServiceName'] = clientInfo['AMT_ClientServiceName'] ? clientInfo['AMT_ClientServiceName'] : "";
    logInfo['RequestSequenceId'] = logInfo['ReceivedRequestSequenceId'] ? logInfo['ReceivedRequestSequenceId'] : 1;
    logInfo['RequestedURL'] = window.location.href;
    let timeZone = DateTime.local().toFormat('ZZZZ');
    let offset;
    let timeZoneName;
    if(timeZone.toString().includes("+")){
      let timeZoneNameSplitted = timeZone.toString().split("+");
      offset = "+"+timeZoneNameSplitted[1];
      timeZoneName = timeZoneNameSplitted[0];
    }
    if(timeZone.toString().includes("-")){
      let timeZoneNameSplitted = timeZone.toString().split("-");
      offset = "+"+timeZoneNameSplitted[1];
      timeZoneName = timeZoneNameSplitted[0];
    }
    logInfo['ServerTimeZoneOffset'] = offset;
    logInfo['ServerTimeZone'] = timeZoneName;
    // logInfo['ParentRequestId'] = logInfo.CurrentRequestId;
    // logInfo['CurrentRequestId'] = uuidv4();

    Object.keys(AMT_loggerRequest).map((key) => {
        if(logInfo[key])
        {
            AMT_loggerRequest[key] = logInfo[key];
        }
    });

    Object.keys(AMT_loggerRequest['TransactionLog']).map((key) => {
        if(logInfo[key])
        {
            AMT_loggerRequest['TransactionLog'][key] = logInfo[key];
        }
    });

    // Temporarily Added
    // AMT_loggerRequest['LineOfBusiness'] = 'Solartis';
    // console.log("AMT Log Request",AMT_loggerRequest);
    try
    {
        if(logInfo.hasOwnProperty("AMT_loggerToken") && logInfo.AMT_loggerToken!==""){
        const headers = {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${logInfo.AMT_loggerToken}`
        }
        const AMT_loggerResponse = Axios.post(clientInfo.AMT_LogService, JSON.stringify(AMT_loggerRequest), { headers: headers });
    }
        // console.log("AMTLoggerRes",AMT_loggerResponse.status);
    }
    catch (error)
    {

    }

}
