import React, { useEffect } from 'react';
import { Input } from 'reactstrap';

const EmptyHidden = (props) => {

    const {component} = props

    useEffect(()=> {
        props.updateValueWithSubplicationName({ [component.AttributeName]: "" }, component);
    },[])

  return (
    <Input
    type="hidden"
    id={component.ComponentId}
    value=""
/>
  )
}

export default EmptyHidden