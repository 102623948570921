import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import BackspaceIcon from "@material-ui/icons/Backspace";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { Tooltip } from '@material-ui/core';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MsgReader from '@kenjiuno/msgreader';
import { makeStyles } from '@material-ui/core/styles';
import { getMimeType } from '../utils/Common/API';
import { decompressRTF } from '@kenjiuno/decompressrtf';
import { deEncapsulateSync } from 'rtf-stream-parser';
import iconvLite from 'iconv-lite';
import { triggerToast ,removeHtmlTags} from "../utils/Common/HelperUtils";


const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 600,
    backgroundColor: "rgb(47,53,58)",
    fontSize: "14px"
  },
}));


const MultipleUploaderV3 = (props) => {
  const [attachment, setAttachment] = useState([]);
  const [length, setLength] = useState(0);
  const [drag, setDrag] = useState(false);
  const { component } = props;
  const dropRef = useRef();
  const changeAttRef = useRef();

  useEffect(() => {
    getAddEventListener()
    let { pagelevelValues, component } = props;
    if (
      pagelevelValues &&
      pagelevelValues[component.SubApplicationName] &&
      pagelevelValues[component.SubApplicationName][component.AttributeName]
    ) {
      let onLoadValues =
        pagelevelValues[component.SubApplicationName][component.AttributeName];
      setAttachment(onLoadValues);
    }

    return () => {
      getRemoveEventListener()
    };
  }, []);

  useEffect(() => {
    let e = {
      target: {
        name: component.AttributeName,
      },
    };
    setLength(attachment.length);
    onChange(e);
    //useref is synchronous
    changeAttRef.current = attachment;
  }, [attachment]);


  const getAddEventListener = () => {
    try {
      ["dragenter", "dragover", "dragleave", "mouseout", "drop"].forEach(
        (eventName) => {
          window.addEventListener(eventName, handleDragEvents);
        }
      );
      window.addEventListener("dragenter", handleDragIn);
      window.addEventListener("dragleave", handleDragOut);
      window.addEventListener("mouseout", handleMouseOut);
      window.addEventListener("drop", handleMouseOut);

      let div = dropRef.current;
      div.addEventListener("drop", handleDrop);
    } catch (err) {
      console.log("Error in getAddEventListener method", err.message);
    }
  }

  const getRemoveEventListener = () => {
    try {
      ["dragenter", "dragover", "dragleave", "mouseout", "drop"].forEach(
        (eventName) => {
          window.removeEventListener(eventName, handleDragEvents);
        }
      );
      window.removeEventListener("dragenter", handleDragIn);
      window.removeEventListener("dragleave", handleDragOut);
      window.removeEventListener("mouseout", handleMouseOut);
      window.removeEventListener("drop", handleMouseOut);

      let div = dropRef.current;
      div.removeEventListener("drop", handleDrop);
    } catch (err) {
      console.log("Error in getRemoveEventListener method", err.message);
    }
  }

  const onChange = async (e) => {
    try {
      if (props.customTable) {
        let value = Object.assign({}, { [e.target.name]: attachment });
        props.handleRowLevelInputChange(value, component, props.customTableRowIndex, props);
      } else {
        let value = Object.assign({}, { [e.target.name]: attachment });
        await props.handleInputChangeV2(value, component, props.isDynamicTable, props.datatableRowindex);
      }
    } catch (err) {
      triggerToast(err)
      console.log("Error in onChange method", err.message);
    }
  };
const handleFile=(attachedFile,value)=>{
  try{
    if (value) {
      setAttachment([])
    }
    if (attachedFile.length <= 0) {
      return;
    }
    if (props && props[component.OnClickAction]) {
      props[component.OnClickAction](attachedFile, setAttachment, changeAttRef.current, props, true);
    } else {
      props.handleAttachments(attachedFile, setAttachment, changeAttRef.current, props, true);
    }
  }catch(err){
    triggerToast(err)
    console.log("Error in handleFile method", err.message);
  }
  }
  const onUpload = async (event) => {
    try {
      let attachedFile = event.target.files;
      let filename = attachedFile[0].name
      let extension = filename.substr(filename.lastIndexOf('.') + 1);
      if (extension === "msg") {
        let fileReader = new FileReader();
        fileReader.onload = async (evt) => {
          let buffer = evt.target.result;
          let msgReader = new MsgReader(buffer);
          let fileData = msgReader.getFileData();
          let newData = Buffer.from(decompressRTF(Array.from(fileData.compressedRtf))).toString("ascii");
          const result = deEncapsulateSync(newData, { decode: iconvLite.decode });
          let fileStore = [];
          let htmlContent = result.text;
          let filePromises = fileData.attachments.map((data, i) => {
            let file = msgReader.getAttachment(i);
            let filename = file.fileName
            let extension = filename.substr(filename.lastIndexOf('.') + 1);
            let mimetype = getMimeType(extension);
            let fileUrl = new File([file.content], file.fileName, { type: mimetype ? mimetype : "application/octet-stream" });
            if (data.attachmentHidden === undefined || data.attachmentHidden === false) {
              fileStore = [...fileStore, fileUrl]
            }
            if (data.fileName === filename && (data.attachmentHidden === undefined || data.attachmentHidden === true)) {
              return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = async (fileLoadedEvent) => {
                  try {
                    let srcData = fileLoadedEvent.target.result;
                    htmlContent = htmlContent.replace(`src="cid:${data.pidContentId}"`, `src="${srcData}"`)
                    resolve(htmlContent)
                  } catch (err) {
                    reject(err);
                  }
                }
                reader.onerror = (error) => {
                  reject(error);
                };
                reader.readAsDataURL(fileUrl);
              })
            }
          });
          const fileInfos = await Promise.all(filePromises);
          let imgWithFile = fileInfos[fileInfos.length - 1];
          let fullContent = imgWithFile ? imgWithFile : htmlContent;
          let removedStyle=await removeHtmlTags(fullContent,'style');
          let stateData = {
            AttachmentSubject: fileData.subject,
            AttachmentDescription: removedStyle ? removedStyle : fileData.body
          }
          let subapplicationName = props.subapplicationName;
          let DetailSubApp = props.detailSummaryList[subapplicationName];
          props.UpdateState("pagelevelValues", { [DetailSubApp]: { ...props.pagelevelValues[DetailSubApp], ...stateData } }, true);
          props.UpdateState("values", { ...props.values, ...stateData }, true);
          props.UpdateState("transactionPrimaryObject", { [DetailSubApp]: { ...props.transactionPrimaryObject[DetailSubApp], ...stateData } }, true);
          handleFile(fileStore, true);
        }
        fileReader.readAsArrayBuffer(attachedFile[0]);
      }
      else {
        handleFile(attachedFile, false)
      }
    } catch (err) {
      triggerToast(err)
      console.log("Error in onUpload method", err.message);
    }

  };

  const downloadCurrentFile = (file) => {
    try {
      if (props && props[component.OnClickAction]) {
        props[component.OnClickAction](file);
      } else {
        props.handleAttachments(file, undefined, undefined, props);
      }
    } catch (err) {
      triggerToast(err)
      console.log("Error in downloadCurrentFile method", err.message);
    }
  };

  const deleteAttachment = (index) => {
    try {
      let tempAttachment = JSON.parse(JSON.stringify([...attachment]));
      tempAttachment.splice(index, 1);
      setAttachment(tempAttachment);
    } catch (err) {
      triggerToast(err)
      console.log("Error in deleteAttachment method", err.message);
    }
  };


  let dragCounter;

  const handleDragEvents = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
    } catch (err) {
      triggerToast(err)
      console.log("Error in handleDragEvents method", err.message);
    }
  };

  const handleDragIn = (e) => {
    try {
      dragCounter++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDrag(true);
      }
    } catch (err) {
      triggerToast(err)
      console.log("Error in handleDragIn method", err.message);
    }
  };

  const handleDragOut = (e) => {
    try {

      dragCounter--;
      if (dragCounter === 0) {
        setDrag(false);
      }
    } catch (err) {
      triggerToast(err)
      console.log("Error in handleDragOut method", err.message);
    }
  };

  const handleMouseOut = (e) => {
    try {
      dragCounter = 0;
      if (dragCounter === 0) {
        setDrag(false);
      }
    } catch (err) {
      triggerToast(err)
      console.log("Error in handleMouseOut method", err.message);
    }
  };

  const handleDrop = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      dragCounter = 0;
      if (dragCounter === 0) {
        setDrag(false);
      }
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        let data = { ...e.dataTransfer.files };
        let event = {
          target: {
            files: data,
          },
        };
        onUpload(event);
        e.dataTransfer.clearData();
      }
    } catch (err) {
      triggerToast(err)
      console.log("Error in handleDrop method", err.message);
    }
  };


  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} ref={dropRef} className="grid-drag__container"  >
      {drag && (
        <div className="drag__content">
          <div className="drag__text">
            <div>
              <CloudUploadIcon style={{ fontSize: "2rem" }} />
              <span> Drag & Drop your files below to upload or attach files</span>
            </div>
          </div>
        </div>
      )}
      <div>Attachment Found: {length} Attachment(s)</div>
      {attachment.length > 0 ? (
        <>
          {attachment.map((file, index) => {
            return (

              <div className="file__container" key={index} >
                <Tooltip title={file[component.ResponseKey]} classes={{ tooltip: classes.customWidth }} arrow >
                  <a
                    key={index}
                    className="Multiple-Upload-Link file__nameText"
                    onClick={() => downloadCurrentFile(attachment[index])}
                  >
                    {file[component.ResponseKey]}
                  </a>
                </Tooltip>
                <IconButton component="div" onClick={() => deleteAttachment(index)}
                >
                  <BackspaceIcon />
                </IconButton>

                <br />
              </div>

            );
          })}
        </>
      ) : null}
      <div className="upload upload-inlineContainer theme_button">
        <div className="upload-button theme_button">
          <span className="upload-button-text theme_button">
            <PublishIcon />
            <span>{component.ComponentLabel} </span>
          </span>
          <input
            id="contained-button-file"
            multiple
            type="file"
            // accept={
            //   extension === undefined
            //     ? "application/vnd.ms-excel , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/pdf"
            //     : null
            // }
            onChange={onUpload}
            className="fileInputOverflow upload-button-input theme_button"
          />

        </div>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  UIFlowReducer: state.UIFlowReducer,
});

export default connect(mapStateToProps)(MultipleUploaderV3);
