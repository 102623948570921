import React from "react";
import red from "@material-ui/core/colors/red";
import { ValidatorComponent } from "react-material-ui-form-validator";
import { MenuItem } from "@material-ui/core";
import SelectDropdown from "@material-ui/core/Select";

const red300 = red["500"];

const style = {
  left: 0,
  fontSize: "12px",
  color: red300
};

const styles = {
  dropdown: {
    minWidth: "100%",
    marginTop: "16px !important",
  },
  label: {
    width: "150%",
  },
};

class SelectDropdownValidatorV2 extends ValidatorComponent {
  render() {
    const {
      ComponentId,
      labelId,
      name,
      errorMessages,
      validators,
      requiredError,
      value,
      options,
      component,
      attributeValue,
      displayEmpty,
      autoComplete,
      variant,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <SelectDropdown
          labelId={labelId}
          id={ComponentId}
          name={name}
          value={value}
          disabled={component.IsReadonly}
          onChange={(e) => this.props.onChange(e)}
          fullWidth
          variant={variant}
          displayEmpty={displayEmpty}
          autoComplete={autoComplete}
        >
          {options && options.map((option) => {
            return (
              <MenuItem key={option.Sequence} value={option.Value} disabled={option.disabled}>
                {option.DisplayValue}
              </MenuItem>
            );
          })}
        </SelectDropdown>
          <div className="errorMessageStyle" >
        {this.errorText()}
        </div>
      </React.Fragment>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <span style={style}>{this.getErrorMessage()}</span>;
  }
}

export default SelectDropdownValidatorV2;
