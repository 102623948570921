import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { setIconcondition, setShowHideCondition, setStateList, setprimaryState } from '../../../../../actions/applicationMetadataAction';
import { CardContent } from "@material-ui/core";
import { Grid, Button } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import { showLoader, hideLoader } from "../../../../../actions/UIFlowActions";
import { EntitySubAppTranslation } from '../../../utils/requestConstruction/ResponseTranslation';
import { LocationandBuildingAxios, getServiceRequestDetail, AxiosInstance } from "../../../utils/Common/API"
import LocationSummary from './Summary'
import dynamicRender from '../../CustomComponents/dynamicRender';
import childComp from '../../CustomComponents/ChildComponent/childComponent'
import { ValidatorForm } from "react-material-ui-form-validator";
import CloneWithPropsHOC from '../../../../../template/CloneWithPropsHOC';
import { defaultToast, triggerToast } from '../../../utils/Common/HelperUtils';
import PageLoader from "../../../controls/PageLoader";
import { removeDiacritics } from '../../../utils/Common/CommonUtils';
import { constructAndInvokeMetadataCall, deleteDependentAttribute } from '../../../utils/Common/MetaUtils';
import { subAppListConstruction } from '../../../utils/Common/DetailSummarySubAppList';
import { format } from 'date-fns';
import Axios from 'axios';
import { commonChangeMethod } from '../../../utils/Common/InputUtils';

class LocationDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageMetadata: {},
            isSubquestionRequired:false,
            pagelevelValues: {},
            values: {},
            detailValues: {},
            transactionPrimaryObject: {},
            subAppList: [],
            isSummaryNeeded: false,
            parentHierarchyArr: [],
            stateListVal: [],
            actionArray: [],
            summarySubAppState: [],
            subappNameListState: [],
            detailSummaryListState: {},
            modifiedAdditionalCoverage: {},
            childFlagProps:{},
            loading : false,
            callbackNeeded: false
        }
        this.childform = React.createRef();
        this.cancelAPICall = React.createRef();
    }

    async componentDidMount() {
        if (Object.keys(this.props.rowData).length > 5) {
            if (this.props.metadataReducer.locationIdList) {
                if (this.props.metadataReducer.locationIdList.includes(this.props.rowData.ID)) {
                    this.props.setLocationId(this.props.rowData.ID)
                }
                if (this.props.metadataReducer.buildingIdList) {
                    if (this.props.metadataReducer.buildingIdList.includes(this.props.rowData.ID)) {
                        this.props.setbuildingId(this.props.rowData.ID)
                    }
                }
                if (this.props.metadataReducer.occupancyclassIdList) {
                    if (this.props.metadataReducer.occupancyclassIdList.includes(this.props.rowData.ID)) {
                        this.props.setoccupancyclassId(this.props.rowData.ID)
                    }
                }
            }
        }
        let { util, utilfunction } = this.props.pageInfo
        await utilfunction[util].componentDidMount(this.props, this.state, this.UpdateState, this.getMetadataRequest)
    }

    handleLoader = (params) => {
        this.setState({ loading: params });
      };

    updateValues = (obj) => {
        this.setState({
            ...this.state,
            ...obj
        })
    }

    functions = {
        loader: this.handleLoader,
        updateStateValues: this.updateValues
    };

    async Metadata(){
        try{
        if(this.cancelAPICall.current && this.cancelAPICall.current.Metadata){
            this.cancelAPICall.current.Metadata.cancel("Metadata canceled")
        }
        this.cancelAPICall.current = {...this.cancelAPICall.current, Metadata: Axios.CancelToken.source()}
        let SubApplicationList = this.props.pageInfo["subApplication"]["locationDetail"]
        let detailSubApp = []
        let summarySubApp = []
        let subappNameList = []
        let detailSummaryList = {}
        subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
        let { objectId, objectVerId } = this.props.pageInfo
        let subAppInfo={
            subappName:subappNameList,
            objectId,
            objectVerId,
        }
        let effectiveDate = (this.state.values && this.state.values.EffectiveDate) ? this.state.values.EffectiveDate : (this.props.values && this.props.values.EffectiveDate) ? this.props.values.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
        const cancelToken =(this.cancelAPICall.current && this.cancelAPICall.current['Metadata'] && this.cancelAPICall.current['Metadata'].token) || ''
        let response=await constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: this.state.transactionPrimaryObject, EffectiveDate: effectiveDate }, null,{cancelToken}  )
        if(response === 'APIcancelled'){
            this.updateValues({loading:false})
        }
        else{
            if(this.cancelAPICall.current['Metadata']) this.cancelAPICall.current['Metadata'] = '';
            this.updateValues({pageMetadata: response, loading:false, 'Metadata_loading': false})
        }
        }
        catch(e){
            console.log("Error in Metadata", e)
        }
    }

     async componentDidUpdate(prevProps,prevState) {
            let { util, utilfunction } = this.props
        if(this.props.utilfunction){
            if (this.props.utilfunction[util].hasOwnProperty("componentDidUpdate")) {
                await utilfunction[util].componentDidUpdate(this.props,this.state,this.UpdateState,prevState,prevProps)
            }
        }

        if (prevProps.canProceed !== this.props.canProceed) {
            if (this.props.canProceed) {
                this.saveVehicleDetail();
            }
        }
        if(this.state.isSubquestionRequired)
        {
            console.log("subquestion called ")
            this.state["isSubquestionRequired"]=false;

            this.callback();
        }

        if(this.state.callbackNeeded === true){
            let {isLoaderNeeded=true}  = this.props.pageInfo
            this.updateValues({callbackNeeded:false, loading: isLoaderNeeded,'Metadata_loading': true});
            await this.Metadata();
        }
    }

    executeScroll = () => {
        let formRef= this.childform.current;
        formRef = formRef ? formRef : this.childform
        ReactDOM.findDOMNode(formRef.errors[0]).scrollIntoView({ behavior: 'smooth', block: 'center' });
        if (false || !!document.documentMode) {
            window.scrollBy(0, -120);
        }
    }

    handleSubmit = (callback) => {
        this.props.showLoader()

        let key
        let parentHierarchyArr = this.state.parentHierarchyArr
        let actionArray = this.state.actionArray
        if (this.props.pageInfo.subApplication.locationSummary) {
            if (this.props.pageInfo.subApplication.locationSummary[0] !== null || this.props.pageInfo.subApplication.locationSummary[0] !== undefined) {
                key = this.props.pageInfo.subApplication.locationSummary.toLowerCase() + "_dataList";
                this.state.detailValues.id = this.props.rowData.ID;
            }
        }
        this.invokeAPICall_V1(callback, key)
        parentHierarchyArr = this.props.metadataReducer.iconcondition && this.props.metadataReducer.iconcondition !== undefined && this.props.metadataReducer.iconcondition.length > 0 ? this.props.metadataReducer.iconcondition : []
        if (!parentHierarchyArr.includes(this.props.pageInfo.subApplication.locationSummary.toLowerCase())) {
            parentHierarchyArr.push(this.props.pageInfo.subApplication.locationSummary.toLowerCase())
        }
        this.props.setIconcondition(parentHierarchyArr)
        if (!actionArray.includes(this.props.pageInfo.action)) {
            if (this.props.pageInfo.action === "saveLocationDetail") {
                this.setState({
                    isSummaryNeeded: true
                }, () => {
                    actionArray.push(this.props.pageInfo.action)
                })
            }
            else {
                this.setState({
                    isSummaryNeeded: false
                })
            }
        }
        this.props.hideLoader()
    }
    invokeAPICall = async (callback, key) => {
        let { util, utilfunction } = this.props.pageInfo
        this.props.showLoader()
        let request = await utilfunction[util].constructTransactionRequest(this.state, this.props, this.state.pagelevelValues, true, false)
        request["EventName"] = this.props.pageInfo.eventName
        request["TargetEventName"] = this.props.pageInfo.targetEventName
        request["EntityName"] = this.props.pageInfo.entityName
        request["ROOTID"] = this.props.metadataReducer.submissionResponse.SubmissionID //"e3b34655-d77a-4aaa-b6c4-f8ba7cf870be"
        request = await utilfunction[util].deleteAdditionalCoverageInRequest(this.state.modifiedAdditionalCoverage, request, this.state)
        if (this.props.pageInfo.parentHierarchy) {
            request["PARENTID"] = this.props.parentProps.parentRowData.ID
            request["ID"] = this.props.parentProps.parentRowData.ID
        }
        else {
            let stateList = this.props.metadataReducer.stateList
            this.props.pageInfo.utilfunction[this.props.pageInfo.util].setStateID(stateList, this.props, request)
          }
        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
            .then(async (response) => {
                if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
                    throw new Error('')
                }
                else{
                    let responseEntity = response.data[this.props.pageInfo.entityName]
                    await this.props.pageInfo.utilfunction[this.props.pageInfo.util].setHierarchyIds(responseEntity, request, this.getLocationList, callback, key, this.props);
                    this.setState({
                        values: { ...this.state.values, ...response.data[this.props.pageInfo.entityName] }
                    }, () => {
                        if (this.props.pageInfo.parentHierarchy) {
                            callback(response.data[this.props.pageInfo.entityName], key, this.state)
                        }
                        this.props.hideLoader()
                    })
                }
            })
            .catch((err) => {
                this.props.hideLoader()
                triggerToast(err.message)
                console.log(err);
            });


    }

    invokeAPICall_V1 = async(callback,key) => {
        let { util, utilfunction } = this.props.pageInfo
        this.props.showLoader()
        await utilfunction[util].constructTransactionRequest(this.state, this.props, this.state.pagelevelValues, true, false,callback,key,this.UpdateState)
        this.props.hideLoader();
    }

    getLocationList = (callback, key) => {
        let request = Object.create({});
        request["ServiceRequestDetail"] = Object.assign(
            {},
            getServiceRequestDetail()
        );
        request.OwnerId = sessionStorage.getItem("OwnerID");
        request["EventName"] = this.props.pageInfo.eventName
        request["TargetEventName"] = this.props.pageInfo.getEventName
        request["ID"] = "2fb336d5-c5bb-4141-8ee2-9366c97b1f20"
        request["PARENTID"] = "2fb336d5-c5bb-4141-8ee2-9366c97b1f20"
        request["ROOTID"] = "2fb336d5-c5bb-4141-8ee2-9366c97b1f20"
        console.log("getlocation list request--->",request)
        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
            .then((response) => {
                if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
                    console.log("Response Failed");
                    triggerToast("Response Failed");
                }else{
                console.log("getlocation list response--->",response)
                this.setState({
                    values: { ...this.state.values, ...response.data[this.props.pageInfo.entityName] }
                }, () => {
                    callback(response.data[this.props.pageInfo.entityName], key, this.state)
                })
            }
            })
    }
    requestConstruction = (request) => {
        if (this.props.pageInfo.subApplication !== '') {
            let subappnamelist = this.props.pageInfo.subApplication.locationDetail
            for (let i = 0; i < subappnamelist.length; i++) {
                if (this.props.pagelevelValues[subappnamelist[i]]) {
                    Object.keys(this.props.pagelevelValues[subappnamelist[i]]).map(() => {
                        request = { [subappnamelist[i]]: this.props.pagelevelValues[subappnamelist[i]] }
                    })
                }
            }
        }
    }

    getMetadataRequest = async (state) => {
        await this.props.pageInfo.utilfunction[this.props.pageInfo.util].getMetadataRequest(this.state, this.props, state, this.metadataAPI_callback);
    }


    metadataAPI_callback = (request, response) => {
        // this.props.setMetadataRequest(JSON.stringify(request, null, 2));
        // this.props.setMetadataResponse(JSON.stringify(response, null, 2));
        this.setState({
            pageMetadata: response
        })
        this.props.hideLoader();

    }

   async callbackAPI(value, component, isDynamicTable, datatableRowindex,props){
        try{
            if(component.Action.Actions && component.Action.Actions.length > 0){
                if(component.Action.Actions && component.Action.Actions.length > 0){
                    await commonChangeMethod(component.Action.Actions ,value ,props, this.state, component,  this.state.transactionPrimaryObject, this.state.pagelevelValues, this.cancelAPICall)
                   }
            }
           else if (component.AjaxNeeded==='true' || (this.cancelAPICall.current && this.cancelAPICall.current.Metadata)) {
            let isLoaderNeeded=true
            if("isLoaderNeeded" in this.props.pageInfo && this.props.pageInfo.isLoaderNeeded===false){
                isLoaderNeeded=false
            }
            if("isLoaderNeeded" in component.Action){
                isLoaderNeeded=component.Action.isLoaderNeeded
            }
            this.updateValues({loading: isLoaderNeeded, 'Metadata_loading': true})
            await this.Metadata()
          }
        }
        catch(e){
            console.log('Error in Callback', e)
        }
    }

    debounceCall = function () {
        let timer;
        return async function (callBack, timeDelay) {
            clearTimeout(timer);
            timer = setTimeout(callBack, timeDelay*1000);
        }
      }()



    handleInputChangeV2 = async (value, component, isDynamicTable, datatableRowindex,props) => {
        if(this.props.pageInfo && this.props.pageInfo.handleAsyncUpdate === true){
            this.setState((state)=>{
                let obj = {}
                let keys = Object.keys(value);
                let attributeName = keys[0];
                let attributeValue = value[attributeName]
                if (value[attributeName] && value[attributeName].constructor !== Array && value[attributeName].constructor !== File) {
                  attributeValue = value[attributeName].toString();
                }
                if(value[attributeName] && value[attributeName].constructor === File){
                  attributeValue = value[attributeName]
                }
                attributeValue =  removeDiacritics(attributeValue, component, props)
                let subappName = component.SubApplicationName;
                let pagelevelValues = state.pagelevelValues;
                let transactionPrimaryObject = state.transactionPrimaryObject;
                let values = state.values;
                if (!pagelevelValues[subappName]) {
                    pagelevelValues[subappName] = {};
                  }
                  if (!transactionPrimaryObject[subappName]) {
                    transactionPrimaryObject[subappName] = {};
                  }
                  if(keys.length>1){
                    pagelevelValues = {...pagelevelValues, [subappName]: {...pagelevelValues[subappName], ...value}}
                    transactionPrimaryObject = {...transactionPrimaryObject, [subappName]: {...transactionPrimaryObject[subappName], ...value}}
                  }
                  else{
                    pagelevelValues = {...pagelevelValues, [subappName]: {...pagelevelValues[subappName], ...value}}
                    transactionPrimaryObject = {...transactionPrimaryObject, [subappName]: {...transactionPrimaryObject[subappName], ...value}}
                    values[attributeName] = attributeValue;
                  }
                  obj = { pagelevelValues, transactionPrimaryObject, values}
                  deleteDependentAttribute(component, pagelevelValues,transactionPrimaryObject,props)

                return obj;
            }, async()=>{
                try{
                     if(Object.keys(component.Action).length>0 && (component.Action.timeDelay!==undefined && component.Action.timeDelay!== '' && typeof(Number(component.Action.timeDelay))==='number')){
                      await this.debounceCall(() => this.callbackAPI(value, component, isDynamicTable, datatableRowindex,props), component.Action.timeDelay)
                    }else{
                       await this.callbackAPI(value, component, isDynamicTable, datatableRowindex,props)
                    }
                }
                catch(e){
                    console.log("Error in HandleInputchangeV2", e)
                }
            })
        }
        else{
            await this.props.pageInfo.utilfunction[this.props.pageInfo.util].handleInputChangeV2(value, component, isDynamicTable, datatableRowindex,this.props, this.state,this.functions, props,this.cancelAPICall);
        }
    }
    copyValue = async (value, component, isDynamicTable, datatableRowindex) => {
        let statevalues =await this.props.pageInfo.utilfunction[this.props.pageInfo.util].copyValue(value, component, isDynamicTable, datatableRowindex,this.state,this.props);
        this.updateValues(statevalues);

    }

    handleInputChange = (event,component,props) => {
        console.log("coming inside handle input change",props,component,event)
        let attributeName = event.target.name;
        let attributeValue = event.target.value;

        this.setState((prevState) => {
          let values = Object.assign({}, prevState.values); // creating copy of state variable jasper
          values[attributeName] = attributeValue; // update the name property, assign a new value

          let subappName =
          component.SubApplicationName;
          let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
          // pagelevelValues[subappName][attributeName] = attributeValue;

          if (!pagelevelValues[subappName]) {
            pagelevelValues[subappName] = {};
          }
          pagelevelValues[subappName][attributeName] = attributeValue;
          console.log("values and pagelevel in handleinput change--->",values,pagelevelValues)
          return { values, pagelevelValues }; // return new object jasper object
        });
       }
    invokeGetPolicyCov = (stateVal, transactionPrimaryObject) => {
        this.props.showLoader()
        let request = {};
        request.ROOTID = this.props.metadataReducer.submissionResponse.SubmissionID;
        let stateList = this.props.metadataReducer.stateList
        if (stateList.length > 0) {
            for (let i = 0; i < stateList.length; i++) {
                if (stateList[i].State === stateVal) {
                    request["ID"] = stateList[i].ID
                }
            }
        }
        request.EventName = this.props.pageInfo.eventName;
        request.TargetEventName = this.props.pageInfo.getpolicyEventName;
        request.OwnerId = sessionStorage.getItem("OwnerID");
        request["ServiceRequestDetail"] = Object.assign(
            {},
            getServiceRequestDetail()
        );
        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
            .then((response) => {
                if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
                    console.log("Response Failed");
                    triggerToast("Response Failed");
                }else{
                let val = {}
                let responseTranslations = EntitySubAppTranslation(response.data, this.props, val, "StateResponse")
                Object.keys(responseTranslations).map((key) => {
                    if (this.state.transactionPrimaryObject.hasOwnProperty(key)) {
                        delete this.state.transactionPrimaryObject[key]
                    }
                })
                this.setState({
                    pagelevelValues: { ...this.state.pagelevelValues, ...responseTranslations },
                    transactionPrimaryObject: { ...this.state.transactionPrimaryObject, ...responseTranslations }
                }, () => {
                    this.state.transactionPrimaryObject.LocationDetail.State = stateVal
                    this.callback(stateVal)
                })
                }
            })
    }

    updateValueWithSubplicationName = (updatedValue, component) => {
        let keys = Object.keys(updatedValue);
        let SubapplicationName = component.SubApplicationName;
        let attributeName = keys[0];
        let attributeValue = updatedValue[attributeName];
        this.setState(prevState => {
            let detailValues = Object.assign({}, prevState.detailValues);
            let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
            let transactionPrimaryObject = Object.assign({}, prevState.transactionPrimaryObject);
            detailValues[attributeName] = attributeValue;

            if (!pagelevelValues[SubapplicationName]) {
                pagelevelValues[SubapplicationName] = {};
            }
            pagelevelValues[SubapplicationName] = Object.assign(
                {},
                prevState.pagelevelValues[SubapplicationName],
                updatedValue
            );

            if(this.props.rowData.ID || this.props.rowData.uid){
            if (!transactionPrimaryObject[SubapplicationName]) {
                transactionPrimaryObject[SubapplicationName] = {};
            }
            transactionPrimaryObject[SubapplicationName] = Object.assign(
                {},
                prevState.transactionPrimaryObject[SubapplicationName],
                updatedValue
            );
            transactionPrimaryObject[SubapplicationName][attributeName] = attributeValue;
            }
            // deleteDependentAttribute(component,pagelevelValues,transactionPrimaryObject)
            return {
                pagelevelValues, detailValues,
                transactionPrimaryObject
            }
        }, () => {

        });
    }
    handleHiddenInputChange = (component, isDynamicTable, datatableRowindex) => {
        let SubapplicationName = component.SubApplicationName;
        let attributeName = component.AttributeName;
        let attributeValue = component.AttributeValue || component.DefaultValue;
        this.setState(prevState => {
            let detailValues = Object.assign({}, prevState.detailValues);
            detailValues[attributeName] = attributeValue;
            let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
            if (typeof SubapplicationName !== 'string') {
                return;
            }
            if (!pagelevelValues[SubapplicationName]) {
                pagelevelValues[SubapplicationName] = {};
            }
            pagelevelValues[SubapplicationName][attributeName] = attributeValue || component.DefaultValue;
            return { detailValues,pagelevelValues }
        }, () => {

        });
    }
    callback = async(state) => {
        this.UpdateState("loading", true, true)
        let selectedState = state ? state : this.props.metadataReducer.primaryState
       await this.getMetadataRequest(selectedState);
        this.UpdateState("loading", false, true)
    }

    UpdateState = (statename, value, rerender) => {
        if (rerender) {
            this.setState({
                [statename]: value
            });
        } else {
            this.state[statename] = value;
        }
    }
    UpdateCallBack = async(callback, props, obj)=>{
        this.setState((state)=>{
          let newObj = callback(state, props, obj)
              return {
                ...state,
                ...newObj
              }
            });
        };
    onClickSave = (key, subname, value, expand, ID) => {
        this.updateArrayValueOnState(key, value, expand, ID, subname);
    }
    addRow = (key) => {
        let uid = uuidv4();
        this.updateArrayValueOnState(key, { uid, isExpanded: true }, true);
    }
    updateArrayValueOnState = async (key, value, expand, id, subname) => {
        let arr = [];
        value['isExpanded'] = expand;
        let { ID, uid } = value;
        let checkKey = ID ? 'ID' : uid ? 'uid' : null;

        if (this.state[key] !== undefined && Array.isArray(this.state[key])) {
            arr = [...this.state[key]];
        }
        if ((checkKey === 'ID' && ID !== undefined) || (checkKey === 'uid' && uid !== undefined)) {
            let updated = false;
            await arr.map((el, idx) => {
                if (el[checkKey] === value[checkKey]) {
                    arr[idx] = { ...value };
                    updated = true;
                }
            })
            if (updated === false) {
                arr.push(value);
            }
        } else {
            arr.push(value);
        }
        let pagelevelValues = Object.assign({}, this.state.pagelevelValues);
        if (subname) {
            pagelevelValues[subname] = arr;
        }
        pagelevelValues[key] = arr;

        this.setState({ [key]: arr, pagelevelValues });
    }

    deleteRow = (key, value) => {

        let { ID, uid } = value;
        let checkKey = ID ? 'ID' : uid ? 'uid' : null;


        if (!uid) {
            this.props.pageInfo.utilfunction[this.props.pageInfo.util].deleteRow(this.props, this.state, ID);
        }

        if (checkKey !== null) {
            this.setState(prevState => {
                let arr = prevState[key];
                arr.map((el, idx) => {
                    if(el.ID){
                        if (el.ID === ID) {
                        arr.splice(idx, 1);
                        return;
                        }
                    }

                    else{
                        if (el.uid === uid) {
                            arr.splice(idx, 1);
                            return;
                          }

                    }
                })
                return ({ [key]: arr });
            })
            defaultToast("Deleted Successfully")
        }
    }

    deleteRowApi = (ID, submissionID, EventName) => {
        let request = {};
        request.ROOTID = submissionID;
        request.ID = ID;
        request.EventName = EventName;
        request.OwnerId = this.props.clientInfo.OwnerID;
        request["ServiceRequestDetail"] = Object.assign(
            {},
            getServiceRequestDetail()
        );
        AxiosInstance.post("/core/invokeWorkflowEvent", request)
            .then((response) => {
            })
            .catch((err) => {
                console.log(err);
            });
    };

    onExpand = (key, value, isExpand) => {
        let { ID, uid } = value;
        let checkKey = ID ? 'ID' : uid ? 'uid' : null;

        if (checkKey !== null) {
            this.setState(prevState => {
                let arr = prevState[key];
                arr.map((el, idx) => {
                    if (el[checkKey] === value[checkKey]) {
                        arr[idx]['isExpanded'] = isExpand
                        return;
                    }
                })
                return ({ [key]: arr });
            })
        }
    }
    render() {
        let subapplicationList = [];

        if (this.state.pageMetadata.ApplicationDetail) {
            subapplicationList = this.state.pageMetadata.ApplicationDetail && this.state.pageMetadata.ApplicationDetail.SubApplicationDetailList;
        }

        let { util, utilfunction } = this.props.pageInfo

        const TableControls = {
            "tableUtils": {
                "AddRow": this.addRow,
                "onExpand": this.onExpand,
                "onChildSave": this.onClickSave,
                "EditRow": "",
                "OnDetailEdit": "",
                "redirectTo": ""
            },
            "TableOptions": {
                "ChildrenSubApplicationName": null,
                "ChildrenComponent": childComp,
                "AddButtonNeeded": true,
                "SortingNeeded": false,
                "FilteringNeeded": false,
                "FilterIconNeeded": false,
                "rowsPerPageOptions": [5, 10, 25],
                "RowsPerPage": 5,
                "ActionName": "Action",
                "ActionListOptions": [
                    {
                        "ActionName": "RemoveVehicle",
                        "ActionDisplayName": "Delete",
                        "OnOptionClick": this.deleteRow
                    }
                ]
            },
            "tableFunctions":{
                "AddRow": this.addRow,
                "onExpand": this.onExpand,
                "onChildSave": this.onClickSave,
                "deleteRow": this.deleteRow
            }
        }

        const functions = {
            updateValueWithSubplicationName: this.updateValueWithSubplicationName,
            handleHiddenInputChange: this.handleHiddenInputChange,
            handleInputChangeV2: this.handleInputChangeV2,
            UpdateState: this.UpdateState,
            UpdateCallBack: this.UpdateCallBack,
            handleInputChange: this.handleInputChange,
            handleAutoCompletechange: this.props.handleAutoCompletechange,
            handleDateChange: this.props.handleDateChange,
            invokeGetAccount: this.props.invokeGetAccount,
            callback: this.callback,
            copyValue: this.copyValue,
            updateValues: this.updateValues
        }

        return (
            <ValidatorForm
                id="child"
                onSubmit={e => {
                    this.handleSubmit(this.props.tableUtils.onChildSave)
                }}
                onError={e => this.executeScroll()}
                ref={this.childform}
            >
                <React.Fragment>

                <Grid item xs={10} sm={10} md={12} lg={12}>
                        <CardContent className="custom-margin-left">
                            <Grid container spacing={3}>
                                {
                                    this.state.isSummaryNeeded === false ?
                                        dynamicRender(subapplicationList, { ...this.state, ...this.props }, this.state, functions, TableControls, this.state.summarySubAppState, this.state.detailSummaryListState,false,this.childform)
                                        : null
                                }
                            </Grid>
                        </CardContent>
                    </Grid>


                    {
                        this.state.isSummaryNeeded ?
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <LocationSummary {...this.props} />
                            </Grid>
                            :
                            this.props.pageInfo.isSavebtn ?
                                <Grid item xs={9} sm={9} md={9} lg={12}>
                                    <Button id="Save" disabled={this.state.disableButton?true:false} onClick={() => this.childform.submit()} color="primary" className="nav-btn customSaveButton" >
                                    {/* style={styles.button} */}
                                        Save
                                    </Button>
                                    <br />
                                    <br />
                                </Grid>
                                : null
                    }
                    {console.log("this.props inside render in detailsummarylist--->",this.props)}
                    <CloneWithPropsHOC
                        currentProps = {this.props}
                        parentState = {this.state}
                        parentProps = {this.props.parentProps}
                        functions = {functions}
                        transform = {utilfunction[util].transform}>
                            {this.props.children}
                    </CloneWithPropsHOC>
                </React.Fragment>
                {this.state.loading && <PageLoader />}
            </ValidatorForm>
        )
    }
}

const mapStateToProps = state => (
    {
        metadataReducer: state.metadataReducer,
        navigationReducer: state.navigationReducer,
        clientInfo: state.ownerResources.clientInfo,
        UIFlowReducer: state.UIFlowReducer
    }
)

const mapDispatchToProps = (dispatch) => (
    {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        setprimaryState: (primaryState) => dispatch(setprimaryState(primaryState)),
        setStateList: (stateList) => dispatch(setStateList(stateList)),
        setShowHideCondition: (showHide) => dispatch(setShowHideCondition(showHide)),
        setIconcondition: (iconcondition) => dispatch(setIconcondition(iconcondition))
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetail);
