import React, {Fragment} from 'react';
import TablePagination from '@material-ui/core/TablePagination';

function DataTablePagination(props) {

    let { dataList, dataListSize, paginationProperties, TableOptions} = props;
    let { lazyLoading = false } = TableOptions;
    let tableSize = dataList.length;
    if(lazyLoading){
        tableSize = dataListSize;
    }

    return (
        <Fragment>
            <TablePagination
                rowsPerPageOptions={TableOptions.rowsPerPageOptions}
                component="div"
                count={tableSize}
                rowsPerPage={paginationProperties.rowsPerPage}
                page={paginationProperties.page}
                onChangePage={paginationProperties.changePage}
                onChangeRowsPerPage={paginationProperties.changeRows} 
            />
        </Fragment>
    )
}

export default DataTablePagination;
