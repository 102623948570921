import { expressServerBaseURL } from '../views/runtime/utils/Common/config';
import axios from 'axios';
import download from 'downloadjs';
import {triggerToast} from '../views/runtime/utils/Common/HelperUtils';
import { getMimeType } from '../views/runtime/utils/Common/API';
import { model_PDFGenerateRequest } from '../views/runtime/models/generatePDFRequest';
import { AxiosInstance } from '../views/runtime/utils/Common/API';

const generatePDF = async (filePath,RowData) => {
  try {
    let xmlRequest = model_PDFGenerateRequest;
    xmlRequest = xmlRequest
    .replace("{USER_NAME}", localStorage.getItem("userName"))
    .replace("{FORM_LOCATION}", filePath)
    let request = {}
    request.xmlRequest = xmlRequest
    let response = await AxiosInstance.post('core/generatePDF', request)
    return response
    } catch (error) {
      console.log('PDF Conversion Error', error)
     }
  }

export const FileHandlerAxios = async (rootPath) => {

  try {
    let extension = rootPath.substr(rootPath.lastIndexOf('.') + 1);
    let mimetype= getMimeType(extension);

    getFileContentAxios(rootPath).then(response =>{


      const file = new Blob([response.data], {
        type: mimetype
      });
      //Support for IE Browser
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        console.log("IE detected");
        window.navigator.msSaveOrOpenBlob(file,"Myfile.pdf");
      }
      // Support for Chrome & Firefox version
      else if (navigator.userAgent.indexOf('Chrome') != -1 || navigator.userAgent.indexOf('Firefox') != -1) {
        const fileURL = URL.createObjectURL(file);
        const w = window.open(fileURL, '_blank');
        w && w.focus();
      } else {
        // Safari & Opera iOS
        const fileURL = URL.createObjectURL(file);
        window.location.href = fileURL;
      }

    })

  } catch (err) {
    throw new Error(err);
  }
}

export const FileHandlerAxiosV2 = async (rootPath,rowData) => {

  try {
    let extension = rootPath.substr(rootPath.lastIndexOf('.') + 1);

    if (extension.toLowerCase() === 'docx' || extension.toLowerCase() === 'xsl') {
      let response = await generatePDF(rootPath, rowData)
        if (response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === 'failed') {
          triggerToast('Error in GetDocument')
        } else {
          const file = response.data
          //Support for IE Browser
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, "Myfile.pdf");
          }
          // Support for Chrome & Firefox version
          else if (navigator.userAgent.indexOf('Chrome') != -1 || navigator.userAgent.indexOf('Firefox') != -1) {
            const w = window.open(file, '_blank');
            w && w.focus();
          } else {
            // Safari & Opera iOS
            window.location.href = file;
          }
        }
    } else {
      let mimetype= getMimeType(extension);
    getFileContentAxios(rootPath).then(response =>{


      const file = new Blob([response.data], {
        type: mimetype
      });
      //Support for IE Browser
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        console.log("IE detected");
        window.navigator.msSaveOrOpenBlob(file,"Myfile.pdf");
      }
      // Support for Chrome & Firefox version
      else if (navigator.userAgent.indexOf('Chrome') != -1 || navigator.userAgent.indexOf('Firefox') != -1) {
        const fileURL = URL.createObjectURL(file);
        const w = window.open(fileURL, '_blank');
        w && w.focus();
      } else {
        // Safari & Opera iOS
        const fileURL = URL.createObjectURL(file);
        window.location.href = fileURL;
      }

    })
  }

  } catch (err) {
    throw new Error(err);
  }
}

export const downloadFile = async (filename,rootPath) => {
  try {
    let extension = filename.substr(filename.lastIndexOf('.') + 1);
    let mimetype= getMimeType(extension);

    getFileContentAxios(rootPath).then(response => {
      download(response.data, filename, mimetype);
    })

  } catch (err) {
    throw new Error(err);
  }
}

export const getFileContentAxios = async (rootPath) => {
  console.log("UI get check for asx0",rootPath);
  return await axios(`${expressServerBaseURL}filehandler/getFileContent?rootPath=${rootPath}`,
  { method : "GET", responseType : "blob"});

}
