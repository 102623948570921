import React from 'react';
import { Grid, Skeleton } from "@mui/material";

const Skeletons = (props) => {
  let {component, component: {Action} } = props;

  let variant = (Action.Skeleton && variant) || "h3"
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={Number(component.Size) || "auto"}
      lg={Number(component.Size) || "auto"}
      key={component.AttributeName}
      className={`${component.AttributeStyleClass}`}
    >
       <Skeleton variant={variant} />
    </Grid>
  );
};

export default Skeletons;
