import React from 'react';
import { FormControl, Grid, InputLabel ,Typography} from "@material-ui/core";
import SelectDropdownValidatorV2 from "./SelectValidatorV2";

function ActionSelect(props) {

    let {subAppInfo, isDisabled, tableFunctions} = props;

    const { component,componentProps,componentState,state,parentProps,functions,isDynamicTable} = props;
    let actionListOptionsList=component.Action.actionmenu || [];
    const stateObj = componentState || parentProps;
    const propsObj = componentProps || state;
    let rowData=props.pagelevelValues.AvailableMarket;
    let options = actionListOptionsList.map((option) =>{
        return option
    })
    let attributeValue = component.DefaultValue


    const onChange = (e) => { 
        actionListOptionsList.map((option)=>{
           if(e.target.value == option.Value)
           tableFunctions.hasOwnProperty(option.OnOptionClick) && tableFunctions[option.OnOptionClick](option.EventName, propsObj, stateObj, component,functions,e,props,option,rowData);
          })
    }

    return (
        <>
        <Grid
          className={component.StyleClass+ 'lineHeight custom-selectContainer'}
          item
          xs={12}
          sm={12}
          md={isDynamicTable ? 12 : component.Size}
          lg={isDynamicTable ? 12 : component.Size}
          key={component.AttributeName}
        >
          <FormControl
            className={`custom-dropdown custom-dropdown-select custom-dropdown-selectv2 ${component.AttributeStyleClass} `}
          >
            <SelectDropdownValidatorV2
              ComponentId={component.ComponentId}
              name={component.AttributeName}
              options={options}
              value={attributeValue}
              component={component}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
         </>
      );
}

export default ActionSelect;
