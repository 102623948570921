import * as RiskScheduleUtils from "./Common/RiskScheduleUtils";
import * as SelectUtil from "./Common/SelectUtil"
import { submission } from "./Common/DisplayUtils";
import { parent, child } from "./Common/TabUtil.js";
import { HOCUtil } from "./Common/DynamicHOC_Utils";
import * as PopupOptionInfo from "../../../views/runtime/controls/Popup/PopupOptionsInfo"
import * as DashboardUtil from "./Common/DashboardUtil"
import {PageHeaderUtil} from "./Common/PageHeaderUtils.js";
import container from "./DIContainer.js";


export const Utils = {
    RiskScheduleUtils,
    SelectUtil,
    submission,
    HOCUtil,
    PopupOptionInfo,
    parent,
    child,
    DashboardUtil,
    PageHeaderUtil
}


export const ProductNavigationKeys =['utilfunction','selectUtil', 'tabUtil','popupDetail']

// Register Utilities in the DI Container
container.register('Utils',Utils)
container.register('ProductNavigationKeys',ProductNavigationKeys)

// export default container
