import React, { Fragment, useState, useEffect } from 'react';
import FileImport from './FileImport';
import FileExport from './FileExport';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const FileImportExport = (props) => {

    const { t } = useTranslation();

    const UIFlowReducer = useSelector((state) => {
        return state.UIFlowReducer
    });
    let exportType = UIFlowReducer.exportType;

    const { triggerImport=false, triggerExport=false } = UIFlowReducer;

    const [importDialogue, setimportDialogue] = useState(false);
    const [exportDialogue, setexportDialogue] = useState(false);
    const { importExportProperties } = props.pageInfo;
    let { ImportCallback, ImportOnlyNeeded = false, ExportOnlyNeeded = false } = importExportProperties;
    if(exportType && importExportProperties["ExportDetails"]){
        ExportOnlyNeeded  = importExportProperties["ExportDetails"][exportType]['ExportOnlyNeeded'];
    }

    useEffect(() => {
        if(triggerImport) {
            handleImportOnClick();
        }

        if(triggerExport){
            handleExportOnClick();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerImport, triggerExport])

    const handleImportOnClick = () => {
        ImportCallback && ImportCallback(false);setimportDialogue(true)
    }

    const handleExportOnClick = () => {
        setexportDialogue(true)
    }

    return (
        <Fragment>
            {/* <span>
                {
                    ImportOnlyNeeded &&
                    <Tooltip title={ImportTooltip}>
                        <IconButton
                            aria-label="Import"
                            className="custom-icon-button"
                            component="span"
                            onClick={() => setimportDialogue(true)}
                        >
                            <Publish />
                        </IconButton>
                    </Tooltip>
                }
                {
                    ExportOnlyNeeded &&
                    <Tooltip title={ExportTooltip}>
                        <IconButton
                            aria-label="Export"
                            className="custom-icon-button"
                            component="span"
                            onClick={() => setexportDialogue(true)}
                        >
                            <GetApp />
                        </IconButton>
                    </Tooltip>
                }
            </span> */}
            {
                <span>
                    {
                        ImportOnlyNeeded && 
                        <Button
                            type="button"
                            variant="contained" 
                            style={{ margin:'1rem'}}
                            className="primaryButton"
                            onClick = {handleImportOnClick}
                        >
                            {t('Import')}
                        </Button>
                    }
                    {
                        ExportOnlyNeeded &&
                        <Button
                            type="button"
                            variant="contained" 
                            style={{margin:'1rem'}}
                            className="primaryButton"
                            onClick = {handleExportOnClick}
                            >
                            {t('Export')}
                        </Button> 
                    }
                </span>
            }
            {
                importDialogue &&
                <FileImport
                    open={importDialogue}
                    closeDialog={setimportDialogue}
                    importExportProperties={importExportProperties}
                    navigation = {props.navigation}
                />
            }
            {
                exportDialogue &&
                <FileExport
                    open={exportDialogue}
                    closeDialog={setexportDialogue}
                    importExportProperties={importExportProperties}
                />
            }
        </Fragment>
    )
}


export default FileImportExport;
