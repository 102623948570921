import { constructTransactionRequest,constructTransactionRequestV1 } from "../requestConstruction/DynamicReqConstruction";
import { commonProductAxiosInstance } from "./API";
import * as Action from "../../../../actions/types"
import store from "../../../../store";
import {getServiceRequestDetail,GetCommonEventName} from "./API";
import { EntitySubAppTranslationV2 } from "../requestConstruction/ResponseTranslation";
import { getInsured } from "./CommonUtils";
import { mergeResponseKeys } from "./RiskScheduleUtils";
import { triggerToast } from "./HelperUtils";
import { constructAndInvokeMetadataCall } from "./MetaUtils";


export const saveInsured_Homeowners=async(state,props,pagedetail,UpdateState)=>{

    try{
		UpdateState("loading",true, true)
        let request= await constructTransactionRequestV1( state,props,{...state.pagelevelValues},false,true,false,false);
        let {Insured} = request
        // request = await mergeResponseKeys(props.pageNavigation[state.currentPageIndex].SubApplicationName,state.pagelevelValues,request)
        request.OwnerId = sessionStorage.getItem("OwnerID");
        request.ServiceRequestDetail = getServiceRequestDetail()
        Insured.FirstName1 = Insured.FirstName1 && Insured.FirstName1.trim()
        Insured.LastName2 = Insured.LastName2 && Insured.LastName2.trim()
        Insured.PrincipalFirstName = Insured.PrincipalFirstName && Insured.PrincipalFirstName.trim()
        Insured.PrincipalLastName = Insured.PrincipalLastName && Insured.PrincipalLastName.trim()
        let data={
            Insured
        }
        request={...request,...data}
        request.EventName=GetCommonEventName();
        let insuredFullName;
        if(Insured.FirstName1 && Insured.LastName2){
            insuredFullName  = `${request.Insured.FirstName1.trim()||""} ${request.Insured.LastName2.trim()||""}`
        }else if(Insured.PrincipalFirstName && Insured.PrincipalLastName){
            insuredFullName  =  `${request.Insured.PrincipalFirstName.trim()||""} ${request.Insured.PrincipalLastName.trim()||""}`
        }
        store.dispatch({type: Action.SET_INSUREDNAME, payload: insuredFullName});
      let  response = await saveInsured(state,props,request)
      if(props.pageInfo.dynamicFlow && props.UIFlowReducer.pageData && !props.UIFlowReducer.pageData.submissionID){
        store.dispatch({ type: Action.SET_UI_VALUES, payload: {pageData:{...props.UIFlowReducer.pageData,submissionID:response.data.ID}}});
      }else if(!props.UIFlowReducer.submissionID){
        store.dispatch({type: Action.SET_SUBMISSIONID,payload: response.data.ID});
      }
      if(!props.UIFlowReducer.insuredID){
        await store.dispatch({type: Action.SET_INSUREDID, payload: response.data.ID});
        }//Need Confirmation from Ajay to remove this line.
        store.dispatch({type: Action.SET_INSUREDNAME, payload: response.InsuredName});
        if(response.data.ProcessStatus && response.data.SubmissionStatus){
            await  store.dispatch({type: Action.SET_PROCESS_STATUS, payload: response.data.ProcessStatus});
            await  store.dispatch({type: Action.SET_SUBMISSION_STATUS, payload: response.data.SubmissionStatus});
            UpdateState("pagelevelValues", {...state.pagelevelValues, insuredFullName, ...response.data}, true)
            }
        UpdateState("pagelevelValues", {...state.pagelevelValues, insuredFullName}, false)
        store.dispatch({type: Action.SET_PAGEHEADER_UPDATE,payload: true});
        UpdateState("loading",false, true)
        return response;
    }
    catch(error){
        UpdateState("loading",false, true)
        console.log("Error in SaveSubmission Homeowners",error);
    }
}


const saveSubmission_Homeowners_RateIndication=async(state,props,pagedetail,UpdateState)=>{

    try{
		UpdateState("loading",true, true)
        let request= await constructTransactionRequestV1( state,props,{...state.pagelevelValues},false,true,false,false);
        let {Insured} = request
        request = await mergeResponseKeys(props.pageNavigation[state.currentPageIndex].SubApplicationName,state.pagelevelValues,request)
        request.OwnerId = sessionStorage.getItem("OwnerID");
        request.ServiceRequestDetail = getServiceRequestDetail()
        Insured.FirstName1 = Insured.FirstName1 && Insured.FirstName1.trim()
        Insured.LastName2 = Insured.LastName2 && Insured.LastName2.trim()
        Insured.PrincipalFirstName = Insured.PrincipalFirstName && Insured.PrincipalFirstName.trim()
        Insured.PrincipalLastName = Insured.PrincipalLastName && Insured.PrincipalLastName.trim()
        let data={
            Insured
        }
        request={...request,...data}
        request.EventName=GetCommonEventName();
        let insuredFullName;
        if(Insured.FirstName1 && Insured.LastName2){
            insuredFullName  = `${request.Insured.FirstName1.trim()||""} ${request.Insured.LastName2.trim()||""}`
        }else if(Insured.PrincipalFirstName && Insured.PrincipalLastName){
            insuredFullName  =  `${request.Insured.PrincipalFirstName.trim()||""} ${request.Insured.PrincipalLastName.trim()||""}`
        }
        store.dispatch({type: Action.SET_INSUREDNAME, payload: insuredFullName});
      let  response = await saveInsured(state,props,request)
      store.dispatch({type: Action.SET_INSUREDNAME, payload: response.InsuredName});
      if(!props.UIFlowReducer.submissionID){
        await store.dispatch({type: Action.SET_SUBMISSIONID,payload: response.data.ID});
        }
      if(response.data.ProcessStatus && response.data.SubmissionStatus){
        await  store.dispatch({type: Action.SET_PROCESS_STATUS, payload: response.data.ProcessStatus});
        await  store.dispatch({type: Action.SET_SUBMISSION_STATUS, payload: response.data.SubmissionStatus});
        UpdateState("pagelevelValues", {...state.pagelevelValues, insuredFullName}, true)
        }
        // props.setexpandPanelData(true)
        UpdateState("loading",false, true)
    }
    catch(error){
        UpdateState("loading",false, true)
        console.log("Error in SaveSubmission Homeowners",error);
    }
}


const saveInsured_Mpl=async(state,props,pagedetail,UpdateState)=>{

    try{
		props.showLoader();
        let request= await constructTransactionRequest(state,props,{...state.pagelevelValues},true,false);
        request.AdditionalInsuredDetail = state.SelectedAddnlInsured_dataList;
        delete request["AdditionalInsuredWrapper"];
        request.EventName=GetCommonEventName();
        let insuredFullName = request.Insured.InsuredName
        store.dispatch({type: Action.SET_INSUREDNAME, payload: insuredFullName});
        let response = await saveInsured(state,props,request)
        if(!props.UIFlowReducer.insuredID){
            await store.dispatch({type: Action.SET_INSUREDID, payload: response.data.InsuredID});
        }
        UpdateState("pagelevelValues", {...state.pagelevelValues, ...response.data}, true)
        props.setexpandPanelData(true)
    }
    catch(error){
        console.log("Error in SaveInsured Homeowners",error);
    }
}
const saveInsured_CL=async(state,props,pagedetail,UpdateState)=>{

    try{
		UpdateState("loading",true, true)
        let request= await constructTransactionRequestV1( state,props,{...state.pagelevelValues},false,true,false,false);
        let {Insured} = request
        request = await mergeResponseKeys(props.pageNavigation[state.currentPageIndex].SubApplicationName,state.pagelevelValues,request)
        request.OwnerId = sessionStorage.getItem("OwnerID");
        request.ServiceRequestDetail = getServiceRequestDetail()
        Insured.FirstName1 = Insured.FirstName1 && Insured.FirstName1.trim()
        Insured.LastName2 = Insured.LastName2 && Insured.LastName2.trim()
        Insured.PrincipalFirstName = Insured.PrincipalFirstName && Insured.PrincipalFirstName.trim()
        Insured.PrincipalLastName = Insured.PrincipalLastName && Insured.PrincipalLastName.trim()
        let data={
            Insured
        }
        request={...request,...data}
        request.EventName="SolartisCLCommonMaster";
        let insuredFullName;
        if(Insured.FirstName1 && Insured.LastName2){
            insuredFullName  = `${request.Insured.FirstName1.trim()||""} ${request.Insured.LastName2.trim()||""}`
        }else if(Insured.PrincipalFirstName && Insured.PrincipalLastName){
            insuredFullName  =  `${request.Insured.PrincipalFirstName.trim()||""} ${request.Insured.PrincipalLastName.trim()||""}`
        }
        store.dispatch({type: Action.SET_INSUREDNAME, payload: insuredFullName});
      let  response = await saveInsuredDetail_CL(state,props,request)
      if(!props.UIFlowReducer.insuredID){
        await store.dispatch({type: Action.SET_INSUREDID, payload: response.data.ID});
        }
        UpdateState("pagelevelValues", {...state.pagelevelValues, insuredFullName}, false)
        props.setexpandPanelData(true)
        UpdateState("loading",false, true)
    }
    catch(error){
        UpdateState("loading",false, true)
        console.log("Error in SaveInsured CL",error);
    }
}
const saveInsured = async(state,props,request) => {
    try{
    const currentPageIndex  = state.currentPageIndex;
    let saveEventName= props.pageNavigation[currentPageIndex].saveeventName;
    request.TargetEventName=saveEventName;
    if(props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.submissionID){
        request.SubmissionID=props.UIFlowReducer.pageData.submissionID;
    }else if(props.UIFlowReducer && props.UIFlowReducer.submissionID){
        request.SubmissionID=props.UIFlowReducer.submissionID;
    }
    else{
        request.ID="";
    }
    request.SelectedLineOfBusiness = [props.productDetails];
    request.AgencyId=props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.hasOwnProperty('agencyID') ? props.UIFlowReducer.pageData.agencyID : props.metadataReducer.AgencyInformation.ID;
    request.AgentId=props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.hasOwnProperty('agentID') ? props.UIFlowReducer.pageData.agentID : props.metadataReducer.AgentInformation.ID;
    request.AgencyNumber=props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.hasOwnProperty('agencyNumber') ? props.UIFlowReducer.pageData.agencyNumber : props.metadataReducer.AgencyInformation.AgencyNumber;
    request.AgentNumber=props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.hasOwnProperty('agentNumber') ? props.UIFlowReducer.pageData.agentNumber : props.metadataReducer.AgentInformation.AgentNumber;
    if(props.pageNavigation[currentPageIndex].isExternalInfoNeeded){
        if (Object.keys(props.UIFlowReducer.externalInfo).length > 0) {
            let {externalInfo} = props.UIFlowReducer;
            request = {...request,...externalInfo}
        }
    }
    if(request.InsuredAddress){
        await store.dispatch({type: Action.SET_INSUREDADDRESS, payload: request.InsuredAddress});
        }
    const response= await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    store.dispatch({type: Action.SET_SUBMISSION_DATA, payload: response.data});
    store.dispatch({type: Action.SET_SUBMISSION_TYPE, payload: response.data.SubmissionType})
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
        triggerToast("Unable to save Submission details");
        console.log('Response Failed')
    }
    props.hideLoader();
    return response;
    }
    catch(e){
        console.log('Error in saveInsured::', e);
        triggerToast("Unable to save Submission details");
    }
}

const saveInsuredDetail_CL = async(state,props,request) => {
    const currentPageIndex  = state.currentPageIndex;
    let saveEventName= props.pageNavigation[currentPageIndex].saveeventName;
    request.TargetEventName=saveEventName;
    if(props.UIFlowReducer.insuredID){
        request.ID=props.UIFlowReducer.insuredID;
        request["ROOTID"]=props.UIFlowReducer.insuredID;
    }
    else{
        request.ID="";
    }
    request.SelectedLineOfBusiness = [props.productDetails]
    request.AgencyId=props.metadataReducer.AgencyInformation.ID;
    request.AgentId=props.metadataReducer.AgentInformation.ID;
    request.AgencyNumber=props.metadataReducer.AgencyInformation.AgencyNumber;
    request.AgentNumber=props.metadataReducer.AgentInformation.AgentNumber;
    if(request.InsuredAddress){
        await store.dispatch({type: Action.SET_INSUREDADDRESS, payload: request.InsuredAddress});
        }
    const response= await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    store.dispatch({type: Action.SET_SUBMISSION_DATA, payload: response.data});
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
        triggerToast("Unable to save Insured details");
        console.log('Response Failed')
    }
    props.hideLoader();
    return response;
}
const getInsured_Mpl=async(state,props,func)=>{
    getInsured(state,props,func,props.UIFlowReducer.insuredID);
}
export const getInsured_Homeowners=async(state,props,func)=>{
    getInsured(state,props,func,props.UIFlowReducer.insuredID,props.UIFlowReducer);
}
const getInsured_CL=async(state,props,func)=>{
    getInsured_CommercialLines(state,props,func,props.UIFlowReducer.insuredID);
}
export const getAgencyAgent=async(state,props,func,stateUpdateNeeded=true)=>{
    try{
        if (!(props.UIFlowReducer && ((props.UIFlowReducer.pageData && props.UIFlowReducer.pageData.agentID) || props.UIFlowReducer.agentID))
        )
    return;
    if(stateUpdateNeeded)
        func('loading',true,true)
    let getRequest={};
    let agencyAgentDtl = {}
    let { ownerResources } = store.getState()
    getRequest["ServiceRequestDetail"] = await Object.assign({}, getServiceRequestDetail());
    getRequest.OwnerId = sessionStorage.getItem("OwnerID");
    getRequest.EventName = ownerResources.clientInfo.GetAgentAgencyEvent ? ownerResources.clientInfo.GetAgentAgencyEvent : "GetAgentAndAgencyDetails";
    const { UIFlowReducer, metadataReducer } = props;
    getRequest.ID = (UIFlowReducer.pageData && UIFlowReducer.pageData.agentID) || (metadataReducer.AgentInformation && metadataReducer.AgentInformation.ID) || UIFlowReducer.agentID;
    let EventVersion = props.pageInfo.eventVersion? props.pageInfo.eventVersion: "";
    const getAgenyResponse = await commonProductAxiosInstance.post(`core/invokeWorkflowEvent/${EventVersion}`, getRequest);

    // getAgenyResponse.data = {
    //     Agency:{
    //         "Name":"McKenzie Insurance Agency",
    //         "AgencyNumber":"AGENT123",
    //         "EmailAddress":"cmckenzie@solartis.com",
    //         "Address":"123 Nagar",
    //         "PhoneNumber":"570-878-2111",
    //         "Fax":"987456321",
    //         "Status":"Valid",
    //         "PrimaryContact":"Mahi",
    //         "ContactType":"FAX",
    //         "ID":"057e9984-c9b1-4ae1-889f-5650cb62bd39"
    //     },
    //     Agent:{
    //         "FirstName1":"Max",
    //         "StateRegionCode":"NY",
    //         "Type":"Yes",
    //         "Number":"123" ,
    //         "EmailAddress":"Max@solartis.com",
    //         "PhoneNumber":"987456321",
    //         "AgentStatus":"Valid",
    //         "ID":"8abdcbb0-1d3f-47a2-8201-3dc12766cd3e"
    //     }
    // }

    agencyAgentDtl["AgentInformation"] = getAgenyResponse.data.Agent;
    agencyAgentDtl["AgencyInformation"] = getAgenyResponse.data.Agency;
    if (getAgenyResponse.data.Agency.AgencyLookUpName)
        agencyAgentDtl["AgencyInformation"]['Name'] = getAgenyResponse.data.Agency.AgencyLookUpName
    if (getAgenyResponse.data.Agent.AgentLookUpName)
        agencyAgentDtl["AgentInformation"]['AgentName'] = getAgenyResponse.data.Agent.AgentLookUpName
    func("pagelevelValues", {...state.pagelevelValues, ...agencyAgentDtl})
    func("transactionPrimaryObject", {...state.transactionPrimaryObject, ...agencyAgentDtl})
    Object.assign(state.pagelevelValues,{...agencyAgentDtl})
    if(stateUpdateNeeded)
        func('loading',false,true)
    return getAgenyResponse.data
    }catch(err){
        console.log('Error in getAgencyAgent',err)
    }
}

export const getInsured_CommercialLines=async(state,props,func,ID, GetEventName = "SolartisCLCommonMaster")=>{
    try{
    func('loading',true,true)
    const currentPageIndex  = state.currentPageIndex;
    let request=Object.create({});
    request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName=props.pageNavigation[currentPageIndex].geteventName;
    request.ID=ID;
    request.EventName= GetEventName;
    let subApplication=props.pageNavigation[currentPageIndex].SubApplicationName;
    delete subApplication.InsuredSummary;
    let {ObjectId:objectId,ObjectVerId:objectVerId}=props.pageNavigation[currentPageIndex];
    const response=await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    let Entities=await constructAndInvokeMetadataCall({subappName:subApplication,objectId,objectVerId})
    let responseFromTranslation=await EntitySubAppTranslationV2({...response.data},{...Entities.ApplicationDetail});
    const mapEntity=await constructAndInvokeMetadataCall({subappName:subApplication,objectId,objectVerId},{transactionPrimaryObject:{...state.transactionPrimaryObject,...responseFromTranslation}})
    await func("pagelevelValues",{...state.pagelevelValues,...responseFromTranslation},false);
    await func("pageMetadata",mapEntity,false);
    await func("transactionPrimaryObject",{...state.pagelevelValues,...responseFromTranslation},true);
    func('loading',false,true)
    return {mapEntity,response, responseFromTranslation};
    }
    catch(error){
        func('loading',false,true)
        console.log("Error in getInsured",error);
    }
}


export const getSubmission_Homeowners=async(state,props,func,ID, GetEventName = "OrchidCommonMaster")=>{
    try{
    const currentPageIndex  = state.currentPageIndex;
    let request=Object.create({});
    console.log('submissionID Azeem',ID);
    request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName=props.pageNavigation[currentPageIndex].getSubmissioneventName;
    request.EventName= GetEventName;
    request["ID"] = ID
    request["PARENTID"] = ID
    request["ROOTID"] = ID
    let subApplication=props.pageNavigation[currentPageIndex].SubApplicationName;
    delete subApplication.InsuredSummary;
    let {ObjectId:objectId,ObjectVerId:objectVerId}=props.pageNavigation[currentPageIndex];
    let params={transactionPrimaryObject:state.transactionPrimaryObject};
    const response= await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request)
    if(!props.UIFlowReducer.SubmissionNumber && response.data && response.data.SubmissionNumber){
        await store.dispatch({type: Action.SET_SUBMISSION_NUMBER, payload: response.data.SubmissionNumber});
    }
    const mapEntity = await constructAndInvokeMetadataCall({subappName:subApplication,objectId,objectVerId},params)
    let responseFromTranslation=await EntitySubAppTranslationV2({...response.data},{...mapEntity.ApplicationDetail});
    await func("pagelevelValues",{...state.pagelevelValues,...responseFromTranslation},false);
    await func("transactionPrimaryObject",{...state.pagelevelValues,...responseFromTranslation},true);
    props.setexpandPanelData(true)
    return {mapEntity,response, responseFromTranslation};
    }
    catch(error){
        console.log("Error in getInsured",error);
    }
}


export const PL={
    "saveInsured_Homeowners":saveInsured_Homeowners,
    "getInsured_Homeowners":getInsured_Homeowners,
    "saveSubmission_Homeowners_RateIndication":saveSubmission_Homeowners_RateIndication
}
export const MPL={
    "saveInsured_Mpl":saveInsured_Mpl,
    "getInsured_Mpl":getInsured_Mpl
}
export const CL={
    "saveInsured_CL":saveInsured_CL,
    "getInsured_CL":getInsured_CL
}
