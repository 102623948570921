import React, {  Component, Suspense } from 'react'
import { connect } from 'react-redux'
import * as router from "react-router-dom";
import { AppSidebar, AppSidebarHeader, AppSidebarForm, AppSidebarFooter, AppSidebarMinimizer } from '@coreui/react'
import AppSidebarNav from "../../../../../containers/DefaultLayout/SideNavbar";
import { metadataAxios } from "../../../utils/Common/API";
import { resetLoadingCounter, setFindConsoleInfo } from '../../../../../actions/UIFlowActions';

const initialState = {
  currentPageIndex: 1
}

class Sidebar extends Component {
    constructor(props){
        super(props);
        this.state = {...initialState}
    }

    constructMetdataRequest = () => {
      let request = require("../../../../../views/runtime/models/metadataRequest.json");
      return request;
    };

    handleNodeSelect = (index, subApplicationNameList) => {
      if (false) {
        this.props.setCurrentIndex(index);
        let request = this.constructMetdataRequest();

        let subAppList = [];
        subApplicationNameList.map((name) => {
          subAppList.push({
            SubApplicationName: name,
          });
        });
        request.SubApplicationNameList = subAppList;

        console.log("request->", request);
        let transactionKeyList = [];
        transactionKeyList.push({
          key: "Object::Quote::EffectiveDate",
          value: "2020-07-01",
        });
        Object.keys(this.props.metadataState.values).forEach((key) => {
          transactionKeyList.push({
            key: key,
            value: this.props.metadataState.values[key],
          });
        });
        // this.props.setMetadataRequest(request);
        metadataAxios.post("core/getmetadata", request).then(
          (response) => {
            console.log("bini-> ", response.data);
            this.props.setPageMetadata(response.data);
            // this.props.setMetadataResponse(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };

    updateFindConsoleInfo = (selectedFindItem) => {
      // this.props.setFindView(view);
      this.props.resetLoadingCounter();
      this.props.setfindConsoleInfo(selectedFindItem);
    };

    updateQuoteSummaryView = (index, view, workflowType, subApplicationName, ObjectId, ObjectVerId) => {
      this.props.setQuoteSummaryView(index, view, workflowType, subApplicationName, ObjectId, ObjectVerId);
    };

    render() {
        let component = []
        return(
              <AppSidebar fixed display="lg" className = "theme_color">
                <AppSidebarHeader />
                <AppSidebarForm />
                <Suspense>
                  { this.props.navigationReducer.navigation.hasOwnProperty("pageNavigation") &&
                    Object.keys(this.props.navigationReducer.navigation.pageNavigation).map((key) => {
                       let currentPageIndex= this.props.UIFlowReducer.currentPageIndex;
                      if(key.toString() === this.props.location.pathname.slice(1).split('/')[0].toLowerCase())
                      {
                        component.push (
                          <AppSidebarNav
                            key={key}
                            currentPageIndex={currentPageIndex}
                            navConfig={this.props.navigationReducer.navigation.pageNavigation[key].sidebar}
                            location = {this.props.location}
                            // {...this.props}
                            handleNodeSelect={this.handleNodeSelect}
                            updateFindConsoleInfo={this.updateFindConsoleInfo}
                            updateQuoteSummaryView={this.updateQuoteSummaryView}
                            router={router}
                          />
                        )
                      }
                    })
                  }
                  {(component)}
                </Suspense>
                <AppSidebarFooter />
                <AppSidebarMinimizer className="hide" />
              </AppSidebar>
        )

    }
}

const mapStateToProps = (state) => {
  return {
    UIFlowReducer: state.UIFlowReducer,
    navigationReducer: state.navigationReducer
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      resetLoadingCounter: () => dispatch(resetLoadingCounter()),
      // // setMetadataRequest: (request) =>dispatch(setMetadataRequest(request)),
      // //setMetadataResponse: (response) => dispatch(setMetadataResponse(response)),
      setfindConsoleInfo: (findConsoleInfo) => dispatch(setFindConsoleInfo(findConsoleInfo)),
        };
};



export default connect(mapStateToProps, mapDispatchToProps) (Sidebar);
