import React, { Component } from 'react';
import { Grid, Typography,InputAdornment, InputLabel} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CryptoJS from 'crypto-js';
import ErrorIcon from '@mui/icons-material/Error';
import store from '../../../store';

class Password extends Component {
    constructor(props) {
        super(props);
        this.state = { value:{},showPassword: false,showVisible:false}
        this.labelRef = React.createRef();
      }
    componentDidMount(){
        this.InfoList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.componentvalue !== this.props.componentvalue) {
          this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.componentvalue }, this.props.component, this.props, prevProps);
        }
      }

    encryptPassword = (valueArray) => {
        let encryptedPassword=[];
        let finalencryptedpass=[],temp;
        let { ownerResources } = store.getState();
        let obj ={};
        valueArray.map((value,index) => {
          encryptedPassword[index]= CryptoJS.AES.encrypt(value[1], CryptoJS.enc.Utf8.parse(ownerResources.clientInfo.AES_Password_Encrypt), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          });
          obj[value[0]] =encryptedPassword[index].toString();
          finalencryptedpass[index]=obj;
          obj={}
        })
        finalencryptedpass.map((obj) => {
          temp={...temp,...obj}
      })
      return temp
      }

    decryptPassword =(encryptedValue)=>{
      let { ownerResources } = store.getState();
      let decrypted = CryptoJS.AES.decrypt(encryptedValue,CryptoJS.enc.Utf8.parse(ownerResources.clientInfo.AES_Password_Encrypt),{
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      let decryptedValue = decrypted.toString(CryptoJS.enc.Utf8)
      return decryptedValue
    }

    onChange =  (e) => {
            let value = Object.assign({}, { [e.target.name]: e.target.value });
            let a = Object.entries(value);
            let encryptedData = this.encryptPassword(a);
            this.props.handleInputChangeV2(encryptedData, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
    }

    UpdateState = (statename, value, rerender) =>{
        if(rerender)
        {
          this.setState({
            [statename] : value
          });
        }else{
          this.state[statename] = value;
        }
    }

    InfoList = () => {
            // info icon static data
            let component=this.props.component;
            let itemparams = component.ItemTipParams.split(",");
            let value = [];
            itemparams.forEach((itemparam, index) => {
                    value[index]=itemparam;
            })
            this.UpdateState("infodata",value,false);
    }

    hideInfoList=(flag) => {
        this.UpdateState("viewInfoData",!flag,true);
    }

    handleClickShowPassword = () => {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
      }));
    };

    handleMouseDownPassword = (e) => {
      e.preventDefault();
    };

    handleOnFocus = (e) =>{
      this.setState((prevState) => ({
        showVisible: !prevState.showVisible,
      }));
    }

    handleOnBlur = (e) =>{
      this.setState((prevState) => ({
        showVisible: !prevState.showVisible,
      }));
      if(this.state.showPassword)
        this.setState((prevState) => ({
          showPassword: !prevState.showPassword,
        }));
    }



  render() {

    const { component, variant } = this.props;
    let {Action,SubApplicationName} = component
    let {viewInfoData,infodata}=this.state;
    let componentvalue = this.props.componentvalue;
    let isRequired = component.IsRequired === "Y" ? true : false;
    let isReadOnly = component.IsReadonly;
    let validations=[];
    let errorMessages= [];

    const InfoTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: '0.875rem',
      },
    }))

    let componentLabel = "", value = "",autoComplete="new-password",styleObject={};
    if(component.ComponentLabel){
      componentLabel = isRequired ? component.ComponentLabel + " *" : component.ComponentLabel;
    }

    if (component.DefaultValue === "" && componentvalue !== undefined) {
      value = this.decryptPassword(componentvalue);
    }
    if (component.DefaultValue !== "" && componentvalue !== undefined) {
      value = this.decryptPassword(componentvalue);;
    }
    if (component.DefaultValue !== "" && componentvalue === undefined) {
      value = this.decryptPassword(component.DefaultValue);
    }
    if (component.DefaultValue === "" && componentvalue === undefined) {
      value = "";
    }
    if (component.DefaultValue !== "" &&  component.AttributeValue === "" && componentvalue !== undefined) {
      value = this.decryptPassword(componentvalue);
    }

    if (isRequired) {
        validations.push("required");
        errorMessages.push(component.RequiredMessage);
        validations.push("IsEmpty");
        errorMessages.push(component.RequiredMessage);
    }


    if (component.ValidationRegex) {
      validations.push("Passwordrequirement");
      errorMessages.push(component.ValidatorMessage);
      ValidatorForm.addValidationRule('Passwordrequirement', (value) => {
                let ValidationRegex=`${component.ValidationRegex}`;
                const RegexPattern = new RegExp(ValidationRegex);
                return RegexPattern.test(value)
        })
    }

    if(Action.comparePasswords && Object.keys(Action).length >0)
  {
    let a = this.props.pagelevelValues && this.props.pagelevelValues[SubApplicationName] && this.props.pagelevelValues[SubApplicationName][component.AttributeName]
    let b = this.props.pagelevelValues && this.props.pagelevelValues[SubApplicationName] && this.props.pagelevelValues[SubApplicationName][Action.comparePasswords];

    
    if (a && b && a!==b){
      ValidatorForm.addValidationRule('PasswordrMisMatch', (a,b) => {
          this.forceUpdate()
          return a===b
      })
          let {passwordMismatchMsg}= Action
          validations.push("PasswordrMisMatch:"+a);
          errorMessages.push(passwordMismatchMsg);
          validations.push("PasswordrMisMatch:"+b);
          errorMessages.push(passwordMismatchMsg);
  }
  }
  if(component.AdjacentLabel === 'Y'){
    styleObject = {display:'flex'};
  }
      return (
        <React.Fragment>
          <Grid item xs={12} sm={12} md={parseInt(component.Size)} lg={parseInt(component.Size)}  className={`${component.AdjacentLabel !== 'Y' ? 'custom-text' : null} ${component.AttributeStyleClass}`} style={styleObject}>
            {
            component.AdjacentLabel === 'Y' ?
            <>
              <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              className={`${component.AttributeStyleClass}_Label`}
              >
                <InputLabel htmlFor={component.AttributeName} className="custom-label-down text-font adjacent_label" ref={this.labelRef}>
                  {componentLabel}
                </InputLabel>
              </Grid>
              <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
              >
                <TextValidator
                variant={variant}
                type={this.state.showPassword ? 'Text' : 'Password'}
                name={component.AttributeName}
                placeholder={componentLabel.replace(/&nbsp;/g, '')}
                value={value}
                defaultValue={component.DefaultValue}
                className={`custom-input ${component.StyleClass} lineHeight ${componentLabel.length > 200 ? 'long-label-text' : ''}`}
                onChange={(e) => this.onChange(e)}
                disabled={isReadOnly}
                validators={validations}
                errorMessages={errorMessages}
                onFocus={this.handleOnFocus}
                onBlur={this.handleOnBlur}
                autoComplete={autoComplete}
                fullWidth
                InputProps={{endAdornment:
                  <InputAdornment position="end">
                    {this.state.showVisible && (<IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>)}
                    {component.ItemTipParams && (
                                        <InfoTooltip
                                            title={
                                                <div>
                                                    {infodata && infodata.map((value, index) => (
                                                        <p key={index} style={{ fontWeight: 'bold', margin: 0 }}>
                                                            {value}
                                                        </p>
                                                    ))}
                                                </div>
                                            }
                                            placement="right"
                                        >
                                            <IconButton
                                                className="custom-svg-icon InfoiconIEOverride"
                                                aria-label="info icon"
                                                style={{ color: '#20a8d8', fontSize: '1rem !important' }}
                                                onMouseEnter={() => { this.hideInfoList(false); }}
                                                onMouseLeave={() => { this.hideInfoList(true); }}
                                            >
                                                <ErrorIcon />
                                            </IconButton>
                                        </InfoTooltip>
                                    )}
                  </InputAdornment>
                }}
            />
              </Grid>
            </>
            :
            <TextValidator
                type={this.state.showPassword ? 'Text' : 'Password'}
                name={component.AttributeName}
                placeholder={componentLabel.replace(/&nbsp;/g, '')}
                label={<Typography  className="custom-label-down small_label text-font">{componentLabel}</Typography>}
                value={value}
                defaultValue={component.DefaultValue}
                className={`custom-input ${component.StyleClass} lineHeight ${componentLabel.length > 200 ? 'long-label-text' : ''}`}
                onChange={(e) => this.onChange(e)}
                disabled={isReadOnly}
                validators={validations}
                errorMessages={errorMessages}
                variant={variant}
                onFocus={this.handleOnFocus}
                onBlur={this.handleOnBlur}
                autoComplete={autoComplete}
                fullWidth
                InputProps={{endAdornment:
                  <InputAdornment position="end">
                    {this.state.showVisible && (<IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>)}
                    {component.ItemTipParams && (
                                        <InfoTooltip
                                            title={
                                                <div>
                                                    {infodata && infodata.map((value, index) => (
                                                        <p key={index} style={{ fontWeight: 'bold', margin: 0 }}>
                                                            {value}
                                                        </p>
                                                    ))}
                                                </div>
                                            }
                                            placement="right"
                                        >
                                            <IconButton
                                                className="custom-svg-icon InfoiconIEOverride"
                                                aria-label="info icon"
                                                style={{ color: '#20a8d8', fontSize: '1rem !important' }}
                                                onMouseEnter={() => { this.hideInfoList(false); }}
                                                onMouseLeave={() => { this.hideInfoList(true); }}
                                            >
                                                <ErrorIcon />
                                            </IconButton>
                                        </InfoTooltip>
                                    )}
                  </InputAdornment>
                }}
            />
            }
        </Grid>
        </React.Fragment>
      );
  }

}

const styles = {
    mainGrid : {
        position : 'relative'
    }
}
export default Password;
