import React, { useState, useEffect, useRef} from 'react';
import store from "../../../store";
import {TextValidator} from "react-material-ui-form-validator";
import appActions from "../../../actions";
import { connect } from 'react-redux';
const { actionType } = appActions;

const FieldValidator = (props)  => {
    let errormsg = props.component.RequiredMessage;
    let required = props.component.IsRequired === "Y" ? 'required' : undefined;
    const labelStyle = props.component.AttributeStyleClass
    // const [errorCount, setErrorCount] = useState(props.UIFlowReducer.formValidatorIndex);
    // const errorCountRef = useRef(null);
    // errorCountRef.current = errorCount;
    useEffect(() => {
        store.dispatch({
            type: actionType.SET_FORMVALIDATIONINDEX_INC,
          });

        return () => {
            store.dispatch({
                type: actionType.SET_FORMVALIDATIONINDEX_DEC,
              });
        };
      }, []);
      // useEffect(() => {
      //   setErrorCount(props.UIFlowReducer.formValidatorIndex)
      // }, [props.UIFlowReducer.formValidatorIndex]);
    return(
        <>
        <label className={labelStyle}>{errormsg}</label>
        <TextValidator
                    style={{display: "none"}}
                    validators={required?[required]:[]}
        />
        </>
    )
};

const mapStateToProps = state => ({
    UIFlowReducer: state.UIFlowReducer
  });

export default connect(mapStateToProps)(FieldValidator);
