import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import BackspaceIcon from "@material-ui/icons/Backspace";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { Tooltip } from '@material-ui/core';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from '@material-ui/core/styles';
import { commonEmailUploader } from "./ButtonUtil";
import { TextValidator } from "react-material-ui-form-validator";


var validations = [];
const validationMessage=[];
const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 600,
    backgroundColor: "rgb(47,53,58)",
    fontSize: "14px"
  },
}));


const MultipleUploaderV2 = (props) => {
  const [attachment, setAttachment] = useState([]);
  const [length, setLength] = useState(0);
  const [drag, setDrag] = useState(false);
  const { component } = props;
  const [Error, setError]= useState([]);
  const dropRef = useRef();
  const changeAttRef = useRef();
  const inputElement=useRef();

  useEffect(() => {
    getAddEventListener()
    let { pagelevelValues, component } = props;
    if (
      pagelevelValues &&
      pagelevelValues[component.SubApplicationName] &&
      pagelevelValues[component.SubApplicationName][component.AttributeName]
    ) {
      let onLoadValues =
        pagelevelValues[component.SubApplicationName][component.AttributeName];
      setAttachment(onLoadValues);
    }

    return () => {
      getRemoveEventListener()
    };
  }, []);

  useEffect(() => {
    let e = {
      target: {
        name: component.AttributeName,
      },
    };
    setLength(attachment.length);
    onChange(e);
    //useref is synchronous
    changeAttRef.current = attachment;
  }, [attachment]);


  const getAddEventListener = () => {
    try {
      ["dragenter", "dragover", "dragleave", "mouseout", "drop"].forEach(
        (eventName) => {
          window.addEventListener(eventName, handleDragEvents);
        }
      );
      window.addEventListener("dragenter", handleDragIn);
      window.addEventListener("dragleave", handleDragOut);
      window.addEventListener("mouseout", handleMouseOut);
      window.addEventListener("drop", handleMouseOut);

      let div = dropRef.current;
      div.addEventListener("drop", handleDrop);
    } catch (err) {
      console.log("Error in getAddEventListener method", err.message);
    }
  }

  const getRemoveEventListener = () => {
    try {
      ["dragenter", "dragover", "dragleave", "mouseout", "drop"].forEach(
        (eventName) => {
          window.removeEventListener(eventName, handleDragEvents);
        }
      );
      window.removeEventListener("dragenter", handleDragIn);
      window.removeEventListener("dragleave", handleDragOut);
      window.removeEventListener("mouseout", handleMouseOut);
      window.removeEventListener("drop", handleMouseOut);

      let div = dropRef.current;
      div.removeEventListener("drop", handleDrop);
    } catch (err) {
      console.log("Error in getRemoveEventListener method", err.message);
    }
  }

  const onChange = async (e) => {
    try {
      if (props.customTable) {
        let value = Object.assign({}, { [e.target.name]: attachment });
        props.handleRowLevelInputChange(value, component, props.customTableRowIndex, props);
      } else {
        let value = Object.assign({}, { [e.target.name]: attachment });
        await props.handleInputChangeV2(value, component, props.isDynamicTable, props.datatableRowindex);
      }
    } catch (err) {
      console.log("Error in onChange method", err.message);
    }
  };

  const onUpload = async (event) => {
    try {
      let attachedFile = event.target.files;
      if (attachedFile.length <= 0) {
        return;
      }
      if (props && props[component.OnClickAction]) {
        props[component.OnClickAction](attachedFile, setAttachment, changeAttRef.current, props, true);
      } else {
        commonEmailUploader(attachedFile,setAttachment,changeAttRef.current,props,true,component,setError,Error)      }
    } catch (err) {
      console.log("Error in onUpload method", err.message);
    }
  };

  const downloadCurrentFile = (file) => {
    try {
      if (props && props[component.OnClickAction]) {
        props[component.OnClickAction](file);
      } else {
       // props.handleAttachments(file);
      commonEmailUploader(file)
      }
    } catch (err) {
      console.log("Error in downloadCurrentFile method", err.message);
    }
  };

  const deleteAttachment = (index) => {
    try {
      let tempAttachment = JSON.parse(JSON.stringify([...attachment]));
      tempAttachment.splice(index, 1);
      setAttachment(tempAttachment);
    } catch (err) {
      console.log("Error in deleteAttachment method", err.message);
    }
  };

  let extension = undefined;

  let dragCounter;

  const handleDragEvents = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
    } catch (err) {
      console.log("Error in handleDragEvents method", err.message);
    }
  };

  const handleDragIn = (e) => {
    try {
      dragCounter++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDrag(true);
      }
    } catch (err) {
      console.log("Error in handleDragIn method", err.message);
    }
  };

  const handleDragOut = (e) => {
    try {

      dragCounter--;
      if (dragCounter === 0) {
        setDrag(false);
      }
    } catch (err) {
      console.log("Error in handleDragOut method", err.message);
    }
  };

  if (component.IsRequired === "Y") {
    validations.push("required");
    validationMessage.push(component.RequiredMessage);
    validations.push("IsEmpty");
    validationMessage.push(component.RequiredMessage);
  }

  const handleMouseOut = (e) => {
    try {
      dragCounter = 0;
      if (dragCounter === 0) {
        setDrag(false);
      }
    } catch (err) {
      console.log("Error in handleMouseOut method", err.message);
    }
  };

  const handleDrop = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      dragCounter = 0;
      if (dragCounter === 0) {
        setDrag(false);
      }
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        let data = { ...e.dataTransfer.files };
        let event = {
          target: {
            files: data,
          },
        };
        onUpload(event);
        e.dataTransfer.clearData();
      }
    } catch (err) {
      console.log("Error in handleDrop method", err.message);
    }
  };
  let autocomplete="off";
let custom3=component.Custom3 && JSON.parse(component.Custom3);
(custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

  let value = attachment.length > 0 || (component.componentvalue && component.componentvalue.length > 0) ? "true" : ""; 
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} ref={dropRef} className="grid-drag__container"  >
      {drag && (
        <div className="drag__content">
          <div className="drag__text">
            <div>
              <CloudUploadIcon style={{ fontSize: "2rem" }} />
              <span> Drag & Drop your files here to upload</span>
            </div>
          </div>
        </div>
      )}
      <div>Attachment Found: {length} Attachment(s)</div>
      {attachment.length > 0 ? (
        <>
          {attachment.map((file, index) => {
            return (

              <div className="file__container" key={index} >
                <Tooltip title={file[component.ResponseKey]} classes={{ tooltip: classes.customWidth }} arrow >
                  <a
                    key={index}
                    className="Multiple-Upload-Link file__nameText"
                    onClick={() => downloadCurrentFile(attachment[index])}
                  >
                    {file[component.ResponseKey]}
                  </a>
                </Tooltip>
                {!component.IsReadonly ? 
                 <IconButton component="div" onClick={() => deleteAttachment(index)}>
                  <BackspaceIcon />
                </IconButton> : <span style={{marginLeft: '20px'}}></span>}
                <br />
                {file.size ? "File Size :" + (((file.size)/1024)/1024).toFixed(2) + ' MB' : ""} 
                <br />
                {Error && Error.length>0?(
            <>
           { Error.map((mes)=>{
            if(file.orgFileName===mes.AttachmentName){
              return(<><p style={redColor}>{mes["fileSizeError"]}</p><p style={redColor}>{mes["fileNameError"]}</p><p style={redColor}>{mes["fileExtensionError"]}</p>
              </>)
            }           
            })}
            </>
            ): null}
            <br/>
              </div>

            );
          })}
        </>
      ) : null}
      <div className="upload-btn-msg">
      <div className="upload upload-inlineContainer theme_button">
        <div className={`upload-button theme_button theme_buttonColor ${component.IsReadonly? 'disabled': ''}`}>
          <span className="upload-button-text">
            <PublishIcon />
            <span>{component.ComponentLabel} </span>
          </span>
          <input
            id="contained-button-file"
            multiple
            type="file"
            accept={
              extension === undefined
                ? "application/vnd.ms-excel , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/pdf"
                : null
            }
            onChange={onUpload}
            className="fileInputOverflow upload-button-input"
            disabled ={component.IsReadonly ? "disabled" : ""}
          />

        </div>   
      </div>
      <div style={greyColor}>Drag & Drop your files here to upload</div>
      </div>
      <TextValidator
     className='texterrorthrowstyles component-container'
     inputRef={inputElement}
     validators={validations}
     errorMessages={validationMessage}
     autoComplete={autocomplete}
     value={value}
     /> 
    </Grid>
  );
};

const redColor={color:"red"};
const greyColor={color:"#8f8f8f"}
const mapStateToProps = (state) => ({
  UIFlowReducer: state.UIFlowReducer,
});

export default connect(mapStateToProps)(MultipleUploaderV2);
