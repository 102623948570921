class DIContainer{
  constructor(){
    this.service = {}
  }

  register(name,definition){
    this.service[name] = definition
  }

  get(name){
    const util = this.service[name]

    if(!util){
      console.log(`DIContainer ${name} is not found`)
    }

      return util
  }

  getAll(){
    return this.service
  }
}

const container = new DIContainer()

export default container
