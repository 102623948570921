import React, { Fragment } from 'react';
import { Grid, CardContent } from "@material-ui/core";
import DynamicComponent from "../../corecomponents/DynamicComponent";
import DynamicNavigation from "../../corecomponents/DynamicNavigation";
import DataTable from '../../controls/DataTable/DataTable';
import DataTableV2 from '../../controls/DataTable/DataTableV2';
import DialogWrapper from './DialogComponent/DialogWrapper';
import ExpandableComponent from '../../sections/CustomComponents/ExpandableComponent/ExpandableComponent';
import ChildFormComponent from "./ChildComponent/childFormComponent";
import RenderTemplate from '../../../../template/RenderTemplate';
import ValidationMessages from '../../controls/Validation/ValidationMessages';
// import controls from '../../../../template';
import store from '../../../../store';
import container from '../../utils/DIContainer';

const isChildComponentNeeded = (mActionList,ChildSubApplicationList) =>{
   let {addRow,editRow}=mActionList, ChildSubappPresent = ChildSubApplicationList.length > 0;
   if((addRow && addRow.isNeeded) || (editRow && editRow.isNeeded) || ChildSubappPresent) return true
   else return false;
  }

const dynamicRender = (subapplicationList = [], componentProps, componentState = {}, functions = {}, TableControls = {}, summarySubApp, detailSummaryList, hierarchicalRendering, childformRef, staticReadonlyRequired = false) => {

  return (



    subapplicationList.map((subapplication, subaapplicationIndex) => {
      let pageName = subapplication.Description;
      let attributeList = subapplication.AttributeDetailList;
      let subapplicationName = subapplication.ApplicationName;

      var  { IsExpandable, IsSubmitButtonNeeded, IsEmptyFormNeeded, ButtonType, SubmitButtonName, AdditionalButtonName, AdditionalButtonClickEvent, StyleClass } = subapplication;
      const childFormInfo = { IsSubmitButtonNeeded, IsEmptyFormNeeded, ButtonType, SubmitButtonName, AdditionalButtonName, AdditionalButtonClickEvent }

      let templateType = "";
      let utilObject = {};
      let parentProps = {};
      let dataList = [];
      let stateValues = {};
      let columnList = [];
      let dataListSize;
      let dataTableUpdate = false
      let mActionList = subapplication.mAction || {};
      let dynamicTable = false
      let isChildComponent= false;
      let expandKey= false;
      const ChildSubApplicationList = subapplication.ChildSubApplicationList ? subapplication.ChildSubApplicationList : [];
      templateType = subapplication.Type;
            if(Object.keys(mActionList).length > 0){
         isChildComponent = isChildComponentNeeded(mActionList,ChildSubApplicationList);
         dynamicTable = true;
         expandKey = mActionList.expandByDefault === true? true:false;
         }
      var { templateName, utilfunctionNeeded, utilfunction,additionalUtil=undefined } = componentProps;

      let subAppInfo = {
        "ComponentList": attributeList,
        "SubApplicationDetail": subapplication,
        subapplicationName
      };
      let LineOfBusiness = componentProps.metadataReducer ? componentProps.UIFlowReducer.productDetails.hasOwnProperty("LineOfBusiness") && componentProps.UIFlowReducer.productDetails["LineOfBusiness"] : componentState.metadataReducer ? componentState.UIFlowReducer.productDetails.hasOwnProperty("LineOfBusiness") && componentState.UIFlowReducer.productDetails["LineOfBusiness"] : null;
      templateName= templateName ? templateName : LineOfBusiness;
      const tableOptions = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] && utilfunction[templateName][subapplicationName.toLowerCase()]["TableOptions"] ? utilfunction[templateName][subapplicationName.toLowerCase()]["TableOptions"] : TableControls.TableOptions;
      const tableUtils = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] && utilfunction[templateName][subapplicationName.toLowerCase()]["tableUtils"] ? utilfunction[templateName][subapplicationName.toLowerCase()]["tableUtils"] : TableControls.tableUtils;
      const tableFunctions = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] && utilfunction[templateName][subapplicationName.toLowerCase()]["tableFunctions"] ? utilfunction[templateName][subapplicationName.toLowerCase()]["tableFunctions"] : TableControls.tableFunctions;
      const dialogOptions = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] ? utilfunction[templateName][subapplicationName.toLowerCase()]["dialogOptions"] : {};
      const dialogUtils = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] ? utilfunction[templateName][subapplicationName.toLowerCase()]["dialogUtils"] : {};
      const validationSource = subapplicationName.toLowerCase() + "_dataList";

      if (hierarchicalRendering) {
        if (templateType === "Summary" || templateType === "PivotSummary"){
          dataList = componentState.hasOwnProperty(subapplicationName) ? componentState[subapplicationName] : [];
        }
      } else {
        dataList = componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_dataList") ? componentState[subapplicationName.toLowerCase() + "_dataList"] : [];
        if(dataList!=undefined){
          //temporary fix: parentdataListUpdate - which state component prefer
          //parentdataListUpdate (Y/N) - componetState datalist is empty parentdatalist will be used || componentState datalist will be used even if it is empty.
          if(dataList.length === 0 && (!mActionList.hasOwnProperty("parentdataListUpdate") || mActionList.parentdataListUpdate === 'Y')) {
            dataList = componentProps.hasOwnProperty(subapplicationName.toLowerCase() + "_dataList") ? componentProps[subapplicationName.toLowerCase() + "_dataList"] : [];
          }
        }
      }
      if(componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_dataListSize")) dataListSize = componentState[subapplicationName.toLowerCase() + "_dataListSize"]
      if(componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_dataTableUpdate")) dataTableUpdate = componentState[subapplicationName.toLowerCase() + "_dataTableUpdate"]
      // getting dataList from pagelevelValues
       if(dataList!=undefined){
      if(dataList.length === 0) {
        dataList = componentState.pagelevelValues ? (componentState.pagelevelValues.hasOwnProperty(subapplicationName.toLowerCase() + "_dataList") ?  componentState.pagelevelValues[subapplicationName.toLowerCase() + "_dataList"] : []) : [];
      }
      }

      if (utilfunction) {
        stateValues = Object.assign({}, componentProps, componentState);
      }
      else{
        stateValues = componentProps;
      }


      if (utilfunction) {
        utilObject = utilfunction[templateName] ? utilfunction[templateName][subapplicationName.toLowerCase()] ? utilfunction[templateName][subapplicationName.toLowerCase()] : {} : {};
      }
      if(additionalUtil!==undefined && utilfunction.hasOwnProperty(`${additionalUtil}`)){
        utilObject={...utilObject,...utilfunction[additionalUtil]};
      }
      columnList = componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_columnList") ? componentState[subapplicationName.toLowerCase() + "_columnList"] : [];
      if(columnList.length === 0) {
        columnList = componentProps.hasOwnProperty(subapplicationName.toLowerCase() + "_columnList") ? componentProps[subapplicationName.toLowerCase() + "_columnList"] : [];
      }

      // getting ColumnList from pagelevelValues
      if(columnList.length === 0) {
        columnList = componentState.pagelevelValues ? (componentState.pagelevelValues.hasOwnProperty(subapplicationName.toLowerCase() + "_columnList") ?  componentState.pagelevelValues[subapplicationName.toLowerCase() + "_columnList"] : []) : [];
      }

      //parentProps assign
      if(componentProps.hasOwnProperty('parentProps')){
        parentProps = componentProps.parentProps
      }
      else {
        parentProps = componentState
      }
      let ChildComp=null
      let redux = store.getState()
      const controls =  container.get('templateControls')
      let LOB = componentProps.metadataReducer ? componentProps.metadataReducer.lob : componentState.metadataReducer ? componentState.metadataReducer.lob : null;
      // let subappUtilFunctions = LOB && subapplicationUtil[LOB] && subapplicationUtil[LOB][templateName] ? subapplicationUtil[LOB][templateName]: {}
      if(tableOptions && tableOptions.ChildrenComponent != null){
        if(typeof(tableOptions.ChildrenComponent)==="string"){
          let ChildrenComp=controls[tableOptions.ChildrenComponent]
          ChildComp=ChildrenComp && React.isValidElement(<ChildrenComp/>) ? ChildrenComp: null
        }else if( React.isValidElement(<tableOptions.ChildrenComponent/>)){
          ChildComp=tableOptions.ChildrenComponent
        }
      }
      if(dynamicTable && mActionList.childComponent && mActionList.childComponent.childComponentName && mActionList.childComponent.childComponentName!==''){
        let ChildrenComp=controls[mActionList.childComponent.childComponentName.toLowerCase()]
        ChildComp=ChildrenComp && React.isValidElement(<ChildrenComp/>) ? ChildrenComp: null
      }
      let type = <Grid container>
          {((templateType === "Summary" || templateType === "PivotSummary") && !dynamicTable) ? (
            <Grid container className="mt-4 mb-4">
              <DataTable
                subAppInfo={subAppInfo}
                columnList={columnList}
                dataList={dataList}
                UpdateState={functions.UpdateState}
                getDocument={functions.getDocument}
                TableOptions={tableOptions}
                tableUtils={tableUtils}
                ChildSubApplicationList={ChildSubApplicationList}
                componentProps={componentProps}
                componentState={componentState}
                parentProps={parentProps}
                TableControls={TableControls}
                functions={functions}
                dataListSize={componentState[`${subapplicationName.toLowerCase()}__dataListSize`]}
                hierarchicalRendering={hierarchicalRendering}
                IsExpandable={IsExpandable}
                {...childFormInfo}
                staticReadonlyRequired={staticReadonlyRequired}
                pageInfo={componentProps.pageInfo || componentState.pageInfo || null}
                {...utilObject}
              >
                { ChildComp &&
                  <ChildComp {...componentProps} {...utilObject} {...componentState} ActionList={tableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} />}  {/* The {...utilObject} spread here should be moved to the util specified in child component subapp name */}
              </DataTable>
            </Grid>
          ) : null}
          {
            ((templateType === "Summary" || templateType === "PivotSummary") && dynamicTable) ? (
                <Grid container className="mt-4 mb-4">
                    <DataTableV2
                        subAppInfo={subAppInfo}
                        columnList={attributeList}
                        dataTableUpdate={dataTableUpdate}
                        dataList={dataList}
                        UpdateState={functions.UpdateState}
                        getDocument={utilObject.getDocument}
                        tableActions={mActionList}
                        tableFunctions={tableFunctions}
                        TableOptions={tableOptions}
                        tableUtils={tableUtils}
                        dataListSize={dataListSize}
                        ChildSubApplicationList={ChildSubApplicationList}
                        componentProps={componentProps}
                        componentState={componentState}
                        parentProps={parentProps}
                        TableControls={TableControls}
                        functions={functions}
                        hierarchicalRendering={hierarchicalRendering}
                        IsExpandable={IsExpandable}
                        {...childFormInfo}
                        staticReadonlyRequired={staticReadonlyRequired}
                        pageInfo={componentProps.pageInfo || componentState.pageInfo || null}
                        // dataListSize={componentState[`${subapplicationName.toLowerCase()}__dataListSize`]}
                        {...childFormInfo}
                        {...utilObject}
                    >
                        {/* {TableControls.TableOptions && TableControls.TableOptions.ChildrenComponent != null && <TableControls.TableOptions.ChildrenComponent {...componentProps} {...componentState} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} />} */}
                        {isChildComponent && <ChildComp {...componentProps} {...utilObject} {...componentState}  componentProps={componentProps} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} mActionList={mActionList} functions={functions}/>}
                    </DataTableV2>
                </Grid>
            ) : null
        }
          {
            templateType === "Detail" || templateType === "Accordion" || templateType === "Form" ? (
            <DynamicComponent
              onKeyDown={functions.onKeyDown}
              UpdateState={functions.UpdateState}
              refs={childformRef}
              pageInfo={componentProps.pageInfo}
              pageName={pageName}
              components={attributeList}
              IsExpandable={IsExpandable}
              navigateNext={functions.navigateNext}
              values={stateValues.values}
              updateValues={stateValues.updateValues}
              transactionPrimaryObject={stateValues.transactionPrimaryObject}
              ChildSubApplicationList={ChildSubApplicationList}
              functions={functions}
              componentProps={componentProps}
              componentState={componentState}
              TableControls={TableControls}
              summarySubApp={summarySubApp}
              detailSummaryList={detailSummaryList}
              pagelevelValues={stateValues.pagelevelValues}
              handleInputChange={functions.handleInputChange}
              handleInputChangeV2={functions.handleInputChangeV2}
              copyValue={functions.copyValue}
              handleAutoCompletechange={functions.handleAutoCompletechange}
              handleDateChange={functions.handleDateChange}
              handleHiddenInputChange={functions.handleHiddenInputChange}
              invokeGetAccount={functions.invokeGetAccount}
              updateValueWithSubplicationName={functions.updateValueWithSubplicationName}
              handleRTEChange={functions.handleRTEChange}
              callback={functions.callback}
              responseList={componentState.responseList} // need to check the purpose of this
              responseLists = {componentState.responseLists}
              {...utilObject}
            //  {...subappUtilFunctions}
              staticReadonlyRequired={staticReadonlyRequired}
            />
            ) : null
          }

          {
            templateType !== "Detail" && templateType !== "Dialog" && templateType !== "Accordion" && templateType !== "Summary" && templateType !== "PivotSummary" && templateType !== "Form" && templateType !== "ValidationMessages" ?
            (<RenderTemplate
                Template={redux.navigationReducer.templateInfo[templateType]}
                pageName={pageName}
                subapplicationName={subapplicationName}
                SubApplicationDetail = {subapplication}
                functions={functions}
                components={attributeList}
                utilfunction={utilObject}
                pagelevelValues={stateValues.pagelevelValues}
                values={stateValues.values}
                transactionPrimaryObject={stateValues.transactionPrimaryObject}
                parentProps={stateValues}
                staticReadonlyRequired={staticReadonlyRequired}
              >
              </RenderTemplate>
            ) : null
          }

          {
            templateType === "ValidationMessages" && stateValues[validationSource] && stateValues[validationSource].length > 0 ?
              (<ValidationMessages
                  dataList={stateValues[validationSource]}
                  Header={pageName}
              />) : null
          }

          {
            componentProps.dynamicNavigation && templateType === "NavigationButtons" ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DynamicNavigation
                  components={attributeList}
                  values={stateValues.values}
                  UpdateState={componentProps.UpdateState}
                  functions={functions}
                  propsValue={componentProps.propsValue}
                  navigateToPrevious={componentProps.navigateToPrevious}
                  navigateToNext={componentProps.navigateToNext}
                  lobUtil={componentProps.lobUtil}
                  pagelevelValues={stateValues.pagelevelValues}
                  transactionPrimaryObject={stateValues.transactionPrimaryObject}
                  metadataReducer={componentProps.metadataReducer}
                  pageMetadata={componentState.pageMetadata}
                  formref={childformRef}
                  state={componentState}
                  {...utilObject}
                  staticReadonlyRequired={staticReadonlyRequired}
                />
              </Grid>
            ) : null
          }
        </Grid>

        let FormContent = (props) =>{
          let a;
          if(templateType === "Detail" || templateType === "Accordion" || templateType === "Form")
          {
          a=

          <DynamicComponent
                pageInfo={componentProps.pageInfo}
                pageName={pageName}
                components={attributeList}
                IsExpandable={IsExpandable}
                values={stateValues.values}
                updateValues={stateValues.updateValues}
                transactionPrimaryObject={stateValues.transactionPrimaryObject}
                ChildSubApplicationList={ChildSubApplicationList}
                functions={functions}
                componentProps={componentProps}
                componentState={componentState}
                tableFunctions={tableFunctions}
                TableControls={TableControls}
                summarySubApp={summarySubApp}
                detailSummaryList={detailSummaryList}
                pagelevelValues={stateValues.pagelevelValues}
                handleInputChange={functions.handleInputChange}
                handleInputChangeV2={functions.handleInputChangeV2}
                handleAutoCompletechange={functions.handleAutoCompletechange}
                handleDateChange={functions.handleDateChange}
                handleHiddenInputChange={functions.handleHiddenInputChange}
                invokeGetAccount={functions.invokeGetAccount}
                updateValueWithSubplicationName={functions.updateValueWithSubplicationName}
                handleRTEChange={functions.handleRTEChange}
                callback={functions.callback}
                {...utilObject}
                // {...subappUtilFunctions}
                staticReadonlyRequired={staticReadonlyRequired}
                onKeyDown={functions.onKeyDown}
              />
          }
          if(templateType === "Dialog")
          {
            a =
            <DialogWrapper
              components={attributeList}
              subAppInfo = {subAppInfo}
              functions={functions}
              componentProps={componentProps}
              componentState={componentState}
              values={stateValues.values}
              dialogOptions={dialogOptions}
              dialogUtils={dialogUtils}
              initializeMultipleMail={functions.initializeMultipleMail}
              ChildSubApplicationList={ChildSubApplicationList}
              pagelevelValues={stateValues.pagelevelValues}
              parentProps={parentProps}
              transactionPrimaryObject={stateValues.transactionPrimaryObject}
              handleInputChange={functions.handleInputChange}
              handleInputChangeV2={functions.handleInputChangeV2}
              handleAutoCompletechange={functions.handleAutoCompletechange}
              handleDateChange={functions.handleDateChange}
              handleRTEChange={functions.handleRTEChange}
              handleHiddenInputChange={functions.handleHiddenInputChange}
              updateValueWithSubplicationName={functions.updateValueWithSubplicationName}
              handlePersistValue={functions.handlePersistValue}
              UpdateState={functions.UpdateState}
              pageInfo={componentProps.pageInfo}
              {...utilObject}
            />
          }
      if(templateType !== "Dialog" && templateType !== "Detail" && templateType !== "Accordion" && templateType !== "Summary" && templateType !== "PivotSummary" && templateType !== "Form" && templateType !== "ValidationMessages")
      {
        a=<RenderTemplate
                  {...props}
                  Template={redux.navigationReducer.templateInfo[templateType]}
                  pageName={pageName}
                  subapplicationName={subapplicationName}
                  SubApplicationDetail = {subapplication}
                  functions={functions}
                  components={attributeList}
                  utilfunction={utilObject}
                  pagelevelValues={stateValues.pagelevelValues}
                  values={stateValues.values}
                  transactionPrimaryObject={stateValues.transactionPrimaryObject}
                  parentProps={stateValues}
                  staticReadonlyRequired={staticReadonlyRequired}
                >
                </RenderTemplate>
      }
      if((templateType === "Summary" || templateType === "PivotSummary" || templateType === "EditableSummary") && !dynamicTable)
      {
        a=<Grid container className="mt-4 mb-4">
                <DataTable
                  subAppInfo={subAppInfo}
                  columnList={componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_columnList") ? componentState[subapplicationName.toLowerCase() + "_columnList"] : []}
                  dataList={dataList}
                  UpdateState={functions.UpdateState}
                  TableOptions={tableOptions}
                  tableUtils={tableUtils}
                  ChildSubApplicationList={ChildSubApplicationList}
                  componentProps={componentProps}
                  componentState={componentState}
                  parentProps={parentProps}
                  dataListSize={componentState[`${subapplicationName.toLowerCase()}__dataListSize`]}
                  TableControls={TableControls}
                  functions={functions}
                  hierarchicalRendering={hierarchicalRendering}
                  IsExpandable={IsExpandable}
                  {...childFormInfo}
                  staticReadonlyRequired={staticReadonlyRequired}
                  pageInfo={stateValues.pageInfo || null}
                >
                  {TableControls.TableOptions.ChildrenComponent != null && <TableControls.TableOptions.ChildrenComponent {...componentProps} {...componentState} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} />}
                </DataTable>
              </Grid>
      }
      {
        if((templateType === "Summary" || templateType === "PivotSummary") && dynamicTable){
           a= <Grid container className="mt-4 mb-4">
                <DataTableV2
                    subAppInfo={subAppInfo}
                    columnList={attributeList}
                    dataList={dataList}
                    UpdateState={functions.UpdateState}
                    getDocument={utilObject.getDocument}
                    tableActions={mActionList}
                    tableFunctions={tableFunctions}
                    TableOptions={tableOptions}
                    tableUtils={tableUtils}
                    ChildSubApplicationList={ChildSubApplicationList}
                    componentProps={componentProps}
                    componentState={componentState}
                    parentProps={parentProps}
                    // state2={componentState}
                    TableControls={TableControls}
                    functions={functions}
                    hierarchicalRendering={hierarchicalRendering}
                    IsExpandable={IsExpandable}
                    {...childFormInfo}
                    staticReadonlyRequired={staticReadonlyRequired}
                    pageInfo={componentProps.pageInfo || componentState.pageInfo || null}
                >
                    {/* {TableControls.TableOptions && TableControls.TableOptions.ChildrenComponent != null && <TableControls.TableOptions.ChildrenComponent {...componentProps} {...componentState} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} />} */}
                    {isChildComponent && <ChildComp {...componentProps} {...componentState}  componentProps={componentProps} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} />}
                </DataTableV2>
            </Grid>
        }

    }
            return (a);
    }

        let childform = IsEmptyFormNeeded === "Y" ?
          <ChildFormComponent
            pageName={pageName}
            subapplicationName={subapplicationName}
            IsSubmitButtonNeeded={IsSubmitButtonNeeded}
            IsEmptyFormNeeded={IsEmptyFormNeeded}
            ButtonType={ButtonType}
            componentProps={componentProps}
            componentState={componentState}
            UpdateState={functions.UpdateState}
            SubmitButtonName={SubmitButtonName}
            AdditionalButtonName={AdditionalButtonName}
            AdditionalButtonClickEvent={AdditionalButtonClickEvent}
            utilfunction={{...utilObject}}
            staticReadonlyRequired={staticReadonlyRequired}
          >
            {FormContent()}
          </ChildFormComponent> : type;

        let expandableComponent = IsExpandable === 'Y' ? <ExpandableComponent pageName={pageName}expandByDefault={expandKey}>{childform}</ExpandableComponent> : childform
        let StyledComponent = StyleClass ? <div className={StyleClass}>{expandableComponent}</div> : expandableComponent;
        let {StyleClass:SubapplicationClassName='', ...customProperties} = subapplication.mAction && subapplication.mAction.ComponentProperties ? subapplication.mAction.ComponentProperties : {};
        let properties = {lg: 12, md: 12, xs: 12, sm: 12, ...customProperties}
        return (
          // attributeList.length > 0 &&
          <Fragment key={subapplication.SubApplicationVersionId}>
            <Grid item {...properties} className={`${subapplicationName}__wrapperClass ${SubapplicationClassName}`}>
              <CardContent className="custom-indentation custom-margin-left">
                {StyledComponent}
              </CardContent>
            </Grid>
          </Fragment>
        );
      }
    )
  )
}

export default dynamicRender;
