    import React, { Component, Fragment, Suspense, lazy } from 'react';
import TableHeading from './TableHeading';
import DataTableColumns from './DataTableColumns';
import DataTableRow from './DataTableRow';
import DataTablePagination from './DataTablePagination';
import NoRecords from './NoRecords';
import CustomFilterRow from './CustomFilterRow';
import { TableContainer, Paper, Table, TableHead, TableBody } from "@material-ui/core";

const TableHeaderActions = lazy(() => import('./TableHeaderActions'));

class DataTable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            page: 0,
            rowsPerPage: this.props.TableOptions.RowsPerPage,
            order: "asc",
            orderBy: '',
            filtercriteria: {},
            isColumnChecked: false,
        }
        this.childformshrink = React.createRef();
    }

    componentDidMount() {

        let { subAppInfo, UpdateState, columnList, dataList, TableOptions } = this.props;
        let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
        let SubApplnName = SubApplicationDetail.ApplicationName.toLowerCase();

        let columnListName = SubApplnName + "_columnList";
        let columnlistValue = subAppInfo['ComponentList'];

        let dataListName = SubApplnName + "_dataList";
        let dataListValue = dataList;

        let selectable = TableOptions.Selectable ? TableOptions.Selectable : {};
        let { attributeName } = selectable;

        if (dataList) {
            this.setState({isColumnChecked: (!!dataList.length && dataList.every(el => el[attributeName] === "true"))});
        }


        if (columnList.length === 0) {
            UpdateState(dataListName, dataListValue, false)
            UpdateState(columnListName, columnlistValue, true)
        }
    }

    componentDidUpdate = (prevProps) => {
        let { page, rowsPerPage } = this.state;
        let { TableOptions,UpdateState,dataList,columnList,subAppInfo } = this.props;
        let { lazyLoading = false } = TableOptions;
        let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
        let SubApplnName = SubApplicationDetail.ApplicationName.toLowerCase();

        let columnListName = SubApplnName + "_columnList";
        let columnlistValue = subAppInfo['ComponentList'];

        let dataListName = SubApplnName + "_dataList";
        let dataListValue = dataList;
        if (columnList.length === 0) {
            UpdateState(dataListName, dataListValue, false)
            UpdateState(columnListName, columnlistValue, true)
        }
        if (prevProps.dataList && this.props.dataList) {
            if (prevProps.dataList.length !== this.props.dataList.length) {
                if (!lazyLoading && this.props.dataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length === 0) {
                    if (page !== 0) {
                        this.handleChangePage({}, page - 1);
                    }

                }
            }
            if(prevProps.dataList!== this.props.dataList){
                UpdateState("defaultRowsPerPage",rowsPerPage,false);
            }
        }
    }

    updatePageNumber = () =>{
        this.setState({
            page : 0
        })
    }

    handleChangePage = (event, newPage) => {
        let { TableOptions } = this.props;
        let { lazyLoading = false } = TableOptions;

        this.setState({
            page: newPage,
        }, () => {
            if (lazyLoading) {
                this.onLazyLoadData()
            }
        });
    };

    handleChangeRowsPerPage = (event) => {
        let { TableOptions } = this.props;
        let { lazyLoading = false } = TableOptions;

        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        }, () => {
            if (lazyLoading) {
                this.onLazyLoadData()
            }
        });
    };

    onLazyLoadData = () => {
        let { tableUtils } = this.props;
        let { getLazyLoadData } = tableUtils;
        let { page, rowsPerPage } = this.state;
        
        let offsetInfo = {
            "StartLimit": (page * rowsPerPage).toString(), //Offset
            "EndLimit": (rowsPerPage).toString(), //Limit
            ...this.state.filtercriteria
        }
        getLazyLoadData(offsetInfo,this.props, this.updatePageNumber); 
    }

    descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
    }

    stableSort = (array, comparator) => {
        // console.log("Inside stableSort");
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    handleRequestSort = (event, property) => {
        // console.log("Inside handleRequestSort");
        const { order, orderBy } = this.state;
        const isAsc = orderBy === property && order === "asc";
        this.setState({
            order: isAsc ? "desc" : "asc",
            orderBy: property
        });
    };

    typeWatch = function (callback, ms) {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        }
    }()

    handleFilterRequest = async (e) => {
        e.preventDefault();
        let { tableUtils = {}, TableOptions } = this.props;
        let { getLazyLoadData } = tableUtils;
        let { lazyLoading = false } = TableOptions;
        let keyname = e.target.name;
        let value = typeof e.target.value === 'string' ? e.target.value : e.target.value.toString();

        this.setState((prevState) => {
            let filtercriteria = Object.assign({}, prevState.filtercriteria);
            if (value.length > 0) {
                filtercriteria[keyname] = value;
            } else {
                delete filtercriteria[keyname];
            }

            return { filtercriteria };
        }, () => {
            if (lazyLoading) {
                let offsetInfo = { ...this.state.filtercriteria }
                this.typeWatch(() => { getLazyLoadData(offsetInfo) }, 1000)
            }
        })
    }

    onFilter = (dataList) => {
        const { filtercriteria } = this.state;
        if (Object.keys(filtercriteria).length > 0) {
            let filteredData = dataList.filter((rowdata) => {
                for (var key in filtercriteria) {
                    if (!rowdata.hasOwnProperty(key) || !(rowdata[key].toLowerCase().includes(filtercriteria[key].toLowerCase())))
                        return false;
                }
                return true;
            });
            return filteredData;
        } else {
            return dataList;
        }

    }

    onRowDataManipulation = (dataList) => {
        const { TableOptions } = this.props;
        let resultData = dataList;
        if (TableOptions.FilteringNeeded) {
            resultData = this.onFilter(resultData);
        }
        if (TableOptions.SortingNeeded && this.state.orderBy !== '') {
            resultData = this.stableSort(resultData, this.getComparator(this.state.order, this.state.orderBy))
        }
        return resultData;

    }

    handleCheckBox = (e, rowdata, selectable) => {
        let { tableUtils, subAppInfo, dataList, UpdateState } = this.props;
        const { attributeName } = selectable;
        let { SubApplicationDetail } = subAppInfo;
        let { ApplicationName } = SubApplicationDetail;
        let dataListName = ApplicationName.toLowerCase() + "_dataList";
        let checkedCount = 0;
        let updatedDataList = dataList.map(el => {
            if (rowdata) {
                if (el === rowdata) {
                    el[attributeName] = e.target.checked.toString();
                }
            } else {
                el[attributeName] = e.target.checked.toString();
            }
            (el[attributeName] === "true") && checkedCount++;
            return el;
        })
        if (tableUtils.onRowSelect) tableUtils.onRowSelect();
        this.setState({ isColumnChecked: (updatedDataList.length !== 0 && updatedDataList.length === checkedCount) });
        UpdateState('rowCheckedCount', checkedCount, false);
        UpdateState(dataListName, updatedDataList, true);
    }


    handleTableRowSelect = async (checkedStatus, rowData, selectableOptions) => {
        const { onRowSelectAction = null, attributeName = null, } = selectableOptions;
        const { dataList, subAppInfo, UpdateState } = this.props;
        const { SubApplicationDetail } = subAppInfo;
        const { ApplicationName } = SubApplicationDetail;
        const dataListName = ApplicationName.toLowerCase() + "_dataList";

        if(!onRowSelectAction || !attributeName) return; 

        const response = await onRowSelectAction(checkedStatus, rowData, this.props);
        if(response.toLowerCase() === 'success') {
            rowData[attributeName] = checkedStatus;
            UpdateState(dataListName, dataList, true);
        }
        
    }

    handleTableColumnSelect = async (checkedStatus, rowData, selectableOptions) => {
        const { onColumnSelectAction = null, attributeName = null } = selectableOptions;
        const { subAppInfo, UpdateState } = this.props;
        const { SubApplicationDetail } = subAppInfo;
        const { ApplicationName } = SubApplicationDetail;
        const dataListName = ApplicationName.toLowerCase() + "_dataList";
        if(!onColumnSelectAction || !attributeName) return; 

        const response = await onColumnSelectAction(checkedStatus, this.props);
        if(response &&  response.status.toLowerCase() === 'success') {
            const { data } = response;
            if(checkedStatus === 'true') {
                this.setState({ isColumnChecked: true });
            }
            else {
                this.setState({ isColumnChecked: false });
            }
            UpdateState(dataListName, data.VehicleList, true);
        }
        
    }

    toggleSelect = (e, rowdata = false) => {
        let { TableOptions } = this.props;
        let selectable = TableOptions.Selectable ? TableOptions.Selectable : [];
        let { externalActions = false } = selectable;
        const checkedStatus = e.target.checked.toString();
        if(externalActions) {
            if(rowdata) {
                this.handleTableRowSelect(checkedStatus, rowdata, selectable);
            }
            else {
                this.handleTableColumnSelect(checkedStatus, rowdata, selectable);
            }
        }
        else {
            this.handleCheckBox(e, rowdata, selectable);
        }
    }

    getTableDataList = (dataList) => {
        let { TableOptions } = this.props;
        let { lazyLoading = false } = TableOptions;
        let { page, rowsPerPage } = this.state;

        if (lazyLoading) {
            return this.onRowDataManipulation(dataList);
        } else {
            return this.onRowDataManipulation(dataList).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        }
    }

    render() {

        let paginationProperties = {
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            changePage: this.handleChangePage,
            changeRows: this.handleChangeRowsPerPage,
        }

        let columnOptions = {
            filtercriteria: this.state.filtercriteria,
            handleFilter: this.handleFilterRequest,
            sortOrder: this.state.order,
            sortBy: this.state.orderBy,
            handleSort: this.handleRequestSort
        }

        let { dataList = [], columnList, TableOptions, subAppInfo, IsExpandable, ChildSubApplicationList } = this.props;
        let tableSize = 0,colSpanValue=0;
        let actionRequired = TableOptions['ActionListOptions'].length > 0 ? true : false;
        let detailRequired = TableOptions.ChildrenComponent != null || ChildSubApplicationList && ChildSubApplicationList.length > 0;
        actionRequired && (colSpanValue++);
        detailRequired && (colSpanValue++);
        const { headerActions = null } = TableOptions;
        if (Array.isArray(dataList)) {
            tableSize = dataList.length;
        }
        let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
        if (SubApplicationDetail.Description !== undefined) {
            var HeadingNeeded = SubApplicationDetail.Description.length > 0 ? true : false;
        }
        return (
            <Fragment>
                {HeadingNeeded && !IsExpandable && <TableHeading {...this.props} />}
                {
                    headerActions && headerActions.actionVisibility && 
                    <Suspense fallback={<div>Loading...</div>}>
                        <TableHeaderActions 
                            {...this.props}
                        />
                    </Suspense>
                }
                <Paper style={{ width: "100%" }} elevation={5}>
                    <TableContainer ref={this.childformshrink}>
                        <Table aria-label="collapsible table">
                            <TableHead style={{ width: "100%" }}>
                                {
                                    <DataTableColumns
                                        {...this.props}
                                        toggleSelect={this.toggleSelect}
                                        isColumnChecked={this.state.isColumnChecked}
                                        columnOptions={columnOptions}
                                    />
                                }
                                {
                                    TableOptions.FilteringNeeded &&
                                    <CustomFilterRow
                                        {...this.props}
                                        toggleSelect={this.toggleSelect}
                                        isColumnChecked={this.state.isColumnChecked}
                                        columnOptions={columnOptions}
                                    />
                                }
                            </TableHead>
                            <TableBody>
                                {
                                    this.getTableDataList(dataList)
                                        .map((data, index) => (
                                            <DataTableRow
                                                {...this.props}
                                                toggleSelect={this.toggleSelect}
                                                rowData={data}
                                                shrink={this.childformshrink}
                                                DataTableRowIndex={index}
                                            />
                                        ))
                                }
                                {
                                    tableSize === 0 && <NoRecords colSpanValue={colSpanValue} columnList={columnList} />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        tableSize > 0 &&
                        <DataTablePagination
                            {...this.props}
                            paginationProperties={paginationProperties}
                        />
                    }
                </Paper>
            </Fragment>
        )
    }
}

export default DataTable;
