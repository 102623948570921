import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()

// Toaster Config Start
export const triggerToast = (message,msgTitle="Try again! Something went wrong. ",updateState) => {
  toast.error(msgTitle + message, { position: toast.POSITION.TOP_CENTER })
  updateState && updateState("loading",false,true)
}

export const triggerInfoToast = (message, properties= { position: toast.POSITION.TOP_CENTER}) => {
  toast.info( message, properties)
}

export const successToast = (message) => {
  toast.success(message);
}

export const failureToast = (message) => {
  toast.error(message,{position: toast.POSITION.TOP_CENTER});
}

export const defaultToast = (msg,option)=>  toast(msg,option)

export const Toast = toast
//  Toaster config end

export const changeIDtoUID = (req) => {

  Object.entries(req).map(([resKey, resValue]) => {
    if (resValue.constructor === Array) {
      resValue.map(valueRow => {
        Object.entries(valueRow).map(([objKey, objValue]) => {
          if (objValue.constructor === Array) {
            objValue = objValue.map(row => {
              delete row.ID;
              delete row.PARENTID;
              row.uid = uuidv4();
              return row;
            })
            resValue[0][objKey] = objValue
          }
        })
      })
    }
  })
  return req;
}


// Scroll config Start
export const scrollBehaviour=(state1,state2)=>{
  if((state1) && (!state2)){
    window.scrollTo({top:0,behavior:"instant"})
  }
}

export const scrollBehaviourDown =() =>{
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })
}
// Scroll config end


//Used to format percentage
export const changePercentageFormat = num => num+'%'

export const sanitizeData = (payload) => {
  for (const key in payload) {
    if (typeof payload[key] === 'string') {
      payload[key] = encodeHTMLTags(payload[key])
    } else if (typeof payload[key] === 'object') {
      payload[key] = sanitizeData(payload[key]) // Recursively decode nested objects
    }
  }
  return payload;
}

const encodeHTMLTags = (input) => {
  return input.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}

const decodeHTMLTags = (input) => {
  return input.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
};

export const decodeHTML = (jsonObject) => {
  for (const key in jsonObject) {
      if (typeof jsonObject[key] === 'string') {
          jsonObject[key] = decodeHTMLTags(jsonObject[key])
      } else if (typeof jsonObject[key] === 'object') {
          jsonObject[key] = decodeHTML(jsonObject[key]) // Recursively encode nested objects
      }
  }
  return jsonObject;
}

export const removeHtmlTags = (htmlContent, Tag) => {
  try {
      let startTag= `<${Tag}`;
      let endTag= `</${Tag}>`;
      if (htmlContent.match(startTag) && htmlContent.match(endTag)) {
          let length = endTag.length
          let positionStart = htmlContent.search(startTag);
          let position = htmlContent.search(endTag);
          let positionEnd = position + length;
          let result = htmlContent.slice(positionStart, positionEnd);
          htmlContent = htmlContent.replace(result, " ");
          return removeHtmlTags(htmlContent,Tag)
      }
      else {
          return htmlContent
      }
  } catch (error) {
      triggerToast(error.message)
      console.log("error in removeHtmlTags", error)
  }
}

export const getRequiredIndex=(source,keyName,value)=>{
  return source.findIndex((element,index)=>element[`${keyName}`]===value)
}

export const getIndex = (props,navDetails,getRequiredIndex,pagename="") =>{
  let value = props.pageInfo.navigationname
  let key = value && props.UIFlowReducer[`current${value}ConsoleInfo`] ? (props.UIFlowReducer[`current${value}ConsoleInfo`].name ? props.UIFlowReducer[`current${value}ConsoleInfo`].name : props.pageInfo.landingpage ) : props.pageInfo.landingpage;
  if(pagename !== "")
  key=pagename;
  let index = getRequiredIndex(navDetails,'name',key)
  return index
}


