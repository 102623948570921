import React from 'react';
import * as NavigationControls from '../controls/Navigation';

export default function DynamicNavigation(props) {
    const { components } = props;
    if (!components) return <div>Processing meta data...</div>
    return (
        <React.Fragment>
            {
                components.map(component => {
                    if (component.RenderValue) {
                        let { ComponentType } = component;
                        const UIComponent = NavigationControls.default[ComponentType.toLowerCase()];
                        if (UIComponent) {
                            return (
                                <UIComponent
                                    key={component.SubApplicationName + component.AttributeName}
                                    component={component}
                                    {...props}
                                />
                            )
                        } else return null;
                    } else return null;
                })
            }
        </React.Fragment>
    )
}