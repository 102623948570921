import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DynamicComponent from '../../../corecomponents/DynamicComponent';
import PageLoader from '../../../controls/PageLoader'

class DialogWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogLoader: false
        }
    }

    handleSave = () => {
        this.props.handleSave();
    }

    handleError = () => {
        this.props.handleError();
    }

    handleClose = () => {
        if(this.props.dialogUtils && this.props.dialogUtils.handleClose){
            this.props.dialogUtils.handleClose(this.state, this.props);
        }
    else{
        let subAppName = this.props.subAppInfo.SubApplicationDetail.ApplicationName.toLowerCase()
        if(this.props.componentState[`${subAppName}_showDialog`] === true)
            this.props.functions.updateValues({[`${subAppName}_showDialog`]: false})
    }
}

    handleDialogLoader = (params) => {
        this.setState({dialogLoader: params})
    }

    render() {
        let subAppName = this.props.subAppInfo.SubApplicationDetail.ApplicationName.toLowerCase()
        let { maxWidth = "lg", titleNeeded = false, dialogTitle = ''} = this.props.subAppInfo.SubApplicationDetail.mAction ? this.props.subAppInfo.SubApplicationDetail.mAction : this.props.dialogOptions
        let template = this.props.pageInfo
        let disableEnforceFocus = template && template.disableEnforceFocus ? template.disableEnforceFocus : false
        let showDialog = this.props.componentState[`${subAppName}_showDialog`]
        let dialogFunctions = {
            handleDialogLoader: this.handleDialogLoader
        }
        return (
            <React.Fragment>
                {
                    showDialog &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} xl={12}>
                                <Dialog 
                                    disableEnforceFocus = {disableEnforceFocus}
                                    open={showDialog} 
                                    onClose={this.props.closePopup}
                                    aria-labelledby="form-dialog-title" 
                                    fullWidth={true}
                                    maxWidth={maxWidth}
                                    disableBackdropClickfullWidth={true}
                                    disableBackdropClick={true}
                                >
                                    <div className={`flex ${subAppName}__wrapperClass` }>
                                        {
                                            titleNeeded && 
                                            <DialogTitle id="form-dialog-title">  
                                                <span> {dialogTitle} </span> 
                                            </DialogTitle>
                                        }
                                        <span className ="flexPushRight">
                                            <IconButton><CloseIcon onClick={this.handleClose}/></IconButton>
                                        </span>
                                    </div>
                                    <DialogContent>
                                        <DynamicComponent 
                                            {...this.props}
                                            functions={{...this.props.functions, ...dialogFunctions}}
                                        />
                                            {this.props.children}
                                    </DialogContent>
                                    {
                                        this.state.dialogLoader && <PageLoader />
                                    }
                                </Dialog>
                        </Grid>
                    </Grid>
                }
            </React.Fragment>
        )
    }
}

export default DialogWrapper;