import React, { Component } from "react";
import { FormControl, Grid, InputLabel } from "@material-ui/core";
import TextValidator from "./TextValidatorV2";
import { ValidatorForm } from "react-material-ui-form-validator";
import { renderIcon } from '../utils/LOB_Util/AU/VIN_Validator';
import InputAdornment from '@material-ui/core/InputAdornment';


class Text extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" }
    this.labelRef = React.createRef();
  }

  async componentDidMount() {
    if (false) {
      const { component } = this.props;
      let label = unescape(component.ComponentLabel);
      const node = this.labelRef.current;
      node.innerHTML = label;
    }
    if (this.props.component.DefaultValue !== "" && this.props.componentvalue === undefined) {
      this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
    }
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("MaxLength", (value,maxlength) => {
      if (value.length>maxlength) {
        return false;
      }
      return true;
    });


  }

  componentDidUpdate(prevProps) {
    if (this.props.component.DefaultValue !== "" &&  (prevProps.component.DefaultValue !== this.props.component.DefaultValue)) {
      this.props.updateValueWithSubplicationName && this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component, this.props, prevProps);
    }

  }

  onChange = (e) => {
    e.target.value = e.target.value && e.target.value.trim() === "" ? "" : e.target.value;
    if (this.props.customTable) {
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      // console.log("Calling handleRowLevelInputChange",e.target.name, value[e.target.name]);
      this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex, this.props);
    } else if(this.props.component.OnChangeAction!=='' && this.props[this.props.component.OnChangeAction]) {
        let value = Object.assign({}, { [e.target.name]: e.target.value });
        this.props[this.props.component.OnChangeAction](value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
      } else {
       let value = Object.assign({}, { [e.target.name]: e.target.value });
       this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
      }
    }
    onFocus = (e) => {
      this.setState({ value: e.target.value })
    }
    onBlur = (e) => {
      if (this.state.value !== e.target.value && (this.props.component.OnBlurAction === "callMetadata") && this.props.callback) {
        this.props.callback()
      }
    }
    onKeyDown = (e) =>{
      if(this.props.component.OnKeyDownAction!=='' ){
        this.props[this.props.component.OnKeyDownAction] && this.props[this.props.component.OnKeyDownAction](e,this.props);
      }
      else {
        this.props.onKeyDown && this.props.onKeyDown(e,this.props);
       }
    }

    conditionValidation = (value, validations) => {
      let conditionCheck = false;
      if(!value) return '';
      conditionCheck =  validations.every(condition=>{
        let val = condition.split(':')
        let index =  condition.indexOf(':')
        let con = val[0]
        let checkValue = condition.slice(index+1)
        if(con === 'minFloat' && checkValue){
          return Number(value) >= Number(checkValue)
        }
       else if(con === 'maxFloat' && checkValue){
          return  Number(value) <=  Number(checkValue)
        }
       else if(con === 'MaxLength' && checkValue){
         return value.length <= checkValue
        }
       else if((con === 'matchRegexp' && checkValue)){
           const pattern = new RegExp(checkValue);
          return pattern.test(value)
        }
        else {
            return true;
        }
      })
      return conditionCheck ? 'hideError' : '';
    }

  render() {
    const { component, isDynamicTable, datatableRowindex, pagelevelValues, variant } = this.props;
    let componentvalue = this.props.componentvalue;
    let isRequired = component.IsRequired === "Y" ? true : false;
    let isReadOnly = component.IsReadonly;
    let componentLabel = "" ;
    if(component.ComponentLabel){
      componentLabel = isRequired ? component.ComponentLabel + " *" : component.ComponentLabel;
    }
    const validations = [];
    const errorMessages = [];
    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
      validations.push("IsEmpty");
      errorMessages.push(component.RequiredMessage);
    }

    if(component.MaxLength!=0)
   {
      validations.push('MaxLength:' + component.MaxLength);
      errorMessages.push(component.ValidatorMessage);
   }
    // Validation for checking the minimum and maximum Limit of value
    if (component.MaxValue > 0) {
      validations.push('maxFloat:' + component.MaxValue);
      errorMessages.push(component.ValidatorMessage);
    }
    if(component.MinValue!=="" && component.MinValue != 0){
      validations.push('minFloat:' + component.MinValue);
      errorMessages.push(component.ValidatorMessage);
    }
    if (component.ValidationRegex) {
      validations.push('matchRegexp:'+ component.ValidationRegex);
      errorMessages.push(component.ValidatorMessage);
      }
    let value = "";
    if (component.DefaultValue === "" && componentvalue !== undefined) {
      value = componentvalue;
    }
    if (component.DefaultValue !== "" && componentvalue !== undefined) {
      value = componentvalue;
    }
    if (component.DefaultValue !== "" && componentvalue === undefined) {
      value = component.DefaultValue;
    }
    if (component.DefaultValue === "" && componentvalue === undefined) {
      value = "";
    }
    if (
      component.DefaultValue !== "" &&
      component.AttributeValue === "" &&
      componentvalue !== undefined
    ) {

      value = componentvalue;
    }

    let labelId = component.ComponentId + "_label";
    let labelClassName = component.IsRequired === "Y" ? "mandatory attributeLabel" : "attributeLabel";
    let endAdnt = renderIcon({ component, data : pagelevelValues ? pagelevelValues[component.SubApplicationName] : {} })
    if(component.OutputFormatPattern==="percentage"){
      endAdnt=<React.Fragment>
          <InputAdornment position="end">%</InputAdornment>
          {endAdnt}
      </React.Fragment>
    }
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    const styleObject = component.AdjacentLabel === 'Y' ? {display:'flex'} : {}

    const dynClass =  this.conditionValidation(value, validations)
    return (
      <>
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size}  className={`${component.AdjacentLabel !== 'Y' ? 'custom-text lineHeight custom-selectContainer' : null} ${component.AttributeStyleClass}`} style={styleObject}>
      {component.AdjacentLabel === 'Y' ?
        <>
          <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className={`${component.AttributeStyleClass}_Label`}
          >
            <InputLabel htmlFor={component.AttributeName} className="custom-label-down text-font adjacent_label" ref={this.labelRef}>
              {componentLabel}
            </InputLabel>
          </Grid>
          <Grid
          item
          xs={8}
          sm={8}
          md={8}
          lg={8}
          className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
          >
        <FormControl className={`custom-input custom-dropdown-select custom-dropdown-selectv2 ${component.AttributeStyleClass} ${dynClass}`}>
          <TextValidator
            labelId={labelId}
            ComponentId={component.ComponentId}
            name={component.AttributeName}
            value={value}
            component={component}
            onChange={this.onChange}
            errorMessages={errorMessages}
            validators={validations}
            onFocus={this.onFocus}
            disabled={isReadOnly}
            variant={variant}
            onBlur={ this.onBlur}
            onKeyDown={this.onKeyDown}
            className={`${dynClass}`}
            InputProps={{
            endAdornment: endAdnt
          }}
          autoComplete={autocomplete}
          />
        </FormControl>
            </Grid>
          </>:
          <FormControl className={`custom-input custom-dropdown-select custom-dropdown-selectv2 ${component.AttributeStyleClass} ${dynClass}`}>
            {/* {!isDynamicTable && (
            <InputLabel
              ref={this.labelRef}
              id={labelId}
              className={`custom-dropdown-label text-font small_label ${labelClassName} `}
            >
              {component.ComponentLabel}
            </InputLabel>
          )} */}
          <TextValidator
            labelId={labelId}
            ComponentId={component.ComponentId}
            name={component.AttributeName}
            value={value}
            component={component}
            onChange={this.onChange}
            disabled={isReadOnly}
            errorMessages={errorMessages}
            validators={validations}
            variant={variant}
            onFocus={this.onFocus}
            onBlur={ this.onBlur}
            onKeyDown={this.onKeyDown}
            className={`${dynClass}`}
            InputProps={{
            endAdornment: endAdnt
          }}
          autoComplete={autocomplete}
          />
      </FormControl>}
      </Grid>
      </>
    );
  }
}

export default Text;
