import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/Warning';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import FlagIcon from '@material-ui/icons/Flag';
import CloseIcon from '@material-ui/icons/Close';

const Icons = {
    'success': CheckCircleIcon,
    'error': WarningIcon,
    'change': BorderColorIcon,
    'loading': CircularProgress,
    'flag' : FlagIcon,
    'close' : CloseIcon
};

export default Icons;
