import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Button } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { renderIcon } from '../utils/LOB_Util/AU/VIN_Validator';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
    }),
);

const Decoder = (props) => {

    //state
    const [loading, setLoading] = useState(false);

    const { component, componentLabel, componentvalue, handleInputChangeV2, pagelevelValues } = props;
    const classes = useStyles();

    const invokeDecodeApi = async () => {
        setLoading(true);
        const callback = props[component.OnClickAction];
        if(callback){
            try {
                await callback(component);
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false);
            }
        }
    }

    const handleInputChange = (e) => {
        let value = {
            [e.target.name]: e.target.value
        };
        handleInputChangeV2(value, component);
    }
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    return (
        <React.Fragment style={{ display : 'flex', alighItem : 'center' }}>
            <Grid container spacing={3} className="decode-container">
                <Grid className={component.StyleClass} item xs={12} sm={12} md={(componentvalue && component.IsReadonly !== 'true') ? 10 : 12} lg={(componentvalue && component.IsReadonly !== 'true') ? 10 : 11}>
                    <FormControl className={`decode-input-form ${clsx(classes.margin, classes.textField)}`}>
                        <InputLabel htmlFor="standard-adornment-password">{component.IsRequired === 'Y' ? `${componentLabel} *` : `${componentLabel}`}</InputLabel>
                        <Input
                            value={componentvalue}
                            name={component.AttributeName}
                            onChange={(e) => handleInputChange(e)}
                            disabled={component.IsReadonly}
                            autoComplete={autocomplete}
                            endAdornment={
                                <InputAdornment position="end">
                                    {renderIcon({ component, data : pagelevelValues[component.SubApplicationName] })}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                {
                    component.IsReadonly !== 'true' && 
                    <Grid item xs={8} sm={4} md={2} lg={2}>
                        { componentvalue &&  <Button className="primaryButton decode-btn" onClick={() => invokeDecodeApi()}>{loading ? <CircularProgress className="buttonLoader" /> : 'Verify VIN'}</Button>}
                    </Grid>
                }
            </Grid>
        </React.Fragment>
    )

}

export default Decoder;
