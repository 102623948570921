import { format } from "date-fns"
import { getDateWithoutOffset } from "./DateUtils"
import { AxiosInstance, getServiceRequestDetail } from "./API"
import store from "../../../../store"
import appActions from "../../../../actions"
import { triggerToast } from "./HelperUtils"


const { actionType } = appActions


export const deleteRowMetaData=(props)=>{
  props.forEach((val)=>{
    if(val.hasOwnProperty('rowMetaData')){
      delete val.rowMetaData
      return val
    }else{
      return val
    }
  })
}
export const deleteObjRowMetaData = (props) => {
  for (const [key, value] of Object.entries(props)) {
    if(Array.isArray(value)){
        deleteRowMetaData(value)
      }
    }
  }

  export const attributeSplit = (params) => {
    let val = {}
    let attributeParam = params.split(",")
    attributeParam.forEach((key) => {
      let item = key.split("~");
      val[item[0]] = item[1]
    })
    return val
  }

  export const attributeSplitV2 = (params) => {
    let val = {}
    let attributeParam = params.split(",")
    attributeParam.forEach((key) => {
      if(key.includes("~")){
        let item = key.split("~");
        val[item[0]] = item[1]
      }else  val[key]=key

    })
    return val
  }

  const getStateListString = (states) => {
    let stateString = states.toString()
    let replacedString
    if (stateString.includes(",")) {
        replacedString = stateString.replaceAll(",", '","')
    }
    else {
        replacedString = stateString
    }
    return replacedString
  }

  export const constructMetaDataRequestV1 = (subAppInfo, props = {}) => {
    const reduxState = store.getState();
    let { subappName, objectId, objectVerId, state, subApplicationGroupList } = subAppInfo;
    let { transactionPrimaryObject, viewType = undefined, policyStateList = undefined, quoteNumber = undefined, EffectiveDate = undefined, quoteEffectiveDate = undefined, } = props;
    let isProuctFlow = transactionPrimaryObject && transactionPrimaryObject.isProuctFlow
    let request = JSON.parse(JSON.stringify(require("../../models/metadataRequest.json")));
    request.ServiceRequestDetail = getServiceRequestDetail();
    request.OwnerId = sessionStorage.getItem("OwnerID");

  //  need to uncomment
   if (objectId && objectVerId) {
      let { clientInfo } = reduxState.ownerResources
      if(objectId==='Multi_Api')
      {
        request.ObjectId=clientInfo.multiLob_ObjectId;
        request.ObjectVerId=clientInfo.multiLob_ObjectVerId;
      }
      else if(objectId==='API'){
        request.ObjectId=reduxState.UIFlowReducer.productDetails.ProductID;
        request.ObjectVerId=reduxState.UIFlowReducer.productDetails.ProductVerID;
      }
      else{
      request.ObjectId = objectId;
      request.ObjectVerId = objectVerId;
      }

    } else {
      request.ObjectId = localStorage.getItem("ObjectId");
      request.ObjectVerId = localStorage.getItem("ObjectVerId");
    }

    request.EffectiveDate = EffectiveDate ? EffectiveDate : format(new Date(), 'yyyy-MM-dd');
    request.SubApplicationNameList = subappName ? (!(Array.isArray(subappName))) ? [subappName]: subappName : [];

    if (subApplicationGroupList) {
      request['SubApplicationGroupNameList'] = subApplicationGroupList;
    }

    let transactionKeyList = [];

    if (objectId && objectVerId) {
      transactionKeyList.push(
        {
          key: "Object::Quote::EffectiveDate",
          value: "2020-07-01",
        },
        {
          key: "ProductID",
          value: objectId
        },
        {
          key: "ProductVerID",
          value: objectVerId
        },
        {
          key: "OwnerId",
          value: sessionStorage.getItem("OwnerID")
        }
      );
    }

    if (viewType) {
      transactionKeyList.push({
        key: "viewType",
        value: viewType,
      }
      );
    }

    let { currentPageIndex} = reduxState.UIFlowReducer;
    if (reduxState.navigationReducer.pageNavigation && reduxState.navigationReducer.pageNavigation[currentPageIndex - 1]) {
      reduxState.navigationReducer.pageNavigation[currentPageIndex - 1].hasOwnProperty('pageName')?
      transactionKeyList.push({
        key: "pagename",
        value: `${reduxState.navigationReducer.pageNavigation[currentPageIndex - 1]["pageName"]}`,
      }):
      transactionKeyList.push({
        key: "pagename",
        value: `${reduxState.navigationReducer.pageNavigation[currentPageIndex - 1]["name"]}`,
      });
    }

    if (quoteNumber) {
      transactionKeyList.push({
        key: "QuoteInformation__QuoteNumber",
        value: quoteNumber,
      }
      );
    }
    if (reduxState.metadataReducer.quoteResponse.EffectiveFromDate) {
      let effectiveDate = reduxState.metadataReducer.quoteResponse.EffectiveFromDate
      effectiveDate = effectiveDate ? getDateWithoutOffset(effectiveDate) : ''
      transactionKeyList.push({
        key: "quoteEffectiveDate",
        value: format(new Date(effectiveDate), "MM/dd/yyyy")
      }
      );
    }
    if(reduxState.userInfoReducer.privilageList.length>0){
      reduxState.userInfoReducer.privilageList.forEach(privilage=>{
        transactionKeyList.push({
          key: privilage.PrivilegeName,
          value: true
        });
      })

    }
    if((reduxState.UIFlowReducer.pageName !== "" && reduxState.UIFlowReducer.pageName !== undefined) ||  (reduxState.UIFlowReducer.pageData && reduxState.UIFlowReducer.pageData.pageName)){
      const pageName = reduxState.UIFlowReducer.pageData && reduxState.UIFlowReducer.pageData.pageName ? reduxState.UIFlowReducer.pageData.pageName : reduxState.UIFlowReducer.pageName;
      transactionKeyList.push({
        key: "pageName",
        value: pageName
      });
    }
    if(reduxState.userInfoReducer.userName){
      transactionKeyList.push({
        key: "userName",
        value: reduxState.userInfoReducer.userName
      });
    }
    if(reduxState.userInfoReducer.UserPrimaryObjectId){
      transactionKeyList.push({
        key: "UserPrimaryObjectId",
        value: reduxState.userInfoReducer.UserPrimaryObjectId
      });
    }
    if(reduxState.userInfoReducer.userRole){
      transactionKeyList.push({
        key: "userRole",
        value: reduxState.userInfoReducer.userRole
      });
    }
    if (reduxState.metadataReducer.policyStateList && reduxState.metadataReducer.policyStateList.length > 0 && viewType === 'Add') {
      transactionKeyList.push({
        key: "PolicyStateList",
        value: reduxState.metadataReducer.policyStateList
      }
      );
    }

    if (viewType === 'Edit' && policyStateList && policyStateList.length > 0) {
      transactionKeyList.push({
        key: "PolicyStateList",
        value: policyStateList
      });
    }

    if (transactionPrimaryObject) {
      Object.keys(transactionPrimaryObject).forEach((SubapplicationValues) => {
        if (transactionPrimaryObject[SubapplicationValues] !== undefined && transactionPrimaryObject[SubapplicationValues] !== null) {
          if (typeof transactionPrimaryObject[SubapplicationValues] === "string") {
            transactionKeyList.push({
              key: SubapplicationValues,
              value: transactionPrimaryObject[SubapplicationValues],
            });
          } else if (
            Array.isArray(transactionPrimaryObject[SubapplicationValues])
          ) {
          } else {
            Object.keys(transactionPrimaryObject[SubapplicationValues]).forEach(
              (key) => {
                if (
                  transactionPrimaryObject[SubapplicationValues][key] &&
                  transactionPrimaryObject[SubapplicationValues][key] !== "" &&
                  (typeof transactionPrimaryObject[SubapplicationValues][key] ===
                    "string" ||
                    typeof transactionPrimaryObject[SubapplicationValues][key] ===
                    "boolean" ||
                    typeof transactionPrimaryObject[SubapplicationValues][key] ===
                    "number")
                ) {
                  transactionKeyList.push({
                    key: SubapplicationValues + "__" + key,
                    value: transactionPrimaryObject[SubapplicationValues][key],
                  });

                }
                else if (
                  transactionPrimaryObject[SubapplicationValues][key] &&
                  transactionPrimaryObject[SubapplicationValues][key].constructor === Array &&
                  typeof transactionPrimaryObject[SubapplicationValues][key][0] !== 'object'
                ){
                  if(!request.MultiTransactionKeys){
                    request.MultiTransactionKeys = [];
                  };
                  request.MultiTransactionKeys.push({
                    Key: SubapplicationValues + "__" + key,
                    Values:
                    transactionPrimaryObject[SubapplicationValues][key],
                  })
                    let replacedString =  getStateListString(transactionPrimaryObject[SubapplicationValues][key])
                    transactionKeyList.push({
                      key: SubapplicationValues + "__" + key,
                      value: replacedString,
                    });
                }
              }
            );
          }
        }
      });
    }

    reduxState.metadataReducer && Object.entries(reduxState.metadataReducer).map(([key, value]) => {
      if(typeof value !== 'object')
        transactionKeyList.push({
          key: key,
          value: value,
        });
       if(Array.isArray(value)) {
        if(!request.MultiTransactionKeys){
          request.MultiTransactionKeys = [];
        };
        request.MultiTransactionKeys.push({
          Key:key,
          Values: value,
        })
       }
      })
    
    if (isProuctFlow==='Yes'){
      transactionKeyList.push({
        key: "RateBasedMetadataCall",
        value: ''
      });
    }
    else if(isProuctFlow==='No'){
      // else if(true|| isProuctFlow=='No'){
      transactionKeyList.push({
        key: "RateBasedMetadataCall",
        value: 'value'
      });
    }
    // transactionKeyList.push({
    //   key: "ProductDetailsWithState__RatingProductVerNumber",
    //   value: 'value'
    // });
    // transactionKeyList.push({
    //   key: "IsProductDetailSaved",
    //   value: 'true'
    // });
    // transactionKeyList.push({
    //   key: "DisplayNotesSection",
    //   value: 'value'
    // });  ---->check
    if (transactionKeyList.length > 0) {
      request.TransactionPrimaryKey.application = transactionKeyList;
    }

    request.State = state ? state : "CW";
    return request;
  };
  export const pushMetaDataToDataList = async (SubApplicationDetailList,componentState, indexKey) => {
    try {
      SubApplicationDetailList.forEach((el,index)=>{

        if(el.Type==='EditableSummary'&&componentState.hasOwnProperty(el.ApplicationName.toLocaleLowerCase()+'_dataList'))
        {
          let List=[...componentState[el.ApplicationName.toLocaleLowerCase()+'_dataList']]
          List.forEach((val,index)=>{
            componentState[el.ApplicationName.toLocaleLowerCase()+'_dataList'][index]["rowMetaData"]=el.AttributeDetailList

          })
        }
      })

      // let riskschedule_dataList = dataList.map((el, idx) => {
      //   el["rowMetaData"] = attributeList[0];
      //   if (indexKey) {
      //     el[indexKey] = idx + 1;
      //   }
      //   return el
      // })

    } catch (e) {
      console.log(e);
      triggerToast('')
    }

  }


  export const constructAndInvokeMetadataCall = async (subAppInfo, props = {}, callback, cancellationObj) => {
    let request = await constructMetaDataRequestV1(subAppInfo, props);
    // console.log("request constructAndInvokeMetadataCall------->",request)
    let response = await invokeMetadata(request, callback, cancellationObj);
    if(response === 'APIcancelled'){
      return response;
    }
    // console.log("response constructAndInvokeMetadataCall------->",request)
    store.dispatch({ type: actionType.SET_METADATA_REQUEST, payload: JSON.stringify(request, null, 2) })
    store.dispatch({ type: actionType.SET_METADATA_RESPONSE, payload: JSON.stringify(response, null, 2) })
    callback && callback(request, response);
    return response
  }

  export const invokeMetadata = async (metadataRequest,callback, cancellationObj) => {
    let metadataResponse;
    try {
      metadataResponse = await AxiosInstance.post(
        "core/getmetadata",
        metadataRequest,
        {...cancellationObj}
      );
      metadataResponse = await metadataResponse.data;
      return metadataResponse;
    } catch (error) {
      console.error("Error in Metadata Invocation", error.message);
      return 'APIcancelled';
    }
  };

  export const constructMetaDataRequest = (subApplication, transactionValues = {},infoObj) => {

    let request = JSON.parse(JSON.stringify(require("../../models/metadataRequest.json")));

    let {ObjectId,ObjectVerId} = infoObj;

    if(ObjectId){
      request.ObjectVerId = ObjectVerId;
      request.ObjectId = ObjectId;
    }

    request.ServiceRequestDetail = getServiceRequestDetail();
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request['EffectiveDate'] = format(new Date(), 'yyyy-MM-dd');

    let subApplicationList = [];

    if (Array.isArray(subApplication)) {
      subApplication.map((subAppName) => {
        subApplicationList.push({
          SubApplicationName: subAppName,
        });
      });
      request.SubApplicationNameList = subApplicationList;
    } else {
      request.SubApplicationNameList[0].SubApplicationName = subApplication;
    }

    let transactionKeyList = [];
    transactionKeyList.push({
      key: "Object::Quote::EffectiveDate",
      value: format(new Date(), "yyyy-MM-dd"),
    });

    Object.keys(transactionValues).forEach((key) => {
      if (
        typeof transactionValues[key] === "string" ||
        typeof transactionValues[key] === "boolean" ||
        typeof transactionValues[key] === "number"
      ) {
        transactionKeyList.push({
          key: key,
          value: transactionValues[key],
        });
      } else if (typeof transactionValues[key] === "object") {
      }
    });

    if (transactionKeyList.length > 0) {
      request.TransactionPrimaryKey.application = transactionKeyList;
    }

    return request;
  };


export const constructAndInvokeMetadataCallV2 = async (subAppInfo, props = {}, callback) => {
  // state and effective date needs to be handled for more scenarios
  let reduxState = store.getState();
  if(subAppInfo.state.toLowerCase() === 'primarystate') subAppInfo.state = reduxState.UIFlowReducer.primaryState
  if(subAppInfo.state.toLowerCase() === 'policystate') {
    let { transactionPrimaryObject } = props
    if(transactionPrimaryObject.PolicyDetail && transactionPrimaryObject.PolicyDetail.State && transactionPrimaryObject.PolicyDetail.State != '')
      subAppInfo.state = transactionPrimaryObject.PolicyDetail.State;
    if(transactionPrimaryObject.PrimaryState && transactionPrimaryObject.PrimaryState.PrimaryState && transactionPrimaryObject.PrimaryState.PrimaryState != '')
      subAppInfo.state = transactionPrimaryObject.PrimaryState.PrimaryState;
  }
  let request = await constructMetaDataRequestV1(subAppInfo, props);
  let response = await invokeMetadata(request, callback);
  store.dispatch({ type: actionType.SET_METADATA_REQUEST, payload: JSON.stringify(request, null, 2) })
  store.dispatch({ type: actionType.SET_METADATA_RESPONSE, payload: JSON.stringify(response, null, 2) })
  callback && callback(request, response);
  return response
}

export const getAttributeList = (response) => {
  let attributeList = [];
  if (response.ApplicationDetail) {
    attributeList =
      response.ApplicationDetail.SubApplicationDetailList[0]
        .AttributeDetailList;
  }
  return attributeList;
};

export const getTableActions = (pageMetadata = {}, property) => {

  let tableActions = {};

  if(pageMetadata.hasOwnProperty('ApplicationDetail') && pageMetadata.ApplicationDetail.hasOwnProperty('SubApplicationDetailList') && pageMetadata.ApplicationDetail.SubApplicationDetailList.length === 0)
  return tableActions;

  let { SubApplicationDetailList } = pageMetadata.ApplicationDetail;
  let value = SubApplicationDetailList.length;

  for(let i = 0; i < value; i++){
    let { Type } = SubApplicationDetailList[i];
    if(Type.toLowerCase() === "summary"){
      tableActions = SubApplicationDetailList[i].mAction;
    }
  }
  if (property) return tableActions[property];
  else return tableActions;
}

export const deleteDependentAttribute=(component,pagelevelValues,transactionPrimaryObject)=>{
  if(component.DependentComponent && component.DependentComponent.length)
    {
      component.DependentComponent.forEach(DependentAttribute =>{
        let obj=DependentAttribute.split("__");
        let c1=pagelevelValues.hasOwnProperty(obj[0]);
        let c2=transactionPrimaryObject.hasOwnProperty(obj[0])
        if(c1 || c2)
        {
          c1 && delete pagelevelValues[obj[0]][obj[1]];
          c2 && delete transactionPrimaryObject[obj[0]][obj[1]];
        }
      })
    }
    return {pagelevelValues,transactionPrimaryObject}
}

export const resetValidation=(component,props)=>{
  if(typeof(component.DependentComponent)==='object' && component.DependentComponent.length > 0){
    props.refs && props.refs.resetValidations && props.reference.resetValidations();
  }
}


export const supAppTransaction = (rowData, summaryResponse,setState,setPagelevelValues) => {
  let SubapplicationName=rowData.hasOwnProperty("SubapplicationName") && rowData["SubapplicationName"]? rowData["SubapplicationName"]:""
  let Summary_SubapplicationName=rowData.hasOwnProperty("Summary_SubapplicationName") && rowData["Summary_SubapplicationName"]?rowData["Summary_SubapplicationName"]:""
  let subApplicationNameList=[...SubapplicationName,...Summary_SubapplicationName];
  if (rowData.hasOwnProperty('Summary_SubapplicationName') && rowData["Summary_SubapplicationName"]!== "") {
    let dataListName = rowData["Summary_SubapplicationName"][0].toLowerCase() + '_dataList';
    if (summaryResponse && Object.keys(summaryResponse).length > 0) {
      let field=rowData.hasOwnProperty("Summary_FieldTableName")&& rowData["Summary_FieldTableName"]!== "" ? rowData["Summary_FieldTableName"]:{}
      setState((prevState) => ({[dataListName]: summaryResponse[field], ...prevState}))
    }
  }
  if (rowData.hasOwnProperty('SubapplicationName') && rowData["SubapplicationName"]!== "") {
    let temp = rowData.hasOwnProperty("SubApplication_FieldTableName")&& rowData["SubApplication_FieldTableName"]!== "" ? rowData["SubApplication_FieldTableName"]:{}
    setPagelevelValues((prevState) => ({ [rowData["SubapplicationName"]]: summaryResponse[temp] , ...prevState}))
  }
  return subApplicationNameList
}

export const UpdateMetadata = async (props, transactionPrimaryObject, functions) => {
  const { UpdateState } = functions;
  try {
    UpdateState && UpdateState("loading", true, true);
    let { objectId, objectVerId } = props.componentState.pageSubAppInfo ? props.componentState.pageSubAppInfo : props.pageInfo;
    let subappName = props.componentState.pageSubAppInfo ? props.componentState.pageSubAppInfo.subappName : props.pageInfo ? props.pageInfo.subApplication : [];
    let subAppDetail = {
      subappName: subappName,
      objectId: objectId,
      objectVerId: objectVerId
    };
    let MetadataResponse = await constructAndInvokeMetadataCall(subAppDetail, { transactionPrimaryObject: transactionPrimaryObject });
    UpdateState && UpdateState("pageMetadata", MetadataResponse, true);
  } catch (error) {
    console.error("Error in UpdateMetadata:", error);
  } finally {
    UpdateState && UpdateState("loading", false, true);
  }
};
