import React, { Component, Fragment, cloneElement } from 'react';
import {TableRow, TableCell, Collapse, Box} from "@material-ui/core";
import container from '../../utils/DIContainer';

class DataTableDetail extends Component {

    render() {
        const dynamicRender = container.get('dynamicRender')
        let { isExpanded, TableOptions, columnList,ChildSubApplicationList,rowData,hierarchicalRendering,subAppInfo,parentProps} = this.props;
        let { SubApplicationDetail } = subAppInfo,{ ApplicationName } = SubApplicationDetail;
        let colspanValue = 2;
        let actionRequired = TableOptions['ActionName'] && TableOptions['ActionName'].length > 0 ? true : false;
        colspanValue = actionRequired ? colspanValue : colspanValue -1;
        const childrenList = React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
              return cloneElement(child, { ...this.props })
            }
            return child;
        });
        if(ChildSubApplicationList && ChildSubApplicationList.length > 0)
        {
            let temp = { pagelevelValues: {}, transactionPrimaryObject: {},[ApplicationName] :{} };
            Object.entries(rowData).forEach(([key, value], index) => {
                if(typeof value === 'object' && value !== null)
                {
                    temp["pagelevelValues"][key] = value;
                    temp["transactionPrimaryObject"][key] = value;
                }
                else
                {
                    temp[ApplicationName][key] = value;
                }


              });
            parentProps=Object.assign({},this.props.parentProps,rowData,temp);
        }


        return (
            <Fragment>
                <TableRow>
                    <TableCell className="DataTableDetailDefault" colSpan={columnList.length+colspanValue}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                            {ChildSubApplicationList && ChildSubApplicationList.length > 0?
                            dynamicRender(ChildSubApplicationList, this.props.componentProps, parentProps, this.props.functions,
                             this.props.TableControls, this.props.summarySubApp, this.props.detailSummaryList,hierarchicalRendering) :
                                   childrenList
                                }
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }
}

export default DataTableDetail
