import React from 'react';
import { connect } from 'react-redux';
import {  setShowHideCondition, setStateList, setIconcondition } from '../../../../../actions/applicationMetadataAction';
import DynamicComponent from "../../../corecomponents/DynamicComponent";
import { showLoader, hideLoader } from "../../../../../actions/UIFlowActions";
import { getParentState } from '../../../utils/Common/CommonUtils';
import DataTable from '../../../controls/DataTable/DataTable';
import { LocationandBuildingAxios, getServiceRequestDetail } from "../../../utils/Common/API"
import store from "../../../../../store";
import appActions from '../../../../../actions';
import dynamicRender from '../dynamicRender';
import PageLoader from '../../../controls/PageLoader';
import { defaultToast, triggerToast } from '../../../utils/Common/HelperUtils';
import { v4 as uuidv4 } from 'uuid';
import { constructAndInvokeMetadataCall } from '../../../utils/Common/MetaUtils';

const { actionType } = appActions
class LocationSummary extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            pageMetadata: {},
            pagelevelValues: {},
            parentHierarchyArr: [],
            loading: false
        }
        this.addRow = this.addRow.bind(this);
    }

    componentDidMount = async () => {
       this.handleMetadataCall();
       await this.props.pageInfo.utilfunction[this.props.pageInfo.util].componentDidMount_Summary(this.props,this.state,this.UpdateState,this.handleMetadataCall);
    }
    componentDidUpdate(prevProps) {
        if ((!this.props.metadataReducer.isLocationSaved) && (prevProps.metadataReducer.isLocationSaved !== this.props.metadataReducer.isLocationSaved)) {
            this.handleMetadataCall();
        }
    }
    componentWillReceiveProps  = async (nextProps) => {
        if (this.props.pageInfo.utilfunction[this.props.pageInfo.util].hasOwnProperty("componentWillReceiveProps")) {
            await this.props.pageInfo.utilfunction[this.props.pageInfo.util].componentWillReceiveProps(this.props,nextProps,this.UpdateState);
        }
    }


    updateValues = (obj) => {
        this.setState({
          ...this.state,
          ...obj,
        });
      };

    invokeAPICall = async () => {
        this.props.showLoader()
        const { subApplication, entityName } = this.props.pageInfo;
        let request = Object.create({});
        request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
        request["OwnerId"] = sessionStorage.getItem("OwnerID")
        await this.props.pageInfo.utilfunction[this.props.pageInfo.util].getRequestContruction(request, this.props);
        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
            .then((response) => {
                if(response.data.hasOwnProperty('ResponseStatus') &&  response.ResponseStatus.toLowerCase() === "failed"){
                    throw new Error("Response Failed");
                }else{
                this.props.hideLoader()
                if (response.data[entityName]) {
                    if (response.data[entityName].length > 0) {
                        let parentHierarchyArr = this.state.parentHierarchyArr
                        parentHierarchyArr = this.props.metadataReducer.iconcondition ? this.props.metadataReducer.iconcondition : []
                        if (!parentHierarchyArr.includes(this.props.pageInfo.subApplication.locationSummary.toLowerCase())) {
                            parentHierarchyArr.push(this.props.pageInfo.subApplication.locationSummary.toLowerCase())
                        }
                        this.props.setIconcondition(parentHierarchyArr)
                    }
                }
                  this.setState({
                      values: { ...this.state.values, ...response.data[entityName] },
                      pagelevelValues: { ...this.state.pagelevelValues, ...response.data[entityName] }
                  }, async () => {
                      let responseEntity = response.data[entityName]
                      let idArray = []
                      if (responseEntity) {
                          await this.props.pageInfo.utilfunction[this.props.pageInfo.util].setParentHierarchyId(responseEntity, this.props, idArray);
                      }
                      if (Array.isArray(response.data[entityName])) {
                          let subapplicationName = subApplication['locationSummary'].toLowerCase();
                          let dataListName = subapplicationName + "_dataList";
                          let dataListValue = response.data[entityName]
                          this.UpdateState(dataListName, dataListValue, true);
                      }
                      this.handleMetadataCall();
                  })
              }
            })
            .catch((err) => {
                console.log(err);
                triggerToast(err.message)
            });
    }
    handleMetadataCall = async () => {
        try{
        this.props.showLoader()
        const { subApplication, objectId, objectVerId, defaultExpandNeeded = false } = this.props.pageInfo;
        let subapplicationName = [subApplication['locationSummary'],subApplication['locationMandatoryCheck']];
        if(subApplication.hasOwnProperty('NavigationButtons')) subapplicationName.push(subApplication.NavigationButtons)
        if(subApplication.hasOwnProperty('ValidationRules')) subapplicationName.push(subApplication.ValidationRules)
        let parentstate = getParentState(this.props)
        let state =  parentstate ? parentstate : 'CW' , transactionPrimaryObject = this.props.parentProps ? this.props.parentProps.transactionPrimaryObject : {}
        let metadataResponse = await constructAndInvokeMetadataCall({ subappName: subapplicationName, objectId, objectVerId, state }, { transactionPrimaryObject, EffectiveDate: this.props.values && this.props.values.EffectiveDate });
        this.UpdateState("pageMetadata", metadataResponse, true);
        if(defaultExpandNeeded  && this.state[`${this.props.pageInfo.entityName.toLowerCase()}_dataList`]  &&  this.state[`${this.props.pageInfo.entityName.toLowerCase()}_dataList`].length===0 ){
            let uid = uuidv4();
            let conditionKey = this.props.pageInfo.entityName + uid
            let conditions = Object.assign({}, this.props.metadataReducer.showHideCondition, {[conditionKey]:false})
            this.props.setShowHideCondition(conditions)
            this.updateArrayValueOnState(`${this.props.pageInfo.entityName.toLowerCase()}_dataList`, { uid, isExpanded: true }, true);
        }
        this.props.hideLoader()
        }
        catch(e){
            console.log("error in handlemetadatacall", e)
        }
    }

    UpdateState = (statename, value, rerender) => {
        if (rerender) {
            this.setState({
                [statename]: value
            });
        } else {
            this.state[statename] = value;
        }
    }

    getLocationList = async () => {
        let resultData = {
            "VehicleList": []
        }
        return resultData['VehicleList'];
    }

    findRowIndex = (statename, rowInfo, key) => {
        let currentState = JSON.parse(JSON.stringify(this.state[statename]));
        let rowIndex;
        currentState.map((row, index) => {
            if (row[key] === rowInfo[key]) {
                rowIndex = index;
            }
        });
        return rowIndex;
    }

    onDeleteLocation = async(statename, currentRowInfo) => {
        let key = 'ID';
        if(currentRowInfo[key]){
         await   this.props.pageInfo.utilfunction[this.props.pageInfo.util].deleteRow(this.props,currentRowInfo[key]);
        } else{
            key = 'uid'
        }
        let currentState = JSON.parse(JSON.stringify(this.state[statename]));
        let currentRowIndex = this.findRowIndex(statename, currentRowInfo, key);
        currentState.splice(currentRowIndex, 1);
        this.UpdateState(statename, currentState, true);
        defaultToast("Deleted Successfully")
    }

    onClickSave = (response, key, state,props,updateState) => {
        let dataList = this.state[key];
        let dataListName = key;
        let expand =this.props.expandNeeded===true ? this.props.expandNeeded : false
        let responseList =response;
        if(expand===true){
            responseList=this.props.pageInfo.utilfunction[this.props.pageInfo.util].getDataListWithExpand(response, dataList,state,props)
            store.dispatch({type: actionType.SET_FOCUS,payload: true});
            updateState('getLocationCall',true,false)
        }
        let dataListValue = responseList
        this.UpdateState(dataListName, dataListValue, true);
    }
    addRow = async (key) => {
        let  Length =this.state.hasOwnProperty(key) ? this.state[key].length:0;
        await this.props.pageInfo.utilfunction[this.props.pageInfo.util].onAddRow(this.props, this.updateArrayValueOnState, key,Length);
     }
    updateArrayValueOnState = (key, value, expand, ID) => {
        let arr = [];
        value['isExpanded'] = expand;
        if (this.state[key] !== undefined && Array.isArray(this.state[key])) {
            arr = this.state[key];
        }
        if (ID !== undefined) {
            let updated = false;
            arr.map((el, idx) => {
                if (el.ID === ID) {
                    arr[idx] = { ...value };
                    updated = true;
                }
            })
            if (updated === false) {
                arr.push(value);
            }
        } else {
            arr.push(value);
        }
        this.setState({ [key]: arr });
    }
    onExpand = (key, { ID }, isExpand) => {
        this.setState(prevState => {
            let arr = prevState[key];
            arr.map((el, idx) => {
                if (el.ID === ID) {
                    arr[idx]['isExpanded'] = isExpand
                    return;
                }
            })
            return ({ [key]: arr });
        })
    }

    saveTableData = (
      tablename,
      attributeName,
      attributeValue,
      datatableRowindex
    ) => {
      this.setState((prevState) => {
        let tableData = prevState[tablename];

        tableData = tableData.map((el) => {
          if (el === datatableRowindex) {
            el[attributeName] = attributeValue;
          }
          return el;
        });
        return { [tablename]: tableData };
      });
    };

    render() {
        let subapplicationDetailList=[];
        let childComponent = this.props.pageInfo.TemplateName ? 'templaterendering' : 'detailsummarylist'
        let TableControls= {
            tableUtils : {
                "AddRow": this.addRow,
                "onExpand": this.onExpand,
                "onChildSave" : this.onClickSave,
                "EditRow": "",
                "OnDetailEdit": ""
            },
            TableOptions : {
                "ChildrenSubApplicationName": null,
                "ChildrenComponent": childComponent,
                "AddButtonNeeded": true,
                "SortingNeeded": false,
                "FilteringNeeded": false,
                "rowsPerPageOptions": [5, 10, 25],
                "RowsPerPage": 5,
                "ActionName": "",
                "ActionListOptions": [
                    {
                        "ActionName": "RemoveVehicle",
                        "ActionDisplayName": "Delete",
                        "OnOptionClick": this.onDeleteLocation
                    }
                ]
            },
            tableFunctions: {
                "AddRow": this.addRow,
                "onExpand": this.onExpand,
                "onChildSave" : this.onClickSave,
                "deleteRow": this.onDeleteLocation
            }
        }
        const functions = {
            UpdateState:this.UpdateState,
            saveTableData:this.saveTableData,
            updateValues:this.updateValues
        }
        if(this.state.pageMetadata.ApplicationDetail) {
        subapplicationDetailList = this.state.pageMetadata.ApplicationDetail.SubApplicationDetailList

        }
           return(
                <>
                {dynamicRender(
                subapplicationDetailList,
                this.props,
                this.state,
                functions,
                TableControls,
                this.state.summarySubApp,
                this.state.detailSummaryList,
                this.state.hierarchicalRendering,
              )}
              {this.state.loading &&  <PageLoader/>}
              </>
              )
    }
}

const mapStateToProps = state => (
    {
        metadataReducer: state.metadataReducer,
        UIFlowReducer: state.UIFlowReducer
    }
)

const mapDispatchToProps = (dispatch) => (
    {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        setStateList: (stateList) => dispatch(setStateList(stateList)),
        setShowHideCondition: (showHide) => dispatch(setShowHideCondition(showHide)),
        setIconcondition: (iconcondition) => dispatch(setIconcondition(iconcondition))
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(LocationSummary);
