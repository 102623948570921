import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';

export default class Heading extends Component {
  render() {
    const { component } = this.props;
    //const screensize = component.AttributeStyleClass.split(",");
    //console.log("AttributeStyleClass for heading",screensize);
    //let className = "col-xs-11 col-sm-12 col-md-12 col-lg-12 " + component.StyleClass
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={component.Size > 0 ? component.Size : 12} lg={component.Size > 0 ? component.Size : 12} key={component.AttributeName} className={component.StyleClass}>
          {/* <Typography variant="h6" className="custom-subheading">{component.ComponentLabel}</Typography> */}
          <Typography variant="h6" className={`custom-heading heading-font`}>{this.props.componentLabel}</Typography>
        </Grid>


      </React.Fragment>
    )
  }
}
