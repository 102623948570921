import { AUTHENTICATE, GET_PRIVILEGES, LOGOUT, RESET_STORE,RESET_SUBMISSION, SET_FROM_SSO } from './types';
//import { authAxios } from '../views/runtime/utils/API';

export const authenticate = (data) => dispatch => {
    //console.log("iniside AUTHENTICATE action ", data)
    dispatch({
        type: AUTHENTICATE,
        payload: data
    })
}

export const logout = () => async (dispatch) => {
    //console.log("iniside LOGOUT action ", data)
    await dispatch({type: LOGOUT})
    dispatch(reset())
}

export const getPrivileges = (data) => dispatch => {
    dispatch({
        type: GET_PRIVILEGES,
        payload: data
    })
}

export const reset = () => dispatch => {
    dispatch({
        type: RESET_STORE
    })
}

export const resetsubmission = () => dispatch => {
    dispatch({
        type : RESET_SUBMISSION
    })
}

export const setFromSSO = (data) => dispatch => {
    dispatch({
        type : SET_FROM_SSO,
        payload : data
    })
}