import * as actionTypes from '../actions/types';

export const metadataReducerInitialState = () => ({
  lob: "",
  buildingPremium: false,
  ratingRequest: {},
  ratingResponse: {},
  FlowType: "Create",
  ismodifyQuote: false,
  quoteResponse: {},
    submissionResponse: {},
    quoteStatus: '',
    policyFormDisplay: false,
    insured__searchClicked: false,
    route: '',
    enableRate: false,
    ProcessStatus:"",
    Flow:"",
    isLocationSaved :true,
    feeName:false,
    taxName:false,
    AvailableMarket__AdjustCoverage: false,
    SelectedPlanName: ''
});
const initialState = metadataReducerInitialState();
export const metadataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOB:
      return {
        ...state,
        lob: action.payload,
      };
    case actionTypes.SET_GETAVAILABLEMARKET_CHECKED:
      return {
        ...state,
        policyFormDisplay: action.payload,
      };
    case actionTypes.FINDINSURED_CLICK:
      return {
        ...state,
        insured__searchClicked: action.payload,
      };
      case actionTypes.SET_MODIFY_QUOTE:
      return {
        ...state,
        ismodifyQuote: action.payload,
      };
    case actionTypes.SET_ROUTE_INFO:
      return {
        ...state,
        route: action.payload,
      };
    case actionTypes.SET_BUILDING_PREMIUM:
      return {
        ...state,
        buildingPremium: action.payload,
      };
    case actionTypes.SET_ENABLE_RATE:
      return {
        ...state,
        enableRate: action.payload,
      };
      case actionTypes.SET_FLOW:
        return {
          ...state,
          Flow: action.payload,
        };
      case actionTypes.SET_FEE_NAME:
        return {
          ...state,
          feeName: action.payload,
        }
      case actionTypes.SET_ROUTE_NAME:
        return{
            ...state,
            routeName: action.payload
        }
      case actionTypes.SET_TAX_NAME:
        return {
          ...state,
          taxName: action.payload,
        }
    case actionTypes.SET_SUBMISSION_TYPE:
      return {
        ...state,
        SubmissionType: action.payload,
      };
    case actionTypes.SET_SUBMISSION_STATUS:
      return {
        ...state,
        SubmissionStatus: action.payload,
      };
      case actionTypes.SET_ADJUSTCOVERAGE:
      return {
        ...state,
        AvailableMarket__AdjustCoverage: action.payload,
      };
 case actionTypes.SET_SHOWHIDE_CONDITION:
        return {
            ...state,
            showHideCondition: action.payload
        }
 case actionTypes.ADDICON_CDN:
        return {
            ...state,
            iconcondition: action.payload
        }
    case actionTypes.SET_PRIMARY_STATE:
        return {
            ...state,
            primaryState: action.payload
        }
    case actionTypes.RESET_APPLICATION_REDUCER:
      return {
        ...initialState
      }
         case actionTypes.GET_RESPONSE:
        return {
            ...state,
            getResponse: action.payload
        }
      case actionTypes.SET_RATING_REQUEST:
        return {
            ...state,
            ratingRequest: action.payload,
        }
            case actionTypes.SET_STATELIST:
        return {
            ...state,
            stateList: action.payload
        }
        case actionTypes.SET_RATING_RESPONSE:
        return {
            ...state,
            ratingResponse: action.payload
        }
        case actionTypes.SET_FLOW_TYPE:
      return {
        ...state,
        FlowType: action.payload,
      };
        case actionTypes.GET_AGENCY_DETAILS:
        return{
            ...state,
            AgencyInformation: action.payload
        }
    case actionTypes.GET_AGENT_DETAILS:
        return{
            ...state,
            AgentInformation: action.payload
        }
    case actionTypes.SET_EXPANDPANEL_DATA:
        return{
            ...state,
            expandPanelData : action.payload
        }

    case actionTypes.SET_ADDITIONALCOVERAGELIST_RESPONSE:
        return {
            ...state,
            additionalCoverageResponse: action.payload
        }
    case actionTypes.SET_ADDITIONALCOVERAGELISTVISIBILITY:
        return {
            ...state,
            additionalCoverageVisibility: action.payload
        }
            case actionTypes.SET_GETQUOTE_RESPONSE:
        return {
            ...state,
            quoteResponse: action.payload
        }
        case actionTypes.SET_HASLOCATION:
          return {
            ...state,
            isLocationSaved: action.payload,
          };
          case actionTypes.SET_CREATESUBMISSION_RESPONSE:
        return {
            ...state,
            submissionResponse: action.payload
        }
        case actionTypes.SET_QUOTE_STATUS:
            return {
                ...state,
                ...action.payload
            }
        case actionTypes.SET_APPLICATION_VALUES:
            return{
                ...state,
                ...action.payload
            }
        case actionTypes.SET_POLICY_DETAILS:
              return {
                  ...state,
                  ...action.payload
              }
        case actionTypes.SET_SELECTED_PLAN:
                return {
                    ...state,
                    SelectedPlanName: action.payload
                }
        case actionTypes.SET_PROCESS_STATUS:
return{
...state,
ProcessStatus : action.payload
}

    default:
      return state;
  }
};
