import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import CheckboxValidator from './CheckboxValidator';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { commonCheckBoxMethod } from './ButtonUtil';
import { updateValueToRedux } from '../utils/Common/CommonUtils';
class MultipleCheckBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCheckboxName: [],
        }
    }

    componentDidMount() {
        ValidatorForm.addValidationRule("required", (value) => {
            if (!value) {
                return false;
            }
            return true;
        });
        let {component}=this.props;
        if(component.Action.SaveDataInSession && component.Action.SaveDataInSession==='Y'){
            updateValueToRedux(this.props,component.DefaultValue)
        }
      if (!this.props.customTable) {
             if ((this.props.component.DefaultValue.constructor === Array&&
                this.props.component.DefaultValue.length!==0) || (this.props.componentvalue)){
            this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
                this.setState({selectedCheckboxName : this.props.component.DefaultValue});
            }
        }

    }

    // onChange = (e) => {
    //     let checked = e.target.checked === true ? 'Yes' : 'No';
    //     let value = Object.assign({}, { [e.target.name]: checked });
    //     this.setState({ selectedCheckboxName: checked })
    //     if (this.props.customTable) {
    //         this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex, this.props);
    //     }
    //     else {
    //         this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
    //     }
    // }

    componentDidUpdate(prevProps, prevState) {
       if(prevProps.component.DefaultValue!==this.props.component.DefaultValue&&this.props.component.DefaultValue.constructor === Array)
       {
            this.setState({selectedCheckboxName : this.props.component.DefaultValue});
       }
        if (!this.props.customTable) {
            if (this.props.component.DefaultValue.constructor === Array&&
                this.props.component.DefaultValue.length!==0&& JSON.stringify(this.props.componentvalue)!==JSON.stringify(this.props.component.DefaultValue)) {
                this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
                this.setState({selectedCheckboxName : this.props.component.DefaultValue});
            }
        }
    }

    handleMultipleCheckBox = (attributeName, checkBoxValue) => {
        //this.setState((prevState) => {
            let selectedCheckboxName = this.state.selectedCheckboxName;
            let {component}=this.props;
            let index = selectedCheckboxName.findIndex(value => value === checkBoxValue);
            if (index > -1) {
                selectedCheckboxName.splice(index, 1);
            }
            else {
                selectedCheckboxName = [...selectedCheckboxName, checkBoxValue];
            }
            // let value = Object.assign({}, { [attributeName]: selectedCheckboxName.toString() });

            // LOB : 356736,2192 => Storing inside values state
            //-----------------------------
            let values = [];

            // selectedCheckboxName.forEach(element => {
            //     values.push({[attributeName]:element})
            // });

            let value = { [attributeName] : selectedCheckboxName }
            //-----------------------------
            if(Object.keys(component.Action).length>0)
            commonCheckBoxMethod(component,value,this.props)
            else
            this.props.handleInputChangeV2(value, component, this.props.isDynamicTable, this.props.datatableRowindex);
            //return { selectedCheckboxName }
       // })
    }

    render() {

        const { component } = this.props;
        const { SubApplicationAttributeValueList,DefaultValue } = component;

        // component.DefaultValue !== "true" ? this.state.selectedCheckboxName = false : this.state.selectedCheckboxName = true
        // this.props.attributeValue && this.props.attributeValue.constructor !== Object ? (this.props.attributeValue !== "Yes" ? this.state.selectedCheckboxName = false : this.state.selectedCheckboxName = true) : (component.DefaultValue !== "Yes" ? this.state.selectedCheckboxName = false : this.state.selectedCheckboxName = true)

        let isRequired = component.IsRequired === 'Y' ? true : false;
        const validations = [];
        const errorMessages = [];
        let value = DefaultValue.constructor===Array&& DefaultValue.length!=0?DefaultValue:[]
        if ((isRequired && !this.state.selectedCheckboxName) || (isRequired && Array.isArray(this.state.selectedCheckboxName) && this.state.selectedCheckboxName.length === 0)) {
            validations.push('required');
            errorMessages.push(component.RequiredMessage);
        }

        return (
            <Grid item xs={12} sm={12} md={component.Size > 0 ? component.Size : 12} lg={component.Size > 0 ? component.Size : 12} className={component.AttributeStyleClass !== "" ? component.AttributeStyleClass : ""}>
                <FormControl className={component.StyleClass}>
                    <CheckboxValidator
                        componentLabel={this.props.componentLabel}
                        SubApplicationAttributeValueList={SubApplicationAttributeValueList}
                        component={component}
                        componentAttributeValue={value}
                        // onChange={this.onChange}
                        handleMultipleCheckBox={this.handleMultipleCheckBox}
                        errorMessages={errorMessages}
                        validators={validations}
                    />
                </FormControl>
            </Grid>);
    }
}

export default MultipleCheckBox;
