import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import { Provider } from "react-redux";
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter, Route  } from 'react-router-dom';
import './i18n';
import {store,persistor} from './store';
import { PersistGate } from 'redux-persist/integration/react'

ReactDOM.render(<HashRouter >
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Route render={props =>  <App  {...props}/> } />
        </PersistGate>
    </Provider>
</HashRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
