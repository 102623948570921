import * as actionTypes from "../actions/types";

export const setLOB = (lob) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOB,
    payload: lob,
  });
};

export const setGetAvailableMarket = (flag) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_GETAVAILABLEMARKET_CHECKED,
    payload: flag,
  });
};
export const setRouteInfo = (routeInfo) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ROUTE_INFO,
    payload: routeInfo,
  });
};
export const setBuildingPremium = (buidingPremium) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BUILDING_PREMIUM,
    payload: buidingPremium,
  });
};
export const setEnableRate = (enableRate) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ENABLE_RATE,
    payload: enableRate,
  });
};
export const setFlow = (res) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FLOW,
    payload: res,
  });
};
export const setSubmissionType = (submissiontype) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SUBMISSION_TYPE,
    payload: submissiontype,
  });
};
export const setSubmissionStatus = (submissionstatus) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SUBMISSION_STATUS,
    payload: submissionstatus,
  });
};

export const setRouteName = type => dispatch => {
  dispatch({
      type : actionTypes.SET_ROUTE_NAME,
      payload : type
  })
}

export const setShowHideCondition = (showHide) => dispatch => {
  dispatch({
      type: actionTypes.SET_SHOWHIDE_CONDITION,
      payload: showHide
  })
}
export const setprimaryState = (primaryState) => dispatch => {
    dispatch({
        type: actionTypes.SET_PRIMARY_STATE,
        payload: primaryState
    })
}
export const getApiResponse = (getResponse) => dispatch => {
    dispatch({
        type: actionTypes.GET_RESPONSE,
        payload: getResponse
    })
}
export const setRatingRequest = (request) => dispatch => {
    dispatch({
        type: actionTypes.SET_RATING_REQUEST,
        payload: request
    })
}
export const setStateList = (stateList) => dispatch => {
    dispatch({
        type: actionTypes.SET_STATELIST,
        payload: stateList
    })
}
export const setRatingResponse = (response) => dispatch => {
    dispatch({
        type: actionTypes.SET_RATING_RESPONSE,
        payload: response
    })
}

export const setFlowType = (type) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FLOW_TYPE,
    payload: type,
  });
};

export const setFeeName = (type) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FEE_NAME,
    payload: type,
  });
};
export const setTaxName = (type) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TAX_NAME,
    payload: type,
  });
};
export const setAgencyDetails = (response) => dispatch => {
    dispatch({
        type: actionTypes.GET_AGENCY_DETAILS,
        payload: response
    })
}

export const setAgentDetails = (response) => dispatch => {
    dispatch({
        type: actionTypes.GET_AGENT_DETAILS,
        payload: response
    })
}

export const setexpandPanelData = flag => dispatch =>{
    dispatch({
        type: actionTypes.SET_EXPANDPANEL_DATA,
        payload: flag
    })
}
export const setAdditionalCoverageList = (response) => dispatch => {
    dispatch({
        type: actionTypes.SET_ADDITIONALCOVERAGELIST_RESPONSE,
        payload: response
    })
}
export const setAdditionalCoverageVisibility = (response) => dispatch => {
    dispatch({
        type: actionTypes.SET_ADDITIONALCOVERAGELISTVISIBILITY,
        payload: response
    })
}
export const setGetQuoteResponse = (response) => dispatch => {
    dispatch({
        type: actionTypes.SET_GETQUOTE_RESPONSE,
        payload: response
    })
}
export const setCreateSubmissionResponse = (response) => dispatch => {
    dispatch({
        type: actionTypes.SET_CREATESUBMISSION_RESPONSE,
        payload: response
    })
}


export const setQuoteStatus = (quoteStatus) => dispatch => {
  dispatch({
      type: actionTypes.SET_QUOTE_STATUS,
      payload: quoteStatus
  })
}

export const setIconcondition = (iconcondition) => dispatch => {
  dispatch({
      type: actionTypes.ADDICON_CDN,
      payload: iconcondition
  })
}

export const setPolicyDetails = (policyDetails) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_POLICY_DETAILS,
    payload: policyDetails,
  });
};

export const setProcessStatus = (processstatus) => dispatch => {
  dispatch({
      type: actionTypes.SET_PROCESS_STATUS,
      payload: processstatus
  })
}

export const ismodifyQuote = page => dispatch => {
  dispatch({
      type: actionTypes.SET_MODIFY_QUOTE,
      payload: page
  })
}
