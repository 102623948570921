import store from "../../../../store";
import {AMT_Logger} from './Client_AMTLogger';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { dateTimeFormat } from "../Common/DateUtils";
const {v4 : uuidv4} = require("uuid");

let format = 'YYYY-MM-DD HH:mm:ss';

const injectAmtInRequest = async (request) => {

  try{
    let uid = uuidv4();
    let temp = {};
    let payload = {};

    let date = new Date() ;
    temp['productDetails'] = {};
    temp['RequestedTime'] = dateTimeFormat(date)

    if(request.url.includes("?") && request.url.includes("SourceRequestID")){
      let AMTID = request.url.split("?")[1].split("&");
      let SourceRequestID = AMTID[0].split("=")[1];
      let CurrentRequestID = AMTID[1].split("=")[1];
      let RequestSequenceID = AMTID[2].split("=")[1];
      temp['SourceRequestId']  = SourceRequestID;
      temp['ParentRequestId']  = CurrentRequestID;
      temp['CurrentRequestId'] = uid;
      temp['RequestSequenceId'] = parseInt(RequestSequenceID);
    }
    else{
      temp['SourceRequestId']  = uid; //1
      temp['ParentRequestId']  = ""; //0
      temp['CurrentRequestId'] = uid; //1
      temp['RequestSequenceId'] = 1;
    }

    payload['SourceRequestId']  = uid; //1
    payload['ParentRequestId']  = ""; //0
    payload['CurrentRequestId'] = uid; //1
    payload['RequestSequenceId'] = temp['RequestSequenceId']
    payload['RequestedTime'] = temp['RequestedTime'];

    if( !request.url.includes('/login'))
    {
      const ReduxState = store.getState();
      const productDetails = ReduxState.UIFlowReducer.productDetails;
      if(Object.entries(productDetails).length > 0){
        temp['productDetails'] = productDetails;
      }

    }

    request.data['AMTDetails'] = temp;
    return request;
  } catch(err){
    console.log("axios interceptor requst:", err.message);
  }

}

const responseAmtHandler = async (response) => {

  try{
    if(!response.config.url.includes('/logout')){
      let date = new Date();

      let payload = response.data.hasOwnProperty('AMTDetails') ? response.data['AMTDetails'] : {};

      payload['ResponseTime'] = dateTimeFormat(date)
      payload['ResponseStatus'] = 'Success';
      payload['ProcessStatus'] = 'Success';
      payload['CurrentRequestId'] = payload['SourceRequestId'];

      // delete payload['SourceRequestId']
      delete payload['ParentRequestId']
      // delete payload['CurrentRequestId']

      // store.dispatch({
      //   type : "SET_CONFIGINFO",
      //   payload : payload
      // });

      AMT_Logger(payload);
    }
    delete response.data.AMTDetails;
    return response;

  }catch(err){
    console.log("error in axios response interceptor :", err.message);
  }

}

const axiosResponseErrorHandler = async (error,auth) => {
  if (axios.isCancel(error)) {
    return Promise.reject('Cancelled');
  }
  console.error("Error in API Invocation", error);
  if(error.response){

    let date = new Date()

    let payload = error.response.data.hasOwnProperty('AMTDetails') ? error.response.data['AMTDetails'] : {};

    payload['ResponseTime'] = dateTimeFormat(date)
    payload['ResponseStatus'] = 'Failed'
    payload['ProcessStatus'] = 'Failed';
    payload['message'] = error.message;

    // delete payload['SourceRequestId']
    delete payload['ParentRequestId']
    // delete payload['CurrentRequestId']

    // store.dispatch({
    //   type : "SET_CONFIGINFO",
    //   payload : payload
    // });

    await AMT_Logger(payload);
    if(auth){
    switch (error.response.status) {
      case 404 :
          window.location.href = window.location.origin + '/web/#/404';
          break;
      case 500 :
          window.location.href = window.location.origin + '/web/#/500';
          break;
      case 503 :
          window.location.href = window.location.origin + '/web/#/503';
          break;
      default :
          window.location.href = window.location.origin + '/web/#/login';
    }

    return Promise.reject(error);
  }

  }
}

export const includeInterceptor = (axiosInstance,auth=false) => {

    axiosInstance.interceptors.request.use(
      async function(request){
        let state = store.getState();
      const {CheckSum} = state.ownerResources.clientInfo;
        request = await injectAmtInRequest(request);
        function generateChecksum(data, securityKey) {
          const hash = CryptoJS.HmacSHA256(JSON.stringify(data), securityKey);
          return hash.toString();
        }
        const originalChecksum = generateChecksum(request.data, CheckSum);
        request.headers['X-CheckSum'] = originalChecksum;
        return request;
      },
      function (error){
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      async function(response){
        response = await responseAmtHandler(response);
        return response;
      },
      async function(error){
        await axiosResponseErrorHandler(error,auth);
        if (error.response)
        return error.response;
      }
    );
}

export const includeCommonPrdInterceptor = (axiosInstance) => {

  axiosInstance.interceptors.request.use(
    async function(request){
      let state = store.getState();
      const {CheckSum} = state.ownerResources.clientInfo;
      request.data.ProductVerNumber = request.data.ProductVerNumber ? request.data.ProductVerNumber : state.ownerResources.clientInfo.MultiLineProductVerNumber;
      request.data.ProductNumber = request.data.ProductNumber ? request.data.ProductNumber : state.ownerResources.clientInfo.MultiLineProductNumber;
      request = await injectAmtInRequest(request);
      function generateChecksum(data, securityKey) {
        const hash = CryptoJS.HmacSHA256(JSON.stringify(data), securityKey);
        return hash.toString();
      }
      const originalChecksum = generateChecksum(request.data, CheckSum);
      request.headers['X-CheckSum'] = originalChecksum;
      return request;
    },
    function (error){
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    async function(response){
      response = await responseAmtHandler(response);
      return response;
    },
    async function(error){
      await axiosResponseErrorHandler(error);
    }
  );

}
