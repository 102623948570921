import React from "react";
import { Button } from "@material-ui/core";

export default function SubmitButton(props) {
    let { component } = props;
    let { ComponentLabel, AttributeStyleClass, OnClickAction, IsReadonly } = component;
    let onClick = props[OnClickAction] || props.lobUtil[OnClickAction];
    const handleClick = (e) => {
        onClick && onClick(e, props, component);
    }
    
    return (
        <Button
            type = "submit"
            color = "primary"
            disabled = { IsReadonly }
            className = { IsReadonly ? `${AttributeStyleClass} nav-btn-disabled` : AttributeStyleClass }
            onClick = { handleClick }
        >
            {ComponentLabel}
        </Button>
    );
}
