import * as actionTypes from "./types";

export const setTemplateInfo = (template) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TEMPLATE_INFO,
    payload: template,
  });
};

export const setNavigation = (navigation) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_NAVIGATION,
    payload: navigation,
  });
};

export const setPageNavigation = (navigation) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PAGE_NAVIGATION,
    payload: navigation,
  });
};

export const setNavigationInfo = (navigation) => dispatch => {
  dispatch({
      type: actionTypes.SET_NAVIGATION_INFO,
      payload: navigation
  })
}
