/* eslint-disable no-use-before-define */

import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormControl, Grid, InputLabel, MenuItem } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


class MultiSelect extends Component {
  componentDidMount() {
    if (
      this.props.component.SubApplicationAttributeValueList !== [] &&
      this.props.component.SubApplicationAttributeValueList.length === 2 &&
      this.props.component.SubApplicationAttributeValueList[0].DisplayValue ===
        "Select"
    ) {
      this.props.handleHiddenInputChange(this.props.component);
    }
  }

  componentDidUpdate() {
    if (
      this.props.component.SubApplicationAttributeValueList.length === 2 &&
      this.props.component.SubApplicationAttributeValueList[0].DisplayValue ===
        "Select" &&
      this.props.attributeValue !==
        this.props.component.SubApplicationAttributeValueList[1].Value
    ) {
      this.props.handleHiddenInputChange(this.props.component);
    }
  }
  render() {
    const { component } = this.props;
    const optionsList = component.SubApplicationAttributeValueList;
    let defaultOption = optionsList.find((option) => {
      if (option.Value === component.DefaultValue) {
        return true;
      } else return false;
    });
    if (!defaultOption) {
      defaultOption = {
        DisplayValue: "Select",
        Sequence: 1,
        Value: "",
      };
    }
    let labelClassName =
      component.IsRequired === "Y"
        ? "mandatory attributeLabel"
        : "attributeLabel";
    let attributeValue =
      this.props.attributeValue === undefined ||
      this.props.attributeValue === ""
        ? component.DefaultValue
        : this.props.attributeValue;
    let labelId = component.ComponentId + "_label";
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    return (
      <Grid
        className={component.StyleClass}
        item
        xs={12}
        sm={12}
        md={this.props.isDynamicTable ? 12 : component.Size}
        lg={this.props.isDynamicTable ? 12 : component.Size}
        key={component.AttributeName}
      >
        <FormControl
          style={styles.dropdown}
          className={`custom-dropdown ${component.AttributeStyleClass}`}
        >
          <Autocomplete
            multiple
            id={component.ComponentId}
            options={optionsList}
            disableCloseOnSelect
            getOptionLabel={(option) => option.DisplayValue}
            autoComplete={autocomplete}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.DisplayValue}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={component.ComponentLabel}
                className={labelClassName}
                style={styles.label}
                autoComplete={autocomplete}
              >
                {component.ComponentLabel}
              </TextField>
            )}
          />
        </FormControl>
      </Grid>
    );
  }
}

const styles = {
  dropdown: {
    minWidth: "100%",
    marginTop: "16px",
  },
  label: {
    width: "100%",
  },
};

export default MultiSelect;
