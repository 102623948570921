import React, { Component } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Card, CardContent } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { withStyles } from "@material-ui/core/styles";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import store from "../../../../../store";
import appActions from '../../../../../actions';
const { actionType } = appActions


class ExpandableComponent extends Component {
    constructor(props) {
        super(props)
        this.expandableRef = React.createRef();
        this.state = {
            expand: false
        }
    }
    componentDidMount() {
        if (this.props.children && this.props.children.hasOwnProperty('props') && this.props.children.props.hasOwnProperty('componentProps') && this.props.children.props.componentProps.hasOwnProperty('accordionExpand') && this.props.children.props.componentProps.accordionExpand == true) {
            this.setState({ expand: true })
            if(this.props.children.props.componentProps.hasOwnProperty('UIFlowReducer') && this.props.children.props.componentProps.UIFlowReducer.isFocusNeeded==true){
            this.expandableRef.current && ReactDOM.findDOMNode(this.expandableRef.current).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            store.dispatch({type: actionType.SET_FOCUS,payload: false});
        }}
        if (this.props.expand || this.props.openByDefault || this.props.expandByDefault) {
            this.setState({ expand: true })
            let {name} = this.props
            if(name)
            store.dispatch({
                type: actionType.SET_UI_VALUES,
                payload: { [`${name}_accordion`]: true }
            });
        }
    } 
    componentDidUpdate(prevProps, prevState){
        let {name} = this.props
        if(name&& (this.props.UIFlowReducer[`${name}_accordion`] !== prevProps.UIFlowReducer[`${name}_accordion`])
         && prevState.expand === this.state.expand ){
            this.setState(
                {   expand: this.props.UIFlowReducer[`${name}_accordion`] },()=>{
                    this.expandableRef.current && ReactDOM.findDOMNode(this.expandableRef.current).scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                }
            )
        }
    }
    handleChange = (event, isExpanded) => {
        this.setState({ expand: isExpanded }, () => {
                let { name } = this.props
                if (name)
                    store.dispatch({
                        type: actionType.SET_UI_VALUES,
                        payload: { [`${name}_accordion`]: isExpanded },
                    });
        })
    };
    render() {
        const styles = this.props.className ? this.props.className : '';
        const IconLeftAccordionSummary = withStyles({
            expandIcon: {
                paddingLeft:'0px',
                marginRight:'5px',
                color:'Black',
                textAlign:'end',
            },
    //         root :{
    //             padding : '0px',
    //             minHeight: 50,
    //             maxHeight: 50,
    //             backgroundColor: "#477d64",
    //             '&.Mui-expanded': {
    //             minHeight: 50,
    //             maxHeight: 50,
    //             backgroundColor: "#477d64",
    // }
    //         }
            
        })(AccordionSummary);
        return (
         <React.Fragment>
              
           
                <Accordion 
                    expanded={this.state.expand} onChange={this.handleChange}  className={`${styles}   accordianoverride large_heading`}>
                    <IconLeftAccordionSummary expandIcon={<ArrowDropDown style={{ textAlign: "end", fontSize: 45 }} />}
                        aria-label="Expand"
                        aria-controls="additional-actions3-content"
                        style={{ paddingLeft: 20 }}
                        className='root'
                    >
                        <Typography variant="h6" color='Black' sx={{ width: '33%', flexShrink: 5 }} ref={this.expandableRef}>{this.props.pageName}</Typography>


                    </IconLeftAccordionSummary>
                    <AccordionDetails className="accordiondetails">
                        <Card className="cardStyle"  >
                            <CardContent className="custom-margin-left" >
                                <Grid spacing={3}  >
                                    {this.props.children}
                                </Grid>
                            </CardContent>

                        </Card>

                    </AccordionDetails>
                </Accordion>


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    UIFlowReducer: state.UIFlowReducer
  });
export default connect(mapStateToProps)(ExpandableComponent);