import outputtext from "./CellPlainText";
import celllinktext from "./CellLinkText";
import documentlink from "../Document";
import cellicon from './CellIcon';

const TableControls ={
    outputtext,
    celllinktext,
    subheading: outputtext,
    documentlink,
    icon: cellicon
}

export default TableControls;
