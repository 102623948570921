import React, { Component } from 'react';
import { FormControl, FormLabel, Grid } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import SelectRadioValidator from './SelectRadioValidator';

class Radio extends Component {
 constructor(props){
    super(props)
    this.state={
    }
 }
   async componentDidMount() {

        ValidatorForm.addValidationRule("IsEmpty", (value) => {
            if (!value) {
                return false;
            }
            return true;
        });
        let {component}=this.props;
        const {dynSubApplicationAttributeValueList,...OnKeyUpActionResponse} =  component.OnKeyUpAction && this.props[component.OnKeyUpAction] && await this.props[component.OnKeyUpAction](this.props)
        this.setState({
           dynSubApplicationAttributeValueList 
        })
             let options =this.props.component.SubApplicationAttributeValueList;
        let defaultOption = options.find((option) => {
            // if (option.Value.localeCompare(this.props.componentvalue, undefined, { sensitivity: 'accent' }) === 0) {
            if ((option.Value && this.props.componentvalue && typeof this.props.componentvalue === 'string') && (option.Value.trim().toLowerCase() === this.props.componentvalue.trim().toLowerCase())) {
                return true;
            } else return false;
        });

        if (!this.props.customTable) {
            if ((this.props.component.DefaultValue !== "" || (this.props.componentvalue && (this.props.componentvalue !==this.props.component.DefaultValue )))  && !defaultOption) {
                this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
            }
        }

    }

    componentDidUpdate(prevProps) {

        let options = this.props.component.SubApplicationAttributeValueList;

        if (!this.props.customTable) {

            if (
              this.props.componentvalue&&prevProps.componentvalue&&
              this.props.componentvalue !== prevProps.componentvalue)
            this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.componentvalue }, this.props.component);
            else if (this.props.component.DefaultValue!==prevProps.component.DefaultValue && this.props.componentvalue!==this.props.component.DefaultValue ) {
              this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
            }
        }
    }

    valueSelect = (e) => {
        // console.log("Selected Value ==>", e.target.value);
    };

    render() {

        const { component, isDynamicTable } = this.props;
       
        const options =this.state.dynSubApplicationAttributeValueList || component.SubApplicationAttributeValueList;
        let labelClassName = component.IsRequired === 'Y' && component.ComponentLabel !== '' ? 'mandatory attributeLabel' : 'attributeLabel';
        let StyleClassName = `${component.StyleClass} ${labelClassName}`;

        let isRequired = component.IsRequired === 'Y' ? true : false;
        const validations = [];
        const errorMessages = [];

        let attributeValue = "";

        if (this.props.transactionValue) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].Value === this.props.transactionValue) {
                    attributeValue = this.props.transactionValue;
                    break;
                }
                else {
                    attributeValue = component.DefaultValue;
                }
            }
        }

        // Only on initial render of the component.

        if (!this.props.transactionValue && component.DefaultValue) {
            attributeValue = component.DefaultValue;
        }


        if (isRequired) {
            validations.push('required');
            errorMessages.push(component.RequiredMessage);
            validations.push('IsEmpty');
            errorMessages.push(component.RequiredMessage);
        }
        let inner_grid_md = component.Size && component.Size !== "0" ? (12 - component.Size) : 8
        let inner_grid_lg = component.Size && component.Size !== "0" ? (12 - component.Size) : 8

        return (
            <Grid item xs={12} sm={12} md={12} lg={12} key={component.AttributeName}  className={`${component.AttributeStyleClass} lineHeight`}> 
  
                <FormControl component="fieldset" style={styles.formControl}>
                    <Grid container spacing={3}>
                        {
                            component.ComponentLabel && (
                                <Grid item xs={12} sm={12}
                                    md={isDynamicTable ? 12 : component.Size !== "0" ? component.Size : 4}
                                    lg={isDynamicTable ? 12 : component.Size !== "0" ? component.Size : 4}>
                                    <FormLabel className={StyleClassName} style={styles.label}>{component.ComponentLabel} </FormLabel>
                                </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={inner_grid_md} lg={inner_grid_lg} className={`${component.StyleClass} lineHeight-radio`} > 
                        
                            <SelectRadioValidator
                                options={options}
                                component={component}
                                valueSelect={this.valueSelect}
                                handleInputChangeV2={this.props.handleInputChangeV2}
                                errorMessages={errorMessages}
                                validators={validations}
                                value={attributeValue}
                                {...this.props}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
                {this.props.children && <div className="childSubapplication">{this.props.children}</div>}
            </Grid>
        );
    }
}

const styles = {
    label: {
        marginTop: '8px'
    },
    formControl: {
        minWidth: '100%'
    },
    radionButton: {
        color: '#0D1E37',
        fontSize: '12px'
    }
}

export default Radio;
