import React, { useState,useEffect,useRef } from "react";
import { FormControl, Grid, InputLabel } from "@mui/material";
import SelectFilterDropdownValidator from "./SelectFilterValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import { attributeSplit } from "../utils/Common/MetaUtils";

function SelectFilter (props) {
  const [value,setValue]=useState('');
  const  SelectFilterRef = useRef();
  const labelRef = useRef();
  const prevComponentValueRef=useRef(props.componentvalue);
  const prevComponentDefaultValueRef=useRef(props.component.DefaultValue);

  useEffect(()=> {
    defaultInfoList();
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
    if (!props.customTable) {
      if(props.component.DefaultValue)
        props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.component.DefaultValue },props.component);
    }
    if(props.component.AttributeParams != "" && props.component.AttributeParams != null){
      let attrParams = attributeSplit(props.component.AttributeParams)
      if(attrParams['persistAttribute'] === "true" && props.hasOwnProperty("handlePersistValue")){
        props.handlePersistValue({ [props.component.AttributeName]: props.component.DefaultValue }, props.component);
      }
    }
  },[])


useEffect(()=>{
     if (!props.customTable) {
        props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.componentvalue }, props.component);
      }
  },[props.componentvalue])

useEffect(()=>{
    if (!props.customTable) {
      props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.component.DefaultValue }, props.component);
     }
 },[props.component.DefaultValue])

  let handleOnChange = (e, data) => {
    const { component} = props;
    let newObj = {};
    let displayValue = data && data.Value ? data.Value : " ";
    newObj = {[component.AttributeName]: displayValue}
    setValue({value: data});
    if (props.component.OnChangeAction && props.component.OnChangeAction !== '') {
      props[props.component.OnChangeAction] && props[props.component.OnChangeAction](props, newObj, props.component, props.isDynamicTable, props.datatableRowindex, props);
  }
  else{
    props.handleInputChangeV2(newObj, props.component, props.isDynamicTable, props.datatableRowindex);
  }
    //props.handleAutoCompletechange(newObj, component.ItemTipParams, component);

  }

  let defaultInfoList = () => {
  const {transactionValue,defaultValue,componentvalue} = props;
  let valueInfoObj = {
    value: defaultValue?defaultValue:transactionValue,
    DisplayValue:componentvalue
  }
  setValue({value: valueInfoObj});
  }

  let UpdateState = (statename, value, rerender) =>{
    if(rerender)
    {
      setValue({
        [statename] : value
      });
    }else{
      statename=value;
    }
}


    let { component, isDynamicTable} =props;
    let options = component.SubApplicationAttributeValueList;
    if (component.SubApplicationAttributeValueList.length >= 2 && component.SubApplicationAttributeValueList[0].DisplayValue === 'Select') {
      options = component.SubApplicationAttributeValueList.slice(1);
    }
    let isRequired = component.IsRequired === "Y" ? true : false;
    const validations = [];
    const errorMessages = [];
    let attributeValue ="";
    if (props.transactionValue !== undefined) {
      const result = options.find((option) => option['Value'] && option['Value'].trim() === props.transactionValue.trim());
      attributeValue = (result && result['Value']) ? result : '';
      UpdateState("value",attributeValue,false)
    }

    if(!attributeValue && component.DefaultValue && props.transactionValue !=="") {
      const result = options.find((option) => option['Value'] && option['Value'].trim() === component.DefaultValue.trim());
      attributeValue = (result && result['Value']) ? result : '';
      UpdateState("value",attributeValue,false)
    }

    if (props.transactionValue === undefined && component.DefaultValue) {
      const result = options.find((option) => option['Value'] && option['Value'].trim() === component.DefaultValue.trim());
      attributeValue = (result && result['Value']) ? result : '';
      UpdateState("value",attributeValue,false)
    }
    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
      validations.push("IsEmpty");
      errorMessages.push(component.RequiredMessage);
    }
    return (
      <Grid
          className={component.StyleClass+ ' lineHeight'}
          style={styles.selectContainer}
          item
          xs={12}
          sm={12}
          md={isDynamicTable ? 12 : component.Size}
          lg={isDynamicTable ? 12 : component.Size}
          key={component.AttributeName}>
          {component.AdjacentLabel === 'Y' ? 
            <>
              <Grid 
              item 
              xs={4} 
              sm={4} 
              md={4} 
              lg={4}  
              className={`${component.AttributeStyleClass}_Label`}
              >
                <InputLabel htmlFor={component.AttributeName} className="custom-label-down text-font adjacent_label" ref={labelRef}>
                  {component.IsRequired === 'Y' ? `${component.ComponentLabel} *` : component.ComponentLabel}
                </InputLabel>
              </Grid>
              <Grid 
              item 
              xs={8} 
              sm={8} 
              md={8} 
              lg={8}  
              className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
              >
          <FormControl
            style={styles.dropdown}
            className={`custom-dropdown ${component.AttributeStyleClass}`}>
              <SelectFilterDropdownValidator
                  ComponentId={component.ComponentId}
                  name={component.AttributeName}
                  value={attributeValue}
                  options={options}
                  component={component}
                  onChange={handleOnChange}
                  errorMessages={errorMessages}
                  validators={validations}>
              </SelectFilterDropdownValidator>
          </FormControl>
              </Grid>
            </>:
          <FormControl
            style={styles.dropdown}
            className={`custom-dropdown ${component.AttributeStyleClass}`}>
              <SelectFilterDropdownValidator
                  ComponentId={component.ComponentId}
                  name={component.AttributeName}
                  value={attributeValue}
                  options={options}
                  component={component}
                  onChange={handleOnChange}
                  errorMessages={errorMessages}
                  validators={validations}>
              </SelectFilterDropdownValidator>
          </FormControl>}
      </Grid>
    );
  }



const styles = {
  selectContainer: {
    display: "flex",
  },
  dropdown: {
    minWidth: "100%",
    alignSelf: "flex-end"
  },
  label: {
    width: "100%",
    position: "relative",
    marginBottom: "-20px"

  },
}


export default SelectFilter

