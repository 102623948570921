import React, { Fragment } from 'react';
import {  TableCell, IconButton } from "@material-ui/core";
import { ExpandMore, ExpandLess} from "@material-ui/icons";

function DetailIcon(props) {
    const { isExpanded, setisExpanded } = props;
    return (
        <Fragment>
            <TableCell className='custom-table-expand'>
                <IconButton size="small" onClick={() => setisExpanded(!isExpanded)}>
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </TableCell>
        </Fragment>
    )
}

export default DetailIcon;
