import React, { cloneElement } from "react";
import Collapse from "@material-ui/core/Collapse";
import { Grid } from "@material-ui/core";
import { IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";

class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: false,
      render:false
    };
  }

  componentDidMount = () => {
    let render = true;
    if(this.props.pageInfo.utilfunction && this.props.pageInfo.utilfunction.collapseMount)
      render = this.props.pageInfo.utilfunction.collapseMount(this.props)
    this.setState({render})
  }

  componentDidUpdate = (prevProps, prevState) => {
    let render ;
    if(this.props.pageInfo.utilfunction && this.props.pageInfo.utilfunction.collapseUpdate)
      render = this.props.pageInfo.utilfunction.collapseUpdate(this.props,this.state,prevProps)
    render && this.setState({render})
  }

  handleExpand = () => {
    this.setState({ isExpand: !this.state.isExpand });
  };

  handleAdd = () => {
    this.setState({ isExpand: true });
  };

  handleHideDetail = () => {
    this.setState({ isExpand: true });
  };

  render() {
    if(!this.state.render) return null;

    const { pageInfo } = this.props;

    const childrenProps = {
      showDetail: this.state.isExpand,
      handleHideDetail: this.handleHideDetail,
      parentProps: { ...this.props.parentProps },
    };

    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          return cloneElement(child, { ...childrenProps });
        }
        return child;
      }
    );

    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{margin: "20px 0"}} >
          <IconButton
            onClick={() => this.handleExpand()}
            size="small"
            component="span"
            style={{ marginTop: "-6px" }}
          >
            {this.state.isExpand ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <span className="custom-subheading heading-font" style={{ marginTop: "10px" }}>
            {pageInfo.name}
          </span>
        </Grid>
        <React.Fragment>
          <Collapse unmountOnExit in={this.state.isExpand} timeout="auto">
            {childrenWithProps}
          </Collapse>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default Collapsible;
