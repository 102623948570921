import React from "react";
import { FormControlLabel, FormControl , Grid ,Checkbox } from "@material-ui/core";

function DataTableCheckBox(props) {
  return (
      // <TableCell align="center" style={{ width: "auto" }} >
        <Grid item xs={12} sm={12} md={12} lg={12} className="check_box">
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={props.isReadOnly}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  name={props.name}
                  checked={props.checked}
                  defaultChecked={props.defaultChecked}
                  onChange={props.onChange}
                  size="small"/>
              } />
          </FormControl>
        </Grid>
      // </TableCell>
  )
}

export default DataTableCheckBox;
