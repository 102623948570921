import React, { Fragment, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { Button, Typography } from '@material-ui/core';
import { Publish, CancelRounded } from '@material-ui/icons';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm,TextValidator } from 'react-material-ui-form-validator';
import { connect, useDispatch } from "react-redux";
import { commonWorkflowAxios, getJSONRequestFromAPI } from '../../utils/Common/API';
import { downloadFile } from '../../../../CommonAxios/FileHandlerAxios';
import store from "../../../../store";
import { LOBUtil } from '../../utils/Common/CommonUtils';
import appActions from "../../../../actions/index";
import { triggerToast } from '../../utils/Common/HelperUtils';

const { actionType } = appActions

const FileImport = (props) => {

    const [state, setState] = useState({});
    const [file, setFile] = useState("");
    const [showImport, setShowImport] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [statusFilePath, setStatusFilePath] = useState(null);
    const [statusMessage, setStatusMessage] = useState(null);
    const [isStatusPropmt, setIsStatusPropmt] = useState(false);
    const dispatch = useDispatch();

    let importForm = React.createRef();
    const { ImportEventName, ImportCallback, ImportAutoDownLoad = false } = props.importExportProperties;

    useEffect(() => {

        if(showImport && statusFilePath){
            // showSuccessToast();
            ImportAutoDownLoad && ImportStatus();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showImport, statusFilePath])

    const handleChangeText = (event) => {
        const { name, value } = event.target;
        setState(prevstate => ({...prevstate, [name] : value}) );
    }

    const handleClose = () => {
        dispatch({
            type: actionType.TRIGGER_IMPORT,
            payload:false
        });
        if(statusMessage) {
            dispatch({
                type: actionType.INVOKE_GET_STATE_LIST,
                payload: true
            });
        }
        props.closeDialog(false);
        if(showImport && statusFilePath){
            if(ImportCallback){
                ImportCallback(true);
            }
        }
    }

    const handleFileAdd = event => {
        let filename = event.target.files[0].name && event.target.files[0].name.substring(0,event.target.files[0].name.lastIndexOf('.'));
        let e = {
            target : {
                name : 'AttachmentName',
                value : filename
            }
        }
        handleChangeText(e);
        setFile( event.target.files[0]);
        setShowError(false);
    }

    const handleError = () => {
        if(file.name === "" || file.name === null || file.name === undefined){
            setShowError(true);
        }
    }

    const handleSave = async () => {

        if(file === "" || file === undefined || file === null || file.name === "" || file.name === null || file.name === undefined){
            setShowError(true);
        }
        else{
            setLoader(true);
            const formData = new FormData();
            formData.append("uploadFileData", file, file.name);

            await commonWorkflowAxios.post("/core/fileUpload?pathVariable=IMPORT_TRANSACTION", formData).then(async (res) => {
                // console.log("********FileUploadResponse********",res.data);
                if(res.statusText==='OK' || res.status === 200)
                {
                    const productDetails = props.productDetails;
                    const ImportRequest = await getJSONRequestFromAPI(ImportEventName, productDetails)
                    if(ImportRequest['ServiceRequestDetail']){
                        ImportRequest['ServiceRequestDetail']['ServiceRequestVersion'] = '1.0';
                        ImportRequest['ServiceRequestDetail']['ResponseType'] = 'json'
                    }
                    ImportRequest['ImportEventName'] = ImportEventName;
                    ImportRequest['EventName'] = props.importExportProperties['EventName'] || ImportEventName;
                    ImportRequest['SubmissionID'] = props.UIFlowReducer.submissionID;
                    ImportRequest['FilePath'] = res.data.newpath;
                    if(productDetails){
                        ImportRequest['ProductNumber'] = productDetails.ProductNumber;
                        ImportRequest['ProductVerNumber'] = productDetails.ProductVerNumber;
                        ImportRequest['ObjectId'] = productDetails['ProductID'] ? productDetails['ProductID'] : "";
                        ImportRequest['ObjectVerId'] = productDetails['ProductVerID'] ? productDetails['ProductVerID'] : "";
                    }

                    // console.log("********ImportRequest********",ImportRequest);

                    await commonWorkflowAxios.post('/core/invokeWorkflowEvent/V2',ImportRequest)
                        .then( async response => {
                            // console.log("********Import Response********", response.data);
                            if(Object.keys(response.data).length > 0){
                                if(response.data.ImportStatusFilePath){
                                    setStatusFilePath(response.data.ImportStatusFilePath);
                                    setShowImport(true);
                                    setStatusMessage(response.data['ImportStatusMessage'] ? response.data['ImportStatusMessage'] : "");
                                    setIsStatusPropmt(true);
                                    const lobUtil = productDetails.hasOwnProperty("LineOfBusiness") ? LOBUtil[productDetails["LineOfBusiness"]] || {} : {};
                                    if(props.navigation.IncludeForwardNav)
                                    {
                                        store.dispatch({ type: actionType.SOLARTISUIPAGENAVIGATIONSTATUS, payload: props.navigation.SolartisUIPageNavigationStatus });
                                        lobUtil.navigationStatus &&  lobUtil.navigationStatus(props.navigation.SolartisUIPageNavigationStatus)
                                    }
                                }else{
                                    throw new Error('Status FilePath is Undefined');
                                }
                            }
                            else{
                                throw new Error('Import Response is Empty')
                            }

                        })
                        .catch((err) => {
                            console.log("Error in While Importing =>", err.message);
                            triggerToast(err.message)
                            setStatusFilePath(null);
                            setShowImport(false);
                            setStatusMessage(null);
                            setIsStatusPropmt(false);
                        })
                        .finally(() => {setLoader(false);})
                }
              })
              .catch((err) => {
                  console.log("Error in File Upload",err.message);
                //   showErrorToast();
                })
              .finally(() => {setLoader(false);})
        }
    }

    const ImportStatus = () => {
        var FileName = statusFilePath.substring(statusFilePath.lastIndexOf('/') + 1);
        var path = statusFilePath.replace(FileName,'');
        // console.log("********StatusFilePath********", statusFilePath, FileName, path);
        try {
            downloadFile(FileName,path);
        }catch(err){
            console.log("Error in While Downloading the File",err.message);
        }
    }

    const handleStatusPrompt = () => {
        setIsStatusPropmt(false);
    }

    const statusInfo = () => {
        if(isStatusPropmt){
            return(
                <Typography variant="body1" className="status-msg">
                    {statusMessage}
                    <span className ="status-msg-closeIcon">
                        <CancelRounded onClick={handleStatusPrompt}/>
                    </span>
                </Typography>
            )
        }
        return null;
    }

    return (
        <Fragment>
            <Dialog
                disableBackdropClickfullWidth={true}
                className="fullWidthDialogue"
                maxWidth = {'sm'}
                disableEscapeKeyDown
                open={props.open}
                aria-labelledby="form-dialog-title"
            >
                <ValidatorForm  ref={f => (importForm = f)} onSubmit={handleSave} onError={handleError}>
                    <div className="flex" >
                        <DialogTitle id="form-dialog-title">
                            <span> Attach File </span>
                        </DialogTitle>
                        <span className ="flexPushRight">
                            <IconButton><CloseIcon onClick={handleClose}/></IconButton>
                        </span>
                    </div>
                    <DialogContent>
                        <TextValidator
                            autoFocus
                            className="custom-input"
                            margin="normal"
                            name="AttachmentName"
                            label={
                                <Typography className="custom-label-down text-font" style={styles.label}>
                                    Name *
                                </Typography>
                            }
                            placeholder="Name *"
                            value = {state.AttachmentName || ''}
                            onChange = {handleChangeText}
                            validators={['required']}
                            errorMessages={['Attachment Name is mandatory']}
                            fullWidth
                        />
                        <br/>
                    <div className="upload upload-container">
                            <div className="upload-button theme_buttonColor"  onClick={() => {setShowImport(false)}}>
                                <span className="upload-button-text">
                                    <Publish/><span>Choose File</span>
                                </span>
                                <input
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    accept="application/vnd.ms-excel , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={handleFileAdd}
                                    className="fileInputOverflow upload-button-input"
                                />
                            </div>
                            <span className="upload-filename">{file === undefined ? "" : file.name}</span>
                        </div>
                        {showError && <span className="ErrorFileImport" >File Import is Mandatory</span>}
                    </DialogContent>
                    <DialogActions className="mt-1-5" >
                        {
                            (showImport && statusFilePath && !ImportAutoDownLoad) &&
                                <Button className="ImportStatusButton primaryButton" onClick={ImportStatus}>
                                    <SystemUpdateAltIcon className="mr-1" /> Import Status
                                </Button>
                        }
                        {
                            (ImportAutoDownLoad && isStatusPropmt && statusMessage) && statusInfo()
                        }
                        <Button type="button" className="primaryButton"  onClick={() => importForm.submit()}>
                            {
                                loader ? <CircularProgress className="buttonLoader" /> : 'Import'
                            }
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </Fragment>
    );
};

const styles = {
    label: {
        width: '150%'
    }
}

const mapStateToProps = (state) => ({
    navigationReducer: state.navigationReducer,
    productDetails:state.UIFlowReducer.productDetails,
    metadataReducer: state.metadataReducer,
    UIFlowReducer: state.UIFlowReducer
});


export default connect(mapStateToProps)(FileImport);
