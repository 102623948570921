import * as actionTypes from "../actions/types";

export const uiFlowReducerInitialState = () => ({
  currentPageIndex: 1,
  isFocusNeeded: false,
  loading: 0,
  currentFindConsoleInfo : {},
  IsCoverageSave: false,
    SolartisUIPageNavigationStatus: "",
  quoteSummaryNavIndex: 0,
  RateRender: false,
  RateInvoked: false,
  triggerImport : false,
  importType: "",
  triggerExport : false,
  exportType: "",
  importVersion : '',
  locationRowValueList: [],
  productDetails: {},
  quoteID: '',
  submissionData: {},
  quoteSummaryView: {},
  selectedLOB:'',
  selectedProducts: [],
  policyID: "",
  currentQuoteConsoleInfo: {},
  PageHeaderUpdate: false,
  updatePageName:'',
  formValidatorIndex: 0,
  expandPanelDetailData: {},
  updateExpandPanel: false,
  validationData: '',
  updateValidation: false,
  externalInfo: {},
  navigationCriteria: {},
  landingPage: '',
  showOfficeDetails: false
});

const initialState = uiFlowReducerInitialState();

export const UIFlowReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_PAGEINDEX: {
      let currentPageIndex = action.payload;
      return {
        ...state,
        currentPageIndex,
      };
    }
    case actionTypes.SET_SUBMISSION_DATA: {
      return {
        ...state,
        submissionData: action.payload
      }
    }
    case actionTypes.SET_SELECTED_PRODUCTS: {
      return {
        ...state,
        selectedProducts: action.payload
      }
    }
    case actionTypes.SET_SELECTED_LOB: {
      return {
        ...state,
        selectedLOB: action.payload
      }
    }
    case actionTypes.SET_FINDCONSOLE_INFO:
      return {
        ...state,
        currentFindConsoleInfo: action.payload,
      };
    case actionTypes.SET_QUOTECONSOLE_INFO: {
      let currentQuoteConsoleInfo = action.payload;
      return {
        ...state,
        currentQuoteConsoleInfo,
        currentPageIndex: currentQuoteConsoleInfo.index,
      };
    }
    case actionTypes.SET_POLICYCONSOLE_INFO: {
      let currentPolicyConsoleInfo = action.payload;
      return {
        ...state,
        currentPolicyConsoleInfo,
        currentPageIndex: currentPolicyConsoleInfo.index,
      };
    }
    case actionTypes.SET_SUBMISSIONID: {
      let submissionID = action.payload;
      return {
        ...state,
        submissionID,
      };
    }
    case actionTypes.SET_ACCOUNTID: {
      let accountID = action.payload;
      return {
        ...state,
        accountID,
      };
    }
    case actionTypes.SET_QUOTE_ID:
      return {
        ...state,
        quoteID: action.payload,
      };
    case actionTypes.SET_POLICY_ID:
      return {
        ...state,
        policyID: action.payload,
      };
    case actionTypes.SET_INSUREDID:
      return {
        ...state,
        insuredID: action.payload,
      };
    case actionTypes.SET_AGENTID:
      return {
        ...state,
        agentID: action.payload,
      };
    case actionTypes.SET_AGENCYID:
      return {
        ...state,
        agencyID: action.payload,
      };
    case actionTypes.SET_USERROLE:
        return {
          ...state,
          userRole: action.payload,
        };  
    case actionTypes.SET_FOCUS:
      return {
        ...state,
        isFocusNeeded: action.payload,
      };
    case actionTypes.RESET_LOADER:
          return{
            ...state,
            loading: 0
          }
    case actionTypes.SHOW_LOADER:
      return {
        ...state,
        loading: state.loading + 1,
      };
    case actionTypes.HIDE_LOADER:
      return {
        ...state,
        loading: state.loading ? state.loading - 1 : 0,
      };
    case actionTypes.IS_COVERAGE_SAVE:
      return {
        ...state,
        IsCoverageSave: action.payload,
      };
    case actionTypes.SET_SUBMISSION_NUMBER:
      return {
        ...state,
        SubmissionNumber: action.payload,
      };
    case actionTypes.SET_HASUNDERWRITINGDETAILS:
      return {
        ...state,
        isUnderwritingDetailsSaved: action.payload,
      };
    case actionTypes.SET_LOCATION_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
      case actionTypes.SET_QUOTE_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_BLOCK_LOCATION_SAVE:
      return {
        ...state,
        BlockLocationSave: action.payload,
      };
      case actionTypes.SET_OVERIDE_CLEARANCE_DATE:
      return {
        ...state,
        OverideClearanceDate: action.payload,
      };
      case actionTypes.SET_CONSOLE_INFO: {
      let currentConsoleInfo = action.payload;
      return {
        ...state,
        currentConsoleInfo,
        currentPageIndex: currentConsoleInfo.index,
      };
    }
        case actionTypes.SET_CURRENTPAGE_INFO: {
      let currentPageInfo = action.payload;
      return {
        ...state,
        currentPageInfo,
        currentPageIndex: currentPageInfo.index,
      };
    }
        case actionTypes.SET_INSUREDNAME: {
      let insuredFullName = action.payload;
      return {
        ...state,
        insuredFullName,
      };
    }
     case actionTypes.SET_INSUREDADDRESS: {
      let insuredAddress = action.payload;
      return {
        ...state,
        insuredAddress,
      };
    }
     case actionTypes.SOLARTISUIPAGENAVIGATIONSTATUS: {
      let SolartisUIPageNavigationStatus =
        action.payload >= state.SolartisUIPageNavigationStatus
          ? action.payload
          : state.SolartisUIPageNavigationStatus;
      return {
        ...state,
        SolartisUIPageNavigationStatus,
      };
    }
     case actionTypes.QUOTE_SUMMARY_NAV_INDEX:
      return {
        ...state,
        quoteSummaryNavIndex: action.currentIndex,
      };
      case actionTypes.SET_RATE_RENDER:
      return {
        ...state,
        RateRender: action.payload,
      };
      case actionTypes.SET_RATE_INVOKED:
      return {
        ...state,
        RateInvoked: action.payload,
      };
      case actionTypes.IS_RATING_INVOKED:
        return {
          ...state,
          IsRatingInvoked: action.payload,
        };
        case actionTypes.SET_PRODUCT_BROWSE_STATE_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
        case actionTypes.TRIGGER_EXPORT:
        return {
            ...state,
            ...action.payload
            }
       case actionTypes.TRIGGER_IMPORT:
        return {
            ...state,
            triggerImport: action.payload
        }
      case actionTypes.IMPORT_VERSION:
        return {
            ...state,
            importVersion : action.payload
        }
      case actionTypes.SET_QUOTESUMMARY_VIEW:
      return {
        ...state,
        quoteSummaryView: action.payload,
      };
      case actionTypes.SET_PRODUCT_DETAILS:
        return {
            ...state,
            productDetails: action.payload
        }
      case actionTypes.SET_PAGENAME:
        return {
            ...state,
            pageName: action.payload
        }
        case actionTypes.LOAD_LOCATION_DATA:
          // console.log('From SetPremium Reducer==>',action.payload)
          return {
              ...state,
              locationRowValueList : action.payload,
          }
          case actionTypes.LOAD_LOCATION_ROWDATA:
            {
                let values = action['values'];
                let customTableRowIndex = action['customTableRowIndex'];
                let locationRowValueList = JSON.parse(JSON.stringify(state.locationRowValueList));

                let rowObject = {};

                if(state.locationRowValueList[customTableRowIndex]){
                    rowObject = JSON.parse(JSON.stringify(state.locationRowValueList[customTableRowIndex]));
                }

                let temprowObj = Object.assign({}, rowObject, values);

                locationRowValueList[customTableRowIndex] = temprowObj;

                return {
                    ...state,
                    locationRowValueList
                }
            }

        case actionTypes.SAVE_LOCATION_ROWDATA:
            {
                let value= action.payload;
                let locationRowValueList = JSON.parse(JSON.stringify(state.locationRowValueList));

                locationRowValueList.map((location, index) => {
                    let rowObject = {};

                    if(locationRowValueList[index]){
                        rowObject = JSON.parse(JSON.stringify(locationRowValueList[index]));
                    }

                    let temprowObj = Object.assign({}, rowObject, value);

                    locationRowValueList[index] = temprowObj;
                })

                return {
                    ...state,
                    locationRowValueList
                }

            }

        case actionTypes.UPDATE_LOCATION_ROWDATA:
            {
                let value = action['value'];
                let customTableRowIndex = action['customTableRowIndex'];
                let component = action['component'];
                let attributeName = component.AttributeName;
                let attributeValue = value[attributeName];
                let locationRowValueList = JSON.parse(JSON.stringify(state.locationRowValueList));

                let tempObject = {};
                if(state.locationRowValueList[customTableRowIndex]){
                    tempObject = JSON.parse(JSON.stringify(state.locationRowValueList[customTableRowIndex]));
                }

                let attributeObject = Object.assign({}, tempObject, {[attributeName]: attributeValue,});

                locationRowValueList[customTableRowIndex] = attributeObject;

                return{
                    ...state,
                    locationRowValueList
                }
            }


        /* Classification (Full-Rating Page) */

        case actionTypes.UPDATE_CLASSIFICATION_ROW_DATA:
            {
                //console.log("inside updateClassification reducer")
                let { value, component, locationIndex, classificationIndex} = action.payload;

                let locationRowValueList = JSON.parse(JSON.stringify(state.locationRowValueList));

                // let tempLocation = {}
                // if(locationRowValueList[locationIndex]){
                //     tempLocation = { ...state.locationRowValueList[locationIndex]};
                //     let tempClassificationList = {...tempLocation["classificationList"]}
                //     if(tempClassificationList[classificationIndex]){
                //         console.log("updating classification for ", locationIndex, " , ", classificationIndex)
                //         let tempClassification = tempClassificationList[classificationIndex];
                //         let updatedClassification = Object.assign({}, tempClassification, value);
                //         locationRowValueList[locationIndex]["classificationList"][classificationIndex] = updatedClassification;
                //     }
                // }

                let updatedClassification =  Object.assign(JSON.parse(JSON.stringify(locationRowValueList[locationIndex]["classificationList"][classificationIndex])), value);
                locationRowValueList[locationIndex]["classificationList"][classificationIndex] = updatedClassification;

                return{
                    ...state,
                    locationRowValueList
                }
            }

        case actionTypes.ADD_CLASSIFICATION:
            {
                let {locationIndex} = action.payload;
                //console.log("inside add classification reducer ", locationIndex)
                let locationRowValueList = JSON.parse(JSON.stringify(state.locationRowValueList));

                let locationRowObject = {};

                if(state.locationRowValueList[locationIndex]){
                    locationRowObject = JSON.parse(JSON.stringify(state.locationRowValueList[locationIndex]));
                }

                //console.log("locationRowObject -> ", locationRowObject)

                if(!locationRowObject['classificationList']){
                    locationRowObject['classificationList'] = []
                }
                locationRowObject['classificationList'].push({ ID: Math.floor(Math.random() * 874596) });
                locationRowValueList[locationIndex]=locationRowObject;

                return {
                    ...state,
                    locationRowValueList
                }
            }
            case actionTypes.SET_EXPANDPANELDETAIL_DATA:
              return {
                  ...state,
                  expandPanelDetailData: action.payload
              }
        case actionTypes.UPDATE_EXPANDPANEL:
              return {
                    ...state,
                    updateExpandPanel: action.payload
                  }
        case actionTypes.DELETE_CLASSIFICATION:
        {
            let {locationIndex, classificationIndex, classificationId} = action.payload;
            let locationRowValueList = JSON.parse(JSON.stringify(state.locationRowValueList));
            let filteredClassificationList = locationRowValueList[locationIndex]["classificationList"].filter(classification => classification.ID !== classificationId);
            locationRowValueList[locationIndex]["classificationList"] = filteredClassificationList;

            return {
                ...state,
                locationRowValueList
            }
        }
    case actionTypes.SET_UI_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_PAGEHEADER_UPDATE:
        return {
            ...state,
            PageHeaderUpdate: action.payload
    }
    case actionTypes.SET_PAGENAME_UPDATE:
      return {
        ...state,
        updatePageName: action.payload
      }
      case actionTypes.SET_VALIDATION_DATA:
        return {
            ...state,
            validationData: action.payload
        }
    case actionTypes.UPDATE_VALIDATIONS:
            return {
                ...state,
                updateValidation: action.payload
            }

    case actionTypes.SET_EXTERNAL_INFO:
      return {
        ...state,
        externalInfo: action.payload
      }

    case actionTypes.SET_NAVIGATIONCRITERIA_INFO:
      return {
        ...state,
        navigationCriteria: {
          ...state.navigationCriteria,
          ...action.payload
        }
      }

      case actionTypes.SET_FORMVALIDATIONINDEX_INC:
      return {
        ...state,
        formValidatorIndex: state.formValidatorIndex + 1 
      }

      case actionTypes.SET_FORMVALIDATIONINDEX_DEC:
      return {
        ...state,
        formValidatorIndex: state.formValidatorIndex - 1 
      }

      case actionTypes.SET_LANDING_PAGE:
        return {
          ...state,
          landingPage: action.payload
        }

    default:
      return state;
  }
};
