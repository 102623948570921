import { getServiceRequestDetail } from "../Common/API";
import {getJSONRequestFromAPI} from "../Common/API"
import { constructRequestValues } from "../API_utils/ConstructRequestValues";


export const constructTransactionRequest = (state, props, pagelevelValues, includePagelevelValues = true, metadataResponseFromReducer = true) => {

  let subappList = [];
  let subAppEntitymaping = {};
  let detailSubAppMaping = {};
  let obj = {};
  let entityHierarchyName = "";

  let request = Object.create({});
  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  request.OwnerId = sessionStorage.getItem("OwnerID");

  const metadataRes = metadataResponseFromReducer ? JSON.parse(props.metadataReducer.metadataResponse) : state.pageMetadata
  const subAppDetailList = metadataRes.ApplicationDetail.SubApplicationDetailList;

  for (let a = 0; a < subAppDetailList.length; a++) {
    const attrList = subAppDetailList[a].AttributeDetailList;
    const templateType = subAppDetailList[a].Type;

    if (attrList.length > 0) {
      detailSubAppMaping[attrList[0].SubApplicationName] = templateType;
    }

    if (attrList) {
      for (let i = 0; i < attrList.length; i++) {
        entityHierarchyName = attrList[i].EntityHierarchyName === "" ? attrList[i].SubApplicationName : attrList[i].EntityHierarchyName;
        subAppEntitymaping[attrList[i].SubApplicationName] = entityHierarchyName;

        if (!subappList.includes(attrList[i].SubApplicationName)) {
          subappList.push(attrList[i].SubApplicationName);
        }

        if (entityHierarchyName && entityHierarchyName !== "") {
          if (subAppDetailList[a].Type.toLowerCase() !== "summary") {
            if (!obj[entityHierarchyName]) {
              obj[entityHierarchyName] = {};
            }

            if (attrList[i].RenderValue === true && attrList[i].ComponentType.toLowerCase() !== "subheading" && attrList[i].ComponentType.toLowerCase() !== "button" && attrList[i].ComponentType.toLowerCase() !== "submit" && attrList[i].ComponentType.toLowerCase() !== "sectionheading" ) {
              if (attrList[i].AttributeName.includes(".")) {
                let splitName = attrList[i].AttributeName.split(".");
                if (obj[entityHierarchyName][splitName[0]] === undefined) {
                  obj[entityHierarchyName][splitName[0]] = {};
                }
                obj[entityHierarchyName][splitName[0]][splitName[1]] = attrList[i].DefaultValue;
              }
              else {
                obj[entityHierarchyName][attrList[i].AttributeName] = attrList[i].DefaultValue;
              }
            }
          }
        }
      } // attributList Iteration Loop
    }
  } // SubApplicationList Iteration Loop


  /* console.log("SubApplicationList", subappList);
   console.log("SubAppEntitymaping", subAppEntitymaping);
   console.log("DetailSubAppMaping", detailSubAppMaping); */

  if (Object.keys(obj).length !== 0) {
    let detailName = "";
    let obj2 = {};

    if (!includePagelevelValues) {
      for (let i = 0; i < subappList.length; i++) {
        let pagevalsubApp = detailName !== "" ? detailName : subappList[i];

        Object.keys(obj).forEach((keys) => {
          Object.entries(obj[keys]).map(([key, value]) => {
            obj2[key] = pagelevelValues[pagevalsubApp][key];
          });
        });

        for (let key of Object.keys(pagelevelValues[pagevalsubApp])) {
          if (pagelevelValues[pagevalsubApp][key] !== obj2[key]) {
            obj2[key] = "";
          }
        }

        if (pagelevelValues[pagevalsubApp].hasOwnProperty("ID")) {
          obj2["ID"] = pagelevelValues[pagevalsubApp]["ID"];
          obj2["PARENTID"] = pagelevelValues[pagevalsubApp]["PARENTID"];
        }

        request = { ...request, ...{ [pagevalsubApp]: obj2 } };
      }
    }

    else {
      request = { ...request, ...obj };

      for (let i = 0; i < subappList.length; i++) {
        if (subappList[i].includes("Summary")) {
          let splitval = subappList[i].split("Summary");
          detailName = splitval[0] + "Summary";
        }
        else {
          detailName = "";
        }

        let pagevalsubApp = detailName !== "" ? detailName : subappList[i];
        if (pagelevelValues[pagevalsubApp]) {
          // Object.keys(state.pagelevelValues[subappList[i]]).forEach((keys) =>{
          //  if(request[subAppEntitymaping[subappList[i]]].hasOwnProperty(keys)){
          if (Array.isArray(pagelevelValues[pagevalsubApp])) {
            if (detailSubAppMaping[subappList[i]] === "Summary" || detailSubAppMaping[subappList[i]] === "Detail") {
              let subAplnName = subAppEntitymaping[subappList[i]].includes("Detail")
                ? subAppEntitymaping[subappList[i]]
                : subAppEntitymaping[subappList[i]] + "Detail";
              if (subAplnName.includes("Detail")) {
                let splitEntity = subAplnName.split("Detail");
                let parentEntity = splitEntity[0];
                if (!request[parentEntity]) {
                  request[parentEntity] = [];
                }
                let arrayVal = request[parentEntity] && request[parentEntity].constructor === Object ? request[parentEntity] : { ...request[parentEntity] }
                arrayVal[subAplnName] = pagelevelValues[pagevalsubApp];
                if (Object.keys(arrayVal).length > 0) {
                  request[parentEntity] = [arrayVal];
                }
              }
            }
          }
          else if (detailName === "") {
            Object.entries(pagelevelValues[pagevalsubApp]).map(([key, value]) => {
              if (key.includes(".")) {
                let splitName = key.split(".");
                if(request[subAppEntitymaping[subappList[i]]] && request[subAppEntitymaping[subappList[i]]][splitName[0]]
                  && request[subAppEntitymaping[subappList[i]]][splitName[0]][splitName[1]]){
                  if (request[subAppEntitymaping[subappList[i]]][splitName[0]] === undefined) {
                    request[subAppEntitymaping[subappList[i]]][splitName[0]] = {};
                  }
                  request[subAppEntitymaping[subappList[i]]][splitName[0]][splitName[1]] = value;
                }
              }
              else {
                request[subAppEntitymaping[subappList[i]]][key] = value;
              }
            }
            );
          }
          else {
            if (detailSubAppMaping[subappList[i]] === "Summary" ||
              detailSubAppMaping[subappList[i]] === "Detail" ||
              detailSubAppMaping[subappList[i]] === "Form") {
              Object.entries(pagelevelValues[pagevalsubApp]).map(
                ([key, value]) => {
                  request[subAppEntitymaping[subappList[i]]][key] = value;
                }
              );
            }
          }
        }
        // }
        else {
          if (detailSubAppMaping[subappList[i]] === "Summary") {
            let subAplnName = subAppEntitymaping[subappList[i]]
            if (subAplnName.includes("Detail")) {
              let splitEntity = subAplnName.split("Detail");
              let parentEntity = splitEntity[0];
              let splitSubapp = subappList[i].split("Summary")
              let parentSubApp = splitSubapp[0]
              if (pagelevelValues[parentSubApp]) {
                if (!request[parentEntity]) {
                  request[parentEntity] = [];
                }
                let objValue = {}
                Object.entries(pagelevelValues[parentSubApp]).map(
                  ([key, value]) => {
                    objValue[key] = value
                  }
                );
                if (objValue) {
                  request[parentEntity] = [objValue]
                }
              }
              else {
                if (request[parentEntity]) {
                  request[parentEntity] = [request[parentEntity]]
                }
              }
            }
          }
        }
      }
    }
  }
  else {
    request = { ...request, ...pagelevelValues };
  }

  // console.log("[karthu] request-->", request);
  return request;
};

export const constructTransactionRequestV1 = async (state, props, pagelevelValues, eventName,includeID = false, metadataResponseFromReducer = true,version) => {
  let productDetails = props.UIFlowReducer.productDetails
  let request={};
  if(eventName){
    request = await getJSONRequestFromAPI(eventName, productDetails,version);
  }
  request = await constructRequestValues(props, pagelevelValues, request,state,includeID,metadataResponseFromReducer)
  if(Object.keys(request).length > 0){
    return request
  };

};

export const constructTransactionRequestV2 = (state, props, pagelevelValues, includePagelevelValues = true, metadataResponseFromReducer = true) => {

  let request = Object.create({});
  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  request.OwnerId = sessionStorage.getItem("OwnerID");
  if(Object.keys(pagelevelValues).length<1 && !includePagelevelValues){
    return request;
  }
  const metadataRes = metadataResponseFromReducer ? JSON.parse(props.metadataReducer.metadataResponse) : state.pageMetadata
  const { SubApplicationEntity = {} } = metadataRes.ApplicationDetail;

  for(let [SubApplication, Entity] of Object.entries(SubApplicationEntity)) {
    if(!Entity)
      request[SubApplication] = pagelevelValues.hasOwnProperty(SubApplication) ? unflatten(pagelevelValues[SubApplication]) : {};
    else
    {
      let newRequest = pagelevelValues.hasOwnProperty(SubApplication) ? unflatten(pagelevelValues[SubApplication]) : {};
      request[Entity] = {...request[Entity],...newRequest}
    }
  }
  return request;
};


const unflatten = obj =>
  Object.keys(obj).reduce((res, k) => {
    k.split('.').reduce(
      (acc, e, i, keys) =>
        acc[e] ||
        (acc[e] = isNaN(Number(keys[i + 1]))
          ? keys.length - 1 === i
            ? obj[k]
            : {}
          : []),
      res
    );
    return res;
  }, {});
