import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ValidatorForm } from "react-material-ui-form-validator";
import { Card, CardContent, Grid } from "@material-ui/core";
import {Stack } from "@mui/material";
import { constructAndInvokeMetadataCall } from  '../utils/Common/MetaUtils';
// import * as Controls from '../controls/index';
import DataTable from '../controls/DataTable/DataTable';
import { invokeFindConsoleAPI, Find } from './FindUtils';
import TitleBar from './TitleBar';
import { subAppListConstructionV1 } from '../utils/Common/DetailSummarySubAppList';
import PageLoader from '../controls/PageLoader';
import childComp from "../sections/CustomComponents/ChildComponent/childComponent";
import store from '../../../store';
import * as actiontype from "../../../actions/types";
import {showLoader, hideLoader, setAgentID, setInsuredID,setPolicyID, setAccountID, setCurrentPageIndex, setFindConsoleInfo, setQuoteID, setSubmissionID } from '../../../actions/UIFlowActions.js';
import { setFlowType } from '../../../actions/applicationMetadataAction';
import { setPageNavigation } from '../../../actions/navigationAction';
import { triggerToast } from '../utils/Common/HelperUtils.js';
import container from '../utils/DIContainer.js';

class Findd extends Component {

    constructor(props) {
        super(props)
        this.referenceCreated = React.createRef();
        this.state = {
             pageMetadata : {},
             pagelevelValues : {},
             transactionPrimaryObject : {},
             viewName : 'Find',
             showTable : "none",
             index : 1,
             dataListSize : 0,
             defaultRowsPerPage:5,
             loading:false,
             updateChildPageFunction : null
        }
    }
    componentDidMount = async () => {
        let routeName = this.props.location.pathname.slice(1).toLowerCase();
        let {currentFindConsoleInfo:{name}} = this.props;
        if(!name)
        name=this.props.pageInfo.name;
        await store.dispatch({type: actiontype.RESET_SUBMISSION});
        await store.dispatch({type: actiontype.SET_ROUTE_INFO,payload:"find"})
        let redux=store.getState()
        let navigation = redux.navigationReducer.navigation;
        let findNavs={}
        let findConsoleInfo={}
        navigation['pageNavigation'][routeName]['sidebar'].forEach(elem=>{
            if(elem.url===this.props.location.pathname)
            return findNavs=elem.children
        });
        findNavs.forEach(elem=>{
            if(elem.name===name)
            return findConsoleInfo=elem;
        })
        await this.props.setfindConsoleInfo(findConsoleInfo);
        let { findview } = this.props.currentFindConsoleInfo;
        let tableSubAppName = findview.trim() + "Table"
        let dataListName = tableSubAppName.toLowerCase() + "_dataList";
        this.UpdateState(dataListName, [], false);
        this.props.setCurrentPageIndex(this.props.currentFindConsoleInfo.index)
        this.handleMetadataCall();

    }

    cleanUpStateByPrevProps = (prevprops) => {
        let { findview="" } = prevprops.currentFindConsoleInfo;

        let tableSubAppName = findview.length ? findview.trim() + "Table" : "";
        let detailSubAppName = findview.length ? findview.trim() : "";
        let dataListName = tableSubAppName ? tableSubAppName.toLowerCase() + "_dataList" : "";
        let columnListName = tableSubAppName ? tableSubAppName.toLowerCase() + "_columnList" : "";

        this.state[columnListName] && delete this.state[columnListName] ;
        this.state[dataListName] && delete this.state[dataListName];
        this.state.pagelevelValues[detailSubAppName] && delete this.state.pagelevelValues[detailSubAppName];
        this.state.transactionPrimaryObject[detailSubAppName] && delete this.state.transactionPrimaryObject[detailSubAppName];
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.currentFindConsoleInfo && prevProps.currentFindConsoleInfo['name'] &&
        prevProps.currentFindConsoleInfo['name'] !== this.props.currentFindConsoleInfo['name']){
            this.props.setCurrentPageIndex(this.props.currentFindConsoleInfo.index)
            this.cleanUpStateByPrevProps(prevProps);
            this.setState({
                showTable : "none",
                dataListSize : 0,
                updateChildPageFunction : null,
                defaultRowsPerPage : 5,
                pageMetadata: {}
            })
            this.handleMetadataCall();
        }
    }


    handleSubappName = (props, updateState) => {
        const { currentFindConsoleInfo } = props;
        let subappname = currentFindConsoleInfo["SubApplicationList"]
        let detailSubApp = []
        let summarySubApp = []
        let subappNameList = []
        let detailSummaryList = {}
        subappname && subAppListConstructionV1(subappname, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
        updateState("summarySubAppState", summarySubApp, false)
        updateState("SubApplicationNameList", subappNameList, false)
        updateState("detailSummaryList", detailSummaryList, false)
    }
 handleMetadataCall = async() => {
        if(!(Object.keys(this.props.currentFindConsoleInfo).length>0))
        return;
        this.handleSubappName(this.props, this.UpdateState);
        this.props.showLoader();
        let currentViewInfo = JSON.parse(JSON.stringify(this.props.currentFindConsoleInfo));
        let { SubApplicationList,ObjectId,ObjectVerId } = currentViewInfo;
        let {SubApplicationNameList,transactionPrimaryObject} = this.state
        let subAppInfo={
            subappName:SubApplicationNameList,
            objectId:ObjectId,
            objectVerId:ObjectVerId}

        let metadataResponse = await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject})
        this.props.hideLoader();
        this.UpdateState("pageMetadata", metadataResponse, true);

    }

    onFormSubmit = async (e) => {
        this.state.updateChildPageFunction &&  this.state.updateChildPageFunction()
        e.preventDefault();
        await this.getTableData();
        this.setColumnData()
    }

    setColumnData = async () => {
        let summarySubApp = this.props.currentFindConsoleInfo.findview+"Summary"
        let columnname = summarySubApp.toLowerCase()+"_columnList"
        this.state.pageMetadata.ApplicationDetail.SubApplicationDetailList.map((Subapplication)=>{
            if(Subapplication.ApplicationName===summarySubApp)
            {
                this.setState({
                    [`${columnname}`]:Subapplication.AttributeDetailList
                })
            }
        })
    }


    getTableData = async ( offsetInfo = {} ) => {
        try{
        let { StartLimit = "0", EndLimit = this.state.defaultRowsPerPage  } = offsetInfo;

        this.props.showLoader();
        this.UpdateState("showTable", "block", false);

        let { findview, name } = this.props.currentFindConsoleInfo;
        let subApplicationName = findview.trim();

        let requestdata = this.state.pagelevelValues.hasOwnProperty(subApplicationName) ? this.state.pagelevelValues[subApplicationName] : {}
        let findConsoleResponse = await invokeFindConsoleAPI(name, requestdata, {StartLimit, EndLimit, ...offsetInfo}, this.state, this.props);

        let tableSubAppName = findview.trim() + "summary";
        let dataListName = tableSubAppName.toLowerCase() + "_dataList";
        this.props.hideLoader();
        this.UpdateState("dataListSize", findConsoleResponse.DataListSize, false);
        this.UpdateState(dataListName, findConsoleResponse.DataList, true);
        }
        catch(err){
          triggerToast('Error in response')
        }

    }

    handleOnClear = async(component,props) => {
        this.UpdateState("showTable", "none", false);
        let subApplicationName = component.SubApplicationName;

        if(this.state.pagelevelValues.hasOwnProperty(subApplicationName) && Object.keys(this.state.pagelevelValues[subApplicationName]).length > 0)
        {
            await this.setState((prevState) => {
                let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
                let transactionPrimaryObject = Object.assign({}, prevState.transactionPrimaryObject);
                pagelevelValues[subApplicationName] = {};
                transactionPrimaryObject[subApplicationName] = {};
                let pageMetadata = {}
                return { pagelevelValues, transactionPrimaryObject, pageMetadata }
            },()=>{
                this.handleMetadataCall()
            })
        }
        props.reference.current.childs.forEach((child)=>{
            child.makeInvalid()
        })
        props.reference.current.resetValidations()
    }

    redirection = async (ID,goto) => {
        await this.props.setFlowType('Find')
        this.props.history.push({
            pathname: goto,
            state : { "ID" : ID}
        });
    }

    handleAutoCompletechange = (value, item, component) => {
        Find.handleAutoCompletechange(value, item, component, this.UpdateState,this.state)
      };


    onExpand = async (key, value, isExpand) => {
        let { name } = this.props.currentFindConsoleInfo;
        let Name=name.replaceAll(' ','');
        let { ID } = value;
        if (Find[Name]) {
            const getResponse = Find[Name].getAccordionDetails ? await Find[Name].getAccordionDetails(ID, this.props, this.state) : {}
            if (key !== null) {
                this.setState((prevState) => {
                  let arr = prevState[key];
                  arr.forEach((el, idx) => {
                    if(el["ID"] === ID){
                        arr[idx] = {...el, ...getResponse}
                        arr[idx]["isExpanded"] = isExpand.toString();
                    }
                  });
                  return { [key]: arr };
                });
              }
        }
    }
    // handleRedirection = async (ID, goto, data) => {
    //     let { name } = this.props.currentFindConsoleInfo;
    //     this.UpdateState('loading',true,true)
    //       switch(name.toLowerCase())
    //     {
    //         case 'account' :
    //         {
    //             await this.props.setAccountID(ID);
    //             this.redirection(ID,goto)
    //             break
    //         }
    //         case 'quote' :
    //         {
    //             await Promise.all([getNavigationDetails(ID, this.props, name), this.props.setQuoteID(ID)])
    //             this.props.setCurrentPageIndex(1)
    //             this.redirection(ID,goto)
    //             break
    //         }
    //         case 'policy' :
    //         {
    //             await Promise.all([getNavigationDetails(ID, this.props, name), this.props.setPolicyID(ID)])
    //             this.props.setCurrentPageIndex(1)
    //             this.redirection(ID,goto)
    //             break
    //         }
    //         case 'policy by risk' :
    //         {
    //                 await Promise.all([getNavigationDetails(ID, this.props, name), this.props.setPolicyID(ID), this.props.setCurrentPageIndex(1)])
    //                 this.redirection(ID,goto)
    //                 break
    //         }
    //         case 'submission' :
    //             {
    //                 await this.props.setSubmissionID(ID);
    //                 ID = data.InsuredID;
    //             }
    //             case 'insured' :
    //         {
    //             await Promise.all([getNavigationDetails(ID, this.props, name), this.props.setInsuredID(ID)])
    //             this.props.setCurrentPageIndex(2)
    //             this.redirection(ID,goto);
    //             break;
    //         }
    //         default:
    //     }
    //     this.UpdateState('loading',false,true)
    // }
    handleRedirection = async (ID, goto,props) => {
        let { templateName,utilfunction, Status, RateIndication_Flow, QuoteNow_Flow } = this.props.currentFindConsoleInfo
        props.ProcessStatus && props.ProcessStatus.toLowerCase().includes(Status) ? await store.dispatch({type: actiontype.SET_FLOW,payload:RateIndication_Flow}) :
        await store.dispatch({type: actiontype.SET_FLOW,payload:QuoteNow_Flow}) ;
        await store.dispatch({type: actiontype.SET_PROCESS_STATUS,payload: props.ProcessStatus});
        await store.dispatch({type: actiontype.SET_SUBMISSION_STATUS,payload: props.SubmissionStatus});
        await store.dispatch({type: actiontype.SET_SUBMISSION_NUMBER,payload: props.SubmissionNumber});
        if(utilfunction && templateName && utilfunction[templateName]['handleRedirection']){
            this.UpdateState('loading',true,true)
            await utilfunction[templateName]['handleRedirection'](ID,goto,{...this.props,"rowData":{...props}})
            this.UpdateState('loading',false,true)
        }
    }

    handleOnChange = (value, component) => {

      let subApplicationName = component.SubApplicationName;
      this.setState((prevState) => {
        let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
        let transactionPrimaryObject = Object.assign({}, prevState.transactionPrimaryObject);

        if (!pagelevelValues[subApplicationName])
        {
            pagelevelValues[subApplicationName] = {};
        }

        if (!transactionPrimaryObject[subApplicationName])
        {
            transactionPrimaryObject[subApplicationName] = {};
        }

        pagelevelValues[subApplicationName] = Object.assign({}, pagelevelValues[subApplicationName], value);
        transactionPrimaryObject[subApplicationName] = Object.assign({}, transactionPrimaryObject[subApplicationName], value);

        return { pagelevelValues, transactionPrimaryObject }

      },() => {
             component.AjaxNeeded === "true" && this.handleMetadataCall()
        }
    )}

    updateValueWithSubplicationName = (updatedValue, component) => {
        let SubapplicationName = component.SubApplicationName;
        this.setState((prevState) => {
          let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
          if (!pagelevelValues[SubapplicationName]) {
            pagelevelValues[SubapplicationName] = {};
          }
          pagelevelValues[SubapplicationName] = Object.assign(
            {},
            prevState.pagelevelValues[SubapplicationName],
            updatedValue
          );
          return {
            pagelevelValues,
            transactionPrimaryObject: pagelevelValues,
          };
        });
      };
    UpdateState = (statename, value, rerender) =>{
        if(rerender)
        {
          this.setState({
            [statename] : value
          });
        }else{
          this.state[statename] = value;
        }
    }

    handleLazyLoad = (offsetInfo,props,childUpdateState) => {
        childUpdateState && this.UpdateState("updateChildPageFunction",childUpdateState,false)
        this.getTableData(offsetInfo);
    }

    render() {
        let { viewName, showTable} = this.state;
        let { name } = this.props.currentFindConsoleInfo;
        let subapplicationList = [];
        if (this.state.pageMetadata.ApplicationDetail)
        {
            subapplicationList = this.state.pageMetadata.ApplicationDetail.SubApplicationDetailList;
        }

        // let tableUtils = {
        //     "AddRow"    : "",
        //     "EditRow"   : "",
        //     "OnDetailEdit" : "",
        //     "redirectTo" : this.handleRedirection,
        //     "getLazyLoadData" : this.handleLazyLoad,
        //     "onExpand" : this.onExpand
        // };

        // let TableOptions = {
        //     "lazyLoading" : true,
        //     "ChildrenSubApplicationName": null,
        //     "ChildrenComponent": childComp,
        //     "AddButtonNeeded" : false,
        //     "SortingNeeded" : true,
        //     "FilteringNeeded" : true,
        //     "FilterIconNeeded" : false,
        //     "rowsPerPageOptions" : [5, 10, 25],
        //     "RowsPerPage" : this.state.defaultRowsPerPage,
        //     "ActionName"  : "Action",
        //     "ActionListOptions"  : []
        // }
        // let TableControls = {tableUtils,TableOptions}

        let state = {...this.props.currentFindConsoleInfo,...this.state}
        let currentViewInfo = JSON.parse(JSON.stringify(this.props.currentFindConsoleInfo));
        let { ObjectId,ObjectVerId } = currentViewInfo;
        let pageInfo={
            objectId:ObjectId,
            objectVerId:ObjectVerId}

        return (
            <Fragment>
            <ValidatorForm ref={this.referenceCreated} onSubmit={this.onFormSubmit} onError={(errors) => console.log(errors)}>
            <TitleBar viewName={viewName} name={name}/>
            <Card style={styles.card}>
            {
                subapplicationList.map((subapplication) => {
                    const ChildSubApplicationList = subapplication.ChildSubApplicationList ? subapplication.ChildSubApplicationList : [];
                    let attributeList = subapplication.AttributeDetailList;
                    let utilObject={};
                    let templateType = subapplication.Type;
                    let subapplicationName = subapplication.ApplicationName;
                    let TableOptions = subapplication.mAction.ActionList;
                    let tableUtils = subapplication.mAction.addRow;
                    let props=this.props.currentFindConsoleInfo;
                    var { templateName, utilfunction } = props;
                    if (utilfunction) {
                        utilObject = utilfunction[templateName] ? utilfunction[templateName][subapplicationName.toLowerCase()] ? utilfunction[templateName][subapplicationName.toLowerCase()] : {} : {};
                      }
                    if(TableOptions && (TableOptions.RowsPerPage || TableOptions.ChildrenComponent)!= null){
                        TableOptions.RowsPerPage = this.state.defaultRowsPerPage;
                        TableOptions.ChildrenComponent = TableOptions.ChildrenComponent ? childComp : null;
                    }
                    if(tableUtils && (tableUtils.getLazyLoadData || tableUtils.redirectTo || tableUtils.onExpand)!= null){
                        tableUtils.getLazyLoadData = this.handleLazyLoad;
                        tableUtils.redirectTo = this.handleRedirection;
                        tableUtils.onExpand = this.onExpand
                    }
                    let TableControls = {tableUtils,TableOptions};
                    let subAppInfo = {
                        "ComponentList" : attributeList,
                        "SubApplicationDetail" : subapplication
                    };

                    return(
                        <Fragment>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <CardContent className="">
                                    <Grid container spacing={3}>
                                    {
                                        templateType === 'Detail' &&
                                        <Fragment>
                                        {
                                         attributeList.map((component) => {
                                            if(component.RenderValue){
                                                const Controls = container.get('componentControls')
                                                const UIComponent = Controls[component.ComponentType.toLowerCase()];
                                                let attributeValue = this.state.pagelevelValues[component.SubApplicationName]!== undefined ? this.state.pagelevelValues[component.SubApplicationName][component.AttributeName] : undefined;
                                                let transactionValue = this.state.transactionPrimaryObject[component.SubApplicationName]!== undefined ? this.state.transactionPrimaryObject[component.SubApplicationName][component.AttributeName] : undefined;
                                                return(
                                                    <UIComponent
                                                        key={component.ComponentId}
                                                        reference={this.referenceCreated}
                                                        component={component}
                                                        attributeValue={attributeValue}
                                                        transactionPrimaryObject={this.state.transactionPrimaryObject}
                                                        transactionValue={transactionValue}
                                                        componentLabel = {component.ComponentLabel}
                                                        componentvalue={attributeValue}
                                                        isDynamicTable={false}
                                                        pagelevelValues={this.state.pagelevelValues}
                                                        updateValueWithSubplicationName={this.updateValueWithSubplicationName}
                                                        handleInputChangeV2={this.handleOnChange}
                                                        onClick_ClearButton={this.handleOnClear}
                                                        handleAutoCompletechange={this.handleAutoCompletechange}
                                                        UpdateState  = {this.UpdateState}
                                                        {...utilObject}
                                                    />
                                                )
                                            }
                                         })
                                        }

                                        </Fragment>
                                    }
                                    {
                                        (templateType === 'Summary' && showTable === 'block') &&
                                        <div style= {{display: `${showTable}`}} className="find-table">
                                            {/* <ResultDetailBar
                                                dataList={this.state.hasOwnProperty(subapplicationName.toLowerCase()+"_dataList") ? this.state[subapplicationName.toLowerCase()+"_dataList"] : []}
                                                name={name}
                                                dataListSize={this.state.dataListSize}
                                            /> */}
                                            <Stack
                                             mr={"-2.3%"}
                                            >

                                            <DataTable
                                                state={state}
                                                parentProps = {this.props}
                                                ChildSubApplicationList = {ChildSubApplicationList}
                                                subAppInfo   = { subAppInfo }
                                                columnList   = {this.state.hasOwnProperty(subapplicationName.toLowerCase()+"_columnList") ? this.state[subapplicationName.toLowerCase()+"_columnList"] : []}
                                                dataList     = {this.state.hasOwnProperty(subapplicationName.toLowerCase()+"_dataList") ? this.state[subapplicationName.toLowerCase()+"_dataList"] : []}
                                                UpdateState  = {this.UpdateState}
                                                TableOptions = {TableOptions}
                                                tableUtils   = {tableUtils}
                                                pageInfo = {pageInfo}
                                                transactionPrimaryObject = {this.state.transactionPrimaryObject}
                                                dataListSize = {this.state.dataListSize}
                                                subapplicationName = {subapplicationName}
                                                detailSummaryList = {this.state.detailSummaryList}
                                                SubmitButtonNeeded = {false}
                                                TableControls= {TableControls}
                                            >
                                            {TableOptions.ChildrenComponent !=null && <TableOptions.ChildrenComponent/>}
                                            </DataTable>
                                            </Stack>

                                        </div>
                                    }
                                    </Grid>
                                </CardContent>
                            </Grid>
                        </Fragment>
                    )
                })
            }
            </Card>
            </ValidatorForm>
            {
                (this.props.loading > 0 || this.state.loading) &&
                    <Grid>
                        <PageLoader />
                    </Grid>
            }
            </Fragment>
                )
    }
}

const styles = {
    card: {
      boxShadow: "none",
      marginTop: "10px"
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setfindConsoleInfo : (findConsoleInfo) => dispatch(setFindConsoleInfo(findConsoleInfo)),
        setSubmissionID : (submissionID) => dispatch(setSubmissionID(submissionID)),
        setAccountID : (accountID) => dispatch(setAccountID(accountID)),
        setQuoteID : (quoteID) => dispatch(setQuoteID(quoteID)),
        setPolicyID : (policyID) => dispatch(setPolicyID(policyID)),
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        setFlowType: type => dispatch(setFlowType(type)),
        setCurrentPageIndex: (index) => dispatch(setCurrentPageIndex(index)),
        setInsuredID: (insuredID) => dispatch(setInsuredID(insuredID)),
        setAgentID: (agentID) => dispatch(setAgentID(agentID)),
        setPageNavigation: (pageNavigation) => dispatch(setPageNavigation(pageNavigation)),
        setNavigation: (navigation) => dispatch(setPageNavigation(navigation))
    }
}

const mapStateToProps = (state) => {
    return{
        currentFindConsoleInfo : state.UIFlowReducer.currentFindConsoleInfo,
        navigation: state.navigationReducer.navigation,
        navigationReducer: state.navigationReducer,
        loading: state.UIFlowReducer.loading,
        clientInfo : state.ownerResources.clientInfo,
        // productFlowReducer : state.productFlowReducer,
        pageNavigation : state.navigationReducer.navigation.pageNavigation,
        UIFlowReducer: state.UIFlowReducer

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Findd);
