import React, { useEffect, useRef, useState } from "react";
import { FormControl, Grid, InputLabel, Typography } from "@material-ui/core";
import SelectDropdownValidator from "./SelectValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import { attributeSplit } from '../utils/Common/MetaUtils';
import * as SelectUtil from '../utils/Common/SelectUtil'
import { AccordiononErrorContextConsumer } from "../sections/CustomComponents/ContextAPIUtils/AccordiononErrorContext";
import { commonSelectMethod } from "./ButtonUtil";

function DynamicSelect (props) {

    const [value, setValue] = useState('')
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});
    const previousComponentValue = useRef(props.componentvalue);
    const previousComponentDefaultValue = useRef(props.component.DefaultValue);
    const labelRef = useRef();

    useEffect( () => {

        ValidatorForm.addValidationRule("IsEmpty", (value) => {
            if(!value) return false;
            return true;
        });

        const onLoadMethod = async () => {

            if(!props.customTable){
                if(props.component.DefaultValue && props.componentvalue === undefined)
                    props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.component.DefaultValue }, props.component);
            }

            if(props.component.AttributeParams != "" && props.component.AttributeParams != null) {
                let attrParams = attributeSplit(props.component.AttributeParams);
                if(attrParams['persistAttribute'] === "true" && props.hasOwnProperty("handlePersistValue")) {
                    props.handlePersistValue({ [props.component.AttributeName]: props.component.DefaultValue}, props.component);
                }
            }

            if(props.component.Action && Object.keys(props.component.Action).length > 0 && props.component.Action.onLoad) {
                await commonSelectMethod({...props.component, Action: props.component.Action.onLoad}, {}, props, false, stateFunctions);
            }

            if(props.component.Custom2 && props.component.Custom2 != ""){
                let custom2 = JSON.parse(props.component.Custom2.replace(/'/g, '"'));
                let response;
                if(props.hasOwnProperty(custom2['ONLOAD']))
                    response = await props[custom2["ONLOAD"]]({ [props.component.AttributeName]: props.component.DefaultValue }, props.component, props, stateFunctions)
            }

        };

        onLoadMethod();
        setOptions(component.SubApplicationAttributeValueList);

    },[])

    useEffect( () => {

        if(!props.customTable) {
            if(props.componentvalue && previousComponentValue.current && props.componentvalue !== previousComponentValue.current)
                props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.componentvalue }, props.component);
            else if (props.component.DefaultValue !== previousComponentDefaultValue.current && props.componentvalue !== props.component.DefaultValue)
                props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.component.DefaultValue }, props.component);
            else if(options&& options.length === 1 && !props.componentvalue && options[0].Value !== props.componentvalue)
                props.updateValueWithSubplicationName({ [props.component.AttributeName]: options[0].Value }, props.component);
        }

    })
    useEffect( () => {
      const onLoadMethod = async () => {
          if(props.component.Action && Object.keys(props.component.Action).length > 0 && props.component.Action.onLoad) {
              await commonSelectMethod({...props.component, Action: props.component.Action.onLoad}, {}, props, false, stateFunctions);
          }
      };
      if(props.componentState[`${props.component.SubApplicationName}_${props.component.AttributeName}__Update`]){
          onLoadMethod();
          props.functions.updateValues({[`${props.component.SubApplicationName}_${props.component.AttributeName}__Update`]: false})
      }
  }, [props.componentState[`${props.component.SubApplicationName}_${props.component.AttributeName}__Update`]])

    const onChange = (e) => {

        let value = Object.assign({}, { [e.target.name]: e.target.value });

        if(props.component.OnChangeAction && props.pageInfo){

            if(props.pageInfo.selectUtil && props.pageInfo.selectUtil[props.component.OnChangeAction])
                props.pageInfo.selectUtil[props.component.OnChangeAction](props.component, value, props);

            if(props.component.OnChangeAction != '' && SelectUtil[props.component.OnChangeAction])
                SelectUtil[props.component.OnChangeAction](props.component, value, props, options);

        }

        else if (props.component.Action && Object.keys(props.component.Action).length > 0  )
          commonSelectMethod(props.component, value, props,true,stateFunctions);

        else if(props.customTable)
            props.handleRowLevelInputChange(value, props.component, props.customTableRowIndex);

        else {

            if(props.component.AttributeParams != "" && props.component.AttributeParams != null){
                let attrParams = attributeSplit(props.component.AttributeParams)
                if(attrParams['persistAttribute'] === "true" && props.hasOwnProperty('handlePersistValue'))
                props.handlePersistValue(value, props.component);
            }
            props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex);

        }

    }

    const stateFunctions = {
        setValue, setOptions, setLoading
    }
    let autoWidthNeeded = false;
    let { component, isDynamicTable, datatableRowindex, componentvalue } = props;
    let isRequired = component.IsRequired === "Y" ? true : false;
    const validations = [], errorMessages = [];
    let isLabelRequired = true;
    let { variant } = props
    let attrParams = attributeSplit(props.component.AttributeParams);
    let attributeValue = "";
    let labelId = component.ComponentId + "_label";
    let labelClassName = isRequired ? "mandatory attributeLabel" : "attributeLabel";

    if(isDynamicTable) componentvalue = props.componentvalue

    if(attrParams.hasOwnProperty('hideInputLabel')){
        isLabelRequired = false;
    }
    if(attrParams.hasOwnProperty('autowidthNeeded')){
        autoWidthNeeded = true;
    }
    if(isRequired) {
        validations.push("required");
        errorMessages.push(component.RequiredMessage);
    }

    if(props.transactionValue !== undefined){
        const result =options&&  options.find((option) => option['Value'] && option['Value'].trim() === props.transactionValue.trim());
        attributeValue = (result && result['Value']) ? result['Value'] : '';
    }

    if(!attributeValue && component.DefaultValue){
        const result =options&& options.find((option) => option['Value'] && option['Value'].trim() === component.DefaultValue.trim());
        attributeValue = (result && result['Value']) ? result['Value'] : '';
    }

    if(props.transactionValue === undefined && component.DefaultValue){
        const result = options && options.find((option) => option['Value'] && option['Value'].trim() === component.DefaultValue.trim());
        attributeValue = (result && result['Value']) ? result['Value'] : '';
    }

    if( options && options.length === 1 && component.DefaultValue === "" && !attributeValue){
        const result = options[0]
        attributeValue = (result && result['Value']) ? result['Value'] : '';
    }

    return (
        <Grid
            className = {component.StyleClass + ' custom-selectContainer lineHeight'}
            item
            xs = {12}
            sm = {12}
            md = {isDynamicTable ? 12 : component.Size}
            lg = {isDynamicTable ? 12 : component.Size}
            key = {component.AttributeName}
        >
            
            {component.AdjacentLabel === 'Y' ? 
            <>
            <Grid 
            item 
            xs={4} 
            sm={4} 
            md={4} 
            lg={4}  
            className={`${component.AttributeStyleClass}_Label`}
            >
                <InputLabel htmlFor={component.AttributeName} className={`custom-label-down text-font adjacent_label ${labelClassName}`} ref={labelRef}>
                {component.ComponentLabel}
                </InputLabel>
            </Grid>
            <Grid 
            item 
            xs={8} 
            sm={8} 
            md={8} 
            lg={8}  
            className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
            >
            <FormControl fullWidth>
            <AccordiononErrorContextConsumer>
                    {
                        (AccordionError) =>
                        <SelectDropdownValidator
                            loaderNeeded={loading}
                            labelId = {labelId}
                            ComponentId = {component.ComponentId}
                            name={component.AttributeName}
                            component={component}
                            value={attributeValue}
                            options={options}
                            onChange={onChange}
                            validators={validations}
                            errorMessages={errorMessages}
                            variant={variant}
                            autoWidthNeeded={autoWidthNeeded}
                            {...AccordionError}
                        />
                    }
                </AccordiononErrorContextConsumer>
            </FormControl>
            </Grid>
            </>
            :<FormControl style = {isLabelRequired ? styles.dropdown : styles.inlineLabel } className = {`custom-dropdown custom-dropdown-select ${component.AttributeStyleClass}`}>
                {isLabelRequired ?
                    !isDynamicTable && (
                        <InputLabel
                            ref = {labelRef}
                            id = {labelId}
                            className = {`${labelClassName} small_label ${variant === "outlined" && 'selectOutlinedLabel'}`}
                            style = {styles.label}
                        >
                            {component.ComponentLabel}
                        </InputLabel>
                    ) : <Typography> {component.ComponentLabel} </Typography>
                }
                <AccordiononErrorContextConsumer>
                    {
                        (AccordionError) =>
                        <SelectDropdownValidator
                            loaderNeeded={loading}
                            labelId = {labelId}
                            ComponentId = {component.ComponentId}
                            name={component.AttributeName}
                            component={component}
                            value={attributeValue}
                            options={options}
                            onChange={onChange}
                            validators={validations}
                            errorMessages={errorMessages}
                            variant={variant}
                            autoWidthNeeded={autoWidthNeeded}
                            {...AccordionError}
                        />
                    }
                </AccordiononErrorContextConsumer>
            </FormControl>}
        </Grid>
    );
    

}

const styles = {
    selectContainer: {
        display: "flex",
    },
    dropdown: {
        minWidth: "100%",
        alignSelf: "flex-end"
    },
    label: {
        width: "100%",
        position: "relative",
        marginBottom: "-20px"
    },
    inlineLabel:{
        minWidth: "100%",
        alignSelf: "flex-end",
        flexDirection: 'row'
    }
}

export default DynamicSelect;
