import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import { Grid } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import axios from 'axios';
import * as VehicleUtility from '../sections/CustomComponents/VehicleSchedule/VehicleUtility';

Geocode.enableDebug();

class GoogleMapRegion extends Component {

	constructor(props) {
		super(props);
		this.state = {
			city: '',
			state: '',
			country: '',
			county: '',
			zipcode: '',
			SOLState: '',
			SOLZipcode: '',
			SOLLat: '',
			SOLLng: '',
			formattedAddress : '',
			mapPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			},
			markerPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			},
			// showErrorMsg : false,
			props: this.props
		}
	}
	/**
	 * Get the current address from the default map position and set those values in the state
	 */
	componentDidMount() {
		const { clientInfo } = this.props.ownerResources;
		Geocode.setApiKey(clientInfo.GoogleMapsAPIKey);
		ValidatorForm.addValidationRule('IsEmpty', (value) => {
            if (!value) {
                return false;
            }
            return true;
        });
	};

	/**
	 * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
	 *
	 * @param nextProps
	 * @param nextState
	 * @return {boolean}
	 */
	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.SOLCity !== nextState.SOLCity ||
			this.state.SOLState !== nextState.SOLState ||
			this.state.SOLZipcode !== nextState.SOLZipcode ||
			this.state.formattedAddress !== nextState.formattedAddress
		) {
			return true
		}
		if(this.state.showErrorMsg !== nextState.showErrorMsg) return true;
		if(this.props.pagelevelValues[this.props.component.SubApplicationName]){
			if(
				this.props.pagelevelValues[this.props.component.SubApplicationName]['ZipCode'] !== nextProps.pagelevelValues[nextProps.component.SubApplicationName]['ZipCode'] ||
				this.props.pagelevelValues[this.props.component.SubApplicationName]['GaragingLocation'] !== nextProps.pagelevelValues[nextProps.component.SubApplicationName]['GaragingLocation'] ||
				this.props.pagelevelValues[this.props.component.SubApplicationName]['SolartisState'] !== nextProps.pagelevelValues[nextProps.component.SubApplicationName]['SolartisState'] 
			){
				return true
			}
		}
		else{
			if(
				nextProps.pagelevelValues[this.props.component.SubApplicationName] && 
				( nextProps.pagelevelValues[this.props.component.SubApplicationName]['ZipCode'] ||
				nextProps.pagelevelValues[this.props.component.SubApplicationName]['GaragingLocation'] ||
				nextProps.pagelevelValues[this.props.component.SubApplicationName]['SolartisState'] )
			) return true;
		}
		return false;
	}

	/**
		 * Get the street_number and set the street_number input value to the one selected
		 *
		 * @param addressArray
		 * @return {string}
		 */
	getStreetNumber = (addressArray) => {
		let SOLAddress1 = '';
		for (let i = 0; i < addressArray.length; i++) {
			if ('street_number' === addressArray[i].types[0]) {
				SOLAddress1 = addressArray[i].long_name;
			}
			if ('route' === addressArray[i].types[0]) {
				SOLAddress1 = SOLAddress1 + ' ' + addressArray[i].long_name
			}
		}
		return SOLAddress1;
	};

	/**
	 * Get the SOLCity and set the SOLCity input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getCity = (addressArray) => {
		let SOLCity = '';
		for (let i = 0; i < addressArray.length; i++) {
			if ('locality' === addressArray[i].types[0]) {
				SOLCity = addressArray[i].long_name;
				return SOLCity;
			}
		}
	};
	/**
	 * Get the area and set the area input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getStreetName = (addressArray) => {
		let SOLAddress2 = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					// if ('route' === addressArray[i].types[j]) {
					// 	SOLAddress2 = addressArray[i].long_name;
					// }
					if ('postal_code_suffix' === addressArray[i].types[j]) {
						SOLAddress2 = addressArray[i].long_name;
					}
				}
			}
		}
		return SOLAddress2;
	};
	/**
	 * Get the address and set the address input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getState = (addressArray) => {
		let SOLState = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
					SOLState = addressArray[i].short_name;
					return SOLState;
				}
			}
		}
	};

	/**
	 * Get the address and set the address input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getZipcode = (addressArray) => {
		let SOLZipcode = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
					SOLZipcode = addressArray[i].long_name;
					return SOLZipcode;
				}
			}
		}
	};

	/**
	 * Getting location information seperately like eg. City, State, ZipCode from the locationData array.
	 * @param locationData 
	 */

	 getLocationInformation = (locationData) => {
		const SOLCity = this.getCity(locationData);
		const SOLState = this.getState(locationData);
		const SOLZipcode = this.getZipcode(locationData);
		return { SOLCity, SOLState, SOLZipcode };
	}

	/**
	 * Getting full address detail wiht lat and lng data.
	 * @param lat 
	 * @param lng 
	 */

	getFullAddressWithLatAndLng = async (lat, lng) => {
		try {
			const locationData = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.props.ownerResources.clientInfo.GoogleMapsAPIKey}`)
			const { results } = locationData.data;
			let locationInfo;
			for(let i = 0; i < results.length; i++) {
				locationInfo = this.getLocationInformation(results[i].address_components);
				if('SOLZipcode' in locationInfo && locationInfo.SOLZipcode) {
					break;
				}
			}
			this.updateLocationInformation(locationInfo);
		}
		catch(err) {
			console.log(err);
		}
	}

	getStateList = async () => {
		try {
			const stateList = await VehicleUtility.getStateList(this.props);
			return stateList;
		}
		catch(err) {
			console.log("err", err);
			return [];
		}
	}

	/**
	 * Update Location information in state.
	 * @param locationInfo 
	*/

	updateLocationInformation = async (locationInfo = {}) => {
		const { SOLCity = '', SOLState = '', SOLZipcode = '' } = locationInfo;
		let formattedAddress = [SOLCity && SOLCity, SOLState && SOLState, SOLZipcode && SOLZipcode];
		formattedAddress = formattedAddress.filter(name => name !== undefined).join(' - ');
		const stateList = await this.getStateList();
		if(stateList && Array.isArray(stateList) && stateList.includes(SOLState)) {
			this.setState({
				SOLCity: (SOLCity) ? SOLCity : '',
				SOLState: (SOLState) ? SOLState : '',
				SOLZipcode: (SOLZipcode) ? SOLZipcode : '',
				formattedAddress : formattedAddress ? formattedAddress : '',
				showErrorMsg : false
			}, () => {
				
				/* Itemtip param code  */
	
				let addressData = {};
				if (this.props.component.ItemTipParams !== "") {
					let addressheaders = this.props.component.ItemTipParams.split(",");
					addressheaders.forEach((addressheader) => {
						let address = addressheader.split('~');
						addressData[address[1]] = this.state[address[0]];
					})
				}
				this.props.handleGoogleRegion(addressData, this.props.component);
			})
		}
		else this.setState({ showErrorMsg : true, SOLCity : SOLCity })
	}

	/**
	 * When the user types an address in the search box
	 * @param place
	 */
	onPlaceSelected = (place) => {
		const addressArray = place.address_components,
		SOLZipcode = this.getZipcode(addressArray);
		const lat = place.geometry.location.lat();
		const lng = place.geometry.location.lng();
		if(SOLZipcode) {
			const locationInfo = this.getLocationInformation(addressArray);
			this.updateLocationInformation(locationInfo);
		}
		else {
			this.getFullAddressWithLatAndLng(lat, lng);
		}
		
    };

	/**
	 * Clear Address in the input field
	*/
	clearAddress = () => {
		this.setState({
			SOLCity: '',
			SOLState: '',
			SOLZipcode: '',
			formattedAddress : '',
		}, () => {
			let addressData = {};
			if (this.props.component.ItemTipParams !== "") {
				let addressheaders = this.props.component.ItemTipParams.split(",");
				addressheaders.forEach((addressheader) => {
					let address = addressheader.split('~');
					addressData[address[1]] = "";
				})
			}
			this.props.handleGoogleRegion(addressData, this.props.component);
        })
	}

	render() {

		const { pagelevelValues, component } = this.props;

		let addressData = {};
		if (this.props.component.ItemTipParams !== "") {
			let addressheaders = this.props.component.ItemTipParams.split(",");
			addressheaders.forEach((addressheader) => {
				let address = addressheader.split('~');
				if(pagelevelValues && pagelevelValues[component.SubApplicationName]){
					addressData[address[1]] = this.props.pagelevelValues[component.SubApplicationName][address[1]];
				}
			})
		}
		let autocomplete="off";
		let custom3=component.Custom3 && JSON.parse(component.Custom3);
		(custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
	

		const AsyncMap = withScriptjs(
			withGoogleMap(
				props => (
					<GoogleMap google={this.props.google}
						defaultZoom={this.props.zoom}
						defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
					>
						{/* InfoWindow on top of marker */}
						{(this.state.markerPosition.lat !== '' && this.state.markerPosition.lng !== '') &&
							<React.Fragment>
								

								<Marker google={this.props.google}
									name={'Dolores park'}
									draggable={true}
									onDragEnd={this.onMarkerDragEnd}
									position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
								/>
								<Marker />
							</React.Fragment>
						}
						{/* For Auto complete Search Box */}
						<Autocomplete
							style={{
								width: '100%',
								height: '40px',
								paddingLeft: '1px',
								top: '0em',
								marginBottom: '0px',
								position: 'relative',
								borderTop: 'none',
								borderRight: 'none',
								borderLeft: 'none'
							}}
							autoComplete={autocomplete}
							id="googleAutoComplete"
							placeholder="Garaging Location *"
							onPlaceSelected={this.onPlaceSelected}
							types={['(regions)']}
							componentRestrictions={{ country: "us" }}
						/>
						{
							this.state.showErrorMsg &&
							<div style={styles.errorMsg}>Please add State to add Vehicles</div>
						} 
					</GoogleMap>
				)
			)
		);
		let map;
		if (this.props.center.lat !== undefined) {
			map = <Grid item xs={12} sm={12} md={12} lg={12}>
				<div>
					<Grid container spacing={3} style={{ marginTop : '0rem', marginBottom : '3rem' }}>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							{
								!this.state.formattedAddress && !addressData.SolartisGaragingLocationLookup ? 
									<AsyncMap
										googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${this.props.ownerResources.clientInfo.GoogleMapsAPIKey}&v=3.exp&libraries=geometry,drawing,places`}
										loadingElement={
											<div style={{ height: `0%`, width: `100%` }} />
										}
										containerElement={
											<div style={{ height: '0px' }}
											/>
										}
										mapElement={
											<div style={{ height: `0%`, width: `100%`, position: 'relative', marginTop: '0%', marginBottom: '0.1%' }}
											/>
										}
									/> : 
									<FormControl style={{ width: '100%', marginTop : '0px', marginBottom : '-2rem' }}>
										<InputLabel htmlFor="standard-adornment-password">Graging Location</InputLabel>
										<Input
											className="custom-input"
											autoComplete={autocomplete}
											value={this.state.formattedAddress ? this.state.formattedAddress : addressData.SolartisGaragingLocationLookup}
											disabled={component.IsReadonly}
											endAdornment={
											component.IsReadonly !== 'true' && (
													<InputAdornment position="end">
														<IconButton aria-label="toggle password visibility">
															<ClearIcon onClick={this.clearAddress}/>
														</IconButton>
													</InputAdornment>
												)
											}
										/>
									</FormControl>
							}
						</Grid>
					</Grid>
				</div>
			</Grid>

		} else {
			map = <div style={{ height: '0px' }} />
		}
		return (map)
	}
}

const styles = {
	errorMsg : {
		padding : '7px 0px',
		color : 'rgb(244, 67, 54)'
	}
}

const mapStateToProps = (state) => ({
	ownerResources : state.ownerResources
})

export default connect(mapStateToProps)(GoogleMapRegion)
