import React, { useState, useEffect } from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from 'axios';
import { AxiosInstance, getJSONRequestFromAPI, commonProductAxiosInstance } from '../utils/Common/API';
import { connect } from 'react-redux';
import { setTransactionRequest } from '../../../actions/workFlowEventAction';

const AutoCompleteV3 = (props) => {

    // props
    const { component, componentvalue, pagelevelValues, lobUtil } = props;
    const { LookupInstanceName, OnClickAction = null } = component;

    // states
    const [value, setValue] = useState(componentvalue ? componentvalue : '');
    const [inputValue, setInputValue] = useState('');
    const [defaultValue, setDefaultValue] = useState({});
    const [attributeInfo, setAttributeInfo] = useState({});
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([] || {});
    const [showlist, setShowlist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedvalue, setselectedvalue] = useState(null);
    const showSearchIcon = !componentvalue ? true : false;


    useEffect (() => {
        defaultInfoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    // useEffect for setSearchQuery
    useEffect(() => {
        let axiosCancelToken = axios.CancelToken.source();
        let timerID = null;
        const timedelay = () => { invokeAutocomplete(axiosCancelToken) }
        if(open && inputValue.length === 0) {
            setSearchQuery(inputValue);
            if ( OnClickAction && lobUtil[OnClickAction] )
                timerID = lobUtil[OnClickAction](timedelay);
            else invokeAutocomplete(axiosCancelToken);
        }
        if(open && inputValue.length > 0) {
            timerID = setTimeout(() => {
                setSearchQuery(inputValue);
            }, 800)
        }

        return () => {
            if(timerID) clearTimeout(timerID);
            if(axiosCancelToken) axiosCancelToken.cancel();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, inputValue])


    // useEffect for fetch data
    useEffect(() => {

        let axiosCancelToken = axios.CancelToken.source();

        const fetchData = async () => {
            if (!open) {
                setLoading(false);
                setOptions([]);
            }
            if (searchQuery.length > +component.MinValue && open) {
                invokeAutocomplete(axiosCancelToken)
            }
        }
        fetchData();

        return () => { if (axiosCancelToken) axiosCancelToken.cancel() }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, open])


    // useEffect for setting values initially
    useEffect(() => {
        if (componentvalue) {
            setValue({ [LookupInstanceName]: componentvalue });
        }
        else {
            setValue('');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentvalue])


    // API call
    const invokeAutocomplete = async (axiosCancelToken) => {
        try {
            setLoading(true);
            let request;
            let EventParam = component.EventName.split('~');
            let [EventName,EventAction,EventMaster] = EventParam;
            let localAxiosInstance = AxiosInstance;
            let requestEvent = EventAction ? EventAction : EventName
            if (props.workflowReducer.transactionRequest.hasOwnProperty(requestEvent)) {
                request = JSON.parse(JSON.stringify(props.workflowReducer.transactionRequest[requestEvent]));
            }
            else {
                request = await getJSONRequestFromAPI(requestEvent, props.UIFlowReducer.productDetails, attributeInfo.EventVersion);
                props.setTransactionRequest(JSON.parse(JSON.stringify(request)));
            }
            if(attributeInfo.isValuesNeeded === 'false'){
                request = Object.assign({}, request, props.pagelevelValues[component.SubApplicationName], { [component.LookupInstanceName] : searchQuery }, defaultValue);
            }else{
                request = Object.assign({}, request, props.values, props.pagelevelValues[component.SubApplicationName], { [component.LookupInstanceName] : searchQuery }, defaultValue);
            }
            if(attributeInfo.values){
                attributeInfo.values.replace(/[\[\]]/g,'').split('-').map(el => {
                    request[el] = props.values[el];
                })
            }
            let versionString = "core/invokeWorkflowEvent";
            attributeInfo.EventVersion && (versionString += `/${attributeInfo.EventVersion}`);
            if(EventAction){
                request.EventName = EventMaster;
                request.EventAction = EventAction;
                request.TargetEventName = EventName;
                localAxiosInstance = commonProductAxiosInstance;
            }
            const response = await localAxiosInstance.post(versionString, request, {
                cancelToken: axiosCancelToken ? axiosCancelToken.token : ''
            });
            if (Array.isArray(response.data) && response.data.length > 0) {
                setOptions(response.data);
            }
            else if (typeof response === 'object' && Object.keys(response.data).length > 0) {
                setOptions(response.data[component.ResponseKey]);
            }
            else {
                setOptions(undefined);
            }
            setLoading(false);
        }
        catch (err) {
            if (err === 'Cancelled') {
                return;
            }
            setLoading(false);
            console.log(err);
        }
    }


    // info icon data
    const InfoList = () => {
        let itemparams = component.ItemTipParams.split(",");
        let value = {};
        itemparams.forEach((itemparam, index) => {
            if (index > 0) {
                let item = itemparam.split("~");
                if (item[0].includes('_')){
                    let itemChild = item[0].split("_");
                    value[item[1]] = pagelevelValues[component.SubApplicationName].hasOwnProperty(item[0]) ?
                    pagelevelValues[component.SubApplicationName][item[0]] :
                    (pagelevelValues[component.SubApplicationName].hasOwnProperty(itemChild[1]) ?
                    pagelevelValues[component.SubApplicationName][itemChild[1]] : '');
                }
                else{
                    value[item[1]] = pagelevelValues[component.SubApplicationName].hasOwnProperty(item[0]) ?
                    pagelevelValues[component.SubApplicationName][item[0]] :
                    pagelevelValues[component.SubApplicationName][component.SubApplicationName + item[0]];
                }
            }
        })
        setselectedvalue(value);
    }
    // let autocomplete="off";
    // let custom3=component.Custom3 && JSON.parse(component.Custom3);
    // (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    // To set default values to empty
    const defaultInfoList = () => {
        let itemparams = component.ItemTipParams.split(",");
        let attributeParams = component.AttributeParams.split(",");
        let attributeInfo  = {}
        let defaultValue = {};
        itemparams.forEach((itemparam, index) => {
            if (index > 0) {
                let item = itemparam.split("~");
                defaultValue[item[0]] = "";
            }
        })
        attributeParams.forEach((attributeParam,index) => {
            if(index >= 0){
                let item = attributeParam.split("~");
                attributeInfo[item[0]] = item[1];
            }
        })
        setDefaultValue(defaultValue);
        setAttributeInfo(attributeInfo)
    }

    // handleOptionSelect
    const handleOnChange = (e, data) => {
        let newObj = {};
        if (data) {
            let displayValue = data.LookupDisplayValue ? data.LookupDisplayValue : data.DisplayName
            let object ={}
            if(component.ItemTipParams){
                let items = component.ItemTipParams.split('~')
                items.map(item=>{
                    object[item] = data[item]
                })
            }else{
                object=data
            }
            newObj = {
                [component.AttributeName]: displayValue ? displayValue : data[component.AttributeName],
                ...object
            }
        }
        setValue(data);
        props[component.OnChangeAction] && props[component.OnChangeAction](props, newObj, attributeInfo);
        data && props.handleAutoCompletechange(newObj, component.ItemTipParams, component);
    }

    const handleOnInputChange = (e, data) => {
        let object={}
        if(component.ItemTipParams){
            let items = component.ItemTipParams.split('~')
            items.map(item=>{
                object[item] = ''
            })
        }
        let newObj = {
            [component.AttributeName]: '',
            ...object
        }
        setInputValue(data)
        !data && props.handleAutoCompletechange(newObj, component.ItemTipParams, component);
    }

    // validations
    ValidatorForm.addValidationRule('IsEmpty', (value) => {
        if (!value) {
            return false;
        }
        return true;
    });
    let variant = props.variant
    let isRequired = component.IsRequired === "Y" ? true : false;
    const validations = [];
    const errorMessages = [];
    if (isRequired) {
        validations.push('required');
        errorMessages.push(component.RequiredMessage);
        validations.push('IsEmpty');
        errorMessages.push(component.RequiredMessage);
    }
    let styleObject = {};
    if(component.AdjacentLabel === 'Y')
      styleObject = {display:'flex'};
    return (
        <Grid style={{...styles.mainGrid,...styleObject}} item xs={12} md={component.Size? component.Size : 7}
        lg={component.Size? component.Size : 7} sm={12}
        key={component.AttributeName}  className={component.AttributeStyleClass + (component.AdjacentLabel === "Y" ? "labelrightalignment": "")}>
            {component.AdjacentLabel === "Y"  ?
             <>
                <Grid style={styles.mainGrid} item xs={4} md={component.Size? 4 :7} lg={component.Size? 4 :7} sm={4} key={component.AttributeName} className='adjacent_label'>
                    <InputLabel htmlFor={component.AttributeName} className="custom-label-down adjacent_label">{component.IsRequired === 'Y' ? `${component.ComponentLabel} *` : component.ComponentLabel}</InputLabel>
                </Grid>
                <Grid style={styles.mainGrid} item xs={8} md={component.Size? 8 :7} lg={component.Size? 8 :7} sm={8} key={component.AttributeName} className='adjacent_TextValidator_Grid' >
                    <Autocomplete
                    style={{ display: "inline-flex", width: "100%" }}
                    freeSolo
                    className={`custom-autocomplete ${component.StyleClass} lineHeight`}
                    disabled={component.IsReadonly}
                    id={component.AttributeName}
                    value={value}
                    options={options ? options : []}
                    onOpen={() => setOpen(true)}
                    onFocus={()=> setOpen(true)}
                    onClose={() => setOpen(false)}
                    inputValue={inputValue}
                    onChange={(e, newValue) => handleOnChange(e, newValue)}
                    onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
                    openOnFocus={true}
                    getOptionLabel={(option) => {
                        if(!option[LookupInstanceName]) return '';
                        return option[LookupInstanceName]
                    }}
                    loading={loading}
                    clearOnBlur={false}
                    renderInput={(params) =>
                        <TextValidator {...params}
                            placeholder={component.ComponentLabel}
                            value={inputValue}
                            className="custom-input"
                            name={component.AttributeName}
                            margin="normal"
                            validators={validations}
                            errorMessages={errorMessages}
                            variant={variant}
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {showSearchIcon && <IconButton> <SearchIcon /> </IconButton>}
                                    </React.Fragment>
                                )
                            }}
                        />}
                    />
                </Grid>
             </> 
             :
                <Autocomplete
                    style={{ display: "inline-flex", width: "99%" }}
                    freeSolo
                    className={`custom-autocomplete small_options ${component.StyleClass} lineHeight`}
                    disabled={component.IsReadonly}
                    // id={component.AttributeName}
                    value={value}
                    options={options ? options : []}
                    // autoComplete={autocomplete}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    inputValue={inputValue}
                    onChange={(e, newValue) => handleOnChange(e, newValue)}
                    onInputChange={(e, newInputValue) =>handleOnInputChange(e, newInputValue)}
                    getOptionLabel={(option) => {
                        if(!option[LookupInstanceName]) return '';
                        return option[LookupInstanceName]
                    }}
                    loading={loading}
                    clearOnBlur={true}
                    renderInput={(params) =>
                        <TextValidator {...params}
                            placeholder={component.ComponentLabel}
                            value={inputValue}
                            className="custom-input"
                            // name={component.AttributeName}
                            // autoComplete={autocomplete}
                            label={(component.AdjacentLabel !== "Y")&&(<Typography className="custom-label-down text-font small_label">{component.IsRequired === 'Y' ? `${component.ComponentLabel} *` : component.ComponentLabel}</Typography>)}
                            margin="normal"
                            variant={variant}
                            validators={validations}
                            errorMessages={errorMessages}
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {showSearchIcon && <IconButton> <SearchIcon /> </IconButton>}
                                    </React.Fragment>
                                )
                            }}
                        />}
                />
                }
                {/* {
                    value && value.DisplayValue !== "" && component.ItemTipParams &&
                    <IconButton className="custom-svg-icon InfoiconIEOverride" aria-label="add an alarm" style={{ transform: "rotate(180deg)", color: '#20a8d8', fontSize: '1rem !important' }} onBlur={() => { setShowlist(false) }} onClick={() => { setShowlist(!showlist); InfoList(); }} >
                        <ErrorIcon />
                    </IconButton>
                } */}
                {
                    showlist ?
                        <Card className="autocomplete_info_box">
                            <CloseIcon style={{ float: 'right', paddingRight: '5px', paddingTop: '5px', fontSize: '20px' }} onClick={() => { setShowlist(false) }} />
                            <CardContent>
                                {
                                    selectedvalue ?
                                        Object.keys(selectedvalue).map((key, index) => {
                                            return (
                                                <div style={{ marginBottom: '5px' }} key={index}>
                                                    <p style={{ fontWeight: 'bold', marginBottom: '0px' }}>{key}</p>
                                                    <p style={{ marginBottom: '0px' }}>{selectedvalue[key]}</p>
                                                </div>
                                            )
                                        })
                                        : null
                                }
                            </CardContent>
                        </Card>
                        :
                        null
                }
        </Grid>
    )

}

const styles = {
    mainGrid : {
        position : 'relative'
    }
}

const mapStateToProps = state => ({
    workflowReducer: state.workflowReducer,
    metadataReducer: state.metadataReducer,
    UIFlowReducer: state.UIFlowReducer
});

const mapDispatchToProps = dispatch => {
    return {
        setTransactionRequest: request => dispatch(setTransactionRequest(request))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteV3);
