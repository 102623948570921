import { constructAndInvokeMetadataCall } from '../../../utils/Common/MetaUtils';
import { getServiceRequestDetail, AxiosInstance } from '../../../utils/Common/API';
import { Toast, triggerToast } from '../../../utils/Common/HelperUtils';
import appActions from "../../../../../actions/index";
import store from "../../../../../store";


const { actionType } = appActions

function componentDidMount() {
    const { popupDetail = null } = this.props;
    if(!popupDetail) return;
    const { popupContent = null } = popupDetail;
    getMetdataRequest(this);
    if(this.props.bulkCopy!==true){
    getVehicleStatus(this, popupContent);
}
}

/**
 * classObject param is "this" object of parent class
 * @param {this} clssObject
 * @returns
 */

export const getMetdataRequest = async (classObject) => {
    // const { popupDetail = null } = classObject.props;
    // if(!popupDetail) return;
    // const { popupContent = null } = popupDetail;
    // if(!popupContent) return;
    const subApplicationList = classObject.props.subappNameList;
    if(subApplicationList.length === 0) return;
    const {  ProductID : objectId , ProductVerID : objectVerId } = classObject.props.productDetails;
    // classObject.props.functions.handleLoader(true); -Commented bcz of Utils_Orhcid.js getSchedultOfForms api response is getting delay
    let params = {
        transactionPrimaryObject: classObject.props.pagelevelValues,
        EffectiveDate: classObject.props.values['EffectiveDate'] && classObject.props.values['EffectiveDate']
    };
    // const request = constructMetaDataRequestV1({ subappName: subApplicationList, objectId, objectVerId, state : 'CW' }, params);
    // metadataInvocation(request, classObject);
    // const metaDataResponse = await invokeMetadata(request);
    const metaDataResponse = await constructAndInvokeMetadataCall({ subappName: subApplicationList, objectId, objectVerId, state : 'CW' }, params)
    classObject.UpdateStateContent("pageMetadata", metaDataResponse, true);
    // classObject.props.functions.handleLoader(false);
    return metaDataResponse;
}

// const metadataInvocation = async (request, classObject) => {
//     const metaDataResponse = await invokeMetadata(request);
//     classObject.UpdateStateContent("pageMetadata", metaDataResponse, false);
//     classObject.handleLoader(false);
// }

export const handleInputChangeV2 = (value, component, props,state) => {
    let pagelevelValue=state.pagelevelValue;
    let transactionValue=state.transactionValue;
    let keys = Object.keys(value);
    let temp = {};
    let attributeName = keys[0];
    let attributeValue = value[attributeName];
    let subappName = component.SubApplicationName;
    let pagelevelValues = Object.assign({}, pagelevelValue);
    let transactionKeyList = Object.assign({}, transactionValue);
    if (!pagelevelValues[subappName]) {
        pagelevelValues[subappName] = {};
    }
    if (!transactionKeyList[subappName]) {
        transactionKeyList[subappName] = {};
    }
    pagelevelValues[subappName][attributeName] = attributeValue;
    transactionKeyList[subappName][attributeName] = attributeValue;
    temp["pagelevelValues"] = pagelevelValues;
    temp["transactionKeyList"] = transactionKeyList;
    return temp;
}

const updateDataTable = (vehicleList = [], reRenderRequired , subApplicationName, classObject) => {
    if(!subApplicationName) return;
    const { props } = classObject;
    let dataListName = subApplicationName.toLowerCase() + "_dataList";
    let dataListValue = vehicleList;
    props.UpdateState(dataListName, dataListValue, reRenderRequired);
}

export const toastify = (message) => {
    Toast.error("Try again! Something went wrong. "+ message, { position: Toast.POSITION.TOP_CENTER })
}
export const infoToast = (message) => {
    Toast.info(message, { position: Toast.POSITION.TOP_CENTER });
}



/* API Methods */

const getVehicleStatus = async (classObject, popupInfo) => {
    const { props } = classObject;
    const { popupDetail = null } = props;
    if(!popupDetail) {
        toastify('Error while reading the data');
        return;
    }
    const { getVehicleStatus } = popupDetail.eventInfo;
    let submissionID = "";
    let subApplicationName = popupInfo ? popupInfo.subApplicationList[0] : null;

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let getVehicleSatusRequest = {};
    getVehicleSatusRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    getVehicleSatusRequest["OwnerId"] = sessionStorage.getItem('OwnerID');
    getVehicleSatusRequest["EventName"] = getVehicleStatus;
    getVehicleSatusRequest["ID"] = submissionID;

    try {
        let response = await AxiosInstance.post("/core/invokeWorkflowEvent", getVehicleSatusRequest);
        if (Object.keys(response.data).length > 0 && response.data.hasOwnProperty("VehicleStatus")) {
            updateDataTable(response.data.VehicleStatus, true, subApplicationName, classObject);
        }
        else {
            toastify('Error while invoking vehicle status')
        }
    }
    catch (err) {
        console.log("Error in GetStateList API", err.message);
        toastify('Error while invoking vehicle status')
    }

}

const deleteStateAU= async (props, eventName) => {
    props.setShowLoader(true)
    props.handleClosePopup(false);
    try{
        let deleteRequest = {};
        deleteRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
        deleteRequest["OwnerId"] = sessionStorage.getItem("OwnerID");
        deleteRequest["EventName"] = "SolartisMasterWF";
        deleteRequest["TargetEventName"] = eventName;
        deleteRequest["ID"] = props.stateInfo.ID;
        deleteRequest["ROOTID"] = props.UIFlowReducer.submissionID;
        let response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", deleteRequest);
        if(response.data.hasOwnProperty('StateList')){
            props.setStateList(response.data.StateList);
            let formatterStateList = getFormattedStateName(response.data.StateList);
            store.dispatch({
                type: actionType.INVOKE_GET_VEHICLE_LIST,
                payload: true
            });
            store.dispatch({
                type: actionType.SET_POLICY_STATELIST,
                payload: formatterStateList
            });
        }
        else{
            throw new Error('Error while Deleting State')
        }
    }
    catch (error){
        console.log('Error while trying to delete state', error)
        triggerToast(error.message)
    }
    finally{
        props.setShowLoader(false)
    }
}

const getFormattedStateName = (stateList) => {
    let tempStateList = stateList.reduce((initialElement, currentElement) => {
        return [
            ...initialElement,
            currentElement.State
        ]
    }, []);

    let stateCode = "";
    if(tempStateList.length === 0) return "''";
    tempStateList.forEach((state, index) => {
        if (index === tempStateList.length - 1) {
            stateCode = stateCode + "'" + state + "'";
        } else {
            stateCode = stateCode + "'" + state + "'" + ",";
        }
    });
    return stateCode;
}

const handleClosePopupStatus = (props) => {
    props.handleClosePopup(false);
}


const invokeSelectiveDeleteVehicle= async (props) => {
    const { OwnerId, masterEventName, deleteVehicleEvent } = props.pageInfo;

    let submissionID = "";
    let vehicleID =props.currentRowInfo.ID;
   let vehicleList='';


    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }
    let deleteVehicleRequest = Object.create({});
    deleteVehicleRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    deleteVehicleRequest["OwnerId"] = OwnerId;
    deleteVehicleRequest["EventName"] = masterEventName;
    deleteVehicleRequest["TargetEventName"] = deleteVehicleEvent;
    deleteVehicleRequest["ROOTID"] = submissionID;
    deleteVehicleRequest["ID"] = vehicleID;
    props.loader(false);
           props.handleClosePopup(false);
    try {
        let deleteVehicleResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", deleteVehicleRequest);
         deleteVehicleResponse = await deleteVehicleResponse.data;
         if (Object.keys(deleteVehicleResponse).length > 0) {
             vehicleList=deleteVehicleResponse.hasOwnProperty('VehicleList') ? deleteVehicleResponse['VehicleList'] : [];
            props.updateDataTable(vehicleList,true);
            infoToast('Selected Vehicles Removed Successfully');

        }
        else throw new Error('Something Went Wrong');
    }
    catch (e) {
        toastify("Error in deleteVehicleResponse");
        console.error("Error in deleteVehicleResponse", e.message);
        return [];
    }
    finally{
        props.loader(true);

    }

}
const invokeDeleteAllVehicle = async (props) => {
    const { OwnerId, deleteAllVehicleEvent } = props.pageInfo;
    let submissionID = "";
    let vehicleList='';

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let deleteAllVehicleRequest = Object.create({});
    deleteAllVehicleRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    deleteAllVehicleRequest["OwnerId"] = OwnerId;
    deleteAllVehicleRequest["EventName"] = deleteAllVehicleEvent;
    deleteAllVehicleRequest["ID"] = submissionID;
     props.handleLoad(true);
    props.handleClosePopup(false);
    try {
        let deleteAllVehicleResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", deleteAllVehicleRequest);
        deleteAllVehicleResponse = await deleteAllVehicleResponse.data;
        if (Object.keys(deleteAllVehicleResponse).length > 0) {
            vehicleList= deleteAllVehicleResponse.hasOwnProperty('VehicleList') ? deleteAllVehicleResponse['VehicleList'] : [];
            props.updateVehicleList(vehicleList);
            infoToast('All Vehicles Removed Successfully');
        }
        else throw new Error('Something Went Wrong');
    }
    catch (e) {
        toastify("Error in deleteAllVehicle API");
        console.error("Error in deleteAllVehicle API", e.message);
        return [];
    }
     finally{
         props.handleLoad(false);
         props.toggleExpand(true);
     }

}
const functions = {
    componentDidMount : componentDidMount,
    getMetdataRequest : getMetdataRequest,
    // metadataInvocation : metadataInvocation, //why here?
    handleInputChangeV2 : handleInputChangeV2,
    removeState: deleteStateAU,
    handleClosePopup: handleClosePopupStatus,
    invokeSelectiveDeleteVehicle : invokeSelectiveDeleteVehicle,
    invokeDeleteAllVehicle:invokeDeleteAllVehicle
}

export default functions;
