import React, { useState, useEffect, useRef } from 'react';
import { Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { getDateWithoutOffset } from "../utils/Common/DateUtils";

export default function ExpirationDate(props){

    // useState
    const [ openExpirationDate, setOpenExpirationDate ] = useState(false);

    // props
    const { component, values, pagelevelValues } = props;

    // refs
    const effectiveDateRef = useRef(pagelevelValues[component.SubApplicationName]);

    const openCloseCalender = () => {
        setOpenExpirationDate(!openExpirationDate);
    }

    const setExpirationDate = (expirationDate, defaultValue) => {
        let expirationDateValue = expirationDate;
        if(defaultValue){
            if(!isNaN(defaultValue)){
                let month = Number(defaultValue);
                return expirationDateValue.setMonth(expirationDate.getMonth() + month);
            }
            else{
                return new Date();
            }
        }
        else{
            expirationDateValue.setFullYear(expirationDate.getFullYear() + 1);
            expirationDateValue.setMonth(expirationDate.getMonth());
            expirationDateValue.setDate(expirationDate.getDate());
        }
        return expirationDateValue;
    }

    const getExpirationDate = () => {
        let expirationDate = setExpirationDate(new Date(effectiveDateRef.current), component.DefaultValue);
        let formatedDate = format(new Date(expirationDate), component.InputFormatPattern);
        if ( values[expirationDate] === undefined ) {
            props.handleDateChange( expirationDate, formatedDate, component.AttributeName, component );
        }
    }

    useEffect(() => {

        if(pagelevelValues[component.SubApplicationName] && pagelevelValues[component.SubApplicationName]['EffectiveDate']){
            effectiveDateRef.current = pagelevelValues[component.SubApplicationName]['EffectiveDate'];
            getExpirationDate();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagelevelValues[component.SubApplicationName] && pagelevelValues[component.SubApplicationName]['EffectiveDate']])


    useEffect(() => {

        if(component.DefaultValue !== ''){
            getExpirationDate();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component.DefaultValue])


    const handleInternally = (event, value) => {
        let expirationDate = getDateWithoutOffset(value);
        let formatedDate = format(expirationDate, component.OutputFormatPattern);
        setOpenExpirationDate(!openExpirationDate);
        props.handleDateChange(expirationDate, formatedDate, component.AttributeName, component);
    }
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    const dateValue = values[component.AttributeName] ? getDateWithoutOffset(values[component.AttributeName]) : values[component.AttributeName];

    return(
        <React.Fragment>
            <Grid item xs={12} sm={12} md={6} lg={6} key={component.AttributeName}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    //variant="inline"
                    //orientation="landscape"
                    open={openExpirationDate}
                    onClose={openCloseCalender}
                    onClick={!component.IsReadonly && openCloseCalender}
                    format="MM/dd/yyyy"
                    margin="normal"
                    name={component.AttributeName}
                    id={component.AttributeName}
                    label={component.ComponentLabel}
                    placeholder="mm/dd/yyyy"
                    value={dateValue}
                    minDate={new Date()} // min date update - based on effective date
                    readOnly={component.IsReadonly}
                    autoComplete={autocomplete}
                    onChange={(event, value) => {
                        handleInternally(event, value)
                    }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </React.Fragment>
    )

}
