export const model_PDFGenerateRequest = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
'<PdfGenerationRq xmlns:ns2="http://www.solartis.net/schema/ServiceRequest">' + 
    '<ServiceRequestDetail>' +
        '<ns2:ServiceRequestVersion>7.0</ns2:ServiceRequestVersion>' +
        '<ns2:OwnerId>{OWNER_ID}</ns2:OwnerId>' +
        '<ns2:Token>{TOKEN}</ns2:Token>' +
        '<ns2:UserName>{USER_NAME}</ns2:UserName>' +
        '<ns2:LanguageCode></ns2:LanguageCode>' +
        '<ns2:ResponseType>XML</ns2:ResponseType>' +
    '</ServiceRequestDetail>' +
    '<FormDataList>' +
        '<FormData>' +
            '<XmlSource>&lt;Root&gt;&lt;/Root&gt;</XmlSource>' +
            '<FormTemplates>' +
                '<FormTemplate>' +
                    '<FormLocation>{FORM_LOCATION}</FormLocation>' +
                    '<FormSequence>0</FormSequence>' +
                '</FormTemplate>' +
            '</FormTemplates>' +
        '</FormData>' +
    '</FormDataList>' +
    '<FopFileLocation>{FOP_FILE_LOCATION}</FopFileLocation>' +
    '<AddPageNumber>No</AddPageNumber>' +
    '<OutputFileLocation>{OUTPUT_FILE_LOCATION}</OutputFileLocation>' +
    '<OutputFileName>QuoteProposal_</OutputFileName>' +
    '<IsPreview></IsPreview>' +
'</PdfGenerationRq>'
