export const subAppListConstruction = (subappname, detailSummaryList, subappNameList, detailSubApp, summarySubApp) => {
    if (subappname && subappname["locationDetail"]) {
        subappname = subappname["locationDetail"]
    }
    for (let i = 0; i < subappname.length; i++) {
        let subAppNme = subappname[i]
        let splitValue = ''
        if (subAppNme.includes("_")) {
            let splitter = subAppNme.split("_")
            splitValue = splitter[1]
        }
        if (subAppNme.includes("Detail")) {
            let splitVal = subAppNme.split("Detail")
            for (let j = 0; j < subappname.length; j++) {
                if (splitVal[0] + "Summary" === subappname[j] || splitVal[0] === subappname[j] || splitVal[0] + "Summary_" + splitValue === subappname[j]) {
                    detailSummaryList[subappname[j]] = subAppNme
                    detailSubApp.push(subAppNme)
                    summarySubApp.push(subappname[j])
                }
            }
        }
    }
    for (let a = 0; a < subappname.length; a++) {
        let subAppNme = subappname[a]
        if (detailSubApp.includes(subAppNme)) {

        }
        else {
            if (!subappNameList.includes(subAppNme)) {
                subappNameList.push(subAppNme)
            }
        }
    }
}

export const subAppListConstructionV1 = (subappname, detailSummaryList, subappNameList, detailSubApp, summarySubApp) => {
  if (subappname && subappname["locationDetail"]) {
      subappname = subappname["locationDetail"]
  }
  for (let i = 0; i < subappname.length; i++) {
      let subAppNme = subappname[i]
      let splitValue = ''
      if (subAppNme.includes("_")) {
          let splitter = subAppNme.split("_")
          splitValue = splitter[1]
      }
      if (subAppNme.includes("Detail")) {
          let splitVal = subAppNme.split("Detail")
          for (let j = 0; j < subappname.length; j++) {
              if (splitVal[0] + "Summary" === subappname[j] || splitVal[0] === subappname[j] || splitVal[0] + "Summary_" + splitValue === subappname[j]) {
                  detailSummaryList[subappname[j]] = detailSummaryList[subappname[j]] ? `${detailSummaryList[subappname[j]]},${subAppNme}`: subAppNme
                  detailSubApp.push(subAppNme)
                  summarySubApp.push(subappname[j])
              }
          }
      }
  }
  for (let a = 0; a < subappname.length; a++) {
      let subAppNme = subappname[a]
      if (detailSubApp.includes(subAppNme)) {

      }
      else {
          if (!subappNameList.includes(subAppNme)) {
              subappNameList.push(subAppNme)
          }
      }
  }
}
