import React, { Component, Fragment, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import store from "./store";
import appActions from "./actions";
import IdleTimer from "react-idle-timer";
import { idleTimeOut } from "../src/views/runtime/utils/Common/config";
import { authAxios } from "../src/views/runtime/utils/Common/API";
import  PageLoader  from '../src/views/runtime/controls/PageLoader';
import { ResourceAxios, getNavigation } from './CommonAxios/ResourceAxios';
import Cookies from 'universal-cookie';
import { withTranslation } from 'react-i18next';
import { SET_USER_LANGUAGE } from './actions/types';
import DynamicContent from "./containers/DynamicContent";
import {logUserDetailRequest} from '../src/views/Pages/Login/loginUtil'
import {constructTemplateWithUtil, templateConstruct} from './views/runtime/utils/Common/Navigation_Util';
import {Utils,ProductNavigationKeys} from "./views/runtime/utils/UtilsIndex"
import {ssofunction} from './views/runtime/utils/SSO_Util/SSO_Utils';
import { setNavigationInfo } from "./actions/navigationAction";
import  cloneDeep  from "lodash/cloneDeep";
import { connect } from "react-redux";
import { getRequiredIndex } from "./views/runtime/utils/Common/HelperUtils";
import { setRouteName } from "./actions/applicationMetadataAction";
import {DIProvider} from './views/runtime/utils/DIContext'
import './views/runtime/utils/registerContent'
import { decodeString } from "./views/runtime/utils/Common/NoCodeUtils";

const { actionType } = appActions;

// Containers
const DefaultLayoutV2 = React.lazy(() => import("./containers/DefaultLayout/DefaultLayoutV2"));

// Pages
const Login          = React.lazy(() => import("./views/Pages/Login/Login"));
const LoginV2        = React.lazy(() => import("./views/Pages/Login/LoginV2"))
const Register       = React.lazy(() => import("./views/Pages/Login/Register"));
const ForgotPassword = React.lazy(() => import("./views/Pages/Login/ForgotPassword"));
const Page404        = React.lazy(() => import("./views/Pages/Page404/Page404"));
const Page500        = React.lazy(() => import("./views/Pages/Page500/Page500"));
const Page503        = React.lazy(() => import("./views/Pages/Page503/Page503"));
const SessionTimeout = React.lazy(() => import("./views/Pages/SessionTimeout/PageTimeout"));
const SparkPage      = React.lazy(() => import("./views/Pages/Login/SSO/SparkPage"));
const SparkLoginPage = React.lazy(() => import("./views/Pages/Login/SSO/SparkLoginPage"));
const Document       = React.lazy(() => import("./views/Pages/Login/Document"));
const cookies =new Cookies();

class App extends Component {

  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.state={
      loadingResourceError : false,
      loadContent: false
    }
  }

  async componentDidMount(){
    let cloneProps = this.props
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    document.title = this.props.t('Title');
    await ResourceAxios()
    .then(() => {
      this.setState({ loadingResourceError : false });
      let {  PersistRouteData= "find~home~login" } = this.props.clientInfo;
      let routes= PersistRouteData.split("~")
      if(!(routes.some(route=>route.toLowerCase()===localStorage.getItem('lastLocation')) )){
        localStorage.setItem('lastLocation','home')
        this.props.history.push('/home')
     }
    })
    .catch((err) => {
      this.setState({ loadingResourceError : true })
    })
    // .then(() => {
    let data=  await ssofunction(cloneProps)
    // .then ( data => {
    this.setState({...data});
    //   })
    // })
    let SessionID = cookies.get('SessionID') != undefined ? cookies.get('SessionID') : null;
    if (SessionID) {
      let navObj = await getNavigation(["PageNavigation","TemplateInfo"]);
      console.log('navObj--->',navObj)
      if(navObj.hasOwnProperty('templateInfo')){
        //Util Construction in Templates
        Object.keys(navObj.templateInfo).forEach( async (template) => {
          navObj.templateInfo[template] = await constructTemplateWithUtil(navObj.templateInfo[template],ProductNavigationKeys,Utils)
        })
        //SubTemplate construction in Templates
        Object.keys(navObj.templateInfo).forEach( async (template) => {
          navObj.templateInfo[template] = await constructTemplateWithUtil(navObj.templateInfo[template],['details','template'],navObj.templateInfo)
        })
        store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: navObj.templateInfo})
      }
      // let navigationDetails = {}
      // switch(this.props.location.pathname)
      // {
      //   case '/submission' :
      //     {
      //       navigationDetails =  await this.GetProductNavigation(navObj);
      //       break;
      //     }
      // }
      if(navObj.hasOwnProperty('pageNavigation')){
        // templateConstruct(navObj);
        Object.keys(navObj.pageNavigation).forEach(async (route) => {
          navObj.pageNavigation[route].sidebar = await constructTemplateWithUtil(navObj.pageNavigation[route].sidebar, ['details', 'template','utilfunction'], {...navObj.templateInfo,...Utils})
        })

        let pagenav = cloneDeep({"pageNavigation":{...navObj.pageNavigation}});
        store.dispatch(setNavigationInfo(pagenav));
        let val=window.location.hash.split('/'),pageData={};
        if(val.length > 1 && val[2]){
            let newVal=val[2];
            pageData = decodeString(newVal)
        }
        store.dispatch({ type: actionType.SET_UI_VALUES, payload: {pageData:{...pageData}}})
        await store.dispatch({type: actionType.SET_PAGE_NAVIGATION, payload: navObj.pageNavigation.home.sidebar[0]});
        this.props.setRouteName(this.props.location.pathname.slice(1).split('/')[0]);
        store.dispatch({
          type: actionType.SET_NAVIGATION,
          payload: {"pageNavigation":{...navObj.pageNavigation}},
      });
      }
    }
  this.setState({
    loadContent: true
});
  }

  componentWillUnmount(){
     window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentDidUpdate(prevProps) {
    document.title = this.props.t('Title');
    if(prevProps.location.pathname !== this.props.location.pathname){
    this.props.setRouteName(this.props.location.pathname.slice(1).split('/')[0]);
      let val=window.location.hash.split('/'),pageData={};
        if(val.length > 1 && val[2]){
            let newVal=val[2];
            pageData = decodeString(newVal) //await changed
        }
        store.dispatch({ type: actionType.SET_UI_VALUES, payload: {pageData:{...pageData}}})
    }
  }


  signIn = (e) => {
    e.preventDefault();
    this.props.history.push("/");
  };

  GetProductNavigation = async(pageNav)=>{
    try{
      let navObj = await getNavigation(["ProductNavigation"],[store.getState().UIFlowReducer.productDetails.ProductVerNumber]);
       let templatefromredux = store.getState().navigationReducer.templateInfo;
       if (navObj.hasOwnProperty("productNavigation")) {
        templateConstruct(navObj, ["template"], templatefromredux);
        let prodnav = navObj.productNavigation.template;
        let index =getRequiredIndex(pageNav.pageNavigation.submission.sidebar,'name','Submission')
        pageNav.pageNavigation.submission.sidebar[index].children = prodnav;
    return {navigations: {productNavigation:{...navObj.productNavigation}}, pageNavigation: prodnav}
      }
  }
  catch(error){
console.log("Error in GetProductNavigation", error)
  }
    }
  handleBeforeUnload = (event) => {
    let currentRoute = this.props.location.pathname.slice(1).toLowerCase();
    let {  PersistRouteData= "find~home~login" } = this.props.clientInfo;
    let routes= PersistRouteData.split("~")
    if(!(routes.some(route=>route.toLowerCase()===currentRoute))){
      const persistedState = JSON.parse(localStorage.getItem('persist:root'));
      delete persistedState.metadataReducer;
      delete persistedState.workflowReducer;
      delete persistedState.requestresponsereducer;
      localStorage.setItem('persist:root', JSON.stringify(persistedState))
      //store.dispatch(authaction.resetsubmission());
    }
    localStorage.setItem('lastLocation', this.props.location.pathname.slice(1).split('/')[0].toLowerCase());

  };

  handleOnAction = (event) => {
    /* console.log('user did something', event) */
  }

  handleOnActive = (event) => {
    /* console.log('user is active', event)
    console.log('time remaining', this.idleTimer.getRemainingTime()) */
  }

  handleOnIdle = async (event) => {
    if ( this.props.location.pathname != "/login" && this.props.location.pathname != "/sessionTimedout")
    {
      // localStorage.clear();
      // this.props.history.push('/sessionTimedout');
      await logUserDetailRequest(this.props, 'Logout')
      let request = {};
      request.UserName = localStorage.getItem('userName');

      let response = await authAxios.post('/auth/logout', request);
      response = await response.data;
      let reduxState = store.getState().authReducer;

      if(response.Status.toLowerCase() === 'success')
      {
        localStorage.clear();
        this.props.history.push('/sessionTimedout');
        // if (reduxState.fromSSO === true) {
          if(response.hasOwnProperty('ssoLogoutURL') && response.ssoLogoutURL !== '')
          {
            window.location.href = response["ssoLogoutURL"];
          }
        // }
        store.dispatch({
          type: 'RESET_STORE'
        })
      }

    }
  }

  changeLanguageCode = (language) => {
    const { i18n } = this.props;
    store.dispatch({
      type : SET_USER_LANGUAGE,
      userLanguage : language
    })
    i18n.changeLanguage(language);
  }

  render() {
    // console.clear();
    let { ReactTimeout = idleTimeOut } = this.props.clientInfo;
    if(typeof ReactTimeout !== 'number') ReactTimeout = parseInt(ReactTimeout);
    let SessionID = cookies.get('SessionID') != undefined ? cookies.get('SessionID') : null;
    if(this.state.loadingResourceError && this.props.location.pathname !== '/404') return <Redirect to='/404' />
    return (
      <DIProvider>
        <DynamicContent/>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={ReactTimeout}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <Suspense fallback={<PageLoader/>}>
          <Switch>
          	<Route
              exact
              path="/LoginSpark"
              name="SparkLogin"
              render={(props) => <SparkLoginPage {...props} />}
            />
          	<Route
              exact
              path="/Spark"
              name="SparkPage"
              render={(props) => <SparkPage {...props} />}
            />
            <Route
              exact
              path="/Document"
              name="Document"
              render={(props) => <Document {...props} />}
            />
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => SessionID != null || SessionID !=undefined ? this.props.history.push('/home') : (this.props.clientInfo && this.props.clientInfo.isNewLoginRequired === "Yes" ? <LoginV2 signIn={this.signIn} {...props} /> : <Login signIn={this.signIn} {...props} /> )}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forget Password Page"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/503"
              name="Page 503"
              render={(props) => <Page503 {...props} />}
            />
            <Route
              exact
              path="/sessionTimedout"
              name="Page Timeout"
              render={(props) => <SessionTimeout {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) =>this.state.loadContent ? <DefaultLayoutV2 changeLanguageCode = { this.changeLanguageCode } {...props} />: <></>}
            />
          </Switch>
        </Suspense>
      </DIProvider>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    fromSSO: state.authReducer.fromSSO,
    clientInfo: state.ownerResources.clientInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRouteName: (value) => dispatch(setRouteName(value))
  };
};

export default withTranslation() (connect(mapStateToProps,mapDispatchToProps)(App));