import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import CheckboxValidator from './CheckboxValidator';
import { ValidatorForm } from 'react-material-ui-form-validator';


class BooleanCheckbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCheckboxName: this.props.component.SubApplicationAttributeValueList.length === 0 ? this.props.attributeValue : this.props.attributeValue || this.props.attributeValue === '' ? this.props.attributeValue.split(',') : this.props.component.DefaultValue ? this.props.component.DefaultValue.split(',') : [],
        }
    }

    componentDidMount() {
        ValidatorForm.addValidationRule("required", (value) => {
            if (!value) {
                return false;
            }
            return true;
        });
        if (!this.props.customTable) {
            if (this.props.component.DefaultValue !== "") {
              this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
            }
          }
    }

    onChange = (e) => {
        let value = Object.assign({}, { [e.target.name]: e.target.checked });
        // console.log("val", value)
        this.setState({ selectedCheckboxName: e.target.checked })
        if (this.props.customTable) {
            this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex, this.props);
        }
        else {
            this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
        }
    }

    handleMultipleCheckBox = (attributeName, checkBoxValue) => {
        this.setState((prevState) => {
            let selectedCheckboxName = prevState.selectedCheckboxName;
            let index = selectedCheckboxName.findIndex(value => value === checkBoxValue);
            if (index > -1) {
                selectedCheckboxName.splice(index, 1);
            }
            else {
                selectedCheckboxName = [...selectedCheckboxName, checkBoxValue];
            }
            let value = Object.assign({}, { [attributeName]: selectedCheckboxName.toString() });

            // LOB : 356736,2192 => Storing inside values state

            this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
            return { selectedCheckboxName }
        })
    }

    render() {

        const { component,customTableReadOnly=false,customTable } = this.props;
        const { SubApplicationAttributeValueList } = component;

        // component.DefaultValue !== "true" ? this.state.selectedCheckboxName = false : this.state.selectedCheckboxName = true
        this.props.attributeValue && this.props.attributeValue.constructor !== Object ? this.props.attributeValue !== "true" ? this.state.selectedCheckboxName = false : this.state.selectedCheckboxName = true : component.DefaultValue !== "true" ? this.state.selectedCheckboxName = false : this.state.selectedCheckboxName = true

        let isReadOnly,isRequired = component.IsRequired === 'Y' ? true : false;
        const validations = [];
        const errorMessages = [];
        if(customTable)
            isReadOnly = component.IsReadonly?component.IsReadonly:customTableReadOnly;
        else
            isReadOnly = component.IsReadonly;
        if ((isRequired && !this.state.selectedCheckboxName) || (isRequired && Array.isArray(this.state.selectedCheckboxName) && this.state.selectedCheckboxName.length === 0)) {
            validations.push('required');
            errorMessages.push(component.RequiredMessage);
        }

        return (
            <Grid item xs={12} sm={12} md={component.Size > 0 ? component.Size : 12} lg={component.Size > 0 ? component.Size : 12} className={`check_box ${component.AttributeStyleClass !== "" ? component.AttributeStyleClass : ""}`}>
                <FormControl className={this.props.customTable && component.IsReadonly && component.StyleClass === 'hide-checkbox'
                        ? 'checkbox-hide'
                        : component.StyleClass}
>
                    <CheckboxValidator
                        className={component.StyleClass}
                        componentLabel={this.props.componentLabel}
                        SubApplicationAttributeValueList={SubApplicationAttributeValueList}
                        component={component}
                        componentAttributeValue={this.state.selectedCheckboxName}
                        onChange={this.onChange}
                        isReadOnly={isReadOnly}
                        handleMultipleCheckBox={this.handleMultipleCheckBox}
                        errorMessages={errorMessages}
                        validators={validations}
                    />
                </FormControl>
            </Grid>);
    }
}

export default BooleanCheckbox;