import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import { Link } from '@mui/material';
// import * as Controls from '../controls/index';
import { commonLinkMethod } from './ButtonUtil';
import container from '../utils/DIContainer';
// container

export default class Links extends Component {
    render() {
        const { component,componentProps,componentState,state,parentProps,componentvalue,functions,rowData ={}} = this.props;
        let ActionNeeded = component.Action ? Object.keys(component.Action).length :false;
        const stateObj = componentState || parentProps;
        const propsObj = componentProps || state;
        let obj={};
        let value = componentvalue ? componentvalue : component.DefaultValue;
        if(value && !component.OnClickAction){
            obj.href = value
        }
        // let temp = {propsObj,stateObj,component,functions}
        const Controls = container.get('componentControls')
        let IconChild = component.AttributeParams && component.AttributeDataType === 'Icon' && Controls[component.AttributeParams.toLowerCase()];
        let Label = IconChild ? <IconChild></IconChild> : component.ComponentLabel;
        return (
            <React.Fragment>
             <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} className={`${component.IsReadonly && 'disabled_link'} ${component.AttributeStyleClass}`}>
                <Link name={component.AttributeName}
                        className={component.IsReadonly===true ? `${component.StyleClass} disabled_link` : `${component.StyleClass}` } // Privilage Read Only Changes for Link
                        {...obj}
                        disabled={component.IsReadonly}
                        onClick={component.IsReadonly ? undefined
                            : ActionNeeded ? (e) => commonLinkMethod(component, rowData, this.props)
                            : (e) => this.props[component.OnClickAction] && this.props[component.OnClickAction](propsObj, stateObj, component, e, this.props)}
                         >
                        <span className="ui-button-text ui-c displayLabel">{Label}</span>
                        <span className="ui-button-text ui-c displayAttribute">{value}</span>
                    </Link>
            </Grid>
            {this.props.children}
            </React.Fragment>
        )
    }
}
