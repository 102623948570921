import { Grid } from '@mui/material';
import React from 'react'
import OutputLabel from './OutputLabel';
import TooltipPopover from './Popover';

const TextPopover = (props) => {

    let {component} = props;

  return (
    <>
    <Grid  item xs={12} sm={12} md={component.Size || 12} lg={component.Size || 12} key={component.AttributeName} className={`TextPopover_${component.AttributeStyleClass} `}>
      <Grid container spacing={0}>
    <OutputLabel {...props} defaultSize="auto"></OutputLabel>
    <TooltipPopover {...props} defaultSize={1}></TooltipPopover>
    </Grid>
    </Grid>
    </>
  )
}

export default TextPopover
