export const constructRequestValues = async (props, pagelevelValues, request, state, includeID = false, metadataResponseFromReducer) => {
  let subappList = [];
  let subAppEntitymaping = {};
  let detailSubAppMaping = {};
  let obj = {};
  let entityHierarchyName = "";
  let subApplicationName = "";
  const metadataRes = metadataResponseFromReducer ? JSON.parse(props.metadataReducer.metadataResponse) : state.pageMetadata
  const subAppDetailList = metadataRes.ApplicationDetail ? metadataRes.ApplicationDetail.SubApplicationDetailList : state.pageMetadata;
  subappIteration(subAppDetailList)
  function summaryListIteration(Summarylist) {
    let newSummaryList = Summarylist && JSON.parse(JSON.stringify(Summarylist))
    Summarylist &&
    Object.entries(Summarylist).map(([dotKey, dotValue], index) => {

      newSummaryList[index] = dotToObjectConversion(dotValue)
      return newSummaryList
    })
    newSummaryList = newSummaryList && newSummaryList.filter(obj => Object.keys(obj).length !== 0)
    return newSummaryList
  }
    /**
     * Iterates the Subapplication List
     * @param {subAppDetailList} subApplicationDetailList
     */

  function subappIteration(subAppDetailList) {
    for (let a = 0; a < subAppDetailList.length; a++) {
      const attrList = subAppDetailList[a].AttributeDetailList;
      const templateType = subAppDetailList[a].Type;
      const ChildSubApplicationList = subAppDetailList[a].ChildSubApplicationList || [];
      entityHierarchyName = subAppDetailList[a].EntityHierarchyName === "" ? subAppDetailList[a].ApplicationName : subAppDetailList[a].EntityHierarchyName;
      subAppEntitymaping[subAppDetailList[a].ApplicationName] = entityHierarchyName;
      if (subAppDetailList[a].Type.toLowerCase() === "summary") {
        subApplicationName = subAppDetailList[a].ApplicationName;
        let datalist = subApplicationName.toLowerCase() + "_dataList"
        if (state && state.hasOwnProperty(datalist)) {
          request[entityHierarchyName] = summaryListIteration(state[datalist])
        }
        else if(pagelevelValues && pagelevelValues.hasOwnProperty(datalist)) {
          request[entityHierarchyName] = summaryListIteration(pagelevelValues[datalist])
        }
      }else{
        if (attrList.length > 0) {
          detailSubAppMaping[attrList[0].SubApplicationName] = templateType;
          attributeIteration(attrList)
        }
        if (ChildSubApplicationList.length > 0) {
          subappIteration(ChildSubApplicationList)
        }
      }
    }
  }
/**
 *
 * @param {SubapplicationAttributeList} attributList
 */
  function attributeIteration(attributList) {

    for (let i = 0; i < attributList.length; i++) {
      entityHierarchyName = attributList[i].EntityHierarchyName === "" ? attributList[i].SubApplicationName : attributList[i].EntityHierarchyName;
      subAppEntitymaping[attributList[i].SubApplicationName] = entityHierarchyName;
      let ChildSubApplication = attributList[i].SubApplicationDetailList || []
      if(attributList[i].ComponentType.toLowerCase().includes('mapcomponent')){
        obj[entityHierarchyName] = {...pagelevelValues[attributList[i].SubApplicationName]}
      }
      if (!subappList.includes(attributList[i].SubApplicationName)) {
        subappList.push(attributList[i].SubApplicationName);
      }

      if (entityHierarchyName && entityHierarchyName !== "") {
        if (attributList[i].ComponentType.toLowerCase() !== "button" && attributList[i].ComponentType.toLowerCase() !== "submit") {
          if (!obj[entityHierarchyName]) {
            obj[entityHierarchyName] = {};
          }

          if (attributList[i].RenderValue === true && attributList[i].ComponentType.toLowerCase() !== "subheading" && attributList[i].ComponentType.toLowerCase() !== "sectionheading" && attributList[i].ComponentType.toLowerCase() !== "outputlabel") {
            if(pagelevelValues[attributList[i].SubApplicationName] && attributList[i].AttributeName in pagelevelValues[attributList[i].SubApplicationName] && typeof pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName]==='string'){
              pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName] = pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName].trim();
            }
            if (attributList[i].AttributeName.includes(".")) {
              let splitName = attributList[i].AttributeName.split(".");
              if (obj[entityHierarchyName][splitName[0]] === undefined) {
                obj[entityHierarchyName][splitName[0]] = {};
              }
              obj[entityHierarchyName][splitName[0]][splitName[1]] =(pagelevelValues[attributList[i].SubApplicationName] && pagelevelValues[attributList[i].SubApplicationName].hasOwnProperty(attributList[i].AttributeName)) ? pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName] :  attributList[i].DefaultValue;
              if (includeID && pagelevelValues[attributList[i].SubApplicationName]) {
                pagelevelValues[attributList[i].SubApplicationName][`${splitName[0]}.ID`] && (obj[entityHierarchyName][splitName[0]]["ID"] = pagelevelValues[attributList[i].SubApplicationName][`${splitName[0]}.ID`])
                pagelevelValues[attributList[i].SubApplicationName][`${splitName[0]}.PARENTID`] && (obj[entityHierarchyName][splitName[0]]["PARENTID"] = pagelevelValues[attributList[i].SubApplicationName][`${splitName[0]}.PARENTID`])
              }

            }
            else {
              obj[entityHierarchyName][attributList[i].AttributeName] = (pagelevelValues[attributList[i].SubApplicationName] && pagelevelValues[attributList[i].SubApplicationName].hasOwnProperty(attributList[i].AttributeName)) ? pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName] :  attributList[i].DefaultValue;
              if (includeID && pagelevelValues[attributList[i].SubApplicationName]) {
                pagelevelValues[attributList[i].SubApplicationName]["ID"] && (obj[entityHierarchyName]["ID"] = pagelevelValues[attributList[i].SubApplicationName]["ID"])
                pagelevelValues[attributList[i].SubApplicationName]["PARENTID"] && (obj[entityHierarchyName]["PARENTID"] = pagelevelValues[attributList[i].SubApplicationName]["PARENTID"])
              }

            }
          }
        }
      }
      if (ChildSubApplication.length > 0) {
        subappIteration(ChildSubApplication);
      }
    }
  }
/**
 *
 * @param {An object with dot attributes} keyData
 */
  function dotToObjectConversion(keyData) {
    let detailValue = {};
    for (const [dotkey, dotvalue] of Object.entries(keyData)) {
      if (dotkey.includes('.')) {
        let key = dotkey.split(".");
        if (detailValue.hasOwnProperty(key[0])) {
          detailValue[key[0]][key[1]] = dotvalue
        } else {
          detailValue[key[0]] = Object.assign({}, { [key[1]]: dotvalue })
        }
      }
      else {
        detailValue[dotkey] = dotvalue;
        if (dotvalue!== undefined && dotvalue.length > 0 && dotkey==='rowMetaData'){
          dotvalue.forEach(attribute=>{
            if(attribute.DefaultValue!==''){
              detailValue[attribute.AttributeName]=attribute.DefaultValue
            }
           })
        }
      }

    }
    delete detailValue['uid']
    delete detailValue['isExpanded']
    if (!includeID) {
      removeIdInObject(detailValue)
    }
    return detailValue;
  }
/**
 *
 * @param {removes ID and PARENTID} obj
 */
  function removeIdInObject(obj) {
    for (const [key] of Object.entries(obj)) {
      delete obj[key]['ID'];
      delete obj[key]['PARENTID'];
      if (typeof obj[key] === 'object') {
        removeIdInObject(obj[key]);
      }
    }
  }
  if (Object.keys(obj).length !== 0) {
    request = { ...request, ...obj };
  }
  else {
    request = { ...request, ...pagelevelValues };
  }

  return request
}

// need to test this for rootlevelrequest construction
export const constructRequestValuesV2 = async (props, pagelevelValues, request, state, includeID = false, metadataResponseFromReducer,rootlevelNeeded=false) => {
  let subappList = [];
  let subAppEntitymaping = {};
  let detailSubAppMaping = {};
  let obj = {};
  let entityHierarchyName = "";
  let subApplicationName = "";
  const metadataRes = metadataResponseFromReducer ? JSON.parse(props.metadataReducer.metadataResponse) : state.pageMetadata
  const subAppDetailList = metadataRes.ApplicationDetail ? metadataRes.ApplicationDetail.SubApplicationDetailList : state.pageMetadata;
  subappIteration(subAppDetailList)
  function summaryListIteration(Summarylist) {
    let newSummaryList = Summarylist && JSON.parse(JSON.stringify(Summarylist))
    Summarylist &&
    Object.entries(Summarylist).map(([dotKey, dotValue], index) => {

      newSummaryList[index] = dotToObjectConversion(dotValue)
      return newSummaryList
    })
    newSummaryList = newSummaryList && newSummaryList.filter(obj => Object.keys(obj).length !== 0)
    return newSummaryList
  }
    /**
     * Iterates the Subapplication List
     * @param {subAppDetailList} subApplicationDetailList
     */

  function subappIteration(subAppDetailList) {
    for (let a = 0; a < subAppDetailList.length; a++) {
      const attrList = subAppDetailList[a].AttributeDetailList;
      const templateType = subAppDetailList[a].Type;
      const ChildSubApplicationList = subAppDetailList[a].ChildSubApplicationList || [];
      entityHierarchyName = subAppDetailList[a].EntityHierarchyName === "" ? subAppDetailList[a].ApplicationName : subAppDetailList[a].EntityHierarchyName;
      subAppEntitymaping[subAppDetailList[a].ApplicationName] = entityHierarchyName;
      if (subAppDetailList[a].Type.toLowerCase() === "summary" ) {
        if(!rootlevelNeeded){
          subApplicationName = subAppDetailList[a].ApplicationName;
          let datalist = subApplicationName.toLowerCase() + "_dataList"
          if (state && state.hasOwnProperty(datalist)) {
            request[entityHierarchyName] = summaryListIteration(state[datalist])
          }
          else if(pagelevelValues && pagelevelValues.hasOwnProperty(datalist)) {
            request[entityHierarchyName] = summaryListIteration(pagelevelValues[datalist])
          }
        }
        
      }else{
        if (attrList.length > 0) {
          detailSubAppMaping[attrList[0].SubApplicationName] = templateType;
          attributeIteration(attrList)
        }
        if (ChildSubApplicationList.length > 0) {
          subappIteration(ChildSubApplicationList)
        }
      }
    }
  }
/**
 *
 * @param {SubapplicationAttributeList} attributList
 */
  function attributeIteration(attributList) {

    for (let i = 0; i < attributList.length; i++) {
      entityHierarchyName = attributList[i].EntityHierarchyName === "" ? attributList[i].SubApplicationName : attributList[i].EntityHierarchyName;
      subAppEntitymaping[attributList[i].SubApplicationName] = entityHierarchyName;
      let ChildSubApplication = attributList[i].SubApplicationDetailList || []
      if (!subappList.includes(attributList[i].SubApplicationName)) {
        subappList.push(attributList[i].SubApplicationName);
      }

      if (entityHierarchyName && entityHierarchyName !== "") {
        if (attributList[i].ComponentType.toLowerCase() !== "button" && attributList[i].ComponentType.toLowerCase() !== "submit") {
          if (!obj[entityHierarchyName]) {
            obj[entityHierarchyName] = {};
          }

          if (attributList[i].RenderValue === true && attributList[i].ComponentType.toLowerCase() !== "subheading" && attributList[i].ComponentType.toLowerCase() !== "sectionheading") {
            if(pagelevelValues[attributList[i].SubApplicationName] && attributList[i].AttributeName in pagelevelValues[attributList[i].SubApplicationName] && typeof pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName]==='string'){
              pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName] = pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName].trim();
            }
            if (attributList[i].AttributeName.includes(".")) {
              let splitName = attributList[i].AttributeName.split(".");
              if (obj[entityHierarchyName][splitName[0]] === undefined) {
                obj[entityHierarchyName][splitName[0]] = {};
              }
              obj[entityHierarchyName][splitName[0]][splitName[1]] =(pagelevelValues[attributList[i].SubApplicationName] && pagelevelValues[attributList[i].SubApplicationName].hasOwnProperty(attributList[i].AttributeName)) ? pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName] :  attributList[i].DefaultValue;
              if (includeID && pagelevelValues[attributList[i].SubApplicationName]) {
                pagelevelValues[attributList[i].SubApplicationName][`${splitName[0]}.ID`] && (obj[entityHierarchyName][splitName[0]]["ID"] = pagelevelValues[attributList[i].SubApplicationName][`${splitName[0]}.ID`])
                pagelevelValues[attributList[i].SubApplicationName][`${splitName[0]}.PARENTID`] && (obj[entityHierarchyName][splitName[0]]["PARENTID"] = pagelevelValues[attributList[i].SubApplicationName][`${splitName[0]}.PARENTID`])
              }

            }
            else {
              let attributeValue = (pagelevelValues[attributList[i].SubApplicationName] && pagelevelValues[attributList[i].SubApplicationName].hasOwnProperty(attributList[i].AttributeName)) ? pagelevelValues[attributList[i].SubApplicationName][attributList[i].AttributeName] :  attributList[i].DefaultValue;
              rootlevelNeeded ? obj[attributList[i].AttributeName] =attributeValue:
              obj[entityHierarchyName][attributList[i].AttributeName] = attributeValue;
              if (includeID && pagelevelValues[attributList[i].SubApplicationName]) {
                pagelevelValues[attributList[i].SubApplicationName]["ID"] && (obj[entityHierarchyName]["ID"] = pagelevelValues[attributList[i].SubApplicationName]["ID"])
                pagelevelValues[attributList[i].SubApplicationName]["PARENTID"] && (obj[entityHierarchyName]["PARENTID"] = pagelevelValues[attributList[i].SubApplicationName]["PARENTID"])
              }

            }
          }
        }
      }
      if (ChildSubApplication.length > 0) {
        subappIteration(ChildSubApplication);
      }
    }
  }
/**
 *
 * @param {An object with dot attributes} keyData
 */
  function dotToObjectConversion(keyData) {
    let detailValue = {};
    for (const [dotkey, dotvalue] of Object.entries(keyData)) {
      if (dotkey.includes('.')) {
        let key = dotkey.split(".");
        if (detailValue.hasOwnProperty(key[0])) {
          detailValue[key[0]][key[1]] = dotvalue
        } else {
          detailValue[key[0]] = Object.assign({}, { [key[1]]: dotvalue })
        }
      }
      else {
        detailValue[dotkey] = dotvalue;
        if (dotvalue!== undefined && dotvalue.length > 0 && dotkey==='rowMetaData'){
          dotvalue.forEach(attribute=>{
            if(attribute.DefaultValue!==''){
              detailValue[attribute.AttributeName]=attribute.DefaultValue
            }
           })
        }
      }

    }
    delete detailValue['uid']
    delete detailValue['isExpanded']
    if (!includeID) {
      removeIdInObject(detailValue)
    }
    return detailValue;
  }
/**
 *
 * @param {removes ID and PARENTID} obj
 */
  function removeIdInObject(obj) {
    for (const [key] of Object.entries(obj)) {
      delete obj[key]['ID'];
      delete obj[key]['PARENTID'];
      if (typeof obj[key] === 'object') {
        removeIdInObject(obj[key]);
      }
    }
  }
  if (Object.keys(obj).length !== 0) {
    request = { ...request, ...obj };
  }
  else {
    request = { ...request, ...pagelevelValues };
  }

  return request
}