import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { stateAbbreviations } from "../utils/Common/config";
import { removeDiacritics } from "../utils/Common/CommonUtils";

Geocode.enableDebug();

class GoogleMapComponentV5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      street_number: "",
      street_name: "",
      street_detail: "",
      city: "",
      state: "",
      country: "",
      county: "",
      zipcode: "",
      SOLFullAddress: "",
      SOLAddress1: "",
      SOLAddress2: "",
      SOLCity: "",
      SOLState: "",
      SOLCountry: "",
      SOLZipcode: "",
      SOLZipcode_Suffix: "",
      SOLCounty: "",
      SOLLat: "",
      SOLLng: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      mapType:Object.keys(this.props.component.Action).length >0 && this.props.component.Action.mapType ? this.props.component.Action.mapType:"roadmap"
    };
  }
  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() { 
    const { clientInfo } = this.props.ownerResources;
    Geocode.setApiKey(clientInfo.GoogleMapsAPIKey);
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isValidCountry", (value, country) => {
      if (value === country) {
        return true;
      }
      return false;
    });
    if(this.props.pagelevelValues && this.props.pagelevelValues[this.props.component.SubApplicationName] && this.props.pagelevelValues[this.props.component.SubApplicationName][
      "StreetNameAddress2"]){
        this.state['SOLAddress2'] = this.props.pagelevelValues[this.props.component.SubApplicationName]["StreetNameAddress2"]
      }
    if(this.props.pagelevelValues && this.props.pagelevelValues[this.props.component.SubApplicationName] && this.props.pagelevelValues[this.props.component.SubApplicationName][
      "Latitude"] && this.props.pagelevelValues[this.props.component.SubApplicationName]["Longitude"] ){
        this.state.markerPosition['lat'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Latitude"])
        this.state.markerPosition['lng'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Longitude"])
        this.state.mapPosition['lat'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Latitude"])
        this.state.mapPosition['lng'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Longitude"])
      }
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.ownerResources.userLanguage !==
      nextProps.ownerResources.userLanguage
    )
      return true;
    if (
      this.state.markerPosition.lat !== nextState.markerPosition.lat ||
      this.state.address !== nextState.address ||
      this.state.SOLFullAddress !== nextState.SOLFullAddress ||
      this.state.SOLCity !== nextState.SOLCity ||
      this.state.SOLAddress1 !== nextState.SOLAddress1 ||
      this.state.SOLAddress2 !== nextState.SOLAddress2 ||
      this.state.street_detail !== nextState.street_detail ||
      this.state.SOLState !== nextState.SOLState ||
      this.state.SOLCountry !== nextState.SOLCountry ||
      this.state.county !== nextState.county ||
      this.state.SOLZipcode !== nextState.SOLZipcode ||
      this.state.SOLZipcode_Suffix !== nextState.SOLZipcode_Suffix ||
      this.state.SOLCounty !== nextState.SOLCounty
    ) {
      return true;
    }
    if (
      this.props.pagelevelValues[this.props.component.SubApplicationName] ===
        undefined &&
      nextProps.pagelevelValues[nextProps.component.SubApplicationName] !=
        undefined
    ) {
      return true;
    }
    if (
      this.props.pagelevelValues[this.props.component.SubApplicationName] !=
        undefined &&
      nextProps.pagelevelValues[nextProps.component.SubApplicationName] ===
        undefined
    ) {
      return true;
    } else if (
      this.props.pagelevelValues[this.props.component.SubApplicationName] !=
        undefined &&
      typeof this.props.pagelevelValues[
        this.props.component.SubApplicationName
      ] === "object"
    ) {
      if (
        this.props.pagelevelValues[this.props.component.SubApplicationName][
          "FullAddress"
        ] !==
          nextProps.pagelevelValues[nextProps.component.SubApplicationName][
            "FullAddress"
          ] ||
        this.props.pagelevelValues[this.props.component.SubApplicationName][
          "SOLFullAddress"
        ] !==
          nextProps.pagelevelValues[nextProps.component.SubApplicationName][
            "SOLFullAddress"
          ] ||
        this.props.pagelevelValues[this.props.component.SubApplicationName][
          this.props.component.AttributeName
        ] !==
          nextProps.pagelevelValues[nextProps.component.SubApplicationName][
            this.props.component.AttributeName
          ] ||
        this.props.component.IsReadonly != nextProps.component.IsReadonly
      ) {
        return true;
      }
      else if(this.props.component.ComponentLabel !== nextProps.component.ComponentLabel){
          return true
      } else {
        return false;
      }
    }
  }

  componentDidUpdate () { 
    const { clientInfo } = this.props.ownerResources;
    Geocode.setApiKey(clientInfo.GoogleMapsAPIKey);
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isValidCountry", (value, country) => {
      if (value === country) {
        return true;
      }
      return false;
    });
    if(this.props.pagelevelValues && this.props.pagelevelValues[this.props.component.SubApplicationName] && this.props.pagelevelValues[this.props.component.SubApplicationName][
      "StreetNameAddress2"]){
        this.state['SOLAddress2'] = this.props.pagelevelValues[this.props.component.SubApplicationName]["StreetNameAddress2"]
      }
    if(this.props.pagelevelValues && this.props.pagelevelValues[this.props.component.SubApplicationName] && this.props.pagelevelValues[this.props.component.SubApplicationName][
      "Latitude"] && this.props.pagelevelValues[this.props.component.SubApplicationName]["Longitude"] ){
        this.state.markerPosition['lat'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Latitude"])
        this.state.markerPosition['lng'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Longitude"])
        this.state.mapPosition['lat'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Latitude"])
        this.state.mapPosition['lng'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Longitude"])
      }
  }
  getAddressFromLatLong = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          SOLFullAddress = response.results[0].formatted_address,
          SOLAddress1 = this.getStreetNumber(addressArray),
          SOLAddress2 = this.getStreetName(addressArray),
          street_detail = SOLAddress1.concat(" ", SOLAddress2),
          SOLCity = this.getCity(addressArray),
          SOLState = this.getState(addressArray),
          county = this.getCounty(addressArray),
          SOLCountry = this.getCountry(addressArray),
          SOLZipcode = this.getZipcode(addressArray),
          SOLZipcode_Suffix = this.getZipcode_suffix(addressArray),
          SOLCounty = this.getCounty(addressArray);

        this.setState({
          address: address ? address : "",
          SOLFullAddress: SOLFullAddress ? SOLFullAddress : "",
          SOLAddress1: SOLAddress1 ? SOLAddress1 : "",
          SOLAddress2: SOLAddress2 ? SOLAddress2 : "",
          street_detail: street_detail ? street_detail : "",
          SOLCity: SOLCity ? SOLCity : "",
          SOLState: SOLState ? SOLState : "",
          county: county ? county : "",
          SOLCountry: SOLCountry ? SOLCountry : "",
          SOLZipcode: SOLZipcode ? SOLZipcode : "",
          SOLZipcode_Suffix: SOLZipcode_Suffix ? SOLZipcode_Suffix : "",
          SOLCounty: SOLCounty ? SOLCounty : "",
          SOLLat: lat ? lat : "",
          SOLLng: lng ? lng : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * Get the street_number and set the street_number input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getStreetNumber = (addressArray) => {
    let SOLAddress1 = "";
    for (let i = 0; i < addressArray.length; i++) {
      if ("street_number" === addressArray[i].types[0]) {
        SOLAddress1 = addressArray[i].long_name;
      }
      if ("route" === addressArray[i].types[0] || "premise" === addressArray[i].types[0] || "intersection" ===addressArray[i].types[0]) {
        SOLAddress1 = SOLAddress1 + " " + addressArray[i].long_name;
      }
    }
    return SOLAddress1;
  };

  /**
   * Get the SOLCity and set the SOLCity input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let SOLCity = "";
    let locality;
    let sublocality_level_1;
    let neighborhood;
    let postal_town;
    for (let i = 0; i < addressArray.length; i++) {
      if ("locality" === addressArray[i].types[0]) {
        locality = addressArray[i].long_name;
      } else if ("sublocality_level_1" === addressArray[i].types[0]) {
        sublocality_level_1 = addressArray[i].long_name;
      }
      else if ("neighborhood" === addressArray[i].types[0]) {
        neighborhood = addressArray[i].long_name;
      }
      else if ("postal_town" === addressArray[i].types[0]) {
        postal_town = addressArray[i].long_name;
      }
    }// Picking City as per Preference / Priority
    return SOLCity = locality ? locality : sublocality_level_1  ? sublocality_level_1 : neighborhood  ? neighborhood : postal_town ? postal_town : "";
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getStreetName = (addressArray) => {
    let SOLAddress2 = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ("Address_line_2" === addressArray[i].types[j]) {
            SOLAddress2 = addressArray[i].long_name;
          }
        }
      }
    }
    return SOLAddress2;
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let SOLState = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          SOLState = addressArray[i].short_name;
          if (stateAbbreviations[SOLState]) {
            SOLState = stateAbbreviations[SOLState]
          }
          return SOLState;
        }
      }
    }
  };

  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getZipcode = (addressArray) => {
    let SOLZipcode = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          SOLZipcode = addressArray[i].long_name;
          return SOLZipcode;
        }
      }
    }
  };

  getZipcode_suffix = (addressArray) => {
    let SOLZipcode_Suffix = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code_suffix" === addressArray[i].types[0]
        ) {
          SOLZipcode_Suffix = addressArray[i].long_name;
          return SOLZipcode_Suffix;
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let SOLCountry = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          SOLCountry = addressArray[i].short_name ;
          if(this.props.component.Action && this.props.component.Action.CountryFullCode ){
            SOLCountry = this.props.component.Action.CountryFullCode.includes(addressArray[i].short_name) ? this.props.component.Action.CountryFullCode : addressArray[i].short_name
          }
          return SOLCountry;
        }
      }
    }
  };

  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCounty = (addressArray) => {
    let county = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_2" === addressArray[i].types[0]
        ) {
          county = addressArray[i].long_name;
        }
        else if (addressArray[i].types[0] && "locality" === addressArray[i].types[0])
        {
          county = addressArray[i].long_name;
        }
      }
    }
    return county
  };
  /**
   * And function for SOLCity,state SOLZipcode address input
   * @param event
   */

  onChange = async (event) => {
    let mappedKey = ''
    let addressheaders = this.props.component.ItemTipParams.split(",");
    addressheaders.map((attribute)=> {
     let [key1, key2] = attribute.split("~")
      if(key2===event.target.name) {
        mappedKey = key1
      }
    })

  mappedKey = mappedKey || event.target.name
    this.setState({ [event.target.name]: event.target.value});
    this.props.functions.UpdateState("isValueChanged",true,false)
    await this.props.handleInputChangeV2({ [mappedKey]: event.target.value }, this.props.component,undefined,undefined,this.props);
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */

  /**
   * When the marker is dragged you get the lat and long using the functions avafilable from event object.
   * Use geocode to get the address, SOLCity, SOLZipcode and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let props = this.props;
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    this.props.functions.UpdateState("isValueChanged",false,false)
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          SOLFullAddress = response.results[0].formatted_address,
          SOLAddress1 = this.getStreetNumber(addressArray),
          SOLAddress2 = this.getStreetName(addressArray),
          street_detail = SOLAddress1.concat(" ", SOLAddress2),
          SOLCity = this.getCity(addressArray),
          SOLState = this.getState(addressArray),
          county = this.getCounty(addressArray),
          SOLCountry = this.getCountry(addressArray),
          SOLZipcode = this.getZipcode(addressArray),
          SOLZipcode_Suffix = this.getZipcode_suffix(addressArray),
          SOLCounty = this.getCounty(addressArray);

          const Addressobj ={
            address: address ? address : "",
            SOLFullAddress: SOLFullAddress ? SOLFullAddress : "",
            SOLAddress1: SOLAddress1 ? SOLAddress1 : "",
            SOLAddress2: SOLAddress2 ? SOLAddress2 : "",
            street_detail: street_detail ? street_detail : "",
            SOLCity: SOLCity ? SOLCity : "",
            SOLState: SOLState ? SOLState : "",
            county: county ? county : "",
            SOLCountry: SOLCountry ? SOLCountry : "",
            SOLZipcode: SOLZipcode ? SOLZipcode : "",
            SOLZipcode_Suffix: SOLZipcode_Suffix ? SOLZipcode_Suffix : "",
            SOLCounty: SOLCounty ? SOLCounty : "",
            SOLLat: newLat ? newLat : "",
            SOLLng: newLng ? newLng : "",
            markerPosition: {
              lat: newLat,
              lng: newLng,
            },
            mapPosition: {
              lat: newLat,
              lng: newLng,
            },
          }
        console.log("Google Address Drag", Addressobj)
        const newAddressObj =  removeDiacritics(Addressobj,this.props.component, this.props)
        this.setState(
          newAddressObj,
          () => {
            let addressvalue = {};
            if (props.component.ItemTipParams !== "") {
              let addressheaders = props.component.ItemTipParams.split(",");
              addressheaders.map((addressheader) => {
                let address = addressheader.split("~");
                addressvalue[address[0]] = newAddressObj[address[1]];
              });
              addressvalue["Lat"] = this.state.SOLLat.toString();
              addressvalue["Lng"] = this.state.SOLLng.toString();
              addressvalue["Latitude"] = this.state.SOLLat.toString();
              addressvalue["Longitude"] = this.state.SOLLng.toString();
            }
            this.props.handleInputChangeV2(addressvalue, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);

       
          }
        );
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    let props = this.props;
    this.props.functions.UpdateState("isValueChanged",false,false)
    const address = place.formatted_address,
      addressArray = place.address_components,
      SOLFullAddress = place.formatted_address,
      SOLAddress1 = this.getStreetNumber(addressArray),
      SOLAddress2 = this.getStreetName(addressArray),
      street_detail = SOLAddress1.concat(" ", SOLAddress2),
      SOLCity = this.getCity(addressArray),
      SOLState = this.getState(addressArray),
      county = this.getCounty(addressArray),
      SOLCountry = this.getCountry(addressArray),
      SOLZipcode = this.getZipcode(addressArray),
      SOLZipcode_Suffix = this.getZipcode_suffix(addressArray),
      SOLCounty = this.getCounty(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
      const prevLat = this.state.mapPosition.lat;
      const prevLng = this.state.mapPosition.lng;
      if(latValue && prevLat && lngValue && prevLng &&  latValue === prevLat && lngValue === prevLng){
          return;
      }
    // Set these values in the state.
    let Addressobj = {
      address: address ? address : "",
      SOLFullAddress: SOLFullAddress ? SOLFullAddress : "",
      SOLAddress1: SOLAddress1 ? SOLAddress1 : "",
      SOLAddress2: SOLAddress2 ? SOLAddress2 : "",
      street_detail: street_detail ? street_detail : "",
      SOLCity: SOLCity ? SOLCity : "",
      SOLState: SOLState ? SOLState : "",
      county: county ? county : "",
      SOLCountry: SOLCountry ? SOLCountry : "",
      SOLZipcode: SOLZipcode ? SOLZipcode : "",
      SOLZipcode_Suffix: SOLZipcode_Suffix ? SOLZipcode_Suffix : "",
      SOLCounty: SOLCounty ? SOLCounty : "",
      SOLLat: latValue ? latValue : "",
      SOLLng: lngValue ? lngValue : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    }
    console.log("Google Address", Addressobj)
    const newAddressObj =  removeDiacritics(Addressobj,this.props.component, this.props)
    this.setState(
      newAddressObj,
      () => {
        let addressvalue = {};
        if (props.component.ItemTipParams !== "") {
          let addressheaders = props.component.ItemTipParams.split(",");
          addressheaders.map((addressheader) => {
            let address = addressheader.split("~");
            addressvalue[address[0]] = newAddressObj[address[1]];
          });
          addressvalue["Lat"] = this.state.SOLLat.toString();
          addressvalue["Lng"] = this.state.SOLLng.toString();
          addressvalue["Latitude"] = this.state.SOLLat.toString();
          addressvalue["Longitude"] = this.state.SOLLng.toString();
        }
    this.props.handleInputChangeV2(addressvalue, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
      }
    );
  };

  OnkeyDown=(e)=>{
    if(e.code==="Enter")
    e.preventDefault();
  }
  handleMapTypeIdChange = ()=>{
    // Toggle between roadmap and hybrid map types
    const newMapType = this.state.mapType === "roadmap" ? "hybrid" : "roadmap";
    this.setState({ mapType: newMapType });
  }

  render() {
    const { t, component,google } = this.props;
    let isRequired = this.props.component.IsRequired === "Y" ? true : false;
    const ln_placeHolderText = t(component.PlaceHolderText);
    const PlaceHolderText = ln_placeHolderText
      ? `${isRequired ? `${ln_placeHolderText} *` : ln_placeHolderText}`
      : "";
    let isReadOnly = component.IsReadonly;
    let {IsMapNeeded,CountriesToBeEnabled } = component.Action;
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
          <GoogleMap
            google={this.props.google}
            defaultZoom={this.props.zoom}
            defaultCenter={{
              lat: this.state.mapPosition.lat,
              lng: this.state.mapPosition.lng,
            }}
            mapTypeId= {this.state.mapType}
            onMapTypeIdChanged={this.handleMapTypeIdChange}
            disabled={isReadOnly}
          >
            {/* InfoWindow on top of marker */}
            {this.state.markerPosition.lat !== "" &&
              this.state.markerPosition.lng !== "" && (
                <React.Fragment>
                  <Marker
                    google={this.props.google}
                    name={"Dolores park"}
                    draggable={isReadOnly === true ? false : true}
                    onDragEnd={this.onMarkerDragEnd}
                    position={{
                      lat: this.state.mapPosition.lat,
                      lng: this.state.mapPosition.lng,
                    }}
                  />
                  <Marker />
                </React.Fragment>
              )}
            {/* For Auto complete Search Box */}
            <Autocomplete
              placeholder={PlaceHolderText}
              onKeyDown={this.OnkeyDown}
              onPlaceSelected={this.onPlaceSelected}
              disabled={isReadOnly}
              types={[]}
              componentRestrictions={{ country:CountriesToBeEnabled || 'us' }}
              autoComplete={autocomplete}
              className="small_label google_autoComplete"
            />
          </GoogleMap>
        ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
       <Grid item xs={12} sm={12} md={12} lg={12} className={`${component.StyleClass} ${IsMapNeeded!==false ? 'ac_space' : ''}`}>
           {IsMapNeeded ===false ? <Autocomplete
                      apiKey={this.props.ownerResources.clientInfo.GoogleMapsAPIKey}
                      style={{
                      width: "100%",
                      height: "40px",
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      }}
                      placeholder={PlaceHolderText}
                      defaultValue={this.props.pagelevelValues[component.SubApplicationName] && this.props.pagelevelValues[component.SubApplicationName][component.AttributeName]? this.props.pagelevelValues[component.SubApplicationName][component.AttributeName] : ""}
                      onPlaceSelected={this.onPlaceSelected}
                      disabled={isReadOnly}
                      onKeyDown={this.OnkeyDown}
                      types={[]}
                      componentRestrictions={{ country:CountriesToBeEnabled || 'us' }}
                      autoComplete={autocomplete}
                /> :  <div>
            <AsyncMap
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${this.props.ownerResources.clientInfo.GoogleMapsAPIKey}&libraries=places`}
              loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
              containerElement={<div className="googlemap_container" />}
              mapElement={
                <div
                  className='googlemap'
                />
              }
            />
          </div>
              }
          <div className="googlemap_bottom_section">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} className="hide">
                <TextValidator
                  name="SOLFullAddress"
                  placeholder='Full Address'
                  label="Full Address"
                  value={this.state.SOLFullAddress}
                  className={`${component.StyleClass} lineHeight`}
                  onChange={this.onChange}
                  disabled={isReadOnly}
                  autoComplete={autocomplete}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}

const mapStateToProps = (state) => ({
  ownerResources: state.ownerResources,
});

export default withTranslation()(
  connect(mapStateToProps)(GoogleMapComponentV5)
);
