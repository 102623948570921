import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { format } from 'date-fns'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  setAdditionalCoverageVisibility
} from "../../../../../actions/applicationMetadataAction";
import RenderTemplate from '../../../../../template/RenderTemplate';
import dynamicRender from '../dynamicRender';
import { constructAndInvokeMetadataCall } from '../../../utils/Common/MetaUtils'
import { hideLoader, showLoader } from "../../../../../actions/UIFlowActions";
import PageLoader from '../../../controls/PageLoader'


const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open: true,
      currentPageIndex: 2,
      pageMetadata: {},
      CoverageList: [],
      isTemplateneeded: false,
      page: 0,
      rowsPerPage: 10,
      dynamicdatatablerows: [],
      primaryState: "",
      values: this.props.values,
      savedValues: this.props.savedValues,
      pagelevelValues: this.props.pagelevelValues,
      transactionPrimaryObject: this.props.transactionPrimaryObject
    };
  }

  componentDidMount = async() => {
    this.UpdateState("loading",true,true)

        if (!this.props.templateRenderNeeded) {
      let currentPageIndex= this.props.UIFlowReducer.currentPageIndex;
      let pageNav =  this.props.pageNavigation[currentPageIndex - 1]? this.props.pageNavigation[currentPageIndex - 1]: this.props.navigation
      let {ObjectId: objectId, ObjectVerId: objectVerId} =pageNav;
      let subappinfo = {subappName: pageNav.SubApplicationName, objectId, objectVerId}
      await constructAndInvokeMetadataCall(subappinfo,{transactionPrimaryObject: this.state.transactionPrimaryObject,  EffectiveDate: this.state.values.EffectiveDate},this.metadataAPI_callback);
    }
    this.UpdateState("loading",false,true)

  }

  componentDidUpdate=  async(prevProps, prevState) =>{


    let LOB = this.props.metadataReducer.lob;
    if ((this.props.values.PrimaryState !== prevProps.values.PrimaryState && this.props.metadataReducer.additionalCoverageVisibility && this.props.metadataReducer.additionalCoverageVisibility[LOB] === "true")) {

      let additionalCoverageVisibility =  this.props.metadataReducer.additionalCoverageVisibility;
      additionalCoverageVisibility[LOB] = 'false';
      this.props.setAdditionalCoverageVisibility(additionalCoverageVisibility);
    }
    this.state.values = this.props.values;
    this.state.savedValues = this.props.savedValues;
    this.state.pagelevelValues = this.props.pagelevelValues;
    this.state.transactionPrimaryObject = this.props.transactionPrimaryObject;

    if (prevProps.currentPageIndex !== this.props.currentPageIndex && !this.props.templateRenderNeeded || (prevProps.metadataReducer.SubmissionStatus && this.props.metadataReducer.SubmissionStatus
      && prevProps.metadataReducer.SubmissionStatus!==this.props.metadataReducer.SubmissionStatus)) {
      let currentPageIndex= this.props.UIFlowReducer.currentPageIndex;
      let {ObjectId: objectId, ObjectVerId: objectVerId} = this.props.pageNavigation[currentPageIndex - 1]
      let subappinfo = {subappName: this.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId}
      await constructAndInvokeMetadataCall(subappinfo,{transactionPrimaryObject: this.state.transactionPrimaryObject,  EffectiveDate: this.state.values.EffectiveDate},this.metadataAPI_callback);

    }
    // if ((prevProps.currentPageIndex !== this.props.currentPageIndex && this.props.navigation.getEventName)) {
    //   console.log("kalai",prevProps.currentPageIndex,this.props.currentPageIndex,this.props.navigation.getEventName)
    //   this.props.getFunction(this.props.navigation, 'get');
    // }
  }

  metadataRequest_callback = (request) => {
    this.props.showLoader();
    // this.props.setMetadataRequest(JSON.stringify(request, null, 2));
    request.SubApplicationNameList = this.props.navigation.SubApplicationName
    request.EffectiveDate = format(new Date(), "yyyy-MM-dd");
    request.State = (this.state.values.PrimaryState !== undefined && this.state.values.PrimaryState !== "") ? this.state.values.PrimaryState : 'CW'
    return request;
  }
  metadataAPI_callback = (request, response) => {
    // this.props.setMetadataRequest(JSON.stringify(request, null, 2));
    // this.props.setMetadataResponse(JSON.stringify(response, null, 2));
    this.setState({
      pageMetadata: response,
    },()=>{
    });
    this.props.hideLoader();
  }


  callback = () => {
    let currentPageIndex= this.props.UIFlowReducer.currentPageIndex;
    let {ObjectId: objectId, ObjectVerId: objectVerId} = this.props.pageNavigation[currentPageIndex - 1]
    let subappinfo = {subappName: this.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId }
    constructAndInvokeMetadataCall(subappinfo,{transactionPrimaryObject: this.state.transactionPrimaryObject,  EffectiveDate: this.state.values.EffectiveDate},this.metadataAPI_callback);

  }

  handleInputChangeV2 = (value, component, isDynamicTable, datatableRowindex) => {
    this.props.handleInputChangeV2(value, component, isDynamicTable, datatableRowindex, this.callback);
    // metadataAPI(constructMetdataRequest(this.state, this.props, this.metadataRequest_callback), this.metadataAPI_callback);
  }

  handleHiddenInputChange = (component, isDynamicTable, datatableRowindex) => {
    this.props.handleHiddenInputChange(component, isDynamicTable, datatableRowindex);
    // metadataAPI(constructMetdataRequest(this.state, this.props, this.metadataRequest_callback), this.metadataAPI_callback);
  }

  updateValueWithSubplicationName = (updatedValue, component) => {
    this.props.updateValueWithSubplicationName(updatedValue, component);
    // if (component.AjaxNeeded === "true") {
    //   metadataAPI(constructMetdataRequest(this.state, this.props, this.metadataRequest_callback), this.metadataAPI_callback);
    // }
  }
  UpdateState = (statename, value, rerender) => {
    // console.log("update state", statename, value, rerender);
    if (rerender) {
      this.setState({
        [statename]: value
      });
    } else {
      this.state[statename] = value;
    }
  }


  handleClick = (event) => {
    this.state.open
      ? this.setState({
        open: false,
      })
      : this.setState({
        open: true,
      });
  };

  render() {
  //  console.clear();
  //  console.log("accordion state",this.state,this.props);
    let lobName = {
      "CommercialProperty": "Commercial Property",
      "CommercialAutomobile": "Commercial Automobile",
      "GeneralLiability": "General Liability",
      "CommercialUmbrella": "Commercial Umbrella"
    }

    const ExpansionPanelSummary = withStyles({
      root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        backgroundColor: "rgba(0, 0, 0, .03)",
        // boxShadow: "9px 10px 43px -2px rgba(0,0,0,0.135)",
        marginBottom: -1,
        minHeight: 10,
        height: 30,
        marginLeft: "-1px !important",
        "&$expanded": {
          minHeight: 10,
        },
      },
      content: {
        "&$expanded": {
          margin: "12px 0",
        },
      },
      expanded: { transform: "rotate(360deg) !important" },
      expandIcon: {
        order: -1,
        transform: "rotate(270deg)",
      },
    })(MuiExpansionPanelSummary);

    let subapplicationList = [];

    if (this.state.pageMetadata.ApplicationDetail) {
      subapplicationList = this.state.pageMetadata.ApplicationDetail
        .SubApplicationDetailList;
    }


    const TableControls = {
      "tableUtils": {
        "AddRow": "",
        "EditRow": "",
        "OnDetailEdit": "",
        "redirectTo": ""
      },
      "TableOptions": {
        "ChildrenSubApplicationName": null,
        "ChildrenComponent": null,
        "AddButtonNeeded": true,
        "SortingNeeded": false,
        "FilteringNeeded": false,
        "FilterIconNeeded": false,
        "rowsPerPageOptions": [5, 10, 25],
        "RowsPerPage": 5,
        "ActionName": "Action",
        "ActionListOptions": []
      }
    }

    const functions = {
      updateValueWithSubplicationName: this.updateValueWithSubplicationName,
      handleHiddenInputChange: this.handleHiddenInputChange,
      handleInputChangeV2: this.handleInputChangeV2,
      UpdateState: this.UpdateState,
      handleInputChange: this.props.handleInputChange,
      handleAutoCompletechange: this.props.handleAutoCompletechange,
      handleDateChange: this.props.handleDateChange,
      invokeGetAccount: this.props.invokeGetAccount,
      focusToParent: this.props.focusToParent ? this.props.focusToParent : null
    }

    const renderContent = () => {
      return (
        <RenderTemplate
          parentState={this.props.parentState}
          focusToParent={this.props.focusToParent ? this.props.focusToParent : null}
          tabPosition={this.props.tabPosition}
          parentTabPosition={this.props.parentTabPosition}
          navigation={this.props.navigation}
          getFunction={this.props.getFunction}
          Template={this.props.Template}
          values={this.props.values}
          pagelevelValues={this.props.pagelevelValues}
          handleInputChangeV2={this.props.handleInputChangeV2}
          transactionPrimaryObject={this.props.transactionPrimaryObject}
          handleHiddenInputChange={this.props.handleHiddenInputChange}
          updateValueWithSubplicationName={this.props.updateValueWithSubplicationName}
          handleAutoCompletechange={this.props.handleAutoCompletechange}
          updateArrayValueOnState={this.props.updateArrayValueOnState}
          deleteArrayValue={this.props.deleteArrayValue}
          canProceed={this.props.canProceed}
          UpdateState={this.props.UpdateState}
          handleGoogleRegion={this.props.handleGoogleRegion}
          handleDateChange={this.props.handleDateChange}
          objectId={this.props.objectId}
          objectVerId={this.props.objectVerId}
          functions={this.props.functions}
          getApiResponse={this.props.getApiResponse}
          productBrowseData={this.props.productBrowseData}
          navigateToPrevious={this.props.navigateToPrevious}
          navigateToNext={this.props.navigateToNext}
          lobUtil={this.props.lobUtil}
          propsValue={this.props.propsValue}
          location={this.props.location}
           />
      )
    }

    return (

      <React.Fragment>
        {this.props.isExpansionPanelNeeded ?
          <Grid item xs={12} sm={12} md={12} lg={12} className={`custom_location ${this.props.navigation.class}`}>
            {this.props.pagelevelValues.SelectedLineOfBusiness && this.props.pagelevelValues.SelectedLineOfBusiness.IsMultiLob !== 'true' &&
              <ExpansionPanelSummary
                aria-label="expand row"
                onClick={(event) => this.handleClick(event)}
                className="custom-margin-left"
                expandIcon={<ExpandMoreIcon></ExpandMoreIcon>}
              >
                <p style={styles.panelSummaryContent}>{lobName[this.props.metadataReducer.lob]}</p>
                {
                  this.props.premium && this.props.renderRateAndPreview ?
                    <p style={{ ...styles.panelSummaryContent, ...styles.rightContent }}>PREMIUM : {currencyFormatter.format(this.props.premium)} </p>
                    :
                    null
                }
              </ExpansionPanelSummary>
            }
            <Collapse
              style={styles.collapse}
              in={this.state.open}
              timeout="auto"
              unmountOnExit
            >
              <Card style={styles.card}>
                {this.props.templateRenderNeeded ?
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardContent  className="custom-margin-left" style={{ marginBottom: '20px', width: '100%' }}>
                      <Grid container spacing={3}>
                        {renderContent()}
                      </Grid>
                    </CardContent>
                  </Grid> : dynamicRender(subapplicationList, this.props, this.state, functions, TableControls)}
              </Card>
            </Collapse>
          </Grid>
          :
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {renderContent()}
          </Grid>

        }
         {
            this.state.loading && <PageLoader />
            }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardReducer: state.dashboardReducer,
  handleControllerReducer: state.handleControllerReducer,
  metadataReducer: state.metadataReducer,
  pageNavigation: state.navigationReducer.pageNavigation,
  commonNavigation: state.metadataReducer.commonNavigation,
  premium: state.workflowReducer.premiumwithoutTFS,
  navigationReducer: state.navigationReducer,
  UIFlowReducer: state.UIFlowReducer
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  setAdditionalCoverageVisibility: (additionalCoverageVisibility) => dispatch(setAdditionalCoverageVisibility(additionalCoverageVisibility)),
  // // setMetadataRequest: (request) =>dispatch(setMetadataRequest(request)),
  // //setMetadataResponse: (response) => dispatch(setMetadataResponse(response)),
});


const styles = {
  collapse: {
    backgroundColor: "#f5f5f5",
    marginTop: "10px",
    marginBottom: "10px",
  },
  panelSummaryContent: {
    border: "none !important",
    background: "none !important",
    margin: "0px",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "bolder",
  },
  rightContent: {
    marginLeft: 'auto',
    marginRight: '2rem'
  },
  card: {
    boxShadow: "none",
    border: "none"
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Accordion);
