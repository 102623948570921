import { combineReducers } from 'redux';
import authReducer from './authReducer';
import { metadataReducerInitialState, metadataReducer } from './applicationMetadata';
import { workflowReducer, getWorkflowEventInitialState } from './workFlowEventReducer';
import ownerResources from './OwnerResources';
import { navigationReducer } from './navigationReducer';
import { userInfoReducer } from "./userInfoReducer"
import { RESET_STORE, RESET_SUBMISSION } from '../actions/types';
import {requestResponseReducerInitialState,requestresponsereducer } from './requestresponsereducer';
import { UIFlowReducer, uiFlowReducerInitialState } from './UIflowReducer';

const appReducer = combineReducers({
    UIFlowReducer,
    authReducer,
    metadataReducer,
    workflowReducer,
    ownerResources,
    navigationReducer,
    userInfoReducer,
    requestresponsereducer
});

// reset the state of a redux store
const rootReducer = (state, action) => {

    if (action.type === RESET_STORE) {
        state = undefined;
    }
    else {
        if (action.type === RESET_SUBMISSION) {
            state = { ...state, workflowReducer: getWorkflowEventInitialState(), metadataReducer: metadataReducerInitialState(), requestresponsereducer:requestResponseReducerInitialState() ,UIFlowReducer: uiFlowReducerInitialState() };
        }
    }
    return appReducer(state, action)
}

export default rootReducer;
