import { getNavigation } from '../../../../CommonAxios/ResourceAxios';
import store from "../../../../store";
import { getRequiredIndex, triggerToast } from "./HelperUtils";
import appActions from "../../../../actions";
import container from "../DIContainer";
const {actionType} = appActions

export const templateConstruct = async (source, keys, info) => {
    try {
        switch (true) {
            case source.hasOwnProperty('productNavigation'):
                let productNavigation = await constructTemplateInProductNav(source.productNavigation, keys, info)
                return productNavigation
            case source.hasOwnProperty('EndorsementNavigation'):
                let endorsementNavigation = await constructTemplateInProductNav(source.EndorsementNavigation, keys, info)
                return endorsementNavigation
            case "UtilsInTemplate":
                let template = await constructUtilInTemplate(source, keys, info)
                return template
            case source.hasOwnProperty('pageNavigation'):
                let pageNav = await constructPageNavigation(source.pageNavigation, keys, info)
                return pageNav
            case source.hasOwnProperty('quoteNavigation'):
                let quoteNavigation = await constructTemplateInProductNav(source.quoteNavigation, keys, info)
                return quoteNavigation
            case source.hasOwnProperty('policyNavigation'):
                let policyNavigation = await constructTemplateInProductNav(source.policyNavigation, keys, info)
                return policyNavigation
            case source.hasOwnProperty('ReinstateNavigation'):
                let reinstateNavigation = await constructTemplateInProductNav(source.ReinstateNavigation, keys, info)
                return reinstateNavigation
            case source.hasOwnProperty('CancelNavigation'):
                let CancelNavigation = await constructTemplateInProductNav(source.CancelNavigation, keys, info)
                return CancelNavigation
            default:
                return {}
        }
    }
    catch(e){
        console.log('Error in Template construct ', e);
    }
}

const constructTemplateInProductNav = async (productNav, keys, TemplateInfo) => {
    try {
        Object.entries(productNav).map(([key, value]) => {
            value.forEach( (i) => {
                keys.forEach( (k) => {
                    if(i[k]){
                        TemplateInfo[i[k]] = constructTemplateWithUtil(TemplateInfo[i[k]], ["utilfunction"], container.get("Utils"))
                        i[k] = TemplateInfo[i[k]]
                    }
                });
                if (i.children) {
                    i.children.forEach((child) => {
                        constructTemplateInProductNav({ children: [child] }, keys, TemplateInfo);
                    });
                }
            });
        });
    }
    catch(e) {
        console.log('Error in Template construct ', e);
    }
};


const constructUtilInTemplate = (template, keys, utilIndex) => {
    let finalTemplate = []
    template.forEach( temp => {
        let tempObj = Object.assign({}, temp)
        keys.forEach( key => {
            if(tempObj[key]){
                tempObj[key] = utilIndex[tempObj[key]]
            }
        })
        finalTemplate.push(tempObj)
    })
    console.log('final template ', finalTemplate)
    return finalTemplate
}

export const constructPageNavigation = async (pageNav, keys=['utilfunction'], TemplateInfo=container.get('Utils')) => {
    try {
        Object.entries(pageNav).map(([key, value]) =>{
            let template = []
            value.template && (template = constructTemplateWithUtil(value.template, keys, TemplateInfo))
            // value.baseTemplate && (template = constructTemplateWithUtil(value.baseTemplate, ['utilfunction'], Utils))
            pageNav[key] = Object.assign({},{...value}, {template: template})

        })
    }
    catch(e) {
        console.log('Error in Template construct ', e);
    }
}

export const constructTemplateWithUtil = (inputTemplate, keys=container.get('ProductNavigationKeys'), utilObject=container.get('Utils')) => {
  try {
        inputTemplate && inputTemplate.forEach( e => {
            keys.forEach(k => {
                if(e[k] && typeof e[k] === 'string'){
                    e[k] = utilObject[e[k]]
                }
            })
            if(e.children && e.children.length > 0){
                e['children'] = constructTemplateWithUtil(e.children, keys, utilObject)
            }
        })
        return inputTemplate
    }
    catch(e) {
        console.log('Error in Template construct ', e);
    }
}

export const callGetNavigation =async (route, productVer, props, state) =>{
    switch(route){
        case "Submission":{
            console.log("submission");
            await getAndSetNavigation("ProductNavigation", productVer, props, state)
            break;
        }
        case "quote":{
            console.log("quote " + productVer);
            let res = await getAndSetNavigation("QuoteNavigation",productVer, props, state);
            let data = await res;
            return;
        }
        case "policy":{
            console.log("policy");
            await getAndSetNavigation("PolicyNavigation",productVer, props, state);
            break;
        }
        default:{
            console.log(" empty route? ", route);
            break;
        }

    }
}

const getAndSetNavigation = async (groupname, productVer, props, state) => {
   let productVerNum = [], template;
   state.loading = true;
    if(state.pagelevelValues["SelectedLineOfBusiness"] && Array.isArray(state.pagelevelValues["SelectedLineOfBusiness"]["LOBName"])){
        // productVerNum = ['GL_4402_471_V1','CF_4402_92_V1', 'AU_4402_80_V1'];
        let productDetails = props.multiFlowReducer.selectedLOB
        productDetails.forEach( (prd) => {
            productVerNum.push(prd.ProductVerNumber)
          })
        template = props.navigationReducer.templateInfo
        // template = TemplateInfo;
    }
    else {
        productVerNum.push(props.productDetails.ProductVerNumber || productVer)
        console.log('productverNumber//// ', productVer)
        template = props.navigationReducer.templateInfo
        // template = TemplateInfo
    }

    let responseObj = await getNavigation([groupname], productVerNum);

    await templateConstruct(responseObj, ['template'], template)
    await props.setNavigation({...props.navigationReducer.navigation, ...responseObj});
    state.loading = false;
    return;
}

export const updateProdNav = async(props,pageDetail)=>{
  try{
    let reduxState = store.getState();
    let templateInfoTemplates = reduxState.navigationReducer.templateInfo
    let ProductVerNumber;
    if(reduxState.UIFlowReducer.productDetails && reduxState.UIFlowReducer.productDetails.ProductVerNumber){
            ProductVerNumber = reduxState.UIFlowReducer.productDetails.ProductVerNumber
    }
    else{
          triggerToast("Error in getting ProductDetails")
          return
          }
          let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"],[ProductVerNumber]);
          if(navObj.hasOwnProperty('productTemplateInfo')){
            //Util Construction in Templates
            Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
              navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],container.get('ProductNavigationKeys'),container.get('Utils'))
            })
            //SubTemplate construction in Templates
            Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
              navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
            })
            store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
          }
          let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
          if (navObj.hasOwnProperty("productNavigation")) {
            let key = reduxState.UIFlowReducer.productDetails.LOBName ? `${reduxState.UIFlowReducer.productDetails.LOBName}_submission` : "template";
            key = navObj.productNavigation[key] ? key : "template"
            console.log('()key 3', key)
            templateConstruct(navObj, ["template"], templatefromredux);
            let prodnav = navObj.productNavigation[key];
            let pageNav = Object.assign({}, reduxState.navigationReducer.navigation);
            let index =getRequiredIndex(pageNav.pageNavigation.submission.sidebar,'name','Submission')
            pageNav.pageNavigation.submission.sidebar[index].children = prodnav;
            store.dispatch({type: actionType.SET_PAGE_NAVIGATION ,payload : prodnav})
            store.dispatch({type: actionType.SET_NAVIGATION,payload: {...pageNav,"productNavigation":{...navObj.productNavigation}}});
      }
    }

  catch(e){
      console.log("Error in updateProdNav ::", e)
    }
 }


 export async function getproductnavigation (props,getProductDetails) {
  try{
  let nav=[];
  let reduxState = store.getState()
  let {productDetails} = reduxState.UIFlowReducer
  let templateInfoTemplates = reduxState.navigationReducer.templateInfo
  let navigation =reduxState.navigationReducer.navigation
  let LOB = productDetails.LOBName
  let {flowType=""}= getProductDetails
  let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"], [productDetails.ProductVerNumber]);
  if(navObj.hasOwnProperty('productTemplateInfo')){
    //Util Construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],container.get('ProductNavigationKeys'),container.get('Utils'))
    })
    //SubTemplate construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
    })
    store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
  }
  let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
  if (navObj.hasOwnProperty("productNavigation")) {
    templateConstruct(navObj, ["template"], templatefromredux);
    nav = navObj.productNavigation[`${LOB}_${flowType.toLowerCase()}`];
    let pageNav = Object.assign({}, navigation);
  pageNav.pageNavigation[flowType.toLowerCase()].sidebar[getRequiredIndex(pageNav.pageNavigation[flowType.toLowerCase()].sidebar,'name',flowType)].children =  nav
    await store.dispatch({ type: actionType.SET_ROUTE_INFO, payload: flowType })
    await store.dispatch({
      type: actionType.SET_NAVIGATION,
      payload: { ...pageNav, "productNavigation": { ...navObj.productNavigation } }
    })
    }

  }
  catch (err) {
      console.log("Error in GetProductNavigation", err)
      throw new Error("Error in GetProductNavigation")

  }
}
