import {changeSOLDateformat} from '../Common/DateUtils'
export const IsDateValidWithinRange = (component, date ) => {
    let currentDate = new Date();
    let dateRange = {}
    let minimumYear = currentDate.getFullYear() - parseInt(component.MinValue);
    let maximumYear = currentDate.getFullYear() - parseInt(component.MaxValue);
    dateRange.minDate = changeSOLDateformat(`${minimumYear}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`);
    dateRange.maxDate = changeSOLDateformat(`${maximumYear}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`);
    return dateRange
}

export const LOBUtil = {
    PL: {
        "IsDateValidWithinRange" : IsDateValidWithinRange,
        }
}
