import * as React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import {  TextValidator } from "react-material-ui-form-validator";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { LocationOnOutlined } from '@material-ui/icons';
import store from '../../../store';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleLookup(props) {
  console.log("clientinfooo",props);
  // const GOOGLE_MAPS_API_KEY = '';
  const GOOGLE_MAPS_API_KEY =  store.getState().ownerResources.clientInfo.GoogleMapsAPIKey ;
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );
  const validations = [];
  const errorMessages = [];
React.useEffect(() => {
console.log("ayy check useEffect props",props )

let componentvalue = props.componentvalue
let value = "";
if (component.DefaultValue === "" && componentvalue !== undefined) {
  value = componentvalue;
}
if (component.DefaultValue !== "" && componentvalue !== undefined) {
  value = componentvalue;
}
if (component.DefaultValue !== "" && componentvalue === undefined) {
  value = component.DefaultValue;
}
if (component.DefaultValue === "" && componentvalue === undefined) {
  value = "";
}
if (
  component.DefaultValue !== "" &&
  component.AttributeValue === "" &&
  componentvalue !== undefined
) {
  value = componentvalue;
}
if (isRequired) {
  validations.push("required");
  errorMessages.push(component.RequiredMessage);
  validations.push("IsEmpty");
  errorMessages.push(component.RequiredMessage);
}
setValue(value)
  }, [props]);


  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  const onChange = (e,newValue) => {
    console.log("properties->",e ,newValue);
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    let value = Object.assign({}, { [props.component.AttributeName]: newValue ? newValue.description : ""});
    if (props.customTable) {
     props.handleRowLevelInputChange(value, props.component, props.customTableRowIndex, props);
    } else if(props.component.OnChangeAction!=='' && props[props.component.OnChangeAction]) {
      props[props.component.OnChangeAction](value, props.component, props.isDynamicTable, props.datatableRowindex);
      } else {
       props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
      }
    }

  const { component, isDynamicTable, datatableRowindex, pagelevelValues } = props;
  let isReadOnly = component.IsReadonly;
  let isRequired = component.IsRequired;
  if (isRequired) {
    validations.push("required");
    errorMessages.push(component.RequiredMessage);
    validations.push("IsEmpty");
    errorMessages.push(component.RequiredMessage);
  }
  let autocomplete="off";
  let custom3=component.Custom3 && JSON.parse(component.Custom3);
  (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

  return (
    <Grid item xs={12} sm={12} md={component.Size} lg={component.Size}  className={component.AttributeStyleClass}>
    <Autocomplete
    style={{ display: "inline-flex", width: "100%" }}
    freeSolo
    className={`${component.StyleClass} googlelookupoverrideclass`}

    disabled={component.IsReadonly}
    id={component.AttributeName}
    autoComplete={autocomplete}
    name={component.AttributeName}
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      includeInputInList
      filterSelectedOptions
      value={value}
      enabled={isReadOnly}
      onChange={(event,newValue) => {
        onChange(event,newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) =>
        <TextValidator {...params}
            placeholder={component.ComponentLabel}
            value={inputValue}
            className="custom-input"
            autoComplete={autocomplete}
            name={component.AttributeName}
            label={<Typography className="custom-label-down text-font">{component.IsRequired === 'Y' ? `${component.ComponentLabel} *` : component.ComponentLabel}</Typography>}
            margin="normal"
            validators={validations}
            errorMessages={errorMessages}
            fullWidth
        />}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts =
        parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (

          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnOutlined}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
    </Grid>
  );
}
