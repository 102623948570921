import React , {Fragment} from 'react';
import {   TableRow, TableCell, TableSortLabel } from "@material-ui/core";
import DataTableCheckBox from './DatatableCheckBox';

function DataTableColumnsV2(props) {

    let { columnList, TableOptions, columnOptions, toggleSelect,ChildSubApplicationList, tableActions, children } =props;
    let { selectable = {}, pivotSummary  = {}, filtering = {}, sorting = {},  alignTableData, allowRowEdit = false, dragRow={} } = tableActions;

    let actionListOptions = []
    if(tableActions.hasOwnProperty('ActionList') && !Array.isArray(tableActions.ActionList)) actionListOptions.push(tableActions.ActionList)
    else if(tableActions.hasOwnProperty('ActionList') && Array.isArray(tableActions.ActionList)) actionListOptions = tableActions.ActionList

    let actionRequired = actionListOptions.length > 0 ? true : false;
    let detailRequired = children || ChildSubApplicationList && ChildSubApplicationList.length > 0;
    // let rowHeadingNeeded = TableOptions.hasOwnProperty("PivotSummary") && TableOptions.PivotSummary.hasOwnProperty("render") ?TableOptions.PivotSummary.render:false;
    // let align = requiredCss.alignCenter ? "center" : "left" // can be moved to db entry
    const createSortHandler = (property) => (event) => {
        columnOptions.handleSort(event, property);
    };

    return (
        <Fragment>
        {
            <TableRow className='Custom_Data_Table_Column theme_DataTable'>
                { (!allowRowEdit && (detailRequired || pivotSummary.isNeeded))  && <TableCell />}
                { dragRow.isNeeded && <TableCell />}
                {   tableActions.selectable && tableActions.selectable.isNeeded &&
                    <DataTableCheckBox
                        {...props}
                        disabled={ !!props.dataList.length }
                        name={selectable.hasOwnProperty("attributeName") ? selectable.attributeName : "" }
                        checked={ props.isColumnChecked }
                        defaultChecked= { props.defaultChecked }
                        onChange={ (e)=> toggleSelect(e) }
                     />
                    //   : tableActions.selectable && tableActions.selectable.hasOwnProperty('isSelectAllNeeded') && tableActions.selectable.isSelectAllNeeded === false && <TableCell />
                }
                {/* {
                    actionRequired &&
                    <TableCell key="actionitem" align={align} className={TableOptions.FilteringNeeded && `dt-headCell`}>
                        {TableOptions.ActionName}
                    </TableCell>
                } */}
                {
                    <Fragment>
                        {
                            columnList.map((component) => {
                                if (component.RenderValue)
                                {
                                    let componentId = component.ComponentId;
                                    let styleClass = "";
                                    if(filtering.isNeeded){
                                        styleClass = styleClass + "dt-headCell";
                                    }
                                    // if(TableOptions.BorderNeeded)
                                    // {
                                    //     styleClass = styleClass + "BorderClass";
                                    // }
                                    let classNameForLine=''
                                    if(component.RowHeadingStyle){
                                        styleClass = `${styleClass} ${component.RowHeadingStyle}`
                                    }
                                    if(component.ComponentLabel.indexOf(' ') >= 0){
                                        if(!tableActions.lineBreakerNotNeeded) classNameForLine='lineBreaker'
                                    }
                                    return (
                                        <TableCell
                                            className={`${styleClass} Custom_Data_Table_Column_Cell ${classNameForLine}`}
                                            key={componentId}
                                            align={alignTableData}
                                            sortDirection={columnOptions.sortBy === componentId ? columnOptions.sortOrder : false}
                                        >
                                        {!sorting.isNeeded && <>
                                            {component.ComponentType.toLowerCase() === 'booleancheckbox'
                                                ? <DataTableCheckBox
                                                    {...props}
                                                    isReadOnly={component.IsReadonly || props.disableColumnComponent || props.dataList.length===0 || false}
                                                    disabled={ !!props.dataList.length }
                                                    name={selectable.hasOwnProperty("attributeName") ? selectable.attributeName : "" }
                                                    checked={ props.isColumnChecked }
                                                    defaultChecked= { props.defaultChecked }
                                                    onChange={ (e)=> toggleSelect(e) }
                                                />
                                                : <>{component.ComponentLabel}</>
                                            }
                                            </>}
                                        {   sorting.isNeeded &&
                                            <TableSortLabel
                                                active={columnOptions.sortBy === componentId}
                                                direction={columnOptions.sortBy === componentId ? columnOptions.sortOrder : "asc"}
                                                onClick={createSortHandler(componentId)}
                                            >
                                                {component.ComponentLabel}
                                            </TableSortLabel>
                                        }
                                        {   /*(TableOptions.FilteringNeeded) &&
                                            <TextField
                                                id={`${componentId}_filterText`}
                                                name={componentId}
                                                value={columnOptions.filtercriteria[componentId] || ""}
                                                onChange={(e) => columnOptions.handleFilter(e)}
                                                /* InputProps={
                                                    TableOptions.FilterIconNeeded && {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                 <FilterListIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                } */
                                            // />*/
                                        }
                                        </TableCell>
                                    )
                                }
                            })
                        }
                    </Fragment>
                }
                {
                    actionRequired &&
                    <TableCell key="actionitem" align={alignTableData} className={`Custom_Data_Table_Column_Cell ${TableOptions.FilteringNeeded ?'dt-headCell': ''}`}>
                        {TableOptions.ActionName}{/* Need to be discussed*/}
                    </TableCell>
                }

            </TableRow>
        }
        </Fragment>
    )
}

export default DataTableColumnsV2;
