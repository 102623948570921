import React from 'react';
import { connect } from 'react-redux';
import { setShowHideCondition, setStateList, setIconcondition } from '../../../../../actions/applicationMetadataAction';
import DynamicComponent from "../../../corecomponents/DynamicComponent";
import { showLoader, hideLoader } from "../../../../../actions/UIFlowActions";
import { getParentState } from '../../../utils/Common/CommonUtils';
import DataTable from '../../../controls/DataTable/DataTable';
import { LocationandBuildingAxios, getServiceRequestDetail } from "../../../utils/Common/API"
import { defaultToast, triggerToast } from '../../../utils/Common/HelperUtils';
import { constructAndInvokeMetadataCall } from '../../../utils/Common/MetaUtils';
import container from '../../../utils/DIContainer';

const LocationSummaryTemplate = React.lazy(() => import("./TemplateRendering"));
const LocationSummaryDetail = React.lazy(()=> container.get('templateControls').detailsummarylist)
class LocationSummary extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            pageMetadata: {},
            pagelevelValues: {},
            parentHierarchyArr: []
        }
    }

    componentDidMount = async () => {
        this.handleMetadataCall();
      await this.props.pageInfo.utilfunction[this.props.pageInfo.util].componentDidMount_Summary(this.props,this.state,this.UpdateState,this.handleMetadataCall);
    }
    componentWillReceiveProps  = async (nextProps) => {
        if (this.props.pageInfo.utilfunction[this.props.pageInfo.util].hasOwnProperty("componentWillReceiveProps")) {
            await this.props.pageInfo.utilfunction[this.props.pageInfo.util].componentWillReceiveProps(this.props,nextProps,this.UpdateState);
        }
    }

    invokeAPICall = async () => {
        this.props.showLoader()
        const { subApplication, entityName } = this.props.pageInfo;
        let request = Object.create({});
        request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
        request["OwnerId"] = sessionStorage.getItem("OwnerID")
        await this.props.pageInfo.utilfunction[this.props.pageInfo.util].getRequestContruction(request, this.props);
        console.log("[karthuu]request-->", request)
        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
            .then((response) => {
                if(response.data.hasOwnProperty('ResponseStatus') &&  response.ResponseStatus.toLowerCase() === "failed"){
                    throw new Error("Response Failed");
                }else{
                console.log("[karthuu]Responsee summary==>", response)
                this.props.hideLoader()
                    if (response.data[entityName]) {
                        if (response.data[entityName].length > 0) {
                            let parentHierarchyArr = this.state.parentHierarchyArr
                            parentHierarchyArr = this.props.metadataReducer.iconcondition ? this.props.metadataReducer.iconcondition : []
                            if (!parentHierarchyArr.includes(this.props.pageInfo.subApplication.locationSummary.toLowerCase())) {
                                parentHierarchyArr.push(this.props.pageInfo.subApplication.locationSummary.toLowerCase())
                            }
                            this.props.setIconcondition(parentHierarchyArr)
                        }
                    }
                    this.setState({
                        values: { ...this.state.values, ...response.data[entityName] },
                        pagelevelValues: { ...this.state.pagelevelValues, ...response.data[entityName] }
                    }, async () => {


                        let responseEntity = response.data[entityName]
                        let idArray = []
                        if (responseEntity) {
                            await this.props.pageInfo.utilfunction[this.props.pageInfo.util].setParentHierarchyId(responseEntity, this.props, idArray);
                        }
                        if (Array.isArray(response.data[entityName])) {
                            let subapplicationName = subApplication['locationSummary'].toLowerCase();
                            let dataListName = subapplicationName + "_dataList";
                            console.log("dataListName", dataListName, entityName, subapplicationName, entityName.includes(subapplicationName))
                            let dataListValue = response.data[entityName]
                            console.log("arryyyyyyy", dataListName, dataListValue)
                            this.UpdateState(dataListName, dataListValue, true);
                        }
                        this.handleMetadataCall();
                    })
               }
            })
            .catch((err) => {
                console.log(err);
                triggerToast(err.message)
            });
        }
    handleMetadataCall = async () => {
        this.props.showLoader()
        const { subApplication, objectId, objectVerId } = this.props.pageInfo;

        let subapplicationName = [subApplication['locationSummary'],subApplication['locationMandatoryCheck']];
        console.log("subapplicationName in handleMetadataCall", subapplicationName)
        let parentstate = getParentState(this.props)
        let state =  parentstate ? parentstate : 'CW' , transactionPrimaryObject = this.props.parentProps ? this.props.parentProps.transactionPrimaryObject : {}

        let metadataResponse = await constructAndInvokeMetadataCall({ subappName: subapplicationName, objectId, objectVerId, state }, { transactionPrimaryObject, EffectiveDate: this.props.values.EffectiveDate });
        console.log("metadataResponse in handleMetadataCall", metadataResponse)
        this.UpdateState("pageMetadata", metadataResponse, true);
        this.props.hideLoader()
    }

    UpdateState = (statename, value, rerender) => {
        if (rerender) {
            this.setState({
                [statename]: value
            });
        } else {
            this.state[statename] = value;
        }
    }

    getLocationList = async () => {

        let resultData = {
            "VehicleList": [
            ]
        }
        return resultData['VehicleList'];
    }

    findRowIndex = (statename, rowInfo, key) => {
        let currentState = JSON.parse(JSON.stringify(this.state[statename]));
        let rowIndex;
        currentState.map((row, index) => {
            if (row[key] === rowInfo[key]) {
                rowIndex = index;
            }
        });
        return rowIndex;
    }

    onDeleteLocation = async(statename, currentRowInfo) => {
        let key = 'ID';
        if(currentRowInfo[key]){
         await   this.props.pageInfo.utilfunction[this.props.pageInfo.util].deleteRow(this.props,currentRowInfo[key]);
        } else{
            key = 'uid'
        }
        let currentState = JSON.parse(JSON.stringify(this.state[statename]));
        let currentRowIndex = this.findRowIndex(statename, currentRowInfo, key);
        currentState.splice(currentRowIndex, 1);
        this.UpdateState(statename, currentState, true);
        defaultToast("Deleted Successfully")
    }

    onClickSave = (response, key) => {
        let dataListName = key;
        let dataListValue = response
        this.UpdateState(dataListName, dataListValue, true);
      }
    addRow = async (key) => {
        await this.props.pageInfo.utilfunction[this.props.pageInfo.util].onAddRow(this.props, this.updateArrayValueOnState, key);
      }
    updateArrayValueOnState = (key, value, expand, ID) => {
        let arr = [];
        value['isExpanded'] = expand;
        if (this.state[key] !== undefined && Array.isArray(this.state[key])) {
            arr = this.state[key];
        }
        if (ID !== undefined) {
            let updated = false;
            arr.map((el, idx) => {
                if (el.ID === ID) {
                    arr[idx] = { ...value };
                    updated = true;
                }
            })
            if (updated === false) {
                arr.push(value);
            }
        } else {
            arr.push(value);
        }
        this.setState({ [key]: arr });
    }
    onExpand = (key, { ID }, isExpand) => {
        this.setState(prevState => {
            let arr = prevState[key];
            arr.map((el, idx) => {
                if (el.ID === ID) {
                    arr[idx]['isExpanded'] = isExpand
                    return;
                }
            })
            return ({ [key]: arr });
        })
    }
    render() {
        console.log("Ayy Check the props",this.props);
        let {utilfunction, TemplateName} = this.props
        let subapplicationDetailList=[];
        let childComponent = this.props.pageInfo.TemplateName ? LocationSummaryTemplate : LocationSummaryDetail
        let TableControls= {
            tableUtils : {
                "AddRow": this.addRow,
                "onExpand": this.onExpand,
                "onChildSave" : this.onClickSave,
                "EditRow": "",
                "OnDetailEdit": ""
            },
            TableOptions : {
                "ChildrenSubApplicationName": null,
                "ChildrenComponent": childComponent,
                "AddButtonNeeded": true,
                "SortingNeeded": false,
                "FilteringNeeded": false,
                "rowsPerPageOptions": [5, 10, 25],
                "RowsPerPage": 5,
                "ActionName": "Action",
                "ActionListOptions": [
                    {
                        "ActionName": "RemoveVehicle",
                        "ActionDisplayName": "Delete",
                        "OnOptionClick": this.onDeleteLocation
                    }
                ]
            }
        }
        let TableOptions = utilfunction && utilfunction[TemplateName] && utilfunction[TemplateName]["TableOptions"] ? utilfunction[TemplateName]["TableOptions"] : TableControls.TableOptions;
        const tableUtils = utilfunction && utilfunction[TemplateName] && utilfunction[TemplateName]["tableUtils"] ? utilfunction[TemplateName]["tableUtils"] : TableControls.tableUtils;
        TableOptions = {...TableOptions,"ChildrenComponent": childComponent}

        if(this.state.pageMetadata.ApplicationDetail) {
        subapplicationDetailList = this.state.pageMetadata.ApplicationDetail.SubApplicationDetailList

        }
        const renderComponent = (subapp)=>{
            let attributeList = subapp.AttributeDetailList;
            return(
              subapp.Type ==="Summary" ?
              <DataTable
              subAppInfo={{
                  "ComponentList": subapp.AttributeDetailList,
                  "SubApplicationDetail": subapp
              }}
              columnList={this.state.hasOwnProperty(subapp.ApplicationName.toLowerCase() + "_columnList") ? this.state[subapp.ApplicationName.toLowerCase() + "_columnList"] : []}
              dataList={this.state.hasOwnProperty(subapp.ApplicationName.toLowerCase() + "_dataList") ? this.state[subapp.ApplicationName.toLowerCase() + "_dataList"] : []}
              UpdateState={this.UpdateState}
              TableOptions={TableOptions}
              tableUtils={tableUtils}
          >
              {TableOptions.ChildrenComponent != null && <TableOptions.ChildrenComponent pageInfo={this.props.pageInfo} parentProps={this.props.parentProps} {...this.props} state={this.state} tableUtils={this.tableUtils} />}
          </DataTable> :
          <DynamicComponent
            components={attributeList}
            pageInfo={this.props.pageInfo}
            values={this.state.values}
            transactionPrimaryObject={this.props.transactionPrimaryObject}
            state={this.state}
            parentProps={this.props.parentProps}
            pagelevelValues={this.state.pagelevelValues}
            {...this.props}
            UpdateState={this.UpdateState}
          />
          )
            }
            return(
                 subapplicationDetailList.length>0 && subapplicationDetailList.map((subapp)=>{
                    return(
                        <div className={`${subapp.ApplicationName}__wrapperClass`}>
                            {renderComponent(subapp)}
                        </div>
                    )
             }
              ))
    }
}

const mapStateToProps = state => (
    {
        metadataReducer: state.metadataReducer,
        UIFlowReducer: state.UIFlowReducer
    }
)

const mapDispatchToProps = (dispatch) => (
    {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        setStateList: (stateList) => dispatch(setStateList(stateList)),
        setShowHideCondition: (showHide) => dispatch(setShowHideCondition(showHide)),
        setIconcondition: (iconcondition) => dispatch(setIconcondition(iconcondition))
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(LocationSummary);
