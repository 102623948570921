import * as actionTypes from "../actions/types";
export const requestResponseReducerInitialState = () => ({
  metadataRequest: {},
  metadataResponse: {},
  ratingRequest: {},
  ratingResponse: {},
});
const initialState = requestResponseReducerInitialState();
export const requestresponsereducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_METADATA_REQUEST:
      return {
        ...state,
        metadataRequest: action.payload,
      };
    case actionTypes.SET_METADATA_RESPONSE:
      return {
        ...state,
        metadataResponse: action.payload,
      };
    case actionTypes.SET_RATING_RESPONSE:
      return {
        ...state,
        ratingResponse: action.payload,
      };
 case actionTypes.SET_RATING_REQUEST:
        return {
            ...state,
            ratingRequest: action.payload,
        }
    default:
      return state;
  }
};
