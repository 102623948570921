import React from 'react';
import { Typography, Paper } from "@material-ui/core";

function TitleBar(props) {
    let name = (props.component && props.component.ComponentLabel ? props.component.ComponentLabel:"")
    let {component} = props
    return (
        <Paper elevation={3} className={`custom-card-content ${component.StyleClass}`}>
            <Typography className="custom-card-content-findlabel heading-font" >
                {`${name}`}
            </Typography>
        </Paper>
    )
}

export default TitleBar;
