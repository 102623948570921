import React, { Component } from 'react';
import CustomComponent from './CustomComponent';
import { withTranslation } from 'react-i18next';
import { Grid } from "@material-ui/core";
import { scrollBehaviour } from '../utils/Common/HelperUtils';
import container from '../utils/DIContainer';

class DynamicComponent extends Component {
    componentDidMount(){
        scrollBehaviour(this.props.currentPageIndex,this.props.IsExpandable)
    }

    render() {
        const dynamicRender = container.get('dynamicRender')
        const { components } = this.props;
        const { t } = this.props;
        let hasChildSubApp = Array.isArray(this.props.ChildSubApplicationList) && this.props.ChildSubApplicationList.length > 0;
        if (!components) return <div>Processing meta data...</div>
        return (
            <Grid container spacing={3} className="dynamicComponentAlignment">
            <React.Fragment>
                {
                    components.map((component, index) => {
                        if (component.RenderValue) {
                            let componentValue = "";
                            const { staticReadonlyRequired = false } = this.props;
                            if(staticReadonlyRequired) {
                                component.IsReadonly = "true";
                            }
                            if (this.props.customTable) {
                                if (this.props.locationRowValueList[this.props.customTableRowIndex]) {
                                    if (this.props.locationRowValueList[this.props.customTableRowIndex].hasOwnProperty(component.AttributeName)) {
                                        componentValue = this.props.locationRowValueList[this.props.customTableRowIndex][component.AttributeName];
                                    }
                                }
                            } else {
                                componentValue = this.props.pagelevelValues && this.props.pagelevelValues[component.SubApplicationName] !== undefined ? this.props.pagelevelValues[component.SubApplicationName][component.AttributeName] : undefined;
                            }
                            return (
                                <React.Fragment>
                                    <CustomComponent
                                        {...this.props}
                                        key={component.SubApplicationName + component.AttributeName}
                                        component={component}
                                        currentPageIndex={this.props.currentPageIndex}
                                        createSubmissionRes={this.props.createSubmissionRes}
                                        componentLabel={t(component.ComponentLabel)}
                                        values={this.props.values}
                                        handleGoogleRegion={this.props.handleGoogleRegion}
                                        attributeValue={this.props.values && this.props.values[component.AttributeName]}
                                        handleInputChange={this.props.handleInputChange}
                                        handleInputChangeV2={this.props.handleInputChangeV2}
                                        handleRTEChange={this.props.handleRTEChange}
                                        callback={this.props.callback}
                                        handleAutoCompletechange={this.props.handleAutoCompletechange}
                                        updateValues={this.props.updateValues}
                                        handleDateChange={this.props.handleDateChange}
                                        componentvalue={componentValue}
                                        transactionPrimaryObject={this.props.transactionPrimaryObject}
                                        transactionValue={this.props.transactionPrimaryObject && this.props.transactionPrimaryObject[component.SubApplicationName] !== undefined ? this.props.transactionPrimaryObject[component.SubApplicationName][component.AttributeName] : undefined}
                                        pagelevelValues={this.props.pagelevelValues}
                                        handleHiddenInputChange={this.props.handleHiddenInputChange}
                                        onClick_ClearButton={this.props.onClick_ClearButton}
                                        invokeGetAccount={this.props.invokeGetAccount}
                                        updateValueWithSubplicationName={this.props.updateValueWithSubplicationName}
                                        invokeGetAccountAddress={this.props.invokeGetAccountAddress}
                                        useaccountaddress={this.props.useaccountaddress}
                                        customTable={this.props.customTable}
                                        customTableRowIndex={this.props.customTableRowIndex}
                                        handleRowLevelInputChange={this.props.handleRowLevelInputChange}
                                        invokeGetInsuredAddress={this.props.invokeGetInsuredAddress}
                                        useinsuredaddress={this.props.useinsuredaddress}
                                        disableCopyInsuredAddress={this.props.disableCopyInsuredAddress}
                                        isInsuredAddressAdded={this.props.isInsuredAddressAdded}
                                        checkCopyInsuredAddress={this.props.checkCopyInsuredAddress}
                                        componentProps={this.props.componentProps}
                                        componentState={this.props.componentState}
                                        tableFunctions={this.props.tableFunctions}
                                        TableControls={this.props.TableControls}
                                        functions={this.props.functions}
                                        stateList={this.props.stateList}
                                        handleVinDecode={this.props.handleVinDecode}
                                        enableEditOption={this.props.enableEditOption}
                                        staticReadonlyRequired={this.props.staticReadonlyRequired}
                                        responseList={this.props.responseList}
                                        responseLists = {this.props.responseLists}
                                        parentProps = {this.props.parentProps}
                                    />

                                </React.Fragment>
                            )
                        } else return null
                    })
                }
                {hasChildSubApp && dynamicRender(this.props.ChildSubApplicationList, this.props.componentProps, this.props.componentState, this.props.functions, this.props.TableControls, this.props.summarySubApp, this.props.detailSummaryList, '', this.props.refs || '', this.props.staticReadonlyRequired)}
            </React.Fragment>
            </Grid>
        )
    }
}

export default withTranslation()(DynamicComponent);
