import React from 'react';
import ReactDOM from 'react-dom';
import { format } from "date-fns";
import {  getParentState } from '../../../utils/Common/CommonUtils';
import { connect } from 'react-redux';
import { CardContent } from "@material-ui/core";
import { Grid, Button } from "@material-ui/core";
import DynamicComponent from '../../../corecomponents/DynamicComponent';
import { ValidatorForm } from "react-material-ui-form-validator";
import  cloneDeep  from "lodash/cloneDeep";
import { hideLoader, showLoader } from '../../../../../actions/UIFlowActions';
import { handleInputChangeV2 } from '../../../utils/Common/InputUtils';
import { constructAndInvokeMetadataCall } from '../../../utils/Common/MetaUtils';
import PageLoader from '../../../controls/PageLoader';

let SubAppNameList = []

class childComp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageMetadata : {},
            pagelevelValues : {},
            values : {},
            transactionPrimaryObject : {},
            subAppList : [],
            isSummaryNeeded : false,
            metadatacalled:false
        }
        this.childform = React.createRef();
    }

    componentDidMount(){
        console.log('mount props',this.props)
        let parentState = getParentState(this.props);
        let list = this.props.detailSummaryList
        let subAppName = this.props.subapplicationName
        SubAppNameList = list[subAppName]
        this.setState( prevState => {
            let values = {...this.props.rowData};
            let pagelevelValues = {}
            if(SubAppNameList && SubAppNameList.includes(",")){
                let SubAppList = SubAppNameList.split(",")
                SubAppList.forEach((element) => {
                    pagelevelValues[element] = this.props.rowData[element] || {}
                });
            }
            else{
                pagelevelValues = {[SubAppNameList]:{...this.props.rowData}};
            }
            return {values,transactionPrimaryObject : cloneDeep(pagelevelValues)
            ,pagelevelValues}
        },() => {
            this.getMetadataRequest(parentState,SubAppNameList);
        })
    }
    componentWillReceiveProps(nextprops){
        if(this.props.pageMetadata !== nextprops.pageMetadata){
            this.state.metadatacalled=true;
        }
        if(this.props.pagelevelValues !== nextprops.pagelevelValues){
            if(this.state.metadatacalled == true){
                let parentState = getParentState(this.props);
                this.getMetadataRequest(parentState,SubAppNameList,nextprops);
                this.state.metadatacalled = false;
            }
        }
    }


    executeScroll = () =>{
        ReactDOM.findDOMNode(this.childform.errors[0]).scrollIntoView({ behavior: 'smooth', block:'center' });
        if(false || !!document.documentMode){
          window.scrollBy(0,-120);
        }
    }

    updateValues = (obj) => {
        this.setState({
            ...this.state,
            ...obj
        })
    }


    UpdateState = (statename, value, rerender) => {
        if (rerender) {
          this.setState({
            [statename]: value,
          });
        } else {
          this.state[statename] = value;
        }
      };


    getMetadataRequest = async (state,childsubApp,props) => {
        // this.props.showLoader();
        // let objectId = 362833
        // let objectVerId = 361937
        // let transactionPrimaryObject ={}
        if(props === undefined || props === null){
            props = this.props;
        }
        let returnObj={}
        if(props['setEditPrivilege']){
            returnObj= await props['setEditPrivilege'](props,this.state.pagelevelValues)
        }
        props.UpdateState && props.UpdateState('loading',true,true)
        const { objectId, objectVerId } = props.pageInfo ? props.pageInfo : props;
        let parentPropsPagelevelValues = props.parentProps ? props.parentProps.pagelevelValues : {};
        let statePagelevelValues = props.state ? props.state.pagelevelValues : {};
        let pagelevelValues = Object.assign({},{...props.pagelevelValues,...parentPropsPagelevelValues, ...statePagelevelValues});
        let keyLength=Object.keys(returnObj)
        if(keyLength && keyLength.length>1){
            pagelevelValues[SubAppNameList]={...this.state.transactionPrimaryObject[SubAppNameList],...returnObj}
        }else{
            pagelevelValues[SubAppNameList] = this.state.transactionPrimaryObject[SubAppNameList];
        }
        let EffectiveDate = pagelevelValues && pagelevelValues.hasOwnProperty("PolicyInfo") ?  pagelevelValues.PolicyInfo.EffectiveDate : format(new Date(), 'yyyy-MM-dd');
        console.log("transaction")
        // const request = constructMetaDataRequestV1( { subappName : childsubApp, objectId, objectVerId,state  }, { transactionPrimaryObject:pagelevelValues } );
        // this.props.setMetadataRequest(JSON.stringify(request, null, 2));
        // this.getMetadata(request);
        // const response = await invokeMetadata(request);
        if(childsubApp){
          childsubApp =  childsubApp.includes(",") ? childsubApp.split(",") : childsubApp
        }
        await constructAndInvokeMetadataCall( { subappName : childsubApp, objectId, objectVerId, state }, { transactionPrimaryObject: pagelevelValues,EffectiveDate }, this.metadataAPI_callback )
        // this.props.setMetadataResponse(JSON.stringify(response, null, 2));
        // this.setState((prevState) => {
        //     let pageMetadata = JSON.parse(JSON.stringify(prevState.pageMetadata));
        //     pageMetadata = response;
        //     return { pageMetadata };
        // })
        // this.props.hideLoader();
        props.UpdateState && props.UpdateState('loading',false,true)
    }

    metadataAPI_callback = (request, response) => {
        // this.props.setMetadataRequest(JSON.stringify(request, null, 2));
        this.setState((prevState) => {
            let pageMetadata = JSON.parse(JSON.stringify(prevState.pageMetadata));
            pageMetadata = response;
            return { pageMetadata };
        });
        this.props.hideLoader();
    }
    handleInputChangeV2 = async (value, component, isDynamicTable, datatableRowindex,props) => {
        let list = this.props.detailSummaryList
        let subAppName = this.props.subapplicationName
        SubAppNameList = list[subAppName]
        let {objectId,objectVerId} = this.props && this.props.pageInfo
        let subAppInfo= {subappName: SubAppNameList, objectId, objectVerId}
        await handleInputChangeV2(value, component, this.props, this.state, this.functions,subAppInfo,{},true,props)
    }
    updateValueWithSubplicationName = (updatedValue, component) => {
        let SubapplicationName = component.SubApplicationName;
        let attributeName = component.AttributeName;
        let attributeValue = component.DefaultValue;
        this.setState((prevState) => {
          let temp = {
            label: component.ComponentLabel,
            value: attributeValue,
          };
          let values = Object.assign({}, prevState.values, updatedValue);
          let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
          let transactionPrimaryObject = Object.assign({}, prevState.transactionPrimaryObject);
          let savedValues = Object.assign({}, prevState.savedValues);
          savedValues[attributeName] = temp;
          if (!pagelevelValues[SubapplicationName]) {
            pagelevelValues[SubapplicationName] = {};
          }
          pagelevelValues[SubapplicationName] = Object.assign(
            {},
            prevState.pagelevelValues[SubapplicationName],
            updatedValue
          );
          transactionPrimaryObject[SubapplicationName] = Object.assign(
            {},
            prevState.transactionPrimaryObject[SubapplicationName],
            updatedValue
          );
          
          return { values, pagelevelValues, savedValues,transactionPrimaryObject };
        });
      }
    handleHiddenInputChange = (component, isDynamicTable, datatableRowindex) => {
    let attributeName = component.AttributeName;
    let attributeValue = component.AttributeValue || component.DefaultValue;
    if (component.SubApplicationAttributeValueList.length == 2) {
        attributeValue = component.SubApplicationAttributeValueList[1].Value;
    }
    this.setState(
        (prevState) => {
        let temp = {
            label: component.ComponentLabel,
            value: attributeValue,
        };
        let values = Object.assign({}, prevState.values); // creating copy of state variable jasper
        let savedValues = Object.assign({}, prevState.savedValues); // creating copy of state variable jasper
        values[attributeName] = attributeValue; // update the name property, assign a new value
        savedValues[attributeName] = temp;

        let { currentPageIndex, pageNavigation } = this.state;
        let subappName = component.SubApplicationName;
        let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
        //pagelevelValues[subappName][attributeName] = attributeValue;

        if (!pagelevelValues[subappName]) {
            pagelevelValues[subappName] = {};
        }
        pagelevelValues[subappName][attributeName] = attributeValue;

        return { values, savedValues, pagelevelValues }; // return new object jasper object
        }
    );
    };

    handleSubmit = (e,actionCallback) => {
        // e.preventDefault();
        // e.stopPropagation();
        if(this.state.proceedSaving!==undefined && this.state.proceedSaving===false){
            return;
        }else{
            ReactDOM.findDOMNode(this.props.shrink.current).scrollIntoView({ behavior: 'smooth', block: 'center' });
        let key = this.props.subapplicationName.toLowerCase() + "_dataList";
        actionCallback(key,this.props.subapplicationName,this.state.values,false,this.state.values.ID,this.props, this.state)
        }
    }
    callback = () => {
        let parentState = getParentState(this.props);
        this.getMetadataRequest(parentState,SubAppNameList);
    }

    functions = {
        UpdateState: this.UpdateState,
        updateStateValues :this.updateValues,
      };

    render(){
        
        let subapplicationList = [];

        if (this.state.pageMetadata.ApplicationDetail) {
            subapplicationList = this.state.pageMetadata.ApplicationDetail && this.state.pageMetadata.ApplicationDetail.SubApplicationDetailList;
        }


        return(
            <React.Fragment>
                <ValidatorForm
                    id ="child"
                    onSubmit={e => {
                        this.handleSubmit(e,this.props.tableUtils.onChildSave)}}
                    onError={e => this.executeScroll()}
                    ref={this.childform}
                >
                {
                    subapplicationList &&
                    subapplicationList.map(( subapplication, index ) => {
                        let attributeList = subapplication.AttributeDetailList;
                        return(
                            <React.Fragment key={index}>
                                <Grid item xs={11} sm={11} md={12} lg={12}>
                                    <CardContent className={`custom-margin-left ${subapplication.ApplicationName}__wrapperClass`}>
                                        <Grid container spacing={3}>
                                           {
                                           this.state.isSummaryNeeded === false ?
                                           <DynamicComponent
                                                components = { attributeList }
                                                {...this.props}
                                                callback={this.callback}
                                                refs={this.childform}
                                                // handleAutoCompletechange = { this.props.handleAutoCompletechange }
                                                handleHiddenInputChange = { this.handleHiddenInputChange }
                                                handleInputChangeV2 = { this.handleInputChangeV2 }
                                                // handleGoogleRegion = { this.props.handleGoogleRegion }
                                                updateValueWithSubplicationName = { this.updateValueWithSubplicationName }
                                                values = { this.state.values }
                                                pagelevelValues = { this.state.pagelevelValues }
                                                transactionPrimaryObject = { this.state.transactionPrimaryObject }
                                                functions= {this.functions}
                                            />
                                        :null
                                        }
                                        </Grid>

                                        {   this.props.IsSubmitButtonNeeded === 'Y' &&
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="nav-btn customSaveButton theme_button"
                                            style={styles.button}
                                            onClick={() => this.childform.current.submit()}
                                        >
                                            SAVE
                                        </Button>}

                                    </CardContent>
                                </Grid>
                            </React.Fragment>
                        )
                    })
                }
                {this.state.loading && <PageLoader />}
                </ValidatorForm>
            </React.Fragment>
        )
    }
}

const styles = {
    button: {
        float : 'right',
        marginTop : '15px',
        // marginRight : '0px !important'
    }
}

const mapStateToProps = state => (
    {
        metadataReducer: state.metadataReducer,
        UIFlowReducer: state.UIFlowReducer
    }
)

const mapDispatchToProps = (dispatch) => (
    {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        // // setMetadataRequest: (request) =>dispatch(setMetadataRequest(request)),
        // //setMetadataResponse: (response) => dispatch(setMetadataResponse(response)),
    }
);

export default connect( mapStateToProps ,mapDispatchToProps )( childComp );
