import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import CalendarValidator from './CalendarValidator';
import { format, isValid } from "date-fns";
import {getDateWithoutOffset, changeSOLDateformat} from '../utils/Common/DateUtils'
import { LOBUtil } from './../utils/UI_utils/CalenderUtil';
import { ValidatorForm } from 'react-material-ui-form-validator';

class CalendarV2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      calenderOpenClose: false
    }
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule('mindate', (value,mindate) => {
      if (value&&isValid(new Date(value))&&mindate&&isValid(new Date(mindate)))
      {
        let date1=new Date(value).toLocaleDateString("en-ZA")
        let date2=new Date(mindate).toLocaleDateString("en-ZA")
        if(new Date(date1) < new Date(date2) ){
          return false
        }
      }
       return true;
    });
    ValidatorForm.addValidationRule('maxdate', (value,maxdate) => {
      if (value&&isValid(new Date(value))&&maxdate&&isValid(new Date(maxdate)))
      {
        let date1=new Date(value).toLocaleDateString("en-ZA")
        let date2=new Date(maxdate).toLocaleDateString("en-ZA")
        if (new Date(date1) > new Date(date2)){
          return false
        }
      }
       return true;
    });
    ValidatorForm.addValidationRule("IsValidDate", (value) => {
      if (value&&!isValid(new Date(value))) {
        return false;
      }
      return true;
    });

  }


  handleCalenderOpenClose = () => {
    this.props.component.IsReadonly !== true && this.setState({ calenderOpenClose: !this.state.calenderOpenClose })
  }
  onBlur=(e,editable)=>{
    let datevalue =e.target.value;
    if((datevalue&&datevalue.includes('_')&&editable)||(datevalue===""&&editable)){
      let formattedDatevalue = isValid(new Date(datevalue)) ? format(getDateWithoutOffset(datevalue),this.props.component.OutputFormatPattern):datevalue;
        let value = Object.assign({}, { [this.props.component.AttributeName]: formattedDatevalue });
        this.props.handleInputChangeV2(value, this.props.component);
  }
  }
  onChange = (event, datevalue) => {
    if(datevalue !== null && datevalue !== undefined ){
      if(datevalue.includes('_')){
        let value = Object.assign({}, { [this.props.component.AttributeName]: datevalue });
        this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
      }
      else{
        let formattedDatevalue = isValid(new Date(datevalue)) ? format(getDateWithoutOffset(datevalue),this.props.component.OutputFormatPattern):datevalue;
        if (this.props.customTable)
        {
          let value = Object.assign({}, { [this.props.component.AttributeName]: formattedDatevalue });
          this.props.handleDateRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex);
        }
        else
        {
          let value = Object.assign({}, { [this.props.component.AttributeName]: formattedDatevalue });
          // this.props.handleDateChange(event, datevalue, this.props.component.AttributeName, this.props.component)
          let {component}=this.props
          if(component.OnChangeAction)
          this.props[component.OnChangeAction] && this.props[component.OnChangeAction](this.props,value)
          else
            this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
        }
      }
    }else if(datevalue === null){
      let value = Object.assign({}, { [this.props.component.AttributeName]: '' });
      this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex, this.props);
    }
  }

  isValidDate = (date) => {

    let dateformat = /^\d{4}-\d{2}-\d{2}$/;
    if(date.match(dateformat)){
      return true;
    }
    return false
  }

  render() {
    let { component, attributeValue, componentvalue } = this.props;
    let dateRange = {};
    let isReadOnly = component.IsReadonly;
    let isRequired = component.IsRequired === 'Y' ? true : false;
    const validations = [];
    const errorMessages = [];
    let {MaxValue,MinValue} =component
    let minDate,maxDate;
    let labelId = component.ComponentId + '_label';
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
    if (isRequired && !componentvalue) {
      validations.push('required');
      errorMessages.push(component.RequiredMessage);
    }
    if(!componentvalue && componentvalue===undefined){
      componentvalue = component.DefaultValue ? component.DefaultValue : componentvalue;
    }
    let LOB = this.props.UIFlowReducer.productDetails["LOBName"]
    if(this.isValidDate(component.MinValue) && this.isValidDate(component.MaxValue)){
      dateRange.minDate = changeSOLDateformat(component.MinValue)
      dateRange.maxDate = changeSOLDateformat(component.MaxValue)
    }
    if (LOB && LOBUtil[LOB] && LOBUtil[LOB][component.OnKeyUpAction]) {
      dateRange = LOBUtil[LOB][component.OnKeyUpAction](component, componentvalue)
    }
    if(MinValue&&MinValue!=='0'){
      validations.push('mindate:'+component.MinValue);
       errorMessages.push(component.ValidatorMessage);
      minDate=new Date(component.MinValue)
    }
    if(MaxValue&&MaxValue!=='0')
    {
      validations.push('maxdate:'+component.MaxValue);
       errorMessages.push(component.ValidatorMessage);
      maxDate=new Date(component.MaxValue)
    }
    if(!isValid(componentvalue)){
      errorMessages.push(component.Custom2);
    }
    validations.push('IsValidDate');

    return (
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} key={component.AttributeName} className={component.AdjacentLabel === "Y" ? "labelrightalignment": ""}>
        {component.AdjacentLabel === "Y"  && (!this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down text-font labelstyle" style={styles.label}>{component.ComponentLabel}{isRequired &&  <span >* </span>}</Typography>)}
          <CalendarValidator
            validators={validations}
            errorMessages={errorMessages}
            open={this.state.calenderOpenClose}
            onClose={() => this.handleCalenderOpenClose()}
            onClick={() => this.handleCalenderOpenClose()}
            format={component.InputFormatPattern}
            disabled={isReadOnly}
            component={component}
            styleClass={component.StyleClass}
            name={component.AttributeName}
            id={component.ComponentId}
            dateRange={dateRange}
            minDate={minDate}
            maxDate={maxDate}
            label={(component.AdjacentLabel !== "Y")&& (!this.props.isDynamicTable && <Typography id={labelId} className="custom-label-down text-font" style={styles.label}>{component.ComponentLabel}{isRequired &&  <span >* </span>}</Typography>)}
            placeholder={component.PlaceHolderText!=='' ? component.PlaceHolderText.replace(/&nbsp;/g, '') : component.ComponentLabel.replace(/&nbsp;/g, '')}
            value={componentvalue ? componentvalue : null}
            editable={true}
            autoComplete={autocomplete}
            onBlur={this.onBlur}
            onChange={(event, value) => { this.onChange(event, value) }}
          />
      </Grid>
    )
  }
}

const styles = {
  label: {
    width: '100%'
  }
};

const mapStateToProps = (state) => {
  return{
    metadataReducer : state.metadataReducer,
    UIFlowReducer: state.UIFlowReducer
  }
}

export default connect(mapStateToProps)(CalendarV2)
