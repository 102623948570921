import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RenderTemplate from '../../../../../template/RenderTemplate';
import { connect } from 'react-redux';

// temporary util
let utils = {
    tabOnChange: (event, value, setActiveTab) => {
        setActiveTab(value);
    }
}

function DynamicTab(props) {

    const [activeTab, setActiveTab] = useState(props.tabDetails[0].value);

    // mount methods - if needed
    useEffect( () => {
    }, [])

    const utilfunction = props.utilfunction && props.utilfunction.hasOwnProperty(props.templateName) ? props.utilfunction.templateName : utils;
    let { tabDetails = [] } = props;

    const onTabChange = (event, value) => {
        utilfunction['tabOnChange'](event, value, setActiveTab);
    }

    let activeIndex = tabDetails.findIndex( i => i.value === activeTab );

    let childTemplateName = tabDetails[activeIndex].hasOwnProperty("renderTemplateName") ? tabDetails[activeIndex].renderTemplateName : "";
    let childTemplate = props.templateInfo.hasOwnProperty(childTemplateName) ? props.templateInfo[childTemplateName] : [];

    return (
        <>
            <Box sx={{ width: '100%' }}>
                {/* <Typography>{activeTab}</Typography> */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs className={props.className} value = {activeTab} onChange = {onTabChange}  variant="fullWidth"  TabIndicatorProps={{style: { display: 'none' }}}>
                        {
                            tabDetails.map( (tabDetail) => {
                                return (<Tab 
                                    label = {tabDetail.label} 
                                    tabName = {tabDetail.name} 
                                    value={tabDetail.value}
                                    disabled={tabDetail.disabled}
                                />)
                            })
                        }
                    </Tabs>
                </Box>
            </Box>
            {
                childTemplate && <RenderTemplate {...props} Template={childTemplate} activeTab={activeTab} key={activeTab} />
            }
        </>
    );
}
const mapStateToProps = state => (
    {
        templateInfo: state.navigationReducer.templateInfo
    }
)
export default connect(mapStateToProps)(DynamicTab);