import * as React from 'react';
import { useRef } from 'react';
import Tooltip,{ tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { IconButton } from '@mui/material';
import container from '../utils/DIContainer';

const getTooltipStyles = (type, theme) => {
    const colors = {
      Error: 'red',
      Info: 'blue',
      Success: 'green',
      Warning: 'orange',
    };

    const color = colors[type] || theme.palette.text.primary;
    
    return {
        [`& .${tooltipClasses.arrow}`]: {
        color,
    },
        [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color,
        fontSize: '0.875rem',
        border: `1px solid ${color}`,
    },
    };
};

const BootstrapTooltip = styled(({ className, type,...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme,type }) => getTooltipStyles(type, theme));

export default function TooltipPopoverV2(props) {

  const buttonRef = useRef(null);
  let { component,defaultSize } = props;
  let { Action } = component;
  const Controls = container.get('componentControls');
  let IconChild = component.AttributeParams && Controls[component.AttributeParams.toLowerCase()];
  let Children = IconChild ? <IconChild fontSize={Action.size ? Action.size.toLowerCase() : 'default'} color={Action.color ? Action.color.toLowerCase() : 'default'}></IconChild> : component.ComponentLabel;
  const key = component.ResponseKey ? component.ResponseKey : '';
  let hideIconClass = '';
  let Value = [];

  const processedValue = (value, key) => {
    if (Array.isArray(value)) {
      if (value.every(item => typeof item === 'string')) {
        return value;
      } else if (value.every(item => typeof item === 'object' && item !== null)) {
        return value.flatMap(item => {
          if (item[key]) {
            return item[key].split(',').map(el => el.trim());
          }
          return [JSON.stringify(item)];
        });
      }
    }
    return [value.toString()];
  };


  if (props.customTable) {
    if (props.rowData.hasOwnProperty(component.AttributeName)) {
      const value = props.rowData[component.AttributeName];
      Value = processedValue(value, key);
    } else {
      hideIconClass = 'hide';
    }
  } else {
    Value = [component.DefaultValue.toString()];
  }

  return (
    <Grid item xs={12} sm={12} md={defaultSize || component.Size || 12} lg={defaultSize || component.Size || 12} key={component.AttributeName} className={`Tooltip_${component.AttributeStyleClass} ${hideIconClass}`}>
      <BootstrapTooltip
        type={Action.Type}
        title={
          <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }} className='small_options'>
            {Value.map((item, index) => (
              <li style={{ marginBottom: '2px' }} key={index}>{item}</li>
            ))}
          </ul>
        }
        placement={Action.tooltipPlacement || "right-start"}
        classes={{ popper: `${component.StyleClass}` }}
        arrow>
        <IconButton ref={buttonRef}>
          {Children}
        </IconButton>
      </BootstrapTooltip>
    </Grid>
  );
};

