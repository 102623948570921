import React, { useState, useEffect } from "react";
import { Grid, InputLabel, Typography } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormatCustom from './NumberFormatCustom';
import { renderIcon } from '../utils/LOB_Util/AU/VIN_Validator';
import { ToWords } from 'to-words';
const CurrencyV2 = (props) => {
  const [state, setState] = useState({
    // currencyValidation: false,
    value: "",
    valToWords: "",
  });


  useEffect(() => {
    if (props.component.DefaultValue !== "" && props.componentvalue === undefined) {
      props.updateValueWithSubplicationName({ [props.component.AttributeName]: props.component.DefaultValue }, props.component);
    }
    if (props.component.Action && props.component.Action.currencyInWords) {
      convertToWords(props.component.DefaultValue !== '' ? props.component.DefaultValue : props.componentvalue);
    }
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      return !value;
    });
  }, []);

  const convertToWords = (value) => {
    const toWords = new ToWords({
      localeCode: props.component.Action.currencyLang,
      converterOptions: {
        currency: true
      }
    });

    const words = value ? toWords.convert(value) : "";

    setState(prevState => ({
      ...prevState,
      value: value,
      valToWords: words
    }));

    return words;
  };

 const onChange = (e) => {
    e.target.value = e.target.value && e.target.value.trim() === "" ? "" : e.target.value;
    if (props.customTable) {
      let value = Object.assign({}, { [e.target.name]: e.target.value.replace(/,/g, "") });
      props.handleRowLevelInputChange(value, props.component, props.customTableRowIndex,props);
    }
    else {
      let value = Object.assign({}, { [e.target.name]: e.target.value.replace(/,/g, "") });
      props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
      let values = e.target.value;
      if (props.component.Action && props.component.Action.currencyInWords) {
        convertToWords(values);
      }
    }
  }


  const onKeyDown = (e) =>{
    if(props.component.OnKeyDownAction!=='' ){
      props[props.component.OnKeyDownAction] && props[props.component.OnKeyDownAction](props,e);
    }
    else {
      props.onKeyDown && props.onKeyDown(props,e);
     }
  }

  const onBlur =  (e) => {
    let {component}=props;
    component.OnBlurAction && props[component.OnBlurAction] &&  props[component.OnBlurAction](props,e);
  }

  const { component, pagelevelValues, variant } = props;
  const componentvalue = props.componentvalue;
  const isRequired = component.IsRequired === "Y";
  const isReadOnly = component.IsReadonly;
  const componentLabel = isRequired ? component.ComponentLabel + " *" : component.ComponentLabel;
  const validations = [];
  const errorMessages = [];
  let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
  let value = "";

  if (isRequired) {
    validations.push("IsEmpty");
    errorMessages.push(component.RequiredMessage);
  }
  if (component.MinValue > 0) {
    validations.push('minNumber:' + component.MinValue);
    errorMessages.push(component.ValidatorMessage);
  }
  if (component.MaxValue > 0) {
    validations.push('minNumber:' + component.MinValue);
    errorMessages.push(component.ValidatorMessage);
    validations.push('maxNumber:' + component.MaxValue);
    errorMessages.push(component.ValidatorMessage);
  }
//   if (state.currencyValidation) {
//     validations.push("isCurrency");
//     errorMessages.push("Invalid Format");
//   }
  if (component.ValidationRegex) {
    validations.push('matchRegexp:'+ component.ValidationRegex);
    errorMessages.push(component.ValidatorMessage);
  }

  if (component.DefaultValue === "" && component.AttributeValue === "" && componentvalue !== "" && componentvalue !== undefined) {
    value = componentvalue
  }
  if (component.DefaultValue !== "" && component.AttributeValue !== "" && componentvalue !== undefined) {
    value = componentvalue
  }
  if (component.DefaultValue !== "" && component.AttributeValue === "" && componentvalue === undefined) {
    value = component.DefaultValue
  }
  if (component.DefaultValue !== "" && component.AttributeValue !== "" && componentvalue === undefined) {
    value = "";
  }
  if (component.DefaultValue !== "" && component.AttributeValue === "" && componentvalue !== undefined) {
    value = componentvalue;
  }

  const styleObject = component.AdjacentLabel ==='Y'? {display:'flex'} : {}

    return (
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} className={component.AttributeStyleClass} style={styleObject} >
        { component.AdjacentLabel === 'Y' ?
          <>
          <Grid item xs={4} sm={4} md={4} lg={4}>
              <InputLabel htmlFor={component.AttributeName} className={`custom-label-down text-font adjacent_label ${component.AttributeStyleClass}`}>
                {componentLabel}
              </InputLabel>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} className="adjacent_TextValidator_Grid">
          <TextValidator
          name={component.AttributeName}
          placeholder={!props.isDynamicTable && component.ComponentLabel}
          value={value}
          defaultValue={value}
          autoComplete={autocomplete}
          className={`custom-input custom-dollar ${component.StyleClass} lineHeight`}
          onChange={(e) => onChange(e)}
          margin="normal"
          disabled={isReadOnly}
          validators={validations}
          onBlur={(e) => onBlur(e)}
          errorMessages={errorMessages}
          variant={variant}
          fullWidth
          onKeyDown={(e) => onKeyDown(e)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: <InputAdornment position="start">$ </InputAdornment>,
            endAdornment: renderIcon({ component, data : pagelevelValues ? pagelevelValues[component.SubApplicationName] : {} })
          }}
        />
         {state.valToWords && (
              <div className="CurrencyInWords">
                {state.valToWords}
              </div>
            )}
          </Grid>
          </> :
          <>
          <TextValidator
          name={component.AttributeName}
          placeholder={!props.isDynamicTable && component.ComponentLabel}
          label={
            component.AdjacentLabel !== "Y"  &&(!props.isDynamicTable && (
              <Typography className="custom-label-down small_label text-font small_label">
                {componentLabel}
              </Typography>
            ))
          }
          value={value}
          defaultValue={value}
          autoComplete={autocomplete}
          className={`custom-input custom-dollar ${component.StyleClass} lineHeight`}
          onChange={(e) => onChange(e)}
          margin="normal"
          disabled={isReadOnly}
          validators={validations}
          onBlur={(e) => onBlur(e)}
          errorMessages={errorMessages}
          variant={variant}
          fullWidth
          onKeyDown={(e) => onKeyDown(e)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: <InputAdornment position="start">$ </InputAdornment>,
            endAdornment: renderIcon({ component, data : pagelevelValues ? pagelevelValues[component.SubApplicationName] : {} })
          }}
        />
         {state.valToWords && (
              <div className="CurrencyInWords">
                {state.valToWords}
              </div>
            )}
        </>}
      </Grid>
    );
  }

export default CurrencyV2;
