import React, { useEffect, forwardRef,useState } from "react";
import { connect, useSelector } from "react-redux";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import {setUpdateExpandPanel, setexpandPanelDetailData} from "../../../../../actions/UIFlowActions"
import { pageLoadActions } from "../../../utils/Common/NoCodeUtils";
import dynamicRender from "../dynamicRender";
import { handleInputChangeV2 as handleInputChangeV2_Util} from "../../../utils/Common/InputUtils";
import { updateValueWithSubapplicationName } from "../../../utils/Common/CommonUtils";
import PageLoader from "../../../controls/PageLoader";

function ExpandPanelV5 (props) {

  const initialState = {
    pageMetadata: {}, summaryMetadata: {}, pagelevelValues: {}, transactionPrimaryObject: {}, loading: false
  }
  const [state, setState] = useState(initialState)
  const [expanded, setExpanded] = useState(false);
  const [expandIconNeeded, setExpandIconNeeded] = useState(true);
const { UIFlowReducer, navigationReducer } = useSelector((state) => state);
const {currentPageIndex } = navigationReducer;
const {updateExpandPanel, expandPanelDetailData} = UIFlowReducer
  
const UpdateState = (key, value, shouldRerender) => {
  if (shouldRerender) {
      setState((prevState) => (
          { ...prevState, [key]: value }));
  }
  else { state[key] = value; }
};

const updateValues = (params) => {
  setState((state)=> ({ ...state, ...params }));
}

const stopPropagation = (e) => e.stopPropagation();

const expandValues = {...expandPanelDetailData,PageName: navigationReducer.pageNavigation && navigationReducer.pageNavigation[currentPageIndex - 1]&&navigationReducer.pageNavigation[currentPageIndex - 1]["name"],
parentTabPosition: props.parentTabPosition,
isExpandPanel:'Yes'
}

const handleInputChangeV2 = async (value, component, isDynamicTable, datatableRowindex) => {
  let newValues = await handleInputChangeV2_Util(value, component, props, state, functions, state.pageSubAppInfo,{},true );
  updateValues(newValues);
}

const updateValueWithSubapplicationNames = (value, component) => {
  let values = updateValueWithSubapplicationName(value, component, state);
  updateValues(values)
}

const expandCondition = (event,expanded) => {
  if(props.pageInfo.hasOwnProperty('isExpandPanelDetail') && props.pageInfo.isExpandPanelDetail === 'Y' && !expandIconNeeded){
    stopPropagation(event)
  }else
  setExpanded(!expanded)
}

useEffect  (() => {
  async function callMetadata(){
    let { pageOnLoadActions = [] } = props.pageInfo;
    Object.assign(state.transactionPrimaryObject, expandValues)
    Object.assign(state.pagelevelValues, expandValues)
    let values = await pageLoadActions(pageOnLoadActions, props,state, functions);
    if(values.pageMetadata.ApplicationDetail && values.pageMetadata.ApplicationDetail.SubApplicationDetailList.length> 0) setExpanded(true)
    if(props.pageInfo.hasOwnProperty('isExpandPanelDetail') && props.pageInfo.isExpandPanelDetail === 'Y') setExpanded(true)
    if(props.pageInfo.hasOwnProperty('isExpandIconNeeded') && props.pageInfo.isExpandIconNeeded === 'N') setExpandIconNeeded(false)
    if(values && Object.keys(values).length > 0 ) updateValues(values);
    props.setUpdateExpandPanel(false)
  }

  if(updateExpandPanel)
  callMetadata()
},[updateExpandPanel]) 
  
    const ExpansionPanelSummary = withStyles({
      root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderTopLeftRadius:"9px",
        borderTopRightRadius:"9px",
        borderBottomLeftRadius:"9px",
        borderBottomRightRadius:"9px",
        marginBottom: -1,
        minHeight: 10,
        "&$expanded": {
          minHeight: 10,
        },
      },
      content: {
        "&$expanded": {
        },
      },
      expanded: { transform: 'rotate(360deg) !important',        borderBottomLeftRadius:"0px",
      borderBottomRightRadius:"0px",},
      expandIcon: {
        order: -1,
        transform: 'rotate(270deg)'
      },
    })(MuiExpansionPanelSummary);
    const functions = {
        UpdateState: UpdateState,
        handleInputChangeV2: handleInputChangeV2,
        updateValueWithSubplicationName: updateValueWithSubapplicationNames
      };
    let isExpandPanelDetail = false;
    if(props.pageInfo.hasOwnProperty('isExpandPanelDetail') && props.pageInfo.isExpandPanelDetail === 'Y'){
      isExpandPanelDetail = true;
    }
    let {pageMetadata, summaryMetadata} = state;
    let SubApplicationDetailList = pageMetadata && pageMetadata.ApplicationDetail && pageMetadata.ApplicationDetail.SubApplicationDetailList;
    let summarySubApplicationDetailList = summaryMetadata && summaryMetadata.ApplicationDetail && summaryMetadata.ApplicationDetail.SubApplicationDetailList
    return (
      <React.Fragment>
        {
          SubApplicationDetailList && SubApplicationDetailList.length > 0 ?
          <MuiExpansionPanel expanded={expanded} onClick={(e) => expandCondition(e,expanded)} style={{maxWidth:'100%'}} className={isExpandPanelDetail ? "custom-detailExpandPanel" : "custom-collapsibleExpandPanel"} ref={props.myForwardedRef}>
          <ExpansionPanelSummary className={isExpandPanelDetail ? "custom-ExpandPanelSummary" : ""} expandIcon={expandIconNeeded ? <ExpandMoreIcon></ExpandMoreIcon> : null }>
          <Grid container spacing={2}>
              {
                dynamicRender(
                  summarySubApplicationDetailList,
                    props,
                    state,
                    functions,
                    {},
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined
                  )}
                </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Grid container spacing={2}>
              {
                dynamicRender(
                  SubApplicationDetailList,
                    props,
                    state,
                    functions,
                    {},
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined
                  )
              } 
            </Grid>
          </ExpansionPanelDetails>
        </MuiExpansionPanel> : null
        }
        {
        state.loading && <PageLoader />
        }
      </React.Fragment>
    );
}


const mapStateToProps = (state) => ({
  UIFlowReducer: state.UIFlowReducer,
  premium: state.UIFlowReducer.premium,
  compositePremium: state.UIFlowReducer.compositePremium
});
const mapDispatchToProps = (dispatch) => {
  return {
    setexpandPanelDetailData: (value) => dispatch(setexpandPanelDetailData(value)),
    setUpdateExpandPanel: (value) => dispatch(setUpdateExpandPanel(value))
  };
};


const ConnectedComponent = connect(mapStateToProps,mapDispatchToProps)(ExpandPanelV5);

const ExpandComponentWFR = forwardRef((props, ref) => {
  return (
    <ConnectedComponent
      {...props}
      myForwardedRef={ref}
    />
  )
})

export default ExpandComponentWFR;