export const AUTHENTICATE = 'AUTHENTICATE';
export const GET_PRIVILEGES = 'GET_PRIVILEGES';
export const LOGOUT = 'LOGOUT';
export const SET_PAGENAME='SET_PAGENAME';
export const SET_PAGENAME_UPDATE='SET_PAGENAME_UPDATE';
export const RESET_APPLICATION_REDUCER = 'RESET_APPLICATION_REDUCER';
export const RESET_STORE = 'RESET_STORE';
export const RESET_SUBMISSION = 'RESET_SUBMISSION';
export const RESET_LOADER = 'RESET_LOADER';
export const SET_RIDERECT_TO = 'SET_RIDERECT_TO';
export const SET_SSOUSER_DATA = 'SET_SSOUSER_DATA';

export const GET_METADATA = 'GET_METADATA';
export const GET_ACCOUNT_ID = 'GET_ACCOUNT_ID';
export const GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO';
export const SET_ACCOUNT_NAME = 'SET_ACCOUNT_NAME';
export const SET_PRIMARY_STATE = 'SET_PRIMARY_STATE';
export const SET_GETAVAILABLEMARKET_CHECKED='SET_GETAVAILABLEMARKET_CHECKED';
export const SET_SUBMISSION_NUMBER='SET_SUBMISSION_NUMBER';
export const SET_OVERIDE_CLEARANCE_DATE='SET_OVERIDE_CLEARANCE_DATE';
export const SET_BLOCK_LOCATION_SAVE='SET_BLOCK_LOCATION_SAVE';
export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST';
export const SET_CARDOBJECT_INFO = 'SET_CARDOBJECT_INFO';
export const SET_RESPONSEROOT_NAME = 'SET_RESPONSEROOT_NAME';
export const SET_COLUMNINFO = 'SET_COLUMNINFO';

export const SET_NAVIGATION_INFO = "SET_NAVIGATION_INFO";
export const SET_TEMPLATE_INFO = "SET_TEMPLATE_INFO";
export const SET_METADATA_REQUEST = 'SET_METADATA_REQUEST';
export const SET_METADATA_RESPONSE = 'SET_METADATA_RESPONSE';
export const SET_RATING_REQUEST = 'SET_RATING_REQUEST';
export const SET_RATING_RESPONSE = 'SET_RATING_RESPONSE';
export const SET_STATE_METADATA_RESPONSE = 'SET_STATE_METADATA_RESPONSE';
export const SET_STATELIST_COUNT = 'SET_STATELIST_COUNT';
export const SET_STATE_STRING = 'SET_STATE_STRING';

export const SET_CURRENT_INDEX = 'SET_CURRENT_INDEX';
export const SET_PAGE_METADATA = 'SET_PAGE_METADATA';

export const SET_FIND_VIEW = 'SET_FIND_VIEW';
export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS';
export const SET_QUOTESUMMARY_VIEW = 'SET_QUOTESUMMARY_VIEW';
export const SET_QUOTESUMMARY_INDEX = 'SET_QUOTESUMMARY_INDEX';

export const INVOKE_FIND_API = 'INVOKE_FIND_API';
export const CLEAR_RESULT = 'CLEAR_RESULT';
export const INVOKE_FINDCONSOLE_METADATA_API = 'INVOKE_METADATA_API';
export const INVOKE_MODIFYCONSOLE_METADATA_API = 'INVOKE_MODIFYCONSOLE_METADATA_API';
export const INVOKE_DELETEACCOUNT_API = 'INVOKE_DELETEACCOUNT_API';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const ALERT_MESSAGE = 'ALERT_MESSAGE';
export const DISABLE_ALERT_MESSAGE = 'DISABLE_ALERT_MESSAGE';

export const SET_PAGE_NAVIGATION = 'SET_PAGE_NAVIGATION';
export const SET_NAVIGATION = 'SET_NAVIGATION';
export const SET_CURRENT_PAGEINDEX = "SET_CURRENT_PAGEINDEX";
export const SET_TAB_PAGEINDEX = "SET_TAB_PAGEINDEX";

export const SET_QUOTE_NUMBER = 'SET_QUOTE_NUMBER';
export const SET_PAGE_LEVEL_VALUES = 'SET_PAGE_LEVEL_VALUES';
export const SET_VALUES = 'SET_VALUES';
export const SET_SAVED_VALUES = 'SET_SAVED_VALUES';
export const SET_WORKFLOW_TYPE = 'SET_WORKFLOW_TYPE';
export const SET_QUOTE_STATUS = 'SET_QUOTE_STATUS';
export const SET_QUOTE_DETAILS = 'SET_QUOTE_DETAILS'
export const SET_HASLOCATION = 'SET_HASLOCATION'
export const SET_POLICY_DETAILS = 'SET_POLICY_DETAILS'
export const SET_QUOTE_ID = 'SET_QUOTE_ID';
export const SET_INSUREDID = 'SET_INSUREDID';
export const SET_QUOTECONSOLE_INFO = 'SET_QUOTECONSOLE_INFO';
export const SET_POLICYCONSOLE_INFO = 'SET_POLICYCONSOLE_INFO';
export const SET_INSUREDADDRESS = 'SET_INSUREDADDRESS';
export const SET_INSUREDNAME = 'SET_INSUREDNAME';
export const SET_AGENTID = 'SET_AGENTID';
export const SET_AGENCYID = 'SET_AGENCYID';
export const SET_AGENCYNUMBER = 'SET_AGENCYNUMBER'
export const SET_CONSOLE_INFO = 'SET_CONSOLE_INFO'
export const SET_SUBMISSION_DATA = 'SET_SUBMISSION_DATA';
export const SET_QUOTE_DATA = 'SET_QUOTE_DATA';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export const SET_INSURED_DATA = 'SET_INSURED_DATA';
export const SET_CREATESUBMISSION_RESPONSE = 'SET_CREATESUBMISSION_RESPONSE';
export const SET_GETQUOTE_RESPONSE = 'SET_GETQUOTE_RESPONSE';
export const SET_LOB = 'SET_LOB'
export const SET_UI_VALUES = 'SET_UI_VALUES'
export const SET_LOCATION_ID = 'SET_LOCATION_ID'
export const SET_LOCATION_ID_LIST = 'SET_LOCATION_ID_LIST'
export const SET_BUILDING_ID = 'SET_BUILDING_ID'
export const SET_BUILDING_ID_LIST = 'SET_BUILDING_ID_LIST'
export const SET_OCCUPANCY_ID = 'SET_OCCUPANCY_ID'
export const SET_OCCUPANCY_ID_LIST = 'SET_OCCUPANCY_ID_LIST'
export const SET_EXTERNAL_QUOTE_NUMBER = 'SET_EXTERNAL_QUOTE_NUMBER'
export const ADDICON_CDN = 'ADDICON_CDN'
export const GET_RESPONSE = 'GET_RESPONSE'
export const SET_UNDERWRITINGQUESTIONS_ID = 'SET_UNDERWRITINGQUESTIONS_ID'
export const SET_FOCUS = 'SET_FOCUS'
export const SET_POLICYCOVERAGE_ID = 'SET_POLICYCOVERAGE_ID'
export const EXPAND_CONDITION = 'EXPAND_CONDITION'
export const SET_STATELIST = 'SET_STATELIST'
export const SET_POLICY_STATELIST = 'SET_POLICY_STATELIST'
export const SET_QUOTE_ID_NUMBER = 'SET_QUOTE_ID_NUMBER';
export const SET_SHOWHIDE_CONDITION = 'SET_SHOWHIDE_CONDITION'
export const SET_GENERALLIABILITY_ID = 'SET_GENERALLIABILITY_ID'
export const IS_COVERAGE_SAVE = 'IS_COVERAGE_SAVE';
export const SET_CURRENTPAGE_INFO = 'SET_CURRENTPAGE_INFO';

/* Action Types for Store Transaction Data's & Request */

export const SET_TRANSACTION_REQUEST = 'SET_TRANSACTION_REQUEST';
export const SAVE_TRANSACTION_DATA = 'SAVE_TRANSACTION_DATA';


/* Action Types for Classification (Quick Rating Page) */

export const APPEND_CLASSIFICATION = 'APPEND_CLASSIFICATION';
export const REMOVE_CLASSIFICATION = 'REMOVE_CLASSIFICATION';
export const REINITIALIZE_CLASSIFICATION = 'REINITIALIZE_CLASSIFICATION';
export const MODIFY_CLASSIFICATION_ROW_DATA = 'MODIFY_CLASSIFICATION_ROW_DATA';


/* Action Types for AdditionalCoverage */

export const ADD_ADDITIONALCOVERAGE = 'ADD_ADDITIONALCOVERAGE';
export const EDIT_ADDITIONALCOVERAGE = 'EDIT_ADDITIONALCOVERAGE';
export const DELETE_ADDITIONALCOVERAGE = 'DELETE_ADDITIONALCOVERAGE';
export const SET_SAVEADDITIONALCOVERAGE_RESPONSE = 'SET_SAVEADDITIONALCOVERAGE_RESPONSE'
export const SET_ADDITIONALCOVERAGELIST_RESPONSE = 'SET_ADDITIONALCOVERAGELIST_RESPONSE'

/* Action Types for Losses (Full Rating Page)*/

export const INITIALIZE_LOSSES = 'INITIALIZE_LOSSES';
export const ADD_LOSSES = 'ADD_LOSSES';
export const UPDATE_LOSSES = 'UPDATE_LOSSES';


/* Action Types for LocationTable (Full Rating Page) */

export const LOAD_LOCATION_ROWDATA = 'LOAD_LOCATION_ROWDATA';
export const UPDATE_LOCATION_ROWDATA = 'UPDATE_LOCATION_ROWDATA';
export const SAVE_LOCATION_ROWDATA = 'SAVE_LOCATION_ROWDATA';

/* Action Types for Classification (Full Rating Page) */

export const ADD_CLASSIFICATION = 'ADD_CLASSIFICATION';
export const DELETE_CLASSIFICATION = 'DELETE_CLASSIFICATION';
export const UPDATE_CLASSIFICATION_ROW_DATA = 'UPDATE_CLASSIFICATION_ROW_DATA';

/* Action Types for Vehicle Detail (Full Rating Page) */
export const LOAD_VEHICLE_DETAIL = 'LOAD_VEHICLE_DETAIL';
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const UPDATE_VEHICLE_ROW_DATA = 'UPDATE_VEHICLE_ROW_DATA';
export const STOR_VEHICLE_VALUES = 'STOR_VEHICLE_VALUES';


export const PREMIUM_DETAILS = 'PREMIUM_DETAILS';
export const DYNAMIC_DATATABLE_ROW = 'DYNAMIC_DATATABLE_ROW';
export const LOAD_LOCATION_DATA = 'LOAD_LOCATION_DATA';

export const SET_PRODUCT_INFO = 'SET_PRODUCT_INFO';

/* Language Dictionary */
export const SET_IMAGE_BASE64_FORMAT = 'SET_IMAGE_BASE64_FORMAT';
export const SET_CLIENTINFO_KEY = 'SET_CLIENTINFO_KEY';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_LANGUAGE_CODE = 'SET_LANGUAGE_CODE';

/* Configuration Informations  */
export const SET_CONFIGINFO = 'SET_CONFIGINFO';

// Action for Submissiion Flag

export const SET_SUBMISSION_VIEW = 'SET_SUBMISSION_VIEW';
export const SOLARTISUIPAGENAVIGATIONSTATUS = 'SOLARTISUIPAGENAVIGATIONSTATUS'

/* Actions for FindConsole */
export const SET_FINDCONSOLE_INFO = 'SET_FINDCONSOLE_INFO';
export const SET_SUBMISSIONID = 'SET_SUBMISSIONID';
export const SET_ACCOUNTID = 'SET_ACCOUNTID';
export const SET_QUOTEID = 'SET_QUOTEID';
export const SET_POLICY_ID = 'SET_POLICY_ID';
export const SET_LINE_OF_BUSINESS = 'SET_LINE_OF_BUSINESS';
export const ENDORSEMENT_FLOW = 'ENDORSEMENT_FLOW';

export const SET_COMMERCIALPROPERTY_ID = "SET_COMMERCIALPROPERTY_ID";

/* Additional Coverage Visibility Flaf */
export const SET_ADDITIONALCOVERAGELISTVISIBILITY = 'SET_ADDITIONALCOVERAGELISTVISIBILITY';

/* Product Details*/
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';

export const SET_RATE_METADATA_UPDATE = 'SET_RATE_METADATA_UPDATE';
export const SET_FLOW_TYPE = 'SET_FLOW_TYPE';
export const SET_FEE_NAME = 'SET_FEE_NAME';
export const SET_TAX_NAME = 'SET_TAX_NAME';
export const SET_RATE_RENDER = 'SET_RATE_RENDER';
export const SET_PRODUCT_BROWSE_STATE_UPDATE = "SET_PRODUCT_BROWSE_STATE_UPDATE";
export const SET_RATE_INVOKED = 'SET_RATE_INVOKED';
export const SET_RENDER_ERROR = 'SET_RENDER_ERROR';
export const SET_ERROR_RENDER = 'SET_ERROR_RENDER';
export const IS_RATING_INVOKED = 'IS_RATING_INVOKED';

export const SET_APPLICATION_VALUES = "SET_APPLICATION_VALUES";
export const PREMIUM = 'PREMIUM';
export const PREMIUM_WITHOUTTFS = 'PREMIUM_WITHOUTTFS'
export const FORCE_CLEAR_GOOGLECOMPONENT='FORCE_CLEAR_GOOGLECOMPONENT';
export const SET_HOMESCREEN_IMAGES_PATH='SET_HOMESCREEN_IMAGES_PATH';
export const SET_FAVICON = 'SET_FAVICON';
export const SET_MINI_LOGO_PATH = 'SET_MINI_LOGO_PATH'
export const SET_SPINNER_LOGO_PATH = 'SET_SPINNER_LOGO_PATH'
export const SET_COLOR_PALETTE = 'SET_COLOR_PALETTE'
//cl reducer
export const SET_BUILDING_PREMIUM = "SET_BUILDING_PREMIUM";
export const SET_ENABLE_RATE = "SET_ENABLE_RATE";

// sso
export const SET_FROM_SSO = 'SET_FROM_SSO';

// User Info
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USERROLE = 'SET_USERROLE';
export const SET_METADATA_PRIVILAGE='SET_METADATA_PRIVILAGE';
//MultiFlow

export const SET_SELECTED_LOB = "SET_SELECTED_LOB";
export const SET_RATED_LOB ="SET_RATED_LOB";
export const SET_ACTIVE_LOB = "SET_ACTIVE_LOB";
export const SET_SUBMISSION_STATE = "SET_SUBMISSION_STATE";
export const CHANGE_LOB_STATE_VALUE = "CHANGE_LOB_STATE_VALUE";
export const SET_PREVIOUSLOB_INFO = "SET_PREVIOUSLOB_INFO";

//Import / Export
export const TRIGGER_IMPORT = 'TRIGGER_IMPORT';
export const TRIGGER_EXPORT = 'TRIGGER_EXPORT';
export const IMPORT_VERSION = 'IMPORT_VERSION';
export const GET_INSURED_DATA="GET_INSURED_DATA";
export const FINDINSURED_CLICK="FINDINSURED_CLICK";

// quote Summary
export const QUOTE_SUMMARY_NAV_INDEX = 'QUOTE_SUMMARY_NAV_INDEX';

//Policy Summary
export const POLICY_SUMMARY_NAV_INDEX = 'POLICY_SUMMARY_NAV_INDEX';

export const SET_POLICY_DATES = 'SET_POLICY_DATES';

// State Detail
export const INVOKE_GET_STATE_LIST = 'INVOKE_GET_STATE_LIST';
export const INVOKE_GET_VEHICLE_LIST = 'INVOKE_GET_VEHICLE_LIST';

//AgencyAgentInsuredInfo

export const GET_AGENCY_DETAILS = 'GET_AGENCY_DETAILS';
export const GET_AGENT_DETAILS = 'GET_AGENT_DETAILS';
export const SET_HASUNDERWRITINGDETAILS = 'SET_HASUNDERWRITINGDETAILS';
export const SET_FORMVALIDATIONINDEX_INC = 'SET_FORMVALIDATIONINDEX_INC';
export const SET_FORMVALIDATIONINDEX_DEC = 'SET_FORMVALIDATIONINDEX_DEC';

// New Insured Error Message
export const SHOW_EMPTY_INSURED_MSG = 'SHOW_EMPTY_INSURED_MSG'
export const SET_LOCATION_DETAILS='SET_LOCATION_DETAILS'
export const SET_USERNAME_INFO='SET_USERNAME_INFO'
export const SET_ROUTE_INFO = 'SET_ROUTE_INFO'
export const SET_EXPANDPANEL_DATA='SET_EXPANDPANEL_DATA'
export const SET_EXPANDPANELDETAIL_DATA='SET_EXPANDPANELDETAIL_DATA'
export const UPDATE_EXPANDPANEL = 'UPDATE_EXPANDPANEL'

//Verisk Logo
export const THIRD_PARTY_LOGO="THIRD_PARTY_LOGO"
//HomePage Logo
export const HOMEPAGE_LOGOS='HOMEPAGE_LOGOS'
//MPL POLICY Date
export const SET_POLICY_DATE='SET_POLICY_DATE'


//PL RATE INDICATION
export const SET_FLOW='SET_FLOW'
export const OWNER_RESOURCE = 'OWNER_RESOURCE';

//Submissiontype
export const SET_SUBMISSION_TYPE = "SET_SUBMISSION_TYPE";
export const SET_PROCESS_STATUS='SET_PROCESS_STATUS';
export const SET_SUBMISSION_STATUS = "SET_SUBMISSION_STATUS"
export const SET_PAGEHEADER_UPDATE = 'SET_PAGEHEADER_UPDATE';
export const SET_ADJUSTCOVERAGE = 'SET_ADJUSTCOVERAGE';
export const SET_VALIDATION_DATA = 'SET_VALIDATION_DATA';
export const UPDATE_VALIDATIONS = 'UPDATE_VALIDATIONS';
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';

// External Info
export const SET_EXTERNAL_INFO = 'SET_EXTERNAL_INFO'
export const SET_NAVIGATIONCRITERIA_INFO = 'SET_NAVIGATIONCRITERIA_INFO'

//routes Info
export const SET_ROUTE_NAME = 'SET_ROUTE_NAME';

export const SET_LANDING_PAGE = 'SET_LANDING_PAGE'
export const SET_MODIFY_QUOTE = 'SET_MODIFY_QUOTE'