import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import CalendarValidator from './CalendarValidator';
import { isValid } from "date-fns";
import InputAdornment from '@material-ui/core/InputAdornment';
import { ValidatorForm , TextValidator } from 'react-material-ui-form-validator';
import EventIcon from '@mui/icons-material/Event';
import { changeSOLDateformat, dateFormatter, getDateFromStringValue } from '../utils/Common/DateUtils';

const Calendar =(props)=> {
  const [calenderOpenClose,setCalenderOpenClose]=useState(false);
  const [formattedValueBoolean,setFormattedValueBoolean]=useState(false);
  const [isNotValid,setIsNotValid]=useState(false);
  const prevProps = useRef(props);
  
  useEffect (() => {
    ValidatorForm.addValidationRule('mindate', (value,mindate) => {
      if (value&&isValid(new Date(value))&&mindate&&isValid(new Date(mindate)))
      {
        let date1=new Date(value).toLocaleDateString("en-ZA")
        let date2=new Date(mindate).toLocaleDateString("en-ZA")
        if(new Date(date1) < new Date(date2) ){
          return false
        }
      }
       return true;
    });
    ValidatorForm.addValidationRule('maxdate', (value,maxdate) => {
      if (value&&isValid(new Date(value))&&maxdate&&isValid(new Date(maxdate)))
      {
        let date1=new Date(value).toLocaleDateString("en-ZA")
        let date2=new Date(maxdate).toLocaleDateString("en-ZA")
        if (new Date(date1) > new Date(date2)){
          return false
        }
      }
       return true;
    });
    ValidatorForm.addValidationRule("IsValidDate", (value) => {
      if (value&&!isValid(new Date(value))) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("IsDateInRange", () => { return false;});
    if(props.component.DefaultValue !== ""){
        if(Date.parse(props.component.DefaultValue)){
            convertDefaultValue();
        }else{
        convertDefaultValueFromString();
      }
    }
  },[])

  useEffect(() =>{
    if ( props.component.DefaultValue !== '' && ( props.component.DefaultValue !== prevProps.current.component.DefaultValue || props.componentvalue === undefined )) {
      if (Date.parse(props.component.DefaultValue)) {
        convertDefaultValue();
      } else {
        convertDefaultValueFromString();
      }
    }
    prevProps.current = props;
  }, [props.component.DefaultValue, props.componentvalue]);

  const handleCalenderOpenClose = () => {
    props.component.IsReadonly !== true && setCalenderOpenClose(prev=>!prev)
  }
  const convertDefaultValueFromString = () => {
    let {component}=props;
    let {originalDateValue:originalDate,formattedMaxDate,formattedMinDate} = getDateFromStringValue(component,props.component.DefaultValue);
    let value = Object.assign({}, { [props.component.AttributeName]: props.component.DefaultValue });
    let d1,d2,d3;
    if(Number(component.MaxValue) && Number(component.MinValue)){
      let maxdate=formattedMaxDate,mindate=formattedMinDate,dateV=originalDate;
      d1=new Date(mindate);
      d2=new Date(maxdate);
      d3=new Date(dateV);
      if(!(d3 >= d1 && d3 <= d2) && !isNotValid){
        setIsNotValid(true);
      }else if(isNotValid){
        setIsNotValid(false)
      }
    }
    setFormattedValueBoolean(true);
    props.updateValueWithSubplicationName(value, props.component);
    return;
  }
  const convertDefaultValue = () =>{
    let formatPattern=props.component.InputFormatPattern.toLowerCase();
    let {inputSplitter}=props.component.Action;
    let regexConstructor='yyyy-mm-dd',valueArray=props.component.DefaultValue.split(inputSplitter),component=props.component;
    formatPattern.split(inputSplitter).forEach((el,i)=>{
        let regex=new RegExp(`${el.toLowerCase()}+`);
        regexConstructor=regexConstructor.replace(regex,valueArray[i]);
    })
    let regex = /(\d{2})\/(\d{2})\/(\d{4})/;
    const outputString = dateFormatter(props.component,props.component.DefaultValue);
    setFormattedValueBoolean(true);
    let d1,d2,d3;
    if(Number(component.MaxValue) && Number(component.MinValue)){
    let formattedMaxDate = component.MaxValue.replace(regex, "$3-$1-$2"),formattedMinDate = component.MinValue.replace(regex, "$3-$1-$2"),maxdate=formattedMaxDate,mindate=formattedMinDate,dateV=regexConstructor;
    d1=new Date(mindate);
    d2=new Date(maxdate);
    d3=new Date(dateV);
    if(!(d3 >= d1 && d3 <= d2) && !isNotValid){
      setIsNotValid(true);
    }else if(isNotValid){
      setIsNotValid(false)
    }
    }
    let value = Object.assign({}, { [props.component.AttributeName]: outputString });
    props.updateValueWithSubplicationName(value, props.component);
    return;
  }
  const onChange = (event, datevalue) => {
    if(datevalue !== null && datevalue !== undefined ){
      if(props.component.Action && Object.keys(props.component.Action).length > 0 && isValid(new Date(datevalue))){
        const outputString = dateFormatter(props.component,datevalue);
        let value = Object.assign({}, { [props.component.AttributeName]: outputString });
        setFormattedValueBoolean(true);
        props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
      }
    }else if(datevalue === null){
      let value = Object.assign({}, { [props.component.AttributeName]: '' });
      props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
    }
  }
  const changeComponent = () => {
    setCalenderOpenClose(true);
    setFormattedValueBoolean(false)
    let value = Object.assign({}, { [props.component.AttributeName]: '' });
    props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
  }
  const isValidDate = (date) => {

    let dateformat = /^\d{4}-\d{2}-\d{2}$/;
    if(date.match(dateformat)){
      return true;
    }
    return false
  }

    let { component, componentvalue } = props;
    let dateRange = {};
    const validations = [];
    const errorMessages = [];
    let isReadOnly = component.IsReadonly;
    let {MaxValue,MinValue} =component;
    let isRequired = component.IsRequired === 'Y' ? true : false;
    let labelId = component.ComponentId + '_label',minDate=new Date(component.MinValue),maxDate=new Date(component.MaxValue)
    let autocomplete="off";
    if(isNotValid){
      validations.push('IsDateInRange');
      errorMessages.push(component.ValidatorMessage)
    }
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
    if (isRequired && !componentvalue) {
      validations.push('required');
      errorMessages.push(component.RequiredMessage);
    }
    if(!componentvalue){
      componentvalue = component.DefaultValue ? component.DefaultValue : componentvalue;
    }
    if(isValidDate(component.MinValue) && isValidDate(component.MaxValue)){
        dateRange.minDate = changeSOLDateformat(component.MinValue)
        dateRange.maxDate = changeSOLDateformat(component.MaxValue)
      }
    if(MinValue&&MinValue!=='0'){
        validations.push('mindate:'+component.MinValue);
        minDate=new Date(component.MinValue)
      }
    if(MaxValue&&MaxValue!=='0')
        {
          validations.push('maxdate:'+component.MaxValue);
          maxDate=new Date(component.MaxValue)
        }
    return (
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} key={component.AttributeName}>
          {formattedValueBoolean === true ?
           <TextValidator
           name={component.AttributeName}
           label={
               <Typography className="custom-label-down text-font">
                 {component.componentLabel}
               </Typography>
           }
           value={componentvalue}
           validators={validations}
           errorMessages={errorMessages}
           className={`custom-input ${component.StyleClass} lineHeight calendar-disabled`}
           disabled={true}
           fullWidth
           InputProps={{
             endAdornment: <InputAdornment position="end"><EventIcon onClick={changeComponent} /></InputAdornment>
           }}
           autoComplete={autocomplete}
           /> : <CalendarValidator
            validators={validations}
            errorMessages={errorMessages}
            open={calenderOpenClose}
            onClose={() => handleCalenderOpenClose()}
            onClick={() => handleCalenderOpenClose()}
            changeComponent={changeComponent}
            format={"MM/dd/yyyy"}
            disabled={isReadOnly}
            component={component}
            styleClass={component.StyleClass}
            minDate={minDate}
            maxDate={maxDate}
            name={component.AttributeName}
            id={component.ComponentId}
            dateRange={dateRange}
            autoComplete={autocomplete}
            label={component.IsRequired === 'Y' ? !props.isDynamicTable && <Typography id={labelId} className="custom-label-down text-font" style={styles.label}>{component.ComponentLabel}*</Typography> :
            !props.isDynamicTable && <Typography id={labelId} className="custom-label-down text-font" style={styles.label}>{component.ComponentLabel}</Typography>}
            placeholder={component.ComponentLabel.replace(/&nbsp;/g, '')}
            value={componentvalue ? componentvalue : null}
            onChange={(event, value) => { onChange(event, value) }}
            editable={false}
          />
  }
      </Grid>
    )
  }

const styles = {
  label: {
    width: '100%'
  }
};

const mapStateToProps = (state) => {
  return{
    metadataReducer : state.metadataReducer,
    UIFlowReducer: state.UIFlowReducer
  }
}

export default connect(mapStateToProps)(Calendar)
