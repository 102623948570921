import React, { Component } from 'react';
import {  Grid, InputLabel } from "@material-ui/core";

class OutputLabel extends Component {
    constructor(props) {
        super(props);
        this.labelRef = React.createRef();
      }



      render() {
        let { component, isDynamicTable , defaultSize} = this.props;

        let isRequired = component.IsRequired === "Y" ? true : false;

        let labelClassName = isRequired === true ? "mandatory attributeLabel" : "attributeLabel";

        let labelId = component.ComponentId + "_label";

        return (
          <Grid
            className={component.StyleClass}
            style={styles.selectContainer}
            item
            xs={12}
            sm={12}
            md={defaultSize || (isDynamicTable ? 12 : component.Size)}
            lg={defaultSize || (isDynamicTable ? 12 : component.Size)}
            key={component.AttributeName}
          >
              {!isDynamicTable && (
                <InputLabel
                  ref={this.labelRef}
                  id={labelId}
                  className={`${labelClassName+" "+component.AttributeStyleClass} small_label`}
                  style={styles.label}
                >
                  <span dangerouslySetInnerHTML={{ __html: component.ComponentLabel }}></span>
                </InputLabel>
              )}

          </Grid>
        );
      }
    }

    const styles = {
      selectContainer: {
        alignSelf: "flex-end"
      },
      label:{
          margin:"0px"
      }
}

export default OutputLabel;
