import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import PageLoader from '../../../controls/PageLoader';
import store from '../../../../../store';
import appActions from '../../../../../actions';
import {Popper, Paper, ClickAwayListener } from '@material-ui/core';
import RenderTemplate from '../../../../../template/RenderTemplate';
import { CloseOutlined } from '@material-ui/icons';
const {actionType} = appActions
class Main extends Component {
    constructor(props){
        super(props);
        this.state = {
          anchorEl:null
        }
        this.mainRef = React.createRef();
    }
    handleClickAway = (e) => {
      if(e.target.localName === 'body'){
        return
      }
      store.dispatch({type: actionType.SET_UI_VALUES, payload: {showOfficeDetails: false}})
    }

    render(){
        const reduxStore = store.getState();
        let templateName=this.props.pageInfo && this.props.pageInfo.template
        let renderTemplate = this.props.templateInfo && this.props.templateInfo[templateName];
        const {UIFlowReducer,authReducer} = reduxStore
        const {showOfficeDetails}  = UIFlowReducer
        let {headerContentNeeded = false} = this.props.pageInfo
        // const routeName = this.props.location.pathname.slice(1)
        // const templateName = this.props.navigationReducer.navigation.pageNavigation[routeName].hasOwnProperty("template") ? this.props.navigationReducer.navigation.pageNavigation[routeName].template : null;

        return (
            <main className="main" style={styles.main} ref={this.mainRef}>
               {headerContentNeeded && templateName &&
              <div className='officeMappingMainContent' style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    width:"100%",
                    height:"auto"
                  }}>
                    <Popper
                    className='officeMappingMainPopper'
                    style={{
                      width:"100%",
                      height:"auto"
                    }}
                      open={showOfficeDetails}
                      anchorEl={this.mainRef}
                      disablePortal
                    >
                        <ClickAwayListener onClickAway={this.handleClickAway}>
                          <Paper elevation={3} style={{ padding: 16 }}>
                            <div style={{display:"flex",justifyContent:"flex-end",marginRight:"50px",marginTop:"15px"}}>
                          <CloseOutlined style={{fontSize: "30px",cursor:"pointer"}} onClick={this.handleClickAway}/>
                          </div>
                          {<RenderTemplate {...this.props} Template={renderTemplate} />}
                          </Paper>
                        </ClickAwayListener>
                    </Popper>
              </div>
              }
              <div className="relative-container-wrapper">
                <Container fluid>
                  {
                    this.props.children.length > 0 ?
                    (<React.Fragment>{this.props.children}
                      <Redirect from="/" to={this.props.location.pathname ==='/' ? "/home" : this.props.location.pathname } />
                    </React.Fragment>) :
                    (<Suspense fallback={<PageLoader />}>
                      <div>No routes available.</div>
                    </Suspense>)
                  }
                </Container>
               </div>
            </main>
        )
    }
}

const styles = {
    main: {
      backgroundColor: "#fff",
      position: "relative"
    },
};

const mapStateToProps = (state) => {
    return {
        metadataReducer: state.metadataReducer,
        UIFlowReducer: state.UIFlowReducer
    };
};

export default connect(mapStateToProps)(Main);
