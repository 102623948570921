import * as actionTypes from "../actions/types";

export const setCurrentPageIndex = (currentPageIndex) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT_PAGEINDEX,
    payload: currentPageIndex,
  });
};
export const setFindConsoleInfo = (selectedViewInfo) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FINDCONSOLE_INFO,
    payload: selectedViewInfo,
  });
};
export const setQuoteConsoleInfo = (selectedViewInfo) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_QUOTECONSOLE_INFO,
    payload: selectedViewInfo,
  });
};
export const setPolicyConsoleInfo = (selectedViewInfo) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_POLICYCONSOLE_INFO,
    payload: selectedViewInfo,
  });
};
export const setSubmissionID = (ID) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SUBMISSIONID,
    payload: ID,
  });
};
export const setAccountID = (ID) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ACCOUNTID,
    payload: ID,
  });
};
export const setQuoteID = (ID) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_QUOTE_ID,
    payload: ID,
  });
};
export const setPolicyID = (ID) => dispatch => {
    dispatch({
        type : actionTypes.SET_POLICY_ID,
        payload : ID
    })
}
export const setInsuredID = (ID) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_INSUREDID,
    payload: ID,
  });
};
export const setAgentID = (ID) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_AGENTID,
    payload: ID,
  });
};
export const setUserRole = (role) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USERROLE,
    payload: role,
  });
};
export const setAgencyID = (ID) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_AGENCYID,
    payload: ID,
  });
};
export const showLoader = () => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_LOADER,
  });
};

export const hideLoader = () => (dispatch) => {
  dispatch({
    type: actionTypes.HIDE_LOADER,
  });
};
export const setSubmissionNumber = (number) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SUBMISSION_NUMBER,
    payload: number,
  });
};
export const setBlockLocationSave = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BLOCK_LOCATION_SAVE,
    payload: value,
  });
};
export const setConsoleInfo = (selectedViewInfo) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CONSOLE_INFO,
    payload: selectedViewInfo,
  });
};
export const setCurrentPageInfo = (selectedViewInfo) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENTPAGE_INFO,
    payload: selectedViewInfo,
  });
};
export const setInsuredFullName = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_INSUREDNAME,
    payload: value,
  });
};
export const setSelectedLOB = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_LOB,
    payload: value,
  });
};
export const setSelectedProducts = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_PRODUCTS,
    payload: value,
  });
};
export const setInsuredAddress = (values) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_INSUREDADDRESS,
    payload: values,
  });
};
export const solartisUIPageNavigationStatus = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.SOLARTISUIPAGENAVIGATIONSTATUS,
    payload: value,
  });
};
export const setQuoteSummaryNavIndex = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.QUOTE_SUMMARY_NAV_INDEX,
    currentIndex: value,
  });
};
export const setRateRender = (flag) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_RATE_RENDER,
    payload: flag,
  });
};
export const setRateInvoked = (flag) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_RATE_INVOKED,
    payload: flag,
  });
};
export const setIsProductInvoked = (flag) => (dispatch) => {
  dispatch({
    type: actionTypes.IS_RATING_INVOKED,
    payload: flag,
  });
};
export const setProductBrowseStateUpdate = (obj) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PRODUCT_BROWSE_STATE_UPDATE,
    payload: obj,
  });
};
export const triggerExport = flag => dispatch =>{
    dispatch({
        type: actionTypes.TRIGGER_EXPORT,
        payload: flag
    })
}
export const triggerImport = flag => dispatch => {
    dispatch({
        type: actionTypes.TRIGGER_IMPORT,
        payload: flag
    })
}


export const saveLocationRowData = (value) => dispatch => {
  dispatch({
      type : actionTypes.SAVE_LOCATION_ROWDATA,
      payload : value
  })
}
export const setQuoteSummaryView = (index, view, workflowType, subApplicationName, ObjectId, ObjectVerId) => dispatch => {
    dispatch({
        type: actionTypes.SET_QUOTESUMMARY_VIEW,
        payload :  {index, view, workflowType, subApplicationName, ObjectId, ObjectVerId}
    });
  }
  export const setWorkflowType = (workflowType) => dispatch => {
    dispatch({
        type: actionTypes.SET_QUOTESUMMARY_VIEW,
        payload :  {workflowType: workflowType}
    });
  }
  export const setProductDetails = (productdetails) => dispatch => {
    dispatch({
        type: actionTypes.SET_PRODUCT_DETAILS,
        payload: productdetails
    })
}
export const resetLoadingCounter = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_LOADER
  });
};
export const setQuoteDetails = (quoteDetails) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_QUOTE_DETAILS,
    payload: quoteDetails,
  });
};

export const setOverideClearanceDate = (time) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_OVERIDE_CLEARANCE_DATE,
    payload: time,
  });
};

export const setPageheaderUpdate = data => dispatch => {
  dispatch({
      type: actionTypes.SET_PAGEHEADER_UPDATE,
      payload: data
  })
}
export const setPageNameUpdate = data => dispatch =>{
  dispatch({
    type: actionTypes.SET_PAGENAME_UPDATE,
    payload: data
})
}

export const setExternalInfo = data => dispatch => {
  dispatch({
    type: actionTypes.SET_EXTERNAL_INFO,
    payload: data
  })
}

export const setUpdateExpandPanel = flag => dispatch => {
  dispatch({
      type: actionTypes.UPDATE_EXPANDPANEL,
      payload: flag
  })
}

export const setexpandPanelDetailData = flag => dispatch => {
  dispatch({
      type: actionTypes.SET_EXPANDPANELDETAIL_DATA,
      payload: flag
  })
}

export const setLandingPage = page => dispatch => {
  dispatch({
      type: actionTypes.SET_LANDING_PAGE,
      payload: page
  })
}
