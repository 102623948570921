import React,{Component} from "react";
class OtherDocumentation extends Component {
    constructor(props) {
        super(props);
      }
    render(){
    return(
        <form className="document-form">
         
            <h6 className="document-heading theme_collapsiblecolor ">{this.props.pageName}</h6>
            <div className="document-wrap">
                   {this.props.children}
            </div>
            </form>
    )
    }
}
export default OtherDocumentation