import { Grid } from '@material-ui/core';
import React from 'react';
import container from '../utils/DIContainer';


function Overlay (props) {

    const renderContent = (UIComponent, component)=>{

        return(
      <Grid
        className={props.component.StyleClass+ ' lineHeight'}
        style={{display: "flex"}}
        item
        xs={12}
        sm={12}
        md={props.component.Size}
        lg={props.component.Size}
        key={props.component.AttributeName}
      >
        <Grid className={`customOverlayWrapper ${props.component.AttributeStyleClass}`}>
          <div className={`customOverlayContent ${props.component.IsReadonly ? 'disable': ''}`}>
            <UIComponent
                {...props}
                component={props.component}
            >
            </UIComponent>
          </div>
          <div className={`customOverlayPanel ${props.component.IsReadonly ? 'displayNone': ''}`}>
            {props.children}
          </div>
        </Grid>
      </Grid>
    )
    }
    const Controls = container.get('componentControls');
    const UIComponent = Controls[props.component.ChildComponent.toLowerCase()];
    return (
        renderContent(UIComponent,props.component)
    )
  }

export default React.memo(Overlay)
