import store from '../../../../store'
import appActions from '../../../../actions/index';
import { getServiceRequestDetail, LocationandBuildingAxios,GetCommonEventName } from './API';
import { format, subDays } from 'date-fns'
import { Toast, triggerToast } from './HelperUtils';
import { constructAndInvokeMetadataCall,attributeSplit, deleteDependentAttribute } from './MetaUtils';
import { handleInputChangeV2,handleInputChangeV3 } from './InputUtils';

const { actionType } = appActions

export const validateState = (component, value, componentProps) => {
    let props = componentProps.state
    let newState = true
    let primaryState
    let keys = Object.keys(value);
    let attributeName = keys[0];
    let chosenState = value[attributeName].toString()
    for (let i = 0; i < props.metadataReducer.stateList.length; i++) {
        if(props.metadataReducer.stateList[i].State === chosenState){
            newState = false
        }
        else{
            newState = true
        }
        if(props.metadataReducer.stateList[i].SolartisIsPrimaryState === "Yes"){
            primaryState = props.metadataReducer.stateList[i].State
        }
    }
    if(newState || chosenState === primaryState){
        store.dispatch({ type: actionType.SET_PRIMARY_STATE, payload: chosenState})
        componentProps.handleInputChangeV2(value, component, false, null, props.callback, false);
    }
    else{
        triggerToast('The Chosen State already exists. Kindly choose a different state.', { position: Toast.POSITION.TOP_CENTER })
    }
}
export const handleOnChangeN = (component, value, props) => {
    let date = new Date()
    let val=[], pattern = component.OutputFormatPattern
    let key=value[component.AttributeName].toLowerCase()

    if (value[component.AttributeName] === 'Custom') { return val = true }
    else if(value[component.AttributeName] === ""){
        return [{CreatedFromDate: ""},{CreatedToDate: ""}] ;
    }
    else {
        let attrParams = attributeSplit(component.AttributeParams)
        let FieldName =Object.keys(attrParams).length > 1 ? Object.keys(attrParams):'';
        let dates = dynamicDatePicker(date, key, pattern)
        console.log('CreatedFromDate',dates,)
        return val =[{[FieldName[0]]:dates[0]},{[FieldName[1]]:dates[1]}]
    }
}

export const handleOnChangeNV2 = (component, value, props) => {
    let date = new Date()
    let val=[], pattern = component.OutputFormatPattern
    let key=value[component.AttributeName].toLowerCase()

    if (value[component.AttributeName] === 'Custom') { return val = true }
    // else if(value[component.AttributeName] === ""){
    //     return [{CreatedFromDate: ""},{CreatedToDate: ""}] ;
    // }
    else {
        let attrParams = attributeSplit(component.AttributeParams)
        let FieldName =Object.keys(attrParams).length > 1 ? Object.keys(attrParams):'';
        let dates = dynamicDatePicker(date, key, pattern)
        console.log('CreatedFromDate',dates,)
        return val =[{[FieldName[0]]:dates[0]},{[FieldName[1]]:dates[1]}]
    }
}

export const dynamicDatePicker = (date,key,pattern)=>{
    let num= key.match(/(\d+)/);
    if(key.includes('d') && num && num[0]){
        return key.includes('-')  ?  [format(subDays(date, num[0]), pattern),format(new Date, pattern)] : [format(new Date, pattern),format(new Date, pattern)]
    }
    else if(key.includes('m') &&  num && num[0]){
        return key.includes('-')  ?  [format(new Date(date.getFullYear(), date.getMonth()-num[0], 1),pattern),format(new Date(date.getFullYear(), date.getMonth(), 0),pattern)] : [format(new Date(date.getFullYear(), date.getMonth(), 1), pattern),format(new Date, pattern)]
    }
    else if(key.includes('y') && num && num[0]){
        return key.includes('-')  ?  [format(new Date(date.getFullYear()-num[0],0, 1), pattern),format(new Date(date.getFullYear()-1, 12, 0), pattern)] : [format(new Date(date.getFullYear(), 0, 1), pattern),format(new Date, pattern)]
    }
    else if(key.includes('') || key.length===0){
        return ['','']
    }
    else{
        return ['','']
    }
}
export const validateCancellationType = (component, value, props) => {
    let keys = Object.keys(value);
    let attributeName = keys[0];
    if(value[attributeName] !== "Flat"){
        props.functions.UpdateState('productbrowsevalidation_dataList',[{Message:'Please choose cancellation type as Flat'}],true)
    } else{
        props.handleInputChangeV2(value, component, false, null, props.callback, true);
        props.functions.UpdateState('productbrowsevalidation_dataList',[],true)
    }
}


  export const VeRiskRCVCalculator=async(props,value)=>{
    let VeRiskObj;
        let request={}
        const {componentProps:{parentState:{UpdateState}}}=props;
        request["ServiceRequestDetail"]=getServiceRequestDetail();
        request["OwnerId"] = sessionStorage.getItem("OwnerID")
        request.TargetEventName  = props.component.EventName;
        request.EventName = GetCommonEventName()
        request.ROOTID = store.getState().UIFlowReducer.insuredID;
        request.ID = store.getState().UIFlowReducer.insuredID;
        request["EntityName"] = "Location";
        request.PARENTID = store.getState().UIFlowReducer.insuredID;
        const response = await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        response.data.Location.forEach((val,idx)=>{
            if(val.ID===value.LocationFullAddress){
                VeRiskObj=val
                store.dispatch({ type: actionType.SET_UI_VALUES, payload: {StateId:val.StateRegionCode}})
            }
        })
        Object.assign(props.transactionPrimaryObject,VeRiskObj)
        clearAvailableMarket(props,value)
}

export const clearAvailableMarket =async (props, value) =>{
    try{
    if(!props || !props.hasOwnProperty("functions")){
      return
    }
    let {component,functions:{UpdateState},componentState, componentProps:{UIFlowReducer:{quoteID}}} = props
    let {Action} = component;
    let {isLoaderNeeded=true} = Action;

    if(component.ComponentType==="Currency" && componentState && componentState.pagevalues && componentState.pagelevelValues && componentState.pagevalues[`${component.ComponentId}`] && componentState.pagelevelValues[`${component.SubApplicationName}`][`${component.ComponentId}`] && (componentState.pagevalues[`${component.ComponentId}`]===componentState.pagelevelValues[`${component.SubApplicationName}`][`${component.ComponentId}`])){
        return;
    }
    if(component.OnKeyDownAction && props[component.OnKeyDownAction] && quoteID!==undefined && quoteID!==''){
        await props[component.OnKeyDownAction]();
    }
    isLoaderNeeded && UpdateState("loading",true,false)
    store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: false});
    let {pagelevelValues,transactionPrimaryObject}=props
    if(transactionPrimaryObject[component.SubApplicationName])
        delete transactionPrimaryObject[component.SubApplicationName]['PolicyForms']
    if(pagelevelValues[component.SubApplicationName])
        delete pagelevelValues[component.SubApplicationName]['PolicyForms']
    let obj= handleInputChangeV3(
        value,
        component,
        undefined,
        undefined,
        undefined,
        {pagelevelValues,transactionPrimaryObject}
      );
    let subappinfo = {
        subappName: props.pageInfo.subApplication,
        objectId: props.componentProps.productDetails.ProductID,
        objectVerId: props.componentProps.productDetails.ProductVerID,
        state:"CW"
      }
      transactionPrimaryObject ={...obj.transactionPrimaryObject,AvailableMarket:props.AvailableMarket}
      deleteDependentAttribute(component,pagelevelValues,transactionPrimaryObject)
      let metadataResponse ={};
      metadataResponse = await constructAndInvokeMetadataCall(subappinfo,{transactionPrimaryObject});
    UpdateState("pageMetadata",metadataResponse,false);
    UpdateState("pagelevelValues",obj.pagelevelValues,false);
    UpdateState("transactionPrimaryObject",obj.transactionPrimaryObject,false);
    isLoaderNeeded && UpdateState("loading",false,false);
    let  flowBlockKey=props.pageInfo.flowBlockKey ;
    if(flowBlockKey){
        obj[`${flowBlockKey}`] = false
        store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj})
      }
    props.componentProps.parentState.UpdateState("AvailableMarket",{},false);
    let dataListName = props.pageInfo.dataListName || []
    dataListName.forEach(key=> {
        props.componentProps.parentState.UpdateState(`${key.toLowerCase()}_dataList`,[],false);
    })
    // props.componentProps.parentState.UpdateState("carriervalidations_dataList",[],false)
    props.componentProps.parentState.UpdateState("clearCarrierSelection",true,false)
    props.componentProps.parentState.UpdateState("AvailableMarketList_dataList",[],true);
    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"SubjectivityListRenderFlag":"No"} });
}catch(e){
    props.functions.UpdateState("loading",false,false);
    console.log('Error in clearAvailableMarket',e)
}
}
