import * as React from 'react';
import { Typography, Button,Menu,MenuItem ,ListItemText} from '@mui/material';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
//import MuiMenu from '@material-ui/core/Menu'
import { Grid } from '@material-ui/core';


const Fees=[
{fee:"Basic Premium",tax:"$2234"},{fee:"Policy Fee",tax:"$2234"},
{fee:"Inspection Fee",tax:"$2234"},{fee:"Filing Fee",tax:"$2234"},
{fee:"Stamp Fee",tax:"$2234"},{fee:"DCA EMPA Residential Fee",tax:"$2234"},
{fee:"Citizen Assesment Fee",tax:"$2234"},{fee:"Hurricane Catastrophe Fee",tax:"$2234"},
{fee:"Surplus Lines Tax",tax:"$2234"},{fee:"Agent Commission",tax:"$2234"},
{fee:"Agent Commission%",tax:"$9089"}]

export default function MenuSignature(props ) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl1, setAnchorEl1] = React.useState(null)
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const { component } = props;

  return (

    <div style={{paddingTop:'150px'}}>
    <Grid container>
        {/* <Grid item>
      <Button
        style={{color:'#1434A4',textTransform:'none',textDecoration:'underline'
        ,fontSize:'22px'}}
        onClick={handleClick}
      >
       {props.header}  <ArrowDropDown style={{fontSize:'50px',paddingLeft:'0px',marginLeft:'-9%'}}></ArrowDropDown>
      </Button>
      <Menu

        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem  onClick={handleClose}><a href="url" style={{fontSize:'15px'}}>Send eSignature Request to Agent</a></MenuItem>
        <MenuItem  onClick={handleClose}><a href="url" style={{fontSize:'15px'}}>Send eSignature Request to Agent</a></MenuItem>

      </Menu>
      </Grid> */}


      <Grid item style={{paddingLeft:'50%'}}>
      <Button
        style={{color:'#1434A4',textTransform:'none',textUnderlinePosition:'under',
        textDecoration:'underline',fontSize:'20px',wordSpacing:'-3%'}}
        onClick={handleClick1}
      >
        {component.ComponentLabel}<ArrowDropDown style={{fontSize:'50px',paddingLeft:'0px',marginLeft:'-4%'
        }}></ArrowDropDown>{component.ComponentLabel}
      </Button>
      <Menu

       anchorEl={anchorEl1}
       open={open1}
       onClose={handleClose1}
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'left',
       }}
       transformOrigin={{
         vertical: 'top',
         horizontal: 'left',
       }}
      >
          <div style={{color:'#848482', width:'350px',top:'247px'}}>
          {Fees.map((Fees)=>{
                  return (
                  <MenuItem  onClick={handleClose1}><ListItemText>{Fees.fee}</ListItemText>
        <Typography style={{color:'Black',fontWeight:'Bold'}}>{Fees.tax}</Typography></MenuItem>)

              } ) }

              </div>





      </Menu>
      </Grid>
      </Grid>
    </div>
  );
}
