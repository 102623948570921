import React from 'react';
import { Breadcrumbs, Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export default function Navigation(props) {

    let { pageNavigation, currentPageIndex } = props;
    let breadcrumbLength = pageNavigation.length;

    return (
        <div className="container custom-MuiBreadcrumbs-root breadcrumbCustom">
            <Breadcrumbs separator="" className="custom-breadcrumb" aria-label="breadcrumb">
                {
                   pageNavigation && pageNavigation.length ? pageNavigation.map((nav, index) => {
                        if (nav.display) {
                           if (nav.index > currentPageIndex) {
                                return (
                                    <Button
                                        key={index}
                                       className="navbtn"
                                      //  style={styles.navbtn}
                                        onClick={() => props.jumpToStep(nav.index,props.pageNavigation)}
                                        endIcon={nav.index < breadcrumbLength ? <NavigateNextIcon  /> : null}
                                        disabled
                                    >
                                        {nav.description}
                                    </Button>
                                )
                            }
                            else {
                                return (
                                    <Button
                                        key={index}
                                       // style={nav.index === currentPageIndex ? styles.activenavbtn : styles.navbtn}
                                        className={nav.index === currentPageIndex ? "activenavbtn theme_nav" : "navbtn"}
                                        onClick={() => props.jumpToStep(nav.index,props.pageNavigation)}
                                        endIcon={nav.index < breadcrumbLength ? <NavigateNextIcon  /> : null}
                                    >
                                        {nav.description}
                                    </Button>
                                )
                            }
                        }
                    }) : null
                }

            </Breadcrumbs>
        </div>
    );
}

let styles = {
    // navBtn: {
    //     backgroundColor: 'transparent',
    //     border: 'none',
    //     color: 'grey',
    //     ':focus': {
    //         border: 'none !important'
    //       }
    // },
    // activeNavBtn: {
    //     backgroundColor: 'transparent',
    //     border: 'none',
    //     color: 'blue',
    //     ':focus': {
    //         border: 'none !important'
    //       }
    // },
    // navbtn: {
    //     border: 'none !important',
    //     background: 'none !important',
    //     textTransform: 'none',
    //     fontSize: '15px',
    //     color: '#616b74'
    // },
    // activenavbtn: {
    //     border: 'none !important',
    //     background: 'none !important',
    //     textTransform: 'none',
    //     color: '#20a8d8',
    //     fontSize: '15px'
    // },
    // visitednavbtn: {
    //     border: 'none !important',
    //     background: 'none !important',
    //     textTransform: 'none',
    //     fontSize: '18px',
    //     color: 'rgba(0, 0, 0, 0.26)'
    // }
}
