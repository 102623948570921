import React, {createContext,useContext} from "react";
import container from "./DIContainer";

const DIContext = createContext()

export const DIProvider = ({children})=> (
  <DIContext.Provider value={container}>
    {children}
  </DIContext.Provider>
)

export const useDIContext = () => useContext(DIContext)

