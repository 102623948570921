import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { AppHeader } from '@coreui/react';
import DefaultHeader from '../../../../../containers/DefaultLayout/DefaultHeader';
import { logUserDetailRequest } from '../../../../Pages/Login/loginUtil'
import { authAxios } from "../../../utils/Common/API";
import { ResourceAxios } from "../../../../../CommonAxios/ResourceAxios";
import { logout, setFromSSO } from "../../../../../actions/authActions";

const Header = (props) => {

    const onToggle = ()=>{
        if(document.getElementById("stikcyContainer"))
        document.getElementById("stikcyContainer").classList.toggle("onExpandfreezepanel")
        // this.forceUpdate()
    }

    const signOut = async (e) => {
        e.preventDefault();
    
        let request = {};
        await logUserDetailRequest(props.userInfoReducer, 'Logout')
        request.UserName = localStorage.getItem("userName");
        
        let response = await authAxios.post("/auth/logout", request);
        response = await response.data;
        
        if (response.Status.toLowerCase() === "success") {
          localStorage.clear();
          if (props.fromSSO === true) {
            props.setFromSSO(false);
            window.location.href = "https://login.microsoftonline.com/common/oauth2/logout";
          } else {
            props.history.push("/login");
          }
          await props.logout();
          ResourceAxios();
        }
      };

    return (
        <AppHeader  className="theme_header" fixed>
            <Suspense>
              <DefaultHeader
                changeLanguageCode={props.changeLanguageCode}
                onToggle={onToggle}
                onLogout={(e) => signOut(e)}
              />
            </Suspense>
        </AppHeader>
    )
}

const mapStateToProps = (state) => {
    return {
      fromSSO: state.authReducer.fromSSO,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
      setFromSSO : data => dispatch(setFromSSO(data)),
      logout: () => dispatch(logout()),      
    };
};

export default connect(mapStateToProps, mapDispatchToProps) (Header);