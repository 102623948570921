import { format } from "date-fns";

//works only for MM/DD/YYYY
export const getDateWithoutOffset = date => {
  if(date && !(date.includes('_'))){
      const dt = new Date(changeDateformat(date))
      const dateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
      return dateOnly
  }
  return date
}

//works for MM/DD/YYYY to YYYY-MM-DD
export const changeDateformat = date => {
  if(date && date.includes("/")){
      const [mm,dd,yyyy] = date.split("/")
      return `${yyyy}-${mm}-${dd}`
  }
  return date
}

//works for YYYY-MM-DD to MM/DD/YYYY to
export const changeSOLDateformat = date => {
  if(date && date.includes("-")){
      const [yyyy,mm,dd] = date.split("-")
      return `${mm}-${dd}-${yyyy}`
  }
  return date
}

export const dateTimeFormat = (date) => {
  // this function return date with time format YYYY-MM-DD HH:mm:ss eg: 2023-10-27 10:02:41.770
  const currentDate = date
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const hours = String(currentDate.getHours()).padStart(2, '0')
  const minutes = String(currentDate.getMinutes()).padStart(2, '0')
  const seconds = String(currentDate.getSeconds()).padStart(2, '0')
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0')

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`
  return formattedDateTime
}

export const dateFormat = (date) => {
  // this function return date with time format MM/DD/YYYY eg: 10/27/2023
  const currentDate = date

  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const year = currentDate.getFullYear()

  const formattedDate = `${month}/${day}/${year}`
  return formattedDate
}

export const getDateFromStringValue= (component,value) =>{
  try{
      const {Action} = component;
      let {outputSplitter}=Action;
      let fomattedValue=value;
      let dayRegex = /(\d+)(?:st|nd|rd|th)/;
      fomattedValue=fomattedValue.replace(dayRegex,'$1');
      let array1=Action.outputString.split(outputSplitter),array2=fomattedValue.split(outputSplitter);
      let year,day,month;
      array2.forEach((elem,i)=>{
        if(!array1.includes(elem)){
          if(!isNaN(array2[i]) && !isNaN(parseFloat(array2[i]))){
          let match = /[0-9]{4}/.exec(array2[i])
          if(match){
          year=array2[i];
          }else if(!isNaN(array2[i]) && !isNaN(parseFloat(array2[i]))){
              day=array2[i].padStart(2,'0');
          }
          }
          else{
            month=array2[i];
          }
        }
      })
      const monthNumber=(new Date(Date.parse(month + day+',' + year)).getMonth() + 1).toString().padStart(2,'0');
      let originalDateValue=year+"-"+monthNumber+"-"+day;
      let regex = /(\d{2})\/(\d{2})\/(\d{4})/;
      let formattedMaxDate = component.MaxValue.replace(regex, "$3-$1-$2");
      let formattedMinDate=component.MinValue.replace(regex, "$3-$1-$2");
      return {originalDateValue,formattedMaxDate,formattedMinDate};
  }catch(e){
    console.log('Error in dateFromStringValue',e)
  }
 }
 export const dateFormatter = (component,datevalue) => {
  try{
    const {Action} = component;
    let formatPattern = component.OutputFormatPattern;
    let fomattedValue=format(getDateWithoutOffset(datevalue),formatPattern);
    let splitter=Action.outputSplitter;
    let format2=formatPattern.split(splitter).map(el=>{
      return el.slice(0,1)
    })
    fomattedValue=fomattedValue.split(splitter);
    let {outputString}=Action;
    let replaceHelper={
      d: '{day}',
      m: '{month}',
      y: '{year}'
    }
    for(let i in format2)
    outputString=outputString.replace(replaceHelper[format2[i].toLocaleLowerCase()],fomattedValue[i]);
    return outputString;
  }catch(e){
    console.log('Error in dateFormatter',e)
  }
}
