import React, { Fragment, useEffect, useRef } from 'react';
import { Checkbox, Grid, Icon } from '@material-ui/core';
import { commonCheckBoxMethod } from './ButtonUtil';

export default function IconCheckBox(props) {
    const { component, componentState, functions, componentvalue } = props;
    const { Action = {} } = component;
    const prevProps = useRef(props);
    
    useEffect(async() => {
        if (component.DefaultValue !== "" && componentvalue === undefined) {
            await props.updateValueWithSubplicationName && props.updateValueWithSubplicationName({ [component.AttributeName]: component.DefaultValue }, component);
        }
    }, []);
    
    useEffect(() => {
        if (component.DefaultValue !== "" && (prevProps.current.component.DefaultValue !== component.DefaultValue || componentvalue === undefined)) {
            props.updateValueWithSubplicationName && props.updateValueWithSubplicationName({ [component.AttributeName]: component.DefaultValue }, component);
        }
    }, [component.DefaultValue, componentvalue]);
    
    const onChange = (event) => {
        const isChecked = event.target.checked;
        const value = isChecked.toString();
        const obj = { [component.AttributeName]: value }
        if (props.customTable) {
            props.handleRowLevelInputChange(obj, component, props.customTableRowIndex, props);
        } else if (Action.onChange && Object.keys(Action.onChange).length > 0) {
            commonCheckBoxMethod({ ...component, Action: Action.onChange }, obj, props);
        } else {
            props.handleInputChangeV2(obj, component, props, componentState, functions, componentState.pageSubAppInfo);
        }
    };
    
    const attributeValue = component.DefaultValue === "" ? (componentvalue !== undefined ? componentvalue : "") : (componentvalue !== undefined ? componentvalue : component.DefaultValue);

    return (
        <Fragment>
            <Grid className="edit_grid theme_nav" item xs={1} sm={1} md={component.Size} lg={component.Size}>
                <Checkbox
                    disabled={component.IsReadonly}
                    icon={<Icon fontSize={Action.size ? Action.size.toLowerCase() : 'default'} style={{ color: Action.color ? Action.color.toLowerCase() : 'primary' }}>{Action.iconName}</Icon>}
                    checkedIcon={<Icon fontSize={Action.size ? Action.size.toLowerCase() : 'default'} style={{ color: Action.color ? Action.color.toLowerCase() : 'primary' }}>{Action.iconChecked}</Icon>}
                    color="primary"
                    name={component.AttributeName}
                    checked={attributeValue === 'true'? true : false}
                    defaultChecked={component.DefaultValue === 'true' ? true : false}
                    onChange={onChange}
                    size="small"
                />
            </Grid>
        </Fragment>
    );
}
