import React from "react";
import red from "@material-ui/core/colors/red";
import { ValidatorComponent } from "react-material-ui-form-validator";
import { CircularProgress, MenuItem } from "@material-ui/core";
import SelectDropdown from "@material-ui/core/Select";

const red300 = red["500"];

const style = {
  left: 0,
  fontSize: "12px",
  color: red300
};

const styles = {
  dropdown: {
    minWidth: "100%",
    marginTop: "16px !important",
  },
  label: {
    width: "150%",
  },
};

class SelectDropdownValidator extends ValidatorComponent {
  render() {
    const {
      ComponentId,
      labelId,
      name,
      errorMessages,
      validators,
      requiredError,
      value,
      options,
      component,
      attributeValue,
      displayEmpty,
      autoComplete,
      loaderNeeded,
      autoWidthNeeded,
      variant,
      LoaderComponent,
      isDisabled,
      ...rest
    } = this.props;

    const isError = (variant === "outlined" && !this.state.isValid) || false;
    const loaderCondition = () => LoaderComponent || <CircularProgress />
    return (
      <React.Fragment>
        <SelectDropdown
          labelId={labelId}
          id={ComponentId}
          name={name}
          value={value}
          disabled={isDisabled!==undefined ? isDisabled : component.IsReadonly}
          onChange={(e) => this.props.onChange(e)}
          fullWidth={!autoWidthNeeded}
          autoWidth={autoWidthNeeded}
          displayEmpty={displayEmpty}
          error={isError}
          variant={variant}
          autoComplete={autoComplete}
          IconComponent={loaderNeeded ? loaderCondition : undefined}
        >
          {options && options.map((option) => {
            return (
              <MenuItem key={option.Sequence} className="small_options" value={option.Value}>
                {option.DisplayValue}
              </MenuItem>
            );
          })}
        </SelectDropdown>
        {this.errorText()}
      </React.Fragment>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <span className="small_error" style={style}>{this.getErrorMessage()}</span>;
  }
}

export default SelectDropdownValidator;
