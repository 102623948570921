import React, { Component } from 'react'

export default class FindButton extends Component {
  render() {
    const { component } = this.props;
    return (
      <span className={`col-xs-12 col-sm-6 col-md-6 col-lg-6 ${component.AttributeStyleClass}`}>
          <button
            id={component.ComponentId}
            name={component.ComponentId}
            style={styles.button}
            className={`MuiButtonBase-root MuiButton-root MuiButton-text nav-btn theme_buttonColor MuiButton-textPrimary theme_button ${component.StyleClass}`}
            type="submit"
            // onClick={(e) => handleSubmit(e,component)}
          >
            <span>{component.ComponentLabel}</span>
          </button>
      </span>
    )
  }
}

const styles = {
  button: {
  float: 'right'
  }
};
