import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputAdornment from '@material-ui/core/InputAdornment';
class StaticText extends Component {
  componentDidMount = () => {
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
  };

  render() {
    const { component, isDynamicTable, datatableRowindex } = this.props;
    /* let attributeValue =
      this.props.attributeValue === undefined ||
      this.props.attributeValue === ""
        ? component.DefaultValue
        : this.props.attributeValue; */
    let componentvalue = this.props.componentvalue;
    let isRequired = component.IsRequired === "Y" ? true : false;
    let isReadOnly = component.IsReadonly;
    let componentLabel = isRequired
      ? component.ComponentLabel + " *"
      : component.ComponentLabel;
    const validations = [];
    const errorMessages = [];
    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
      validations.push("IsEmpty");
      errorMessages.push(component.RequiredMessage);
    }
    console.log('From Static Text',componentvalue)
    let value = "";


    if (this.props.values[component.AttributeName]) {
      value = this.props.values[component.AttributeName];
    }
    else{
      value = component.AttributeValue;
    }
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
    
    return (
      <Grid item xs={6} sm={6} md={component.Size} lg={component.Size}>
        <TextValidator
          name={component.AttributeName}
          placeholder={!this.props.isDynamicTable && component.ComponentLabel}
          label={
            !this.props.isDynamicTable && (
              <Typography className="custom-label-down text-font">
                {componentLabel}
              </Typography>
            )
          }
          onChange={(e)=>this.props.handleInputChangeV2(e)}
          defaultValue={value}
          className="custom-input"
          margin="normal"
          disabled={isReadOnly}
          validators={validations}
          errorMessages={errorMessages}
          autoComplete={autocomplete}
          fullWidth
          InputProps={component.AttributeName == 'OriginalCostNewOfVehicle' && (
            {
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }
          )}
        />
      </Grid>  
    );
  }
}
export default StaticText;
