import React from 'react';
import RenderTemplate from '../../../../../template/RenderTemplate';
import { connect } from "react-redux";
import store from '../../../../../store';

class TemplateRendering extends React.Component {

constructor(props) {
    super(props);
    this.state = {
      locations : {}
    };
  }
  render(){
    const reduxStore = store.getState();
    console.log("TempInfo...........",this.props)
    const templateName = reduxStore.navigationReducer.templateInfo[this.props.pageInfo.TemplateName]
    console.log("templateName-->",templateName)
    return (
              <RenderTemplate
                      isExpand = {this.props.isExpand}
                      tableUtils = {this.props.tableUtils}
                      state= {this.props.state}
                      rowData = {this.props.rowData}
                      Template={templateName}
                      values={this.props.values}
                      pagelevelValues={this.props.pagelevelValues}
                      handleInputChangeV2={this.props.handleInputChangeV2}
                      transactionPrimaryObject={this.props.transactionPrimaryObject}
                      handleHiddenInputChange={this.props.handleHiddenInputChange}
                      updateValueWithSubplicationName={this.props.updateValueWithSubplicationName}
                      handleAutoCompletechange={this.props.handleAutoCompletechange}
                      updateArrayValueOnState = {this.props.updateArrayValueOnState}
                      objectId={this.props.objectId}
                      objectVerId={this.props.objectVerId}
                      parentProps = {this.props.parentProps}
                    />
    )
}
}

const mapStateToProps = (state) => {
  return {
    navigation: state.navigationReducer.navigation,
    templateInfo: state.navigationReducer.templateInfo
  };
};

export default connect (mapStateToProps) (TemplateRendering);
