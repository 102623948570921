import React from "react";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef } from "react";
// import dynamicRender from "../../sections/CustomComponents/dynamicRender";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { handleInputChangeV2 as handleInputChangeV2_Util, tableMethods } from "../../utils/Common/InputUtils";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "../Button";
import { commonButtonMethod } from "../ButtonUtil";
import { subAppListConstruction } from "../../utils/Common/DetailSummarySubAppList";
// import { handleRowLevelInputChange } from "../../utils/Common/ProductSelection";
import { AxiosInstance,getServiceRequestDetail } from "../../utils/Common/API";
import { connect } from "react-redux";
import { hideLoader,showLoader } from "../../../../actions/UIFlowActions";
import PageLoader from "../PageLoader";
import ChildCompV2 from '../../sections/CustomComponents/ChildComponent/childCompV2';
import { handleRowLevelInputChange } from "../../utils/Common/DynamicHOC_Utils";
import { triggerToast } from "../../utils/Common/HelperUtils";
import { constructAndInvokeMetadataCall,supAppTransaction } from "../../utils/Common/MetaUtils";
import container from "../../utils/DIContainer";

const DataTablePopup = (props) => {
  const [pageMetadata, setPageMetadata] = useState({});
  const [pagelevelValues, setPagelevelValues] = useState({});
  const [transactionPrimaryObject, setTransactionPrimaryObject] = useState({});
  const [state, setState] = useState({});
  const [values, setValues] = useState({});
  const [detailSummaryList, setDetailSummaryList] = useState({})
  const dynamicRender = container.get('dynamicRender')

  let { tableActions, tableFunctions, pageInfo, rowData } = props;
  let { childComponent = {} } = tableActions;
  let { ComponentLabel, ComponentType, StyleClass, isButtonNeeded, Size, saveButtonAction = {} } = childComponent.SaveButton
  let subapplicationList = [];
  let childform = useRef();


  useEffect(async () => {
    props.showLoader()
    let summaryResponse = await invokeGetScheduleOfFormDetail()
   let subApplicationNameList = supAppTransaction(rowData, summaryResponse,setState,setPagelevelValues)
    let detailSubApp = [], summarySubApp = [], subappNameList = [], detailSummaryList = {}
    subAppListConstruction(subApplicationNameList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
    setDetailSummaryList(detailSummaryList)
    let subappinfo = { subappName: subappNameList, state: 'CW', subApplicationGroupList: [] }
    let response = await constructAndInvokeMetadataCall({ ...subappinfo, objectId: pageInfo.objectId, objectVerId: pageInfo.objectVerId }, { transactionPrimaryObject: transactionPrimaryObject })
    setPageMetadata(response)
    props.hideLoader()
    return () => {
      setState({})
      setTransactionPrimaryObject({})
      setPagelevelValues({})
      setPageMetadata({})
    }
  }, [])

  const invokeGetScheduleOfFormDetail = async () => {
    try {
      let Request = Object.create({})
      Request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail())
      Request["OwnerId"] = sessionStorage.getItem("OwnerID")
      Request["EventName"] = 'getOptionalFormsDetail'
      Request['ID'] = rowData.ID

      let response = await AxiosInstance.post("core/invokeWorkflowEvent/V4", Request);
      let getAPIResponse = {}
      if (response.data.ResponseStatus && response.data.ResponseStatus.toLowerCase() === "failed") {
        triggerToast(response.data.ResponseStatus)
        return getAPIResponse
      } else {
        getAPIResponse = response
        return getAPIResponse
      }
    } catch (error) {
      console.log('error::', error)
    }
  }

  if (pageMetadata.ApplicationDetail)
    subapplicationList = pageMetadata.ApplicationDetail && pageMetadata.ApplicationDetail.SubApplicationDetailList;

  const stateValues = {
    pageMetadata,
    pagelevelValues,
    transactionPrimaryObject,
    values
  }

  const componentProps = {
    Size: Size,
    ComponentLabel: ComponentLabel,
    StyleClass: StyleClass,
    ComponentType: ComponentType
  }

  const handleInputChangeV2 = (value, component) => {
    handleInputChangeV2_Util(value, component, props, { ...stateValues }, functions, stateFunction, undefined, undefined, false)
  }

  const handleClose = () => {
    if (props.tableFunctions && props.tableFunctions.toggleDialog)
      props.tableFunctions.toggleDialog();
  }

  const executeScroll = () => {
    ReactDOM.findDOMNode(childform.current.errors[0]).scrollIntoView({ behavior: 'smooth', block: 'center' });
    if (false || !!document.documentMode) {
      window.scrollBy(0, -120);
    }
  }

  let functionProp = {}

  const UpdateState = (stateName, value) => {
    setState((prevState) => {
      return { ...prevState, [stateName]: value }
    })
  }

  const updateStateValues = (obj) => {
    setPagelevelValues(obj.pagelevelValues)
    if (obj.values)
      setValues(obj.values)
    setTransactionPrimaryObject(obj.transactionPrimaryObject)
    if (obj.pageMetadata)
      setPageMetadata(obj.pageMetadata)
  }

  const buttonProps = {
    componentProps: { ...props, ...props.componentProps },
    componentState: { ...props.componentState },
    refs: childform,
    ...functionProp,
    component: { ...componentProps, UpdateState: UpdateState }
  }

  const functions = {
    handleInputChangeV2,
    UpdateState,
    setPagelevelValues,
    updateStateValues
  }

  const stateFunction = {
    updateStateValues
  }

  const TableControls = {
    "tableFunctions": {
      "addRow": tableMethods.addRow,
      "onExpand": tableMethods.onExpand,
      "deleteRow": tableMethods.deleteRow,
      "handleRowLevelInputChange": handleRowLevelInputChange,
      "onChildSave": tableMethods.onChildSave,
    },
    "tableUtils": {
      "AddRow": tableMethods.addRow,
      "onExpand": tableMethods.onExpand,
      "onChildSave": tableMethods.onChildSave,
      "deleteRow": tableMethods.deleteRow,
      "EditRow": "",
      "OnDetailEdit": "",
      "redirectTo": "",
    },
    "TableOptions": {
      "ChildAction":"",
      "ChildrenSubApplicationName": null,
      "ChildrenComponent": ChildCompV2,
      "AddButtonNeeded": true,
      "SortingNeeded": false,
      "FilteringNeeded": false,
      "FilterIconNeeded": false,
      "rowsPerPageOptions": [5, 10, 25],
      "RowsPerPage": 5
    },

  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        onClose={props.closePopup}
        disableBackdropClickfullWidth={true}
        disableBackdropClick={true}
        open={true}>
        <span className="flexPushRight">
          <IconButton><CloseIcon onClick={handleClose} /></IconButton>
        </span>
        <DialogContent>
          <ValidatorForm
            ref={childform}
            onSubmit={((e) => commonButtonMethod({ Action: saveButtonAction }, {...buttonProps.componentProps, ...functions}, { ...state, ...stateValues }, functions, e))}
            onError={e => executeScroll()}>
            {
              dynamicRender(subapplicationList, props, { ...state, ...stateValues,...pagelevelValues }, functions, TableControls, undefined, detailSummaryList)
            }
            {isButtonNeeded === true && <Button {...buttonProps}{...tableFunctions} />}
          </ValidatorForm>

        </DialogContent>

        {state && state.loading && <PageLoader />}
      </Dialog>

    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader())
  }
}

export default connect(null, mapDispatchToProps)(DataTablePopup)
