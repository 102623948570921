import React, { Fragment } from 'react';
import { Grid, CardContent } from "@material-ui/core";
import DynamicComponent from "../../corecomponents/DynamicComponent";
import DynamicNavigation from "../../corecomponents/DynamicNavigation";
import DataTable from '../../controls/DataTable/DataTable';
import DataTableV2 from '../../controls/DataTable/DataTableV2';
import DialogWrapper from './DialogComponent/DialogWrapper';
import ChildComponent from './ChildComponent/childComponent';
import ExpandableComponent from '../../sections/CustomComponents/ExpandableComponent/ExpandableComponent';
import ChildFormComponent from "./ChildComponent/childFormComponent";
import RenderTemplate from '../../../../template/RenderTemplate';
import ValidationMessages from '../../controls/Validation/ValidationMessages';
import { LOBUtil, defaultTableControls } from '../../utils/Common/CommonUtils';
import { AccordiononErrorContextConsumer } from './ContextAPIUtils/AccordiononErrorContext';
import store from '../../../../store';
import ChildCompV2 from './ChildComponent/childCompV2';

const isChildComponentNeeded = (mActionList,ChildSubApplicationList) =>{
  let {addRow,editRow}=mActionList, ChildSubappPresent = ChildSubApplicationList.length > 0;
  if((addRow && addRow.isNeeded) || (editRow && editRow.isNeeded) || ChildSubappPresent) return true
  else return false;
}

const dynamicRenderV2 = (subapplicationList = [], componentProps, componentState = {}, functions = {}, TableControls = defaultTableControls, summarySubApp, detailSummaryList, hierarchicalRendering, childformRef, staticReadonlyRequired = false) => {
  return (
    subapplicationList.map((subapplication, subaapplicationIndex) => {
      let pageName = subapplication.Description;
      let attributeList = subapplication.AttributeDetailList;
      let subappInvisible = attributeList.every(({ IsInvisible }) => IsInvisible.toLowerCase() === 'y' )
      let subapplicationName = subapplication.ApplicationName;
      let subapplicationEntity = subapplication.EntityHierarchyName ? subapplication.EntityHierarchyName : '';
      let reduxState = store.getState();

      var  { IsExpandable, IsSubmitButtonNeeded, IsEmptyFormNeeded, ButtonType, SubmitButtonName, AdditionalButtonName, AdditionalButtonClickEvent, StyleClass } = subapplication;
      const childFormInfo = { IsSubmitButtonNeeded, IsEmptyFormNeeded, ButtonType, SubmitButtonName, AdditionalButtonName, AdditionalButtonClickEvent }

      let templateType = "";
      let utilObject = {};
      let parentProps = {};
      let dataList = [];
      let stateValues = {};
      let columnList = [];
      let mActionList = subapplication.mAction || {};
      let dynamicTable = false
      let isChildComponent= false;
      let dataTableUpdate = false;
      let expandKey= false;
      templateType = subapplication.Type;
      let dataListSize;

      var { templateName, utilfunctionNeeded, utilfunction,additionalUtil=undefined } = componentProps;

      const ChildSubApplicationList = subapplication.ChildSubApplicationList ? subapplication.ChildSubApplicationList : [];
      let subAppInfo = {
        "ComponentList": attributeList,
        "SubApplicationDetail": subapplication,
        subapplicationName,
        subapplicationEntity
      };
      let LineOfBusiness = componentProps.UIFlowReducer ? componentProps.UIFlowReducer.productDetails.hasOwnProperty("LineOfBusiness") && componentProps.UIFlowReducer.productDetails["LineOfBusiness"] : componentState.UIFlowReducer ? componentState.UIFlowReducer.productDetails.hasOwnProperty("LineOfBusiness") && componentState.UIFlowReducer.productDetails["LineOfBusiness"] : null;
      templateName= templateName ? templateName : LineOfBusiness;
      const tableOptions = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] && utilfunction[templateName][subapplicationName.toLowerCase()]["TableOptions"] ? utilfunction[templateName][subapplicationName.toLowerCase()]["TableOptions"] : TableControls.TableOptions;
      const tableUtils = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] && utilfunction[templateName][subapplicationName.toLowerCase()]["tableUtils"] ? utilfunction[templateName][subapplicationName.toLowerCase()]["tableUtils"] : TableControls.tableUtils;
      const tableFunctions = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] && utilfunction[templateName][subapplicationName.toLowerCase()]["tableFunctions"] ? utilfunction[templateName][subapplicationName.toLowerCase()]["tableFunctions"] : TableControls.tableFunctions;
      const dialogOptions = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] ? utilfunction[templateName][subapplicationName.toLowerCase()]["dialogOptions"] : {};
      const dialogUtils = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] ? utilfunction[templateName][subapplicationName.toLowerCase()]["dialogUtils"] : {};
      const openByDefault = utilfunction && utilfunction[templateName] && utilfunction[templateName][subapplicationName.toLowerCase()] ? utilfunction[templateName][subapplicationName.toLowerCase()]["openByDefault"] :false;
      const validationSource = subapplicationName.toLowerCase() + "_dataList";

      if(Object.keys(mActionList).length > 0){
        isChildComponent = isChildComponentNeeded(mActionList,ChildSubApplicationList);
        dynamicTable = true;
        expandKey = mActionList.expandByDefault === true? true:false;
      }

      if (hierarchicalRendering) {
        if (templateType === "Summary" || templateType === "PivotSummary"){
          dataList = componentState.hasOwnProperty(subapplicationName) ? componentState[subapplicationName] : [];
        }
      } else {
        dataList = componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_dataList") ? componentState[subapplicationName.toLowerCase() + "_dataList"] : [];
        //temporary fix: parentdataListUpdate - which state component prefer
        //parentdataListUpdate (Y/N) - componetState datalist is empty parentdatalist will be used || componentState datalist will be used even if it is empty.
        if(dataList && dataList.length === 0 && (!mActionList.hasOwnProperty("parentdataListUpdate") || mActionList.parentdataListUpdate === 'Y')) {
          dataList = componentProps.hasOwnProperty(subapplicationName.toLowerCase() + "_dataList") ? componentProps[subapplicationName.toLowerCase() + "_dataList"] : [];
        }
      }

      // getting dataList from pagelevelValues
      if(dataList && dataList.length === 0) {
        dataList = componentState.pagelevelValues ? (componentState.pagelevelValues.hasOwnProperty(subapplicationName.toLowerCase() + "_dataList") ?  componentState.pagelevelValues[subapplicationName.toLowerCase() + "_dataList"] : []) : [];
      }

      if(componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_dataListSize")) dataListSize = componentState[subapplicationName.toLowerCase() + "_dataListSize"]

      if(componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_dataTableUpdate")) dataTableUpdate = componentState[subapplicationName.toLowerCase() + "_dataTableUpdate"]
    //   if (utilfunction) {
    //     stateValues = Object.assign({}, componentProps, componentState);
    //   }
    //   else{
    //     stateValues = componentProps;
    //   }

      stateValues = Object.assign({}, componentProps, componentState);


      if (utilfunction) {
        utilObject = utilfunction[templateName] ? utilfunction[templateName][subapplicationName.toLowerCase()] ? utilfunction[templateName][subapplicationName.toLowerCase()] : {} : {};
      }
      if(additionalUtil!==undefined && utilfunction.hasOwnProperty(`${additionalUtil}`)){
        utilObject={...utilObject,...utilfunction[additionalUtil]};
      }
      columnList = componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_columnList") ? componentState[subapplicationName.toLowerCase() + "_columnList"] : [];
      if(columnList.length === 0) {
        columnList = componentProps.hasOwnProperty(subapplicationName.toLowerCase() + "_columnList") ? componentProps[subapplicationName.toLowerCase() + "_columnList"] : [];
      }

      // getting ColumnList from pagelevelValues
      if(columnList.length === 0) {
        columnList = componentState.pagelevelValues ? (componentState.pagelevelValues.hasOwnProperty(subapplicationName.toLowerCase() + "_columnList") ?  componentState.pagelevelValues[subapplicationName.toLowerCase() + "_columnList"] : []) : [];
      }

      //parentProps assign
      if(componentProps.hasOwnProperty('parentProps') && componentProps.parentProps) {
        parentProps = componentProps.parentProps
      }
      else {
        parentProps = componentProps
      }
        let lobUtil = LineOfBusiness ? LOBUtil[LineOfBusiness] : {};
      let LOB = componentProps.UIFlowReducer ? componentProps.UIFlowReducer.lob : componentState.UIFlowReducer ? componentState.UIFlowReducer.lob : null;
      let type = <Grid container >
          {((templateType === "Summary" || templateType === "PivotSummary") && !dynamicTable) ? (
            <Grid container className="topbottommargin">
              <DataTable
                subAppInfo={subAppInfo}
                columnList={columnList}
                dataList={dataList}
                UpdateState={functions.UpdateState}
                getDocument={utilObject.getDocument}
                TableOptions={tableOptions}
                tableUtils={tableUtils}
                ChildSubApplicationList={ChildSubApplicationList}
                componentProps={componentProps}
                parentProps={parentProps}
                componentState={componentState}
                TableControls={TableControls}
                functions={functions}
                hierarchicalRendering={hierarchicalRendering}
                IsExpandable={IsExpandable}
                {...childFormInfo}
                staticReadonlyRequired={staticReadonlyRequired}
                pageInfo={componentProps.pageInfo || componentState.pageInfo || null}
                {...utilObject}
              >
                {TableControls.TableOptions && TableControls.TableOptions.ChildrenComponent != null && <TableControls.TableOptions.ChildrenComponent {...componentProps} {...componentState} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} />}
              </DataTable>
            </Grid>
          ) : null}

          {((templateType === "Summary" || templateType === "PivotSummary") && dynamicTable) ? (
            <Grid container className="topbottommargin">
              <DataTableV2
                subAppInfo={subAppInfo}
                columnList={columnList}
                dataList={dataList}
                dataListSize={dataListSize}
                dataTableUpdate={dataTableUpdate}
                UpdateState={functions.UpdateState}
                getDocument={utilObject.getDocument}
                tableActions={mActionList}
                tableFunctions={tableFunctions}
                TableOptions={tableOptions}
                tableUtils={tableUtils}
                ChildSubApplicationList={ChildSubApplicationList}
                componentProps={componentProps}
                parentProps={parentProps}
                componentState={componentState}
                TableControls={TableControls}
                functions={functions}
                hierarchicalRendering={hierarchicalRendering}
                IsExpandable={IsExpandable}
                {...childFormInfo}
                staticReadonlyRequired={staticReadonlyRequired}
                pageInfo={componentProps.pageInfo || componentState.pageInfo || null}
              >
                {/* {TableControls.TableOptions && TableControls.TableOptions.ChildrenComponent != null && <TableControls.TableOptions.ChildrenComponent {...componentProps} {...componentState} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} />} */}
                {isChildComponent && <ChildCompV2 {...componentProps} {...componentState} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} mActionList={mActionList} functions={functions} /> }
                </DataTableV2>
            </Grid>
          ) : null}

          {
            templateType === "Detail" || templateType === "Accordion" || templateType === "Form" ? (
            <DynamicComponent
              pageInfo={componentProps.pageInfo}
              pageName={pageName}
              components={attributeList}
              IsExpandable={IsExpandable}
              values={stateValues.values}
              updateValues={stateValues.updateValues}
              transactionPrimaryObject={stateValues.transactionPrimaryObject}
              ChildSubApplicationList={ChildSubApplicationList}
              functions={functions}
              componentProps={componentProps}
              componentState={componentState}
              parentProps={parentProps}
              TableControls={TableControls}
              summarySubApp={summarySubApp}
              detailSummaryList={detailSummaryList}
              pagelevelValues={stateValues.pagelevelValues}
              handleInputChange={functions.handleInputChange}
              handleInputChangeV2={functions.handleInputChangeV2}
              handleAttachments={functions.handleAttachments}
              copyValue={functions.copyValue}
              handleAutoCompletechange={functions.handleAutoCompletechange}
              handleDateChange={functions.handleDateChange}
              handleHiddenInputChange={functions.handleHiddenInputChange}
              invokeGetAccount={functions.invokeGetAccount}
              updateValueWithSubplicationName={functions.updateValueWithSubplicationName}
              handleRTEChange={functions.handleRTEChange}
              handlePersistValue={functions.handlePersistValue}
              callback={functions.callback}
              enableEditOption={functions.enableEditOption}
              lobUtil={lobUtil}
              refs={childformRef}
              {...utilObject}
            //  {...subappUtilFunctions}
              staticReadonlyRequired={staticReadonlyRequired}
            />
            ) : null
          }

          {
            templateType !== "Detail" && templateType !== "Accordion" && templateType !== "Summary" && templateType !== "PivotSummary" && templateType !== "Form" && templateType !== "ValidationMessages" && templateType !== "Dialog"?
            (<RenderTemplate
                Template={reduxState.navigationReducer.templateInfo[templateType]}
                pageName={pageName}
                subapplicationName={subapplicationName}
                SubApplicationDetail = {subapplication}
                components={attributeList}
                utilfunction={utilObject}
                pagelevelValues={stateValues.pagelevelValues}
                values={stateValues.values}
                transactionPrimaryObject={stateValues.transactionPrimaryObject}
                parentProps={stateValues}
                staticReadonlyRequired={staticReadonlyRequired}
              >
              </RenderTemplate>
            ) : null
          }

          {
            templateType === "ValidationMessages" && stateValues[validationSource] && stateValues[validationSource].length > 0 ?
              (<ValidationMessages
                  dataList={stateValues[validationSource]}
                  Header={pageName}
              />) : null
          }

          {
            componentProps.dynamicNavigation && templateType === "NavigationButtons" ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DynamicNavigation
                  components={attributeList}
                  values={stateValues.values}
                  UpdateState={componentProps.UpdateState}
                  functions={functions}
                  propsValue={componentProps.propsValue}
                  navigateToPrevious={componentProps.navigateToPrevious}
                  navigateToNext={componentProps.navigateToNext}
                  lobUtil={componentProps.lobUtil}
                  pagelevelValues={stateValues.pagelevelValues}
                  transactionPrimaryObject={stateValues.transactionPrimaryObject}
                  UIFlowReducer={componentProps.UIFlowReducer}
                  pageMetadata={componentState.pageMetadata}
                  formref={childformRef}
                  componentState={componentState}
                  {...utilObject}
                  staticReadonlyRequired={staticReadonlyRequired}
                />
              </Grid>
            ) : null
          }
          {
            templateType === "Dialog"?
              ( <DialogWrapper
                components={attributeList}
                subAppInfo = {subAppInfo}
                functions={functions}
                componentProps={componentProps}
                componentState={componentState}
                values={stateValues.values}
                dialogOptions={dialogOptions}
                dialogUtils={dialogUtils}
                ChildSubApplicationList={ChildSubApplicationList}
                pagelevelValues={stateValues.pagelevelValues}
                transactionPrimaryObject={stateValues.transactionPrimaryObject}
                handleInputChange={functions.handleInputChange}
                handleInputChangeV2={functions.handleInputChangeV2}
                handleAutoCompletechange={functions.handleAutoCompletechange}
                handleDateChange={functions.handleDateChange}
                handleHiddenInputChange={functions.handleHiddenInputChange}
                updateValueWithSubplicationName={functions.updateValueWithSubplicationName}
                handlePersistValue={functions.handlePersistValue}
                UpdateState={functions.UpdateState}
                handleAttachments={functions.handleAttachments}
                handleRTEChange={functions.handleRTEChange}
                {...utilObject}
              />) : null
          }
        </Grid>

        let FormContent = (props) =>{
          let a;
          if(templateType === "Detail" || templateType === "Accordion" || templateType === "Form")
          {
          a=

          <DynamicComponent
                pageInfo={componentProps.pageInfo}
                pageName={pageName}
                components={attributeList}
                IsExpandable={IsExpandable}
                values={stateValues.values}
                updateValues={stateValues.updateValues}
                transactionPrimaryObject={stateValues.transactionPrimaryObject}
                ChildSubApplicationList={ChildSubApplicationList}
                functions={functions}
                componentProps={componentProps}
                componentState={componentState}
                TableControls={TableControls}
                summarySubApp={summarySubApp}
                detailSummaryList={detailSummaryList}
                pagelevelValues={stateValues.pagelevelValues}
                handleInputChange={functions.handleInputChange}
                handleInputChangeV2={functions.handleInputChangeV2}
                handleAutoCompletechange={functions.handleAutoCompletechange}
                handleDateChange={functions.handleDateChange}
                handleHiddenInputChange={functions.handleHiddenInputChange}
                invokeGetAccount={functions.invokeGetAccount}
                updateValueWithSubplicationName={functions.updateValueWithSubplicationName}
                handleRTEChange={functions.handleRTEChange}
                handlePersistValue={functions.handlePersistValue}
                callback={functions.callback}
                {...utilObject}
                // {...subappUtilFunctions}
                staticReadonlyRequired={staticReadonlyRequired}
              />
          }
          if(templateType === "Dialog")
          {
            a =
            <DialogWrapper
              components={attributeList}
              subAppInfo = {subAppInfo}
              functions={functions}
              componentProps={componentProps}
              componentState={componentState}
              values={stateValues.values}
              dialogOptions={dialogOptions}
              dialogUtils={dialogUtils}
              ChildSubApplicationList={ChildSubApplicationList}
              pagelevelValues={stateValues.pagelevelValues}
              transactionPrimaryObject={stateValues.transactionPrimaryObject}
              handleInputChange={functions.handleInputChange}
              handleInputChangeV2={functions.handleInputChangeV2}
              handleAutoCompletechange={functions.handleAutoCompletechange}
              handleDateChange={functions.handleDateChange}
              handleHiddenInputChange={functions.handleHiddenInputChange}
              updateValueWithSubplicationName={functions.updateValueWithSubplicationName}
              handlePersistValue={functions.handlePersistValue}
              UpdateState={functions.UpdateState}
              handleAttachments={functions.handleAttachments}
              handleRTEChange={functions.handleRTEChange}
              {...utilObject}
            />
          }
      if(templateType !== "Dialog" && templateType !== "Detail" && templateType !== "Accordion" && templateType !== "Summary" && templateType !== "PivotSummary" && templateType !== "Form" && templateType !== "ValidationMessages")
      {
        a=<RenderTemplate
                  {...props}
                  Template={reduxState.navigationReducer.templateInfo[templateType]}
                  pageName={pageName}
                  subapplicationName={subapplicationName}
                  SubApplicationDetail = {subapplication}
                  components={attributeList}
                  utilfunction={utilObject}
                  pagelevelValues={stateValues.pagelevelValues}
                  values={stateValues.values}
                  transactionPrimaryObject={stateValues.transactionPrimaryObject}
                  parentProps={stateValues}
                  staticReadonlyRequired={staticReadonlyRequired}
                  componentProps={componentProps}
                  componentState={componentState}
                >
                </RenderTemplate>
      }
      if((templateType === "Summary" || templateType === "PivotSummary" || templateType === "EditableSummary") && !dynamicTable)
      {
        a=<Grid container className="topbottommargin">
                <DataTable
                  subAppInfo={subAppInfo}
                  columnList={componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_columnList") ? componentState[subapplicationName.toLowerCase() + "_columnList"] : []}
                  dataList={dataList}
                  UpdateState={functions.UpdateState}
                  getDocument={utilObject.getDocument}
                  TableOptions={tableOptions}
                  tableUtils={tableUtils}
                  ChildSubApplicationList={ChildSubApplicationList}
                  componentProps={componentProps}
                  componentState={componentState}
                  TableControls={TableControls}
                  functions={functions}
                  hierarchicalRendering={hierarchicalRendering}
                  IsExpandable={IsExpandable}
                  {...childFormInfo}
                  staticReadonlyRequired={staticReadonlyRequired}
                  pageInfo={componentProps.pageInfo || componentState.pageInfo || null}
                  {...utilObject}
                >
                  {TableControls.TableOptions.ChildrenComponent != null && <TableControls.TableOptions.ChildrenComponent {...componentProps} {...componentState} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} />}
                </DataTable>
              </Grid>
      }
      if((templateType === "Summary" || templateType === "PivotSummary" || templateType === "EditableSummary") && dynamicTable)
      {
        a=<Grid container className="topbottommargin">
                <DataTableV2
                  subAppInfo={subAppInfo}
                  columnList={componentState.hasOwnProperty(subapplicationName.toLowerCase() + "_columnList") ? componentState[subapplicationName.toLowerCase() + "_columnList"] : []}
                  dataList={dataList}
                  dataListSize={dataListSize}
                  dataTableUpdate={dataTableUpdate}
                  UpdateState={functions.UpdateState}
                  getDocument={utilObject.getDocument}
                  TableOptions={tableOptions}
                  tableUtils={tableUtils}
                  tableActions={mActionList}
                  tableFunctions={tableFunctions}
                  ChildSubApplicationList={ChildSubApplicationList}
                  componentProps={componentProps}
                  componentState={componentState}
                  parentProps={parentProps}
                  TableControls={TableControls}
                  functions={functions}
                  hierarchicalRendering={hierarchicalRendering}
                  IsExpandable={IsExpandable}
                  {...childFormInfo}
                  {...utilObject}
                  staticReadonlyRequired={staticReadonlyRequired}
                  pageInfo={componentProps.pageInfo || componentState.pageInfo || null}
                >
                  {/* {TableControls.TableOptions.ChildrenComponent != null && <TableControls.TableOptions.ChildrenComponent {...componentProps} {...componentState} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName} />} */}
                  {isChildComponent && <ChildComponent {...componentProps} {...componentState} ActionList={TableControls.TableOptions.ChildAction} summarySubApp={summarySubApp} detailSummaryList={detailSummaryList} subapplicationName={subapplicationName}/> }
                </DataTableV2>
              </Grid>
      }

            return (a);
    }

        let childform = IsEmptyFormNeeded === "Y" ?
          <ChildFormComponent
            pageName={pageName}
            subapplicationName={subapplicationName}
            IsSubmitButtonNeeded={IsSubmitButtonNeeded}
            IsEmptyFormNeeded={IsEmptyFormNeeded}
            ButtonType={ButtonType}
            componentProps={componentProps}
            componentState={componentState}
            UpdateState={functions.UpdateState}
            SubmitButtonName={SubmitButtonName}
            AdditionalButtonName={AdditionalButtonName}
            AdditionalButtonClickEvent={AdditionalButtonClickEvent}
            utilfunction={{...utilObject}}
            // utilfunction={{...utilObject, ...subappUtilFunctions}}
            staticReadonlyRequired={staticReadonlyRequired}
          >
            {FormContent()}
          </ChildFormComponent> : type;

        let expandableComponent = (IsExpandable === 'Y' && templateType !== "Summary" && templateType !== "PivotSummary" && templateType !== "EditableSummary") ?
        <AccordiononErrorContextConsumer>
            {
              (AccordiononError) => {
               return <ExpandableComponent pageName={pageName} openByDefault={openByDefault} expandByDefault={expandKey}  {...AccordiononError}>
                  {childform}
                </ExpandableComponent>
              }
            }
          </AccordiononErrorContextConsumer> : childform
        let StyledComponent = StyleClass ? <div className={StyleClass}>{expandableComponent}</div> : expandableComponent;
        let {StyleClass:SubapplicationClassName='', ...customProperties} = subapplication.mAction && subapplication.mAction.ComponentProperties ? subapplication.mAction.ComponentProperties : {};
        let properties = {lg: 12, md: 12, xs: 12, sm: 12, ...customProperties}
        return (
          // attributeList.length > 0 &&
          <Fragment key={subapplication.SubApplicationVersionId}>
            <Grid item {...properties} className={`${subapplicationName}__wrapperClass ${SubapplicationClassName}`} style={subappInvisible ? {display : "none"} : null}>
              <CardContent className="custom-indentation custom-margin-left">
                {StyledComponent}
              </CardContent>
            </Grid>
          </Fragment>
        );
      }
    )
  )
}

export default dynamicRenderV2;
