import * as actionTypes from "../actions/types";
export const getnavigationReducerInitialState = () => ({
  navigation: {},
  pageNavigation: {},
  templateInfo: {},
});
const initialState = getnavigationReducerInitialState();

export const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TEMPLATE_INFO:
      return {
        ...state,
        templateInfo: action.payload,
      };
    case actionTypes.SET_NAVIGATION:
      return {
        ...state,
        navigation: action.payload,
      };
    case actionTypes.SET_PAGE_NAVIGATION:
      return {
        ...state,
        pageNavigation: action.payload,
      };
      case actionTypes.SET_NAVIGATION_INFO:
            return {
                ...state,
                navigation: action.payload,
      };
      case actionTypes.SET_PAGENAME:
        return {
            ...state,
            pageName: action.payload
        }
      case actionTypes.SET_RIDERECT_TO:
        return {
          ...state,
          navigateTo: action.payload
        }
    default:
      return state;
  }
};
