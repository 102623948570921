import React from 'react'
import { Grid, Icon, IconButton } from '@material-ui/core'
import { displayAction } from '../utils/Common/CommonUtils'
import { commonLinkMethod } from './ButtonUtil'

const DynamicIcon = (props) => {
  let { subAppInfo, rowData, tableFunctions, component } = props
  let SubApplicationDetail = subAppInfo['SubApplicationDetail']
  let SubApplnName = SubApplicationDetail.ApplicationName
  let dataListName = SubApplnName.toLowerCase() + "_dataList"
  const { Action = {} } = component
  let filteredActions = []

  displayAction(Action.ActionList, rowData, filteredActions);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {filteredActions.length > 0 && filteredActions.map((option, optindex) => (
        <>
           <Grid  style={{ display: 'flex', flexDirection: 'row' }} >
              {option.iconName && <IconButton className={`${component.StyleClass} ${component.IsReadonly? 'disabled': ''}`}  disabled={component.IsReadonly? true: false} onClick={(e) => { tableFunctions.hasOwnProperty(option.onOptionClick) ? tableFunctions[option.onOptionClick](dataListName, rowData, SubApplnName, props) : commonLinkMethod(component, rowData, props)}}>
                  <Icon fontSize={option.size?option.size.toLowerCase():'default'} color={option.color ? option.color.toLowerCase():'primary'}>{option.iconName}</Icon>
              </IconButton>}
              {option.label && <span onClick={(e) => { tableFunctions.hasOwnProperty(option.onOptionClick) ? tableFunctions[option.onOptionClick](dataListName, rowData, SubApplnName, props) : commonLinkMethod(component, rowData, props)}} className={`nav-link ${component.AttributeStyleClass}`} >{option.label}</span>}
          </Grid>
        </>))}
    </div>
  )
}

export default DynamicIcon
