import text from "./Text";
import textv2 from './TextV2'
import select from "./Select";
import selectv2 from "./SelectV2";
import radio from "./Radio";
import booleancheckbox from "./BooleanCheckbox";
import checkbox from "./Checkbox";
import subheading from "./Subheading";
import hidden from "./Hidden";
import calendar from "./Calendar";
import calendarv3 from "./CalendarV3";
import googleaddresslookup from "./GoogleAddressLookup";
import googlemapcomponent from "./GoogleMapComponentV3";
import googlemapcomponentv4 from "./GoogleMapComponentV4";
import googlemapcomponentv5 from "./GoogleMapComponentV5";
import googlemapcomponentv6 from "./GoogleMapComponentV6";
import autocomplete from "./AutoCompleteV1";
import effectivedate from "./EffectiveDate";
import sectionheading from "./Sectionheading";
import heading from "./Heading";
import email from "./Email";
import phone from "./Phone";
import phonev3 from "./PhoneV3";
import findbutton from "./FindButton";
import clearbutton from "./ClearButton";
import copyaccountasinsured from "./CopyAccountAsInsured";
import importexportbutton from "./ImportExportButton";
import addbutton from "./AddButton";
import copyaccountaddress from "./UseAccountAddress";
import currency from "./Currency";
import currencyv2 from "./CurrencyV2";
import staticselect from "./StaticSelect";
import statictext from "./StaticText";
import staticdate from "./StaticDate";
import multiselect from './MultiSelect';
import multiselectv2 from './MultiSelectV2';
import copyinsuredaddress from "./UseInsuredAddress";
import inputselect from './InputSelect';
import celllinktext from './DataTable/CellLinkText';
import cellplaintext from './DataTable/CellPlainText';
import emptyspace from './EmptyAttribute'
import outputlabel from "./OutputLabel";
import outputtext from "./OutputText";
import outputlinktext from "./OutputLinkText";
import googlemapregion from './GoogleMapRegion';
import effectivedatev1 from './EffectiveDateV1';
import expirationdate from './ExpirationDate';
import autocompletev2 from './AutoCompleteV2';
import button from './Button';
import submit from './Button';
import customiterator from "./CustomIterator";
import textarea from "./Textarea";
import vin from "./VIN";
import autocompletev3 from './AutoCompleteV3';
import editbutton from "./EditIcon";
import multiplecheckbox from "./MultipleCheckBox";
import expand from "./CustomIterator";
import OtherDocumentation from "./OtherDocumentation";
import documentlink from './Document';
import ButtonPrimary from "./ButtonPrimary";
import homegrid from "./Homescreen";
import sign from "./sign"
import link from './Links'
import overlay from './Overlay'
import importbutton from "./Import";
import importv2 from "./ImportV2"
import multipleuploader from "./MultipleUploader";
import multipleuploaderv2 from "./MultipleUploaderV2";
import multipleuploaderv3 from "./MultipleUploaderV3";
import tooltippopover from "./Popover";
import tooltippopoverv2 from "./PopoverV3";
import textpopover from "./TextPopover";
import { InfoOutlined, LaunchOutlined,ArrowDownward as arrowdownward , ExpandMore as expandmore, MoreVert as morevert} from '@material-ui/icons';
import richtexteditorv2 from './RichtextEditorV2'
import googlelookup from "./GoogleLookup";
import popoverv2 from "./PopoverV2";
import multipleemail from './MultipleEmail';
import maskedcalendar from './MaskedCalendar';
import cellicon from "./DataTable/CellIcon";
import image from "./Image";
import dynamicicon from "./DynamicIcon";
import celllinktextv2 from "./DataTable/CellLinkTextV2";
import selectcalender from "./SelectCalender";
import dynamicselect from "./DynamicSelect";
import dynamicselectfilter from "./DynamicSelectFilter";
import actionmenu from "./ActionMenu";
import actionselect from "./ActionSelect";
import fieldvalidator from "./FieldValidator";
import titlebar from "../pages/TitleBar";
import calendarv2 from "./CalendarV2";
import spacer from "./Spacer";
import maskedtext from "./MaskedText";
import colorpalette from "./ColorPalette";
import autocompletev4 from './AutoCompleteV4';
import password from "./Password";
import skeleton from "./Skeleton";
import selectautocomplete from "./SelectFilter";
import radiov2 from "./RadioV2";
import smartinput from './SmartInput';
import iconcheckbox from "./IconCheckbox";
import emptyhidden from "./EmptyHidden";
import layoutloader from "./LayoutLoader";
import message from "./Message"


// import statefullcomponent from "./StateFullComponent";
const ControlsV4 = {
  text,
  textv2,
  calendarv3,
  select,
  selectv2,
  findbutton,
  clearbutton,
  radio,
  radiov2,
  booleancheckbox,
  checkbox,
  subheading,
  hidden,
  googlelookup,
  calendar,
  googleaddresslookup,
  multipleuploader,
  googlemapcomponent,
  autocomplete,
  effectivedate,
  sectionheading,
  heading,
  email,
  phone,
  copyaccountasinsured,
  importexportbutton,
  addbutton,
  copyaccountaddress,
  currency,
  currencyv2,
  staticselect,
  multiselect,
  multiselectv2,
  statictext,
  staticdate,
  copyinsuredaddress,
  inputselect,
  googlemapcomponentv4,
  googlemapcomponentv5,
  googlemapcomponentv6,
  celllinktext,
  cellplaintext,
  emptyspace,
  outputlabel,
  googlemapregion,
  effectivedatev1,
  expirationdate,
  textarea,
  outputtext,
  outputlinktext,
  autocompletev2,
  button,
  customiterator,
  vin,
  autocompletev3,
  editbutton,
  submit,
  multiplecheckbox,
  expand,
  OtherDocumentation,
  ButtonPrimary,
  link,
  homegrid,
  sign,
  overlay,
  importbutton,
  importv2,
  tooltippopover,
  tooltippopoverv2,
  textpopover,
  infooutlined: InfoOutlined,
  richtexteditorv2,
  popoverv2,
  launchoutlined: LaunchOutlined,
  multipleemail,
  maskedcalendar,
  documentlink,
  icon: cellicon,
  image,
  dynamicicon,
  celllinktextv2,
  selectcalender,
  dynamicselect,
  dynamicselectfilter,
  actionmenu,
  actionselect,
  fieldvalidator,
  titlebar,
  calendarv2,
  spacer,
  maskedtext,
  colorpalette,
  multipleuploaderv2,
  multipleuploaderv3,
  autocompletev4,
  arrowdownward ,
  expandmore,
  morevert,
  phonev3,
  password,
  // statefullcomponent,
  //eventButton,
  // button_next,
  // button_previous,
  skeleton,
  selectautocomplete,
  smartinput,
  iconcheckbox,
  emptyhidden,
  layoutloader,
  message
};

export default ControlsV4;
