import { getServiceRequestDetail, AxiosInstance } from '../../../utils/Common/API';
import { constructTransactionRequest } from '../../../utils/requestConstruction/DynamicReqConstruction';
import { format } from 'date-fns';
import store from "../../../../../store";
import appActions from "../../../../../actions/index";
import { LOBUtil } from '../../../utils/Common/CommonUtils';
import { Toast } from '../../../utils/Common/HelperUtils';


const { actionType } = appActions

export const errorToast = (message) => {
    Toast.error("Try again! Something went wrong. "+ message, { position: Toast.POSITION.TOP_CENTER })
}

export const successToast = (message) => {
    Toast.success(message, { position: Toast.POSITION.TOP_CENTER });
}

export const infoToast = (message) => {
    Toast.info(message, { position: Toast.POSITION.TOP_CENTER });
}

export const getStateList = async (props) => {

    const { OwnerId, masterEventName, getStateEvent } = props.pageInfo;

    let submissionID = "";

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let getStateListRequest = {};
    getStateListRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    getStateListRequest["OwnerId"] = OwnerId;
    getStateListRequest["EventName"] = masterEventName;
    getStateListRequest["TargetEventName"] = getStateEvent;
    getStateListRequest["ID"] = submissionID;

    try {
        let response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", getStateListRequest);
        if (Object.keys(response.data).length > 0 && response.data.StateList && response.data.StateList.length > 0) {
            const { data } = response;
            const stateList = data.StateList.reduce((initialValue, currentValue) => ([
                ...initialValue,
                currentValue.State
            ]), []);
            return stateList;
        }
        else {
            return [];
        }
    }
    catch (err) {
        console.log("Error in GetStateList API", err.message);
        return [];
    }

}


export const invokeGetPolicyByState = async (props, state) => {
    let { OwnerId, masterEventName, getPolicyEvent } = props.pageInfo;

    let submissionID = "";

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let getPolicyRequest = {};
    getPolicyRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    getPolicyRequest["OwnerId"] = OwnerId;
    getPolicyRequest["EventName"] = masterEventName;
    getPolicyRequest["TargetEventName"] = getPolicyEvent;
    getPolicyRequest["ROOTID"] = submissionID;
    getPolicyRequest["State"] = state;

    try {
        let response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", getPolicyRequest);
        response = await response.data;
        return response;
    }
    catch (err) {
        console.log("Error in GetPolicyInfoByState", err.message);
        return {};
    }
}

export const invokeAddVehicle = async (props, state) => {
    let { OwnerId, masterEventName, addVehicleEvent } = props.pageInfo;
    let productInfo = props.metadataReducer.navigation.productNavigation[props.metadataReducer.lob];
    let { currentPageIndex } = props.UIFlowReducer;
    let submissionID = "";
    let commercialAutoMobileID = "";
    const lobUtil = props.UIFlowReducer.productDetails.hasOwnProperty("LineOfBusiness") ? LOBUtil[props.UIFlowReducer.productDetails["LineOfBusiness"]] || {} : {};

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
            commercialAutoMobileID = props.metadataReducer.submissionResponse.CommercialAutomobile.ID
        }
    }

    let addVehicleRequest = Object.create({});
    addVehicleRequest = constructTransactionRequest(state, props, state.pagelevelValues, true, false);

    addVehicleRequest["OwnerId"] = OwnerId;
    addVehicleRequest["EventName"] = masterEventName;
    addVehicleRequest["TargetEventName"] = addVehicleEvent;
    addVehicleRequest["ROOTID"] = submissionID;
    addVehicleRequest["PARENTID"] = commercialAutoMobileID;
    addVehicleRequest["ModeOfSave"] = 'UI';
    addVehicleRequest["CoverageDetails"] = state.covlist ? state.covlist : [];
    try {
        let addVehicleresponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", addVehicleRequest);
        addVehicleresponse = await addVehicleresponse.data;
        if(productInfo[currentPageIndex - 1].IncludeForwardNav)
        {
            store.dispatch({ type: actionType.SOLARTISUIPAGENAVIGATIONSTATUS, payload: productInfo[currentPageIndex - 1].SolartisUIPageNavigationStatus });
            lobUtil.navigationStatus &&  lobUtil.navigationStatus(productInfo[currentPageIndex - 1].SolartisUIPageNavigationStatus)
        }
    } catch (e) {
        console.error("Error in AddVehicle", e.message);
    }

}

export const invokeUpdateVehicle = async (props, state) => {

    let { OwnerId, masterEventName, updateVehicleEvent } = props.pageInfo;

    let submissionID = "";

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let updateVehicleRequest = Object.create({});
    updateVehicleRequest = constructTransactionRequest(state, props, state.pagelevelValues, true, false);

    updateVehicleRequest["OwnerId"] = OwnerId;
    updateVehicleRequest["EventName"] = masterEventName;
    updateVehicleRequest["TargetEventName"] = updateVehicleEvent;
    updateVehicleRequest["ROOTID"] = submissionID;
    updateVehicleRequest["ModeOfSave"] = 'UI';

    try {
        let updateVehicleResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", updateVehicleRequest);
        updateVehicleResponse = await updateVehicleResponse.data;

        if (Object.keys(updateVehicleResponse).length > 0) {
            return updateVehicleResponse.hasOwnProperty("VehicleList") ? updateVehicleResponse["VehicleList"] : [];
        }
    }
    catch (e) {
        console.error("Error in UpdateVehicle", e.message);
        return [];
    }
}

export const invokeSelectiveDeleteVehicle = async (input) => {

    const { subApplicationName, state, props, handleBtnLoader } = input;
    const { OwnerId, selectiveDeleteVehicleEvent } = props.pageInfo;
    let submissionID = "";

    handleBtnLoader(true);

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let selectedVehicleList = [];
    let dataList = state[subApplicationName+ "_dataList"];

    for(const vehicleInfo of dataList) {
        if(vehicleInfo['SolartisVehicle'] === 'true') {
            selectedVehicleList.push({
                'ID' : vehicleInfo['ID']
            })
        }
    }

    let selectiveDeleteVehicleRequest = Object.create({});
    selectiveDeleteVehicleRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    selectiveDeleteVehicleRequest["OwnerId"] = OwnerId;
    selectiveDeleteVehicleRequest["EventName"] = selectiveDeleteVehicleEvent;
    selectiveDeleteVehicleRequest["ROOTID"] = submissionID;
    selectiveDeleteVehicleRequest["VehiclesID"] = selectedVehicleList;

    try {
        let selectiveDeleteResponse = await AxiosInstance.post("core/invokeWorkflowEvent", selectiveDeleteVehicleRequest);
        if(selectiveDeleteResponse.data && Object.keys(selectiveDeleteResponse.data.length > 0)) {
            const { VehicleList = null } = selectiveDeleteResponse.data;
            if(!VehicleList) throw new Error('Something Went Wrong');
            infoToast('Selected Vehicles Removed Successfully');
            return VehicleList;
        }
        else throw new Error('Something Went Wrong');
    }
    catch (e) {
        errorToast('');
        throw new Error('Something Went Wrong');
    }
    finally {
        handleBtnLoader(false);
    }
}

export const invokeCopyVehicle = async (props, vehicleDetail,state) => {
    let { OwnerId, EventName } = props.popupDetail[props.popupName].eventInfo;
    let submissionID = "";
    let NoOfCopies = state.pagelevelValues.BulkCopyVehicle !==undefined?state.pagelevelValues.BulkCopyVehicle.NoOfCopies:1
    let vehicleID = vehicleDetail.ID;
    let lob = props.productDetails.LineOfBusiness;
    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let copyVehicleRequest = Object.create({});
    copyVehicleRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    copyVehicleRequest["OwnerId"] = OwnerId;
    copyVehicleRequest["EventName"] = EventName;
    // copyVehicleRequest["TargetEventName"] = EventName;
    copyVehicleRequest["ROOTID"] = submissionID;
    copyVehicleRequest["ID"] = vehicleID;
    copyVehicleRequest["NoOfCopies"] = NoOfCopies;
    copyVehicleRequest["lob"] =lob;

    try {
        let copyVehicleResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", copyVehicleRequest);
        copyVehicleResponse = await copyVehicleResponse.data;
        // console.log("copyVehicleResponse", copyVehicleResponse);
        if (Object.keys(copyVehicleResponse).length > 0) {
            return copyVehicleResponse.hasOwnProperty('VehicleList') ? copyVehicleResponse['VehicleList'] : [];
        }
    }
    catch (e) {
        console.error("Error in copyVehicleResponse", e.message);
        return [];
    }

}

export const invokeResequenceVehicle = async (props) => {
    const { OwnerId,  reSequenceAllVehicleEvent } = props.pageInfo;

    let submissionID = "";

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let reSequenceAllVehicleRequest = Object.create({});
    reSequenceAllVehicleRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    reSequenceAllVehicleRequest["OwnerId"] = OwnerId;
    reSequenceAllVehicleRequest["EventName"] = reSequenceAllVehicleEvent;
    reSequenceAllVehicleRequest["ROOTID"] = submissionID;

    try {
        let reSequenceAllVehicleResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", reSequenceAllVehicleRequest);
        reSequenceAllVehicleResponse = await reSequenceAllVehicleResponse.data;
        if (Object.keys(reSequenceAllVehicleResponse).length > 0) {
            return reSequenceAllVehicleResponse.hasOwnProperty('VehicleList') ? reSequenceAllVehicleResponse['VehicleList'] : [];
        }
    }
    catch (e) {
        console.error("Error in reSequenceAllVehicle API", e.message);
        return [];
    }

}

export const invokeGetVehicle = async (props) => {
    let { OwnerId, masterEventName, getVehicleEvent } = props.pageInfo;
    const { rowData, transactionPrimaryObject, productDetails } = props;
    let currentDate     = format(new Date(), 'yyyy-MM-dd');
    let EffectiveDate   = props.values ? (props.values.EffectiveDate ? props.values.EffectiveDate : currentDate) : currentDate;
    let submissionID = "";
    let vehicleID = rowData.ID;
    let state = rowData.SolartisState;
    let Hierarchy = rowData.SolartisHierarchy;
    let ProductNumber = productDetails.ProductNumber;
    let ProductVerNumber = productDetails.ProductVerNumber;
    let LOB = productDetails.LineOfBusiness;
    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let getVehicleRequest = Object.create({});

    getVehicleRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    getVehicleRequest["OwnerId"] = OwnerId;
    getVehicleRequest["EventName"] = masterEventName;
    getVehicleRequest["TargetEventName"] = getVehicleEvent;
    getVehicleRequest["ROOTID"] = submissionID;
    getVehicleRequest["ID"] = vehicleID;
    getVehicleRequest["ProductNumber"] = ProductNumber;
    getVehicleRequest["ProductVerNumber"] = ProductVerNumber;
    getVehicleRequest["EffectiveDate"] = EffectiveDate;
    getVehicleRequest["CoverageType"] = "Additional Coverage";
    getVehicleRequest["Hierarchy"] = Hierarchy;
    getVehicleRequest["State"] = state;
    getVehicleRequest["LOB"] = LOB;
    if (transactionPrimaryObject.Policy) {
        getVehicleRequest["PolicyType"] = transactionPrimaryObject.Policy.PolicyType;
        getVehicleRequest["IndividualNamedInsuredOnThePolicy"] = transactionPrimaryObject.Policy.IndividualNamedInsuredOnThePolicy;
    }
    try {
        let getVehicleResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", getVehicleRequest);
        getVehicleResponse = await getVehicleResponse.data;
        if (Object.keys(getVehicleResponse).length > 0) {
            return getVehicleResponse;
        }
    }
    catch (e) {
        console.error("Error in getVehicleInfo", e.message);
        return {};
    }
}

export const invokeGetVehicleList = async (props) => {
    let { OwnerId, masterEventName, getVehicleListEvent } = props.pageInfo;

    let submissionID = "";
    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let getVehicleListRequest = Object.create({});
    getVehicleListRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    getVehicleListRequest["OwnerId"] = OwnerId;
    getVehicleListRequest["EventName"] = masterEventName;
    getVehicleListRequest["TargetEventName"] = getVehicleListEvent;
    getVehicleListRequest["ID"] = submissionID;

    // console.log("getVehicleListRequest", getVehicleListRequest);

    try {
        let getVehicleListResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", getVehicleListRequest);
        getVehicleListResponse = await getVehicleListResponse.data;
        if (Object.keys(getVehicleListResponse).length > 0) {
            return getVehicleListResponse.hasOwnProperty("VehicleList") ? getVehicleListResponse["VehicleList"] : [];
        }
    } catch (e) {
        console.error("Error in getVehicleList", e.message);
        return [];
    }
}

export const invokeGetVehicleListByQuote = async (props) => {
    let { OwnerId } = props.pageInfo;

    let quoteId = "";
    if (props.UIFlowReducer.quoteID) {
        quoteId = props.UIFlowReducer.quoteID
    }

    let getVehicleListRequest = Object.create({});
    getVehicleListRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    getVehicleListRequest["OwnerId"] = OwnerId;
    getVehicleListRequest["EventName"] = 'GetVehicleListQuote_WF';
    getVehicleListRequest["ID"] = quoteId;

    try {
        let getVehicleListResponse = await AxiosInstance.post("core/invokeWorkflowEvent", getVehicleListRequest);
        getVehicleListResponse = await getVehicleListResponse.data;
        if (Object.keys(getVehicleListResponse).length > 0) {
            return getVehicleListResponse.hasOwnProperty("VehicleList") ? getVehicleListResponse["VehicleList"] : [];
        }
    } catch (e) {
        console.error("Error in getVehicleList", e.message);
        return [];
    }
}

const checkIsValueExist = (pagelevelValues, SubApplicationName, attributeName) => {
    return pagelevelValues[SubApplicationName] ? pagelevelValues[SubApplicationName][attributeName] ? pagelevelValues[SubApplicationName][attributeName] : '' : '';
}

export const vehicleVINDecode = async (props, componentInfo, state) => {

    const { OwnerId, vinDecodeEvent } = props.pageInfo;
    const { pagelevelValues } = state;
    const { SubApplicationName, EventName } = componentInfo;
    let SubmissionNumber = "";

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            SubmissionNumber = props.metadataReducer.submissionResponse.SubmissionNumber
        }
    }

    let vinDecodeRequest = Object.create({});
    vinDecodeRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    vinDecodeRequest["OwnerId"] = OwnerId;
    vinDecodeRequest["EventName"] = EventName ? EventName : vinDecodeEvent;
    vinDecodeRequest["SubmissionNumber"] = SubmissionNumber;
    vinDecodeRequest["VIN"] = checkIsValueExist(pagelevelValues, SubApplicationName, 'VIN');
    vinDecodeRequest["Year"] = checkIsValueExist(pagelevelValues, SubApplicationName, 'Year');
    vinDecodeRequest["Make"] = checkIsValueExist(pagelevelValues, SubApplicationName, 'Make');
    vinDecodeRequest["Model"] = checkIsValueExist(pagelevelValues, SubApplicationName, 'Model');
    vinDecodeRequest["GrossVehicleWeight"] = checkIsValueExist(pagelevelValues, SubApplicationName, 'GrossVehicleWeight');
    vinDecodeRequest["OriginalCostNewOfVehicle"] = checkIsValueExist(pagelevelValues, SubApplicationName, 'OriginalCostNewOfVehicle');

    try {
        let vinDecodeResponse = await AxiosInstance.post("core/invokeWorkflowEvent", vinDecodeRequest);
        if (Object.keys(vinDecodeResponse.data).length > 0) {
            return vinDecodeResponse.data;
        }
    } catch (e) {
        console.error("Error in vinDecode", e.message);
        return {};
    }

}

export const vinVerifyForAllVehicle = async (props) => {

    const { OwnerId, vinVerifyForAllVehicle, masterEventName } = props.pageInfo;
    let submissionID = "";

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let vinVerifyForAllRequest = Object.create({});
    vinVerifyForAllRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    vinVerifyForAllRequest["OwnerId"] = OwnerId;
    vinVerifyForAllRequest["EventName"] = masterEventName;
    vinVerifyForAllRequest["TargetEventName"] = vinVerifyForAllVehicle;
    vinVerifyForAllRequest["ROOTID"] = submissionID;

    try {
        let vinVerifyForAllResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", vinVerifyForAllRequest);
        if (Object.keys(vinVerifyForAllResponse.data).length > 0) {
            return vinVerifyForAllResponse.data.hasOwnProperty("VehicleList") ? vinVerifyForAllResponse.data["VehicleList"] : [];;
        }
    } catch (e) {
        console.error("Error in vinVerification for all vehicle", e.message);
        return {};
    }

}

/* export const isBulkUpdateAttribute = (AttributeName, attributeValue) => {
    let result = {
        [AttributeName] : {
            "IsHiddenAttribute" : "No",
            [AttributeName] : attributeValue
        }
    }

    return result;
    // return { [AttributeName] : attributeValue };
 } */

 /*
   * Function Used for attributes which need to update only for a specific vehicle not Bulk Update.
   * ex : (VIN)
 */
export const isVehicleSpecificBulkUpdateAtt = (AttributeName, attributeValue) => {
    let result = {
        [AttributeName] : {
            "IsHiddenAttribute" : "No",
            [AttributeName] : attributeValue
        }
    }

    return result;
}

 /*
   * Function Used for attributes which need to update only for a specific vehicle not Bulk Update.
   * attributes are not shown in the UI, but its values are passed in the Request(like a hidden Attribute)
   * ex : (VINIsOverride, VIN_prior)
 */
export const isBulkUpdateHiddenAttribute = (AttributeName, attributeValue) => {
    let result = {
        [AttributeName] : {
            "IsHiddenAttribute" : "Yes",
            [AttributeName] : attributeValue
        }
    }

    return result;
}

export const invokeBulkUpdate = async (props, state, bulkUpdateInput) => {

    const { rowData } = props;
    const { OwnerId, vehicleBulkUpdateInformation , masterEventName } = props.pageInfo;
    const { bulkUpdateEvent } = vehicleBulkUpdateInformation;
    const { AttributesList } = bulkUpdateInput;
    let submissionID = "";
    let updatedAttributeList = AttributesList.map(attribute => {
        Object.keys(attribute).forEach(key => {
            if(typeof key === undefined) delete attribute[key];
        })
        attribute['rowMetaData'] && delete attribute['rowMetaData'];
        attribute['ID'] && delete attribute['ID'];
        return attribute;
    });

    if (Object.keys(props.metadataReducer.submissionResponse).length > 0) {
        if (props.metadataReducer.submissionResponse.hasOwnProperty("SubmissionID")) {
            submissionID = props.metadataReducer.submissionResponse.SubmissionID
        }
    }

    let bulkUpdateRequest = Object.create({});
    bulkUpdateRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    bulkUpdateRequest["OwnerId"] = OwnerId;
    bulkUpdateRequest["EventName"] = masterEventName;
    bulkUpdateRequest["TargetEventName"] = bulkUpdateEvent;
    bulkUpdateRequest["ROOTID"] = submissionID; // submisssion ID
    bulkUpdateRequest["ID"] = rowData.ID || ''; // vehicle ID
    bulkUpdateRequest["PARENTID"] = rowData.PARENTID || ''; // Policy ID
    bulkUpdateRequest["AttributesList"] = updatedAttributeList;
    bulkUpdateRequest["ModeOfSave"] = 'UI';
    bulkUpdateRequest = {
        ...bulkUpdateInput,
        ...bulkUpdateRequest
    }
    try {
        let bulkUpdateResponse = await AxiosInstance.post("core/invokeWorkflowEvent/V2", bulkUpdateRequest);
        if (Object.keys(bulkUpdateResponse.data).length > 0) {
            return bulkUpdateResponse.data.hasOwnProperty("VehicleList") ? bulkUpdateResponse.data["VehicleList"] : [];
        }
        else throw new Error('Data not present');
    } catch (e) {
        console.error("Error in Bulk update", e.message);
        return [];
    }

}
