import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { stateAbbreviations } from "../utils/Common/config";

// const state = store && store.getState();
// const clientInfo = state ? state.ownerResources.clientInfo : '';

// Geocode.setApiKey(clientInfo);
Geocode.enableDebug();

class GoogleMapComponentV4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      street_number: "",
      street_name: "",
      street_detail: "",
      city: "",
      state: "",
      country: "",
      county: "",
      zipcode: "",
      SOLFullAddress: "",
      SOLAddress1: "",
      SOLAddress2: "",
      SOLCity: "",
      SOLState: "",
      SOLCountry: "",
      SOLZipcode: "",
      SOLZipcode_Suffix: "",
      SOLCounty: "",
      SOLLat: "",
      SOLLng: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      keylist: [],
      props: this.props,
    };
  }
  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    const { clientInfo } = this.props.ownerResources;
    Geocode.setApiKey(clientInfo.GoogleMapsAPIKey);
    //this.getAddressFromLatLong(this.state.mapPosition.lat, this.state.mapPosition.lng);
    //let position = this.onAddressChanged("100 Main St, Mountain Brook, AL 35213, USA");
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      // console.log("From ManualValidation",value);
      if (!value) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isValidCountry", (value, country) => {
    //   console.log("From ManualValidation", value, country);
      if (value === country) {
        return true;
      }
      return false;
    });
    if(this.props.pagelevelValues && this.props.pagelevelValues[this.props.component.SubApplicationName] && this.props.pagelevelValues[this.props.component.SubApplicationName][
      "StreetNameAddress2"]){
        this.state['SOLAddress2'] = this.props.pagelevelValues[this.props.component.SubApplicationName]["StreetNameAddress2"]
      }
    if(this.props.pagelevelValues && this.props.pagelevelValues[this.props.component.SubApplicationName] && this.props.pagelevelValues[this.props.component.SubApplicationName][
      "Latitude"] && this.props.pagelevelValues[this.props.component.SubApplicationName]["Longitude"] ){
        this.state.markerPosition['lat'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Latitude"])
        this.state.markerPosition['lng'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Longitude"])
        this.state.mapPosition['lat'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Latitude"])
        this.state.mapPosition['lng'] = Number(this.props.pagelevelValues[this.props.component.SubApplicationName]["Longitude"])
      }
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */

  shouldComponentUpdate(nextProps, nextState) {
    //  console.log("nextProps in google compont",nextProps.pagelevelValues);
    //  console.log("this.props in google compont",this.props.pagelevelValues);
    //  console.log("nextProps component in google compont",nextProps.component);
    //  console.log("this.props component  in google compont",this.props.component);
    if (
      this.props.ownerResources.userLanguage !==
      nextProps.ownerResources.userLanguage
    )
      return true;
    if (
      this.state.markerPosition.lat !== nextState.markerPosition.lat ||
      this.state.address !== nextState.address ||
      this.state.SOLFullAddress !== nextState.SOLFullAddress ||
      this.state.SOLCity !== nextState.SOLCity ||
      this.state.SOLAddress1 !== nextState.SOLAddress1 ||
      this.state.SOLAddress2 !== nextState.SOLAddress2 ||
      this.state.street_detail !== nextState.street_detail ||
      this.state.SOLState !== nextState.SOLState ||
      this.state.SOLCountry !== nextState.SOLCountry ||
      this.state.county !== nextState.county ||
      this.state.SOLZipcode !== nextState.SOLZipcode ||
      this.state.SOLZipcode_Suffix !== nextState.SOLZipcode_Suffix ||
      this.state.SOLCounty !== nextState.SOLCounty
    ) {
      return true;
    }
    if (
      this.props.pagelevelValues[this.props.component.SubApplicationName] ===
        undefined &&
      nextProps.pagelevelValues[nextProps.component.SubApplicationName] !=
        undefined
    ) {
      return true;
    }
    if (
      this.props.pagelevelValues[this.props.component.SubApplicationName] !=
        undefined &&
      nextProps.pagelevelValues[nextProps.component.SubApplicationName] ===
        undefined
    ) {
      return true;
    } else if (
      this.props.pagelevelValues[this.props.component.SubApplicationName] !=
        undefined &&
      typeof this.props.pagelevelValues[
        this.props.component.SubApplicationName
      ] === "object"
    ) {
      if (
        this.props.pagelevelValues[this.props.component.SubApplicationName][
          "FullAddress"
        ] !==
          nextProps.pagelevelValues[nextProps.component.SubApplicationName][
            "FullAddress"
          ] ||
        this.props.pagelevelValues[this.props.component.SubApplicationName][
          "SOLFullAddress"
        ] !==
          nextProps.pagelevelValues[nextProps.component.SubApplicationName][
            "SOLFullAddress"
          ] ||
        this.props.pagelevelValues[this.props.component.SubApplicationName][
          this.props.component.AttributeName
        ] !==
          nextProps.pagelevelValues[nextProps.component.SubApplicationName][
            this.props.component.AttributeName
          ] ||
        this.props.component.IsReadonly != nextProps.component.IsReadonly
      ) {
        return true;
      }
      else if(this.props.pagelevelValues[this.props.component.SubApplicationName][
          "StreetNameAddress2"
      ] !==
           this.state["SOLAddress2"]){
            this.state['SOLAddress2']= this.props.pagelevelValues[this.props.component.SubApplicationName]["StreetNameAddress2"]
            return true
      }else if(this.props.component.ComponentLabel !== nextProps.component.ComponentLabel){
          return true
      } else {
        return false;
      }
    }else if(this.props.component.ComponentLabel !== nextProps.component.ComponentLabel){
        return true;
    }

    // &&
    return false

    // console.log("all city",this.state.SOLCity,this.props.pagelevelValues[this.props.component.SubApplicationName]["SOLCity"] , this.props.pagelevelValues[this.props.component.SubApplicationName]["City"] );
  }

  getAddressFromLatLong = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          SOLFullAddress = response.results[0].formatted_address,
          SOLAddress1 = this.getStreetNumber(addressArray),
          SOLAddress2 = this.getStreetName(addressArray),
          street_detail = SOLAddress1.concat(" ", SOLAddress2),
          SOLCity = this.getCity(addressArray),
          SOLState = this.getState(addressArray),
          county = this.getCounty(addressArray),
          SOLCountry = this.getCountry(addressArray),
          SOLZipcode = this.getZipcode(addressArray),
          SOLZipcode_Suffix = this.getZipcode_suffix(addressArray),
          SOLCounty = this.getCounty(addressArray);

        this.setState({
          address: address ? address : "",
          SOLFullAddress: SOLFullAddress ? SOLFullAddress : "",
          SOLAddress1: SOLAddress1 ? SOLAddress1 : "",
          SOLAddress2: SOLAddress2 ? SOLAddress2 : "",
          street_detail: street_detail ? street_detail : "",
          SOLCity: SOLCity ? SOLCity : "",
          SOLState: SOLState ? SOLState : "",
          county: county ? county : "",
          SOLCountry: SOLCountry ? SOLCountry : "",
          SOLZipcode: SOLZipcode ? SOLZipcode : "",
          SOLZipcode_Suffix: SOLZipcode_Suffix ? SOLZipcode_Suffix : "",
          SOLCounty: SOLCounty ? SOLCounty : "",
          SOLLat: lat ? lat : "",
          SOLLng: lng ? lng : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * Get the street_number and set the street_number input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getStreetNumber = (addressArray) => {
    let SOLAddress1 = "";
    //console.log("addressArray ", addressArray)
    for (let i = 0; i < addressArray.length; i++) {
      if ("street_number" === addressArray[i].types[0]) {
        SOLAddress1 = addressArray[i].long_name;
      }
      if ("route" === addressArray[i].types[0]) {
        SOLAddress1 = SOLAddress1 + " " + addressArray[i].long_name;
      }
    }
    return SOLAddress1;
  };

  /**
   * Get the SOLCity and set the SOLCity input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let SOLCity = "";
    for (let i = 0; i < addressArray.length; i++) {
      if ("locality" === addressArray[i].types[0]) {
        SOLCity = addressArray[i].long_name;
        return SOLCity;
      } else if ("sublocality_level_1" === addressArray[i].types[0]) {
        SOLCity = addressArray[i].long_name;
        return SOLCity;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getStreetName = (addressArray) => {
    let SOLAddress2 = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          // if ('route' === addressArray[i].types[j]) {
          // 	SOLAddress2 = addressArray[i].long_name;
          // }
          if ("Address_line_2" === addressArray[i].types[j]) {
            SOLAddress2 = addressArray[i].long_name;
          }
        }
      }
    }
    return SOLAddress2;
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let SOLState = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          SOLState = addressArray[i].short_name;
          if (stateAbbreviations[SOLState]) {
            SOLState = stateAbbreviations[SOLState]
          }
          return SOLState;
        }
      }
    }
  };

  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getZipcode = (addressArray) => {
    let SOLZipcode = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          SOLZipcode = addressArray[i].long_name;
          return SOLZipcode;
        }
      }
    }
  };

  getZipcode_suffix = (addressArray) => {
    let SOLZipcode_Suffix = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code_suffix" === addressArray[i].types[0]
        ) {
          SOLZipcode_Suffix = addressArray[i].long_name;
          return SOLZipcode_Suffix;
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let SOLCountry = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          SOLCountry = addressArray[i].short_name;
          return SOLCountry;
        }
      }
    }
  };

  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCounty = (addressArray) => {
    let county = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_2" === addressArray[i].types[0]
        ) {
          county = addressArray[i].long_name;
        }
        else if (addressArray[i].types[0] && "locality" === addressArray[i].types[0])
        {
          county = addressArray[i].long_name;
        }
      }
    }
    return county
  };
  /**
   * And function for SOLCity,state SOLZipcode address input
   * @param event
   */

  onChange = async (event) => {

    let mappedKey = ''
    let component= this.props.component
    let isEditable = component.Action && component.Action.isEditable && component.Action.isEditable[event.target.name]
    if (this.props.component.ItemTipParams !== "" && isEditable) {
    let addressheaders = this.props.component.ItemTipParams.split(",");
    addressheaders.map((attribute)=> {
     let [key1, key2] = attribute.split("~")
      if(key2===event.target.name) {
        mappedKey = key1
      }
    })
  }
  mappedKey = mappedKey || event.target.name
    this.setState({ [event.target.name]: event.target.value});
    this.props.functions.UpdateState("isValueChanged",true,false)
    await this.props.handleInputChangeV2({ [mappedKey]: event.target.value }, this.props.component,undefined,undefined,this.props);
    // await (this.props.handleInputChange ? this.props.handleInputChange(event,this.props.component) : this.props.handleInputChangeV2(event,this.props.component));
    let { SOLFullAddress, SOLAddress1, SOLAddress2, SOLCity } = this.state;
    let SubApplicationName = this.props.component.SubApplicationName;
    let { StreetNameAddress2 } = this.props.pagelevelValues[SubApplicationName];
    if (
      (StreetNameAddress2 && StreetNameAddress2 !== SOLAddress2) ||
      StreetNameAddress2 === ""
    ) {
      SOLAddress2 = StreetNameAddress2;
      let fullAddress = `${SOLAddress1},${SOLCity}, ${this.props.pagelevelValues[SubApplicationName].AccountState}, ${this.props.pagelevelValues[SubApplicationName].AccountZipcode}, ${this.props.pagelevelValues[SubApplicationName].AccountCountry}`;
      SOLFullAddress = String(fullAddress);
    }
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {};

  /**
   * When the marker is dragged you get the lat and long using the functions avafilable from event object.
   * Use geocode to get the address, SOLCity, SOLZipcode and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let props = this.props;
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    this.props.functions.UpdateState("isValueChanged",false,false)
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          SOLFullAddress = response.results[0].formatted_address,
          SOLAddress1 = this.getStreetNumber(addressArray),
          SOLAddress2 = this.getStreetName(addressArray),
          street_detail = SOLAddress1.concat(" ", SOLAddress2),
          SOLCity = this.getCity(addressArray),
          SOLState = this.getState(addressArray),
          county = this.getCounty(addressArray),
          SOLCountry = this.getCountry(addressArray),
          SOLZipcode = this.getZipcode(addressArray),
          SOLZipcode_Suffix = this.getZipcode_suffix(addressArray),
          SOLCounty = this.getCounty(addressArray);
        this.setState(
          {
            address: address ? address : "",
            SOLFullAddress: SOLFullAddress ? SOLFullAddress : "",
            SOLAddress1: SOLAddress1 ? SOLAddress1 : "",
            SOLAddress2: SOLAddress2 ? SOLAddress2 : "",
            street_detail: street_detail ? street_detail : "",
            SOLCity: SOLCity ? SOLCity : "",
            SOLState: SOLState ? SOLState : "",
            county: county ? county : "",
            SOLCountry: SOLCountry ? SOLCountry : "",
            SOLZipcode: SOLZipcode ? SOLZipcode : "",
            SOLZipcode_Suffix: SOLZipcode_Suffix ? SOLZipcode_Suffix : "",
            SOLCounty: SOLCounty ? SOLCounty : "",
            SOLLat: newLat ? newLat : "",
            SOLLng: newLng ? newLng : "",
            markerPosition: {
              lat: newLat,
              lng: newLng,
            },
            mapPosition: {
              lat: newLat,
              lng: newLng,
            },
          },
          () => {
            //					SOLFullAddress: SOLFullAddress,
            //					SOLAddress1: SOLAddress1,
            //					SOLAddress2: SOLAddress2,
            //					SOLCity: SOLCity,
            //					SOLState: SOLState,
            //					SOLCountry: SOLCountry,
            //					SOLZipcode: SOLZipcode,
            //					SOLCounty: SOLCounty,
            //					SOLLat: newLat,
            //					SOLLng: newLng
            let addressvalue = {};
            if (props.component.ItemTipParams !== "") {
              let addressheaders = props.component.ItemTipParams.split(",");
              addressheaders.map((addressheader) => {
                let address = addressheader.split("~");
                addressvalue[address[0]] = this.state[address[1]];
              });
              addressvalue["Lat"] = this.state.SOLLat.toString();
              addressvalue["Lng"] = this.state.SOLLng.toString();
            }

            props.updateValueWithSubplicationName(
              addressvalue,
              props.component
            );
          }
        );
      },
      (error) => {
        console.error(error);
      }
    );
  };

  onAddressChanged = (address) => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.getAddressFromLatLong(lat, lng);
      },
      (error) => {
        console.error("error while getting lat long from address :: ", error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    let props = this.props;
    this.props.functions.UpdateState("isValueChanged",false,false)
    const address = place.formatted_address,
      addressArray = place.address_components,
      SOLFullAddress = place.formatted_address,
      SOLAddress1 = this.getStreetNumber(addressArray),
      SOLAddress2 = this.getStreetName(addressArray),
      street_detail = SOLAddress1.concat(" ", SOLAddress2),
      SOLCity = this.getCity(addressArray),
      SOLState = this.getState(addressArray),
      county = this.getCounty(addressArray),
      SOLCountry = this.getCountry(addressArray),
      SOLZipcode = this.getZipcode(addressArray),
      SOLZipcode_Suffix = this.getZipcode_suffix(addressArray),
      SOLCounty = this.getCounty(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    // console.log("temp from map->",temp);
    // this.props.updateValues('SOLFullAddress', SOLFullAddress)
    // this.props.updateValues('SOLAddress1', SOLAddress1)
    // this.props.updateValues('SOLAddress2', SOLAddress2)
    // this.props.updateValues('SOLCity', SOLCity)
    // this.props.updateValues('SOLState', SOLState)
    // this.props.updateValues('SOLCountry', SOLCountry)
    // this.props.updateValues('SOLZipcode', SOLZipcode)
    // this.props.updateValues('SOLZipcode', SOLCounty)
    // this.props.updateValues('SOLLat', latValue)
    // this.props.updateValues('SOLLng', lngValue)
    // // Set these values in the state.
    this.setState(
      {
        address: address ? address : "",
        SOLFullAddress: SOLFullAddress ? SOLFullAddress : "",
        SOLAddress1: SOLAddress1 ? SOLAddress1 : "",
        SOLAddress2: SOLAddress2 ? SOLAddress2 : "",
        street_detail: street_detail ? street_detail : "",
        SOLCity: SOLCity ? SOLCity : "",
        SOLState: SOLState ? SOLState : "",
        county: county ? county : "",
        SOLCountry: SOLCountry ? SOLCountry : "",
        SOLZipcode: SOLZipcode ? SOLZipcode : "",
        SOLZipcode_Suffix: SOLZipcode_Suffix ? SOLZipcode_Suffix : "",
        SOLCounty: SOLCounty ? SOLCounty : "",
        SOLLat: latValue ? latValue : "",
        SOLLng: lngValue ? lngValue : "",
        markerPosition: {
          lat: latValue,
          lng: lngValue,
        },
        mapPosition: {
          lat: latValue,
          lng: lngValue,
        },
      },
      () => {
        //			SOLFullAddress: SOLFullAddress,
        //			SOLAddress1: SOLAddress1,
        //			SOLAddress2: SOLAddress2,
        //			SOLCity: SOLCity,
        //			SOLState: SOLState,
        //			SOLCountry: SOLCountry,
        //			SOLZipcode: SOLZipcode,
        //			SOLCounty: SOLCounty,
        //			SOLLat: latValue,
        //			SOLLng: lngValue
        let addressvalue = {};
        if (props.component.ItemTipParams !== "") {
          let addressheaders = props.component.ItemTipParams.split(",");
          addressheaders.map((addressheader) => {
            let address = addressheader.split("~");
            addressvalue[address[0]] = this.state[address[1]];
          });
          addressvalue["Lat"] = this.state.SOLLat.toString();
          addressvalue["Lng"] = this.state.SOLLng.toString();
          addressvalue["Latitude"] = this.state.SOLLat.toString();
          addressvalue["Longitude"] = this.state.SOLLng.toString();
        }

        props.updateValueWithSubplicationName(addressvalue, props.component);
      }
    );
  };
  OnkeyDown=(e)=>{
    if(e.code==="Enter")
    e.preventDefault();
  }

  render() {
    let addressDetails = {};
    const { t, component, variant } = this.props;
    let mandatory = {}
    if (this.props.component.ItemTipParams !== "") {
      let addressheaders = this.props.component.ItemTipParams.split(",");

      addressheaders.map((addressheader) => {
        let address = addressheader.split("~");

        if (this.props.pagelevelValues) {
          if (
            this.props.pagelevelValues[this.props.component.SubApplicationName]
          ) {
            addressDetails = {
              ...addressDetails,
              [address[1]]:
                this.props.pagelevelValues[
                  this.props.component.SubApplicationName
                ][address[0]] !== undefined
                  ? this.props.pagelevelValues[
                      this.props.component.SubApplicationName
                    ][address[0]]
                  : "",
            };
          } else {
            addressDetails = { ...addressDetails, [address[1]]: "" };
          }
        }
      });
    }
    // let FullAddress = '', Address1 = '', Address2 = '', City = '', State = '', County = '', Zipcode = '', Country = '';
    // if (this.props.pagelevelValues[this.props.component.SubApplicationName] !== undefined) {
    // 	FullAddress =  this.props.pagelevelValues[this.props.component.SubApplicationName]["FullAddress"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["FullAddress"] : '';
    // 	Address1 = this.props.pagelevelValues[this.props.component.SubApplicationName]["StreetNumberAddress1"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["StreetNumberAddress1"] : this.props.pagelevelValues[this.props.component.SubApplicationName]["Address1"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["Address1"] : '';
    // 	Address2 = this.props.pagelevelValues[this.props.component.SubApplicationName]["StreetNameAddress2"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["StreetNameAddress2"] : this.props.pagelevelValues[this.props.component.SubApplicationName]["Address2"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["Address2"] : '';
    // 	City = this.props.pagelevelValues[this.props.component.SubApplicationName]["City"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["City"] : '';
    // 	State = this.props.pagelevelValues[this.props.component.SubApplicationName]["StateRegionCode"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["StateRegionCode"] : this.props.pagelevelValues[this.props.component.SubApplicationName]["State"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["State"] : '';
    // 	County = this.props.pagelevelValues[this.props.component.SubApplicationName]["County"] !== undefined ?  this.props.pagelevelValues[this.props.component.SubApplicationName]["County"] : '';
    // 	Zipcode = this.props.pagelevelValues[this.props.component.SubApplicationName]["Zipcode1"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["Zipcode1"] : this.props.pagelevelValues[this.props.component.SubApplicationName]["ZipCode"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["ZipCode"] : '';
    // 	Country = this.props.pagelevelValues[this.props.component.SubApplicationName]["Country"] !== undefined ? this.props.pagelevelValues[this.props.component.SubApplicationName]["Country"] : '';
    // }

    let isRequired = this.props.component.IsRequired === "Y" ? true : false;
    let {IsMapNeeded} = component.Action
    const ln_placeHolderText = t(component.PlaceHolderText);
    const PlaceHolderText = ln_placeHolderText
      ? `${isRequired ? `${ln_placeHolderText} *` : ln_placeHolderText}`
      : "";
    // let componentLabel = isRequired ? this.props.component.ComponentLabel + ' *' : this.props.component.ComponentLabel;
    const validations = [];
    let validationsObj = { SOLAddress1: [], SOLCity: [], SOLState: [],SOLCounty: [] , SOLZipcode: [], SOLCountry: [], StreetNameAddress2: []}
    const errorMessages = [];
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    let RequiredObj = component.Action && component.Action.Required
     if(RequiredObj){
      Object.entries(RequiredObj).forEach(([key, value])=> {
       if(value){
        validationsObj = {...validationsObj, [key]: ["required", "IsEmpty"]}
       }
      })
    }
    else{
      if(isRequired){
        Object.keys(validationsObj).forEach((key)=>{
          validationsObj = {...validationsObj, [key]: ["required", "IsEmpty"]}
        })
      }
    }
    Object.entries(validationsObj).forEach(([key, value])=>{
      mandatory[key] = value.includes("required") ?  '*' : ''
    })
    let isReadOnly = this.props.component.IsReadonly;
    validationsObj.SOLZipcode.push("matchRegexp:^[0-9]{5}$");
    let validationsForAddress2;
    if(addressDetails.SOLAddress2)
    {
       validationsObj.StreetNameAddress2.push(`matchRegexp:^(?!\\s)([a-zA-Z0-9-.'\\s])*$`)
    }
    if (component.AttributeParams) {
      let eligibleCountry = component.AttributeParams.split("~");
       validationsObj.SOLCountry.push("isValidCountry:" + eligibleCountry[1])
    }

    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
          <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}
          disabled={isReadOnly}
        >
          {/* InfoWindow on top of marker */}
          {this.state.markerPosition.lat !== "" &&
            this.state.markerPosition.lng !== "" && (
              <React.Fragment>
                <Marker
                  google={this.props.google}
                  name={"Dolores park"}
                  draggable={isReadOnly === true ? false : true}
                  onDragEnd={this.onMarkerDragEnd}
                  position={{
                    lat: this.state.mapPosition.lat,
                    lng: this.state.mapPosition.lng,
                  }}
                />
                <Marker />
              </React.Fragment>
            )}
          {/* For Auto complete Search Box */}
          <Autocomplete
            style={{
              // width: "100%",
              // height: "40px",
              // paddingLeft: "16px",
              // top: "-24.5em",
              // marginBottom: "500px",
              // position: "relative",
              // borderTop: "none",
              // borderRight: "none",
              // borderLeft: "none",
            }}
            placeholder={PlaceHolderText}
            className="small_label google_autoComplete"
            onPlaceSelected={this.onPlaceSelected}
            onKeyDown={this.OnkeyDown}
            disabled={isReadOnly}
            types={[]}
            componentRestrictions={{ country: "us" }}
            autoComplete={autocomplete}
          />
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
       <Grid item xs={12} sm={12} md={12} lg={12} className={component.StyleClass}>
          {IsMapNeeded===false ? <Autocomplete
                                    apiKey={this.props.ownerResources.clientInfo.GoogleMapsAPIKey}
                                    style={{
                                    width: "100%",
                                    height: "40px",
                                    borderTop: "none",
                                    borderRight: "none",
                                    borderLeft: "none",
                                    }}
                                    placeholder={PlaceHolderText}
                                    onPlaceSelected={this.onPlaceSelected}
                                    disabled={isReadOnly}
                                    types={[]}
                                    componentRestrictions={{ country: "us" }}
                                    autoComplete={autocomplete}
                                    className="small_label"
              /> :  <div>
            <AsyncMap
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${this.props.ownerResources.clientInfo.GoogleMapsAPIKey}&libraries=places`}
              loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
              containerElement={<div className="googlemap_container" />}
              mapElement={
                <div
                  className='googlemap'
                />
              }
            />
          </div>}
          <div className="googlemap_bottom_section">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} className="hide">
                <TextValidator
                  name="SOLFullAddress"
                  placeholder={
                    addressDetails["SOLFullAddress"] !== ""
                      ? ""
                      : "Full Address"
                  }
                  //placeholder='Full Address'
                  label="Full Address"
                  //value={this.state.SOLFullAddress}
                  className={`${component.StyleClass} small_label lineHeight`}
                  value={addressDetails["SOLFullAddress"]}
                  onChange={this.onChange}
                  disabled={isReadOnly}
                  variant={variant}
                  fullWidth
                  InputLabelProps={{className:"custom-label-down small_label google-text-font"}}
                  autoComplete={autocomplete}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  name="SOLAddress1"
                  placeholder={
                    addressDetails["SOLAddress1"] !== "" ? "" : "Address Line 1"
                  }
                  //placeholder="Address Line 1"
                  label={`${t("Address Line 1")}${mandatory.SOLAddress1}`}
                  value={addressDetails["SOLAddress1"]}
                  //defaultValue={address1}
                  className={`custom-input ${component.StyleClass} lineHeight`}
                  onChange={this.onChange}
                  disabled={isReadOnly}
                  variant={variant}
                  validators={validationsObj.SOLAddress1}
                  errorMessages="Address line 1 is required"
                  fullWidth
                  InputLabelProps={{className:"custom-label-down small_label google-text-font"}}
                  autoComplete={autocomplete}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  className={`custom-input  ${component.StyleClass} lineHeight`}
                  name="SOLAddress2"
                  placeholder={ addressDetails["SOLAddress2"] !== "" ? "" : "Address Line 2" }
                  label="Address Line 2"
                  value={addressDetails["SOLAddress2"]}
                  onChange={this.onChange}
                  validators={validationsObj.StreetNameAddress2}
                  disabled={isReadOnly}
                  variant={variant}
                  errorMessages={[ "Invalid Address Line 2","Invalid Address Line 2","Invalid Address Line 2" ]}
                  fullWidth
                  InputLabelProps={{className:"custom-label-down small_label google-text-font"}}
                  autoComplete={autocomplete}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  className={`custom-input ${component.StyleClass} lineHeight`}
                  name="SOLCity"
                  placeholder={addressDetails["SOLCity"] !== "" ? "" : "City"}
                  label={`${t("City")}${mandatory.SOLCity}`}
                  value={addressDetails["SOLCity"]}
                  onChange={this.onChange}
                  validators={validationsObj.SOLCity}
                  disabled={isReadOnly}
                  variant={variant}
                  errorMessages="City is required"
                  fullWidth
                  InputLabelProps={{className:"custom-label-down small_label google-text-font"}}
                  autoComplete={autocomplete}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  className={`custom-input  ${component.StyleClass} lineHeight`}
                  name="SOLState"
                  placeholder={addressDetails["SOLState"] !== "" ? "" : "State"}
                  label={`${t("State")}${mandatory.SOLState}`}
                  value={addressDetails["SOLState"]}
                  disabled={isReadOnly}
                  onChange={this.onChange}
                  validators={validationsObj.SOLState}
                  errorMessages="State is  required"
                  variant={variant}
                  fullWidth
                  InputLabelProps={{className:"custom-label-down small_label google-text-font"}}
                  autoComplete={autocomplete}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  className={`custom-input ${component.StyleClass} lineHeight`}
                  name="SOLCounty"
                  placeholder={
                    addressDetails["SOLCounty"] !== "" ? "" : "County"
                  }
                  label={`${t("County")}${mandatory.SOLCounty}`}
                  value={addressDetails["SOLCounty"]}
                  onChange={this.onChange}
                  disabled={isReadOnly}
                  variant={variant}
                  validators={validationsObj.SOLCounty}
                  errorMessages="County is  required"
                  fullWidth
                  InputLabelProps={{className:"custom-label-down small_label google-text-font"}}
                  autoComplete={autocomplete}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  className={`custom-input ${component.StyleClass} lineHeight`}
                  name="SOLZipcode"
                  placeholder={
                    addressDetails["SOLZipcode"] !== "" ? "" : "Zipcode"
                  }
                  label={`${t("Zip Code")}${mandatory.SOLZipcode}`}
                  value={addressDetails["SOLZipcode"]}
                  onChange={this.onChange}
                  validators={validationsObj.SOLZipcode}
                  disabled={isReadOnly}
                  variant={variant}
                  errorMessages={[
                    "Zipcode is required",
                    "Zipcode is required",
                    "Invalid Zipcode",
                  ]}
                  fullWidth
                  InputLabelProps={{className:"custom-label-down small_label google-text-font"}}
                  autoComplete={autocomplete}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  className={`custom-input ${component.StyleClass} lineHeight`}
                  name="SOLZipcode_Suffix"
                  placeholder={
                    addressDetails["SOLZipcode_Suffix"] !== ""
                      ? ""
                      : "Zipcode 2"
                  }
                  label={`${t("Zip Code 2")}`}
                  value={addressDetails["SOLZipcode_Suffix"]}
                  onChange={this.onChange}
                  disabled={isReadOnly}
                  variant={variant}
                  fullWidth
                  InputLabelProps={{className:"custom-label-down small_label google-text-font"}}
                  autoComplete={autocomplete}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  className={`custom-input ${component.StyleClass} lineHeight`}
                  name="SOLCountry"
                  placeholder={
                    addressDetails["SOLCountry"] !== "" ? "" : "Country"
                  }
                  label={`${t("Country")}${mandatory.SOLCountry}`}
                  value={addressDetails["SOLCountry"]}
                  onChange={this.onChange}
                  validators={validationsObj.SOLCountry}
                  disabled={isReadOnly}
                  variant={variant}
                  errorMessages={[
                    "Country is  required",
                    "Country is  required",
                    "Invalid Country",
                  ]}
                  fullWidth
                  InputLabelProps={{className:"custom-label-down small_label google-text-font"}}
                  autoComplete={autocomplete}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}

const mapStateToProps = (state) => ({
  ownerResources: state.ownerResources,
});

export default withTranslation()(
  connect(mapStateToProps)(GoogleMapComponentV4)
);
