import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Favicon from 'react-favicon'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DynamicContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }


  render() {

    const { t } = this.props;
    const { userLanguage, languageCode,imageFormat,favicon } = this.props.ownerResources;

    document.getElementById("Title").innerHTML =t('Title');
    let faviconelement = document.getElementById('favicon');
    //faviconelement.setAttribute('href','opt/CI/SharedData/Shared/Configuration/Resources/Solartis/Workflow/images/favicon.ico');
    return (
      <React.Fragment>
        <Favicon
        url={favicon}>
        </Favicon>
      </React.Fragment>
    );
  }
}

DynamicContent.propTypes = propTypes;
DynamicContent.defaultProps = defaultProps;

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  ownerResources: state.ownerResources
});

export default withTranslation()(connect(mapStateToProps)(DynamicContent));
