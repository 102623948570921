import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import { Button } from '@material-ui/core';
import { commonButtonMethod } from './ButtonUtil';
import { LoaderComponent } from '../utils/Common/CommonUtils';
import { CircularProgress } from '@mui/material';

export default class cButton extends Component {
    render() {
        const { component,componentProps,componentState,state,parentProps,functions } = this.props;
      let ActionNeeded = component.Action ? Object.keys(component.Action).length :false;
        const { onClick_nextButton } = this.props;
        const stateObj = componentState || parentProps;
        const propsObj = componentProps || state;
        let temp = {propsObj,stateObj,component,functions,props:this.props}
        const [Loader, isLoading] = LoaderComponent(<CircularProgress className='field-loader'></CircularProgress>, this.props)
        let isReadOnly = component.IsReadonly;
        if(isLoading) isReadOnly = true;
  
        return (
            <React.Fragment>
            <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} className={`${component.AttributeStyleClass}`}>
                <Button name={component.AttributeName}
                        type={component.ComponentType.toLowerCase() === "submit"?'submit':'button'}
                        color="primary"  
                        className={`nav-btn theme_buttonColor buttonspacing margin1 ${component.StyleClass} ${component.IsReadonly? 'disabled': 'theme_button'}`} 
                        //style={styles.button}
                        value={temp} 
                        disabled={isReadOnly}
                        onClick={component.ComponentType.toLowerCase() === "submit" ?(this.props.refs? (e) => {
                            e.target.somevalue=temp;
                            this.props.refs.current.submit(e)}:'' ): ActionNeeded ?   (e) => commonButtonMethod(component,this.props,stateObj,functions,e):
                            (e) => this.props[component.OnClickAction] && this.props[component.OnClickAction](component.EventName,propsObj,stateObj,component,functions,e,this.props)}>
                        <span className="ui-button-text ui-c">{component.ComponentLabel}</span>
                    </Button>                
            </Grid>
            {this.props.children}
            </React.Fragment>
        )
    }
}
