import React, { cloneElement } from 'react';
import ExpandableComponent from '../ExpandableComponent/ExpandableComponent';
class ExpandableWrapperComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {}
    }
    render(){

        const { pageInfo } = this.props;
        const style= pageInfo.class? pageInfo.class : ''
        const childrenWithProps = React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
              return cloneElement(child, {...this.props.children })
            }
            return child;
        });

        return(
            <React.Fragment>
                 <ExpandableComponent pageName={pageInfo.pageName ? pageInfo.pageName:'' }  name={pageInfo.name ? pageInfo.name:'' } className= {style} expand= {pageInfo.defaultExpand}>
                         {childrenWithProps}
                     </ExpandableComponent>
            </React.Fragment>
        )
    }

}

export default ExpandableWrapperComponent;
