import React, { Component } from 'react';
import { IconButton} from '@material-ui/core';
import { AddCircle} from '@material-ui/icons';

export default class Button extends Component {
    render() {
    	 const {component}=this.props;
        return (
            <IconButton  className={component.AttributeStyleClass !== "" ? component.AttributeStyleClass + " custom-icon-button theme_nav" : "custom-icon-button theme_nav" }   color="primary" component="span" onClick={this.props.onDatatableAdd}><AddCircle /></IconButton>
        );
    }
}