import React, { Component } from 'react';
import { Grid, InputLabel, Typography } from "@material-ui/core";
import {  changePercentageFormat } from '../utils/Common/HelperUtils'
import { format } from "date-fns";
import { getDateWithoutOffset } from '../utils/Common/DateUtils';





class OutputText extends Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
  }

 async componentDidMount(){
  let { component } = this.props;
  component.OnKeyUpAction && this.props[component.OnKeyUpAction] && this.props[component.OnKeyUpAction](this.props,this.state);
 }
 async componentDidUpdate(prevprops,prevstate){
    let { component } = this.props;
    component.OnKeyDownAction && this.props[component.OnKeyDownAction] && this.props[component.OnKeyDownAction](this.props,this.state,prevprops,prevstate);
  }

  formatChange = ( datatype , formatVal , componentvalue ) => {
    if(datatype.toLowerCase() === "number"){
      var regex = String(componentvalue).match(this.props.component.OutputFormatPattern);
      console.log(regex,"number");
      let value = regex && regex[0] ? Number(regex[0]).toFixed(6) :componentvalue
          return value;
      }

    if (datatype.toLowerCase() === "currency"  && !isNaN(componentvalue)  ) {
      if(formatVal.includes('~')){
         var temp = formatVal.split('~')
      let options={
        style: "currency",
        currency:temp[1]
      }
    if(temp[2] && temp[2] !=="false")
       options.maximumFractionDigits=temp[2]    
    if(temp[3] && temp[3] =="true")
        options.trailingZeroDisplay="stripIfInteger"
    const currencyFormatter = new Intl.NumberFormat(temp[0], options);
        return currencyFormatter.format(componentvalue);
      } else if (formatVal && formatVal.toLowerCase() == "decimal") {
        let parts=componentvalue.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return "$" + parts.join(".");
      } else {
        return componentvalue;
      }
    }
    if (formatVal === 'MM/dd/yyyy') {
      return format(getDateWithoutOffset(componentvalue), this.props.component.OutputFormatPattern);
    }
    if(datatype.toLowerCase() === "percentage"){
      return  changePercentageFormat(componentvalue)
    }else{
      return componentvalue
    }
  }


  render() {
    let { component, isDynamicTable, componentvalue } = this.props;
    let labelId = component.ComponentId + "_label";
    let value = ((component.OutputFormatPattern || component.AttributeDataType) && componentvalue) ? (this.formatChange(component.AttributeDataType,component.OutputFormatPattern, componentvalue)) : componentvalue ? componentvalue : ((component.OutputFormatPattern || component.AttributeDataType) && component.DefaultValue) ? (this.formatChange(component.AttributeDataType,component.OutputFormatPattern, component.DefaultValue)) : component.DefaultValue;
    return (
      <React.Fragment>
        <Grid
          style={styles.selectContainer}
          item
          xs={12}
          sm={12}
          md={component.Size}
          lg={component.Size}
          key={component.AttributeName}
          className={`Grid_${component.AttributeStyleClass} `}
        >
          {!isDynamicTable && (
            <InputLabel
              ref={this.labelRef}
              id={labelId}
              className={`${component.StyleClass} small_label`}
              style={styles.label}
            >
              {component.ComponentLabel}
            </InputLabel>
          )}
         {isDynamicTable ? <Typography variant="body2" gutterBottom className={`${component.AttributeStyleClass}`}
          onMouseDown={(e)=>{
            e.stopPropagation();
          }}
          onDragStart={(e)=>{
            e.stopPropagation()
          }}
          onDragOver={(e)=>{
            e.someValue='preventDragEvent'
            e.preventDefault()
          }}
          onDragEnd={(e)=>{
            e.someValue='preventDragEvent'
            e.preventDefault()
          }}
            style={styles.custom_outputtext}>
          {value}
          </Typography> : <Typography variant="body2" gutterBottom className={`${component.AttributeStyleClass} small_label`}
            style={styles.custom_outputtext}>
          {value}
          </Typography>}
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = {
  selectContainer: {
    alignSelf: "flex-end"
  },
  label: {
    margin: "0px",
    width: "51.5%",
    display: "inline-block"
  },
  custom_outputtext:
  {
    display: "inline-block"
  }
}

export default OutputText;
