import React from "react";
import red from "@material-ui/core/colors/red";
import { TextValidator, ValidatorComponent } from "react-material-ui-form-validator";
import Input from "@material-ui/core/Input";
import { Typography } from "@mui/material";

const red300 = red["500"];

const style = {
  left: 0,
  fontSize: "12px",
  color: red300
};


class TextValidatorV2 extends ValidatorComponent {
  render() {
    const {
      ComponentId,
      labelId,
      name,
      errorMessages,
      validators,
      requiredError,
      value,
      options,
      component,
      attributeValue,
      displayEmpty,
      InputProps,
      autoComplete,
      variant,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <TextValidator
          labelId={labelId}
          label={
            !this.props.isDynamicTable && (
              <Typography className="custom-label-down small_label">
                {component.IsRequired === "Y" ? component.ComponentLabel + " *" : component.ComponentLabel}
              </Typography>
            )
          }
          id={ComponentId}
          name={name}
          value={value}
          disabled={component.IsReadonly}
          onChange={(e) => this.props.onChange(e)}
          fullWidth
          variant={variant}
          validators={validators}
          displayEmpty={displayEmpty}
          onFocus={(e) => this.props.onFocus(e)}
          onBlur={ (e) => this.props.onBlur(e)}
          onKeyDown={(e) => this.props.onKeyDown(e)}
          InputProps={InputProps}
          autoComplete={autoComplete}
          className = "hideValidation"
        />
            
          
        <div className="errorMessageStyle" >
        {this.errorText()}
        </div>
      </React.Fragment>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <span style={style}>{this.getErrorMessage()}</span>;
  }
}

export default TextValidatorV2;
