import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from '../../../controls/Button';
import { commonButtonMethod } from '../../../controls/ButtonUtil';

export default class childFormComponent extends Component {
    
    constructor(props){
        super(props);
        this.formList={};
        this.childform=React.createRef();
    }
   
    handleSubmit = (e,buttonProps) =>
    {
        e.preventDefault();
        let {component,componentProps:stateObj,componentState:propsObj} = buttonProps
        //    e.stopPropagation();
        buttonProps[component.OnClickAction](component.EventName,propsObj,stateObj,component)
        
    }
    handleAttributeSubmit = (e) =>{
        e.preventDefault();
        // let {propsObj,stateObj,component,functions,props} = e.target.value
        let {propsObj,stateObj,component,functions,props} = e.target.somevalue
        if(component.Action && Object.keys(component.Action).length > 0){
            commonButtonMethod(component,{...props},stateObj,functions,e)
        }
        else{
            this.props.utilfunction[component.OnClickAction] && this.props.utilfunction[component.OnClickAction](component.EventName,propsObj,stateObj,component,functions,e,props)
        }
    }
   
    executeScroll = () =>{
        ReactDOM.findDOMNode(this.childform.current.errors[0]).scrollIntoView({ behavior: 'smooth', block:'center' });
        if(false || !!document.documentMode){
          window.scrollBy(0,-120);
        }
    }
    render() {
        let {subapplicationName,IsSubmitButtonNeeded, ButtonType, SubmitButtonName, AdditionalButtonName, AdditionalButtonClickEvent,utilfunction}=this.props;
        const componentProps = {Size:12,AttributeName:subapplicationName,SubApplicationName:subapplicationName, OnClickAction:AdditionalButtonClickEvent,
                            EventName:"",ComponentLabel:IsSubmitButtonNeeded === 'Y' ? SubmitButtonName:AdditionalButtonName,
                            StyleClass:"pull-right childFormButtonDisplayClass",ComponentType: ButtonType.toLowerCase() !=="submit" ? "button":"submit"}  
        

        let functionProp ={}
        if(utilfunction){
            functionProp =  {[AdditionalButtonClickEvent] : utilfunction[AdditionalButtonClickEvent]}
        } 
        const buttonProps = {
            componentProps:{...this.props.componentProps}, 
            componentState:{...this.props.componentState},
            refs:this.childform,
            ...functionProp,
            component:{...componentProps,UpdateState: this.props.UpdateState}
        }
        return (
            <React.Fragment>
                <ValidatorForm
                className="form_wd_100"
                ref={this.childform}
                onSubmit={IsSubmitButtonNeeded === 'Y' ? ((e) => this.handleSubmit(e,buttonProps)) : ((e) => this.handleAttributeSubmit(e))}
                onError={e => this.executeScroll()}>
                {React.Children.map(this.props.children, child => {
                         return React.cloneElement(child, {refs: this.childform})   
                })}
                {IsSubmitButtonNeeded==='Y' && <Button  className="theme_button"    {...buttonProps}/>}
               </ValidatorForm>
            </React.Fragment>
        )
    }
}
