import * as React from 'react';
import { useRef } from 'react'
import Tooltip from '@mui/material/Tooltip';
import { Grid } from '@material-ui/core';
import { IconButton} from '@mui/material';
// import * as Controls from '../controls/index';
import { commonButtonMethod } from './ButtonUtil';
import container from '../utils/DIContainer';





const TooltipPopover=(props) =>{
  const buttonRef = useRef(null);
  let {component,componentProps,componentState,state,parentProps,functions, defaultSize} = props;
  let{Action}=component;
  const Controls = container.get('componentControls')
  let IconChild = component.AttributeParams && Controls[component.AttributeParams.toLowerCase()];
  let Children = IconChild ? <IconChild fontSize={Action.size?Action.size.toLowerCase():'default'} color={Action.color ? Action.color.toLowerCase():'default'}></IconChild> : component.ComponentLabel;
  let Value ;
  const stateObj = componentState || parentProps;
  const propsObj = componentProps || state;
  let hideIconClass='';
  props.customTable?props.rowData.hasOwnProperty(component.AttributeName)?Value=<div dangerouslySetInnerHTML={{ __html: props.rowData[component.AttributeName] }} />:hideIconClass='hide':Value = <div dangerouslySetInnerHTML={{ __html: component.DefaultValue }} />

  const handleOnClick = async (e) => {
    try {
      e.preventDefault();
      props.ParentonClick && props.ParentonClick(e)
      component.OnClickAction
        ? await props[component.OnClickAction](
          component.EventName,propsObj,stateObj,component,functions,e,buttonRef
          )
        : await commonButtonMethod(component, props, componentState, functions, e);
    } catch (e) {
      console.log("Error in ButtonMenu OnClick Action", e);
    }
    //  finally {
    //   handleClose();
    // }
  };






  return (
    <Grid  item xs={12} sm={12} md={defaultSize || component.Size || 12} lg={defaultSize || component.Size || 12} key={component.AttributeName} className={`Tooltip_${component.AttributeStyleClass} ${hideIconClass}`}>
      <Tooltip title={Value} placement={Action.tooltipPlacement || "right-start"}  classes={{ popper: `${component.StyleClass}`}} className='small_options' arrow>
      <IconButton  onClick={handleOnClick} ref={buttonRef}>
      {Children}
      </IconButton>
      </Tooltip>
    </Grid>
  );
}


export default TooltipPopover;
