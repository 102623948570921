import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { getDateWithoutOffset } from "../utils/Common/DateUtils";

class EffectiveDate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      effectiveDateOpen: 'true', expireDateOpen: 'true', previousExpireDate: ''
    }
  }

  effectiveDateOpenClose = () => {
    this.setState({ effectiveDateOpen: !this.state.effectiveDateOpen })
  }

  expireDateOpenClose = () => {
    this.setState({ expireDateOpen: !this.state.expireDateOpen })
  }

  componentDidMount = () => {

    let itemTipParams = this.props.component.ItemTipParams.split(",");
    let effectiveDate = itemTipParams[0].split("~")[1];
    let expirationDate = itemTipParams[1].split("~")[1];

    let date1 = new Date();
    date1.setMonth(date1.getMonth() + 1);
    date1.setDate(1);

    let date2 = new Date();
    date2.setFullYear(date1.getFullYear() + 1);
    date2.setMonth(date1.getMonth());
    date2.setDate(date1.getDate());

    let d1 = format(date1, this.props.component.OutputFormatPattern);
    let d2 = format(date2, this.props.component.OutputFormatPattern);

    if (this.props.values[effectiveDate] === undefined && this.props.values[expirationDate] === undefined)
    {
      this.props.handleDateChange(date1, d1, effectiveDate, this.props.component);
      this.props.handleDateChange(date2, d2, expirationDate, this.props.component);
    }

  }

  /* componentDidUpdate(prevProps)
  {
    let effectiveDate = this.props.component.ItemTipParams.split("~")[0];
    let expirationDate = this.props.component.ItemTipParams.split("~")[1];
    if(prevProps.values[expirationDate] !== undefined)
    {
      if(prevProps.values[effectiveDate] !== undefined && prevProps.values[effectiveDate] === this.props.values[effectiveDate])
      {
        if(prevProps.values[expirationDate] !== this.props.values[expirationDate])
        {
          this.expireDateOpenClose()
        }
      }
    }
  } */

  handleInternally = async (event, value) => {

    let itemTipParams = this.props.component.ItemTipParams.split(",");
    let effectiveDate = itemTipParams[0].split("~")[1];
    let expirationDate = itemTipParams[1].split("~")[1];

    let date1 = getDateWithoutOffset(value);

    let date2 = new Date();
    date2.setFullYear(date1.getFullYear() + 1);
    date2.setMonth(date1.getMonth());
    date2.setDate(date1.getDate());

    let d1 = format(date1, this.props.component.OutputFormatPattern);
    let d2 = format(date2, this.props.component.OutputFormatPattern);

    this.setState({ effectiveDateOpen: !this.state.effectiveDateOpen })

    await this.props.handleDateChange(date1, d1, effectiveDate, this.props.component);
    await this.props.handleDateChange(date2, d2, expirationDate, this.props.component);

  };


  render() {
    const { component, values } = this.props;

    let itemTipParams = component.ItemTipParams.split(",");

    let effectiveDate = itemTipParams[0].split("~")[1];
    let effectiveDateLabel = itemTipParams[0].split("~")[0];

    let expirationDate = itemTipParams[1].split("~")[1];
    let expirationDateLabel = itemTipParams[1].split("~")[0];

    let IsReadonly = component.IsReadonly;
    let minDate = new Date()
    minDate.setMonth(minDate.getMonth() - 3);
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";


    const effectiveDateValue = values[effectiveDate] ? getDateWithoutOffset(values[effectiveDate]) : values[effectiveDate];
    const expirationDateValue = values[expirationDate] ? getDateWithoutOffset(values[expirationDate]) : values[expirationDate];

    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={(component.Size) ? component.Size : 6} lg={(component.Size) ? component.Size : 6} key={effectiveDate}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              //variant="inline"
              //orientation="landscape"
              fullWidth
              open={!this.state.effectiveDateOpen}
              onClose={() => this.effectiveDateOpenClose()}
              onClick={() => !IsReadonly && this.effectiveDateOpenClose()}
              InputProps={{ readOnly: true }}
              disabled={IsReadonly}
              margin="normal"
              className={`${component.StyleClass}`}
              name={effectiveDate}
              format={this.props.component.InputFormatPattern}
              id={effectiveDate}
              autoComplete={autocomplete}
              label={effectiveDateLabel}
              placeholder="mm/dd/yyyy"
              value={effectiveDateValue}
              minDate={minDate}
              onChange={(event, value) => {
                this.handleInternally(event, value)
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={(component.Size) ? component.Size : 6} lg={(component.Size) ? component.Size : 6} key={expirationDate}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              //variant="inline"
              //orientation="landscape"
              fullWidth
              open={!this.state.expireDateOpen}
              onClose={() => this.expireDateOpenClose()}
              onClick={() => !IsReadonly && this.expireDateOpenClose()}
              InputProps={{ readOnly: true }}
              disabled={IsReadonly}
              format={this.props.component.InputFormatPattern}
              margin="normal"
              autoComplete={autocomplete}
             className={`${component.StyleClass}`}
              name={expirationDate}
              id={expirationDate}
              label={expirationDateLabel}
              placeholder="mm/dd/yyyy"
              value={expirationDateValue}
              minDate={effectiveDateValue}
              minDateMessage="Expiration Date should not be before Effective date"
              onChange={(event, value) => {
                this.props.handleDateChange(
                  event,
                  format(getDateWithoutOffset(value), this.props.component.OutputFormatPattern),
                  expirationDate,
                  component
                )
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </React.Fragment>
    );
  }
}
export default EffectiveDate;
