import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import TooltipPopover from "./Popover";

function ActionMenu(props) {

    let {subAppInfo, isDisabled, tableFunctions} = props;

    const { component,componentProps,componentState,state,parentProps,functions} = props;
    let actionListOptionsList=props.component.Action.actionmenu;
    const stateObj = componentState || parentProps;
    const propsObj = componentProps || state;
    let rowData=props.pagelevelValues.AvailableMarket;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
       <div>

            <TooltipPopover
              {...props}
            disabled={isDisabled}
            ParentonClick={handleClick}>

                </TooltipPopover>

            <Menu
                id="Action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
            {
                actionListOptionsList.map((option, optindex) => { return (
                    <MenuItem key={option.ActionName} onClick={(e) => {tableFunctions.hasOwnProperty(option.OnOptionClick) && tableFunctions[option.OnOptionClick](option.EventName, propsObj, stateObj, component,functions,e,props,option,rowData); handleClose(e)}}>
                        {option.ActionDisplayName}

                    </MenuItem>
                )})
            }
            </Menu>
            </div>
    )
}

export default ActionMenu;
