import appActions from "../../../../actions";
import store from "../../../../store";
import { EntitySubAppTranslationV3, getEntitySubAppMappingV2 } from "../requestConstruction/ResponseTranslation";
import { AxiosInstance, getServiceRequestDetail } from "./API";
import { SSOEncrytionKey } from "./config";
import { subAppListConstruction } from "./DetailSummarySubAppList";
import { Toast, triggerToast } from "./HelperUtils";
import { constructAndInvokeMetadataCallV2 } from "./MetaUtils";
import { getproductnavigation } from "./Navigation_Util";
import crypto from 'crypto';

const {actionType} =appActions

export const dynamicConditionValidation = (source, conditions) => {
  let booleanValue = true;
  let conditionLength = conditions.length;
  for(let i = 0; i < conditionLength; i++){
      let item = conditions[i];
      let operator = item.operator.toLowerCase();
      if(operator === "equal"){
          if(Array.isArray(source[item.key])===true)
          {(source[item.key].includes(item.value) && booleanValue) ? booleanValue = true : booleanValue = false}
          else
          {(item.value === source[item.key] && booleanValue) ? booleanValue = true : booleanValue = false}
      }
      if(operator === "notequal" ){
          if(item.value==="")
              (source[item.key] !== "" && source[item.key] !== undefined && booleanValue) ? booleanValue = true : booleanValue = false;
          else
          {(item.value !== source[item.key] && booleanValue) ? booleanValue = true : booleanValue = false}
      }
      if(operator === "greaterthan" || operator === "greaterthanequal" ||operator === "lessthan" || operator === "lessthanequal" ){
          let value = null;
          typeof item.value === "string" ? value = parseInt(item.value) : value = item.value

          if(operator === "greaterthan" ){
              (source[item.key] > value && booleanValue) ? booleanValue = true : booleanValue = false
          }
          if(operator === "greaterthanequal" ){
              (source[item.key] >= value && booleanValue) ? booleanValue = true : booleanValue = false
          }
          if(operator === "lessthan" ){
              (source[item.key] < value && booleanValue) ? booleanValue = true : booleanValue = false
          }
          if(operator === "lessthanequal" ){
              (source[item.key] <= value && booleanValue) ? booleanValue = true : booleanValue = false
          }
      }
      // Break the loop early if the overall boolean value is false
      if(!booleanValue) break;
  }
  return booleanValue;
}

export const criteriaValidationCheck = (criteria, response=[]) =>{
  try{
      const reduxState = store.getState();
      const {UIFlowReducer} = reduxState;
      let  validator = [];
      let validate = true;
      if(!criteria) return validate
      let { operator='and'} = criteria
      Object.entries(criteria).map(([key, value]) => {
          if(Array.isArray(value) && value.length > 0){
              if(key === "APIResponse" ){
                  let result=dynamicConditionValidationV2(response,value)
                  validator.push(result)
              }
              if(key==="SessionState"){
                  let result =dynamicConditionValidationV2(UIFlowReducer,value)
                  validator.push(result)
              }
          }
      })
      if(operator==='and' && validator.length > 0){
          validate = validator.every(valid=>valid)
      }else{
          validate = validator.some(valid=>valid)
      }
      return validate;
  }
  catch(err){
      console.err(`Error in criteriaValidationCheck: ${err}`)
  }
}


export const dynamicConditionValidationV2 = (source, conditions) => {
  let allConditionsPassed = true;

  for (const conditionSet of conditions) {
      // If there are conditions inside a condition set
      if (conditionSet.conditions && conditionSet.conditions.length > 0) {
          let innerConditionPassed = false; // Initialize result for OR combination

          for (const innerCondition of conditionSet.conditions) {
              const innerResult = evaluateCondition(source, innerCondition);
              if (innerResult) {
                  innerConditionPassed = true; // If any inner condition is true, set inner condition flag to true
                  break; // No need to check further conditions
              }
          }

          if (!innerConditionPassed) {
              allConditionsPassed = false; // If any inner combination condition is false, set overall flag to false
              break; // No need to check further condition sets
          }
      } else { // If there are no conditions inside a condition set
          const result = evaluateCondition(source, conditionSet);
          if (!result) {
              allConditionsPassed = false; // If the single condition is false, set overall flag to false
              break; // No need to check further condition sets
          }
      }
  }

  return allConditionsPassed; // Return overall result
};

export const dynamicConditionValidationV3 = (source, conditions) => {
    // Recursive function to evaluate conditions with precedence of AND over OR
    const evaluateConditions = (conditionSet, logicalParam = "AND") => {
        let currentResult = null;
        let currentOperator = logicalParam; // Default operator if not provided
 
        for (const condition of conditionSet) {
            let conditionResult;
            // Check if it's a group of conditions (nested AND/OR logic)
            if (condition.conditions && condition.conditions.length > 0) {
                conditionResult = evaluateConditions(condition.conditions, "OR");
            } else {
                // Evaluate single condition
                conditionResult = evaluateCondition(source, condition);
            }
 
            // Combine the results based on the current operator
            if (currentResult === null) {
                currentResult = conditionResult; // First condition result
            } else if (currentOperator === "AND") {
                currentResult = currentResult && conditionResult; // AND logic
            } else if (currentOperator === "OR") {
                currentResult = currentResult || conditionResult; // OR logic
            }
 
            // Set the next operator, if provided
            currentOperator = condition.logicalOperator ? condition.logicalOperator.toUpperCase() : "AND";
        }
 
        return currentResult;
    };
 
    // Start evaluating from the top-level condition set
    return evaluateConditions(conditions);
};

function evaluateCondition(source, item) {
  const operator = item.operator.toLowerCase();
  const key = item.key;
  const value = item.value;
  if (operator === "has" || operator === "nothas") {
      // Check if the key exists in the nested hierarchy of the source object
      const keyExists = nestedKeyValueRetrieval(source, key,"keyexistcheck");
      return (operator === "has" && keyExists) || (operator === "nothas" && !keyExists);
  }
  if (!nestedKeyValueRetrieval(source, key,"keyexistcheck")) {
      return false; // Key not present in source object
  }
  let valueFromSource=nestedKeyValueRetrieval(source,key,"getvalue")
  if (operator === "equal") {
    if (Array.isArray(valueFromSource)) {
        if (typeof valueFromSource[0] === 'string') {
            return valueFromSource.includes(value);
        } else if (typeof valueFromSource[0] === 'object') {
            return valueFromSource.some(obj => Object.values(obj).includes(value));
        }
    }else{
        return valueFromSource === value;
    }
    } else if (operator === "notequal") {
      return value === "" ? (valueFromSource !== "" && valueFromSource !== undefined) : (valueFromSource !== value);
  } else if (operator === "greaterthan" || operator === "greaterthanequal" || operator === "lessthan" || operator === "lessthanequal") {
      const numValue = typeof value === "string" ? parseInt(value) : value;
      switch (operator) {
          case "greaterthan":
              return valueFromSource > numValue;
          case "greaterthanequal":
              return valueFromSource >= numValue;
          case "lessthan":
              return valueFromSource < numValue;
          case "lessthanequal":
              return valueFromSource <= numValue;
          default:
              return false;
      }
  }
  return false;
}

function nestedKeyValueRetrieval(obj, key,type="") {
  const KeyList = key.split('/');
  let result = "response";
  for (let i = 0; i < KeyList.length; i++) {
      result = `${result}["${KeyList[i]}"]`
      let c = new Function("response", `return ${result}`);
      let tempvalue = c(obj);
      if (typeof(tempvalue)!=="boolean" && !tempvalue && typeof(tempvalue)!=='number'){
          return type==="keyexistcheck" ? false:tempvalue
      }
      if (i === KeyList.length - 1)
          return type==="keyexistcheck" ? true:tempvalue
  }
  return type==="keyexistcheck" && false
}

export const checkArrayType = (arr) => {
    if (!Array.isArray(arr)) return 'Not an array';
    
    const allObjects = arr.every(item => item !== null && typeof item === 'object');
    const allStrings = arr.every(item => typeof item === 'string');
  
    return allObjects ? 'Objects' : allStrings ? 'Strings' : 'Array contains mixed types';
  }

export const dynValueListconstructor = async (action, response) => {
    try {
        let obj={}
        let { customComponentValues = [] } = action;
        await customComponentValues.map(async(el) => {
            if (el && el.parentState && el.parentState.length > 0) {
                if(Object.keys(response).length === 0) return
          let { options } = await dynamicObjectConstruction(el.parentState, response, {}, 'response');
          let values = []
          let arrType = checkArrayType(options)
          if(options && options.length > 0){
          if(arrType === "Strings") {
            values = options.map((itm,idx) => ({
                Sequence: idx,
                DisplayValue: itm,
                Value: itm
            }))
          }
          else{
          values = options.map((obj,index) => {
            return {
              DisplayValue: el.DisplayValueKey && nestedKeyValueRetrieval(obj, el.DisplayValueKey),
              Sequence: el.SequenceKey ? nestedKeyValueRetrieval(obj, el.SequenceKey) : index + 1,
              Value: el.ValueKey && nestedKeyValueRetrieval(obj, el.ValueKey),
              AdditionalAttributes: el.AdditionalAttributesKey && nestedKeyValueRetrieval(obj, el.AdditionalAttributesKey)
            };
          });
        }}
          obj={...obj,...{ [`${el.subappName}_${el.AttributeName}__Options`]: values }}
        }
      });
      return obj
    } catch (e) {
      console.log("Error in dynValueListconstructor Method::", e);
    }
  }
  
// To set custom values based on key exist
export const dynamicObjectConstruction = async (arr, sourceObj, updateObj = {}, checkType) => {

  let conditionKeys = {}, custom = false
  if(!checkType) return updateObj;

  if(checkType.toLowerCase() === 'response') conditionKeys = { 'value' : 'ResponseKey' , 'key' : 'TargetAttribute' }
  if(checkType.toLowerCase() === 'request') conditionKeys = { 'value' : 'SourceAttribute' , 'key' : 'RequestKey' }
  if(checkType.toLowerCase() === 'custom') {
      conditionKeys = { 'value': 'CustomValue', 'key': 'CustomKey' }
      custom = true
  }

  arr.forEach((obj)=>{
      let { valuesList = [],CustomValueIfKeyNotExists="",CustomValueIfKeyExists="",conditions=[] } = obj, currentValue;
      let executeMapping =conditions.length>0?dynamicConditionValidationV2(sourceObj,conditions):false;
      if((!executeMapping && !conditions.length>0) || (executeMapping && conditions.length>0)){
      let { key, value } = conditionKeys
      if(!custom) {
          if(obj[value].includes("/"))
          {
              let KeyList = obj[value].split("/");
              let key = "response";
              for (let i = 0; i < KeyList.length; i++) {
                  key = `${key}["${KeyList[i]}"]`
                  let c = new Function("response", `return ${key}`);
                  let tempvalue = c(sourceObj);
                  if (CustomValueIfKeyNotExists !== "" && CustomValueIfKeyExists !== "") {
                      if (!tempvalue) {
                          currentValue = CustomValueIfKeyNotExists;
                          break;
                      }
                      if (i === KeyList.length - 1)
                          currentValue = CustomValueIfKeyExists === "APIValue" ? tempvalue : CustomValueIfKeyExists;
                  } else {
                      if (tempvalue === undefined)
                          break;
                      if (i === KeyList.length - 1)
                          currentValue = tempvalue;
                  }

              }
          }
          else if(obj[value] === "APIResponse")
              currentValue = sourceObj
          else{
              if(CustomValueIfKeyNotExists!=="" && CustomValueIfKeyExists!==""){
                  currentValue = sourceObj.hasOwnProperty([obj[value]]) ? CustomValueIfKeyExists==="APIValue"?sourceObj[obj[value]]:CustomValueIfKeyExists:CustomValueIfKeyNotExists;
              }
              else{currentValue = sourceObj[obj[value]]}
          }
          if(valuesList.length > 0){
              valuesList.forEach( (val) => {
                  if(currentValue && val.ResponseValue  === currentValue ) currentValue = val.TargetValue;
                  else if(!currentValue && val.ResponseValue === "null") currentValue=val.TargetValue; //temporary
              })
          }
      }
      else if(custom && obj.hasOwnProperty(value))
          currentValue = obj[value]
          if(obj[value] === "APIResponse")
              currentValue = sourceObj
          if(typeof currentValue !== "undefined"){
              if(obj[key].includes("/")){
                  assignNestedProperty(updateObj,obj[key],currentValue)
              }else{
                  updateObj[obj[key]]=currentValue
              }
          }
      }
  })
  return updateObj;
}


export const assignNestedProperty=(obj, propertyPath, value)=> {
  const properties = propertyPath.split('/');
  let currentObj = obj;

  for (let i = 0; i < properties.length - 1; i++) {
    const property = properties[i];
    currentObj[property] = currentObj[property] || (isNaN(Number(properties[i+1])) ? {} : []);
    currentObj = currentObj[property];
  }

  currentObj[properties[properties.length - 1]] = value;
}

export const constructValuesFromResponse = async (response) => {
  let dataListObj = {}, values = {}, pagelevelValues = {};
  Object.keys(response).map ((resKey) => {
      if(Array.isArray(response[resKey])){
          let dataListKey = resKey.toLowerCase() + '_dataList';
          dataListObj[dataListKey] = response[resKey]
      }
      else if (typeof response[resKey] === 'object') {
          Object.keys(response[resKey]).map((key, value) => {
          let attributeValue = response[resKey][key];
          values[key] = attributeValue;
          if (!pagelevelValues[resKey]) {
              pagelevelValues[resKey] = {};
          }
          pagelevelValues[resKey][key] = attributeValue;
          });
      }
      else if(typeof response[resKey] === 'string')
      pagelevelValues[resKey]=response[resKey]
  });
  return { values, pagelevelValues, transactionPrimaryObject: pagelevelValues, ...dataListObj }
}

export const mergeDeep = (target, source) => {
  if (typeof target !== 'object' || typeof source !== 'object') {
    return source;
  }

  const merged = { ...target };

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (source[key] instanceof Object && key in target && !Array.isArray(source[key])) {
        merged[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(merged, { [key]: source[key] });
      }
    }
  }

  return merged;
};

const libraryMethod={
  getproductnavigation
}

function findDynamicNavs(obj,pageName,isFound,currentValues={nextValue:{},previousValue:{},current:{}}){
    if(Array.isArray(obj)){
      for(let index=0;index<obj.length;index++){
        const element=obj[index];
        if(obj[index-1] && obj[index-1]['pageName'] && !obj[index-1].children && !(Array.isArray(obj[index-1].children) && obj[index-1].children.length > 0) && !isFound.currFound){ //Since currentElement is not found we set Previous element. case#1
            currentValues.previousValue=obj[index-1];
        }
        if(element.pageName===pageName){
          currentValues.currentValue=element;
          isFound.currFound=true;
        }
        if(!isFound.currFound && element.pageName){//Setting current element as previous as current is not the page we are looking for. case#2
            currentValues.previousValue=element;
        }
        if(isFound.currFound && element.children && element.children.length > 0 && element.children[0].pageName){ //Or have Next element in children case#2
                currentValues.nextValue=element.children[0];
                isFound.nextFound=true;
                if(isFound.currFound)
                    break;
        }else if(isFound.currFound && obj[index+1] && obj[index+1]['pageName'] && !isFound.nextFound){  //Post finding the element we can have the next element in 3 cases the sibling case#1
            currentValues.nextValue=obj[index+1];
            isFound.nextFound=true;
            if(isFound.currFound)
                break;
        }
        if(element.children && element.children.length > 0 && !isFound.currFound){
            currentValues.previousValue=element; // Setting previos Value of parent in case the first child is the element with our pageName.case#3
            findDynamicNavs(element.children,pageName,isFound,currentValues);
        }
        if(isFound.currFound && !isFound.nextFound && obj[index+1]){ // Last case of next element is sibling of parent sidebar.
            currentValues.nextValue=obj[index+1];
            break;
        }
        if(isFound.currFound)
            break;
        }
    }else if(obj.children && Array.isArray(obj.children)){
      findDynamicNavs(obj.children,pageName,currentValues,)
    }
    return currentValues;
}

export const sidebarRouteFilter = (sideBarData,routeName) =>{
  let routeData = []
  if(sideBarData && sideBarData.length > 0){
    for(const obj of sideBarData){
      if (obj.url === `/${routeName}`) {
        routeData.push(obj);
      }
    }
  }
  return routeData
}

export function getNav(sidebar,pageName,item){
    const items= findDynamicNavs(sidebar,pageName,{currFound:false,nextFound:false});
    return items[`${item}Value`];
}

export async function encodePageNameToURL(pageName){
let val=window.location.hash.split('/'),pageData={},redirectRoute='';
if(val.length > 1 && val[2]){
    let newVal=val[2];
    pageData = decodeString(newVal)
    pageData={...pageData,pageName:pageName}
    const encodedString=await constructEncodedString(pageData);
        redirectRoute+=`${encodedString}`;
    return redirectRoute;
  }
}

export const pageLoadActions = async (actions, props, state,functions) => {
  try {
      if(props.pageInfo.pageName && props.UIFlowReducer[`${props.pageInfo.pageName}_updatePageName`])
      await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {[`${props.pageInfo.pageName}_updatePageName`]: false} })
      let {isLoaderNeeded = true} = props.pageInfo
      if(isLoaderNeeded)
      functions && functions.UpdateState ? functions.UpdateState("loading", true, true) : props.functions && props.functions.UpdateState("loading", true, true);
      let obj = {"loading": false}, apiResponse = {}
      for(let i = 0; i < actions.length; i++){
          let action = actions[i]
                      if(action.actionName.toLowerCase() === 'metadata'){
              try{
                  let subApps = [], subAppInfo = {} , tkey={};let reduxState = store.getState();
                  if(action.dynamic && action.dynamic.toLowerCase() === "true"){
                      let { subappName = [] } = action, info = `${props.pageInfo.name}_details`;
                      let uiRedux = store.getState().UIFlowReducer;
                      if(subappName && subappName.length > 0) {
                          subappName.forEach( item => {
                            if(uiRedux[info][item] !== undefined)
                            subApps.push(uiRedux[info][item])
                          })
                      }
                      subAppInfo = {
                          state: uiRedux[info].region,
                          objectId: uiRedux[info].objectId,
                          objectVerId: uiRedux[info].objectVerId
                      }
                  }
                  else {
                      subApps = action.subappName;
                      subAppInfo = {
                          ...action,
                          state: action.region
                      }
                    }
                  if(action.requestInfo){
                      let { requestInfo } = action
                      if(requestInfo.hasOwnProperty('sessionState') && requestInfo.sessionState.length > 0){
                          tkey = await dynamicObjectConstruction(requestInfo.sessionState, reduxState.UIFlowReducer, tkey, 'request')
                      }
                      if(requestInfo.hasOwnProperty('localState') && requestInfo.localState.length > 0){
                          tkey = await dynamicObjectConstruction(requestInfo.localState, {...state, ...obj}, tkey, 'request')
                      }
                      //Temporary UserState
                      if(requestInfo.hasOwnProperty('UserInfoState') && requestInfo.UserInfoState.length > 0){
                        tkey = await dynamicObjectConstruction(requestInfo.UserInfoState, reduxState.userInfoReducer, tkey, 'request')
                      }
                       if(requestInfo.hasOwnProperty('parentProps') && requestInfo.parentProps.length>0){
                          tkey = await dynamicObjectConstruction(requestInfo.parentProps, props, tkey, 'request')
                      }
                      subAppInfo={...action, state: action.region,...tkey}
                  }
                  let detailSummaryList = {}, subappNameList = [], detailSubApp = [], summarySubApp = []
                  subAppListConstruction(subApps, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
                  subAppInfo["subappName"] = subappNameList;
                  obj.transactionPrimaryObject={...obj.transactionPrimaryObject,...tkey}
                  const transactionPrimaryObject = {...state.transactionPrimaryObject,...obj.transactionPrimaryObject, ...props.rowData}
                  let response = await constructAndInvokeMetadataCallV2(subAppInfo, {transactionPrimaryObject});
                  if(action.customResponseKey){
                    obj[action.customResponseKey] = response
                    obj['SubApplicationEntity'] = response.ApplicationDetail.SubApplicationEntity
                  }else{
                  obj['pageMetadata'] = response;
                  obj['pageSubAppInfo'] = subAppInfo;
                  obj['detailSummaryList'] = detailSummaryList;
                  obj['detailSubApp'] = detailSubApp;
                  obj['summarySubApp'] = summarySubApp;
                  obj['SubApplicationEntity'] = response.ApplicationDetail.SubApplicationEntity}
              }catch(innerError){
                  throw new Error("Error in "+action.actionName.toLowerCase()+" page Load Action")
              }

          }
          else if(action.actionName.toLowerCase() === 'getapi') {
              try{
                  let request = {}, response = {}
                  let reduxState = store.getState();
                  if (action.conditions){
                    let conditionResult = dynamicConditionValidationV2({...props,...reduxState.UIFlowReducer,...reduxState.userInfoReducer}, action.conditions)
                    if(!conditionResult)  continue;
                  }
                  let { SubmissionType } = reduxState.metadataReducer;
                  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
                  request["OwnerId"] = sessionStorage.getItem("OwnerID");
                  if (action.requestInfo) {
                      let { requestInfo } = action
                      if (SubmissionType !== '' && SubmissionType !== undefined && requestInfo.eventNames && requestInfo.eventNames[SubmissionType]) {
                          request = { ...request, ...requestInfo.eventNames[SubmissionType] }
                      }
                      else {
                          request = { ...request, ...requestInfo.eventNames }
                      }
                      if (requestInfo.hasOwnProperty('sessionState') && requestInfo.sessionState.length > 0) {
                          request = await dynamicObjectConstruction(requestInfo.sessionState, {...reduxState.metadataReducer,...reduxState.UIFlowReducer}, request, 'request')
                      }
                      if (requestInfo.hasOwnProperty('localState') && requestInfo.localState.length > 0) {
                          request = await dynamicObjectConstruction(requestInfo.localState, { ...state, ...obj }, request, 'request')
                      }
                      if (requestInfo.hasOwnProperty('parentProps') && requestInfo.parentProps.length > 0) {
                          request = await dynamicObjectConstruction(requestInfo.parentProps, props, request, 'request')
                      }
                    //Temporary UserState
                      if(requestInfo.hasOwnProperty('UserState') && requestInfo.UserState.length > 0){
                        let {userInfoReducer}=reduxState
                        request = await dynamicObjectConstruction(requestInfo.UserState, {...userInfoReducer}, request, 'request')
                      }
                      let apiURL = `${requestInfo.eventURL}` + `/${requestInfo.eventVersion}`;
                      response = await AxiosInstance.post(apiURL, request);
                      apiResponse = response
                  }
                  if(response.data.ResponseStatus && response.data.ResponseStatus.toLowerCase() === 'failed'){
                      // throw new Error("Error in "+action.actionName.toLowerCase()+" page Load Action")
                      triggerToast(action.ErrorMessage || response.ResponseStatus);
                      continue;
                  }

                                      if (action.responseInfo) {
                      let { responseInfo = {} } = action;
                      let { sessionState = {}, parentState = [] } = responseInfo;
                      // if(responseInfo.customTranslation && responseInfo.customTranslateMethod && customUtil.hasOwnProperty([responseInfo.customTranslateMethod])){
                      //     let customTranslate = await customUtil[responseInfo.customTranslateMethod](response.data, props, state);
                      //     obj = { ...obj, ...customTranslate }
                      // }
                      if (responseInfo.isTranslationRequired) {
                          let constructedResponse = await constructValuesFromResponse(response.data);
                          obj = mergeDeep(obj,constructedResponse)
                      }
                      if (sessionState.hasOwnProperty('UIFlow') && sessionState.UIFlow.length > 0) {
                          let reduxUpdate = {}
                          reduxUpdate = await dynamicObjectConstruction(sessionState.UIFlow, response.data, reduxUpdate, 'response');
                          if(reduxUpdate.hasOwnProperty('pageData')){
                                let val=window.location.hash.split('/'),pageData={},redirectRoute='';
                                if(val.length > 1 && val[2]){
                                    let newVal=val[2];
                                    pageData = decodeString(newVal)
                                }
                                pageData={...pageData,...reduxUpdate.pageData}
                                const encodedString=await constructEncodedString(pageData);
                                redirectRoute+=`${encodedString}`;
                                if( props.hasOwnProperty('history') && props.history.push)
                                    props.history.push(`/${val[1]}/${redirectRoute}`);
                                delete reduxUpdate.pageData;
                          }
                          store.dispatch({ type: actionType.SET_UI_VALUES, payload: reduxUpdate })
                      }
                      if (sessionState.hasOwnProperty('ApplicationState') && sessionState.ApplicationState.length > 0) {
                          let reduxUpdate = {}
                          reduxUpdate = await dynamicObjectConstruction(sessionState.ApplicationState, response.data, reduxUpdate, 'response');
                          store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: reduxUpdate })
                      }
                      //Temporary UserState
                      if (sessionState.hasOwnProperty('UserInfoState') && sessionState.UserInfoState.length > 0) {
                        let reduxUpdate = {}
                        reduxUpdate = await dynamicObjectConstruction(sessionState.UserInfoState, response.data, reduxUpdate, 'response');
                        store.dispatch({ type: actionType.SET_SSOUSER_DATA, payload: reduxUpdate })
                        }
                      if(sessionState.hasOwnProperty('CustomUpdate') && sessionState.CustomUpdate.length > 0){
                          let reduxUpdate = {}
                          reduxUpdate = await dynamicObjectConstruction(sessionState.CustomUpdate, response, {}, "custom");
                          store.dispatch({ type: actionType.SET_UI_VALUES, payload: reduxUpdate })
                      }
                      if (parentState.length > 0) {
                          let parentStateUpdate = {}
                          parentStateUpdate = await dynamicObjectConstruction(parentState, response.data, parentStateUpdate, 'response');
                          if (props.functions && props.functions.hasOwnProperty('updateValues')) props.functions.updateValues(parentStateUpdate)
                          if (functions.hasOwnProperty('updateStateValues')) functions.updateStateValues(parentStateUpdate)
                          obj = mergeDeep(obj, parentStateUpdate)
                      }
                  }
              }catch(innerError){
                  throw new Error("Error in "+action.actionName.toLowerCase()+" page Load Action")
              }
          }
          else if(action.actionName.toLowerCase() === 'getvaluesapi'){
            try{
               let reduxState = store.getState();
               if(action.conditions){
                    let conditionResult = dynamicConditionValidationV2({...props,...reduxState.UIFlowReducer,...reduxState.userInfoReducer}, action.conditions)
                    if(!conditionResult)  continue;
                }
               let optionObj= await dynValueListconstructor(action,apiResponse.data)
               if(Object.keys(optionObj).length>0){
                obj=mergeDeep(obj,optionObj)
               }
            }catch(innerError){
                throw new Error("Error in "+action.actionName.toLowerCase()+" page Load Action")
            }
          }
          else if(action.actionName.toLowerCase()==='getentity'){
              try{
                  let state=action.region;
                  let {objectId,objectVerId,subappName}=action
                  obj['SubApplicationEntity'] =await getEntitySubAppMappingV2({subappName, objectId, objectVerId, state}, "V2")
              }catch(innerError){
                  throw new Error("Error in "+action.actionName.toLowerCase()+" page Load Action")
              }
          }
          else if(action.actionName.toLowerCase() === 'parentrowstate')
          {
              apiResponse.data={...props.rowData}
          }
          else if(action.actionName.toLowerCase() === 'entitysubapptranslation'){
              try{
                  let subAppInfo = {
                      ...action,
                      state: action.region
                  }
                  if (apiResponse.data && apiResponse.data.hasOwnProperty('ResponseStatus') && apiResponse.data.ResponseStatus.toLowerCase() === "failed") {

                  } else if(apiResponse.data) {
                      let responsefromEntity = await EntitySubAppTranslationV3({...apiResponse.data}, {...subAppInfo,...obj});
                      if(responsefromEntity){
                          obj['pagelevelValues'] ={...obj.pagelevelValues,...responsefromEntity.translatedResponse} ;
                          obj['transactionPrimaryObject'] = {...obj.transactionPrimaryObject,...responsefromEntity.translatedResponse};
                          obj={...obj,...responsefromEntity.translatedList}
                      }
                  }
              }catch(innerError){
                  throw new Error("Error in "+action.actionName.toLowerCase()+" page Load Action")
              }

          }else if(typeof libraryMethod[action.actionName.toLowerCase()] === 'function' ){
              try{
                  const {requiredDetails={}}=action;
                  await libraryMethod[action.actionName.toLowerCase()](props,requiredDetails);
              }catch(innerError){
                  throw new Error("Error in "+action.actionName.toLowerCase()+" page Load Action")
              }
          }else if(action.actionName.toLowerCase() === 'geturldata'){
            let val=window.location.hash.split('/'),pageData={};
            if(val.length > 1 && val[2]){
                let newVal=val[2];
                pageData = decodeString(newVal)
            }
            const obj=await dynamicObjectConstruction(action.sessionState,pageData,{},'response');
            store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj });
          }else{
              throw new Error(action.actionName.toLowerCase()+" is not an action")
          }
      }
      functions && functions.UpdateState ? functions.UpdateState("loading", false, true) : props.functions && props.functions.UpdateState("loading", false, true);
      return obj
  }
  catch(error){
      triggerToast(error.message)
      functions && functions.UpdateState ? functions.UpdateState("loading", false, true) : props.functions && props.functions.UpdateState("loading", false, true);
      console.log('Error in pageLoadActions :: ', error)
  }
}


const getToaster = (toaster, response) => {
  try{
      const {toasterCriteria, message="", position= "top-center", type="success"} = toaster
      const criteria =  criteriaValidationCheck(toasterCriteria, response)
      if(criteria){
          Toast[type] && Toast[type](message, { position })
      }
  }
  catch(e){
      console.log("Error in getToaster")
  }
}

export const showtoaster =async(toaster, response) => {
  try{
      if(Array.isArray(toaster)){
          toaster.map((toastData)=> {
              getToaster(toastData, response)
          })
      }
      else{
          getToaster(toaster, response)
      }
  }
  catch(e){
      console.log("Error in showtoaster", e)
  }
  }

export const constructEncodedString = async (data={},actionObject=[]) => {
    try {
        let obj=data;
        if(actionObject.length>0){
            obj={};
            obj = await dynamicObjectConstruction(actionObject, data, obj, 'response');
        }
        obj=JSON.stringify(obj);
        const iv = Buffer.alloc(16, 0);
        var key = crypto.createCipheriv('aes-128-cbc', SSOEncrytionKey, iv);
        var parameterString = key.update(obj, 'utf8', 'hex')
        parameterString += key.final('hex');
        return parameterString;
    } catch (error) {
        console.log('Error in constructEncodedString',error);
    }
}

export const constructEncodedStringSync =  (data={},actionObject=[]) => {
    try {
        let obj=data;
        obj=JSON.stringify(obj);
        const iv = Buffer.alloc(16, 0);
        var key = crypto.createCipheriv('aes-128-cbc', SSOEncrytionKey, iv);
        var parameterString = key.update(obj, 'utf8', 'hex')
        parameterString += key.final('hex');
        return parameterString;
    } catch (error) {
        console.log('Error in constructEncodedString',error);
    }
}

export const decodeString = (encodedString) => {
    try {
        const iv = Buffer.alloc(16, 0);
        var aesKey = crypto.createDecipheriv("aes-128-cbc", SSOEncrytionKey, iv);
        var decryptedString = aesKey.update(encodedString, "hex", "utf8");
        decryptedString += aesKey.final("utf8");
        return JSON.parse(decryptedString);
    } catch (error) {
        console.log('Error in decodeString',error);
    }
}