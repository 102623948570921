import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {  UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { ClickAwayListener, Paper, Popper, Tooltip } from '@material-ui/core';


import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { logUserDetailRequest } from '../../../../../../views/Pages/Login/loginUtil'
import { authAxios } from "../../../../../../views/runtime/utils/Common/API";
import { ResourceAxios } from "../../../../../../CommonAxios/ResourceAxios";
import { logout, setFromSSO } from "../../../../../../actions/authActions";
import PaletteIcon from '@mui/icons-material/Palette';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import LockResetIcon from '@mui/icons-material/LockReset';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RenderTemplate from '../../../../../../template/RenderTemplate';
import { InfoRounded } from '@material-ui/icons';
import appActions from '../../../../../../actions';
import store from '../../../../../../store';
import { Grid } from '@mui/material';
const {actionType} = appActions
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};
//const DefaultAside = React.lazy(() => import('./DefaultAside'));

class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      userName: '',
      isColorPaletteOpen:false,
      anchorEl: null
    }
    this.buttonRef = React.createRef();
    this.iconRef = React.createRef();
  }

  componentDidMount() {
      this.setState({
        firstname: this.props.userInfoReducer.Firstname,
        lastname: this.props.userInfoReducer.LastName
      })
  }

  onClickColorPalette= () => {
    this.setState({
     isColorPaletteOpen:!this.state.isColorPaletteOpen
    })
   };

   signOut = async (e) => {
    e.preventDefault();

    let request = {};
   await logUserDetailRequest(this.props.userInfoReducer, 'Logout')
    request.UserName = localStorage.getItem("userName");
    let response = await authAxios.post("/auth/logout", request);
    response = await response.data;

    if (response.Status.toLowerCase() === "success") {
      localStorage.clear();
      if (this.props.fromSSO === true) {
        this.props.setFromSSO(false);
        if(response.hasOwnProperty('ssoLogoutURL'))
        {
          window.location.href = response["ssoLogoutURL"];
        }
        // window.location.href = "https://login.microsoftonline.com/common/oauth2/logout";
      } else {
        this.props.history.push("/login");
      }
      await this.props.logout();
      ResourceAxios();
    }
  };

  onPasswordReset = async (e) => {
    e.preventDefault();
    this.props.history.push("/resetpassword");

  }
  render() {
    let reduxState = store.getState()
    let {authReducer,UIFlowReducer} = reduxState
    let {headerContentNeeded = false} = this.props.pageInfo

    const { t } = this.props;
    const { userLanguage, languageCode,imageFormat,mini_logo_path } = this.props.ownerResources;
    let pageInfo = this.props.pageInfo && this.props.pageInfo.renderTemplate
    let renderHeaderContent = this.props.pageInfo && this.props.pageInfo.renderHeaderContent
    let renderHeaderContentTemplate =  this.props.templateInfo && this.props.templateInfo[renderHeaderContent];
    let renderTemplate = this.props.templateInfo && this.props.templateInfo[pageInfo];
    const PrivilegeList  = this.props.userInfoReducer && this.props.userInfoReducer.privilageList;
    const showPaletteIcon = PrivilegeList && PrivilegeList.some(privilege => privilege.PrivilegeName === 'EDIT_STYLES');
    const showResetPassword = PrivilegeList && PrivilegeList.some(privilege => privilege.PrivilegeName === 'SHOW_RESET_PASSWORD');
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    // const userName = localStorage.getItem('userName')
    // UserName changed to Solartis Underwriter for solunderwriter
    if (this.state.firstname !== undefined && this.state.firstname !== null && this.state.firstname !== '' &&
      this.state.lastname !== undefined && this.state.lastname !== null && this.state.lastname !== '') {
      this.state.userName = this.state.lastname + ',' + this.state.firstname;
    }
    else if (this.state.firstname !== undefined && this.state.firstname !== null && this.state.firstname !== '') {
      this.state.userName = this.state.firstname;
    }
    else {
      this.state.userName = localStorage.getItem('userName')
    }


    let avatarLetter = '';
    if (this.state.userName !== '' && this.state.userName !== undefined && this.state.userName !== null) {
      avatarLetter = this.state.userName.charAt(0);
    }

    return (
      <React.Fragment>
         {headerContentNeeded && renderHeaderContent && (
            <div
              className="officeMappingContent"
            >
              <RenderTemplate {...this.props} Template={renderHeaderContentTemplate} />
            </div>
          )}<div>
          <Nav className="ml-auto" navbar>
            {headerContentNeeded && renderHeaderContent &&
              <Grid className={`Info-office`}  style={{width:"100%"}}>
                  <InfoRounded
                    ref={this.iconRef}
                    onClick={() =>
                      store.dispatch({
                        type: actionType.SET_UI_VALUES,
                        payload: { showOfficeContent: true },
                      })
                    }
                  />
                <Popper
                className="officeMappingHeaderPopper"
                  open={UIFlowReducer.showOfficeContent}
                  anchorEl={this.iconRef.current}
                  style={{
                    width: "100%",
                    height: "auto",
                    zIndex: 100,
                  }}
                >
                  <ClickAwayListener
                    onClickAway={() =>
                      store.dispatch({
                        type: actionType.SET_UI_VALUES,
                        payload: { showOfficeContent: false },
                      })
                    }
                  >
                    <Paper style={{ position: "absolute", top: 15 , width:"100%"}}>
                      <RenderTemplate {...this.props} Template={renderHeaderContentTemplate} />
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </Grid>
            }
          <NavItem className="d-md-down-none">
          <h6 className='username theme_header'>Welcome {this.state.userName}!</h6>
          </NavItem>
          <NavItem>
            <NavLink to="#" className="nav-link">
            <Tooltip title={<span className='headerIconTooltip'>Notification</span>}>
              {/* <i className="icon-bell custom-icon custom-icon-bell-size theme_header"></i> */}
              <NotificationsIcon className='color-palette-icon'/>
              {/* <i ><img className=" custom-header-icon theme_header" src={notificationsIcon} /></i> */}
              </Tooltip>
              </NavLink>
          </NavItem>
          {showPaletteIcon && (
            <NavItem>
              <Tooltip title={<span className='headerIconTooltip'>Color Palette</span>}>
                <PaletteIcon onClick={this.onClickColorPalette} className='color-palette-icon' />
              </Tooltip>
            </NavItem>
          )}
          <NavItem>
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
            <Tooltip title={<span className='headerIconTooltip'>Profile</span>}>
            <PersonIcon className='color-palette-icon' />
            </Tooltip>
            </DropdownToggle>
            {showResetPassword &&
             <DropdownMenu>
              <DropdownItem key="DPItem_welcome" header tag="div" className="text-center"><strong>Welcome {this.state.userName}!</strong></DropdownItem>
               <DropdownItem key="DPItem_resetPassword" onClick={e => this.onPasswordReset(e)} className='username theme_header'><i><LockResetIcon/></i>Reset Password</DropdownItem>
            </DropdownMenu>}
          </UncontrolledDropdown>
          </NavItem>
          <NavItem>
            <NavLink to="#" className="nav-link">
            <Tooltip title={<span className='headerIconTooltip'>Logout</span>} placement="bottom-start">
              {/* <i className="icon-logout custom-icon"></i> */}
                <LogoutIcon onClick={(e) => this.signOut(e)} className='color-palette-icon'/>
              {/* <i ><img className=" custom-header-icon theme_header" src={logoutIcon} onClick={(e) => this.signOut(e)}/></i> */}
              </Tooltip>
              </NavLink>
          </NavItem>

       </Nav>
       </div>
       {pageInfo === "ColorPalette" && <RenderTemplate Template={renderTemplate} isColorPaletteOpen={this.state.isColorPaletteOpen}/>}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  ownerResources: state.ownerResources,
  userInfoReducer: state.userInfoReducer,
  fromSSO: state.authReducer.fromSSO,
  UIFlowReducer: state.UIFlowReducer
});

const mapDispatchToProps = (dispatch) => {
  return {
    setFromSSO : data => dispatch(setFromSSO(data)),
    logout: () => dispatch(logout()),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DefaultHeader));
