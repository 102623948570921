import React, { Fragment } from 'react';
import { renderIcon } from '../../utils/LOB_Util/AU/VIN_Validator';

const CellIcon = (props) => {

    const { component, rowData } = props;

    const tempProps = {
        component,
        data : rowData
    }

    const icon = renderIcon(tempProps);

    return (
        <Fragment>
            <span key={component.ComponentId} align="left" style={{ width: "auto" }}>
                {icon ? icon : ' '}
            </span>
        </Fragment>
    )

}

export default CellIcon;
