import React, { Component } from 'react';
import { connect } from "react-redux";
import { Typography } from '@material-ui/core';

class PageLoader extends Component
{
    render(){                
                              
        return(
           <div className="custom-loader-container">
               <div className="custom-loader">
                   <img className="custom-spinner" src={this.props.ownerResources.spinner_logo_path} />
                </div>
                <div className="custom-loader-message">
                    {this.props.dynamicLoadingMessage != '' && <Typography className="text-center">{this.props.dynamicLoadingMessage}</Typography>}
                </div> 
           </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ownerResources : state.ownerResources
});

export default connect(mapStateToProps)(PageLoader);