import React, { Fragment} from "react";
import { IconButton, Tooltip } from '@material-ui/core';
import { NavigateBefore } from '@material-ui/icons';

export default function FloaterPrevious(props) {
    let { component } = props;
    let { ComponentLabel, AttributeStyleClass, OnClickAction, IsReadonly } = component;
    let onClick = props[OnClickAction] || props.lobUtil[OnClickAction];

    const handleClick = (e) => {
        onClick && onClick(e, props, component);
    }
    
    return ( 
        <Fragment>
            <Tooltip title={ComponentLabel}>
                <IconButton 
                    type="button"
                    color="primary"
                    disabled = { IsReadonly }
                    className={AttributeStyleClass}
                    onClick={handleClick}
                >
                    <NavigateBefore/> 
                </IconButton>
            </Tooltip>
        </Fragment>
    );
}
