import {AxiosInstance} from '../Common/API'; 

export const multiLobApi = {
    setSubmissionState : async  (data) => {
        let subdata = await  AxiosInstance.post('/state/setsubmissionState',{data:{state:data}});
        console.log(subdata);
    },
    getSubmissionState : async () => {
        let response = await AxiosInstance.post('/state/getsubmissionState',{data:{}});
        console.log(response.data.state);
        return response.data.state;
    },
    setLobStateValue : async (lob,state) => {
        let response = await  AxiosInstance.post('/state/setlobState',{data:{lob:lob,state:state}});
        console.log(response);
    },
    getLobStateValue : async (lob) => {
        let response = await  AxiosInstance.post(`/state/getlobState`,{data:{lob:lob}});
        return response.data.state;
    },
    clearSubmission : async (lob) => {
        let response = await  AxiosInstance.post(`/state/clearSubmission`,{data:{}});
        return response.data.state;
    },
    newSubmission : async (temp) => {
        let response = await  AxiosInstance.post(`/state/newSubmission`,{data:{"state":temp}});
    }
}