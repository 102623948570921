import React, { useState, useEffect, useMemo, useRef } from 'react';
import JoditEditor from 'jodit-react';
import { Grid } from "@material-ui/core";
import { ValidatorForm,TextValidator } from "react-material-ui-form-validator";
const configHandler=({component})=>{
  let {theme= 'default',removeButtons=[],isToolBarNeed=true,direction='ltr' } = component.Action
  let config = {
    theme,
    direction,
    readonly: component.IsReadonly || false,
    removeButtons, // ['video','about']
    placeholder: component.PlaceHolderText? component.PlaceHolderText :'',
    toolbar: isToolBarNeed
  }
  return config;
}
const RichTextEditorV2 = (props) => {
  const [content, setContent] = useState('');
  const config = useMemo(() => configHandler(props), [props.component]);
  const inputElement = useRef()


  let { componentState: { pagelevelValues }, component } = props;

  useEffect(() => {
    if(pagelevelValues && pagelevelValues[component.SubApplicationName] && pagelevelValues[component.SubApplicationName][component.AttributeName]){
      let onLoadValues = pagelevelValues[component.SubApplicationName][component.AttributeName];
      setContent(onLoadValues)
    }
    ValidatorForm.addValidationRule("IsEmptyRTE", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
  }, [])





//  Reference for RichTextEditor Config for Future Updates
//  let config2 = {
//   zIndex: 0,
//   readonly: false,
//   activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about'],
//   toolbarButtonSize: 'middle',
//   theme: 'default',
//   enableDragAndDropFileToEditor: true,
//   saveModeInCookie: false,
//   spellcheck: true,
//   editorCssClass: false,
//   triggerChangeEvent: true,
//   height: 450,
//   direction: 'ltr',
//   language: 'pt_BR',
//   debugLanguage: false,
//   i18n: 'en',
//   tabIndex: -1,
//   toolbar: true,
//   enter: 'P',
//   useSplitMode: true,
//   colorPickerDefaultTab: 'background',
//   imageDefaultWidth: 100,
//   // removeButtons: ['about', 'print', 'file'],
//   disablePlugins: ['paste', 'stat'],
//   events: {},
//   textIcons: false,
//   uploader: {
//       insertImageAsBase64URI: true,
//   },
//   placeholder: 'hinokami kagura',
//   showXPathInStatusbar: false,
// }

const decodeHtmlEntities = (str) => {
  const entityMap = {
    '&quot;': "'",
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&nbsp;': ' ',
    '&apos;': "'",
    '&cent;': '¢',
    '&pound;': '£',
    '&yen;': '¥',
    '&euro;': '€',
    '&copy;': '©',
    '&reg;': '®',
    '&#39;': "'",
    '&#34;': '"',
  };
  return str.replace(/&quot;|&amp;|&lt;|&gt;|&nbsp;|&apos;|&cent;|&pound;|&yen;|&euro;|&copy;|&reg;|&#39;|&#34;/g, (match) => entityMap[match]);
};

  useEffect(() => {
    if(content){
      const cleanedString = decodeHtmlEntities(content);
      props.handleRTEChange(cleanedString,component)
    }
  }, [content])

  let autocomplete="off";
  let custom3=component.Custom3 && JSON.parse(component.Custom3);
  (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

  let isRequired = component.IsRequired === "Y" ? true : false;
  const validations = [];
  const errorMessages = []
  
  if (isRequired) {
    validations.push("required");
    errorMessages.push(component.RequiredMessage);
    validations.push("IsEmptyRTE");
    errorMessages.push(component.RequiredMessage);
  }
  
  return (
    <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} className={`Editor-Border ${component.StyleClass}`} >
      <JoditEditor
        value={content}
        tabIndex={1}
        config={config}
        onChange={(content) => setContent(content)}
        autoComplete={autocomplete}
        className={component.AttributeStyleClass}
      />
        <TextValidator
        className='texterrorthrowstyles'
        inputRef={inputElement}
        value={content}
        validators={validations}
        errorMessages={errorMessages}
        autoComplete={autocomplete}
      /> 
    </Grid>
    
  );
};

export default RichTextEditorV2
