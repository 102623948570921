import React, { Component } from 'react'
import container from '../utils/DIContainer';
import store from "../../../store";

class CustomComponent extends Component {
  functionCall = (UIComponent, component) => {
    let { SubApplicationDetailList = [] } = component;
    const dynamicRender = container.get('dynamicRender')
    const ownerResources = store.getState().ownerResources;
    const variant = ownerResources.clientInfo.COMPONENT_VARIANT_CONFIG ? ownerResources.clientInfo.COMPONENT_VARIANT_CONFIG : "standard"

    return (
      <UIComponent
        {...this.props}
        component={component}
        componentLabel={this.props.componentLabel}
        currentPageIndex={this.props.currentPageIndex}
        createSubmissionRes={this.props.createSubmissionRes}
        values={this.props.values}
        handleGoogleRegion={this.props.handleGoogleRegion}
        attributeValue={this.props.attributeValue}
        transactionPrimaryObject={this.props.transactionPrimaryObject}
        transactionValue={this.props.transactionValue}
        handleInputChange={this.props.handleInputChange}
        handleInputChangeV2={this.props.handleInputChangeV2}
        handleRTEChange={this.props.handleRTEChange}
        handleAutoCompletechange={this.props.handleAutoCompletechange}
        updateValues={this.props.updateValues}
        handleDateChange={this.props.handleDateChange}
        center={{ lat: 41.850033, lng: -87.6500523 }}
        height='300px'
        zoom={15}
        isDynamicTable={false}
        componentvalue={this.props.componentvalue}
        pagelevelValues={this.props.pagelevelValues}
        handleHiddenInputChange={this.props.handleHiddenInputChange}
        onClick_ClearButton={this.props.onClick_ClearButton}
        invokeGetAccount={this.props.invokeGetAccount}
        updateValueWithSubplicationName={this.props.updateValueWithSubplicationName}
        onDatatableAdd={this.props.onDatatableAdd}
        onExport={this.props.onExport}
        dynamicdatatablerows={this.props.dynamicdatatablerows}
        onImport={this.props.onImport}
        invokeGetAccountAddress={this.props.invokeGetAccountAddress}
        useaccountaddress={this.props.useaccountaddress}
        customTable={this.props.customTable}
        customTableRowIndex={this.props.customTableRowIndex}
        handleRowLevelInputChange={this.props.handleRowLevelInputChange}
        invokeGetInsuredAddress={this.props.invokeGetInsuredAddress}
        useinsuredaddress={this.props.useinsuredaddress}
        disableCopyInsuredAddress={this.props.disableCopyInsuredAddress}
        isInsuredAddressAdded={this.props.isInsuredAddressAdded}
        checkCopyInsuredAddress={this.props.checkCopyInsuredAddress}
        componentProps={this.props.componentProps}
        componentState={this.props.componentState}
        TableControls={this.props.TableControls}
        functions={this.props.functions}
        stateList={this.props.stateList}
        handleVinDecode={this.props.handleVinDecode}
        enableEditOption={this.props.enableEditOption}
        responseList={this.props.responseList}
        responseLists={this.props.responseLists}
        variant={variant}
      >
        {SubApplicationDetailList.length > 0 && dynamicRender(SubApplicationDetailList, this.props.componentProps, this.props.componentState, this.props.functions, this.props.TableControls, this.props.summarySubApp, this.props.detailSummaryList, '', '', this.props.staticReadonlyRequired)}
      </UIComponent>
    )
  }

  render() {
    const { component } = this.props;
    const Controls = container.get('componentControls')
    let isInvisible = component.IsInvisible.toLowerCase() === 'y' ? true : false
    const componentType = component.ComponentType;
    const UIComponent = Controls[componentType.toLowerCase()];

    if (UIComponent) {
      if (isInvisible) {
        let removeValidations = {
          "IsRequired": "N",
          "MinValue": "",
          "MaxValue": "",
          "ValidationRegex": ""
        }
        //Component Without Any Validations
        let componentWOV = Object.assign({}, component, removeValidations);
        return (
          <span style={{ display: 'none' }}>
            {this.functionCall(UIComponent, componentWOV)}
          </span>
        )
      }
      else {
        return (
          this.functionCall(UIComponent, component)
        )
      }
    } else {
      return (<div style={{ color: 'red', fontWeight: '4px' }}>
      </div>
      )
    }
  }
}

export default CustomComponent;
