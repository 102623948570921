import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const CellLinkText = (props) => {
    let { component, rowData, tableUtils } = props;
    
    let goto = component.LinkText;

    const getID = (e) => {
        if(props.redirectTo){
            props.redirectTo(props);
        }else{
            let id = rowData.hasOwnProperty('ID') ? rowData['ID'] : '';
            tableUtils.redirectTo(id, goto, rowData);
        }
    }

    return (
        <Fragment>
            <span key={component.ComponentId} align="left" style={{ width: "auto" }}>
                <Link
                    className="nav-link"
                    onClick={(e) => getID()}
                >
                    { rowData[component.AttributeName] }
                </Link>
            </span>
        </Fragment>
    )
}

export default CellLinkText;
