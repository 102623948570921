import * as React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import RenderTemplate from "../../../template/RenderTemplate";
import store from "../../../store";
import appActions from "../../../actions";
import { useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from "@mui/material";
import { CloseOutlined } from "@material-ui/icons";
import { Grid } from "@material-ui/core";

const { actionType } = appActions;

export default function Popupwrapper(props) {

  const UIFlowReducer = useSelector((state) => state.UIFlowReducer);
  let template = props.pageInfo;
  const disableEnforceFocus = template.disableEnforceFocus || false;
  const titleNeeded = props.pageInfo.Title && props.pageInfo.Title !== '' ? true : false || false,dialogTitle=props.pageInfo.Title;
  const handleClose = () => {
    store.dispatch({
      type: actionType.SET_UI_VALUES,
      payload: { [`${template.name}_popup`]: false },
    });
  };


  let maxWidth = template.Size || "sm";
  let open = UIFlowReducer[`${template.name}_popup`];

  return (
    <React.Fragment>
      {open ?
      <Grid container spacing={2}>
      <Grid item xs={12} lg={12} md={12} xl={12}>
      <Dialog
       disableEnforceFocus={disableEnforceFocus}
        fullWidth={true}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        className={`DialogDefaultClass ${template.className}`}
      >
        <div className="flex">
        {
            titleNeeded && 
            <DialogTitle id="form-dialog-title">  
                <span> {dialogTitle} </span> 
            </DialogTitle>
        }
        <span className ="flexPushRight">
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseOutlined />
        </IconButton>
        </span>
        </div>
        <DialogContent>
          {<RenderTemplate {...props} Template={template.children} />}
        </DialogContent>
      </Dialog>
      </Grid>
      </Grid>
       : null
      }
    </React.Fragment>
  );
}