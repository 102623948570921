import React, { Fragment, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
 import { commonLinkMethod } from '../ButtonUtil';
import { constructEncodedString } from '../../utils/Common/NoCodeUtils';
import store from '../../../../store';

const CellLinkTextV2 = (props) => {  
  const [obj,setObj]=useState({})
  let { component, rowData, tableUtils, tableFunctions = {},isDynamicTable=false } = props;
  
  let goto = component.LinkText;
  let reduxState = store.getState()
  useEffect(()=>{
    if(component.Action && component.Action.URLInfo && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
      constructEncodedString(rowData,component.Action.URLInfo).then(res=>{
        let redirectTo=res
        let redirectURL='';
        if(component.Action.RedirectTo){
          redirectURL=`/${component.Action.RedirectTo.toLowerCase()}/${redirectTo}`;
          setObj({to:redirectURL});
        }
      })
    }
  },[rowData[component.AttributeName]]);
  const getID = (e) => {
    if(component.OnClickAction != "" && tableFunctions.hasOwnProperty(component.OnClickAction)){
      tableFunctions[component.OnClickAction](rowData, props);
    }
    else if(component.Action && Object.keys(component.Action).length > 0){
       commonLinkMethod(component, rowData, props, obj.to);
    }
    else {
      let id = rowData.hasOwnProperty('ID') ? rowData['ID'] : '';
      tableUtils.redirectTo && tableUtils.redirectTo(id, goto, props)
    }
  }

  return (
    <Fragment>
    <span key={component.ComponentId} align="left" style={{ width: "auto" }}>
      {isDynamicTable ? <Link
        className={`nav-link link_${component.StyleClass}`}
        onClick={(e) => getID()}
        {...obj}
        onMouseDown={(e)=>{
          e.stopPropagation();
        }}
        onDragStart={(e)=>{
          e.stopPropagation()
        }}
        onDragOver={(e)=>{
          e.someValue='preventDragEvent'
          e.preventDefault()
        }}
        onDragEnd={(e)=>{
          e.someValue='preventDragEvent'
          e.preventDefault()
        }}
      >
        {rowData[component.AttributeName]}
      </Link> : <Link
        className={`nav-link link_${component.StyleClass}`}
        onClick={(e) => getID()}
      >
        {rowData[component.AttributeName]}
      </Link>}
    </span>
    </Fragment>
  )
}

export default withRouter(CellLinkTextV2);
