import templateControls from "../../../template";
import container from "./DIContainer";
import { Utils,ProductNavigationKeys } from "./UtilsIndex";
import componentControls from '../controls/index'
import dynamicRender from "../sections/CustomComponents/dynamicRender";
import dynamicRenderV2 from "../sections/CustomComponents/dynamicRenderV2";
import * as CommonUtils from './Common/CommonUtils'
import componentFunction from "./componentFunction";

container.register('Utils',Utils)
container.register('ProductNavigationKeys',ProductNavigationKeys)
container.register('templateControls',templateControls)
container.register('componentControls',componentControls)
container.register('dynamicRender',dynamicRender)
container.register('dynamicRenderV2',dynamicRenderV2)
container.register('CommonUtils',CommonUtils)
container.register('componentFunction',componentFunction)
