import queryString from "query-string";
import Cookies from 'universal-cookie';
import appActions from "../../../../actions";
import store from "../../../../store";
import { Buffer } from 'buffer';
import { logUserDetailRequest } from '../../../Pages/Login/loginUtil';
import { AxiosInstance,authAxios,getServiceRequestDetail } from "../Common/API";
import { triggerToast } from "../Common/HelperUtils";
import LogRocket from "logrocket";
import zipy from 'zipyai'; 
const cookies =new Cookies();
const { actionType } = appActions;

export const ssofunction = async (props) => {  
    store.dispatch({ type: actionType.SHOW_LOADER });
    if (cookies.get("data") != null) {
      var ssoData = cookies.get("data");
      if(ssoData["fromSSO"]  === "True") store.dispatch({ type: actionType.SET_FROM_SSO, payload: true });
      if (ssoData["FirstTimeLanding"] == "True") {
        // store.dispatch({ type: actionType.SET_FROM_SSO, payload: true });
        //let url = this.props.location.search;
        let params = queryString.parse(props.location.search);
        let paramsdata = {};
        paramsdata = await decryptParameters(params.data);
        var dataSplitted = paramsdata.pureParameter.split(";");
        var dataMap = {}
        for (var i = 0; i < dataSplitted.length; i++) {
          var tempData = dataSplitted[i].replace(/=*$/, '').split("=");
          dataMap[tempData[0]] = tempData[1]? Buffer.from(tempData[1], 'base64').toString('utf-8'):''
        }
        console.log("ExternalAgent: ssofunction", dataMap)
        console.log('userData', JSON.parse(dataMap['userExternalData']))
        dataMap['userExternalData'] = JSON.parse(dataMap['userExternalData'])
        const ExternalAgentID = dataMap['ExternalAgentID']
        const ExternalAgencyID = dataMap['ExternalAgencyID']
        console.log("ExternalAgent: ExternalAgentID", ExternalAgentID)
        console.log("ExternalAgent: ExternalAgencyID", ExternalAgencyID)
        dataMap['SourceRequestID'] = paramsdata['SourceRequestID'];
        dataMap['CurrentRequestID'] = paramsdata['CurrentRequestID'];
        dataMap['RequestSequenceID'] = parseInt(paramsdata['RequestSequenceID']) + 1;
        if(dataMap["UserDisplayName"].includes(" ")){
          dataMap["firstName"] = dataMap["UserDisplayName"].split(" ")[0];
          dataMap["lastName"] = dataMap["UserDisplayName"].split(" ")[1];
        }
        else{
          dataMap["firstName"] = dataMap["UserDisplayName"];
          dataMap["lastName"] = "";
        }
        const { ownerResources } = store.getState();
        const {clientInfo} = ownerResources;
        if(clientInfo && clientInfo.Enablezipy === 'Y' && clientInfo.zipyAppId) {
          console.log("SSOCheck check 0", dataMap["UserDisplayName"])
          if (clientInfo.EnablezipyAllUsers === 'Y') {
            zipy.anonymize();
            zipy.init(clientInfo.zipyAppId);
            zipy.identify(dataMap["UserDisplayName"]);
          } else {
            let userList =  clientInfo.zipyUserList && clientInfo.zipyUserList.split(";");
            if (userList.includes(dataMap["UserDisplayName"])) {
              zipy.anonymize();
              zipy.init(clientInfo.zipyAppId);
              zipy.identify(dataMap["UserDisplayName"]);
            }
          }
        }
        if(clientInfo && clientInfo.EnableLogRocket === 'Y' && clientInfo.logRocketAppId) {
          console.log("SSOCheck check 0", dataMap["UserDisplayName"])
          if (clientInfo.EnableLogRocketAllUsers === 'Y') {
          LogRocket.init(clientInfo.logRocketAppId);
          LogRocket.identify(dataMap["UserDisplayName"]);
          } else {
            let userList =  clientInfo.LogRocketUserList && clientInfo.LogRocketUserList.split(";");
            if (userList.includes(dataMap["UserDisplayName"])) {
              LogRocket.init(clientInfo.logRocketAppId);
              LogRocket.identify(dataMap["UserDisplayName"]);
            }
          }
        }
        console.log("ayy check end")
        const status = await getPrivilegeBasedOnRole(dataMap, props);
        let saveExternalData = await AxiosInstance.post(`core/saveSSOSessionData`,{...dataMap});
        saveEntityData(dataMap);
        console.log({saveExternalData})
        
        if(ExternalAgentID){
          await GetDefaultAgencyAgent(ExternalAgentID, ExternalAgencyID)
        }
        ssoData["FirstTimeLanding"] = "False";
        cookies.set("data", ssoData);
        // console.log("sso ssofunction",status);
        return status;
      }
    }
    store.dispatch({ type: actionType.HIDE_LOADER });
  };
  const getPrivilegeBasedOnRole = async (dataMap, props) => {
    let authRequest = require("../../models/AuthRequest.json");
    authRequest.UserCredential.UserName = dataMap["UserRole"];
    authRequest.UserCredential.Password = "sKVSbX7wj8UYYAaar4amxQ==";
    authRequest.ServiceRequestDetail.OwnerId = sessionStorage.getItem("OwnerID").toString();
    let authData = {};
    const status = await authAxios.post("auth/login?SourceRequestID="+dataMap['SourceRequestID']+"&CurrentRequestID="+dataMap['CurrentRequestID']+"&RequestSequenceID="+dataMap['RequestSequenceID'], authRequest).then(
      (response) => {
        if (Object.keys(response.data).length > 0) {
          if (response.data.RequestStatus === "PROCESSED") {
            authData = response.data;
            let userInfo={};
            userInfo['userRole']=authData["UserRole"];
            store.dispatch({type:actionType.SET_METADATA_PRIVILAGE,payload:authData.privilageList});
            store.dispatch({ type : actionType.SET_USER_INFO, payload : userInfo });
            store.dispatch({ type: actionType.SET_USERROLE, payload:authData["UserRole"]})
            authData.userName = dataMap["UserDisplayName"];
            // this.props.authenticate(authData);
            let urlPath = dataMap.path ? dataMap.path : ""
            localStorage.setItem("userName", dataMap["UserDisplayName"]);
            localStorage.setItem("FirstName", dataMap["firstName"]);
            localStorage.setItem("LastName", dataMap["lastName"]);
            if(dataMap['ObjectType']){
              if(dataMap['ObjectType'].toLowerCase() == "submission" && dataMap['ObjectID']){
                  store.dispatch({ type: actionType.SET_FLOW_TYPE, payload: "Find" });
                  store.dispatch({ type: actionType.SET_SUBMISSIONID, payload: dataMap['ObjectID'] });
                  // store.dispatch({ type: actionType.SET_FLOW, payload: 'QuoteNow'})
                  // store.dispatch({ type: actionType.SET_LANDING_PAGE, payload: dataMap['LandingPage'] });
                  store.dispatch({ type: actionType.SET_LANDING_PAGE, payload:dataMap['ObjectType'].toLowerCase() });
                  window.location.href = "https://"+dataMap['Domain']+`${urlPath}`+"/#/"+dataMap['ObjectType'].toLowerCase();
                  // window.location.href = "http://localhost:3000/web"+"/#/"+ dataMap['ObjectType'].toLowerCase(); 
              } else if(dataMap['ObjectType'].toLowerCase() == "quote" && dataMap['ObjectID']){
                  store.dispatch({ type: actionType.SET_QUOTE_ID, payload: dataMap['ObjectID'] });
                  // store.dispatch({ type: actionType.SET_CURRENT_PAGEINDEX, payload: 1 });
                  window.location.href = "https://"+dataMap['Domain']+`${urlPath}`+"/#/"+dataMap['ObjectType'].toLowerCase();
                  // window.location.href = "http://localhost:3000/web"+"/#/"+ dataMap['ObjectType'].toLowerCase();
              } else if(dataMap['ObjectType'].toLowerCase() == "policy" && dataMap['ObjectID']){
                  store.dispatch({ type: actionType.SET_POLICY_ID, payload: dataMap['ObjectID'] });
                  window.location.href = "https://"+dataMap['Domain']+`${urlPath}`+"/#/"+dataMap['ObjectType'].toLowerCase();
                  // window.location.href = "http://localhost:3000/web"+"/#/"+dataMap['ObjectType'].toLowerCase();
              } else if(dataMap['ObjectType'].toLowerCase() == "home" && dataMap['ObjectID']){
                  window.location.href = "https://"+dataMap['Domain']+`${urlPath}`+"/#/"+dataMap['ObjectType'].toLowerCase();
                  // window.location.href = "http://localhost:3000/web"+"/#/"+dataMap['LandingPage'];
              } else {
                  window.location.href = "https://"+dataMap['Domain']+`${urlPath}`+"/#/home";
                  // window.location.href = "http://localhost:3000"+`${urlPath}`+"/#/home";
              }
            }
            else{
              window.location.href = "https://"+dataMap['Domain']+`${urlPath}`+"/#/home";
              // window.location.href = "http://localhost:3000"+`${urlPath}`+"/#/home";
            }
            store.dispatch({ type: actionType.HIDE_LOADER });
            logUserData(response.data, dataMap);
            return(
              { loginErrors: ""}
              //this.props.history.push("/")
            );
          } else {
            cookies.remove("SessionID");
            props.history.push("/login");
            return({
              loginErrors: "Invalid Username or Password",
              loading: false,
            });
          }
        } else {
          cookies.remove("SessionID");
          props.history.push("/login");
          return({
            loginErrors: "Invalid Username or Password",
            loading: false,
          });
        }
      },
      (error) => {
        console.log("error occured while authenticating :: ", error);
        cookies.remove("SessionID");
        props.history.push("/login");
        return({
          loginErrors: "Server Error, please try again later",
          loading: false,
        });
      }
    );
    return status;
  };

  const saveEntityData = async (dataMap) => {
    const entityRequest = {
      EntityNumber: dataMap["UserUniqueId"],
      EmailId: dataMap["UserEmailID"],
      EntityType: dataMap["UserRole"],
      Name: dataMap["UserDisplayName"],
      UserExternalData: dataMap['userExternalData']
    };
    if (dataMap.userExternalData && Object.keys(dataMap.userExternalData).length > 0) {
      let DefaultOfficeName = ''
      let AssociatedOffice = []
      DefaultOfficeName = dataMap.userExternalData.HomeOffice
      let {EntitledOffice = []} = dataMap.userExternalData
      if (EntitledOffice.length > 0) {
        for (let i = 0; i < EntitledOffice.length; i++) {
            let a = { "OfficeName": EntitledOffice[i] };
            AssociatedOffice.push(a);
        }
      }
      entityRequest['DefaultOfficeName'] = DefaultOfficeName
      entityRequest['AssociatedOffice'] = AssociatedOffice
    }
    console.log(entityRequest, 'entityReq')
    await authAxios
      .post("auth/saveEntity", entityRequest)
      .then((response) => {
        if (response.data.entityID) {
          getProfileData(response.data.entityID)
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getProfileData = async (entityID) => {
    let userHeaderRequset = require("../../../runtime/models/getAccountProfile.json");
    userHeaderRequset.EventName = "SolartisCommonMaster_WF";
    userHeaderRequset.OwnerId=sessionStorage.getItem("OwnerID")
    userHeaderRequset.TargetEventName = "getAccountProfile";
    userHeaderRequset.EventAction = "GetAccountProfileV1";
    userHeaderRequset.ExternalEntityId = entityID
    delete userHeaderRequset.UserProfile
    try {
      let {data} = await AxiosInstance.post("/core/invokeWorkflowEvent/V4", userHeaderRequset);
      console.log({data})
      if (data && data.ExternalInformation) {
        let externalInfo  = data.ExternalInformation || {};
        store.dispatch({ type : actionType.SET_EXTERNAL_INFO, payload : externalInfo })
      }
    } catch(err) {
      console.log(err);
    }
  }

  const decryptParameters = async (parameter) => {
    let response = await authAxios.post("auth/getPureParameters", {
      parameterData: parameter,
    });
    let decrytedResponse = {};
    decrytedResponse['pureParameter'] = response.data.pureParameter;
    decrytedResponse['CurrentRequestID'] = response.data.amtDataID.currentRequestID;
    decrytedResponse['SourceRequestID'] = response.data.amtDataID.sourceRequestID;
    decrytedResponse['RequestSequenceID'] = response.data.amtDataID.requestSequenceID;
    return decrytedResponse;
  };

  const logUserData = async (loginData, userData) => {
    let userInfo = {}
    userInfo['Role'] = loginData.UserRole;
    userInfo['LastName'] = userData.lastName;
    userInfo['UserName'] = userData['UserRole']
    logUserDetailRequest(userInfo, 'Login', "SSO");
  }

  async function GetDefaultAgencyAgent(ExternalAgentID, ExternalAgencyID){
    try{
     let request = {};
      request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
      request.EventName="GetAgencyAgentbyExternalId"
      request.ExternalAgentID=ExternalAgentID
      request.ExternalAgencyID=ExternalAgencyID
      request.OwnerId = "";
      console.log("GetDefaultAgencyAgent request----->",request)
      let response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request);

      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
         triggerToast("GetDefaultAgencyAgent Response Failed");
         return;
      }
      if(response.data.hasOwnProperty('Message')){
        triggerToast(response.data.Message);
        return;
      }
      let defaultAgentID = response.data.defaultAgentID
      let defaultAgencyID = response.data.defaultAgencyID
      store.dispatch({ type: actionType.SET_USER_INFO, payload: {defaultAgentID,defaultAgencyID } })
      store.dispatch({ type: actionType.SET_AGENTID, payload: defaultAgentID})
      store.dispatch({ type: actionType.SET_AGENCYID, payload: defaultAgencyID })
    }
    catch(err){
      console.error(`Error in GetDefaultAgent ${err}`)
    }
  }
