const getParentTabProperties = (params) => {
    let tabProps = {
        ID:"parentTab",
        onChange: params.parentTabHandler
    }
    return tabProps
}

const getChildTabProperties = (params) => {
    let tabProps = {
        ID:"childTab",
        onChange: params.childTabHandler
    }
    return tabProps
}

export const parent = {
    getTabProperties: getParentTabProperties
}

export const child = {
    getTabProperties: getChildTabProperties
}