import React, { useState } from "react";
import {TextValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';

function SelectFilterDropdownValidator (props) {

    const[inputValue,setInputValue]=useState('')
    const {value,options,component,validators,errorMessages,variant} = props;
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
     
    return (
      <React.Fragment className={component.AdjacentLabel === "Y" ? "labelrightalignment": ""}> 
                <Autocomplete
                    style={{ display: "inline-flex"}}
                    freeSolo
                    className={`custom-autocomplete autoComplete_${component.StyleClass} lineHeight`}
                    autoHighlight
                    disabled={component.IsReadonly}
                    id={component.AttributeName}
                    value={value}
                    options={options ? options : []}
                    inputValue={inputValue}
                    onChange={(e, newValue) => props.onChange(e, newValue)}
                    onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
                    getOptionLabel={option => option.DisplayValue}
                    clearOnBlur={true}
                    autoComplete={autocomplete}
                    renderInput={(params) =>
                        <TextValidator 
                            {...params}
                            value={inputValue}
                            className="custom-input"
                            label={(component.AdjacentLabel !== "Y")&&<Typography className="custom-label-down small_label">{component.IsRequired === 'Y' ? `${component.ComponentLabel} *` : component.ComponentLabel}</Typography>}
                            margin="normal"
                            validators={validators}
                            variant={variant}
                            errorMessages={errorMessages}
                            fullWidth
                            autoComplete={autocomplete}
                            InputProps={{
                                ...params.InputProps
                            }}
                        />}>
                 </Autocomplete>
      </React.Fragment>
    );
  }

export default SelectFilterDropdownValidator;
