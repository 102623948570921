import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {  Grid } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
class CopyAccountAddress extends Component {
    render() {
        const { component } = this.props;
        return (
        <Grid item xs={12} sm={12} md={component.Size > 0 ? component.Size : 12} lg={component.Size > 0 ? component.Size : 12} className={component.AttributeStyleClass !== "" ? component.AttributeStyleClass : ""}>    
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            name={component.AttributeName}
                            checked={this.props.componentvalue}
                            onChange={(event) => this.props.invokeGetAccountAddress(event, component)}
                            size="small"
                            disabled={this.props.useaccountaddress}
                        />
                    }
                    label={component.ComponentLabel}
                />
            </FormGroup>
        </Grid>   );
    }
}

export default CopyAccountAddress;

