import React from 'react';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { getListAxios, getJSONRequestFromAPI } from '../utils/Common/API';
import { setTransactionRequest } from '../../../actions/workFlowEventAction';
import { connect } from 'react-redux';
import { Utils } from '../utils/Common/AutoCompleteUtil'
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { triggerToast } from '../utils/Common/HelperUtils';

function AutoCompleteV2(props) {

  const [valuee, setValue] = React.useState(props.componentvalue !== undefined ? props.componentvalue : '');
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([] || {});
  const [showlist, setShowlist] = React.useState(false);
  const loading = open && options.length === 0;
  const { component } = props;
  // const itemtip = component.ItemTipParams;
  let showSearchIcon = (props.componentvalue === undefined || props.componentvalue === '') ? true : false;
  const [selectedvalue, setselectedvalue] = React.useState({});

  function getdetails(changevalue) {
    setShowlist(changevalue);
  }

  function valuesList(component) {
    let itemparams = component.ItemTipParams.split(",");
    let pagelevelvalues = props.pagelevelValues;
    let value = {};
    itemparams.forEach((itemparam, index) => {
      if (index > 0) {
        let item = itemparam.split("~");
        value[item[1]] = pagelevelvalues[component.SubApplicationName][component.AttributeName][item[0]];
      }
    })
    setselectedvalue(value);
  }

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      try{
        let request = {};
        if (props.workflowReducer.transactionRequest.hasOwnProperty(component.AttributeParams)) {
          request = JSON.parse(JSON.stringify(props.workflowReducer.transactionRequest[component.AttributeParams]));
        }
        else {
          request = await getJSONRequestFromAPI(component.EventName, props.UIFlowReducer.productDetails);
          props.setTransactionRequest(JSON.parse(JSON.stringify(request)));
        }
        if (props.pageInfo.utilfunctionNeeded) {
          const Util = props.UIFlowReducer.productDetails.hasOwnProperty("LineOfBusiness") ? Utils[props.UIFlowReducer.productDetails["LineOfBusiness"]] || {} : {};
          request = Object.assign({}, request, Util[component.AttributeName](props), props.UIFlowReducer.productDetails);
        }
        else
          request = Object.assign({}, request, props.values, props.pagelevelValues[component.SubApplicationName]);
        let response = null;
        response = await getListAxios.post('core/invokeWorkflowEvent', request);
        if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
         throw new Error("Response Failed");
        }else{
        if (active) {
          if (Array.isArray(response.data) && response.data.length > 0) {
            setOptions(response.data);
          }
          else if (typeof response === 'object' && Object.keys(response).length > 0) {
            if(response.data[component.ResponseKey])
              setOptions(response.data[component.ResponseKey])
            else
              throw new Error('');
          }
        }
      }
      }
      catch(error){
        console.log(error)
        triggerToast(error.message)
        setOpen(false)
      }
    })();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    if (props.componentvalue) {
      setValue({ LookupDisplayValue: props.componentvalue });
      setInputValue(props.componentvalue);
    }
    else {
      setValue({ Name: '' });
      // setInputValue('');
    }
  }, [props.componentvalue])


  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  ValidatorForm.addValidationRule('IsEmpty', (value) => {
    if (!value) {
      return false;
    }
    return true;
  })

  let isRequired = component.IsRequired === "Y" ? true : false;
  const validations = [];
  const errorMessages = [];
  // let componentLabel = isRequired ? component.ComponentLabel + ' *' : component.ComponentLabel;
  if (isRequired) {
    validations.push('required');
    errorMessages.push(component.RequiredMessage);
    validations.push('IsEmpty');
    errorMessages.push(component.RequiredMessage);
  }
  let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";


  const handleAutoComplete = (data, component) => {
    let newObj = {};
    if (data) {
      let displayValue = data.LookupDisplayValue ? data.LookupDisplayValue : data.DisplayName
      newObj = {
        [component.AttributeName]: displayValue,
        ...data
      }
    }
    let itemtip
    props.handleAutoCompletechange(newObj, itemtip, component);
  }


  return (

    <Grid container xs={12} sm={12} md={12} lg={12} key={component.AttributeName} className={component.AttributeStyleClass + (component.AdjacentLabel === "Y" ? "labelrightalignment": "")} style={{ padding: '12px', paddingTop: '0px' }}>
      <Grid item xs={12} md={12} lg={7} sm={12} >
      {component.AdjacentLabel === "Y"  && <Typography className="custom-label-down labelstyle">{component.IsRequired === 'Y' ? `${component.ComponentLabel}* ` : component.ComponentLabel}</Typography>}
        <Autocomplete style={{ display: "inline-flex", width: "85%" }}
          className="custom-autocomplete"
          disabled={component.IsReadonly}
          autoComplete={autocomplete}
          id="asynchronous-demo"
          value={valuee}
          freeSolo
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
          }}
          inputValue={inputValue}
          onChange={(event, newValue) => { handleAutoComplete(newValue, component); setValue(newValue); }}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          getOptionLabel={(option) => option.LookupDisplayValue || option.DisplayName}
          options={options}
          loading={loading}
          disableClearable={component.IsReadonly === 'true' ? true : false}
          renderInput={(params) =>
            <TextValidator {...params}
              placeholder={component.ComponentLabel}
              value={inputValue}
              autoComplete={autocomplete}
              className="custom-input"
              name={component.AttributeName}
              label={(component.AdjacentLabel !== "Y")&&(<Typography className="custom-label-down">{component.IsRequired === 'Y' ? `${component.ComponentLabel} *` : component.ComponentLabel}</Typography>)}
              margin="normal"
              validators={validations}
              errorMessages={errorMessages}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {showSearchIcon && <IconButton> <SearchIcon /> </IconButton>}
                  </React.Fragment>
                )
              }}
            />}
        />

        {valuee !== null && valuee.DisplayValue !== "" && component.ItemTipParams &&
          <IconButton className="custom-svg-icon InfoiconIEOverride" aria-label="add an alarm" style={{ transform: "rotate(180deg)", color: '#20a8d8', fontSize: '1rem !important' }} onBlur={() => { setShowlist(false) }} onClick={() => { getdetails(!showlist); valuesList(component); }} >
            <ErrorIcon />
          </IconButton>
        }

      </Grid>
      <Grid item xs={6} md={6} lg={5} sm={6} >

        {
          showlist ?
            <Card style={{ position: 'absolute', marginTop: '0', zIndex: '1', border: '0.1px solid gainsboro', boxShadow: '1px 1px gray', minWidth: '230px' }}>
              <CloseIcon style={{ float: 'right', paddingRight: '5px', paddingTop: '5px', fontSize: '20px' }} onClick={() => { setShowlist(false) }} />
              <CardContent>
                {
                  selectedvalue != null ?
                    Object.keys(selectedvalue).map(function (key) {
                      return (
                        <div style={{ marginBottom: '5px' }}><p style={{ fontWeight: 'bold', marginBottom: '0px' }}>{key}</p>
                          <p style={{ marginBottom: '0px' }}>{selectedvalue[key]}</p></div>)
                    })
                    : null
                }
              </CardContent>
            </Card>
            :
            null
        }
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => ({
  workflowReducer: state.workflowReducer,
  metadataReducer: state.metadataReducer,
  navigationReducer: state.navigationReducer,
  UIFlowReducer: state.UIFlowReducer
});

const mapDispatchToProps = dispatch => {
  return {
    setTransactionRequest: request => dispatch(setTransactionRequest(request))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteV2);
