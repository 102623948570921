import React, { Component, Fragment } from 'react';

import { FormControl, Grid} from "@material-ui/core";
import Select from 'react-select';

export default class InputSelect extends Component {

  onChange = (e) => {

      if(this.props.customTable) {
        let value = Object.assign({}, { [this.props.component.AttributeName]: e.Value });
        this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex);
      }
      else
      {
        this.props.handleInputChangeV2(e, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex);
      }
  }


  render() {

    const { component, isDynamicTable, datatableRowindex ,attributeValue} = this.props;
    let options = component.SubApplicationAttributeValueList;
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";

    return (
      <Fragment>
        <Grid
        className={component.StyleClass}
        item
        xs={12}
        sm={12}
        md={isDynamicTable ? 12 : component.Size}
        lg={isDynamicTable ? 12 : component.Size}
        key={component.AttributeName}
      >
        <FormControl
          style={customstyles.dropdown}
          className={`custom-dropdown ${component.AttributeStyleClass}`}
        >
          <Select
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            menuPortalTarget={document.querySelector('body')}
            onChange={(event,option) =>{this.onChange(event);}}
            id={component.ComponentId}
            name={component.AttributeName}
            autoComplete={autocomplete}
            getOptionLabel={option => option.DisplayValue}
            getOptionValue={option => option.Value}
            value={
              options.map((option) =>{
                if(attributeValue === option.Value){
                return {
                  DisplayValue :option.DisplayValue,
                  Value :option.Value
                }
              }
              })

              }
            isSearchable={true}
            styles={customstyles}
            options={options}
          >

          </Select>
          </FormControl>
          </Grid>
      </Fragment>
    );
  }
}


const customstyles = {
        control: (styles, state) => ({ ...styles,
            borderWidth : "0px 0px 1px !important",
            borderRadius : "0px !important" ,
            borderColor:  state.isFocused ? 'black' : 'gray' ,

            "&:hover": {
              borderColor: 'black',
            },
            boxShadow: 'none'
            }),
        menu: (provided, state) => ({
            ...provided,
            width: 'max-content'
          }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isSelected
              ? '#e5e5e5'
              : isFocused
              ? 'rgba(0, 0, 0, 0.06)'
              : null,
              color :'inherit'
          };
        },



};
