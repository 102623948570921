import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        {/* <span><a href="https://solartis.com" target="_blank" rel="noopener noreferrer">Solartis</a> &copy; {new Date().getFullYear()} </span>
        <span className="ml-auto">Powered by <a href="https://solartis.com" target="_blank" rel="noopener noreferrer">Solartis</a></span> */}
        <span className="footer-orchid">Powered by Solartis &copy; {new Date().getFullYear()}</span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
