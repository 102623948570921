import {
    SET_USER_INFO,
    SET_METADATA_PRIVILAGE,
    SET_SSOUSER_DATA
} from "../actions/types";

export const userInfoReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_USER_INFO:
      return {
        ...state,
        ...payload,
      };
    case SET_METADATA_PRIVILAGE:
      return {
        ...state,
        privilageList: [...payload]
      }
    case SET_SSOUSER_DATA: {
        return {
          ...state,
          ...payload
        }
      }
    default:
      return state;
  }
};
