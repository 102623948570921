import * as actionTypes from "../actions/types";

export const getWorkflowEventInitialState = () => ({ transactionRequest: {} });
const initialState = getWorkflowEventInitialState();

export const workflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRANSACTION_REQUEST:
      let transactionRequest = Object.assign({}, state.transactionRequest);
      transactionRequest[action.payload["EventName"]] = action.payload;
      return {
        ...state,
        transactionRequest,
      };
    default:
      return state;
  }
};
