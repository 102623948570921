import React, { Fragment,createElement } from 'react';
// import controls from './index';
import { connect } from "react-redux";
import container from '../views/runtime/utils/DIContainer';


class RenderTemplate extends React.Component{

  getChildComponent = (componentInfo, props) => {
    let PageComponent = null;
    const controls = container.get('templateControls')
        if(componentInfo.componentName){
           PageComponent = controls[componentInfo.componentName.toLowerCase()];
        }
        if(componentInfo.objectId === 'API'){
            //componentInfo.objectId = '362363'
            //componentInfo.objectVerId = '361434'
        //   componentInfo.objectId = this.props.productDetails&&this.props.productDetails.ProductID?this.props.productDetails.ProductID:props.objectId
        //    componentInfo.objectVerId = this.props.productDetails&&this.props.productDetails.ProductVerID?this.props.productDetails.ProductVerID:props.objectVerId
        }

        let childComponent = [];
        if (componentInfo.children !== undefined && componentInfo.children !== null) {
            componentInfo.children.forEach((componentData) => {
                if(componentData.componentName){
                    childComponent.push(this.getChildComponent(componentData, props));
                }
            })
            return (
                PageComponent ?
                <PageComponent { ...props } {...componentInfo} pageInfo={ componentInfo } >
                    {childComponent}
                </PageComponent>
                : createElement(componentInfo.componentName.toLowerCase(),{...props,...componentInfo,className:componentInfo.className,pageInfo:{componentInfo}},childComponent)
            )
        }
        else {
            return PageComponent ? <PageComponent { ...props } {...componentInfo} pageInfo={componentInfo} />
            : createElement(componentInfo.componentName.toLowerCase(),{...props,...componentInfo,className:componentInfo.className,pageInfo:{componentInfo}});
        }
    }

    render(){
        //console.clear();
        // console.log("compCheck Check");
        const { Template } = this.props;
        if(!Template) return null;
        return (
            <Fragment>
                {
                    Template.map(( templateData ) => {
                        return(
                        <Fragment key={templateData.id}>
                            {
                                this.getChildComponent(templateData, this.props)
                            }
                        </Fragment>
                    )
                })
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    productDetails: state.UIFlowReducer.productDetails
  });

export default connect( mapStateToProps )(RenderTemplate);
