import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Grid } from '@material-ui/core';
import { IconButton} from '@mui/material';
// import * as Controls from '../controls/index';
import store from '../../../store';
import container from '../utils/DIContainer';

const TooltipPopover=(props) =>{


  let {component, defaultSize} = props;
  const Controls = container.get('componentControls')
  let IconChild = component.AttributeParams && component.AttributeDataType.toLowerCase() === 'icon' && Controls[component.AttributeParams.toLowerCase()];
  let Children = IconChild ? <IconChild></IconChild> : component.DefaultValue;
  if(component.AttributeDataType==='Image'){
    let redux=store.getState()
    Children=<img src={redux.ownerResources[component.DefaultValue]} className={`Image_${component.AttributeStyleClass}`}></img>
  }
  let Value = <div dangerouslySetInnerHTML={{ __html: component.ComponentLabel }} />
  return (
    <Grid  item xs={12} sm={12} md={defaultSize || component.Size || 12} lg={defaultSize || component.Size || 12} key={component.AttributeName} className={`Tooltip_${component.AttributeStyleClass} `}>
      <Tooltip title={Value} placement="right-start"  classes={{ popper: `${component.DefaultValue}_${component.StyleClass}`}} arrow>
      <IconButton color="primary">
      {Children}
      </IconButton>
      </Tooltip>
    </Grid>
  );
}


export default TooltipPopover;
