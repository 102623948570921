import store from "../../../../store"

const PageHeaderRender = (params) => {
    const reduxState = store.getState().navigationReducer
    if(reduxState.tabPositions.hasOwnProperty('SubmissionTab') && reduxState.tabPositions.SubmissionTab > 0)
        return true
    else
        return false
}

const ExpandPanelRender = (params) => {
    const reduxState = store.getState().navigationReducer
    if(reduxState.tabPositions.hasOwnProperty('SubmissionTab') && reduxState.tabPositions.SubmissionTab > 0)
        return true
    else
        return false
}

export const submission = {
    PageHeaderRender: PageHeaderRender,
    ExpandPanelRender: ExpandPanelRender
}