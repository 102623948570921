import red from '@material-ui/core/colors/red';
import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import { ValidatorComponent } from "react-material-ui-form-validator";
import {getDateWithoutOffset} from "../utils/Common/DateUtils"

const red300 = red["500"];

const style = {
  left: 0,
  fontSize: "12px",
  color: red300
};

class MaskedCalendarValidator extends ValidatorComponent {

    render() {
        const {
            value,
            open,
            name,
            id,
            onClose,
            onClick,
            format,
            label,
            component,
            placeholder,
            disabled,
            styleClass,
            maxDate,
            minDate,
            autoComplete
        } = this.props;
        const formattedValue = value ? getDateWithoutOffset(value): value
        const password = formattedValue ? "password" :null;
        return (
            <div className={component.AttributeStyleClass}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth='100%'
                //variant="inline"
                //orientation="landscape"
                //InputProps={{ readOnly: true }}
                disabled={disabled}
                open={open}
                onClose={onClose}
                onClick={onClick}
                format={format}
                maxDate={maxDate}
                minDate={minDate}
                maxDateMessage = {component.ValidatorMessage}
                minDateMessage = {component.ValidatorMessage}
               className={styleClass+ ' lineHeight'}
                name={name}
                autoComplete={autoComplete}
                id={id}
                label={label}
                placeholder={placeholder}
                value={formattedValue}
              	onChange={(event,value) =>{this.props.onChange(event,value)}}
                type={password}
              />
            </MuiPickersUtilsProvider>
            <div className="errorMessageStyle" >
             {this.errorText()}
             </div>
            </div>
        );
    }


    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div className='small_error' style={style}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default MaskedCalendarValidator;
